import Joi from 'joi-browser'

const User = Joi.object().keys({
	id: Joi.number().integer(),
	userId: Joi.string().uuid(),
	username: Joi.string().required(),
	email: Joi.string().email().required(),
	firstName: Joi.string().required(),
	lastName: Joi.string().required(),
	attributes: Joi.any(),
	roles: Joi.array().min(1).items(Joi.string()).required(),
	requiredActions: Joi.array(),
	access: Joi.any(),
	createdTimestamp: Joi.any(),
	disableableCredentialTypes: Joi.any(),
	emailVerified: Joi.boolean().default(false),
	enabled: Joi.boolean().default(false),
	notBefore: Joi.any(),
	totp: Joi.boolean().default(false),
	federatedIdentities: Joi.any(),
	team: Joi.array(),
	defaultTeam: Joi.string(),
})
export const methodSchemas = {
	getAll: Joi.array().items(User),
	getOne: User,
	addItem: User,
	deleteItem: Joi.object().keys({ id: Joi.number().integer().positive() }),
	editItem: User,
}
export const collectionSchemas = {}
export const entitySchema = User
