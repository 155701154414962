/**
 * RU on all AuditLogs including whitelisting
 * This is in common as all apps would need it
 * AuditLog Key value pairs come from db of the app.
 */
import { Entity } from '../base/entity/entity'
import { collectionSchemas, entitySchema, methodSchemas } from './auditLog.schemas'
// import { apiBaseUrlPath } from '../../../config';
/**
 * Instructions:
 * 1. For simple usage steps:
 * a. fieldsMeta, actionsMeta, screens, sectionsMeta definition and creation of BaseEntity obj with these inputs
 * 2. For simple overrides of baseEntity
 * a. create an extended class of baseEntity and override its methods...
 * 3. For more customization
 * a. create a derived Service, derived actions and use them in derived BasedEntity class.
 * b. for component customization pass those components to the constuctor of derived BaseEntity class. SearchList component is the root component.
 *
 *
 */

class AuditLogEntity extends Entity {
	// override the following if needed.
	// derived actionObj
	// derived Service obj
	// getActionFns if you want to suppress the baseActionobj methods
}

const fieldsMeta = {
	requestPath: {
		label: 'Request Path',
		min: 3,
		max: 100,
		type: 'text',
		required: true,
		readOnly: true,
		name: 'requestPath',
	},
	requestMethod: {
		label: 'Request Method',
		min: 3,
		max: 100,
		required: true,
		type: 'text',
		name: 'requestMethod',
		readOnly: true,
	},
	requestData: {
		label: 'Request Data',
		name: 'requestData',
		min: 3,
		max: 100,
		type: 'text',
		required: true,
		readOnly: true,
	},
	responseStatus: {
		label: 'Response Status',
		name: 'responseStatus',
		min: 3,
		max: 100,
		type: 'text',
		required: true,
		readOnly: true,
	},

	userId: {
		label: 'Last Updated By',
		min: 3,
		max: 100,
		required: true,
		type: 'text',
		name: 'userId',
		readOnly: true,
	},
	timeAt: {
		label: 'Last Updated At',
		min: 3,
		max: 100,
		required: true,
		type: 'text',
		name: 'timeAt',
		readOnly: true,
	},
}

const actionsMeta = {
	// editItem: {
	//     type: 'button',
	//     label: 'Update',
	//     action: 'editItem',
	//     name: 'editItem'
	// },
	editMap: {
		label: 'Edit Map',
		name: 'editMap',
		action: 'showMap',
		condition: 'typeof value === "object"',
	},
	save: {
		label: 'Save',
		name: 'save',
		action: 'save',
	},
	cancel: {
		type: 'button',
		label: 'Cancel',
		action: 'cancelEdit',
		name: 'cancel',
	},
	// update: {
	//     label: 'Edit',
	//     name: 'update',
	//     action: 'updateItem',
	//     classes: 'fa fa-pencil fa-lg ',
	//     type: 'span'
	// },
	list: {
		label: 'List All',
		name: 'list',
		action: 'load',
	},
	search: {
		label: 'Search',
		name: 'search',
		action: 'search',
	},
	view: {
		label: 'View',
		name: 'view',
		action: 'getItem',
		classes: 'fa fa-eye fa-lg',
		type: 'span',
	},

	createItem: {
		label: 'Create',
		action: 'addNewItem',
		name: 'create',
		type: 'button',
	},
	deleteItem: {
		label: 'Delete',
		action: 'deleteItem',
		name: 'delete',
		type: 'button',
	},
}
const sectionsMeta = {
	// general: {
	//     items: [{name:'skill', type: 'field'}, {name:'subjectArea', type: 'field'}, {name:'intentName', type: 'field'}]
	// },
	basic: {
		title: 'AuditLog Details',
		items: [
			{ name: 'requestPath', type: 'field' },
			{ name: 'requestMethod', type: 'field' },
			{ name: 'requestData', type: 'field' },
			{ name: 'responseStatus', type: 'field' },
			{ name: 'userId', type: 'field' },
			{ name: 'timeAt', type: 'field' },
		],
		classes: 'ticket-form',
	},
	headerComponent: {
		component: 'DetailHeader',
	},

	AuditLogForm: {
		cols: [7, 5],
		items: [
			{ name: 'basic', type: 'section', col: 1 },
			{ name: 'editItem', type: 'action', col: 1 },
			{ name: 'cancel', type: 'action', col: 1 },
		],
		classes: 'composite-inner-form',
	},

	// AuditLogCreateForm: {
	//     cols: [7,5],
	//     items:[{name: 'basic', type: 'section', col: 1},
	//     {name: 'createItem', type: 'action', col: 1}],
	//     classes: 'composite-inner-form'
	// },
}

const tabsMeta = {}

const screens = {
	// edit: {
	//     title:{label: 'Edit AuditLogs', col: 1, },
	//     items: [{name:'heading', type: 'section'}],
	//     actions: ['editMap', 'cancel', 'save']
	// },
	view: {
		items: [
			{ name: 'headerComponent', type: 'section' },
			{ name: 'AuditLogForm', type: 'section' },
		],
		classes: 'composite-form',
		title: 'AuditLog Details',
	},
	// create:{
	//     items: [{name:'headerComponent', type: 'section'}, {name: 'AuditLogCreateForm', type: 'section'}]
	// },
	edit: {
		items: [
			{ name: 'headerComponent', type: 'section' },
			{ name: 'AuditLogForm', type: 'section' },
		],
	},
	// view: {
	//     sections: ['general', 'details'],
	//     actions: ['edit']
	// },
	list: {
		items: [
			{ name: 'requestPath', type: 'link' },
			{ name: 'requestMethod', type: 'field' },
			{ name: 'responseStatus', type: 'field' },
			{ name: 'userId', type: 'field' },
			{ name: 'timeAt', type: 'field' },
		],
	},
	search: {
		items: [
			{ name: 'requestPath', type: 'field' },
			{ name: 'requestMethod', type: 'field' },
			{ name: 'responseStatus', type: 'field' },
		],
	},
}

// const components = { searchList: EntityComponent} // can overrides the components like this if needed
const AuditLog = new AuditLogEntity({
	fieldsMeta,
	actionsMeta,
	sectionsMeta,
	tabsMeta,
	screens,
	name: 'AuditLog',
	title: 'AuditLog',
	methodSchemas,
	collectionSchemas,
	entitySchema,
	idKey: 'id',
	entityUrlPaths: { default: `/AuditLog` },
	fetchDetailByApi: false,
})

const AuditLogListSearchContainer = AuditLog.getContainer('ListSearch')
const AuditLogCreateContainer = AuditLog.getContainer('Create')
const AuditLogDetailContainer = AuditLog.getContainer('Detail')
export { AuditLogListSearchContainer, AuditLogCreateContainer, AuditLogDetailContainer }
