import React, { Component, useEffect, useState } from 'react'
// import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone'
import { withTranslation } from 'react-i18next'
import { Col, FormGroup, FormText, Input, Jumbotron, Label, ListGroup, ListGroupItem, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap'
import './jsonInput/component.css'
class AudioComponent extends Component {
	constructor(props) {
		super(props)
		this.state = {
			tabSwitch: false,
			upload: true,
			refUrl: '',
			activeTab: '1',
			errorMsg: { validUrl: true },
			audioUrl: false,
			audio: false,
			previewFiles: false,
			audioList:
				this.props.value ||
				[
					// { url: 'https://file-examples.com/wp-content/uploads/2017/11/file_example_WAV_1MG.wav', type: 'audio' },
					// { url: 'https://file-examples.com/wp-content/uploads/2017/11/file_example_OOG_1MG.ogg', type: 'audio' },
					// { url: 'https://file-examples.com/wp-content/uploads/2017/11/file_example_MP3_700KB.mp3', type: 'audio' }
				],
		}
		this.toggle = this.toggle.bind(this)
	}
	toggle(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeTab: tab,
				upload: true,
				refUrl: '',
				errorMsg: { validUrl: true },
				audioUrl: false,
				audio: false,
			})
		}
	}
	componentWillMount() {}

	componentDidMount() {}

	componentWillReceiveProps(nextProps) {
		if (nextProps && nextProps.value) {
			this.mapValues(nextProps.value)
		}
	}
	mapValues = value => {
		if (value && value[0].contentRef) {
			let refName = value[0].contentRef
			if (this.validURL(refName)) {
				this.toggle('2')
				this.setState({ audio: true, audioUrl: refName, refUrl: refName })
			} else {
				this.toggle('1')
				this.setState({ audioList: value })
			}
		}
	}
	// shouldComponentUpdate(nextProps, nextState) {

	// }

	// componentWillUpdate(nextProps, nextState) {

	// }

	// componentDidUpdate(prevProps, prevState) {

	// }

	// componentWillUnmount() {

	// }

	validURL(str) {
		var pattern = new RegExp(
			'^(https?:\\/\\/)?' + // protocol
				'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
				'((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
				'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
				'(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
				'(\\#[-a-z\\d_]*)?$',
			'i'
		) // fragment locator
		return !!pattern.test(str)
	}

	handleInputChange = event => {
		const { errorMsg } = this.state
		if (event.target.value) {
			if (this.validURL(event.target.value)) {
				this.saveUrl(event.target.value)
				this.setState({ audio: true, audioUrl: event.target.value, refUrl: event.target.value })
			} else {
				this.setState({
					refUrl: event.target.value,
					audio: false,
					audioUrl: null,
					errorMsg: {
						...errorMsg,
						validUrl: true,
						inValidMsg: '',
					},
				})
			}
		} else {
			this.setState({ audio: false, refUrl: '', audioUrl: false })
		}
	}
	switchTab = tabName => {
		if (tabName && tabName === 'upload') this.setState({ tabSwitch: false, upload: true })
		else this.setState({ tabSwitch: true, upload: false })
	}
	readFileAsync(file) {
		return new Promise((resolve, reject) => {
			let reader = new FileReader()

			reader.onload = () => {
				resolve(reader.result)
			}

			reader.onerror = reject

			reader.readAsDataURL(file)
		})
	}

	async asyncForEach(array, callback) {
		for (let index = 0; index < array.length; index++) {
			await callback(array[index], index, array)
		}
	}

	onDrop = async files => {
		const { errorMsg } = this.state
		// const {maxUpload}=this.props;
		let fileList = []
		if (files && files.length > 0) {
			let filesData = Object.keys(files)
			await this.asyncForEach(filesData, async key => {
				// let docName = files[key].name;
				// let docType = files[key].type;
				let contentBuffer = await this.readFileAsync(files[key])

				let fileObj = {
					contentRef: 'Audio',
					name: files[key].name || 'File',
					type: files[key].type,
					data: contentBuffer,
				}
				fileList.push(fileObj)
			})
			this.props.onComponentChange(this.props.name, fileList)
			this.resetAll()
		} else {
			this.setState({
				errorMsg: {
					...errorMsg,
					upload: false,
					inValidMsg: 'Please Select a File to Upload',
				},
			})
		}
	}
	cancelUpload = () => {
		this.resetAll()
	}

	saveUrl = refUrl => {
		const { errorMsg } = this.state
		let fileList = []
		if (this.validURL(refUrl)) {
			let fileObj = {
				contentRef: refUrl,
			}
			fileList.push(fileObj)
			this.props.onComponentChange(this.props.name, fileList)
			this.resetAll()
		} else
			this.setState({
				errorMsg: {
					...errorMsg,
					validUrl: false,
					inValidMsg: 'Url is Invalid',
				},
			})
	}
	resetAll() {
		this.setState({
			refUrl: '',
			audio: false,
			audioUrl: false,
			errorMsg: {
				validUrl: true,
				inValidMsg: '',
			},
		})
	}

	cancelUrl = () => {
		this.resetAll()
	}
	previewFiles = file => {
		this.setState({ previewFiles: file })
	}
	render() {
		let { t } = this.props
		const { activeTab, audioUrl, audio, audioList, errorMsg, refUrl, previewFiles } = this.state
		return (
			<div className="upload">
				{audio && audioUrl && <Audio {...this.props} audioSrc={audioUrl} />}

				{previewFiles && previewFiles.length > 0 && <AudioList {...this.props} preview={true} list={previewFiles} />}

				<Nav tabs>
					<NavItem>
						<NavLink
							className={`tablink ${activeTab === '1' ? `active` : ``}`}
							onClick={() => {
								this.toggle('1')
							}}>
							{t('UPLOAD')}
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink
							className={`tablink ${activeTab === '2' ? `active` : ``}`}
							onClick={() => {
								this.toggle('2')
							}}>
							{t('URL')}
						</NavLink>
					</NavItem>
				</Nav>
				<TabContent activeTab={activeTab}>
					<TabPane tabId="1">
						<Row>
							<Col sm="12">
								<FormGroup>
									<DropZoneComponent
										upload={this.onDrop.bind(this)}
										cancel={this.cancelUpload.bind(this)}
										previewFiles={this.previewFiles.bind(this)}
										maxUpload={this.props.maxUpload || 1}
										maxUploadSize={this.props.maxUploadSize || 5}
									/>
								</FormGroup>
							</Col>
						</Row>
					</TabPane>
					<TabPane tabId="2">
						<Row>
							<Col sm="12">

                                {errorMsg && !errorMsg.validUrl && <FormText color="danger">{t(errorMsg.inValidMsg) || ''}</FormText>}
								<FormGroup>
									<Label for="exampleCustomFileBrowser">{this('Enter Url')}</Label>
									<Input
										type="textarea"
										placeholder="Enter Refrence URl"
										value={refUrl}
										onChange={this.handleInputChange.bind(this)}
										id="url"
										name="url"
										label="Enter Url !"
									/>
								</FormGroup>
							</Col>
						</Row>
						{/* <Row><Col sm={6}></Col>
                            <Col sm={6}>
                                <Row className="pull-right">
                                    <div className="col-md-5"><Button color="primary" onClick={this.saveUrl.bind(this)} size="sm">Save</Button></div>
                                    <div className="col-md-5"><Button color="danger" onClick={this.cancelUrl.bind(this)} size="sm">Cancel</Button></div>
                                </Row>
                            </Col>
                        </Row> */}
					</TabPane>
					{audioList && audioList.length > 0 && <AudioList {...this.props} list={this.state.audioList} innerList={true} />}
				</TabContent>
			</div>
		)
	}
}

AudioComponent.propTypes = {}

export default withTranslation()(AudioComponent)

// React DropZone Component

const DropZoneComponent = props => {
	const [files, setFiles] = useState([])
	const [errorMsg, setErrorMsg] = useState(false)
	const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
		accept: 'audio/*',
		onDrop: acceptedFiles => {
			setFiles(
				acceptedFiles.map(file =>
					Object.assign(file, {
						preview: URL.createObjectURL(file),
					})
				)
			)
		},
	})
	const [setSelectedFile] = useState(acceptedFiles || null)

	useEffect(() => {
		if (validateFile(acceptedFiles) && validateFileSize(acceptedFiles)) {
			setSelectedFile(acceptedFiles)
			props.previewFiles(acceptedFiles)
			acceptedFiles && acceptedFiles.length > 0 && upload(acceptedFiles)
		}
	}, [acceptedFiles])

	useEffect(
		() => () => {
			// Make sure to revoke the data uris to avoid memory leaks
			files.forEach(file => URL.revokeObjectURL(file.preview))
		},
		[files]
	)

	const validateFile = validate => {
		const { maxUpload, t } = props
		if (validate.length <= maxUpload) {
			setErrorMsg(false)
			return true
		} else {
			setFiles([])
			setSelectedFile(null)
			setErrorMsg(`${t('Maximum')} ${maxUpload} ${t('can be uploaded!')}`)
			return false
		}
	}
	const validateFileSize = validate => {
		const { maxUploadSize, t } = props
		let mbToByte = maxUploadSize * 1024 * 1024
		let file = validate[0]
		if (file && mbToByte && file.size > mbToByte) {
			setFiles([])
			setSelectedFile(null)
			setErrorMsg(`${t('Maximum')} ${maxUploadSize} ${t('MB Audio can be uploaded!')}`)
			return false
		} else {
			setErrorMsg(false)
			return true
		}
	}

	// const removeFile = (fileInfo, event) => {
	//     let filterFile = selectedFile && selectedFile.length > 0 && selectedFile.filter(x => x.name != fileInfo.name)
	//     setSelectedFile(filterFile)
	// }

	// const cancel = () => {
	//     setFiles([])
	//     setSelectedFile(null)
	//     setErrorMsg(false)
	//     props.cancel()
	// }

	const upload = uploadFiles => {
		const {t} = this.props
		if (uploadFiles.length > 0) {
			props.upload(uploadFiles)
			setErrorMsg(false)
		} else {
			setErrorMsg(t(`Please Select a file to upload!`))
		}
	}

	return (
		<section className="container">
			{errorMsg && (
				<p>
					<FormText color="danger">{errorMsg}</FormText>
				</p>
			)}
			<div {...getRootProps({ className: 'dropzone' })}>
				<input {...getInputProps()} />
				<Jumbotron>{props.t("Drag 'n' drop Audio files here, or click to select files")}</Jumbotron>
			</div>
			{/* <Row><Col sm={6}></Col>
                <Col sm={6}>
                    <Row className="pull-right">
                        <div className="col-md-5"><Button color="primary" onClick={upload.bind(this)} size="sm">Upload</Button></div>
                        <div className="col-md-5"><Button color="danger" onClick={cancel.bind(this)} size="sm">Cancel</Button></div>
                        <div className="clearfix"></div>
                    </Row>
                </Col>
            </Row> */}
		</section>
	)
}

// audio List Component
const AudioList = props => {
	const [list, setList] = useState(props.list || [])

	useEffect(() => {
		setList(props.list)
	}, [props])

	return (
		<div className={props.innerList ? `videoList` : ''}>
			<Jumbotron>
				<Row>
					{!props.preview && list && list.length > 0 && (
						<Jumbotron>
							<ListGroup>
								{list.map((file, index) => (
									<ListGroupItem key={index} className="justify-content-between listPad spantitle">
										{props.t('File Name')} : {file.name} <br /> {props.t('Type')} : {file.type}
									</ListGroupItem>
								))}
							</ListGroup>
						</Jumbotron>
					)}
					{props.preview &&
						list &&
						list.length > 0 &&
						list.map((file, index) => (
							<Col sm={12} key={index} className="vcol audio-wrapper">
								<div className="tablink img img-thumbnail">
									<Audio {...this.props} audioSrc={file.url || file.preview || null} />
									{file.path && file.size && (
										<span className="spantitle">
											{file.path} - {file.size} {props.t('bytes')}{' '}
										</span>
									)}
									{!file.path && <span className="tablink spantitle">{`Audio ${index + 1}`}</span>}
								</div>
							</Col>
						))}
				</Row>
			</Jumbotron>
		</div>
	)
}

// React audio Component
const Audio = props => {
	const { audioSrc } = props

	return (
		<div className="audio">
			{audioSrc && (
				<audio controls src={audioSrc}>
					{props.t('Your browser does not support the')}
					<code>{props.t('audio')}</code> {props.t('element')}.
				</audio>
			)}
		</div>
	)
}
