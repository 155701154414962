import DataSet from '@antv/data-set'
import Immutable from 'immutable'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { AutoSizer, Column, SortDirection, SortIndicator, Table } from 'react-virtualized'
import { history } from '../../../../redux/history'
import { filterMetricRow, getObjByPath } from '../../helpers/utils'
import styles from './ListTable.module.css'

class ListTable extends React.PureComponent {
	constructor(props, context) {
		super(props)

		const sortBy = 'index'
		const sortDirection = SortDirection.ASC

		this.state = {
			disableHeader: false,
			headerHeight: 30,
			height: 270,
			hideIndexRow: false,
			overscanRowCount: 10,
			rowHeight: 40,
			rowCount: 1000,
			scrollToIndex: undefined,
			sortBy,
			sortDirection,
			useDynamicRowHeight: false,
		}

		this._getRowHeight = this._getRowHeight.bind(this)
		this._headerRenderer = this._headerRenderer.bind(this)
		this._noRowsRenderer = this._noRowsRenderer.bind(this)
		this._onRowCountChange = this._onRowCountChange.bind(this)
		this._onScrollToRowChange = this._onScrollToRowChange.bind(this)
		this._rowClassName = this._rowClassName.bind(this)
		this._sort = this._sort.bind(this)
		this._cellRenderer = this._cellRenderer.bind(this)
	}

	componentWillReceiveProps(props) {
		let dependsOn = props.dependsOn
		let changed
		const { list } = this.props
		if (dependsOn && Object.keys(dependsOn).length) {
			if (this.state && this.state.dependsOn)
				changed = Object.keys(dependsOn).some(k => {
					if (dependsOn[k] !== this.state.dependsOn[k]) return true
					return false
				})
			else changed = true
		} else {
			changed = true
		}
		let filteredData = this.processData(props)

		let sortedList
		if (filteredData) {
			this.context = { list: Immutable.fromJS(filteredData) }
			sortedList = this._sortList({ sortBy: list.sortBy, sortDirection: list.sortDirection })
			//console.log(`filteredData context ${ JSON.stringify(sortedList)}`)
		}
		this.setState({ ...this.state, ...props, dependsOn, filteredData, sortedList, rowCount: sortedList && sortedList.size }, () => {
			changed && props.list.api && this.getValue()
		})
	}
	getValue() {
		let api = this.props.list.api
		let apiMeta
		if (this.props.list.api) {
			let targetProp = api.response && api.response.targetProp
			targetProp = targetProp || 'lists.' + this.props.list.name
			apiMeta = this.props.meta.apis && this.props.meta.apis[api]
			this.props.executeApi({ apiMeta, values: this.props.filterValues, targetProp, t: this.props.t, apiUrl: this.props.tenant && this.props.tenant.apiUrl })
		}
	}
	processData(props) {
		let data
		const { list } = props
		let targetProp
		let apiMeta
		if (list && list.api) {
			apiMeta = this.props.meta.apis && this.props.meta.apis[list.api]
			targetProp = apiMeta && apiMeta.response && apiMeta.response.targetProp
			targetProp = targetProp || 'lists.' + this.props.list.name
			data = props.dashboard[targetProp]
		} else data = props.data
		// let title = props.list && props.list.title

		const { dataProp } = props.list
		// const { dependsOn } = this.state
		const { filterValues } = props
		let dv

		// let body
		let listData
		let filteredData
		// const metric = metrics[0]

		if (data) {
			if (dataProp) {
				if (dataProp === 'data') listData = data
				else listData = getObjByPath({ ref: data, path: dataProp })
			} else listData = data
			filteredData = filterMetricRow({ data: listData, filters: list.filters })

			if (list.dynFilters) {
				let filters = []
				Object.keys(list.dynFilters).forEach(field => {
					let filter = list.dynFilters[field]
					let filterObj = {}
					if (typeof filter === 'object') {
						// @ts-ignore
						filterObj = { field }
						if (filter.values) filterObj.values = filter.values.map(v => filterValues[v] || v)
						if (filter.value) filterObj.value = filterValues[filter.value] || filter.value
						filterObj.operator = filter.operator
						filters.push(filterObj)
					} else {
						// @ts-ignore
						filterObj = { field, value: filterValues[filter] || filter }
						filters.push(filterObj)
					}
				})
				filteredData = filterMetricRow({ data: filteredData, filters }) // dynamic filters
			}
			if (list.groupByAgg) {
				// @ts-ignore
				dv = new DataSet.View().source(filteredData)
				const as = list.groupByAgg.as || list.groupByAgg.fields
				dv.transform({
					type: 'aggregate',
					fields: list.groupByAgg.fields,
					operations: list.groupByAgg.operations,
					groupBy: list.groupByAgg.groupBy,
					as: as,
				})
			}
		}
		return (dv && dv.rows) || filteredData || listData
	}

	render() {
		const {
			disableHeader,
			headerHeight,
			// height,
			hideIndexRow,
			overscanRowCount,
			rowHeight,
			rowCount,
			scrollToIndex,
			sortBy,
			sortDirection,
			sortedList,
			useDynamicRowHeight,
		} = this.state

		const { list } = this.props
		let dataList = sortedList
		const data = this.state && this.state.filteredData
		if (!dataList && data) {
			dataList = Immutable.fromJS(data)
		}
		const rowGetter = ({ index }) => this._getDatum(dataList, index)
		const colProps = list && list.colProps
		let widthUsed = colProps && colProps.reduce((p, c) => (c.width ? p + c.width : p), 0)
		let colsW
		if (widthUsed) colsW = colProps.length - colProps.filter(c => c.width).length
		else colsW = colProps.length

		let title = this.props.list && this.props.list.title
		// {
		//     colProps && colProps.map((r,i) =>{
		//         return <div key={i}></div>
		//     })
		// }
		// console.log(`widthUsed: ${widthUsed} colsW: ${colsW} `)
		return (
			<div className=" my-3 ">
				<div className=" text-center h4">{title}</div>
				{dataList && colProps && (
					<AutoSizer disableHeight>
						{({ width }) => (
							<Table
								ref="Table"
								disableHeader={disableHeader}
								headerClassName={styles.headerColumn}
								headerHeight={headerHeight}
								height={list.height}
								noRowsRenderer={this._noRowsRenderer}
								overscanRowCount={overscanRowCount}
								rowClassName={this._rowClassName}
								rowHeight={useDynamicRowHeight ? this._getRowHeight : rowHeight}
								rowGetter={rowGetter}
								rowCount={rowCount}
								scrollToIndex={scrollToIndex}
								sort={this._sort}
								sortBy={sortBy}
								sortDirection={sortDirection}
								width={width}>
								{!hideIndexRow && (
									<Column
										label="Index"
										cellDataGetter={({ rowData }) => rowData}
										dataKey="index"
										disableSort={!this._isSortEnabled()}
										cellRenderer={({ cellData, rowIndex }) => cellData && `${rowIndex + 1}`}
										width={width / 10}
									/>
								)}
								{colProps &&
									colProps.map((r, i) => {
										return (
											<Column
												label={r.label || r.field}
												cellDataGetter={this._cellDataGetter}
												key={r.field}
												className={r.className}
												dataKey={r.field}
												width={r.width ? (r.width / 100) * width : (((100 - widthUsed) / 100) * width) / colsW}
												cellRenderer={this._cellRenderer}
											/>
										)
									})}
							</Table>
						)}
					</AutoSizer>
				)}
				{!dataList && <p>No data to display </p>}
			</div>
		)
	}
	_cellDataGetter({ rowData, dataKey }) {
		if (rowData) {
			if (typeof rowData.get === 'function') {
				return rowData.get(dataKey)
			} else {
				return rowData[dataKey]
			}
		}
	}
	itemClick({ columnIndex, rowIndex, cellData, rowData }) {
		const {
			list: { colProps },
		} = this.props
		let route
		if (colProps && colProps[columnIndex] && colProps[columnIndex].route) {
			route = colProps[columnIndex].route
		}
		if (route) history.push(route)
	}

	_cellRenderer({ rowIndex, columnIndex, cellData, rowData, dataKey, isScrolling }) {
		const {
			list: { colProps },
		} = this.props

		if (cellData == null) {
			return ''
		} else {
			if (colProps && colProps[columnIndex - 1].route) {
				return (
					<button className="btn btn-link" onClick={this.itemClick.bind(this, { columnIndex: columnIndex - 1, rowIndex, cellData, rowData })}>
						{' '}
						{String(cellData)}{' '}
					</button>
				)
			}
			return String(cellData)
		}
	}
	_getDatum(list, index) {
		return list && list.get(index % list.size)
	}

	_getRowHeight({ index }) {
		const { list } = this.context
		if (list) return this._getDatum(list, index).size
	}

	_headerRenderer({ dataKey, sortBy, sortDirection }) {
		return (
			<div>
				Full Name
				{sortBy === dataKey && <SortIndicator sortDirection={sortDirection} />}
			</div>
		)
	}

	_isSortEnabled() {
		const { list } = this.context
		const { rowCount } = this.state
		if (list) return rowCount <= list.size
	}

	_noRowsRenderer() {
		return <div className={styles.noRows}>No rows</div>
	}

	_onRowCountChange(event) {
		const rowCount = parseInt(event.target.value, 10) || 0

		this.setState({ ...this.state, rowCount })
	}

	_onScrollToRowChange(event) {
		const { rowCount } = this.state
		let scrollToIndex = Math.min(rowCount - 1, parseInt(event.target.value, 10))

		if (isNaN(scrollToIndex)) {
			scrollToIndex = undefined
		}

		this.setState({ ...this.state, scrollToIndex })
	}

	_rowClassName({ index }) {
		if (index < 0) {
			return styles.headerRow
		} else {
			// console.log(`${index}: rowStyle: ${JSON.stringify(styles)}`)
			return index % 2 === 0 ? styles.evenRow : styles.oddRow
		}
	}

	_sort({ sortBy, sortDirection }) {
		const sortedList = this._sortList({ sortBy, sortDirection })

		this.setState({ ...this.state, sortBy, sortDirection, sortedList })
	}

	_sortList({ sortBy, sortDirection }) {
		const { list } = this.context
		if (list) return list.sortBy(item => item[sortBy]).update(list => (sortDirection === SortDirection.DESC ? list.reverse() : list))
	}

	_updateUseDynamicRowHeight(value) {
		this.setState({ ...this.state, useDynamicRowHeight: value })
	}
}
export default withTranslation()(ListTable)
