    import React, { useCallback, useRef } from "react";
    import { useEffect } from "react";
    import { useState } from "react";
    import {
      Modal,
      ModalOverlay,
      ModalContent,
      ModalHeader,
      ModalBody,
      ModalCloseButton,
      useDisclosure,
      Button,
      Input,
      Box,
      Text,
      InputGroup,
      InputRightElement,
      Spinner,
      Flex,
    } from "@chakra-ui/react";
    import { identityServices } from "../../..";
    import { SearchIcon } from "@chakra-ui/icons";

    const ITEMS_PER_PAGE = 25;

    const SearchContainer = (props) => {
      const { isOpen, onOpen, onClose } = useDisclosure();
      const [searchQuery, setSearchQuery] = useState("");
      const [data, setData] = useState([]);
      const [currentPage, setCurrentPage] = useState(1);
      const [isLoading, setIsLoading] = useState(false);
      const [tenantCfg,setTenantCfg] = useState(false);

      const scrollRef = useRef();
      const totalPages = Math.ceil(data?.length / ITEMS_PER_PAGE);
      const handleTenantConfig = () => {
        let lcStg = localStorage.getItem('persist:root')
        let newLocalStorage = JSON.parse(lcStg)
        let globalStg = newLocalStorage && newLocalStorage.globalSetting 
        let tenantConfig = JSON.parse(globalStg)
        if (tenantConfig && tenantConfig.uiFeature && tenantConfig.uiFeature.admin && tenantConfig.uiFeature.admin.Customer.status) {
          setTenantCfg(true)
        } else {
          setTenantCfg(false)
        }
      }
      

      const handleScroll = useCallback(() => {
        if (
          scrollRef.current.scrollHeight - scrollRef.current.scrollTop <=
          scrollRef.current.clientHeight + 20
        ) {
          if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
          }
        }
      }, [currentPage, totalPages]);

      const currentItems = data?.slice(0, currentPage * ITEMS_PER_PAGE);
      function handleSearchQueryChange(event) {
        setSearchQuery(event.target.value);
      }

      useEffect(() => {
        handleTenantConfig()
        if (isOpen) {
          setIsLoading(true);
          let apiUrl = sessionStorage.getItem("apiUrl");
          if(tenantCfg){
            if(props?.entityValues?.aadTenantId){
              const requestOptions = {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                },
                url: `${apiUrl}/msteams/getAADTeam/${props?.entityValues?.aadTenantId}?$filter=${searchQuery}`,
              };
              identityServices.identityApi
              .request(requestOptions)
              .then((res) => {
                setData(res.data);
                setIsLoading(false);
              })
              .catch((err) => console.log(err.message));
            }else if (!props?.entityValues?.aadTenantId){
              setIsLoading(false)
            }
          }else{
            const requestOptions = {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
              },
              url: `${apiUrl}/Group/getAadGroups?$filter=${searchQuery}`,
            };
            identityServices.identityApi
              .request(requestOptions)
              .then((res) => {
                setData(res.data);
                setIsLoading(false);
              })
              .catch((err) => console.log(err.message));
          }
        }
      }, [isOpen, searchQuery]);
      const handleButtonClick = (id) => {
        props.handleChange("aadGroupId", id);
        setCurrentPage(1);
        setSearchQuery("");
        setData([])
        onClose();
      };
      const handleOpen = (event) => {
        onOpen();
      };
      const handleClose = (event) => {
        setCurrentPage(1);
        setSearchQuery("");
        setData([])
        onClose();
      };
      return (
        <div>
          <Button
            onClick={handleOpen}
            className="min-btn pull-right btn btn-secondary"
            fontWeight="normal"
            position="absolute"
            top="18%"
            left="60%"
          >
            Advance Search
          </Button>

          <Modal
            isOpen={isOpen}
            onClose={onClose}
            scrollBehavior="inside"
            size="2xl"
          >
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>AAD Group(s)</ModalHeader>
              <ModalCloseButton onClick={handleClose} />
              <ModalBody ref={scrollRef} onScroll={handleScroll} bottom={10}>
                <Box
                  zIndex="1000"
                  position="absolute"
                  background="white"
                  width="90%"
                  // top={100}
                  left="48.5%" 
                  transform="translate(-50%, -50%)" 
                >
                  <InputGroup>
                    <Input
                      placeholder="Advance Search"
                      onChange={handleSearchQueryChange}
                    />
                    <InputRightElement>
                      <SearchIcon color="gray.300" />
                    </InputRightElement>
                  </InputGroup>
                </Box>
                <Box marginTop={10}>
                  {isLoading && (
                    <Flex justify="center" align="center">
                      <Box>
                        <Spinner size="xl" color="blue.500" />
                      </Box>
                    </Flex>
                  )}
                  {!isLoading && !data?.length && <p>There are no items in this list</p>}
                  {currentItems &&
                    currentItems?.map((ele, i) => {
                      return (
                        <Box key={ele.id} border="1px solid" borderColor="gray.200" borderRadius="5px" mb={2} p={1} _hover={{ bg: "gray.200", cursor: "pointer" }} onClick={() => handleButtonClick(ele.id)}>
                          <Text
                            fontSize="xl"
                          >
                            {ele.displayName}
                          </Text>
                        </Box>
                      );
                    })}
                </Box>
              </ModalBody>
              <Box></Box>
            </ModalContent>
          </Modal>
        </div>
      );
    };

    export default SearchContainer;