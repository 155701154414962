import { Entity } from '../common/base/entity/entity';
import { methodSchemas, entitySchema, collectionSchemas } from './group.schema';
import { ContentActions } from './group.services';
import { GroupDetailView } from './components/GroupDetailView'
import  GroupMainView   from './components/GroupMainView'
class GroupEntity extends Entity {
  // override the following if needed.
  // derived actionObj
  // derived Service obj
  // getActionFns if you want to suppress the baseActionobj methods
  constructor(options) {
		super(options)
    this.state ={}
	}

}
let tenantCfg 

const handleTenantConfig = () => {
  let lcStg = localStorage.getItem('persist:root')
  let newLocalStorage = JSON.parse(lcStg)
  let globalStg = newLocalStorage && newLocalStorage.globalSetting 
  let tenantConfig 
  if (globalStg) tenantConfig = JSON.parse(globalStg)
  else tenantConfig = null
  if (tenantConfig && tenantConfig.uiFeature && tenantConfig.uiFeature.admin && tenantConfig.uiFeature.admin.Customer.status) {
    tenantCfg = true
  } else {
    tenantCfg = false
  }
}
handleTenantConfig()


const fieldsMeta_MSP = {
  aadTenantId: {
    display : false,
    label: 'AAD Tenant ID',
    name: 'aadTenantId',
    type: 'text',
    readOnly: true,
    dependsOn: ['customerName'],
    disabled : true,
    api: {
      path: 'botmgmt-service/CustomerMSP',
      method: 'GET',
      params: { filter: ['customerName'] },
      response: {
        ref: 'data',
        labelKey: 'aadTenantId',
        valueKey: 'aadTenantId',
      },
      filters: [
        {
          field: 'status',
          operator: 'is',
          value: 'Published',
        },
      ],
    },
  },
  mspGroupId:{
    label: 'mspGroupId Name',
    name: 'mspGroupId',
    placeholder: 'Select Your Client Name',
    type: 'select',
    dependsOn: ['aadGroupId'],
    isMulti: false,
    dynamicOptions: true,
    api: {
      path: 'msteams/getAADTeam',
      params: { queryString: ['aadTenantId'] },
      response: {
        ref: 'data',
        labelKey: 'displayName',
        valueKey: 'id',
      },
    },
  },
  customerNameEdit: {
    label: 'Client Name',
    name: 'customerName',
    type: 'select',
    readOnly: true, 
    api: {
      path: 'botmgmt-service/CustomerMSP',
      method: 'GET',
      response: {
        ref: 'data',
        labelKey: 'customerName',
        valueKey: 'customerName',
      },
      filters: [
        {
          field: 'status',
          operator: 'is',
          value: 'Published',
        },
      ],
    },
  },
  customerName: {
    label: 'Client Name',
    name: 'customerName',
    placeholder: 'Select Your Client Name',
    type: 'select',
    isMulti: false,
    dynamicOptions: true,
    default : 'default',
    api: {
      path: 'botmgmt-service/CustomerMSP',
      method: 'GET',
      response: {
        ref: 'data',
        labelKey: 'customerName',
        valueKey: 'customerName',
      },
      filters: [
        {
          field: 'status',
          operator: 'is',
          value: 'Published',
        },
      ],
    },
  },
  groupText: {
    label: 'Group Name',
    name: 'groupText',
    type: 'text',
    placeholder: 'Enter Group Text',
  },
  aadGroupId: {
    label: 'AAD Group(s)',
    name: 'aadGroupId',
    type: 'select',
    placeholder: 'Please choose AAD Groups',
    dependsOn: ['customerName'],
    note:'Group retrieve from Azure could take up to 5 seconds',
    advanceSearch: true,
    api: {
      path: 'msteams/getAADTeam',
      params: { queryString: ['aadTenantId'] },
      response: {
        ref: 'data',
        labelKey: 'displayName',
        valueKey: 'id',
      },
    },
  },
  aadGroupIdReadOnly: {
    label: 'AAD Group(s)',
    name: 'aadGroupId',
    type: 'select',
    placeholder: 'Please choose AAD Groups',
    readOnly: true,
    dynamicOptions: true,
    dependsOn: ['customerName'],
    api: {
      path: 'msteams/getAADTeam',
      params: { queryString: ['aadTenantId'] },
      method: 'GET',
      response: {
        ref: 'data',
        labelKey: 'displayName',
        valueKey: 'id',
      },
    },
  },
  owners: {
    label: 'Members',
    name: 'owners',
    type: 'select',
    placeholder: 'Select Your Members by SYNCING',
  },
  ownersEdit: {
    label: 'Members',
    name: 'owners',
    type: 'select',
    placeholder: 'Sync MSP Customer',
    isMulti: true,
    dynamicOptions: true,
  },
  members: {
    label: 'Authorized Team Members',
    name: 'members',
    type: 'select',
    placeholder: 'Select Your Team Members',
    isMulti: true,
    dynamicOptions: true,
    dependsOn: ['customerName'],
    api: {
      path: 'botmgmt-service/CustomerMSP/getAgentByCustomer' ,
      params: { queryString: ['customerName'] },
      response: {
        ref: 'data',
        valueKey: 'assignedAgent',
        labelKey: 'assignedAgent'
      },
    },
  },
  status: {
    label: 'Status',
    name: 'status',
    type: 'select',
    placeholder: 'Please choose status',
    options: [
      { label: 'Active', value: 'Active' },
      { label: 'Inactive', value: 'Inactive' },
    ],
  },

  createdAt: {
    label: 'Created At',
    name: 'createdAt',
    type: 'date',
    readOnly: 'true',
  },
  createdBy: {
    label: 'Created By',
    name: 'createdBy',
    type: 'text',
    readOnly: 'true',
  },
  updatedAt: {
    label: 'Updated At',
    name: 'updatedAt',
    type: 'date',
    readOnly: 'true',
  },
  updatedBy: {
    label: 'Updated By',
    name: 'updatedBy',
    type: 'text',
    readOnly: 'true',
  },
}; 

const actionsMeta_MSP = {
  addItem: {
    type: 'button',
    label: 'Create',
    action: 'addNewItem',
    name: 'addNewItem',
  },
  cancel: {
    type: 'button',
    label: 'Cancel',
    action: 'cancelEdit',
    name: 'cancel',
  },
  upsert: {
    label: 'Update',
    name: 'update',
    action: 'upsertItem',
    classes: 'fa fa-pencil fa-lg ',
    type: 'span',
  },

  update: {
    label: 'Update',
    name: 'update',
    action: 'editItem',
    classes: 'fa fa-pencil fa-lg ',
    type: 'span',
  },
  list: {
    label: 'List All',
    name: 'list',
    action: 'load',
  },
  search: {
    label: 'Search',
    name: 'search',
    action: 'search',
  },
  view: {
    label: 'View',
    name: 'view',
    action: 'getItem',
    classes: 'fa fa-eye fa-lg',
    type: 'span',
  },
  create: {
    label: 'Create',
    action: 'addNewItem',
    name: 'create',
    type: 'button',
  },
  syncGroup: {
    label: 'Sync with AAD',
    action: 'upsertItem',
    name: 'syncGroup',
    type: 'button',
    classes: 'fa fa-eye fa-lg btn btn-primary',
  },
};
const sectionsMeta_MSP = {
  basic: {
    title: 'Create Group',
    items: [
      { name: 'aadTenantId' , type : 'field'},
      { name: 'customerName', type: 'field' },
      { name: 'aadGroupId', type: 'field' },
      { name: 'groupText', type: 'field' },
      { name: 'owners', type: 'field' },
      { name: 'members', type: 'field' },
    ],
    classes: 'group-form-basic',
  },
  detailScreen: {
    title: 'Edit Group',
    items: [
      { name: 'aadTenantId' , type : 'field'},
      { name: 'aadGroupIdReadOnly', type: 'field' },
      { name: 'groupText', type: 'field' },
      { name: 'customerNameEdit', type: 'field' },
      { name: 'ownersEdit', type: 'field' },
      { name: 'members', type: 'field' },
      { name: 'status', type: 'field' },
      { name: 'createdBy', type: 'field' },
      { name: 'updatedAt', type: 'field' },
    ],
    classes: 'group-form-update',
  },

  headerComponent: {
    component: 'DetailHeader',
  },
  groupDetailForm: {
    cols: [9, 3],
    items: [
      { name: 'detailScreen', type: 'section', col: 1 },
      { name: 'update', type: 'action', col: 2 },
    ],
    classes: 'composite-inner-form group-inner-form-detail',
  },

  groupCreateForm: {
    cols: [7, 5],
    items: [
      { name: 'basic', type: 'section', col: 1 },
      { name: 'addItem', type: 'action', col: 2 },
    ],
    classes: 'composite-inner-form group-inner-form-create',
  },
};

const tabsMeta = {};

const screens_MSP = {
  view: {
    items: [
      { name: 'headerComponent', type: 'section' },
      { name: 'groupDetailForm', type: 'section' },
    ],
    title: 'Edit Group',
  },
  create: {
    items: [
      { name: 'headerComponent', type: 'section' },
      { name: 'groupCreateForm', type: 'section' },
    ],
  },
  edit: {
    items: [
      { name: 'headerComponent', type: 'section' },
      { name: 'groupDetailForm', type: 'section' },
    ],
  },
  list: {
    title: 'Groups',
    items: [
      { name: 'groupText', type: 'link' },
      { name: 'customerName', type: 'field' },
      { name: 'owners', type: 'field' },
      { name: 'members', type: 'field' },
      { name: 'status', type: 'field' },
    ],
    multiselect: false,
    orderBy: ['updatedAt desc']
  },
};


const fieldsMeta_NON_MSP = {
  // customerNameEdit: {
  //   label: 'Client Name',
  //   name: 'customerNameEdit',
  //   placeholder: 'Select Your Client Name',
  //   type: 'text',
  // },
  customerName: {
    label: 'Client Name',
    name: 'customerName',
    placeholder: 'Select Your Client Name',
    type: 'select', 
    options: [
      { label: 'default', value: 'default' }
    ],
  },
  groupText: {
    label: 'Group Name',
    name: 'groupText',
    type: 'text',
    placeholder: 'Enter Group Text',
  },
  aadGroupId: {
    label: 'AAD Group(s)',
    name: 'aadGroupId',
    type: 'select',
    placeholder: 'Please choose AAD Groups',
    dynamicOptions: true,
    advanceSearch: true,
    api: {
      path: "Group/getAadGroups",
      method: 'GET',
      response: {
        ref: 'data',
        labelKey: 'displayName',
        valueKey: 'id',
      },
    },
  },
  aadGroupIdReadOnly: {
    label: 'AAD Group(s)',
    name: 'aadGroupId',
    type: 'select',
    placeholder: 'Please choose AAD Groups',
    readOnly: true,
    dynamicOptions: true,
    api: {
      path: "Group/getAadGroups",
      method: 'GET',
      response: {
        ref: 'data',
        labelKey: 'displayName',
        valueKey: 'id',
      },
    },
  },
  owners: {
    label: 'Owner(s)',
    name: 'owners',
    type: 'select',
    placeholder: 'Please choose Onwers',
    isMulti: true,
    dynamicOptions: true,
    api: {
      path: 'User/getUsersByRole/user_admin,tenant_admin,bot_admin,support_user,support_supervisor',
      method: 'GET',
      response: {
        ref: 'data',
        labelKey: 'username',
        valueKey: 'username',
      },
      // filters: [
      //   {
      //     field: 'status',
      //     operator: 'is',
      //     value: 'Active',
      //   },
      // ],
    },
  },
  members: {
    label: 'Members',
    name: 'members',
    type: 'select',
    placeholder: 'Please choose Members',
    isMulti: true,
    dynamicOptions: true,
    api: {
      path: "botmgmt-service/appusers/getUsersWithTeamsPackage",
      method: 'GET',
      response: {
        ref: 'data.rows',
        labelKey: `username`,
        valueKey: `username`,
      },
    },
  },
  status: {
    label: 'Status',
    name: 'status',
    type: 'select',
    placeholder: 'Please choose status',
    options: [
      { label: 'Active', value: 'Active' },
      { label: 'Inactive', value: 'Inactive' },
    ],
  },

  createdAt: {
    label: 'Created At',
    name: 'createdAt',
    type: 'date',
    readOnly: 'true',
  },
  createdBy: {
    label: 'Created By',
    name: 'createdBy',
    type: 'text',
    readOnly: 'true',
  },
  updatedAt: {
    label: 'Updated At',
    name: 'updatedAt',
    type: 'date',
    readOnly: 'true',
  },
  updatedBy: {
    label: 'Updated By',
    name: 'updatedBy',
    type: 'text',
    readOnly: 'true',
  },
};

const actionsMeta_NON_MSP = {
  addItem: {
    type: 'button',
    label: 'Create',
    action: 'addNewItem',
    name: 'addNewItem',
  },
  cancel: {
    type: 'button',
    label: 'Cancel',
    action: 'cancelEdit',
    name: 'cancel',
  },
  upsert: {
    label: 'Update',
    name: 'update',
    action: 'upsertItem',
    classes: 'fa fa-pencil fa-lg ',
    type: 'span',
  },

  update: {
    label: 'Update',
    name: 'update',
    action: 'editItem',
    classes: 'fa fa-pencil fa-lg ',
    type: 'span',
  },
  list: {
    label: 'List All',
    name: 'list',
    action: 'load',
  },
  search: {
    label: 'Search',
    name: 'search',
    action: 'search',
  },
  view: {
    label: 'View',
    name: 'view',
    action: 'getItem',
    classes: 'fa fa-eye fa-lg',
    type: 'span',
  },
  create: {
    label: 'Create',
    action: 'addNewItem',
    name: 'create',
    type: 'button',
  },
  syncGroup: {
    label: 'Sync with AAD',
    action: 'upsertItem',
    name: 'syncGroup',
    type: 'button',
    classes: 'fa fa-eye fa-lg btn btn-primary',
  },
};
const sectionsMeta_NON_MSP = {
  basic: {
    title: 'Create Group',
    items: [
      { name: 'aadTenantId' , type : 'field'},
      { name: 'customerName', type: 'field' },
      { name: 'aadGroupId', type: 'field' },
      { name: 'groupText', type: 'field' },
      { name: 'owners', type: 'field' },
      { name: 'members', type: 'field' },
    ],
    classes: 'group-form-basic',
  },
  detailScreen: {
    title: 'Edit Group',
    items: [
      { name: 'aadGroupIdReadOnly', type: 'field' },
      { name: 'groupText', type: 'field' },
      { name: 'customerNameEdit', type: 'field' },
      { name: 'owners', type: 'field' },
      { name: 'members', type: 'field' },
      { name: 'status', type: 'field' },
      { name: 'createdBy', type: 'field' },
      { name: 'updatedAt', type: 'field' },
    ],
    classes: 'group-form-update',
  },

  headerComponent: {
    component: 'DetailHeader',
  },
  groupDetailForm: {
    cols: [9, 3],
    items: [
      { name: 'detailScreen', type: 'section', col: 1 },
      { name: 'update', type: 'action', col: 2 },
      // { name: 'syncGroup', type: 'action', col: 2 },
    ],
    classes: 'composite-inner-form group-inner-form-detail',
  },

  groupCreateForm: {
    cols: [7, 5],
    items: [
      { name: 'basic', type: 'section', col: 1 },
      { name: 'addItem', type: 'action', col: 2 },
    ],
    classes: 'composite-inner-form group-inner-form-create',
  },
};
 

const screens_NON_MSP = {
  view: {
    items: [
      { name: 'headerComponent', type: 'section' },
      { name: 'groupDetailForm', type: 'section' },
    ],
    // classes: 'composite-form',
    title: 'Edit Group',
  },
  create: {
    items: [
      { name: 'headerComponent', type: 'section' },
      { name: 'groupCreateForm', type: 'section' },
    ],
  },
  edit: {
    items: [
      { name: 'headerComponent', type: 'section' },
      { name: 'groupDetailForm', type: 'section' },
    ],
  },
  list: {
    title: 'Groups',
    items: [
      { name: 'groupText', type: 'link' },
      { name: 'customerName', type: 'field' },
      { name: 'owners', type: 'field' },
      { name: 'members', type: 'field' },
      { name: 'status', type: 'field' },
    ],
    multiselect: false,
    orderBy: ['updatedAt desc']
    // actions: ['view', 'update']
  },
};
 

// const components = { searchList: EntityComponent} // can overrides the components like this if needed
const components = {  Detail: GroupDetailView, Create: GroupMainView} 
// @ts-ignore
const group = new GroupEntity({
  fieldsMeta : tenantCfg ? fieldsMeta_MSP : fieldsMeta_NON_MSP,
  actionsMeta : tenantCfg ? actionsMeta_MSP : actionsMeta_NON_MSP,
  sectionsMeta : tenantCfg ? sectionsMeta_MSP : sectionsMeta_NON_MSP,
  tabsMeta ,
  screens : tenantCfg ? screens_MSP : screens_NON_MSP,
  ActionClass: ContentActions,
  name: 'group',
  title: 'Manage Groups',
  methodSchemas,
  collectionSchemas,
  entitySchema,
  idKey: 'id',
  entityUrlPaths: {
    update: 'group/update',
    create: 'group',
    default: 'group',
  },
  fetchDetailByApi: true,
  components
});

const GroupListSearchContainer = group.getContainer('ListSearch');
const GroupCreateContainer = group.getContainer('Create');
const GroupDetailContainer = group.getContainer('Detail');
const GroupImportContainer = group.getContainer('ImportData');

export {
  GroupListSearchContainer,
  GroupCreateContainer,
  GroupDetailContainer,
  GroupImportContainer,
};
