import { EntityConstants } from '..';

/**
 * 
 * Convert this to a base class 
 */

// export class BaseReducer {

export const entity = (state = {}, action) => {
    // console.log('%%%%%%%%STATE IS ',state);
    // console.log('************* ACTIONS',action)
    // let type = action.type.slice(0,action.type.length- this.resourceName.length -1) 
    let entityValues
    switch (action.type) {
        case (EntityConstants.EXECUTEAPI_REQUEST + '_' + action.resourceName):
            return {
                ...state,
                loading: true,
                entityName: action.resourceName,
                error: null
            }
        case (EntityConstants.EXECUTEAPI_SUCCESS + '_' + action.resourceName):
            entityValues = state.entityValues
            if(entityValues && action.targetProp){
                entityValues[action.targetProp] = action.item
            } 
            return {
                ...state,
                entityValues,
                loading: false,
                entityName: action.resourceName,
                error: null
            }
        case (EntityConstants.EXECUTEAPI_FAILURE + '_' + action.resourceName):
            return {
                ...state,
                loading: false,
                entityName: action.resourceName,
                error: action.error
            }
        case (EntityConstants.ADDLINKED_REQUEST + '_' + action.resourceName):
            return {
                ...state,
                loading: true,
                entityName: action.resourceName,
                error: null
            }

        case (EntityConstants.ADDLINKED_SUCCESS + '_' + action.resourceName):
            return {
                ...state,
                loading: false,
                entityName: action.resourceName,
                [action.itemType + '_add']: action.item,
                error: null
            }
        case (EntityConstants.ADDLINKED_FAILURE + '_' + action.resourceName):
            return {
                ...state,
                loading: false,
                entityName: action.resourceName,
            }

        case (EntityConstants.REMOVELINKED_REQUEST + '_' + action.resourceName):
            return {
                ...state,
                loading: true,
                entityName: action.resourceName,
                error: null
            }
        case (EntityConstants.REMOVELINKED_SUCCESS + '_' + action.resourceName):
            return {
                ...state,
                loading: false,
                entityName: action.resourceName,
                error: null
            }
        case (EntityConstants.REMOVELINKED_FAILURE + '_' + action.resourceName):
            return {
                ...state,
                loading: false,
                entityName: action.resourceName
            }

        case (EntityConstants.EDITLINKED_REQUEST + '_' + action.resourceName):
            return {
                ...state,
                loading: true,
                entityName: action.resourceName,
                error: null
            }
        case (EntityConstants.EDITLINKED_SUCCESS + '_' + action.resourceName):
            return {
                ...state,
                loading: false,
                entityName: action.resourceName,
                [action.itemType + '_edit']: action.item,
                error: null
            }
        case (EntityConstants.EDITLINKED_FAILURE + '_' + action.resourceName):
            return {
                ...state,
                loading: false,
                entityName: action.resourceName,
                error: null
            }

        case (EntityConstants.GETALLCOUNT_REQUEST + '_' + action.resourceName):
            return {
                ...state,
                loading: true,
                entityName: action.resourceName,
                error: null
            }
        case (EntityConstants.GETALLCOUNT_SUCCESS + '_' + action.resourceName):
            return {
                ...state,
                loading: false,
                count: action.item,
                entityName: action.resourceName,
                error: null
            }
        case (EntityConstants.GETALLCOUNT_FAILURE + '_' + action.resourceName):
            return {
                ...state,
                loading: false,
                entityName: action.resourceName,
                error: null
            }

        case (EntityConstants.GETALLLINKED_REQUEST + '_' + action.resourceName):
            return {
                ...state,
                loading: true,
                entityName: action.resourceName,
                error: null
            }
        case (EntityConstants.GETALLLINKED_SUCCESS + '_' + action.resourceName):
            return {
                ...state,
                loading: false,
                [action.itemType]: action.item,
                entityName: action.resourceName,
                error: null
            }
        case (EntityConstants.GETALLLINKED_FAILURE + '_' + action.resourceName):
            return {
                ...state,
                loading: false,
                entityName: action.resourceName,
                error: null
            }

        case (EntityConstants.GETALLLINKEDCOUNT_REQUEST + '_' + action.resourceName):
            return {
                ...state,
                loading: true,
                entityName: action.resourceName,
                error: null
            }
        case (EntityConstants.GETALLLINKEDCOUNT_SUCCESS + '_' + action.resourceName):
            return {
                ...state,
                loading: false,
                [action.itemType + '_count']: action.item,
                entityName: action.resourceName,
                error: null
            }
        case (EntityConstants.GETALLLINKEDCOUNT_FAILURE + '_' + action.resourceName):
            return {
                ...state,
                loading: false,
                entityName: action.resourceName,
                error: null
            }

        case (EntityConstants.GETONE_REQUEST + '_' + action.resourceName):
            return {
                ...state,
                loading: true,
                entityName: action.resourceName,
                error: null
            }
        case (EntityConstants.GETONE_SUCCESS + '_' + action.resourceName):
            return {
                ...state,
                loading: false,
                entityValues: action.item,
                entityName: action.resourceName,
                error: null
            }
        case (EntityConstants.GETONE_FAILURE + '_' + action.resourceName):
            return {
                ...state,
                loading: false,
                entityName: action.resourceName,
                error: null
            }

        case (EntityConstants.EDIT_REQUEST + '_' + action.resourceName):
            return {
                ...state,
                loading: true,
                entityName: action.resourceName,
                error: null
            }
        case (EntityConstants.EDIT_SUCCESS + '_' + action.resourceName):
            if (action.item) {
                return {
                    ...state,
                    entityValues: action.item,
                    loading: false,
                    entityName: action.resourceName,
                    error: null
                }
            }
            break;
        case (EntityConstants.EDIT_FAILURE + '_' + action.resourceName):
            return {
                ...state,
                loading: false,
                entityName: action.resourceName,
                error: null
            }

        case (EntityConstants.DELETE_REQUEST + '_' + action.resourceName):
            return {
                ...state,
                loading: true,
                entityName: action.resourceName,
                error: null
            }
        case (EntityConstants.DELETE_SUCCESS + '_' + action.resourceName):
            if (action.item) {
                return {
                    ...state,
                    itemData:
                        state.itemData.filter(item => {
                            return item.id !== action.item[1][0].id
                        })
                    ,
                    loading: false,
                    entityName: action.resourceName,
                    error: null
                }
            }
            break;
        case (EntityConstants.LOADER_FALSE + '_' + action.resourceName):
            // if(action.item) {
            return {
                ...state,
                loading: false,
                entityName: action.resourceName
            }
        //} 
        case (EntityConstants.GETAUDITCONFIG_REQUEST + '_' + action.resourceName):
            return {
                ...state,
                loading: true,
                entityName: action.resourceName
            }
        case (EntityConstants.GETAUDITCONFIG_SUCCESS + '_' + action.resourceName):
            return {
                ...state,
                loading: false,
                auditConfig: action.item,
            }
        case (EntityConstants.GETAUDITCONFIG_FAILURE + '_' + action.resourceName):
            return {
                ...state,
                loading: false,
                entityName: action.resourceName
            }
        case (EntityConstants.GETREFITEMS_REQUEST + '_' + action.resourceName):
                return {
                    ...state,
                    loading: true,
                    entityName: action.resourceName
                }
        case (EntityConstants.GETREFITEMS_SUCCESS + '_' + action.resourceName):
                return {
                    ...state,
                    loading: false,
                    refEntityList:{
                        ...state.refEntityList,
                        [action.itemType]: action.item,
                    }
                }
        case (EntityConstants.GETREFITEMS_FAILURE + '_' + action.resourceName):
                return {
                    ...state,
                    loading: false,
                    entityName: action.resourceName
                }
        case (EntityConstants.ASSIGN_TO_STATE_REQUEST + '_' + action.resourceName):
            return {
                ...state,
                loading: true,
                entityName: action.resourceName,
                error: null
            }
        case (EntityConstants.ASSIGN_TO_STATE_SUCCESS + '_' + action.resourceName):
            return {
                ...state,
                loading: false,
                entityValues: action.item,
                entityName: action.resourceName,
                error: null
            }
        case (EntityConstants.ASSIGN_TO_STATE_FAILURE + '_' + action.resourceName):
            return {
                ...state,
                loading: false,
                entityName: action.resourceName,
                error: null
            }

        case (EntityConstants.GET_UI_FIELDS_META_SUCCESS + '_' + action.resourceName):
            return {
                ...state,
                loading: false,
                uiFieldsMeta: action.item,
            }
        case (EntityConstants.GET_UI_FIELDS_META_SUCCESS_REQUEST + '_' + action.resourceName):

            return {
                ...state,
                loading: true,
                entityName: action.resourceName
            }
        case (EntityConstants.GET_UI_FIELDS_META_SUCCESS_FAILURE + '_' + action.resourceName):
            return {
                ...state,
                loading: false,
                entityName: action.resourceName
            }
        default:
            return state
    }
}
// }
