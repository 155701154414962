import Joi from 'joi-browser'

const customerSchema = Joi.object().keys({
  id : Joi.string().uuid(),
  tenantId: Joi.string().uuid().required(),
  customerName: Joi.string().required(),
  contactDetails: Joi.object()
		.keys({
      name : Joi.string(),
			attributeMap: Joi.object()
				.pattern(/^/, Joi.object({ value: Joi.string(), required: Joi.boolean() }))
				.allow(null)
				.optional(),
		})
		.allow(null)
		.unknown(),
  emailDomain: Joi.array().items(Joi.string().allow(null)).min(0).allow(null).allow(''),
  aadTenantId: Joi.string().uuid().allow('', null).optional(),
  customerSince: Joi.string().required(),
  assignedAgent: Joi.array().min(1).items(Joi.string()).required(),
  status: Joi.string().valid( 'Published', 'Draft').required().default('Published'),
  customerHistory: Joi.array()
    .items({
      eventText: Joi.string(),
      type: Joi.string(),
      modifiedBy: Joi.string(),
      modifiedAt:  Joi.date(),
    })
    .allow(null),
  createdAt: Joi.date().allow(null),
  createdBy: Joi.string().allow(null),
  updatedAt: Joi.string().allow(null),
  updatedBy: Joi.string().allow(null),
  deletedSeq: Joi.number().allow(null)
})
export const methodSchemas = {
    getAll: Joi.array().items(customerSchema),
    getOne: customerSchema,
    addItem: customerSchema,
    deleteItem: Joi.object().keys({id: Joi.number().integer().positive()}),
    editItem: customerSchema
}
export const collectionSchemas = {
  //  notes: note
}
export const entitySchema = customerSchema