import { connect } from 'react-redux';
import { roleActions } from '../'
import {settingsActions} from '../../common'
import { bindActionCreators } from 'redux'
import RoleManagementComponent from '../components/RoleManagmentComponent'
const mapStateToProps = (state) => {
    const { identity, userRoles, globalSetting,ssoData } = state;
    const { profile } = identity
    return {
        profile,
        userRoles,
        globalSetting,
        identity,
        ssoData
    };
}

const mapDispatchToProps = (dispatch) => ({
    ...bindActionCreators(
        {
          ...roleActions,
          ...settingsActions
        },
        dispatch,
      ),
 })

 export default connect(mapStateToProps,mapDispatchToProps)(RoleManagementComponent);