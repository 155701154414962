import React from 'react'
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    //console.log(error, info);
  }

  render() {
    if (this.state.hasError) {
      // call api send mail to support team
      if (this.props.errMsg) {
        setTimeout(() => (window.location.href = "/tickets/my-tickets"), 3000);

        return (
          <h1>
            <b style={{ color: "red" }}>{this.props.errMsg}</b>
          </h1>
        );
      }
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}
export default ErrorBoundary