/**
 * RU on all settings including whitelisting
 * This is in common as all apps would need it
 * Setting Key value pairs come from db of the app.
 */
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import ClientComponent from './clients'
import { ClientActions } from './clients.action'
import { ClientsEntityService } from './clients.services'
/**
 * Show the current subscriptions...
 *
 * Able to change plans on current apps.
 *
 * Able to add new apps to my subscriptions with choice of plans to choose from.
 *
 * Not a standard crud...
 *
 *
 *
 */

let service = new ClientsEntityService()
let ActionObj = new ClientActions({ service })
const mapDispatchToProps = (dispatch, props) => {
	let fns = ActionObj.getFns()
	return {
		...bindActionCreators(
			{
				...fns,
			},
			dispatch
		),
	}
}
const mapStateToProps = (state, ownProps) => {
	const { identity, alert, tenant, realmClient } = state
	const profile = identity ? identity.profile : null
	let props = { profile, alert, tenant, realmClient }
	props.moduleName = 'Subscription'
	return props
}

export const ClientContainer = connect(mapStateToProps, mapDispatchToProps)(ClientComponent)
