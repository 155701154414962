import axios from 'axios'
// import {  } from '../../../config';
import { cacheAdapterEnhancer, throttleAdapterEnhancer } from 'axios-extensions'
import { identityServices, keycloakService, microsoftService } from '../'
let myTenant
export let keycloakInstances = {}
export const identityApi = axios.create({
	timeout: 90000,
	responseType: 'json',
	headers: { 'Cache-Control': 'no-cache', Pragma: 'no-cache' },
	adapter: throttleAdapterEnhancer(cacheAdapterEnhancer(axios.defaults.adapter, { enabledByDefault: false })),
})

// identityApi.interceptors.request.use(
//     (req) => {
//        // Add configurations here
//        console.log("Interceptor:: ", req)
//        let wsData = localStorage.getItem('workspace')
//         wsData = (wsData && wsData != undefined) ? JSON.parse(wsData) : null

//         if(wsData && wsData.enabled ) {

//         }

//         }

//        return req;
//     },
//     (err) => {
//        return Promise.reject(err);
//     }
//  );
export const getQueryParameters = () => {
	let queryParams = {}
	window.location &&
		window.location.search &&
		window.location.search
			.substr(1)
			.split('&')
			.forEach(function (item) {
				let s = item.split('='),
					k = s[0],
					v = s[1] && decodeURIComponent(s[1])
				queryParams[k] = v
			})
	return queryParams
}
export const handlerParent = event => {
	let data = event.data

	if (typeof data == 'string') data = JSON.parse(data)
	//console.log("Hello World? from service ", data['token'], typeof data, event)
	sessionStorage.setItem('ms_token', data.token)
	//window.removeEventListener("message", identityServices.handlerParent)
}
export const initiateIdentityService = (tenant, dispatch) => {
	myTenant = myTenant || tenant
	// seprate ms tenant check
	let queryParameters = identityServices.getQueryParameters()
	//console.log("Query params from service", queryParameters)
	if ((queryParameters && (queryParameters.isTeams == true || queryParameters.isTeams == 'true')) || sessionStorage.getItem('isTeams')) {
		sessionStorage.setItem('isTeams', true)
		//console.log("Inside insure athantication condition", queryParameters)
		window.addEventListener('message', identityServices.handlerParent)
		let msToken = sessionStorage.getItem('ms_token')
		if (!msToken) {
			sessionStorage.setItem('ms_token', queryParameters.token)
		}
		msToken = queryParameters.token
		if (msToken) microsoftService.msIdentityService(myTenant, msToken, queryParameters.appName, dispatch)
	} else {
		keycloakService.keycloakIdentityService(myTenant)
	}
	//end
	// if (!keycloakInstances[tenant.tenantId]) {
	//     keycloakInstances[tenant.tenantId] = new Keycloak({
	//         url: idpUrl,
	//         realm: tenant.tenantId,
	//         clientId: 'adminui-service'
	//     });
	// }
}

export const ensureAuthentication = async props => {
	let tenant = props.tenant || myTenant
	let queryParameters = identityServices.getQueryParameters()
	if ((queryParameters && (queryParameters.isTeams == true || queryParameters.isTeams == 'true')) || sessionStorage.getItem('isTeams')) {
		window.addEventListener('message', identityServices.handlerParent)
		microsoftService.msEnsureAuthentication(props)
	} else {
		// console.log("keycloakService ensureAuthentication ")
		keycloakService.keycloakEnsureAuthentication(props)
	}

	// const { dispatch, identity, tenant } = props

	// //const token = sessionStorage.getItem('keycloak_token');
	// //const refreshToken = sessionStorage.getItem('keycloak_refreshToken');
	// const isExpired = identity && identity.exp ? new Date((identity.exp + identity.timeSkew) * 1000) < new Date() : true
	// if (identity.token && identity.isAuthenticated && !isExpired) {
	//     //console.log("Already Logged into Actionable Science")
	//     // let token = sessionStorage.getItem("keycloak_token");
	//     // let tokenRefresh = sessionStorage.getItem("keycloak_refreshToken");
	//     //!identity.isAuthenticated &&
	//     keycloakInstances[tenant.tenantId].init({ adapter: 'default', token: identity.token })
	//     dispatch(identityActions.loginCheckSuccess())
	//     await setInterceptor(identity.token, tenant.id);
	//     // setTimeout(()=>dispatch(identityActions.setIdentity(keycloakInstances[tenant.tenantId])), 1000);

	// } else {
	//     console.log("SESSION EXPIRED : ", isExpired)
	//     // dispatch(identityActions.clearIdentity())
	//     dispatch(identityActions.initiateLogin());
	//     keycloakInstances[tenant.tenantId]
	//         .init({
	//             onLoad: 'login-required',
	//             flow: 'standard',
	//         })
	//         .success(async authenticated => {
	//             dispatch(identityActions.loginSuccess(keycloakInstances[tenant.tenantId].token))
	//             await setInterceptor(keycloakInstances[tenant.tenantId].token, tenant.id)
	//             setTimeout(() => dispatch(identityActions.setIdentity()), 1000);
	//             updateSession(tenant)
	//             if (!singleTenant) saveTenantId()
	//         })
	//         .error(err =>
	//             dispatch(identityActions.loginFailure()))

	// }
}

export const logoutService = tenant => {
	let redirectURL = `${window.location.protocol}//${window.location.host || window.location.hostname}/logout`
	if (keycloakInstances[tenant.tenantId]) {
		let keycloak = keycloakInstances[tenant.tenantId]
		// keycloak.logout({ redirectUri: redirectURL });
		delete keycloakInstances[tenant.tenantId]
		sessionStorage.clear()
		localStorage.clear()
		window.location.href = keycloak.createLogoutUrl({ redirectUri: redirectURL })
	}
}

export const updateSession = tenant => {
	sessionStorage.setItem('keycloak_token', keycloakInstances[tenant.tenantId].token)
	sessionStorage.setItem('keycloak_refreshToken', keycloakInstances[tenant.tenantId].refreshToken)
}

export const setInterceptor = async (token, tenantUid, profile, tenant, identity) => {
	let defaultTeam
	if ((profile && profile.defaultTeam && profile.defaultTeam.toLowerCase()) || (tenant.parentTenant && tenant.parentTenant.toLowerCase())) {
		defaultTeam = (profile && profile.defaultTeam && profile.defaultTeam.toLowerCase()) || (tenant.parentTenant && tenant.parentTenant.toLowerCase())
	}
	// const UNAUTHORIZED = 401
	identityApi.interceptors.response.use(
		response => response,
		error => {
			const status = error?.response?.status || null
			let isExpired, tokenDate
			tokenDate = (identity && identity.exp && new Date((identity.exp + (identity.timeSkew || 0.1)) * 1000)) || new Date()
			isExpired = tokenDate < new Date()
			if (isExpired && (status === 401 || status === 403)) {
				window.location.reload()
			}
			return Promise.reject(error)
		}
	)
	return identityApi.interceptors.request.use(config => {
		config.headers.common['Authorization'] = 'Bearer ' + token
		config.headers.common['x-tenantId'] = tenantUid
		config.headers.common['Content-Type'] = 'application/json'
		if (!config.headers.removeUseCase && (defaultTeam || localStorage.getItem('product'))) config.headers.common['usecase'] = localStorage.getItem('product') || defaultTeam
		// if (headerParams) {
		// 	let headerParamsConfig = JSON.parse(headerParams)
		// 	headerParamsConfig.forEach(p => {
		// 		config.headers.common[p.name] = p.value
		// 	})
		// }
		return config
	})
	// console.log("I WAS HIT !!! 1 ",identityApi.interceptors.request.use(config=> console.log(config.headers)))
}
export const setInterceptorMs = async (token, tenantUid, tokenData) => {
	identityApi.interceptors.response.use(
		response => response,
		error => {
			const { status } = error.response
			let isExpired, tokenDate
			tokenDate = (tokenData && tokenData.exp && new Date((tokenData.exp + (tokenData.timeSkew || 0.1)) * 1000)) || new Date()
			isExpired = tokenDate < new Date()
			if (isExpired && (status === 401 || status === 403)) {
				window.location.reload()
			}

			return Promise.reject(error)
		}
	)
	return identityApi.interceptors.request.use(config => {
		config.headers.common['msTokenSignedForUser'] = token
		config.headers.common['x-tenantId'] = tenantUid
		config.headers.common['Content-Type'] = 'application/json'
		// if (headerParams) {
		// 	let headerParamsConfig = JSON.parse(headerParams)
		// 	headerParamsConfig.forEach(p => {
		// 		config.headers.common[p.name] = p.value
		// 	})
		// }
		return config
	})
	// console.log("I WAS HIT !!! 1 ",identityApi.interceptors.request.use(config=> console.log(config.headers)))
}
export const saveTenantId = () => {
	let ep = myTenant.apiUrl
	let platform = 'Web'
	if (navigator.userAgent.match(/Android/i)) platform = 'Android'
	if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) platform = 'iOS'

	const requestOptions = {
		method: 'POST',
		data: { platform: platform },
		headers: { 'Content-Type': 'application/json' },
		url: `${ep}/botmgmt-service/botdef/saveUserData`,
	}
	return identityServices.identityApi.request(requestOptions).then(response => {
		if (response.status !== 200 && response.status !== 304) {
			return Promise.reject(response)
		}

		return response.data
	})
}

export const getUserRoleById = (tenantId, userId) => {
	const requestOptions = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
		},
		url: `${myTenant.apiUrl}/role/user-roles/${userId}?tenantId=${tenantId}`,
	}

	return identityServices.identityApi.request(requestOptions).then(response => {
		if (response.status !== 200 && response.status !== 304) {
			return Promise.reject(`Unable to fetch roles : Error code : ${response.status}`)
		}

		return response.data
	})
}

export const getRolesByTenant = tenantId => {
	const requestOptions = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
		},
		url: `${myTenant.apiUrl}/role/tenant-roles/${null}?tenantId=${tenantId}`,
	}

	return identityServices.identityApi.request(requestOptions).then(response => {
		if (response.status !== 200 && response.status !== 304) {
			return Promise.reject(`Unable to fetch the list of roles : Error code : ${response.status}`)
		}

		return response.data
	})
}

export const getTenantRoleById = (tenantId, roleId) => {
	const requestOptions = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
		},
		url: `${myTenant.apiUrl}/role/tenant-role/${roleId}?tenantId=${tenantId}`,
	}
	return identityServices.identityApi.request(requestOptions).then(response => {
		if (response.status !== 200 && response.status !== 304) {
			return Promise.reject(`Unable to fetch the list of roles : Error code : ${response.status}`)
		}

		return response.data
	})
}

// const setInterceptor = (tenant) => {
//     console.log("I WAS HIT !!! 1 ")
//     identityApi.interceptors.request.use(config => {
//         console.log("I WAS HIT !!! 2 ",keycloakInstances)
//         keycloakInstances[tenant.tenantId].logout().updateToken(5)
//         .then(refreshed => {

//             if (refreshed) {
//             updateSession(keycloakInstances[tenant.tenantId].logout())
//             }
//             config.headers.Authorization = 'Bearer ' + keycloakInstances[tenant.tenantId].logout().token;
//             config.headers['Content-Type']  = 'application/json'

//             return Promise.resolve(config)
//         })
//         .catch(() => {
//             keycloakInstances[tenant.tenantId].logout().login();
//         })
//     })
// }
