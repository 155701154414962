import { Grid, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { withTranslation } from "react-i18next";

const styles = theme => ({
  formControl: {
    margin: theme.spacing.unit,
    minWidth: theme.wid,
    maxWidth: 300,
  },
});


const CustomizedForm = ({
    data,
    errors,
    headers,
    handleChange,
    classes,
    t
  }) => (
      <Grid container spacing={24}>
      
      {headers.map((item, index) => (
        <Grid key={`grid-${index}`} item xs={12}>
          <TextField
          key={`txt-${index}`}
          required={item.required}
          onChange={handleChange}
          value={data[item.prop]}
          id={item.prop}
          type={item.type}
          name={item.prop}
          label={t(item.name)}
          fullWidth
          autoComplete={item.prop}
          variant="outlined"
          error={typeof errors[item.prop] !== 'undefined'}
          helperText={typeof errors[item.prop] !== 'undefined' ? errors[item.prop] : ""}
        /> 
         </Grid>
       ))}
       </Grid>
    );

const App = withStyles(styles, { withTheme: true })(CustomizedForm);
export default withTranslation()(App);