import React, { Component } from 'react';

import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import ascTheme from '@asc/ui-theme';


import './App.css';
// Styles
// CoreUI Icons Set
import '@coreui/icons/css/coreui-icons.min.css';
// Import Flag Icons Set
import 'flag-icon-css/css/flag-icon.min.css';
// Import Font Awesome Icons Set
import 'font-awesome/css/font-awesome.min.css';
// Import Simple Line Icons Set
import 'simple-line-icons/css/simple-line-icons.css';
import './app/common/css/_custom.css'
import './app/common/css/style.css'


// Containers
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/lib/integration/react'
import configureStore from './redux/store'
import { Root } from './app/common'
import { routeList } from './redux/routes'
import './assets/scss/react-select.css'
import './assets/scss/main-app.css'

let { store, persistor } = configureStore()
const theme = extendTheme(ascTheme);
class App extends Component {
  render () {
    return (
      <ChakraProvider theme={theme}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <div className='App'>
              <Root routeList={ routeList }/>
            </div>
          </PersistGate>
        </Provider>
      </ChakraProvider>
    )
  }
}

export default App