import { Chip, FormControl, Grid, Input, InputLabel, MenuItem, Select, TextField } from '@material-ui/core'
// import { Clear as CancelIcon, Save as SaveIcon } from '@material-ui/icons'
import { green, red } from '@material-ui/core/colors'
import { withStyles } from '@material-ui/core/styles'
import React from 'react'
import { withTranslation } from 'react-i18next'
const styles = theme => ({
	formControl: {
		margin: theme.spacing.unit,
		minWidth: theme.wid,
		maxWidth: 300,
	},
	roles: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	role: {
		margin: theme.spacing.unit / 4,
	},
	fabSave: {
		color: theme.palette.common.white,
		backgroundColor: green[500],
		float: 'right',
		margin: theme.spacing.unit / 2,
	},
	fabCancel: {
		color: theme.palette.common.white,
		backgroundColor: red[500],
		float: 'right',
		margin: theme.spacing.unit / 2,
	},
	errorColor: {
		color: red[500],
	},
})

const emailRegx =
	/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

class AddUserForm extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			roles: [],
			username: '',
			email: '',
			firstName: '',
			lastName: '',
			isEmailValid: true,
			touched: false,
			requiredActions: ['UPDATE_PASSWORD'],
			userAttributeKey: '',
			selectedAttr: null,
			userAttributeValues: [],
			userAttributeValue: '',
			selectedKeyValAttributes: {},
			reqAttr: [],
		}
		this.handleSaveUser = this.handleSaveUser.bind(this)
	}

	handleChange = evt => {
		this.setState({
			[evt.target.name]: evt.target.value,
		})
	}

	handleUserAttrChange = evt => {
		let { userAttributes } = this.props

		let selectedAttr = userAttributes.find(o => o.key == evt.target.value)

		this.setState({
			[evt.target.name]: evt.target.value,
			selectedAttr,
		})
	}

	removeAttr(key) {
		let { selectedKeyValAttributes } = this.state
		delete selectedKeyValAttributes[key]

		this.setState({ selectedKeyValAttributes })
	}

	addSelectedAttributes = () => {
		let { userAttributeKey, selectedAttr, userAttributeValues, userAttributeValue, selectedKeyValAttributes } = this.state

		if (userAttributeValues && userAttributeValues.length > 0) {
			selectedKeyValAttributes[userAttributeKey] = userAttributeValues
		}

		if (userAttributeValue && userAttributeValue.trim() != '') {
			selectedKeyValAttributes[userAttributeKey] = []
			selectedKeyValAttributes[userAttributeKey].push(userAttributeValue)
		}

		this.setState({
			selectedKeyValAttributes,
			userAttributeKey: '',
			selectedAttr: null,
			userAttributeValues: [],
			userAttributeValue: '',
		})
	}

	handleSaveUser() {
		this.setState({
			touched: true,
		})
		const errorKeys = ['username', 'email', 'firstName', 'lastName']
		let error = false,
			userData = this.state,
			{ userAttributes } = this.props
		userData.reqAttr = []
		Object.keys(this.state).forEach(key => {
			if (errorKeys.indexOf(key) !== -1 && this.state[key].trim() === '') error = true
		})
		let isEmailIdValid = emailRegx.test(this.state.email)
		if (!isEmailIdValid) {
			this.setState({ isEmailValid: false })
			return true
		} else this.setState({ isEmailValid: true })

		if (error) return true
		userData.attributes = {}
		if (userData && userData.selectedKeyValAttributes && Object.keys(userData.selectedKeyValAttributes).length > 0) {
			Object.keys(userData.selectedKeyValAttributes).forEach(elem => {
				userData.attributes[elem] = []
				userData.selectedKeyValAttributes[elem].forEach(attr => {
					userData.attributes[elem].push(attr)
				})
			})
		}

		userAttributes.forEach(elem => {
			if (elem.validationRule.required == 'Yes' && elem.status === 'Active') {
				if (!userData.attributes.hasOwnProperty(elem.key)) {
					userData.reqAttr.push(`${elem.label} attribute is required`)
				}
			}
		})

		if (userData.reqAttr && userData.reqAttr.length > 0) {
			return false
		}

		userData['enabled'] = true
		this.props.saveNewUser(userData, this.props.t)
		this.setState({
			touched: false,
		})
	}

	handleEmailValidation() {
		let isEmailIdValid = this.state.email && emailRegx.test(this.state.email)
		if (!isEmailIdValid) {
			this.setState({ isEmailValid: false })
			return true
		} else this.setState({ isEmailValid: true })
	}

	render() {
		const { classes, selectedRoles, userAttributes, t } = this.props
		let { selectedAttr, selectedKeyValAttributes, reqAttr } = this.state

		return (
			<Grid container spacing={24}>
				<Grid item xs={12}>
					<TextField
						required
						error={this.state.touched && this.state.username.trim() === '' ? true : false}
						onChange={this.handleChange}
						value={this.state['username']}
						id="username"
						name="username"
						label={t('Username')}
						fullWidth
						autoComplete="username"
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						required
						error={this.state.touched && this.state.email.trim() === '' ? true : false}
						onChange={this.handleChange}
						value={this.state['email']}
						id="email"
						name="email"
						label={t('Email')}
						fullWidth
						autoComplete="email"
						onBlur={this.handleEmailValidation.bind(this)}
					/>
					{this.state.email && !this.state.isEmailValid && <span className={classes.errorColor}>{t('Please enter valid email')}</span>}
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField
						required
						error={this.state.touched && this.state.firstName.trim() === '' ? true : false}
						onChange={this.handleChange}
						value={this.state['firstName']}
						id="firstName"
						name="firstName"
						label={t('First name')}
						fullWidth
						autoComplete="fname"
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField
						required
						error={this.state.touched && this.state.firstName.trim() === '' ? true : false}
						onChange={this.handleChange}
						value={this.state['lastName']}
						id="lastName"
						name="lastName"
						label={t('Last name')}
						fullWidth
						autoComplete="lname"
					/>
				</Grid>
				<Grid item xs={12}>
					<FormControl fullWidth>
						<InputLabel htmlFor="select-multiple-role">{t('Roles')}</InputLabel>
						<Select
							multiple
							value={this.state.roles}
							onChange={this.handleChange}
							input={<Input name="roles" id="select-multiple-role" />}
							renderValue={selected => (
								<div className={classes.roles}>{selected && selected.map(value => <Chip key={value} label={value} className={classes.roles} />)}</div>
							)}>
							{selectedRoles &&
								selectedRoles.map(role => (
									<MenuItem key={role.name} value={role.name}>
										{role.name}
									</MenuItem>
								))}
						</Select>
					</FormControl>
				</Grid>

				<Grid item xs={12}>
					<FormControl fullWidth>
						<InputLabel htmlFor="select-multiple-role">{t('User Attributes')}</InputLabel>
						<Select value={this.state.userAttributeKey} onChange={this.handleUserAttrChange} input={<Input name="userAttributeKey" id="select-user-attr" />}>
							{userAttributes &&
								userAttributes.map(attr => {
									if (attr.status == 'Active') {
										return (
											<MenuItem key={attr.key} value={attr.key}>
												{attr.label}
											</MenuItem>
										)
									}
								})}
						</Select>
						{reqAttr &&
							reqAttr.length > 0 &&
							reqAttr.map((elem, i) => (
								<span key={i} className={classes.errorColor}>
									{elem}
								</span>
							))}
					</FormControl>
				</Grid>

				{selectedAttr && selectedAttr.isMultivalue == 'Yes' && (!selectedAttr.validationRule || selectedAttr.validationRule.field == 'Predefined List') && (
					<Grid item xs={12}>
						<FormControl fullWidth className="fc-split">
							<InputLabel htmlFor="select-multiple-role">{t('Attribute Values')}</InputLabel>
							<Select
								multiple
								value={this.state.userAttributeValues}
								onChange={this.handleChange}
								input={<Input name="userAttributeValues" id="select-user-attr-values" />}
								renderValue={selected => (
									<div className={classes.roles}>{selected && selected.map(value => <Chip key={value} label={value} className={classes.roles} />)}</div>
								)}>
								{selectedAttr &&
									selectedAttr.valueLabels &&
									selectedAttr.valueLabels.map(attr => (
										<MenuItem key={attr} value={attr}>
											{attr}
										</MenuItem>
									))}
							</Select>
						</FormControl>
						<span className="for-add-field" onClick={this.addSelectedAttributes}>
							<i className="fa fa-plus y"></i>
						</span>
					</Grid>
				)}

				{selectedAttr && selectedAttr.isMultivalue == 'No' && (!selectedAttr.validationRule || selectedAttr.validationRule.field == 'Predefined List') && (
					<Grid item xs={12}>
						<FormControl fullWidth className="fc-split">
							<InputLabel htmlFor="select-multiple-role">{t('Attribute Value')}</InputLabel>
							<Select
								value={this.state.userAttributeValue}
								onChange={this.handleChange}
								input={<Input name="userAttributeValue" id="select-user-attr-value" />}>
								{selectedAttr &&
									selectedAttr.valueLabels &&
									selectedAttr.valueLabels.map(attr => (
										<MenuItem key={attr} value={attr}>
											{attr}
										</MenuItem>
									))}
							</Select>
						</FormControl>
						<span className="for-add-field" onClick={this.addSelectedAttributes}>
							<i className="fa fa-plus y"></i>
						</span>
					</Grid>
				)}

				{selectedAttr && selectedAttr.validationRule && (selectedAttr.validationRule.field == 'Free Text' || selectedAttr.validationRule.field == 'number') && (
					<Grid item xs={12}>
						<FormControl fullWidth className="fc-split">
							<TextField
								required
								onChange={this.handleChange}
								value={this.state.userAttributeValue}
								id="userAttributeValueTxt"
								name="userAttributeValue"
								label={t('User Attribute Value')}
								type={selectedAttr.validationRule.field == 'Free Text' ? 'text' : selectedAttr.validationRule.field}
								fullWidth
								// onBlur={this.handleEmailValidation.bind(this)}
							/>
							{/* { 
                  this.state.email 
                  && !this.state.isEmailValid 
                  &&  <span className={classes.errorColor}  >
                  {t('Please enter valid email')} 
                  </span>} */}
						</FormControl>
						<span className="for-add-field" onClick={this.addSelectedAttributes}>
							<i className="fa fa-plus y"></i>
						</span>
					</Grid>
				)}

				{selectedKeyValAttributes && Object.keys(selectedKeyValAttributes).length > 0 && (
					<Grid item xs={12} className="for-md-desing attr-show-multi">
						{Object.keys(selectedKeyValAttributes).map((elem, i) => {
							let keyLabel = elem
							userAttributes.forEach(labelTxt => {
								if (labelTxt.key == elem) {
									keyLabel = labelTxt.label
								}
							})

							return (
								<span key={i}>
									{keyLabel + ': ' + selectedKeyValAttributes[elem].join(', ')}
									<span className="close-tag">
										<i className="fa fa-close" onClick={this.removeAttr.bind(this, elem)}></i>
									</span>
								</span>
							)
						})}
					</Grid>
				)}

				<Grid item xs={12} className="for-md-desing">
					<button type="button" className="btn btn-secondary" onClick={this.props.toggleModal}>
						<img src="assets/img/no.png" alt="no" />
						{t('Close')}
					</button>
					<button type="submit" className="btn btn-primary" onClick={e => this.handleSaveUser()}>
						<img src="assets/img/yes.png" alt="yes" />
						{t('Save')}
					</button>
					{/* <Fab aria-label="Save" className={classes.fabSave}>
          <SaveIcon  />
        </Fab>
        <Fab aria-label="Cancel" className={classes.fabCancel}>
          <CancelIcon  />
        </Fab> */}
				</Grid>
			</Grid>
		)
	}
}
export default withTranslation()(withStyles(styles, { withTheme: true })(AddUserForm))
