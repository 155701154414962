import Joi from 'joi-browser'

const tenantMgmt = Joi.object().keys({
  id: Joi.string().required(),
  rootEmailId: Joi.string().required(),
  companyName : Joi.string().required(),
  status: Joi.string().valid('ACTIVE', 'INACTIVE'),
  userAuthReqd : Joi.boolean(),
  plan: Joi.string().valid('Free', 'Premium'),
  createdBy:Joi.string().allow(null),
  leadId : Joi.string().required(),
  createdAt : Joi.date(),
  parentTenantId: Joi.string().allow(null)
})

const tenantMgmtCreate = Joi.object().keys({
  domain: Joi.string().required(),
  companyName : Joi.string().required(),
  firstName: Joi.string().required(),
  lastName: Joi.string().required(),
  rootEmailId: Joi.string().required(),
  password: Joi.string().required(),
  leadId : Joi.string().required(),
  aadId : Joi.string(),
  tenantId : Joi.string(),
  userAuthReqd : Joi.string(),
  parentTenantId: Joi.string().allow(null)
})

const tenantMgmtUpdate = Joi.object().keys({
  id: Joi.string(),
  parentTenantId: Joi.string().allow(null),
  companyName : Joi.string(),
  userAuthReqd: Joi.boolean().required(),
  leadId : Joi.string(),
  createdBy:Joi.string().allow(null),
  createdAt:Joi.date().allow(null),
  rootEmailId: Joi.string(),
  status: Joi.string().valid('ACTIVE', 'INACTIVE'),
  plan: Joi.string().valid('Free', 'Premium')
})

// These are the schemas of the return object from the api server
export const methodSchemas = {
    getAll: Joi.array().items(tenantMgmt),
    getOne: tenantMgmtUpdate,
    addItem: tenantMgmtCreate,
    editItem: tenantMgmtUpdate
}
export const collectionSchemas = {
  //  notes: note
}
export const entitySchema = tenantMgmt