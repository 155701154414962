import React, { Component } from 'react';
// import {
//   ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText,
//   Card, CardBody, Button, CardTitle, CardText, CardImg
// } from 'reactstrap';

import { withTranslation } from 'react-i18next';

class Errors extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }


  }
  
  


  render() {
    const { data } = this.props;
    // const t = this.props.t

    return (
      <div className="d-block w-100 bg-lgrey p-2 border rounded shadow" style={{height:'250px', 'overflowY':'auto'}}>
            {
              data && data.pop && data.map( (e,idx) => {
                return <p className="small" key={idx}> {e} </p>
              })
            }

      </div>
    )
  }
}

export default withTranslation()(Errors)