import React, { Suspense } from 'react'
import { withTranslation } from 'react-i18next'
import { Col, Row } from 'reactstrap'
import * as DashboardComponents from '../'
import { TabDetail } from '../../base/entity/components'
import { Action, CardDetail, ChartDetail, FieldFilter, FormDetail, ListTable, ReportTable } from './'
class SectionBoard extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			meta: props.meta,
			envityValues: props.entityValues,
			name: props.name,
			idKey: props.meta && props.meta.idKey,
			itemId: props.params && props.params.itemId,
		}
		this.handleChange.bind(this)
	}
	componentWillReceiveProps(props) {
		const { meta } = this.props
		// const { sections } = meta
		// const itemsByCols = {}
		// const secDef = sections[name]

		this.setState({ meta: props.meta, filterValues: props.filterValues, idKey: props.meta.idKey, itemId: props.params && props.params.itemId })
	}
	handleChange(...args) {
		this.props.handleChange(args)
	}
	handleSelection(...args) {
		this.props.handleSelection(args)
	}
	renderItem(item) {
		if (!item) return
		const { charts, sections, cards, lists, fields, tabs, actions, treeTables } = this.props.meta
		const { data, filterValues } = this.props
		// const {  filterValues } =  this.props
		// const title = (dashboard && dashboard.title) || ((this.props.meta.name || this.props.moduleName) + ' Dashboard')
		let itemDef
		let dependsOn
		switch (item.type) {
			case 'section':
				itemDef = sections[item.name]
				if (itemDef)
					return (
						<Row className="my-1" key={item.name}>
							<SectionBoard {...this.props} key={item.name} name={item.name} />
						</Row>
					)
				break
			case 'form':
				return <FormDetail {...this.props} formValues={filterValues} />

			case 'field':
				itemDef = fields[item.name]
				if (itemDef) {
					if (itemDef.dependsOnFields && itemDef.dependsOnFields.pop && filterValues) {
						dependsOn = {}
						itemDef.dependsOnFields.forEach(f => {
							dependsOn[f] = filterValues[f]
						})
					}
					return (
						<React.Fragment key={item.name}>
							<FieldFilter {...this.props} key={item.name} name={item.name} dependsOn={dependsOn} field={itemDef} formValues={filterValues} />
						</React.Fragment>
					)
				}
				break
			// case 'tabGroup':
			//     itemDef = tabs[item.name]
			//     if (itemDef)
			//         return (
			//             <React.Fragment key={item.name}>
			//                 <TabDetail key={item.name} {...this.props} tabGroupItem={itemDef} sections={sections} data={data} filterValues={filterValues} />
			//             </React.Fragment>
			//         )
			//     break
			case 'card':
				itemDef = cards[item.name]
				if (itemDef) {
					if (itemDef.dependsOnFields && itemDef.dependsOnFields.pop && filterValues) {
						dependsOn = {}
						itemDef.dependsOnFields.forEach(f => {
							dependsOn[f] = filterValues[f]
						})
					}
					return (
						<div key={item.name}>
							<CardDetail
								{...this.props}
								key={item.name}
								data={data}
								dependsOn={dependsOn}
								name={item.name}
								card={itemDef}
								filterValues={filterValues}
								handleChange={this.handleChange.bind(this)}
							/>
						</div>
					)
				}
				break
			case 'chart':
				itemDef = charts[item.name]
				if (itemDef) {
					if (itemDef.dependsOnFields && itemDef.dependsOnFields.pop && filterValues) {
						dependsOn = {}
						itemDef.dependsOnFields.forEach(f => {
							dependsOn[f] = filterValues[f]
						})
					}
					return (
						<div key={item.name}>
							<ChartDetail
								chart={itemDef}
								{...this.props}
								data={data}
								key={item.name}
								dependsOn={dependsOn}
								name={item.name}
								filterValues={filterValues}
								handleChange={this.handleChange.bind(this)}
							/>
						</div>
					)
				}
				break
			case 'action':
				itemDef = actions[item.name]
				if (itemDef) return <Action key={item.name} actionMeta={itemDef} {...this.props} />
				break
			case 'tabGroup':
				itemDef = tabs[item.name]
				if (itemDef)
					return (
						<React.Fragment key={item.name}>
							<TabDetail key={item.name} {...this.props} tabGroupItem={itemDef} sections={sections} filterValues={filterValues} />
						</React.Fragment>
					)
				break
			case 'list':
				itemDef = lists[item.name]
				if (itemDef) {
					if (itemDef.dependsOnFields && itemDef.dependsOnFields.pop && filterValues) {
						dependsOn = {}
						itemDef.dependsOnFields.forEach(f => {
							dependsOn[f] = filterValues[f]
						})
					}
					return (
						<div key={item.name}>
							<ListTable
								list={itemDef}
								{...this.props}
								data={data}
								key={item.name}
								dependsOn={dependsOn}
								name={item.name}
								filterValues={filterValues}
								handleChange={this.handleChange}
							/>
						</div>
					)
				}
				break
			case 'reportTable':
				itemDef = treeTables[item.name]
				if (itemDef) {
					if (itemDef.dependsOnFields && itemDef.dependsOnFields.pop && filterValues) {
						dependsOn = {}
						itemDef.dependsOnFields.forEach(f => {
							dependsOn[f] = filterValues[f]
						})
					}
					return (
						<div key={item.name}>
							<ReportTable
								treeTable={itemDef}
								{...this.props}
								data={data}
								key={item.name}
								dependsOn={dependsOn}
								name={item.name}
								filterValues={filterValues}
								handleChange={this.handleChange}
							/>
						</div>
					)
				}
				break
			default:
		}
	}
	render() {
		const { filterValues, meta, name, t } = this.props
		const { fields, sections } = meta
		const itemsByCols = {}
		const secDef = sections[name]
		// let setPageFilters = false
		let pageFilters = { ...filterValues }
		secDef &&
			secDef.cols &&
			secDef.items.forEach(i => {
				if (i.type === 'field' && fields[i.name].value && !(filterValues && filterValues[i.name])) {
					pageFilters[i.name] = fields[i.name].value.value || fields[i.name].value.label || fields[i.name].value
					// setPageFilters = true
				}
				if (i.col) {
					itemsByCols[i.col] = itemsByCols[i.col] || []
					itemsByCols[i.col].push(i)
				} else {
					itemsByCols.unassigned = itemsByCols.unassigned || []
					itemsByCols.unassigned.push(i)
				}
			})
		// if(setPageFilters) this.props.setPageFilters(pageFilters)
		itemsByCols.unassigned = itemsByCols.unassigned || (secDef && !secDef.cols && secDef.items)
		let Component
		let compProps = {}
		if (secDef) {
			// let cPath = path.join('./../../../..' , (secDef.path||'') ,  secDef.component)
			// path = './test/DetailHeader'
			// console.error(`Components: ${Object.keys(EntityComponents)}`)

			Component = DashboardComponents[secDef.component]

			if (secDef.props) {
				Object.keys(secDef.props).forEach(k => {
					if (typeof secDef.props[k] === 'string') compProps[k] = filterValues && filterValues[secDef.props[k]]
				})
			}
			if (secDef.loadList && this.state.linkedLists) {
				// the lists that are attached to this component and loaded here.
				compProps[secDef.loadList.targetProp] = this.state.linkedLists[secDef.loadList.targetProp]
			}
		}

		if (secDef && secDef.cols && itemsByCols.unassigned) {
			console.error(`Some elements are not assigned to columns ${itemsByCols.unassigned}`)
			return <div></div>
		} else
			return (
				<Suspense fallback={<h2> {(t && t('Loading')) || 'Loading...'}</h2>}>
					{secDef && secDef.cols && (
						<>
							{/* {secDef.title && 
                             <div className=" text-center small">{secDef.title}:</div>
                            } */}
							{secDef.cols.map((c, i, arr) => {
								return (
									<Col sm={c} key={i + 1}>
										{itemsByCols[i + 1] &&
											itemsByCols[i + 1].map(item => {
												return this.renderItem(item)
											})}
									</Col>
								)
							})}
						</>
					)}
					{itemsByCols &&
						itemsByCols.unassigned &&
						itemsByCols.unassigned.map(item => {
							return (
								<Col sm={12} key={item.name}>
									{this.renderItem(item)}
								</Col>
							)
						})}
					{
						// component
						Component && <Component {...this.props} {...compProps} propsMap={secDef.otherProps} />
					}
				</Suspense>
			)
	}
}
export default withTranslation()(SectionBoard)
