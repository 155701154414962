import React, { Component } from "react";
import { connect } from 'react-redux';
import { alertActions } from '../'
import { withTranslation } from 'react-i18next';
class ErrorPage extends Component {

    componentWillUnmount() {
        this.props.alertClear()
    }

    render() {

        const { alert } = this.props
        const t = this.props.t
        let errorMsg = alert.type === 'alert-danger' && alert.message ?
            <p><span> {t(alert.message.split("|")[1]) || t('Oooops ...')} </span>  {t(alert.message.split("|")[0]) || t('Something went Wrong !')}</p>  :
            <p><span>{t('error 404 !')}</span> {t('sorry page you are looking for can not be found')} </p>

        return (
            <div className="notfound">
                <div className="box">
                    <div>
                        <a className="button" href="/ ">{t('Close')}</a>
                    </div>
                    {errorMsg}
                </div>

            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { alert } = state;
    return {
        alert,
    };
}

const mapDispatchToProps = (dispatch) => ({
    alertClear: () => dispatch(alertActions.clear())
})
const MyComponent = withTranslation()(ErrorPage)
export default connect(mapStateToProps, mapDispatchToProps)(MyComponent);