export const MODIFY_CHANGE_PASSWORD_REQUEST = 'MODIFY_CHANGE_PASSWORD_REQUEST';
export const MODIFY_CHANGE_PASSWORD_SUCCESS = 'MODIFY_CHANGE_PASSWORD_SUCCESS';
export const MODIFY_CHANGE_PASSWORD_FAILURE = 'MODIFY_CHANGE_PASSWORD_FAILURE';

export const MODIFY_USERS_REQUEST = 'MODIFY_USERS_REQUEST';
export const MODIFY_USERS_SUCCESS = 'MODIFY_USERS_SUCCESS';
export const MODIFY_USERS_FAILURE = 'MODIFY_USERS_FAILURE';

export const GET_USER_REQUEST = 'GET_USER_REQUEST';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'GET_USER_FAILURE';
