import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid, List, ListItem, ListItemText,Divider } from '@material-ui/core';
import { withTranslation } from "react-i18next";


const styles = theme => ({
  root: {
    width: '100%',
  },
});


const CustomizedOutputForm = ({
    data,
    headers,
    classes,
    t
  }) => (
      <Grid container spacing={24}>
      <List className={classes.root}>
      {Object.keys(data).map((key) => (
        <Grid item xs={12}  key={`grid-${key}`}>
          
          <ListItem  key={`li-${key}`}>
            <ListItemText primary={headers.filter(value=> value.prop === key ).map(value => t(value.name))} secondary={data[key]} />
          </ListItem>
          <Divider component="li" />
         
         </Grid>
       ))} 
       </List>
       </Grid>
    );

const App = withStyles(styles)(CustomizedOutputForm);
export default withTranslation()(App);