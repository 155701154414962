import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Route } from 'react-router-dom'
import { compose } from 'redux'
import { alertActions, identityActions } from '../'
import { localTest } from '../../../config'
class PrivateRouteCustom extends Component {
	componentWillMount() {
		const {
			tenant: { publicRoutes },
			path,
			identity,
		} = this.props
		if (
			(identity && identity.isAuthenticated) ||
			!publicRoutes ||
			(publicRoutes && publicRoutes.length === 0) ||
			(publicRoutes && publicRoutes.length > 0 && publicRoutes[0] !== path)
		)
			if (!localTest && identity && !identity.isInitiated) this.props.checkIdentity(this.props.t)
	}

	render() {
		const { tenant, identity, allowedRole, path, exact, component: Component } = this.props
		const { publicRoutes } = tenant

		if (tenant.loading && !identity.isAuthenticated && identity.isInititated) return <div className="loader"></div>

		if (identity.roles && identity.roles.some(role => allowedRole && allowedRole.pop && allowedRole.includes(role)))
			return <Route path={path} exact={exact} render={() => <Component />} />
		else if (publicRoutes && publicRoutes.length > 0) return <Route path={path} exact={exact} render={() => <Component />} />
		return <div />
	}
}

const mapStateToProps = state => {
	const { identity, tenant } = state
	return {
		identity,
		tenant,
	}
}

const mapDispatchToProps = dispatch => ({
	checkIdentity: () => dispatch(identityActions.checkIdentity()),
	alertError: msg => dispatch(alertActions.error(msg)),
})

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(PrivateRouteCustom)
