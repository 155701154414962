import { Axis, Chart, Coord, Geom, Label, Tooltip } from 'bizcharts'
import React from 'react'

class Ring extends React.Component {
	constructor(props) {
		super(props)
		this.state = { loading: true }
	}

	render() {
		let { t } = this.props
		const loading = this.state && this.state.loading
		if (loading) {
			this.setState({ loading: false })
			return <div>{(t && t('Loading')) || 'Loading...'}</div>
		}
		const data = [
			{ genre: 'Sports', sold: 275, income: 2300 },
			{ genre: 'Strategy', sold: 115, income: 667 },
			{ genre: 'Action', sold: 120, income: 982 },
			{ genre: 'Shooter', sold: 350, income: 5271 },
			{ genre: 'Other', sold: 150, income: 3710 },
			{ genre: 'Other1', sold: 150, income: 3710 },
			{ genre: 'Other2', sold: 150, income: 3710 },
			{ genre: 'Other3', sold: 150, income: 3710 },
		]

		const cols = {
			sold: { alias: 'Sold' },
			genre: { alias: 'Genre' },
		}
		const axisTitle = { textStyle: { fontSize: 15, fontWeight: 'Bolder', fill: '#CCC' } }
		return (
			<div>
				<Chart forceFit width={500} height={300} data={data} scale={cols} className="border" padding={[80, 100, 80, 80]}>
					{/* <Axis name="genre" title={axisTitle} /> */}
					<Coord type={'theta'} radius={1} innerRadius={0.6} />
					<Axis name="sold" title={axisTitle} />
					{/* <Legend position="top" dy={-20} /> */}
					<Tooltip />
					<Geom type="intervalStack" position="sold" color="genre">
						<Label
							content="sold"
							formatter={(val, item) => {
								return item._origin.genre + ': ' + val
							}}
						/>
					</Geom>
				</Chart>
			</div>
		)
	}
	// render() {
	//     const dimensions  = this.state && this.state.dimensions;

	//     return (
	//         <div ref={el => (this.container = el)}>
	//             {dimensions && this.renderContent()}
	//         </div>
	//     );
	// }
}
export default Ring
