import Keycloak from 'keycloak-js'
import { identityActions, identityServices } from '..'
import { keycloakInstances, setInterceptor, updateSession } from './identity.services'

export const keycloakIdentityService = tenant => {
	if (!keycloakInstances[tenant.tenantId]) {
		if (!tenant || (!tenant.idpUrl && !process.env.REACT_APP_IDP_URL)) throw `Error. Tenant not obtained with idpUrl ${JSON.stringify(tenant)}`
		// @ts-ignore
		keycloakInstances[tenant.tenantId] = new Keycloak({
			url: tenant.idpUrl || process.env.REACT_APP_IDP_URL,
			realm: tenant.tenantId,
			clientId: 'adminui-service',
		})
	}
}

export const keycloakEnsureAuthentication = async props => {
	const { dispatch, identity, tenant } = props

	//const token = sessionStorage.getItem('keycloak_token');
	//const refreshToken = sessionStorage.getItem('keycloak_refreshToken');
	const isExpired = identity && identity.exp ? new Date((identity.exp + identity.timeSkew) * 1000) < new Date() : true
	if (identity.token && identity.isAuthenticated && !isExpired) {
		//console.log("Already Logged into Actionable Science")
		// let token = sessionStorage.getItem("keycloak_token");
		// let tokenRefresh = sessionStorage.getItem("keycloak_refreshToken");
		//!identity.isAuthenticated &&
		keycloakInstances[tenant.tenantId].init({ adapter: 'default', token: identity.token })
		dispatch(identityActions.loginCheckSuccess())
		await setInterceptor(identity.token, tenant.id, identity && identity.profile, tenant, identity)
		// setTimeout(()=>dispatch(identityActions.setIdentity(keycloakInstances[tenant.tenantId])), 1000);
	} else {
		console.log('SESSION EXPIRED : ', isExpired)
		// dispatch(identityActions.clearIdentity())
		dispatch(identityActions.initiateLogin())
		keycloakInstances[tenant.tenantId]
			.init({
				onLoad: 'login-required',
				flow: 'standard',
			})
			.success(async authenticated => {
				dispatch(identityActions.loginSuccess(tenant.id, keycloakInstances[tenant.tenantId].token))
				identityServices.keycloakInstances[tenant.tenantId]
					.loadUserInfo()
					.success(async userInfo => await setInterceptor(keycloakInstances[tenant.tenantId].token, tenant.id, userInfo, tenant, identity))
				setTimeout(() => dispatch(identityActions.setIdentity()), 1000)
				updateSession(tenant)
				//if (!singleTenant) saveTenantId()
			})
			.error(err => dispatch(identityActions.loginFailure()))
	}
}
