export const GETALL_USERS_REQUEST = 'GETALL_USERS_REQUEST'
export const GETALL_USERS_SUCCESS = 'GETALL_USERS_SUCCESS'
export const GETALL_USERS_FAILURE = 'GETALL_USERS_FAILURE'

export const ADD_USERS_REQUEST = 'ADD_USERS_REQUEST'
export const ADD_USERS_SUCCESS = 'ADD_USERS_SUCCESS'
export const ADD_USERS_FAILURE = 'ADD_USERS_FAILURE'

export const MODIFY_USERS_REQUEST = 'MODIFY_USERS_REQUEST'
export const MODIFY_USERS_SUCCESS = 'MODIFY_USERS_SUCCESS'
export const MODIFY_USERS_FAILURE = 'MODIFY_USERS_FAILURE'

export const GET_ROLES_REQUEST = 'GET_ROLES_REQUEST'
export const GET_ROLES_SUCCESS = 'GET_ROLES_SUCCESS'
export const GET_ROLES_FAILURE = 'GET_ROLES_FAILURE'

export const GET_USERS_COUNT_REQUEST = 'GET_USERS_COUNT_REQUEST'
export const GETUSERS_COUNT_SUCCESS = 'GETUSERS_COUNT_SUCCESS'
export const GETUSERS_COUNT_FAILURE = 'GETUSERS_COUNT_FAILURE'

export const PASSWORD_RESET_EMAIL_REQUEST = 'PASSWORD_RESET_EMAIL_REQUEST'
export const PASSWORD_RESET_EMAIL_SUCCESS = 'PASSWORD_RESET_EMAIL_SUCCESS'
export const PASSWORD_RESET_EMAIL_FAILURE = 'PASSWORD_RESET_EMAIL_FAILURE'

export const GET_USER_REQUEST = 'GET_USER_REQUEST'
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS'
export const GET_USER_FAILURE = 'GET_USER_FAILURE'

export const MODIFY_CHANGE_PASSWORD_REQUEST = 'MODIFY_CHANGE_PASSWORD_REQUEST'
export const MODIFY_CHANGE_PASSWORD_SUCCESS = 'MODIFY_CHANGE_PASSWORD_SUCCESS'
export const MODIFY_CHANGE_PASSWORD_FAILURE = 'MODIFY_CHANGE_PASSWORD_FAILURE'

export const GET_ALL_USER_ATTRIBUTES_REQUEST = 'GET_ALL_USER_ATTRIBUTES_REQUEST'
export const GET_ALL_USER_ATTRIBUTES_SUCCESS = 'GET_ALL_USER_ATTRIBUTES_SUCCESS'
export const GET_ALL_USER_ATTRIBUTES_FAILURE = 'GET_ALL_USER_ATTRIBUTES_FAILURE'

export const recordConstants = {
	RECORD_LIMIT: '10',
	RECORD_RANGE: '5',
}
export const titleConstants = {
	USER_DISABLE: 'Confirm you are going to disable the user, and will not be able to login into the system ?',
	USER_ENABLE: 'Confirm you want to enable this user ?',
}
