import moment from 'moment';
// const tz = require('moment-timezone');
var _ = require('lodash');

// getting metaData for masterScreens from Local Json File
function formatJsonMeta(fileName) {
    const readFile = require(`../masters-meta/${fileName}.json`);
    return readFile
}
// getting metaData for masterScreens from Local Json File


function getToastMessages(toastObj) {
    let getToastMessages = formatJsonMeta('toastMessages');
    return getToastMessages;

    //     if(toastObj){
    //         let getToastMessages=  formatJsonMeta('toastMessages');
    //         const setToastMessages=getToastMessages[toastObj];
    // if(getToastMessages && setToastMessages && setToastMessages[toastObj]){
    //         return setToastMessages[toastObj]
    // }else{return false}
    //     }
    //     else{return false}

}

// creating Table Header Column according to Table Structure Format
function formatTableHeader(headerColumn) {
    return headerColumn
}
// creating Table Header Column according to Table Structure Format


function getValueFromProps(props, moduleName) {
    let propsData;
    if (moduleName === 'CaseFieldConfig') {
        propsData = props.CaseFieldConfig;
    }
    return propsData;
}


function mapTableHeaderColumns(data) {
    let headerColumn = [];
    data && data.length > 0 && data.forEach((field, index) => {
        if (field.fieldProperties && field.fieldProperties.showIn && field.fieldProperties.showIn.result) {
            let onClick = false;
            if (field.fieldProperties.showIn.result.clickable) { onClick = true } else { onClick = false }
            let setColumn = {
                title: field.fieldProperties.label ? field.fieldProperties.label : field.fieldName.toUpperCase(),
                field: field.fieldName,
                disableCellClick: onClick,
                sort: true,
                position: field.fieldProperties.showIn.result.position || 1
            }
            headerColumn.push(setColumn)
        }

    })
    let headerSort = headerColumn && headerColumn.length > 0 && headerColumn.sort(function (a, b) {
        return a.position - b.position
    })
    return headerSort;
}

function mapTableData(data,userName) {
    let tableData = [];
    const checkObj = ['assignedTo', 'reportedFor', 'reporter'];
  
    data && data.length > 0 && data.forEach((field, index) => {
        let finalData = {};
        Object.keys(field.data).map((x) => {
            if (checkObj.indexOf(x) !== -1) {
                return finalData = Object.assign({}, finalData, { [x]: field.data[x].username })
            }
            else {
                return finalData = Object.assign({}, finalData, { [x]: field.data[x] })
            }
        }, {})
        finalData.status = (finalData && finalData.status 
            && finalData.status.toState 
            ? finalData.status.toState 
            : field && field.status ? field.status :'--');
        let tableField = finalData;
        tableField.caseId = field.caseId;
        tableField.id = field.id;
        // changes for 2.0
        tableField.from = (field.data && field.data.reportedFor && field.data.reportedFor.firstName ? field.data.reportedFor.firstName + ' ' + field.data.reportedFor.lastName : '--');
        tableField.assignedTo = (field.data && field.data.assignedTo && field.data.assignedTo.firstName ? field.data.assignedTo.firstName + ' ' + field.data.assignedTo.lastName : '--');
        tableField.createdAt = (field.createdAt && field.createdAt != '' ? moment(field.createdAt).local().format('MMM-DD-YYYY  hh:mm:ss A') : '--');
        tableField.updatedAt = (field.updatedAt && field.updatedAt != '' ? moment(field.updatedAt).local().format('MMM-DD-YYYY  hh:mm:ss A') : '--');
        if(field.data.lastViewed && userName){
        let filterUser = field.data.lastViewed && Object.keys(field.data.lastViewed).filter(s=>s===userName);
        if(field.data.lastViewed && filterUser.length>0){
            tableField.updated='matched';
        }else{ 
            tableField.updated='nomatched'
        }
    }
        tableData.push(tableField);
    })

    return tableData;

}


function mapTableSearchColumns(data, values) {
    let searchColumn = [];
    if (data && data.length > 0) {
        data.forEach((field, index) => {
            if (field.fieldProperties && field.fieldProperties.showIn && field.fieldProperties.showIn.search) {
                let setColumn = {
                    label: field.fieldProperties.label ? field.fieldProperties.label : field.fieldName.toUpperCase(),
                    field: field.fieldName,
                    type: field.fieldProperties.type ? field.fieldProperties.type : 'text',
                    property: field.fieldProperties,
                    position: field.fieldProperties.showIn.search.position || 1
                }
                searchColumn.push(setColumn)
            }

        });
    }
    let searchSort = searchColumn && searchColumn.length > 0 && searchColumn.sort(function (a, b) {
        return a.position - b.position
    })
    return searchSort;
}

function searchFilters(filter, data) {
    let filtersResult;
    if (filter) {
        Object.entries(filter).forEach(([key, value]) => {
            let result = data.filter(function (field) {
                return value != "" && field[key] && field[key].toLowerCase() === value.toLowerCase()
            })
            data = result;
            filtersResult = data


        });

        return filtersResult
    }


}
function getFilteredCodes(array, key, value) {
    let filter = _.filter(array, (a) => {
        return a[key] && a[key] != undefined && _.includes(a[key].toLowerCase(), value.toLowerCase())
    });
    return filter
}
function searchTextFilters(filter, data, column) {
    let filtersResult;
    let resCol = {}
    if (filter && filter != '') {
        column.reduce(function (result, currentObject) {
            return resCol[currentObject.field] = currentObject.field
        }, {});
        Object.entries(resCol).forEach(([key, value]) => {
            var filteredCodes = getFilteredCodes(data, key, filter);
            if (filteredCodes && filteredCodes.length > 0)
                filtersResult = filteredCodes
        });

        return filtersResult
    }
    else { return data }

}
function compareValues(key, order = 'asc') {
    return function (a, b) {
        if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
            // property doesn't exist on either object
            return 0;
        }

        const varA = (typeof a[key] === 'string') ?
            a[key].toLowerCase() : a[key].toString();
        const varB = (typeof b[key] === 'string') ?
            b[key].toLowerCase() : b[key].toString();

        let comparison = 0;
        if (varA > varB) {
            comparison = 1;
        } else if (varA < varB) {
            comparison = -1;
        }
        return (
            (order === 'desc') ? (comparison * -1) : comparison
        );
    };
}


export const ticketingHelperFunc = {
    formatJsonMeta,
    formatTableHeader,
    getValueFromProps,
    mapTableHeaderColumns,
    mapTableData,
    mapTableSearchColumns,
    searchFilters,
    searchTextFilters,
    compareValues,
    getToastMessages

}

