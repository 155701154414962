// @ts-nocheck
import ascTheme from '@asc/ui-theme'
import { Box, ChakraProvider, extendTheme, Flex } from '@chakra-ui/react'
import React from 'react'
import { withTranslation } from 'react-i18next'
import GroupHistory from './GroupHistory'
import GroupMainView from './GroupMainView'; //../../common/base/entity/components';
import './index.css'
const theme = extendTheme(ascTheme)
class GroupDetailView1 extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			entityValues: {},
		}
	}
	UNSAFE_componentWillReceiveProps(props) {
		if (props.entity && props.entity.entityValues) {
			this.setState({
				entityValues: props.entity.entityValues,
			})
		}
	}
	handleUpdateGroup({ AADuser }, msp) {
		let { entityValues } = this.state
		if (msp) {
			entityValues.owners = [...entityValues.owners, ...AADuser]
			if (entityValues && entityValues.owners && entityValues.owners.length > 0) {
				entityValues.owners = [...new Set(entityValues.owners)]
			}
		} else {
			entityValues.members = [...entityValues.members, ...AADuser]
			if (entityValues && entityValues.members && entityValues.members.length > 0) {
				entityValues.members = [...new Set(entityValues.members)]
			}
		}

		this.props['editItem']({
			entityValues: this.state.entityValues,
			id: this.state.entityValues['id'],
		})
		this.setState({ entityValues })
	}
	render() {
		const { entityValues } = this.state
		const {
			globalSetting: { uiFeature },
		} = this.props
		const tenantConfig = uiFeature && uiFeature?.admin?.Customer?.status 
		let newProps = Object.assign({}, { ...this.props }) 
		if (newProps.entity && newProps.entity.entityValues) newProps.entity.entityValues = entityValues
		const groupLogs = entityValues.groupHistory || []
		const { meta, profile } = newProps
		let username, restrictUpdate
		username = profile.preferred_username
		if (
			(username && entityValues && entityValues.owners && Object.keys(entityValues.owners).length > 0 && entityValues.owners.indexOf(username) != -1) ||
			(username && entityValues && entityValues.createdBy && entityValues.createdBy === username)
		) {
			restrictUpdate = false
			let frmDetails = meta && meta.sections['groupDetailForm']
			frmDetails.items = frmDetails.items.filter(f => f.name != 'update')
			if (frmDetails.items)
				newProps.meta.sections['groupDetailForm'].items.push({
					name: 'update',
					type: 'action',
					col: 2,
				})
		} else {
			restrictUpdate = true
			let frmDetails = meta && meta.sections['groupDetailForm']
			frmDetails.items = frmDetails.items.filter(f => f.name != 'update')
			if (frmDetails.items) newProps.meta.sections['groupDetailForm'].items = frmDetails.items
		}
		let { t } = this.props
		
		let entityObj={}
		if(entityValues && entityValues?.members?.length>0){
			entityObj={
				syncWith:'members',
				syncList:[]
			}
			entityValues.members.forEach(el=>entityObj?.syncList?.push({label:el, value:el}))
		}
		return (
			<ChakraProvider theme={theme}>
				<Flex>
					<Box w="65%"> 
						<GroupMainView {...newProps} syncList={entityObj} entityValues={entityValues} t={t}/>
					</Box>
					<Box w="35%">
						<GroupHistory
							tenantConfig={tenantConfig}
							groupLogs={groupLogs}
							groupText={entityValues.groupText}
							entityValues={entityValues}
							restrictUpdate={false}
							handleUpdateGroup={this.handleUpdateGroup.bind(this)}
							t={t}
						/>
					</Box>
				</Flex>
			</ChakraProvider>
		)
	}
}

export const GroupDetailView = withTranslation()(GroupDetailView1)
