import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { DetailComponent, EntityActions, EntityService, ListSearchComponent } from './'
import ImportData from './components/ImportData'
// import CreateComponent from './components/CreateComponent';

export class Entity {
	constructor({
		name,
		title,
		fieldsMeta,
		cardsMeta,
		apisMeta,
		tabsMeta,
		gridMeta,
		accordianMeta,
		actionsMeta,
		lookUpMeta,
		categoriesMeta,
		dimensionsMeta,
		tagsMeta,
		sectionsMeta,
		treeViewMeta,
		screens,
		components,
		methodSchemas,
		entitySchema,
		collectionSchemas,
		idKey,
		importKeys,
		linkedItemTypes,
		ActionClass,
		ServiceClass,
		entityUrlPaths,
		fetchDetailByApi,
		bKeys,
		propFields,
		workflowActionsMeta,
		updateOnlyWritable,
		isUiFieldsMetaEnabled,
		contentMeta,
		appComponent,
		appComponentMeta,
		selectedFields,
		dynamicFieldsMeta,
		sideBarMinimized,
		wsEnabled,
		wsType,
	}) {
		this.fieldsMeta = fieldsMeta
		this.actionsMeta = actionsMeta
		this.cardsMeta = cardsMeta
		this.gridMeta = gridMeta
		this.accordianMeta = accordianMeta
		this.dynamicFieldsMeta = dynamicFieldsMeta
		this.name = name
		this.entityUrlPaths = entityUrlPaths // eg. path to get list of all cases  :caseId e.g. /ticketing/caseValues   and /ticketing/caseValues/:caseId
		this.title = title
		this.sectionsMeta = sectionsMeta
		this.treeViewMeta = treeViewMeta
		this.tabsMeta = tabsMeta
		this.apisMeta = apisMeta
		this.screens = screens
		this.lookUpMeta = lookUpMeta
		this.categoriesMeta = categoriesMeta
		this.contentMeta = contentMeta
		this.appComponentMeta = appComponentMeta
		this.appComponent = appComponent
		this.dimensionsMeta = dimensionsMeta
		this.tagsMeta = tagsMeta
		this.selectedFields = selectedFields
		this.idKey = idKey
		this.bKeys = bKeys
		this.methodSchemas = methodSchemas
		this.entitySchema = entitySchema
		let props = entitySchema && entitySchema.describe().children.props
		this.propFields = propFields || (props && props.children && Object.keys(props.children))
		this.wsEnabled = wsEnabled || false
		this.wsType = wsType || null
		if (ServiceClass)
			this.service = new ServiceClass({
				entityUrlPaths: entityUrlPaths || name,
				resourceName: name,
				methodSchemas,
				entitySchema,
				linkedItemTypes,
				wsEnabled: this.wsEnabled,
				wsType: this.wsType,
			})
		else
			this.service = new EntityService({
				entityUrlPaths: entityUrlPaths || name,
				resourceName: name,
				methodSchemas,
				entitySchema,
				linkedItemTypes,
				wsEnabled: this.wsEnabled,
				wsType: this.wsType,
			})
		if (ActionClass) this.actionObj = new ActionClass({ service: this.service, resourceName: name, methodSchemas, entitySchema, collectionSchemas, propFields: this.propFields })
		else this.actionObj = new EntityActions({ service: this.service, resourceName: name, methodSchemas, entitySchema, collectionSchemas, propFields: this.propFields })
		this.collectionSchemas = collectionSchemas
		this.sideBarMinimized = sideBarMinimized
		if (components) {
			this.components = {
				ListSearch: components.ListSearch || ListSearchComponent,
				Create: components.Create || DetailComponent,
				Detail: components.Detail || DetailComponent,
				ImportData: components.ImportData || ImportData,
			}
			if (this.appComponent) {
				this.components.AppComponent = this.appComponent
			}
		} else {
			this.components = components || { ListSearch: ListSearchComponent, Create: DetailComponent, Detail: DetailComponent, ImportData: ImportData }
			if (this.appComponent) {
				this.components.AppComponent = this.appComponent
			}
		}
		this.linkedItemTypes = linkedItemTypes
		this.containers = {}
		this.fetchDetailByApi = fetchDetailByApi
		this.importKeys = importKeys
		this.workflowActionsMeta = workflowActionsMeta
		this.updateOnlyWritable = updateOnlyWritable
		this.isUiFieldsMetaEnabled = isUiFieldsMetaEnabled
	}
	init({ mapStateToProps, mapDispatchToProps }) {
		if (!mapStateToProps)
			mapStateToProps = (state, ownProps) => {
				const { identity, alert, tenant, entityList, entity, globalSetting, tenantConfig, workspace,ProfileEntity } = state
				const profile = identity ? identity.profile : null
				let props = { profile, alert, tenant, entityList, entity, globalSetting, tenantConfig, workspace,ProfileEntity }
				// if(ownProps && ownProps.params) props.params = ownProps.params
				props.meta = {
					fields: this.fieldsMeta,
					actions: this.actionsMeta,
					apis: this.apisMeta,
					tabs: this.tabsMeta,
					screens: this.screens,
					entityUrlPaths: this.entityUrlPaths,
					lookUpMeta: this.lookUpMeta,
					categoriesMeta: this.categoriesMeta,
					tagsMeta: this.tagsMeta,
					dimensionsMeta: this.dimensionsMeta,
					sections: this.sectionsMeta,
					treeViewMeta: this.treeViewMeta,
					title: this.title,
					idKey: this.idKey,
					bKeys: this.bKeys,
					fetchDetailByApi: this.fetchDetailByApi,
					cards: this.cardsMeta,
					importKeys: this.importKeys,
					gridMeta: this.gridMeta,
					workflowActions: this.workflowActionsMeta,
					updateOnlyWritable: this.updateOnlyWritable,
					isUiFieldsMetaEnabled: this.isUiFieldsMetaEnabled,
					contentMeta: this.contentMeta,
					appComponentMeta: this.appComponentMeta,
					entitySchema: this.entitySchema,
					selectedFields: this.selectedFields,
					accordianMeta: this.accordianMeta,
					dynamicFields: this.dynamicFieldsMeta,
					sideBarMinimized: this.sideBarMinimized,
				}
				props.moduleName = this.name
				this.service.apiUrl = tenant && tenant.apiUrl
				this.actionObj.apiUrl = tenant && tenant.apiUrl
				// props.fetchDetailByApi = this.fetchDetailByApi
				return props
			}
		if (!mapDispatchToProps)
			mapDispatchToProps = (dispatch, props) => {
				let fns = this.getActionFns()
				return {
					...bindActionCreators(
						{
							...fns,
						},
						dispatch
					),
					baseResource: props.baseResource,
					baseUrl: props.baseUrl,
					routeProps: props.routeProps,
				}
			}

		if (this.appComponent) {
			this.containers.AppComponent = connect(mapStateToProps, mapDispatchToProps)(this.appComponent)
		}

		this.containers.ListSearch = connect(mapStateToProps, mapDispatchToProps)(this.components.ListSearch)
		this.containers.Create = connect(mapStateToProps, mapDispatchToProps)(this.components.Create)
		this.containers.Detail = connect(mapStateToProps, mapDispatchToProps)(this.components.Detail)
		this.containers.ImportData = connect(mapStateToProps, mapDispatchToProps)(this.components.ImportData)
	}

	getActionFns() {
		let fns = this.actionObj.getFns()

		return fns
	}

	async getContainer(type) {
		if (!['ListSearch', 'Create', 'Detail', 'ImportData', 'AppComponent'].includes(type)) return
		if (this.containers[type]) return this.containers[type]
		else {
			this.init({})
			return this.containers[type]
		}
	}
}
