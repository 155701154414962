
import {  EntityActions } from '../common/base/entity'

export class ContentActions extends EntityActions {
    constructor(options) {
        super(options);
    }
    // postActionsHooks({ actionName, item }) {
    //     let obj
    //     const spread = (ele) => {
    //         if (ele.props || ele.properties) {
    //             obj = Object.assign({}, ele)
    //             delete obj.props
    //             delete obj.properties
    //             obj= { ...ele.props, ...ele.properties, ...obj }
    //             return obj
    //         } else return ele
    //     }
    //     if (!item) return
    //     if (item.pop) return item.map(spread)
    //     return spread(item)


    // }

    // preActionsHooks({ actionName, item, propFields }) {
    //     let obj ={ props: {}}
    //     const collect = (ele) => {
    //         if (!ele.props) {
    //             Object.keys(ele).forEach(k =>{
    //                 if( propFields && propFields.includes(k)) obj.props[k] = ele[k]
    //                 else obj[k] = ele[k]
    //             })
    //             return obj
    //         } else return ele
    //     }
    //     if (!item) return
    //     if (item.pop) return item.map(collect)
    //     if(!propFields) return item
    //     return collect(item)
    //     // need to overwrite still for each specific entity...

    // }

    
    getFns() {
        let that = this
        let fns = super.getFns()
        Object.values(fns).forEach( f => f.bind(that))
        return fns
    }

}
