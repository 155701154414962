export const EXECUTEAPI_REQUEST = 'EXECUTEAPI_REQUEST';
export const GETONE_REQUEST = 'GETONE_REQUEST'
export const EXECUTEAPI_FAILURE = 'EXECUTEAPI_FAILURE';
export const GETONE_FAILURE = 'GETONE_FAILURE'
export const EXECUTEAPI_SUCCESS = 'EXECUTEAPI_SUCCESS';
export const GETONE_SUCCESS = 'GETONE_SUCCESS';
export const SLAAPI_REQUEST = 'SLAAPI_REQUEST';
export const SLAAPI_SUCCESS = 'SLAAPI_SUCCESS';
export const SLAAPI_FAILURE = 'SLAAPI_FAILURE';

export const  LOADER_FALSE = 'LOADER_FALSE'

