import Joi from 'joi-browser'

const userAttribute = Joi.object().keys({
	id: Joi.number().integer().max(18446744073709552000).min(0).allow(null),
	tenantId: Joi.string().allow(null),
	key: Joi.string().allow(null),
	label: Joi.string()
		.trim()
		.min(2)
		.max(255)
		.required()
		.error(() => 'Attribute Name is required'),
	valueLabels: Joi.array().items(Joi.string().allow(null)).min(0).allow(null).allow(''),
	validationRule: Joi.object().keys({
		field: Joi.string().max(100),
		required: Joi.string().max(10)
	}).allow(null, ''),
	isMultivalue: Joi.alternatives().try(Joi.string().default('No').allow(null, ''), Joi.boolean()),
	status: Joi.string().max(10).required(),
	createdBy: Joi.string().max(100).allow(null),
	updatedBy: Joi.string().max(100).allow(null),
	createdAt: Joi.date().allow(null),
	updatedAt: Joi.date().allow(null),
	customerId: Joi.number().integer().min(0).allow(null)
})

export const methodSchemas = {
	getAll: Joi.array().items(userAttribute),
	getOne: userAttribute,
	addItem: userAttribute,
	deleteItem: Joi.object().keys({ id: Joi.number().integer().positive() }),
	editItem: userAttribute,
}
export const collectionSchemas = {
	//  notes: note
}
export const entitySchema = userAttribute
