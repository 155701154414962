import { Box, FormControl, FormHelperText, FormLabel, Input } from '@chakra-ui/react'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import './identity/index.css'

const atleastOneSpecialChar = new RegExp(/(?=.*[@#$%^&+=!])/)
const atleastOneNumber = new RegExp(/(?=.*[0-9])/)

class PasswordChange extends Component {
	constructor(props) {
		super(props)
		this.state = {
			password: '',
			confirmPassword: '',
			errors: [],
			touched: false,
		}
		this.changePassword = this.changePassword.bind(this)
	}
	handleChange(e) {
		this.setState({ password: e.target.value })
	}
	changePassword(event) {
		event.preventDefault()
		const { password, confirmPassword } = this.state
		const { profile } = this.props
		let passwordCheck = this.validatePassword(password)
		let passwordLength = this.validatePasswordLength(password)
		let errors = []
		if (password.trim() === '') errors.push('password')
		if (confirmPassword.trim() === '') errors.push('confirmPassword')
		if (errors && errors.length > 0) {
			this.setState({
				errors: errors,
			})
			return true
		}
		this.setState({
			touched: true,
		})
		if (this.state.password.trim() !== this.state.confirmPassword.trim()) return true
		if (passwordCheck && passwordLength) this.props.changeUserPassword(profile.tenantId, profile.userId, { password: password }, this.props.t, this.props.tenant && this.props.tenant.apiUrl)
	}
	validatePasswordLength(password) {
		this.setState({ errorMsg: '' })
		if (password.length < 8) {
			this.setState({ errorMsg: 'password length (min 8 characters)' })
			return false
		} else {
			this.setState({ errorMsg: '' })
			return true
		}
	}
	validatePassword(password) {
		this.setState({ errorMsg: '' })
		const passPattern = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/
		let patternCheck = passPattern.test(password)
		if (patternCheck === true) {
			this.setState({ errorMsg: 'Password must contain 1 special Character or atleast 1 Number' })
		}
		return patternCheck
	}
	render() {
		const { classes, t } = this.props
		const { errors, password, errorMsg } = this.state
		return (
			<div className="col-sm-6">
				<div className="general-form card">
					<div className="gp-heading">
						<h4>{t('Change Password')}</h4>
					</div>
					<Box px={8} py={4}>
						<form className="custom-from profile-form-custom change-password">
							<FormControl mb={4}>
								<FormLabel mb={2}>{t('New Password')}</FormLabel>
								<Input
									required
									isInvalid={errors.indexOf('password') !== -1 ? true : false}
									errorBorderColor="#f44336"
									name="password"
									defaultValue={this.state.password}
									onChange={this.handleChange.bind(this)}
									type="password"
								/>
								{errorMsg && errorMsg.length > 0 && (
									<FormHelperText className="danger-text" id="component-error-text">
										{t(errorMsg)}
									</FormHelperText>
								)}
								<ul>
									<li className={password && password.length >= 8 ? 'ps-check' : password && 'not-matched'}>
										{t('password length (min 8 characters)')}
										<span className="min-check">
											<img src="assets/img/paswrd-check.png" alt="ps-check" />
										</span>
										<span className="min-check wrong-ps ">
											<img src="assets/img/wrong-check.png" alt="ps-check" />
										</span>
									</li>
									<li
										className={
											password && atleastOneSpecialChar.exec(password) && atleastOneSpecialChar.exec(password).length > 0
												? 'ps-check'
												: password && 'not-matched'
										}>
										{t('1 special character')}
										<span className="min-check">
											<img src="assets/img/paswrd-check.png" alt="ps-check" />
										</span>
										<span className="min-check wrong-ps ">
											<img src="assets/img/wrong-check.png" alt="ps-check" />
										</span>
									</li>
									<li
										className={
											password && atleastOneNumber.exec(password) && atleastOneNumber.exec(password).length > 0 ? 'ps-check' : password && 'not-matched'
										}>
										{t('at least 1 number')}
										<span className="min-check">
											<img src="assets/img/paswrd-check.png" alt="ps-check" />
										</span>
										<span className="min-check wrong-ps ">
											<img src="assets/img/wrong-check.png" alt="ps-check" />
										</span>
									</li>
								</ul>
							</FormControl>

							<FormControl mb={4}>
								<FormLabel mb={2}>{t('Confirm Password')}</FormLabel>
								<Input
									required
									isInvalid={errors.indexOf('confirmPassword') !== -1 ? true : false}
									errorBorderColor="#f44336"
									name="confirmPassword"
									defaultValue={this.state.confirmPassword}
									onChange={e => this.setState({ confirmPassword: e.target.value })}
									type="password"
									aria-describedby="component-error-text"
								/>
								{this.state.touched && this.state.password.trim() !== this.state.confirmPassword.trim() && (
									<FormHelperText className="danger-text" id="component-error-text">
										{t('Password and Confirm Password do not matched.')}
									</FormHelperText>
								)}
							</FormControl>
							<div className="col-sm-12">
								<button className="for-md-btn" onClick={this.changePassword}>
									{t('Save')}
								</button>
							</div>
						</form>
					</Box>
				</div>
			</div>
		)
	}
}

export default withTranslation()(PasswordChange)
