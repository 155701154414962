// import { alertActions } from '..'
import { BaseActions } from '../actions/base.actions'
import { LocaleService } from '../services/locale.service'
let options = {}
options.resourceName = 'Locale'
options.service = LocaleService

class LocaleActions extends BaseActions {}

let LocaleAction = new LocaleActions(options)

const getAvailableLangs = (tenantId, queryString, t, apiUrl) => LocaleAction.getAll(tenantId, queryString, t, apiUrl)

export { getAvailableLangs }
