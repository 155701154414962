import React from 'react';
import { history } from '../../../../../redux/history'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
class DetailHeader extends React.Component {

    handleClick() {
        // history.location
        history.goBack()
    }
    render() {
        const {  title, meta, t } = this.props
        return (

            <>
                <div className="col-sm-12">
                    <div className="heading">
                        <h5>
                            {t(meta && meta.title) || t(title)}
                        </h5>
                        <button className="btn btn-link pull-right" onClick={this.handleClick.bind(this)}>{t('Back to List', { name: meta.name })}</button>
                    </div>

                </div>
            </>
        )
    }

}
export default withTranslation()(DetailHeader)
DetailHeader.propTypes = {
    title: PropTypes.string, name: PropTypes.string
};
