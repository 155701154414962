import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Components, ReportActions, ReportService } from './'
import Report from './components/Report'
const { Dashboard } = Components
// import CreateComponent from './components/CreateComponent';

export class ReportTemplate {
	constructor({
		name,
		title,
		fieldsMeta,
		cardsMeta,
		apisMeta,
		actionsMeta,
		tabsMeta,
		treeTablesMeta,
		sectionsMeta,
		listsMeta,
		reportsMeta,
		components,
		methodSchemas,
		ReportSchema,
		collectionSchemas,
		idKey,
		ActionClass,
		ServiceClass,
		ReportUrlPaths,
		chartsMeta,
	}) {
		this.fieldsMeta = fieldsMeta
		this.actionsMeta = actionsMeta
		this.cardsMeta = cardsMeta
		this.chartsMeta = chartsMeta
		this.reportsMeta = reportsMeta
		this.listsMeta = listsMeta
		this.tabsMeta = tabsMeta
		this.treeTablesMeta = treeTablesMeta
		this.name = name
		this.ReportUrlPaths = ReportUrlPaths // report level get metrics... with input filters and groups... / report url dictionary
		this.apisMeta = apisMeta
		this.title = title
		this.sectionsMeta = sectionsMeta
		this.methodSchemas = methodSchemas // ( used for post apis )
		this.ReportSchema = ReportSchema // all reports have to follow
		if (ServiceClass) this.service = new ServiceClass({ ReportUrlPaths: ReportUrlPaths || name, methodSchemas, ReportSchema })
		else this.service = new ReportService({ ReportUrlPaths: ReportUrlPaths || name, methodSchemas, ReportSchema })
		if (ActionClass) this.actionObj = new ActionClass({ service: this.service, resourceName: name, methodSchemas, ReportSchema, collectionSchemas })
		else this.actionObj = new ReportActions({ service: this.service, resourceName: name, methodSchemas, ReportSchema, collectionSchemas })
		this.collectionSchemas = collectionSchemas
		this.components = components || { Dashboard, Report }
		this.containers = {}
	}
	init() {
		// let that = this
		const mapStateToProps = state => {
			const { identity, alert, tenant, reports, report } = state
			const profile = identity ? identity.profile : null
			let props = { profile, alert, tenant, reports, report }
			// if(ownProps && ownProps.params) props.params = ownProps.params
			props.meta = {
				fields: this.fieldsMeta,
				actions: this.actionsMeta,
				apis: this.apisMeta,
				tabs: this.tabsMeta,
				sections: this.sectionsMeta,
				lists: this.listsMeta,
				title: this.title,
				cards: this.cardsMeta,
				charts: this.chartsMeta,
				reportsMeta: this.reportsMeta,
				treeTables: this.treeTablesMeta,
			}
			props.moduleName = this.name
			// props.fetchDetailByApi = this.fetchDetailByApi
			return props
		}

		const mapDispatchToProps = dispatch => {
			let fns = this.getActionFns()
			return {
				...bindActionCreators(
					{
						...fns,
					},
					dispatch
				),
			}
		}

		this.containers.Report = connect(mapStateToProps, mapDispatchToProps)(this.components.Report)
	}

	getActionFns() {
		let fns = this.actionObj.getFns()

		return fns
	}

	async getContainer(type) {
		if (!['Report'].includes(type)) return
		if (this.containers[type]) return this.containers[type]
		else {
			this.init()
			return this.containers[type]
		}
	}
}
