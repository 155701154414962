import { alertActions } from '..'
import { BaseActions } from '../actions/base.actions'
import { whiteLabelService } from '../services/whiteLabel.services'
let options = {}
options.resourceName = 'whiteLabel'
options.service = whiteLabelService

class whiteLabelActions extends BaseActions {
	getDataWithDefaultTenant(tenantId, t, apiUrl, userInfo) {
		let that = this
		return dispatch => {
			dispatch(super.request('GETALL'))
			let errorMsg = t && t('Cannot fetch ' + this.resourceName + ' at this moment please try again later')
			whiteLabelService.getDataWithDefaultTenant(tenantId, apiUrl, userInfo).then(
				item => {
					if (item) {
						dispatch(super.success('GETALL', item))
					} else {
						errorMsg = errorMsg || 'Cannot fetch ' + this.resourceName + ' at this moment please try again later'
						dispatch(that.failure('GETALL', errorMsg))
						dispatch(alertActions.error(errorMsg))
					}
				},
				error => {
					errorMsg = errorMsg || 'Cannot fetch ' + this.resourceName + ' at this moment please try again later'
					dispatch(that.failure('GETALL', errorMsg))
					dispatch(alertActions.error(errorMsg))
				}
			)
		}
	}
}

let whiteLabelAction = new whiteLabelActions(options)

const getWhiteLabel = (tenantId, t, apiUrl) => whiteLabelAction.getAll(tenantId, null, t, apiUrl)
const upsertWhiteLabel = (item, tenantId, msg, t, apiUrl) => whiteLabelAction.upsertItem(item, tenantId, msg, t, apiUrl)
const getDataWithDefaultTenant = (tenantId, t, apiUrl, userInfo) => whiteLabelAction.getDataWithDefaultTenant(tenantId, t, apiUrl, userInfo)

export { getWhiteLabel, upsertWhiteLabel, getDataWithDefaultTenant }
