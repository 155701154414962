import axios from 'axios'
import { identityActions, identityServices, microsoftService } from '..'
import { singleTenant } from '../../../config'
export const msIdentityService = async (tenant, msToken, appName, dispatch) => {
	//console.log(tenant, msToken)

	let result = await getAcessTokenFromMsToken(msToken, appName, tenant.apiUrl)
	let tokenData = result.data
	dispatch(identityActions.loginSuccessMs(tokenData.signedPayload))
	await identityServices.setInterceptorMs(tokenData.signedPayload, tenant.id, tokenData)
	dispatch(identityActions.setIdentitySuccessMs(tokenData.userPayload, tenant))
	identityServices.saveTenantId()
	return tokenData
	// if (!keycloakInstances[tenant.tenantId]) {
	//     keycloakInstances[tenant.tenantId] = new Keycloak({
	//         url: idpUrl,
	//         realm: tenant.tenantId,
	//         clientId: 'adminui-service'
	//     });
	// }
}

export const msEnsureAuthentication = async props => {
	const { dispatch, identity, tenant } = props

	//const token = sessionStorage.getItem('keycloak_token');
	//const refreshToken = sessionStorage.getItem('keycloak_refreshToken');
	const isExpired = identity && identity.exp ? new Date((identity.exp + identity.timeSkew) * 1000) < new Date() : true
	if (identity.token && identity.isAuthenticated && !isExpired) {
		//console.log("Already Logged into Actionable Science")
		// let token = sessionStorage.getItem("keycloak_token");
		// let tokenRefresh = sessionStorage.getItem("keycloak_refreshToken");
		//!identity.isAuthenticated &&
		//keycloakInstances[tenant.tenantId].init({ adapter: 'default', token: identity.token })
		dispatch(identityActions.loginCheckSuccess())
		await identityServices.setInterceptorMs(identity.token, tenant.id, identity)
		// setTimeout(()=>dispatch(identityActions.setIdentity(keycloakInstances[tenant.tenantId])), 1000);
	} else {
		// console.log("SESSION EXPIRED : ", isExpired)
		let msToken = sessionStorage.getItem('ms_token')
		let queryParameters = identityServices.getQueryParameters()
		let tokendata = await microsoftService.msIdentityService(tenant, msToken, queryParameters.appName, dispatch)
		dispatch(identityActions.loginSuccessMs(tokendata.signedPayload))
		await identityServices.setInterceptorMs(tokendata.signedPayload, tenant.id, identity)
		microsoftService.updateSessionMs(tenant, tokendata.signedPayload)
		if (!singleTenant) identityServices.saveTenantId()
		// dispatch(identityActions.clearIdentity())
		// dispatch(identityActions.initiateLogin());
		// keycloakInstances[tenant.tenantId]
		//     .init({
		//         onLoad: 'login-required',
		//         flow: 'standard',
		//     })
		//     .success(async authenticated => {
		//         dispatch(identityActions.loginSuccess(keycloakInstances[tenant.tenantId].token))
		//         await setInterceptor(keycloakInstances[tenant.tenantId].token, tenant.id)
		//         setTimeout(() => dispatch(identityActions.setIdentity()), 1000);
		//         updateSession(tenant)
		//         //if (!singleTenant) saveTenantId()
		//     })
	}
}
export const updateSessionMs = (tenant, token) => {
	sessionStorage.setItem('ms_access_token', token)
}

export const getAcessTokenFromMsToken = (msToken, appName, apiUrl) => {
	if (!apiUrl) apiUrl = sessionStorage.getItem('apiUrl')
	const requestOptions = {
		url: `${apiUrl}/tenant/getAuthToken/${appName}`,
		headers: { msToken: msToken },
		method: 'GET',
	}

	// if (headerParams) {
	//     requestOptions.headers = {}
	//     let headerParamsConfig = JSON.parse(headerParams);
	//     headerParamsConfig.forEach(p => {
	//         requestOptions.headers[p.name] = p.value
	//     })
	// }
	return axios.request(requestOptions)
}
