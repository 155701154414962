import React, { Component } from "react";
import {Badge} from 'reactstrap';
class ObjectView extends Component {
    render() {
        return (
            <ul className="extra-list-item">
                {   this.props.data && !(Array.isArray(this.props.data)) && (typeof this.props.data ==="object" ) &&
                    Object.keys(this.props.data).map((item, index)=><li key={item+index}>{`${item}: ${this.props.data[item]}`}</li>)
                }
                {   this.props.data && Array.isArray(this.props.data) && 
                    this.props.data.map((item, index)=> {
                        if (!item) return null
                        if (typeof item == 'string') {
                            return (<Badge key={index} style={{margin:'1px'}} href="#" color="primary">{item}</Badge>)
                        } else {
                            return Object.keys(item).map((key, i)=> {
                                if(item.key && Array.isArray(item[key])) {
                                    return (
                                        <Badge key={i} style={{margin:'1px'}} href="#" color="primary">{`${key}: ${JSON.stringify(item[key])}`}</Badge>
                                        )
                                } else if(typeof item[key] == 'string') {
                                    return (
                                    <Badge key={i} style={{margin:'1px'}} href="#" color="primary">{`${key}: ${item[key]}`}</Badge>
                                    )
                                } else {
                                    return (
                                        <Badge key={i} style={{margin:'1px'}} href="#" color="primary">{`${key}: ${JSON.stringify(item[key])}`}</Badge>
                                    )
                                }
                            })
                        }
                     })
                }
            </ul>
        )
    }
}
export default ObjectView; 
