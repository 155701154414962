/**
 * RU on all settings including whitelisting
 * This is in common as all apps would need it
 * Setting Key value pairs come from db of the app.
 */
import { Entity } from '../../entity/entity'
import { collectionSchemas, entitySchema, methodSchemas } from './auditConfig.schema'
// import { apiBaseUrlPath } from '../../../../../config';

class AuditConfigEntity extends Entity {}

let apiPath = ``
// if(apiBaseUrlPath && apiBaseUrlPath !== ""){
//     apiPath = `${apiBaseUrlPath}/AppRoute`
// } else {
apiPath = `AppRoute`
// }

const fieldsMeta = {
	modelName: {
		label: 'Model Name',
		min: 3,
		max: 20,
		type: 'select',
		required: true,
		placeholder: 'Please select the request path',

		api: {
			path: apiPath,
			method: 'GET',
			filters: [
				{
					field: 'status',
					operator: 'is',
					value: 'Published',
				},
			],
			//params: { filter: ['queueName'] },
			response: {
				ref: 'data',
				labelKey: 'modelName',
				valueKey: 'modelName',
			},
		},
		readOnly: false,
		// width: '20%',
		name: 'modelName',
	},
	requestMethod: {
		label: 'Request Method',
		min: 3,
		max: 20,
		type: 'select',
		required: true,
		placeholder: 'Please select the request path',
		dependsOn: ['modelName'],
		dependsOnMeta: {
			modelName: {
				ref: 'requestMethods',
				filter: 'modelName',
			},
		},
		// api: {
		//     path: 'ticketing/AuditConfig/allAppRoutes',
		//     method: 'GET',
		//     //params: { filter: ['queueName'] },
		//     response: {
		//         ref: 'data',
		//         labelKey: 'path',
		//         valueKey: 'path'
		//     }
		// },
		readOnly: false,
		// width: '20%',
		name: 'requestMethod',
	},
	status: {
		label: 'Status',
		name: 'status',
		options: [
			{ label: 'Draft', value: 'Draft' },
			{ label: 'Published', value: 'Published' },
		],
		type: 'select',
		required: true,
		default: 'Draft',
		width: '20%',
	},
}

const actionsMeta = {
	editItem: {
		type: 'button',
		label: 'Update',
		action: 'editItem',
		name: 'editItem',
	},
	editMap: {
		label: 'Edit Map',
		name: 'editMap',
		action: 'showMap',
		condition: 'typeof value === "object"',
	},
	save: {
		label: 'Save',
		name: 'save',
		action: 'save',
	},
	cancel: {
		type: 'button',
		label: 'Cancel',
		action: 'cancelEdit',
		name: 'cancel',
	},
	update: {
		label: 'Edit',
		name: 'update',
		action: 'updateItem',
		classes: 'fa fa-pencil fa-lg ',
		type: 'span',
	},
	list: {
		label: 'List All',
		name: 'list',
		action: 'load',
	},
	search: {
		label: 'Search',
		name: 'search',
		action: 'search',
	},
	view: {
		label: 'View',
		name: 'view',
		action: 'getItem',
		classes: 'fa fa-eye fa-lg',
		type: 'span',
	},

	create: {
		label: 'Create',
		name: 'create',
		type: 'button',
		action: 'upsertItem',
	},
	createItem: {
		label: 'Create',
		action: 'upsertItem',
		name: 'create',
		type: 'button',
	},
	deleteItem: {
		label: 'Delete',
		action: 'deleteItem',
		name: 'delete',
		type: 'button',
	},
}
const sectionsMeta = {
	basic: {
		title: 'Audit Config Details',
		items: [
			{ name: 'modelName', type: 'field' },
			{ name: 'requestMethod', type: 'field' },
			{ name: 'status', type: 'field' },
		],
		classes: 'ticket-form',
	},
	headerComponent: {
		component: 'DetailHeader',
	},

	viewForm: {
		cols: [7, 5],
		items: [
			{ name: 'basic', type: 'section', col: 1 },
			{ name: 'editItem', type: 'action', col: 1 },
			{ name: 'cancel', type: 'action', col: 1 },
		],
		classes: 'composite-inner-form',
	},

	createForm: {
		cols: [7, 5],
		items: [
			{ name: 'basic', type: 'section', col: 1 },
			{ name: 'createItem', type: 'action', col: 1 },
		],
		classes: 'composite-inner-form',
	},
}

const tabsMeta = {}

const screens = {
	view: {
		items: [
			{ name: 'headerComponent', type: 'section' },
			{ name: 'viewForm', type: 'section' },
		],
		classes: 'composite-form',
		title: 'Audit Config Details',
	},
	create: {
		items: [
			{ name: 'headerComponent', type: 'section' },
			{ name: 'createForm', type: 'section' },
		],
	},
	edit: {
		items: [
			{ name: 'headerComponent', type: 'section' },
			{ name: 'viewForm', type: 'section' },
		],
	},
	list: {
		items: [
			{ name: 'modelName', type: 'link' },
			{ name: 'requestMethod', type: 'field' },
			{ name: 'status', type: 'field' },
		],
	},
	search: {
		items: [{ name: 'modelName', type: 'field' }],
	},
}
// const components = { searchList: EntityComponent} // can overrides the components like this if needed
const AuditConfig = new AuditConfigEntity({
	fieldsMeta,
	actionsMeta,
	sectionsMeta,
	tabsMeta,
	screens,
	name: 'AuditConfig',
	title: 'Audit Config',
	methodSchemas,
	collectionSchemas,
	entitySchema,
	idKey: 'id',
	entityUrlPaths: { update: `/AuditConfig/update`, default: `/AuditConfig` },
	fetchDetailByApi: false,
})

const AuditConfigListSearchContainer = AuditConfig.getContainer('ListSearch')
const AuditConfigCreateContainer = AuditConfig.getContainer('Create')
const AuditConfigDetailContainer = AuditConfig.getContainer('Detail')
export { AuditConfigListSearchContainer, AuditConfigCreateContainer, AuditConfigDetailContainer }
