import { settingsConstants } from '../';

export const globalSetting = (state = {}, action) => {
  switch (action.type) {
    case settingsConstants.GETALL_REQUEST:
      return {
        ...state,
        loading: true
      }
    case settingsConstants.GETALL_SUCCESS:
      return {
        ...state,
        loading: false,
        ...action.payload.data
      }

    case settingsConstants.CHANGE_STATUS_SUCCESS:
      if (action.payload) {
        return {
          ...state,
          ...action.payload.data,
          loading: false
        }
      }
      break;


    case settingsConstants.UPDATE_GLOBAL_SETTING_REQUEST:
      return {
        ...state,
        loading: true
      }

    case settingsConstants.UPDATE_GLOBAL_SETTING_SUCCESS:
      if (action.payload) {
        return {
          ...state,
          ...action.payload.data,
          loading: false
        }
      } else {
        return {
          ...state,
          loading: false
        }
      }

    case settingsConstants.UPDATE_GLOBAL_SETTING_FAILURE:
      return {
        ...state,
        loading: false
      }
    case settingsConstants.CLEAR_GLOBAL_SETTING:
      return {
    }

    //to be checked by harish for its compatibility
    case settingsConstants.GET_SETTINGS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case settingsConstants.GET_SETTINGS_SUCCESS:
      if (action.payload) {
        return {
          ...state,
          loading: false,
          settingData: action.payload
        }
      } else {
        return {
          ...state,
          loading: false
        }
      }
    case settingsConstants.GET_SETTINGS_FAILURE:
      return {
        ...state,
        loading: false
      };


    default:
      return state
  }
}