import PropTypes from 'prop-types'
import Tree, { TreeNode } from 'rc-tree'
import 'rc-tree/assets/index.css'
import React, { Component, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { Button, Col, FormGroup, Input, Jumbotron, Label, ListGroup, ListGroupItem, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import './style.css'
class TreeViewComponent extends Component {
	constructor(props) {
		super(props)
		this.state = {
			listingData: this.props && this.props.entityList && this.props.entityList.itemData ? this.props.entityList.itemData : [],
			treeListData: null,
			dimensions: null,
			parentDimension: null,
			childDimension: [],
			modal: false,
			modalList: null,
			selectedKey: '',
			meta: null,
			errMsg: false,
			position: -1,
			parentAdd: false,
			editVal: {},
			editIndex: -1,
			delModal: false,
			deleId: null,
			delData: null,
		}
	}

	dimensionsApi = async () => {
		const {
			meta: { dimensionsMeta },
		} = this.props
		let itemDef = dimensionsMeta['dimensions']
		let dimensions = itemDef.api
		if (dimensions && Object.keys(dimensions).length > 0) {
			await this.props.getRefItems({ apiDef: dimensions, filter: dimensions.filter, top: itemDef.api && itemDef.api.top, t: this.props.t })
		}
	}
	categoryApi = async () => {
		const {
			meta: { categoriesMeta },
		} = this.props
		let itemDef = categoriesMeta['categories']
		let categories = itemDef.api
		if (categories && Object.keys(categories).length > 0) {
			await this.props.getRefItems({ apiDef: categories, filter: categories.filter, top: itemDef.api && itemDef.api.top, t: this.props.t })
		}
	}

	componentWillMount() {
		this.categoryApi()
		this.dimensionsApi()
	}

	componentDidMount() {}

	componentWillReceiveProps(nextProps) {
		const {
			meta: { treeViewMeta },
		} = nextProps

		let dimensions =
			nextProps && nextProps.entity && nextProps.entity.refEntityList && nextProps.entity.refEntityList.dimensions && nextProps.entity.refEntityList.dimensions.length > 0
				? nextProps.entity.refEntityList.dimensions[0]
				: {}
		let listingData =
			nextProps && nextProps.entity && nextProps.entity.refEntityList && nextProps.entity.refEntityList.categories && nextProps.entity.refEntityList.categories.length > 0
				? nextProps.entity.refEntityList.categories
				: {}

		//        let listingData = nextProps && nextProps.entityList && nextProps.entityList.itemData ? nextProps.entityList.itemData : []
		this.setState({ dimensions: dimensions, errMsg: false, meta: treeViewMeta, listingData: listingData })
		if (listingData && listingData.length > 0) {
			let jsonStructure = this.createTreeData(listingData)
			if (jsonStructure && jsonStructure.length > 0) {
				this.setState({ treeListData: jsonStructure })
			}
		}

		this.setParentDimensions(dimensions)
	}

	setParentDimensions(dimensions) {
		let filterParent = dimensions && Object.keys(dimensions).length > 0 && dimensions.levelLabels && dimensions.levelLabels.pop && dimensions.levelLabels.find(x => x.key === 'L1')
		let filterChild = dimensions && Object.keys(dimensions).length > 0 && dimensions.levelLabels && dimensions.levelLabels.pop && dimensions.levelLabels.filter(x => x.key !== 'L1')

		this.setState({ parentDimension: filterParent, childDimension: filterChild })
	}

	createTreeData(arr) {
		let data = this.getNestedChildren(arr, null)
		return data
		// return arr.filter(function(obj){
		//          let id = obj["key"],
		//         parentId = obj["parent"];

		//     nodes[id] = Object.assign(obj, nodes[id], {title:id, children: [] });
		//     parentId && (nodes[parentId] = (nodes[parentId] || {title:id, children: [] }))["children"].push(obj);
		//     return !parentId

		// });
	}

	getNestedChildren(listData, parent) {
		let metaDeta =
			listData &&
			listData.length > 0 &&
			listData.map((elem, index) => {
				return Object.assign({}, elem)
			})
		var out = []
		for (var i in metaDeta) {
			if (metaDeta[i].parent === parent) {
				metaDeta[i].title = metaDeta[i].value
				var children = this.getNestedChildren(metaDeta, metaDeta[i].key)
				if (children.length) {
					metaDeta[i].children = children
				}
				out.push(metaDeta[i])
			}
		}
		return out
	}
	toggleCloseModal() {
		this.setState({ modal: false, parentAdd: false, errMsg: false })
	}
	toggleModal() {
		if (this.checkDepth(this.state.position)) this.setState({ modal: !this.state.modal, parentAdd: false, errMsg: false })
	}
	toggleAddModal = () => {
		this.setState({ modal: !this.state.modal, parentAdd: true, position: 0, editVal: {}, errMsg: false })
	}
	checkDepth(position) {
		const { dimensions } = this.state
		if (dimensions && dimensions.depth > position) {
			return true
		} else {
			return false
		}
	}
	filterEdit = value => {
		const { listingData } = this.state
		let editValue = {}
		listingData &&
			listingData.length > 0 &&
			listingData.forEach((item, idx) => {
				if (item.key === value) {
					editValue = item
					this.setState({ editIndex: idx })
				}
			})
		return editValue
	}
	onSelect(selectedKeys, info) {
		const { listingData } = this.state
		let position = -1
		let pos = ''
		if (selectedKeys && selectedKeys.length > 0) pos = info && info.node && info.node.props && info.node.props.pos ? info.node.props.pos : ''
		if (pos && pos.length > 0) {
			position = pos.split('-').length
		}
		this.setState({ position: position - 1 })
		if (this.checkDepth(position - 1)) {
			let filterVal =
				selectedKeys && selectedKeys.length > 0 && listingData && listingData.length > 0 && listingData.filter(x => x.key === selectedKeys[0] || x.parent === selectedKeys[0])

			let editVal = this.filterEdit(selectedKeys[0])
			this.setState({ modal: !this.state.modal, parentAdd: false, modalList: filterVal, editVal: editVal, selectedKey: selectedKeys[0] })
		}
	}

	addCategory = async formValues => {
		const { listingData } = this.state

		let checkValue = listingData && listingData.length > 0 && listingData.some(el => el.value === formValues.value)
		if (checkValue) {
			this.setState({ errMsg: 'Label already exists!' })
		} else {
			//formValues.tenantId = profile.tenantUid
			this.props.upsertItem({ entityValues: formValues, t: this.props.t })

			setTimeout(() => {
				this.categoryApi()
			}, 1500)

			this.setState({ errMsg: false, modal: false })
		}
	}

	editCategory = async (formValues, id) => {
		this.props.editItem({ entityValues: formValues, id: formValues.id || id, t: this.props.t })
		setTimeout(() => {
			this.categoryApi()
		}, 1000)
		this.setState({ errMsg: false, modal: false })
	}

	removeCategory = async (formValues, id) => {
		formValues.status = 'Deleted'
		this.props.editItem({ entityValues: formValues, id: formValues.id || id, t: this.props.t })
		setTimeout(() => {
			this.categoryApi()
		}, 1000)
		this.setState({ errMsg: false, modal: false, delModal: false, delData: null, delId: null })
	}

	// shouldComponentUpdate(nextProps, nextState) {

	// }

	// componentWillUpdate(nextProps, nextState) {

	// }

	// componentDidUpdate(prevProps, prevState) {

	// }

	// componentWillUnmount() {

	// }

	deleteItem = (id, rowData) => {
		setTimeout(() => {
			this.setState({ modal: false, delModal: true, delId: id, delData: rowData })
		}, 100)
	}
	openModalDelete = () => {
		this.setState({ delModal: !this.state.delModal })
	}
	delItem = confirm => {
		const { delData, delId } = this.state
		if (confirm) {
			if (delData && delId) {
				delData.status = 'Deleted'
				this.removeCategory(delData, delId)
			}
		} else this.setState({ modal: false, delModal: false, delId: null, delData: null })
	}
	render() {
		const { t } = this.props
		const { treeListData, modal, modalList, selectedKey, meta, delModal, parentDimension } = this.state
		const customLabel = row => {
			return (
				<div className="row" key={row.key}>
					<div className="col-md-8">
						<span className="text-info">{row.title}</span>
					</div>
					<div className="col-md-4 pull-right">
						<i onClick={this.deleteItem.bind(this, row.id, row)} className="fa fa-trash text-danger"></i>
					</div>
					<div className="clearfix"></div>
				</div>
			)
		}
		const loop = data => {
			return (
				data &&
				data.length > 0 &&
				data.map(item => {
					if (item.children) {
						return (
							<TreeNode title={customLabel(item)} key={item.key}>
								{loop(item.children)}
							</TreeNode>
						)
					}
					return <TreeNode title={customLabel(item)} key={item.key} isLeaf={item.isLeaf} disabled={item.key === '0-0-0'} />
				})
			)
		}
		const treeNodes = loop(treeListData)
		return (
			<Col xs="12">
				<Jumbotron className="treeJumbo">
					<h4 className="subCatHeading"> {t('Category hierarchy')}</h4>
					<Tree
						//                        onRightClick={this.onRightClick.bind(this)}
						className="myCls"
						showLine
						showIcon={false}
						onSelect={this.onSelect.bind(this)}
						checkable={false}>
						{treeNodes}
					</Tree>
					<Button color="success" outline size="sm" style={{ cursor: 'pointer' }} onClick={this.toggleAddModal.bind(this)}>
						<span className="fa fa-plus"></span> {t('Add')} {parentDimension && parentDimension.value ? parentDimension.value : ''}
					</Button>
				</Jumbotron>

				<div className="clearfix"></div>
				{modal && (
					<CreateModal
						modal={modal}
						parentAdd={this.state.parentAdd}
						toggleModal={this.toggleModal.bind(this)}
						toggleCloseModal={this.toggleCloseModal.bind(this)}
						list={modalList}
						selectedKey={selectedKey}
						editVal={this.state.editVal}
						editIndex={this.state.editIndex}
						meta={meta}
						err={this.state.errMsg}
						dimensions={this.state.dimensions}
						position={this.state.position}
						addCategory={this.addCategory.bind(this)}
						editCategory={this.editCategory.bind(this)}
						removeCategory={this.removeCategory.bind(this)}
					/>
				)}
				{delModal && <DeleteModal deleteItems={this.delItem.bind(this)} delModal={delModal} openModalDelete={this.openModalDelete.bind(this)} />}
			</Col>
		)
	}
}

TreeViewComponent.propTypes = {
	classes: PropTypes.object.isRequired,
}

export default withTranslation()(TreeViewComponent)

const CreateModal = props => {
	const [modal, setModal] = useState(props.modal || false)
	const [childList, setChildList] = useState(props.list)
	const [fields, setFields] = useState([])
	const [state, setState] = useState({ addNew: false })
	const [dimensions, setDimensions] = useState('')

	const toggle = () => {
		setModal(!modal)
		props.toggleModal()
	}
	const toggleClose = () => {
		setModal(!modal)
		props.toggleCloseModal()
	}
	useEffect(() => {
		// let depth=props.dimensions && props.dimensions.depth ? props.dimensions.depth-1 : 0
		// if(depth && depth > 0){

		// }
		let dim = props.position && props.position > -1 ? `L${props.position + 1}` : null
		let getDimension = props.dimensions && props.dimensions.levelLabels && props.dimensions.levelLabels.length > 0 && props.dimensions.levelLabels.find(x => x.key === dim)
		setDimensions(getDimension)
	}, [props.position])

	useEffect(() => {
		let filterChild = props.list && props.list.length > 0 && props.list.filter(x => x.parent === props.selectedKey)
		setChildList(filterChild)
	}, [props.list])

	useEffect(() => {
		const {
			meta: { treeView1 },
		} = props
		treeView1 && treeView1.items && treeView1.items.length > 0 && setFields(treeView1.items)
	}, [props.meta])

	const addCategory = formValues => {
		let spreadList = childList && childList.length > 0 ? [...childList, formValues] : [formValues]
		setChildList(spreadList)
		setState({ addNew: !state.addNew })
		props.addCategory(formValues)
	}
	const cancelCategory = () => {
		setState({ addNew: !state.addNew })
	}

	const editCategory = (formValues, index) => {
		if (formValues) {
			props.editCategory(formValues, index)
		}
	}

	const removeCategory = (formValues, index) => {
		if (formValues) {
			props.removeCategory(formValues, index)
		}
	}
	const toggleAddForm = () => {
		setState({ addNew: !state.addNew })
	}
	const removeItem = item => {
		//        console.log('!!!!! removeItem',item)
	}
	let title
	if (props.editVal && props.editVal.value) {
		title = ': ' + props.editVal.value
	} else {
		title = ': Category'
	}

	return (
		<div>
			<Modal isOpen={modal} className="categoryForm" toggle={toggle}>
				<ModalHeader toggle={toggle}>
					{props.t('Manage')} {props.t(title)}{' '}
				</ModalHeader>
				<ModalBody>
					{props.err && <p className="text-danger">{props.err}</p>}
					<Row className="row-margin">
						<Col>
							<CreateEdit
								fields={fields}
								parentAdd={props.parentAdd}
								selectedKey={props.selectedKey}
								addCategory={addCategory}
								editCategory={editCategory}
								removeCategory={removeCategory}
								levelLabel={(dimensions && dimensions.value) || null}
								position={props.position}
								editVal={props.editVal}
								editIndex={props.editIndex}
								dimensions={(props && props.dimensions && props.dimensions.levelLabels) || null}
							/>
						</Col>
					</Row>

					<Row className="row-margin">
						<Col>
							{state.addNew && (
								<CreateForm
									parentAdd={props.parentAdd}
									fields={fields}
									selectedKey={props.selectedKey}
									addCategory={addCategory}
									cancelCategory={cancelCategory}
									position={props.position}
									dimensions={(props && props.dimensions && props.dimensions.levelLabels) || null}
									levelLabel={(dimensions && dimensions.value) || null}
								/>
							)}
						</Col>
					</Row>

					<Row>
						{!state.addNew && !props.parentAdd && (
							<Col style={{ cursor: 'pointer' }}>
								<Button onClick={toggleAddForm} className="pull-right addCity" outline color="primary" size="sm">
									{' '}
									<span className="fa fa-plus"></span> Add {dimensions && dimensions.value ? dimensions.value : ''}
								</Button>
							</Col>
						)}
					</Row>

					<div className="clearfix"></div>
					<Row className="row-margin">
						<Col>{!props.parentAdd && <ListSubCategory removeItem={removeItem} list={childList} />}</Col>
					</Row>
				</ModalBody>
				<ModalFooter>
					<Button color="secondary" outline size="sm" onClick={toggleClose}>
						<span className="fa fa-close"></span> Close
					</Button>
				</ModalFooter>
			</Modal>
		</div>
	)
}

const ListSubCategory = props => {
	return (
		<ListGroup>
			{props &&
				props.list &&
				props.list.length > 0 &&
				props.list.map((item, idx) => {
					return (
						<ListGroupItem onClick={props.removeItem.bind(this, item)} key={idx}>
							Label : {item.value}
						</ListGroupItem>
					)
				})}
		</ListGroup>
	)
}

const CreateForm = props => {
	const [values, setValues] = useState({ parent: props.selectedKey || null, status: 'Published', type: 'categories', levelLabel: props.levelLabel })

	const onChange = event => {
		setValues({ ...values, [event.target.name]: event.target.value.trim() })
	}
	useEffect(() => {
		if (values.value) {
			setValues({ ...values, key: values.value, valueLabel: values.value })
		}
	}, [values.value])

	const addCategory = () => {
		if (values.value && values.valueLabel) {
			props.addCategory(values)
		}
	}
	const cancelCategory = () => {
		props.cancelCategory()
	}

	return (
		<Row className="row-margin">
			{props &&
				props.fields &&
				props.fields.length > 0 &&
				props.fields.map((item, index) => {
					if (item.type !== 'hidden') {
						return (
							<Col xs="12" key={index}>
								<FormGroup>
									<Label for="exampleEmail">{item.label || item.name}</Label>
									<Input type={item.type || 'text'} onChange={onChange.bind(this)} name={item.name || index} placeholder={item.placeholder} />
								</FormGroup>
							</Col>
						)
					} else return <div key={index}></div>
				})}

			<Col xs="12">
				{props && props.fields && props.fields.length > 0 && (
					<div className="cf-Btn">
						<Button onClick={addCategory.bind(this)} outline color="success" size="sm">
							{' '}
							<span className="fa fa-save"></span> Add
						</Button>
						<Button onClick={cancelCategory.bind(this)} outline color="secondary" size="sm">
							{' '}
							<span className="fa fa-close"></span> Cancel
						</Button>
					</div>
				)}
			</Col>
		</Row>
	)
}

const CreateEdit = props => {
	const [values, setValues] = useState({
		parent: props.parentAdd ? null : props.selectedKey || null,
		status: 'Published',
		type: 'categories',
		levelLabel: null,
	})
	const [edit, setEdit] = useState(props.editVal || {})
	const onChange = event => {
		setValues({ ...values, [event.target.name]: event.target.value.trim() })
		setEdit({ ...edit, [event.target.name]: event.target.value.trim() })
	}
	useEffect(() => {
		if (values.value && props.parentAdd) {
			setValues({ ...values, key: values.value, valueLabel: values.value })
			setEdit({ ...edit, key: values.value, valueLabel: values.value })
		} else {
			setValues({ ...values, valueLabel: values.value })
			setEdit({ ...edit, valueLabel: values.value })
		}
	}, [values.value])

	useEffect(() => {
		// let pos = props.position > 0 ? props.position - 1 : 0;
		let getLevel = getLevelLabel(props.position)
		getLevel && getLevel.value && setValues({ ...values, levelLabel: getLevel.value })
		//        getLevel && getLevel.value && setEdit({...edit,levelLabel:getLevel.value})
	}, [props])

	const getLevelLabel = pos => {
		let levelPos = 0
		if (pos === 0) {
			levelPos = pos + 1
		} else {
			levelPos = pos
		}
		let posLabel = `L${levelPos}`
		let filterPos = props.dimensions && props.dimensions.length > 0 && props.dimensions.find(x => x.key === posLabel)
		return filterPos
	}
	const addCategory = () => {
		if (props.parentAdd) {
			props.addCategory(values)
		} else {
			props.editCategory(edit, edit.id)
		}
	}
	const removeCategory = () => {
		let removeCategory = edit
		removeCategory.status = 'Deleted'
		props.removeCategory(removeCategory, removeCategory.id)
	}
	return (
		<Row className="row-margin">
			{props &&
				props.fields &&
				props.fields.length > 0 &&
				props.fields.map((item, index) => {
					if (item.type != 'hidden') {
						return (
							<Col xs="12" key={index}>
								<FormGroup>
									<Label for="exampleEmail">{item.label || item.name}</Label>
									<Input
										type={item.type || 'text'}
										value={values[item.name] ? values[item.name] : (props && props.editVal && props.editVal[item.name]) || ''}
										onChange={onChange.bind(this)}
										name={item.name || index}
										placeholder={item.placeholder}
									/>
								</FormGroup>
							</Col>
						)
					}
				})}

			<Col className="col-md-12">
				<Button color="success" className="pull-right" outline onClick={addCategory.bind(this)} size="sm">
					{' '}
					<span className="fa fa-save"></span> {props.parentAdd ? props.t('Add') : props.t('Update')} {values.levelLabel || ''}
				</Button>
				<div className="clearfix"></div>
			</Col>
			<p>&nbsp;</p>
		</Row>
	)
}

const DeleteModal = props => {
	const deleteItems = () => {
		props.deleteItems(true)
	}
	return (
		<Modal backdrop={false} isOpen={props.delModal} toggle={props.openModalDelete} className="category-modal">
			<ModalBody style={{ padding: 'unset' }}>
				<h4 className="modal-min-heading">{props.t('Delete')}</h4>
				<form className="custom-from">{props.t('Are you sure, you want to delete?')}</form>
			</ModalBody>
			<div className="add-faq">
				<div className="tools pull-right" style={{ padding: '20px 10px' }}>
					<span style={{ cursor: 'pointer' }} color="secondary" onClick={props.openModalDelete}>
						<img src={`assets/img/no.png`} alt="" />
						{props.t('Close')}
					</span>
					&nbsp; &nbsp;
					<span style={{ cursor: 'pointer' }} color="primary" onClick={deleteItems.bind(this)}>
						<img src={`assets/img/yes.png`} alt="" />
						{props.t('Delete')}
					</span>{' '}
				</div>
			</div>
		</Modal>
	)
}
