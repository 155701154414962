import Joi from 'joi-browser'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { Col, Container, Row } from 'reactstrap'
import { CardDetail, ChartDetail, ListDetail, SectionBoard } from '.'
import { Loader } from '../..'
import { TabDetail } from '../../base/entity/components'
import { getObjByPath } from '../../helpers/utils'
import * as schemas from '../schemas'
class Report extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			filters: props.filters, // page level filters. array of filter keys. mapped to fields.
			url: props.url, // api endpoint for the call.
			filterValues: null, // state of all filters in the reports page and non page with its current values.
			metrics: props.metrics, // metrics available to be shown in page.
			defaultFilters: props.defaultFilters, // initial values of page level filters to get data. Not all filters need to be set for fetching data. Object of key value pairs.
		}
	}
	componentDidMount() {
		let that = this
		let props = this.props
		const {
			meta: { reportsMeta, apis },
		} = this.props

		if (reportsMeta.initialValues && (!this.state || !this.state.initialValuesSet)) {
			this.setState(
				{
					...this.state,
					initialValuesSet: true,
					...reportsMeta.initialValues,
				},
				() => {
					if (reportsMeta.apis) {
						reportsMeta.apis.forEach(a => {
							let api = apis[a]
							let targetProp = (api && api.response && api.response.targetProp) || 'data'
							let obj = getObjByPath({ ref: props.reports, targetProp })
							if (api && !obj) that.executeApi({ apiMeta: api, values: this.state.filterValues, targetProp })
						})
					}
				}
			)
		} else {
			if (reportsMeta.apis) {
				reportsMeta.apis.forEach(a => {
					let api = apis[a]
					let targetProp = (api && api.response && api.response.targetProp) || 'data'
					let obj = getObjByPath({ ref: props.reports, targetProp })
					if (api && !obj) that.executeApi({ apiMeta: api, values: this.state.filterValues, targetProp })
				})
			}
		}
	}
	executeApi({ apiMeta, values, targetProp }) {
		this.props.executeApi({ apiMeta, values, targetProp, t: this.props.t, apiUrl: this.props.tenant && this.props.tenant.apiUrl })
	}
	componentWillReceiveProps(props) {
		let val = Joi.validate(props.meta, schemas.reportsMeta)
		if (val.error) {
			console.error(`${this.props.moduleName || (this.props.entityList ? this.props.entityList.entityName : 'Entity')} has schema error: ${val.error}`)
		} else {
			let filterValues = this.state.filterValues || props.reports.inputs || props.filterValues

			this.setState({ filterValues: filterValues })
			// let filterValues = this.state && this.state.filterValues || props.filterValues
			// if (!filterValues) {
			//     filterValues = {}
			//     filterValues = Object.assign({}, props.defaultFilters || {})
			// }

			// this.setState({
			//     filters: props.filters,
			//     dataUrl: props.dataUrl,
			//     metrics: props.metrics,
			//     defaultFilters: props.defaultFilters,
			//     filterValues
			// })
		}
	}
	/**
	 * To be called from any deep child for updating the filters at Dashboard level
	 * @param {*} param0
	 */
	handleChange({ name, value }) {
		// value could be a single value or array of values if multiple non-continous.. if continuous array of two boundaries.
		let filterValues = this.state.filterValues || this.props.filterValues
		filterValues = filterValues || {}
		let oldValue = this.state.filterValues && this.state.filterValues[name]
		this.setState(
			{
				...this.state,
				filterValues: {
					...filterValues,
					[name]: value,
				},
			},
			() => this.checkCallApis({ name, newValue: value, oldValue })
		)
	}

	handleSelection({ name, value }) {
		let filterValues = this.state.filterValues || this.props.filterValues
		filterValues = filterValues || {}
		let oldValue = this.state.filterValues && this.state.filterValues[name]
		this.setState(
			{
				...this.state,
				filterValues: {
					...filterValues,
					[name]: value,
				},
			},
			() => this.checkCallApis({ name, newValue: value, oldValue })
		)
	}

	setPageFilters({ filterValues }) {
		this.setState({
			...this.state,
			filterValues,
		})
	}
	renderItem(item) {
		if (!item) return
		const { charts, sections, cards, lists, reportsMeta, tabs } = this.props.meta
		const {
			reports: { data },
		} = this.props
		const { filterValues } = this.state
		const title = (reportsMeta && reportsMeta.title) || (this.props.meta.name || this.props.moduleName) + ' Dashboard'
		let itemDef

		let dependsOn
		switch (item.type) {
			case 'section':
				itemDef = sections[item.name]
				if (itemDef)
					return (
						<div className="row" key={item.name}>
							<SectionBoard
								meta={this.state.meta}
								title={title}
								data={data}
								moduleName={this.props.moduleName}
								key={item.name}
								name={item.name}
								filterValues={filterValues}
								{...this.props}
								handleChange={this.handleChange.bind(this)}
								handleSelection={this.handleSelection.bind(this)}
								setPageFilters={this.setPageFilters.bind(this)}
							/>
						</div>
					)
				break
			case 'card':
				itemDef = cards[item.name]
				if (itemDef) {
					if (itemDef.dependsOnFields && itemDef.dependsOnFields.pop && filterValues) {
						dependsOn = {}
						itemDef.dependsOnFields.forEach(f => {
							dependsOn[f] = filterValues[f]
						})
					}
					return (
						<div key={item.name}>
							<CardDetail
								key={item.name}
								data={data}
								dependsOn={dependsOn}
								name={item.name}
								card={itemDef}
								filterValues={filterValues}
								handleChange={this.handleChange.bind(this)}
								{...this.props}
							/>
						</div>
					)
				}
				break
			case 'tabGroup':
				itemDef = tabs[item.name]
				if (itemDef)
					return (
						<React.Fragment key={item.name}>
							<TabDetail
								meta={this.state.meta}
								tabGroupItem={itemDef}
								title={title}
								data={data}
								moduleName={this.props.moduleName}
								key={item.name}
								name={item.name}
								filterValues={filterValues}
								{...this.props}
								handleChange={this.handleChange.bind(this)}
								handleSelection={this.handleSelection.bind(this)}
								setPageFilters={this.setPageFilters.bind(this)}
							/>
						</React.Fragment>
					)
				break

			case 'chart':
				itemDef = charts[item.name]
				if (itemDef) {
					if (itemDef.dependsOnFields && itemDef.dependsOnFields.pop && filterValues) {
						dependsOn = {}
						itemDef.dependsOnFields.forEach(f => {
							dependsOn[f] = filterValues[f]
						})
					}
					return (
						<div key={item.name}>
							<ChartDetail chart={itemDef} {...this.props} data={data} handleChange={this.handleChange.bind(this)} />
						</div>
					)
				}
				break
			case 'list':
				itemDef = lists[item.name]
				if (itemDef) {
					if (itemDef.dependsOnFields && itemDef.dependsOnFields.pop && filterValues) {
						dependsOn = {}
						itemDef.dependsOnFields.forEach(f => {
							dependsOn[f] = filterValues[f]
						})
					}
					return (
						<div key={item.name}>
							<ListDetail list={itemDef} {...this.props} data={data} handleChange={this.handleChange.bind(this)} />
						</div>
					)
				}
				break

			default:
		}
	}
	checkCallApis({ name, newValue, oldValue }) {
		const {
			meta: { reportsMeta, apis },
		} = this.props
		let that = this
		if (reportsMeta.apis) {
			reportsMeta.apis.forEach(a => {
				let api = apis[a]
				let targetProp = (api && api.response && api.response.targetProp) || 'data'
				if (api && api.dependsOnFields && api.dependsOnFields.includes(name) && oldValue !== newValue) {
					that.executeApi({ apiMeta: api, values: this.state.filterValues, targetProp })
				}
			})
		}
	}
	render() {
		const {
			reports: { loading },
			meta,
		} = this.props
		// let that = this
		// let filterValues = this.state.filterValues || (this.props.reports && this.props.reports.filterValues)
		const { reportsMeta } = meta
		const itemsByCols = {}
		// const apisInvoked = (this.state && this.state.apis) || {}
		const title = (reportsMeta && reportsMeta.title) || (this.props.meta.name || this.props.moduleName) + ' Dashboard'
		reportsMeta &&
			reportsMeta.cols &&
			reportsMeta.items.forEach(i => {
				if (i.col) {
					itemsByCols[i.col] = itemsByCols[i.col] || []
					itemsByCols[i.col].push(i)
				} else {
					itemsByCols.unassigned = itemsByCols.unassigned || []
					itemsByCols.unassigned.push(i)
				}
			})
		itemsByCols.unassigned = itemsByCols.unassigned || (reportsMeta && !reportsMeta.cols && reportsMeta.items)

		const t = this.props.t

		if (reportsMeta.cols && itemsByCols.unassigned) return <div>{`Error: Some elements are not assigned to columns ${itemsByCols.unassigned} `}</div>
		else
			return (
				<div>
					<div className="animated fadeIn">{loading && <Loader />}</div>
					<div className={reportsMeta.classes}>
						<Container>
							<Row>
								<div className="heading">
									<h5>{t(title)}</h5>
								</div>
							</Row>
							{reportsMeta && reportsMeta.cols && (
								<Row className="my-4">
									{reportsMeta.cols.map((c, i) => {
										return (
											<Col sm={c}>
												{itemsByCols[i + 1] &&
													itemsByCols[i + 1].map(item => {
														return this.renderItem(item)
													})}
											</Col>
										)
									})}
								</Row>
							)}
							{itemsByCols &&
								itemsByCols.unassigned &&
								itemsByCols.unassigned.map(item => {
									return this.renderItem(item)
								})}
						</Container>
					</div>
				</div>
			)
	}
}
export default withTranslation()(Report)
