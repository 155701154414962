import ticketCommonServices from '../services/ticketCommon.services'
import BaseActions from './BaseActions'
import { ScoreCardActions } from './scoreCardActions'

let options = {}
let serviceOptions = {}
class TicketCommonActionModel extends BaseActions {}

export const getItems = (tenantId, queryString, moduleName, targetTenantDomain, isAdavanceSearch, t, apiUrl, headerData) => {
	return ticketActions(moduleName).getAll(tenantId, queryString, targetTenantDomain, isAdavanceSearch, t, apiUrl, headerData)
}
export const getTicketsByFilter = (tenantId, queryString, moduleName, sortOrder, targetTenantDomain, t, apiUrl) =>
	ticketActions(moduleName).getTicketsByFilter(tenantId, queryString, sortOrder, targetTenantDomain, t, apiUrl)
export const getSubmittedByTickets = (tenantId, queryString, moduleName, sortOrder, targetTenantDomain, t, apiUrl) =>
	ticketActions(moduleName).getSubmittedByTickets(tenantId, queryString, sortOrder, targetTenantDomain, t, apiUrl)
export const getMyAllItems = (tenantId, queryString, moduleName, sortOrder, searchParams, t, apiUrl) =>
	ticketActions(moduleName).getMyAllTickets(tenantId, queryString, sortOrder, searchParams, t, apiUrl)
export const getAllWatchingTickets = (tenantId, queryString, moduleName, sortOrder, searchParams, targetTenantDomain, t, apiUrl) =>
	ticketActions(moduleName).getAllWatchingTickets(tenantId, queryString, moduleName, sortOrder, searchParams, targetTenantDomain, t, apiUrl)
export const getAllMSPAssignedTickets = (tenantId, queryString, moduleName, sortOrder, searchParams, targetTenantDomain, t, apiUrl) =>
	ticketActions(moduleName).getAllMSPAssignedTickets(tenantId, queryString, moduleName, sortOrder, searchParams, targetTenantDomain, t, apiUrl)
export const getAllMSPAssignedQueueTickets = (tenantId, queryString, moduleName, sortOrder, searchParams, targetTenantDomain, t, apiUrl) =>
	ticketActions(moduleName).getAllMSPAssignedQueueTickets(tenantId, queryString, moduleName, sortOrder, searchParams, targetTenantDomain, t, apiUrl)
export const getAllPendingWithMeTickets = (tenantId, queryString, moduleName, sortOrder, searchParams, targetTenantDomain, t, apiUrl) =>
	ticketActions(moduleName).getAllPendingWithMeTickets(tenantId, queryString, moduleName, sortOrder, searchParams, targetTenantDomain, t, apiUrl)
export const getSearchItems = (tenantId, queryString, moduleName, sortOrder, t, apiUrl) =>
	ticketActions(moduleName).getSearchItems(tenantId, queryString, moduleName, sortOrder, t, apiUrl)
export const getAssignedItems = (tenantId, queryString, moduleName, sortOrder, searchParams, t, apiUrl) =>
	ticketActions(moduleName).getAssignedTickets(tenantId, queryString, sortOrder, searchParams, t, apiUrl)
export const getMyQueueItems = (tenantId, queryString, moduleName, sortOrder, searchParams, t, apiUrl) =>
	ticketActions(moduleName).getMyQueueTickets(tenantId, queryString, sortOrder, searchParams, t, apiUrl)
export const getItemsByParams = (tenantId, id, queryString, moduleName, targetTenantDomain, t, apiUrl) =>
	ticketActions(moduleName).getAllWithParams(tenantId, id, queryString, targetTenantDomain, t, apiUrl)
export const addItem = (data, tenantId, moduleName, messageType, targetTenantDomain, userRoles, t, apiUrl, headerData) =>
	ticketActions(moduleName).addNewItem(data, tenantId, messageType, targetTenantDomain, userRoles, t, apiUrl, headerData)
export const addItemWithParams = (data, tenantId, id, moduleName, targetTenantDomain, t, apiUrl) =>
	ticketActions(moduleName).addNewWithParams(data, id, tenantId, targetTenantDomain, t, apiUrl)
export const updateItem = (data, tenantId, id, moduleName, targetTenantDomain, t, apiUrl) =>
	ticketActions(moduleName).editItem(data, tenantId, id, targetTenantDomain, t, apiUrl)
export const updateTicketStatus = (data, tenantId, id, moduleName, targetTenantDomain, t, apiUrl) =>
	ticketActions(moduleName).updateTicketStatus(data, tenantId, id, targetTenantDomain, t, apiUrl)
export const deleteItem = (id, tenantId, moduleName, t, apiUrl) => ticketActions(moduleName).deleteItemWithParams(id, null, tenantId, t, apiUrl)
export const deleteItemWithParams = (id, tenantId, paramId, moduleName, targetTenantDomain, t, apiUrl) =>
	ticketActions(moduleName).deleteItemWithParams(id, paramId, tenantId, t, apiUrl)
export const notifyEmail = (id, moduleName, t, apiUrl) => ticketActions(moduleName).notifyEmail(id, t, apiUrl)
export const addAttachmentWithParams = (data, tenantId, id, moduleName, isDirty, targetTenantDomain, t, apiUrl) =>
	ticketActions(moduleName).addAttachmentWithParams(data, id, tenantId, isDirty, targetTenantDomain, t, apiUrl)

export const deleteAttachmentWithParams = (data, tenantId, paramId, id, moduleName, isDirty, targetTenantDomain, apiUrl) =>
	ticketActions(moduleName).deleteAttachmentWithParams(data, id, paramId, tenantId, isDirty, targetTenantDomain, apiUrl)

let scoreCardActions = new ScoreCardActions({ resourceName: 'ScoreCard' })
export const executeApi = ({ tenantId, values, apiMeta, targetProp, t, apiUrl }) =>
	scoreCardActions.executeApi({ tenantId, values, apiMeta, targetProp, t, apiUrl })
export const getAllItems = (tenantId, queryString, moduleName, sortOrder, t, apiUrl) =>
	ticketActions(moduleName).getAllTickets(tenantId, queryString, sortOrder, t, apiUrl)
function ticketActions(moduleName) {
	serviceOptions.urlItemName = moduleName
	serviceOptions.apiModuleUrl = 'ticketing'
	options.resourceName = moduleName
	serviceOptions.wsType = 'ticket'
	serviceOptions.wsEnabled = true
	options.service = new ticketCommonServices(serviceOptions)
	return new TicketCommonActionModel(options)
}
