// import { Input, Label } from 'reactstrap';
import { Box, FormControl, FormLabel, Grid, Input, InputGroup, Switch, VStack } from '@chakra-ui/react'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
class CustomJsonClone extends Component {
	constructor(props) {
		console.log('props:: ', props)
		super(props)
		this.state = {
			field: props.field,
			key: '',
			value: '',
			hirerachy: false,
			hirarchyItemsExt: [{ parent: null, hirearchyInner: [{ value: '', valueLabel: '' }] }],
			hirarchyItems: [
				{ key: '', defaultValues: [], values: [{ parentValue: null, value: '', valueLabel: '' }], level: 1, label: '', userAttrKey: '', hierarchy: false },
			],
			lastLevelEnabled: false,
			loadedData: false,
		}
	}

	componentDidUpdate() {
		let { formValues, field } = this.props
		let { hirarchyItems, loadedData, hirerachy, lastLevelEnabled } = this.state
		if (!loadedData && field && formValues && formValues[field.name]) {
			console.log('formValues[field.name]::: ', formValues[field.name])
			hirarchyItems = formValues[field.name]

			if (hirarchyItems && hirarchyItems[0]) {
				hirerachy = hirarchyItems[0].hierarchy

				if (
					hirerachy &&
					hirarchyItems[hirarchyItems.length - 1] &&
					hirarchyItems[hirarchyItems.length - 1].values &&
					hirarchyItems[hirarchyItems.length - 1].values.length > 1
				) {
					lastLevelEnabled = !lastLevelEnabled
				}
			}

			this.setState({ hirarchyItems, loadedData: !loadedData, hirerachy, lastLevelEnabled })
		}
	}

	handleKeyPairAdd({ name, key, value, removedKey, isDeleted }) {
		const { formValues } = this.state
		let fieldValue = formValues[name]
		if (isDeleted && fieldValue) delete fieldValue[removedKey]
		else fieldValue = Object.assign({}, fieldValue, { [key]: value })

		this.props.handleChange && this.props.handleChange(name, fieldValue, this.props.t)
	}

	handleKeyChange(e) {
		this.setState({
			key: e.target.value,
		})
	}
	handleValueChange(e) {
		this.setState({
			value: e.target.value,
		})
	}

	handleAddItem(name) {
		if (this.state.key && this.state.value) this.props.handleAddItem({ name, key: this.state.key, value: this.state.value, t: this.props.t })

		this.setState({
			key: '',
			value: '',
		})
	}

	addHirerachyItem(index) {
		let { hirarchyItems, hirerachy, field } = this.state

		let parentValue = null
		console.log('addHirerachyItem:: ', hirarchyItems)

		let level = index == 0 ? 2 : index + 2

		if (hirerachy) {
			parentValue = (hirarchyItems[index] && hirarchyItems[index].values && hirarchyItems[index].values[0] && hirarchyItems[index].values[0].value) || null
		}

		hirarchyItems.push({
			key: '',
			defaultValues: [],
			values: [{ parentValue: parentValue, value: '', valueLabel: '' }],
			level,
			label: '',
			userAttrKey: '',
			hierarchy: hirarchyItems[index].hierarchy,
		})

		console.log(`Add hirearchy item:: ${field.name}:: ${hirarchyItems}`)
		this.props.handleCustomJsonClone(field.name, hirarchyItems, this.props.t)

		this.setState({ hirarchyItems })
	}

	getParentValue(i) {}

	removeHirerachyItem(index) {
		let { hirarchyItems } = this.state

		hirarchyItems.pop()

		this.setState({ hirarchyItems })
		return true
	}

	handleHirearchyItemInner(parentIndex) {
		let { hirarchyItems, hirerachy } = this.state

		let parentValue = null
		let parentIndexPrev = parentIndex > 0 ? parentIndex - 1 : parentIndex
		console.log('@@@@@parentValue::', parentValue)
		if (hirerachy) {
			console.log('parentIndex:: ', parentIndex)
			console.log('hirarchyItems[parentIndex]?.values[0]?.value:: ', hirarchyItems)
			parentValue = (hirarchyItems[parentIndexPrev] && hirarchyItems[parentIndexPrev].values[0] && hirarchyItems[parentIndexPrev].values[0].value) || null
		}

		hirarchyItems[parentIndex].values.push({ parentValue: parentValue, value: '', valueLabel: '' })

		this.setState({ hirarchyItems })
	}

	deleteHirearchyItemInner(parentIndex, i) {
		let { hirarchyItems } = this.state

		hirarchyItems[parentIndex].values.splice(i, 1)

		this.setState({ hirarchyItems })
	}

	enableHirearchy(e, i) {
		let { hirarchyItems, field } = this.state

		hirarchyItems = [
			{
				key: '',
				defaultValues: [],
				values: [{ parentValue: null, value: '', valueLabel: '' }],
				level: 1,
				label: '',
				userAttrKey: '',
				hierarchy: e.target.checked,
			},
		]

		// hirarchyItems[i].hierarchy = e.target.checked

		// if(e.target.checked) {
		//     hirarchyItems = hirarchyItems.map((elem, i) => {
		//         if(i > 0) {
		//             elem.hierarchy = true
		//         }

		//         elem.values = [elem.values[0]]

		//         return elem
		//     })
		// } else {
		//     hirarchyItems = hirarchyItems.map((elem, i) => {
		//         if(i > 0) {
		//             elem.hierarchy = false
		//         }

		//         return elem
		//     })
		// }
		this.setState({ hirerachy: e.target.checked, hirarchyItems })

		this.props.handleCustomJsonClone(field.name, hirarchyItems, this.props.t)
	}

	enableLastLevelHirearchy(e, i) {
		let { hirarchyItems } = this.state

		hirarchyItems[i].hierarchy = !e.target.checked

		if (!e.target.checked) {
			hirarchyItems[i].values = [hirarchyItems[i].values[0]]
		}
		this.setState({ hirarchyItems, lastLevelEnabled: e.target.checked })
	}

	updateParentData(value, name, i) {
		let { hirarchyItems, field } = this.state

		hirarchyItems[i][name] = value

		if (name == 'key') {
			hirarchyItems[i]['userAttrKey'] = value
		}

		this.setState({ hirarchyItems })

		this.props.handleCustomJsonClone(field.name, hirarchyItems, this.props.t)
	}

	updateValues(value, name, parentIndex, i) {
		let { hirarchyItems, field } = this.state

		hirarchyItems[parentIndex].values[i][name] = value

		this.setState({ hirarchyItems })

		this.props.handleCustomJsonClone(field.name, hirarchyItems, this.props.t)
	}

	render() {
		const { field, hirarchyItems, hirerachy, lastLevelEnabled } = this.state
		const { t } = this.props
		return (
			<VStack>
				<Box>
					<FormControl display="flex" alignItems="center">
						<FormLabel>{t(field.label)}</FormLabel>
					</FormControl>
				</Box>
				{hirarchyItems &&
					hirarchyItems.map((elem, index) => (
						<Grid key={index} templateColumns="repeat(3, 1fr)" gap={2}>
							<Box>
								<FormControl display="flex" alignItems="left">
									<InputGroup size="sm">
										<Input name={`key`} placeholder={t("Key")} value={elem.key} onChange={e => this.updateParentData(e.target.value, 'key', index)}></Input>

										<Input name={`label`} placeholder={t("Label")} value={elem.label} onChange={e => this.updateParentData(e.target.value, 'label', index)}></Input>
									</InputGroup>
									{/* {!hirerachy && <span onClick={this.handleAddItem.bind(this, field.name)}><i className="fa fa-plus"></i></span>} */}
								</FormControl>
							</Box>

							<VStack key={index} borderWidth={elem.hierarchy ? '' : '1px'} borderRadius="lg" spacing={1}>
								{elem &&
									elem.values &&
									elem.values.map((el, i) => (
										<Box key={i}>
											<InputGroup key={i} size="sm">
												<Input
													name={`${field.name}_key`}
													placeholder={t("Value")}
													value={el.value}
													onChange={e => this.updateValues(e.target.value, 'value', index, i)}></Input>

												<Input
													name={`${field.name}_value`}
													placeholder={t("Label")}
													value={el.valueLabel}
													onChange={e => this.updateValues(e.target.value, 'valueLabel', index, i)}></Input>
												{/* {!elem.hierarchy && i!=0  && i+1 == elem.values.length && <span onClick={this.deleteHirearchyItemInner.bind(this, index, i)}><i className="fa fa-times"></i></span>}
                                    {!elem.hierarchy && i+1 == elem.values.length && <span onClick={this.handleHirearchyItemInner.bind(this, index)}><i className="fa fa-plus"></i></span>} */}
											</InputGroup>
										</Box>
									))}

								{/* {index == 0  &&
                                <Box>
                                    <FormLabel htmlFor="enable-hirerachy" mb="0">
                                        Enable Hirerchy
                                    </FormLabel>
                                    <Switch name="hirerachy" size="md" id="enable-hirerachy" onChange={(e) => this.enableHirearchy(e, index)} />
                                </Box>
                            }

                            {index > 0 && hirarchyItems[index-1].hierarchy &&
                                <Box>
                                    <FormLabel htmlFor="enable-hirerachy" mb="0">
                                        Last Level
                                    </FormLabel>
                                    <Switch name="hirerachy" size="md" id="enable-hirerachy" onChange={(e) => this.enableHirearchy(e, index)} />
                                </Box>
                            } */}
							</VStack>

							<Box>
								{/* {index == 0  &&
                                <FormLabel htmlFor="enable-hirerachy" mb="0">
                                    Enable Hirerchy
                                    <Switch name="hirerachy" isChecked={hirerachy} size="md" id={"enable-hirerachy-" + index} onChange={(e) => this.enableHirearchy(e, index)} />
                                    
                                </FormLabel>
                            } */}

								{index > 0 && index + 1 == hirarchyItems.length && hirarchyItems && hirarchyItems[0].hierarchy && (
									<FormLabel htmlFor={'enable-hirerachy-last-' + index} mb="0">
										{t('Last Level')}
										<Switch
											name="lasthirerachy"
											isChecked={lastLevelEnabled}
											size="md"
											id={'enable-hirerachy-last-' + index}
											onChange={e => this.enableLastLevelHirearchy(e, index)}
										/>
									</FormLabel>
								)}

								{index != 0 && index + 1 == hirarchyItems.length && (
									<span onClick={this.removeHirerachyItem.bind(this, index)}>
										<i className="fa fa-times"></i>
									</span>
								)}

								{!lastLevelEnabled && index + 1 == hirarchyItems.length && (
									<span onClick={this.addHirerachyItem.bind(this, index)}>
										<i className="fa fa-plus"></i>
									</span>
								)}
							</Box>
						</Grid>
					))}
			</VStack>
		)
	}
}

export default withTranslation()(CustomJsonClone)
