import { identityConstants } from '../'


const initialState = {
  isAuthenticated: false,
  isInitiated: false,
  userInfo: {},
  token: null,
}

export const identity = (state = initialState, action) => {
  switch (action.type) {
    case identityConstants.LOGIN_INITIATE:
      return {
        ...state,
        ...action.payload
      }
    case identityConstants.LOGIN_SUCCESS:
    return {
        ...state,
        ...action.payload
    }
    case identityConstants.LOGIN_FAILURE :
      return {
        ...state,
        ...action.payload
      }   
      case identityConstants.SET_IDENTITY :
      return {
        ...state,
        ...action.payload
      } 
      case identityConstants.LOGOUT_SUCCESS :
      return {
        ...initialState
      }  
      case identityConstants.SET_ROLE :
      return {
        ...state,
        userInfo:{ roles: action.payload}
      }                
    default:
      return state
  }
}