import { TreeState, TreeTable } from 'cp-react-tree-table'
import React from 'react'
import { withTranslation } from 'react-i18next'
// import styles from './ReportTable.module.css'
import { filterMetricRow, getObjByPath, prepareFilters } from '../../helpers/utils'
// import DataSet from "@antv/data-set";

const MOCK_DATA = genData().map(r => {
	if (r.data) {
		r.data.opened = false
	}
	return r
})

class ReportTable extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			treeValue: TreeState.create(MOCK_DATA),
		}
	}

	componentWillReceiveProps(props) {
		let dependsOn = props.dependsOn
		let changed
		if (dependsOn && Object.keys(dependsOn).length) {
			if (this.state && this.state.dependsOn)
				changed = Object.keys(dependsOn).some(k => {
					if (dependsOn[k] !== this.state.dependsOn[k]) return true
					return false
				})
			else changed = true
		} else {
			changed = true
		}
		let filteredData = this.processData(props)
		const treeValue = TreeState.create(MOCK_DATA) //filteredData || MOCK_DATA
		this.setState({ ...props, dependsOn, filteredData, treeValue }, () => {
			changed && props.treeTable.api && this.getValue()
		})
	}
	getValue() {
		let api = this.props.treeTable.api
		let apiMeta
		if (this.props.treeTable.api) {
			let targetProp = api.response && api.response.targetProp
			targetProp = targetProp || 'charts.' + this.props.treeTable.name
			apiMeta = this.props.meta.apis && this.props.meta.apis[api]
			this.props.executeApi({ apiMeta, values: this.props.filterValues, targetProp, t: this.props.t, apiUrl: this.props.tenant && this.props.tenant.apiUrl })
		}
	}
	processData(props) {
		let data
		const { treeTable } = props
		let targetProp
		let apiMeta
		if (treeTable && treeTable.api) {
			apiMeta = this.props.meta.apis && this.props.meta.apis[treeTable.api]
			targetProp = apiMeta && apiMeta.response && apiMeta.response.targetProp
			targetProp = targetProp || 'treeTables.' + this.props.treeTable.name
			data = props.reports[targetProp]
		} else data = props.data
		// let title = props.treeTable && props.treeTable.title

		const { dataProp } = props.treeTable
		const { dependsOn } = this.state
		const { filterValues } = props

		// let body
		let tableData
		// const metric = metrics[0]
		let filteredData
		if (data) {
			if (dataProp) {
				if (dataProp === 'data') tableData = data
				else tableData = getObjByPath({ ref: data, path: dataProp })
			} else tableData = data
			filteredData = filterMetricRow({ data: tableData, filters: treeTable.filters })
			// console.log(`Filtered: ${JSON.stringify(filteredData)}`) // static filters

			if (dependsOn && treeTable.dynFilters) {
				let filters = prepareFilters({ filtersObj: treeTable.dynFilters, values: filterValues })
				filteredData = filterMetricRow({ data: filteredData, filters }) // dynamic filters
			}
			// console.log(`Dyn Filtered: ${JSON.stringify(filteredData)}`)
		}
		// convert to tree from array if not already in tree.

		return (
			filteredData &&
			filteredData.map(r => {
				if (r.data)
					// already in proper format
					return r
				else {
					const { data, children, height, ...rest } = r // extract children, height. club data and rest into data.
					return { data: { ...data, ...rest }, children, height }
				}
			})
		)
	}

	render() {
		const { treeValue } = this.state
		const { treeTable } = this.props
		// const treeValue = TreeState.create( MOCK_DATA)  //filteredData || MOCK_DATA

		if (treeTable) {
			return (
				<TreeTable height={treeTable.height || '300'} className="my-3 border rounded" value={treeValue} onChange={this.handleOnChange}>
					{treeTable.fields &&
						treeTable.fields.map((f, i) => {
							return (
								<TreeTable.Column
									key={f.name}
									basis={i === 0 ? '180px' : '0px'}
									grow={i === 0 ? 0 : 1}
									renderCell={i === 0 ? this.renderIndexCell.bind(this, f) : this.renderCell.bind(this, f)}
									renderHeaderCell={() => <span className={f.headerClass == undefined ? 't-right' : f.headerClass}>{f.label || f.name}</span>}
								/>
							)
						})}
				</TreeTable>
			)
		} else return <></>
	}

	handleOnChange = newValue => {
		this.setState({ treeValue: newValue })
	}

	isNodeStateOpen(row) {
		let open

		if (row.metadata.hasChildren) {
			const nextRow = row.metadata.index + 1
			let nr = this.state.treeValue.data.length >= nextRow && this.state.treeValue.data[nextRow]
			if (nr) open = nr.$state.isVisible
		}
		return open
	}

	renderIndexCell = (colData, row) => {
		let nodeState = this.isNodeStateOpen(row)
		let classes = colData.classes || ''
		return (
			<div style={{ paddingLeft: row.metadata.depth * 15 + 'px' }} className={row.metadata.hasChildren ? 'with-children' : 'without-children'}>
				{row.metadata.hasChildren ? <button className={'toggle-button ' + (nodeState ? 'open' : 'closed')} onClick={this.rowClick.bind(this, row)}></button> : ''}

				<span className={' w-100 ' + classes}>{row.data[colData.name]}</span>
			</div>
		)
	}

	rowClick(row, event) {
		// let fasdf = 1
		// console.log(row)
		let nodeState = this.isNodeStateOpen(row)
		row.updateData({
			...row.data,
			opened: !nodeState,
		})
		row.toggleChildren()
	}
	renderCell = (colData, row) => {
		let classes = colData.classes || ''
		let nodeState = this.isNodeStateOpen(row)
		if (colData && colData.type === 'number') classes += nodeState ? ' font-weight-bold font-italic border-top-2 border-bottom-2 text-right' : ' text-right'
		return <span className={' w-100 ' + classes}>{row.data[colData.name]}</span>
	}

	// renderEditableCell = (row) => {
	//   return (
	//     <input type="text" value={row.data.contact}
	//       onChange={(event) => {
	//         row.updateData({
	//           ...row.data,
	//           contact: event.target.value,
	//         });
	//       }} />
	//   );
	// }
}

export default withTranslation()(ReportTable)

function genData() {
	return [
		{
			data: { name: 'Company A', expenses: '60,000', employees: '5', contact: 'Nicholas Watson' },
			height: 32,
		},
		{
			data: { name: 'Company B', expenses: '70,000', employees: '5', contact: 'Dani Hopkinson' },
			height: 32,
		},
		{
			data: { name: 'Company C', expenses: '50,000', employees: '4', contact: 'Jacob Ellery' },
			height: 32,
		},
		{
			data: { name: 'Company D', expenses: '230,000', employees: '9', contact: 'Kate Stewart' },
			height: 32,
		},
		{
			data: { name: 'Company E', expenses: '310,000', employees: '8', contact: 'Louise Fall' },
			height: 32,
		},
		{
			data: { name: 'Company F', expenses: '110,000', employees: '5', contact: 'Owen Thompson' },
			height: 32,
		},
		{
			data: { name: 'Company G', expenses: '250,000', employees: '18', contact: 'Fred Wilton' },
			height: 32,
		},
		{
			data: { name: 'Company H', expenses: '180,000', employees: '7', contact: 'William Dallas' },
			height: 32,
		},
		{
			data: { name: 'Company I', expenses: '105,000', employees: '22', contact: 'Makenzie Higgs' },
			children: [
				{
					data: { name: 'Department 1', expenses: '75,000', employees: '18', contact: 'Florence Carter' },
					children: [
						{
							data: { name: 'Group alpha', expenses: '25,000', employees: '8', contact: 'Doug Moss' },
						},
						{
							data: { name: 'Group beta', expenses: '10,000', employees: '6', contact: 'Camila Devonport' },
						},
						{
							data: { name: 'Group gamma', expenses: '40,000', employees: '4', contact: 'Violet Curtis' },
						},
					],
				},
				{
					data: { name: 'Department 2', expenses: '30,000', employees: '4', contact: 'Selena Rycroft' },
					height: 32,
				},
			],
		},
		{
			data: { name: 'Company J', expenses: '370,000', employees: '13', contact: 'Ron Douglas' },
			height: 32,
		},
		{
			data: { name: 'Company K', expenses: '500,000', employees: '15', contact: 'Michael Jacobs' },
			height: 32,
		},
		{
			data: { name: 'Company L', expenses: '230,000', employees: '10', contact: 'Stephanie Egerton' },
			height: 32,
		},
		{
			data: { name: 'Company M', expenses: '90,000', employees: '25', contact: 'Michael Buckley' },
			height: 32,
		},
		{
			data: { name: 'Company N', expenses: '370,000', employees: '13', contact: 'Sabrina Rowlands' },
			height: 32,
		},
		{
			data: { name: 'Company O', expenses: '500,000', employees: '15', contact: 'Lana Watt' },
			height: 32,
		},
		{
			data: { name: 'Company P', expenses: '230,000', employees: '10', contact: 'Evelynn Calderwood' },
			height: 32,
		},
		{
			data: { name: 'Company Q', expenses: '90,000', employees: '25', contact: 'Jade Morley' },
			height: 32,
		},
	]
}
