
'use strict'

import { EntityActions } from "../../common/base/entity"

export class WorkSpaceAction extends EntityActions {

    postActionsHooks({ actionName, item, propFields }){
        if(!item) return
        return item
    }
    
    getFns() {
        let that = this
        let fns = super.getFns()
        Object.values(fns).forEach( f => f.bind(that))
        return fns
    }

}