import { identityServices } from '../common'
// import { EntityService } from '../common/base/entity/services/entity.services'
const format = require('string-format')
var subdomain = window.location.host.split('.')[1] ? window.location.host.split('.')[0] : false
// let { store, persistor } = configureStore
class ClientsEntityService {
	constructor(options) {
		this.apiUrl = this.apiUrl || sessionStorage.getItem('apiUrl')
	}
	getClientSecret(tenantId, clientId, apiUrl) {
		if (tenantId === undefined || tenantId == null) Promise.reject('`Error : Missing Tenant Id ')
		if (!apiUrl) apiUrl = sessionStorage.getItem('apiUrl')
		const requestOptions = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
			url: `${apiUrl}/role-management/clients/secret?clientId=${clientId}`,
		}

		return identityServices.identityApi.request(requestOptions).then(response => {
			if (response.status !== 200) {
				return Promise.reject(`Unable to fetch the api secret : Error code : ${response.status}`)
			}

			return response.data
		})
	}

	resetClientSecret(tenantId, clientId, apiUrl) {
		if (tenantId === undefined || tenantId == null) Promise.reject('`Error : Missing Tenant Id ')
		if (clientId === undefined || clientId == null) Promise.reject('`Error : Missing Client Id ')
		if (!apiUrl) apiUrl = sessionStorage.getItem('apiUrl')
		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			data: {
				realm: tenantId,
				clientId: clientId,
			},
			url: `${apiUrl}/role-management/clients/secret`,
		}

		return identityServices.identityApi.request(requestOptions).then(response => {
			if (response.status !== 200) {
				return Promise.reject(`Unable to reset the api secret : Error code : ${response.status}`)
			}

			return response.data
		})
	}
	getAllClients(tenantId, apiUrl) {
		if (!apiUrl) apiUrl = sessionStorage.getItem('apiUrl')
		const requestOptions = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},

			url: `${apiUrl}/role-management/clients`,
		}

		return identityServices.identityApi.request(requestOptions).then(response => {
			if (response.status !== 200) {
				return Promise.reject(`Unable to get clients : Error code : ${response.status}`)
			}

			return response.data
		})
	}
}
export { ClientsEntityService }
