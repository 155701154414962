import React from 'react'
import { Loader } from '../common'

import { withTranslation } from 'react-i18next'

class Client extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			clients: {},
		}
	}
	componentDidMount() {
		this.props.getAllClients()
	}

	handleView(client) {
		const { profile, t } = this.props
		let tenantId = profile && profile.tenantId
		if (client && client.clientId && !client.secret) {
			this.props.getClientSecret(tenantId, client.clientId)
		}
		let clients = this.state.clients || {}
		clients[client.clientId] = true
		this.setState({ clients })
	}
	handleReset(client) {
		const { profile, t } = this.props
		let tenantId = profile && profile.tenantId
		if (client && client.clientId) {
			this.props.resetClientSecret(tenantId, client.clientId)
		}
	}
	/**
	 * render all subscriptions including defaultProducts that are not subscribed
	 * Show subscribed apps with their current plans  ( ability to unsubscribe or change plan)
	 * Show the default subscribed ones ( with ability to change plan)
	 * Show the unsubscribed ones with ability to Subscribe with a chosen plan.  All actions are confirmed once
	 *   ( defaultPlans, defaultProducts contain the unsubscribed ones)
	 */
	render() {
		const t = this.props.t
		const { loading, clients, client } = this.props.realmClient || {}
		const clientsView = this.state?.clients || {}
		let that = this
		return (
			<div>
				<div className="animated fadeIn">{loading && <Loader />}</div>
				<div className="main-inner">
					<div className="table-responsive">
						<table className="table " role="table">
							<thead>
								<tr role="row">
									<th role="columnheader">{t ? t('Client Id') : "Client Id"}</th>
									<th role="columnheader">{(t && t('Client Secret')) || 'Client Secret'}</th>
									<th role="columnheader">{(t && t('Actions')) || 'Actions'}</th>
								</tr>
							</thead>
							<tbody role="rowgroup">
								{clients &&
									clients.map(c => {
										return (
											<tr role="row">
												<td role="cell"> {c.clientId}</td>
												<td role="cell">
													{' '}
													{(clientsView[c.clientId] && (client?.value || c.secret)) || (
														<span title="View Secret" className="al-icon-col" onClick={that.handleView.bind(that, c)}>
															<i className="fa fa-eye fa-lg"></i>
															<button title="View" className="btn btn-link">
																{(t && t('View')) || 'View'}
															</button>
														</span>
													)}
												</td>
												<td role="cell">
													<div className="action-buttons">
														<span title="Reset Secret" className="al-icon-col" onClick={that.handleReset.bind(that, c)}>
															<i className="fa fa-refresh fa-lg"></i>
															<button title="Reset" className="btn btn-link">
																Reset
															</button>
														</span>
													</div>
												</td>
											</tr>
										)
									})}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		)
	}
}
export default withTranslation()(Client)
