import DataSet from '@antv/data-set'
import { Axis, Chart, Coord, Geom, Label, Legend, Tooltip } from 'bizcharts'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { filterMetricRow, getObjByPath, mathOperation, prepareFilters } from '../../helpers/utils'

class ChartDetail extends React.Component {
	constructor(props) {
		super(props)
		this.state = {}
	}

	componentDidMount() {
		if (this.props.chart.api) {
			this.getValue()
		}
	}

	componentWillReceiveProps(props) {
		let dependsOn = props.dependsOn
		let changed
		// let body = (this.state && this.state.body) || '-'
		if (dependsOn && Object.keys(dependsOn).length) {
			if (this.state && this.state.dependsOn)
				changed = Object.keys(dependsOn).some(k => {
					if (dependsOn[k] !== this.state.dependsOn[k]) return true
					return false
				})
			else changed = true
		} else {
			changed = true
		}
		// body = this.processData(props)
		this.setState({ ...props, dependsOn })
	}
	getValue() {
		let api = this.props.chart.api
		let apiMeta
		if (this.props.chart.api) {
			let targetProp = api.response && api.response.targetProp
			targetProp = targetProp || 'charts.' + this.props.chart.name
			apiMeta = this.props.meta.apis && this.props.meta.apis[api]
			this.props.executeApi({ apiMeta, values: this.props.filterValues, targetProp, t: this.props.t, apiUrl: this.props.tenant && this.props.tenant.apiUrl })
		}
	}
	processData(props) {
		let data
		const { chart } = props
		let targetProp
		let apiMeta
		if (chart && chart.api) {
			apiMeta = this.props.meta.apis && this.props.meta.apis[chart.api]
			targetProp = apiMeta && apiMeta.response && apiMeta.response.targetProp
			targetProp = targetProp || 'charts.' + this.props.chart.name
			data = props.dashboard[targetProp]
		} else data = props.data
		// let title = props.chart && props.chart.title
		let metrics = props.chart && props.chart.metrics // usually this is an aggregate transform on data
		const { dataProp } = props.chart
		const { dependsOn } = this.state
		const { filterValues } = props
		let dv

		// let body
		let chartData
		// const metric = metrics[0]
		if (metrics && metrics.pop) {
			if (data) {
				if (dataProp) {
					if (dataProp === 'data') chartData = data
					else chartData = getObjByPath({ ref: data, path: dataProp })
				} else chartData = data
				// const metric = metrics[0]
				// const as = metric.as || metric.fields
				let filteredData = filterMetricRow({ data: chartData, filters: chart.filters })
				// console.log(`Filtered: ${JSON.stringify(filteredData)}`) // static filters

				if (dependsOn && chart.dynFilters) {
					let filters = prepareFilters({ filtersObj: chart.dynFilters, values: filterValues })
					filteredData = filterMetricRow({ data: filteredData, filters }) // dynamic filters
				}
				// console.log(`Dyn Filtered: ${JSON.stringify(filteredData)}`)
				// @ts-ignore
				dv = new DataSet.View().source(filteredData)
			}
		}
		return dv
	}
	render() {
		const { chart, filterValues, t, tenantConfig } = this.props
		const { title, metrics, axes, series } = chart

		// const colors = ["cyan",
		//     "lb", "lg", "ly", "db", "lpurple",
		//     "bg", "mb", "lpink"]
		let dv = this.processData(this.props)

		// let color = colors[Math.floor(Math.random() * colors.length)]
		// if (chart.classes && !chart.classes.includes('bg-gradient-'))
		//     chart.classes = chart.classes + ' bg-gradient-' + color
		let cols
		if (axes && axes.pop) {
			cols = {}
			axes.forEach(a => {
				let obj = {}
				if (a.alias) obj.alias = a.alias
				if (a.range) obj.range = a.range
				if (a.type) obj.type = a.type
				cols[a.field] = obj
			})
		}

		/**
         * <Axis name="createdDate" title />
                    <Axis
                        name="metric"
                        label={{
                            formatter: val => `${val}hrs`
                        }}
                    title                
                    />
         */
		// let rows = dv.rows
		// each metric should be a field on row...
		// x axis is also a field on row..
		// my value, queue value...

		let finalRows = [],
			formatter,
			tooltips
		if (dv && metrics && metrics.pop) {
			metrics.forEach(m => {
				let dv1
				// @ts-ignore
				dv1 = new DataSet.View().source(dv)
				if (m.filters) {
					let filters = prepareFilters({ filtersObj: m.filters, values: filterValues })
					filterMetricRow({ dv: dv1, filters }) // dynamic filters
				}

				let baseObj = {
					type: 'aggregate',
					fields: [m.field],
					operations: m.operations,
					as: [m.field],
				}

				if (chart.chartType === 'ring' && m.operations.includes('percent')) {
					let baseObj1 = {
						type: 'aggregate',
						operations: ['sum', 'sum'],
						as: [`${chart.value}Copy`, `${chart.value}`],
						groupBy: m.groupBy,
						fields: [`${chart.value}`, `${chart.value}`],
					}
					tooltips = [
						`${chart.value}Copy*${chart.series}`,
						(value, series) => {
							return {
								name: t(series),
								value: value,
							}
						},
					]
					dv1.transform(baseObj1)

					baseObj.type = 'percent'
					baseObj.dimension = series
					formatter = (val, item) => {
						return t(item._origin[chart.series]) + ':\n' + (Number(val) * 100).toFixed(2) + ' %'
					}
				} else if (m.operations) {
					baseObj.groupBy = m.groupBy
					formatter = (val, item) => {
						return item._origin[chart.series] + ': ' + Number(val).toFixed(2)
					}
				}

				dv1.transform(baseObj)

				dv1.transform({
					type: 'map',
					callback: row => {
						row.series = m.series
						return row
					},
				})
				finalRows = finalRows.concat(dv1.rows)
			})
		}
		//label={{formatter: val => `${val}c.label`}}
		// finalRows = dv && dv.rows
		if (!finalRows || !finalRows.length) return <></>

		if (chart.settingValue) {
			let settingValue = chart.settingValue
			let tc = tenantConfig && tenantConfig.data && tenantConfig.data.length > 0 && tenantConfig.data.find(o => o.configType == 'defaultSetting' && o.name == 'metrics')
			let tcProperties = tc && tc.properties && tc.properties
			if (tcProperties && tcProperties[settingValue.name]) {
				finalRows.forEach(rows => {
					let operationSymbol = mathOperation({ operation: settingValue.operation })
					rows.avg = eval(`${rows.avg} ${operationSymbol} ${tcProperties[settingValue.name]}`)
				})
			}
		}

		// if(chart.chartType === 'dualAxis') console.log(`Final Rows: ${JSON.stringify(finalRows)}`)
		switch (chart.chartType) {
			case 'lines':
				/** have enough padding to show the titles and legends otherwise they are cut off */
				return (
					<div className="border my-2  py-2 shadow rounded">
						<h5 className="text-center"> {title || 'Chart'} </h5>
						<Chart height={chart.height || 300} data={finalRows} scale={cols} forceFit padding={chart.padding || [30, 20, 100, 80]} className={chart.classes}>
							<Legend />
							{cols &&
								Object.keys(cols).map(c => {
									// let col = cols[c]
									// let l = col.label
									return <Axis name={c} title key={c} />
								})}

							<Tooltip
								crosshairs={{
									type: 'cross',
								}}
							/>
							<Geom type="line" position={chart.xy || 'createdDate*metric'} size={2} color={'series'} shape={'smooth'} />
							{/* for drawing the points  */}
							<Geom
								type="point"
								position={chart.xy || 'createdDate*metric'}
								size={4}
								shape={'circle'}
								color={'series'}
								style={{
									stroke: '#fff',
									lineWidth: 1,
								}}>
								{chart.labels && (
									<Label
										content={[
											chart.xy,
											(x, y) => {
												return `${Number(y).toFixed(2)}`
											},
										]}
									/>
								)}
							</Geom>
						</Chart>
					</div>
				)
			case 'bars':
				return (
					<div className="border my-2  py-2  shadow rounded">
						<h5 className="text-center"> {title || 'Chart'} </h5>
						<Chart height={chart.height || 300} data={finalRows} forceFit padding={chart.padding || [20, 20, 50, 75]} className={chart.classes}>
							<Legend />
							<Coord transpose scale={[1, -1]} />
							{cols &&
								Object.keys(cols).map((c, i) => {
									// let col = cols[c]
									// let l = col.label
									return <Axis name={c} position={(i === 1 && 'right') || 'left'} label={i === 0 && { offset: 10 }} title key={c} />
								})}
							{/* <Axis name={c} position={ i===1 && "right"} label={ i ===0 && {offset:10}}/> */}

							<Tooltip />
							<Geom
								type="interval"
								position={chart.xy || 'metricName*metric'}
								color={chart.series}
								adjust={[
									{
										type: 'dodge',
										marginRatio: 1 / 32,
									},
								]}>
								{chart.labels && (
									<Label
										content={[
											chart.xy,
											(x, y) => {
												return `${Number(y).toFixed(2)}`
											},
										]}
									/>
								)}
							</Geom>
						</Chart>
					</div>
				)
			case 'ring':
				// <Axis name="sold" title={axisTitle}/>
				return (
					<div className="border my-2  py-2 shadow rounded">
						<h5 className="text-center"> {title || 'Chart'} </h5>
						<Chart forceFit width={500} height={chart.height || 300} data={finalRows} scale={cols} className={chart.classes} padding={chart.padding || [80, 100, 80, 80]}>
							{/* <Axis name="genre" title={axisTitle} /> */}
							<Coord type={'theta'} radius={1} innerRadius={0.6} />
							{cols &&
								Object.keys(cols).map((c, i) => {
									// let col = cols[c]
									// let l = col.label
									return <Axis name={c} title key={c} />
								})}

							{/* <Legend position="top" dy={-20} /> */}
							<Tooltip />
							<Geom
								tooltip={tooltips}
								// tooltip={[chart.series + '*percent',(item, percent) => {
								//     percent = Number(percent) * 100 + '%';
								//     return {
								//       name: item,
								//       value: percent
								//     };
								//   }]}
								type="intervalStack"
								position={chart.value}
								color={t(chart.series)}>
								<Label content={chart.value} formatter={formatter} />
							</Geom>
						</Chart>
					</div>
				)
			case 'histogram':
				return (
					<div className="border my-2 py-2 shadow rounded">
						<h5 className="text-center"> {title || 'Chart'} </h5>
						<Chart forceFit width={900} height={chart.height || 300} data={finalRows} scale={cols} className={chart.classes} padding={chart.padding || [50, 50, 80, 80]}>
							{cols &&
								Object.keys(cols).map((c, i) => {
									// let col = cols[c]
									// let l = col.label
									return <Axis name={c} title key={c} />
								})}
							<Tooltip />
							<Geom
								type="interval"
								position={chart.xy}
								color={chart.series}
								adjust={[
									{
										type: 'dodge',
										marginRatio: 1 / 32,
									},
								]}>
								{chart.labels && (
									<Label
										content={[
											chart.xy,
											(x, y) => {
												return `${Number(y).toFixed(2)}`
											},
										]}
									/>
								)}
							</Geom>
						</Chart>
					</div>
				)
			case 'dualAxis':
				let xys = chart.xy.split('*')
				// const x = xys[0]
				const y = xys[1]
				const y2 = chart.xy2 && chart.xy2.split('*')[1]

				return (
					<div className="border my-2 py-2 shadow rounded">
						<h5 className="text-center"> {title || 'Chart'} </h5>

						<Chart
							scale={cols}
							height={chart.height || 300}
							width={500}
							data={finalRows}
							padding={chart.padding || [20, 20, 60, 80]}
							forceFit
							onGetG2Instance={chart1 => {
								this.chartIns = chart1
							}}>
							<Legend
								custom={true}
								allowAllCanceled={true}
								items={[
									{
										value: y,
										marker: {
											symbol: 'square',
											fill: '#3182bd',
											radius: 5,
										},
									},
									{
										value: y2,
										marker: {
											symbol: 'hyphen',
											stroke: '#ffae6b',
											radius: 5,
											lineWidth: 3,
										},
									},
								]}
								onClick={ev => {
									const item = ev.item
									const value = item.value
									const checked = ev.checked
									const geoms = this.chartIns.getAllGeoms()

									for (let i = 0; i < geoms.length; i++) {
										const geom = geoms[i]

										if (geom.getYScale().field === value) {
											if (checked) {
												geom.show()
											} else {
												geom.hide()
											}
										}
									}
								}}
							/>
							{cols &&
								Object.keys(cols).map((c, i) => {
									// let col = cols[c]
									return <Axis name={c} title key={c} />
								})}

							<Tooltip />

							<Geom
								type="interval"
								position={chart.xy}
								color={chart.series}
								adjust={[
									{
										type: 'dodge',
										marginRatio: 1 / 32,
									},
								]}>
								{chart.labels && (
									<Label
										content={[
											chart.xy,
											(x, y) => {
												return `${Number(y).toFixed(2)}`
											},
										]}
									/>
								)}
							</Geom>
							<Geom type="line" position={chart.xy2} color="#fdae6b" size={3} shape="smooth" />
							<Geom type="point" position={chart.xy2} color="#fdae6b" size={3} shape="circle" />
						</Chart>
					</div>
				)
			default:
				return <></>
		}
	}
}
export default withTranslation()(ChartDetail)
