// import { apiUrl, headerParams} from '../../../config';
import axios from 'axios'
import { BaseService } from '../services/base.services'
let options = {}
options.urlItemName = 'whiteLabel'

class whiteLabelServices extends BaseService {
	getDataWithDefaultTenant(tenantId, apiUrl, userInfo) {
		if (!apiUrl) apiUrl = sessionStorage.getItem('apiUrl')
		const requestOptions = {
			method: 'GET',
			headers: { 'Content-Type': 'application/json', 'x-tenantid': tenantId, usecase: localStorage.getItem('product') || (userInfo && userInfo.defaultTeam) },
			url: `${apiUrl}/${this.urlItemName}/findWithDefaultTenant`,
		}

		// if(headerParams){
		//     requestOptions.headers = {}
		//     let headerParamsConfig = JSON.parse(headerParams);
		//     headerParamsConfig.forEach(p =>{
		//         requestOptions.headers[p.name] = p.value
		//     })
		// }

		return axios.request(requestOptions).then(response => {
			if (response.status !== 200 && response.status !== 304) {
				return Promise.reject(response)
			}
			return { rows: response.data }
		})
	}
}
export const whiteLabelService = new whiteLabelServices(options)
