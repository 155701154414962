import Editor, { useMonaco } from '@monaco-editor/react'
import React, { useEffect, useMemo, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { Button, Label, Modal, ModalBody } from 'reactstrap'
import { identityServices } from '../../../../common'
import { formFilters } from '../../../helpers/utils'

const CRUDModal = props => {
	const [open, setOpen] = useState(props.isOpen)
	const [completionValue, setCompletionValue] = useState('')
	const [editorValue, setEditorValue] = useState('')

	const monaco = useMonaco()

	const editorOptions = useMemo(
		() => ({
			minimap: { enabled: false },
			scrollBeyondLastLine: false,
			tabSize: 2,
			theme: 'light',
			wordWrap: 'off',
			lineNumbers: 'off',
			lineNumbersMinChars: 0,
			overviewRulerLanes: 0,
			overviewRulerBorder: false,
			lineDecorationsWidth: 0,
			hideCursorInOverviewRuler: true,
			automaticLayout: true,
			selectionHighlight: true,
			glyphMargin: false,
			folding: false,
			scrollBeyondLastColumn: 0,
			scrollbar: { horizontal: 'hidden', vertical: 'hidden' },
			find: { addExtraSpaceOnTop: false, autoFindInSelection: 'never', seedSearchStringFromSelection: false },
		}),
		[]
	)

	const handleEditorChanged = (name, lang) => (_value, event) => {
		let value = _value
		setEditorValue(value)
	}

	useEffect(() => {
		const ShowAutocompletion = (lang, obj) => {
			// Disable default autocompletion for javascript
			monaco.languages.typescript.javascriptDefaults.setCompilerOptions({ noLib: true })

			// Helper function to return the monaco completion item type of a thing
			function getType(thing, isMember) {
				isMember = isMember == undefined ? (typeof isMember == 'boolean' ? isMember : false) : false // Give isMember a default value of false

				switch ((typeof thing).toLowerCase()) {
					case 'object':
						return monaco.languages.CompletionItemKind.Class

					case 'function':
						return isMember ? monaco.languages.CompletionItemKind.Method : monaco.languages.CompletionItemKind.Function

					default:
						return isMember ? monaco.languages.CompletionItemKind.Property : monaco.languages.CompletionItemKind.Variable
				}
			}

			// Register object that will return autocomplete items
			monaco.languages.registerCompletionItemProvider(lang, {
				// Run this function when the period or open parenthesis is typed (and anything after a space)
				triggerCharacters: ['.', '('],

				// Function to generate autocompletion results
				provideCompletionItems: function (model, position, token) {
					// Split everything the user has typed on the current line up at each space, and only look at the last word
					var last_chars = model.getValueInRange({ startLineNumber: position.lineNumber, startColumn: 0, endLineNumber: position.lineNumber, endColumn: position.column })
					var words = last_chars.replace('\t', '').split(' ')
					var active_typing = words[words.length - 1] // What the user is currently typing (everything after the last space)

					// If the last character typed is a period then we need to look at member objects of the obj object
					var is_member = active_typing.charAt(active_typing.length - 1) == '.'

					// Array of autocompletion results
					var result = []

					// Used for generic handling between member and non-member objects
					var last_token = obj
					var prefix = ''

					if (is_member) {
						// Is a member, get a list of all members, and the prefix
						var parents = active_typing.substring(0, active_typing.length - 1).split('.')
						last_token = obj[parents[0]]
						prefix = parents[0]

						// Loop through all the parents the current one will have (to generate prefix)
						for (var i = 1; i < parents.length; i++) {
							if (last_token.hasOwnProperty(parents[i])) {
								prefix += '.' + parents[i]
								last_token = last_token[parents[i]]
							} else {
								// Not valid
								return result
							}
						}

						prefix += '.'
					}

					// Get all the child properties of the last token
					for (var prop in last_token) {
						// Do not show properites that begin with "__"
						if (last_token.hasOwnProperty(prop) && !prop.startsWith('__')) {
							// Get the detail type (try-catch) incase object does not have prototype
							var details = ''
							try {
								details = last_token[prop].__proto__.constructor.name
							} catch (e) {
								details = typeof last_token[prop]
							}

							// Create completion object
							var to_push = {
								label: prefix + prop,
								kind: getType(last_token[prop], is_member),
								detail: details,
								insertText: prop,
							}

							// Change insertText and documentation for functions
							if (to_push.detail.toLowerCase() == 'function') {
								to_push.insertText += '('
								to_push.documentation = last_token[prop].toString().split('{')[0] // Show function prototype in the documentation popup
							}

							// Add to final results
							result.push(to_push)
						}
					}

					return {
						suggestions: result,
					}
				},
			})
		}
		async function fetchData(api) {
			const requestOptions = {
				method: `${api.method || 'GET'}`,
				headers: { 'Content-Type': 'application/json' },
				url: `${this.props.apiUrl || (this.props.tenant && this.props.tenant.apiUrl)}/${api.path}`,
			}
			if (api.path.indexOf('?') !== -1) requestOptions.url += '&'
			else requestOptions.url += '?'
			if (api.filters && api.filters.pop) {
				requestOptions.url += '$filter='
				requestOptions.url += formFilters({ filters: api.filters })
			}
			if (api.params && api.params.filter && api.params.filter.pop) {
				let filterValues = Object.assign({}, props.formValues, props.dependsOn || {})
				if (requestOptions.url.indexOf('?') !== -1) requestOptions.url += '&$filter='
				else requestOptions.url += '$filter='
				api.params.filter.forEach((p, i, a) => {
					if (i === a.length - 1) requestOptions.url += `${p} eq '${filterValues[p] || null}'`
					else requestOptions.url += `${p} eq '${filterValues[p] || null}' and`
				})
			}

			if (api.params && api.params.orderBy && api.params.orderBy.pop) {
				requestOptions.url += '&$orderby='
				api.params.orderBy.forEach((p, i, a) => {
					if (i === a.length) requestOptions.url += `${p}`
					else requestOptions.url += `${p}`
				})
			}

			let limit = api.limit
			if (api && api.limit) {
				requestOptions.url += `&$top=${limit}`
			}

			if (api.body && api.body.pop) {
				requestOptions.json = true
				requestOptions.body = {}
				api.body.forEach(p => {
					requestOptions.body[p] = props.dependsOn[p]
				})
			}
			try {
				let res
				let response = await identityServices.identityApi.request(requestOptions)
				if (api.response) {
					if (api.response.ref) {
						let refs = api.response.ref.split('.')
						res = refs.reduce((p, c) => {
							return p[c]
						}, response)
					} else res = response
					if (res && res.length && api.response.value) {
						if (api.response.value.includes('.')) {
							let refsValue = api.response.value.split('.')
							res = res[0]
							res = refsValue.reduce((p, c) => {
								return p[c]
							}, res)
						} else {
							res = api.response.value
						}
					}

					if (res) setCompletionValue(res)
				}
				return res
			} catch (err) {
				console.error(props.t(`Attributes fetch for ${props.name} failed with error ${err}. Url ${requestOptions.url}`))
			}
		}
		const field = props.fields.filter(fieldData => fieldData.type === 'monaco-editor')
		if (field && field[0].autoCompletionApiProps && monaco) {
			let api = field[0].autoCompletionApiProps.api
			if (!api) {
				return
			}
			fetchData(api)
			ShowAutocompletion(field[0].lang, completionValue)
		}
	}, [monaco, completionValue, props])

	const renderField = fieldData => {
		const { name, label, type, options, lang, classes, entity } = fieldData
		const value = props.formValues ? props.formValues[name] : ''

		let field = null
		let inputField = null

		if (type === 'monaco-editor') {
			const language = lang || 'JSON'
			const _value = value || ''
			const content = typeof _value === 'string' ? _value : JSON.stringify(_value, null, 2)

			inputField = <Editor height={'150px'} defaultLanguage={language} defaultValue={content} onChange={handleEditorChanged(name, language)} options={editorOptions} />
		}
		// } else {
		//     inputField = <Input isDisabled={_isDisabled} type={type === 'text' ? "" : type}
		//                         value={value || ""} onChange={onChange(name)}
		//                         borderColor={value ? "#CCC" : "#EEE"} bg={fieldBgColor}/>
		// }

		field = <>{inputField}</>

		return (
			<div key={name}>
				<div className={'custom-form-grp row clearfix'}>
					<div className="col-sm-4">
						{label && (
							<Label className="float-right" for={name}>
								{props.t(label)}
							</Label>
						)}
					</div>
					<div className={classes || `col-sm-8`}>{field}</div>
				</div>
			</div>
		)
	}

	const fields = props.fields.map(fieldData => renderField(fieldData))

	const confirm = () => {
		setOpen(false)
		props.updateOptions(false, editorValue)
	}

	const cancel = () => {
		setOpen(false)
		props.updateOptions(false)
	}
	return (
		<Modal autoFocus={false} backdrop={false} isOpen={open} toggle={confirm} className="delete-card ticket-form">
			<h4 className="modal-min-heading">{props.t(props.promptMessage)}</h4>
			<ModalBody className="ticket-inner-form">{fields}</ModalBody>
			<div className="button-wrap" style={{ alignItems: 'center' }}>
				<Button color="primary" onClick={confirm}>
					<img src={`assets/img/yes.png`} alt="yes" />
					{props.t('Yes')}
				</Button>{' '}
				<Button color="primary" onClick={cancel}>
					<img src={`assets/img/no.png`} alt="no" />
					{props.t('No')}
				</Button>
			</div>
		</Modal>
	)
}

export default withTranslation()(CRUDModal)
