import { registerTenantConstants } from '..';

export const registerNewTenant = (state = {loading: true, isValidated: false, registrationInitiated: false, isRegistering: false}, action) => {
    switch (action.type) {



        case registerTenantConstants.CHECK_TENANT_EXISTS_REQUEST:
        return {
          ...state,
          isValidated: false,
        };
        case registerTenantConstants.CHECK_TENANT_EXISTS_SUCCESS:
          if(action.payload) {
            return {
              ...state,
              registerTenantExists: action.payload,
              isValidated: true
            }
          } else {
            return {
              ...state,
              isValidated: true
            }
          }
        case registerTenantConstants.CHECK_TENANT_EXISTS_FAILURE:
          return {
            ...state,
            tenantExists: [],
            error: action.payload,
            isValidated: false
          };

          case registerTenantConstants.REGISTER_TENANT_REQUEST:
          return {
            ...state,
            registrationInitiated: true,
            registrationFailed: false
          };
          case registerTenantConstants.REGISTER_TENANT_SUCCESS:
            return {
              ...state,
              registrationInitiated: false,
            }
          case registerTenantConstants.REGISTER_TENANT_FAILURE:
            return {
              ...state,
              error: action.payload,
              registrationInitiated: false,
              registrationFailed: true
            };
  
            case registerTenantConstants.TENANT_LIFECYCLE_REQUEST:
            return {
              ...state,
              isRegistering: false,
            };
            case registerTenantConstants.TENANT_LIFECYCLE_SUCCESS:
              if(action.payload) {
                return {
                  ...state,
                  tenantLifeCycle: action.payload,
                  isRegistering: true
                }
              } else {
                return {
                  ...state,
                  isRegistering: true
                }
              }
            case registerTenantConstants.TENANT_LIFECYCLE_FAILURE:
              return {
                ...state,
                tenantLifeCycle: null,
                error: action.payload,
                isRegistering: false,
                registrationFailed: true,
              };
// OTP validation case
      case registerTenantConstants.VALIDATE_OTP_REQUEST:
        return {
          ...state,
          isValidated: false,
        };
        case registerTenantConstants.VALIDATE_OTP_SUCCESS:
          if(action.payload) {
            return {
              ...state,
              registerTenantExists: action.payload,
              isValidated: true
            }
          } else {
            return {
              ...state,
              isValidated: true
            }
          }
        case registerTenantConstants.VALIDATE_OTP_FAILURE:
          return {
            ...state,
            tenantExists: [],
            error: action.payload,
            isValidated: false
          };
// get an OTP
case registerTenantConstants.GET_OTP_REQUEST:
  return {
    ...state,
    isValidated: false,
  };
  case registerTenantConstants.GET_OTP_SUCCESS:
    console.log("\n\n::: ", action.payload)
    if(action.payload) {
      return {
        ...state,
        otpData: action.payload,
        isValidated: true,
      }
    } else {
      return {
        ...state,
        isValidated: true
      }
    }
  case registerTenantConstants.GET_OTP_FAILURE:
    return {
      ...state,
      error: action.payload,
      isValidated: false
    };

        default:
            return state
    }
}