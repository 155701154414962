import * as clientConstants from './clients.constants'
export const realmClient = (state = { loading: false }, action) => {
	switch (action.type) {
		case clientConstants.GET_CLIENT_SECRET_REQUEST:
			return {
				...state,
				client: undefined,
				loading: true,
			}
		case clientConstants.GET_CLIENT_SECRET_SUCCESS:
			if (action.payload) {
				return {
					...state,
					loading: false,
					client: action.payload,
				}
			} else {
				return {
					...state,
					client: undefined,
					loading: false,
				}
			}
		case clientConstants.GET_CLIENT_SECRET_FAILURE:
			return {
				...state,
				loading: false,
				client: undefined,
				error: action.payload,
			}
		case clientConstants.RESET_CLIENT_SECRET_REQUEST:
			return {
				...state,
				client: undefined,
				loading: true,
			}
		case clientConstants.RESET_CLIENT_SECRET_SUCCESS:
			if (action.payload) {
				return {
					...state,
					loading: false,
					client: action.payload,
				}
			} else {
				return {
					...state,
					client: undefined,
					loading: false,
				}
			}
		case clientConstants.RESET_CLIENT_SECRET_FAILURE:
			return {
				...state,
				loading: false,
				client: undefined,
				error: action.payload,
			}
		case clientConstants.GETALL_CLIENTS_REQUEST:
			return {
				...state,
				loading: true,
				clients: [],
			}
		case clientConstants.GETALL_CLIENTS_SUCCESS:
			return {
				...state,
				loading: false,
				clients: action.payload,
				count: action.payload?.count || action.payload?.length,
				searchParams: action.data && action.data.filter,
			}
		case clientConstants.GETALL_CLIENTS_FAILURE:
			return {
				...state,
				clients: [],
				loading: false,
			}

		default:
			return state
	}
}
