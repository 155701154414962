import moment from 'moment'
import React, { Component } from 'react'
import * as Datetime from 'react-datetime'
import { withTranslation } from 'react-i18next'
import Select from 'react-select'
import { FormGroup, Input, Label } from 'reactstrap'
// import { apiUrl } from '../../../../config'
import { identityServices } from '../../../common'
import '../styles/stylekpi.css'

/**
 * fe actions
 *  handleChange
 *  handleChangeSelect
 *  setDependency
 *  fetchApi
 *  textField
 *  selectField
 *  mapSelectFields
 *  setSelectField
 * prop actions
 *  handleChange ( parent component )
 * props prop
 *  field : {type, property : {options: {isStatic, apiUrl, moduleName, listMap: {key, value}}, enums:[] }}
 *  resetFilter
 *  apiOptions : { <field>: []}
 * state
 *  formValues, formField, selectOptions, options, isSelect, fieldType, formValue, resetValue, formOptions, apiOptions, shouldUpdate, onChangeOption
 */
class FieldFilter extends Component {
	constructor(props) {
		super(props)
		let formValues = {}
		formValues[props.field.name] = props.field.value
		this.state = {
			field: props.field || false,
			options: (props.field && props.field.options) || [], // processed options
			isSelect: false, // bool indicating if type is select
			fieldType: (props.field && props.field.type) || 'text', // field.type
			formValues: props.formValues || formValues, // dict of field name and field value passed from the parent.
			dependsOn: props.dependsOn,
			forceDisable: props.forceDisable, // override field level disable false to true
			forceEnable: props.field.forceEnable, // overrided field level disable true to false
			defDisable: props.defDisable, // the default option if not specified at field level
			value: props.field.value,
			calenderOpen: false,
			calFilters: {
				start_date: this.props.calFilters && this.props.calFilters.start_date,
				end_date: this.props.calFilters && this.props.calFilters.end_date,
			},
		}
		this.handleChange = this.handleChange.bind(this)
	}
	componentWillMount() {
		const { field } = this.props
		// let formOptions;
		let formValues = {
			...this.state.formValues,
		}
		this.setState({
			...this.state,
			isSelect: field.type === 'select' ? true : false,
			fieldType: field.type || 'text',
			formValues: this.props.formValues ? this.props.formValues : formValues,
		})
	}
	componentDidMount() {
		let props = this.props
		let dependsOn = props.dependsOn
		let changed, options
		if (dependsOn && Object.keys(dependsOn).length) {
			if (this.state.dependsOn)
				changed = Object.keys(dependsOn).some(k => {
					if (dependsOn[k] !== this.state.dependsOn[k]) return true
					return false
				})
			else changed = true
		} else {
			changed = true
		}
		let formValues = {
			...this.state.formValues,
		}
		if (props.formValues) {
			formValues = {
				...this.props.formValues,
			}
		}
		options = (props.field && props.field.options) || []
		this.setState(
			{
				field: props.field || false,
				options: options, // processed options
				isSelect: false, // bool indicating if type is select
				fieldType: (props.field && props.field.type) || 'text', // field.type
				formValues: props.resetFilter ? {} : formValues, // dict of field name and field value passed from the parent.
				dependsOn: (changed && props.dependsOn) || this.state.dependsOn || this.props.dependsOn,
				forceDisable: props.forceDisable, // override field level disable false to true
				forceEnable: props.forceEnable, // overrided field level disable true to false
				defDisable: props.defDisable, // the default option if not specified at field level
			},
			() => {
				;(changed || options.length === 0) && this.getOptions()
			}
		)
	}

	componentWillReceiveProps(props) {
		this.setState({ calFilters: props.calFilters })
	}
	async getOptions() {
		let api = this.state.field && this.state.field.api
		if (!api) return
		const requestOptions = {
			method: `${api.method || 'GET'}`,
			headers: { 'Content-Type': 'application/json' },
			url: `${this.props.apiUrl || (this.props.tenant && this.props.tenant.apiUrl)}/${api.path}`,
		}
		// if (api.params && api.params.queryString) {
		//     let filterValues = Object.assign({}, this.props.formValues, this.state.dependsOn || {})
		//         api.params.queryString.forEach((r, i, a)=>{
		//             requestOptions.url += `/${filterValues[r]}`;
		//         })
		// }
		if (api.params && api.params.filter && api.params.filter.pop) {
			let filterValues = Object.assign({}, this.state.formValues, this.state.dependsOn || {})
			requestOptions.url += '?$filter='
			api.params.filter.forEach((p, i, a) => {
				if (i === a.length - 1) requestOptions.url += ` ${p} eq '${filterValues[p]}'`
				else requestOptions.url += ` ${p} eq '${filterValues[p]}' and `
			})
			if (api.params.orderBy && api.params.orderBy.pop) {
				requestOptions.url += '&$orderby='
				api.params.filter.forEach((p, i, a) => {
					if (i === a.length) requestOptions.url += `${p}`
					else requestOptions.url += `${p},`
				})
			}
		}
		if (api.body && api.body.pop) {
			requestOptions.json = true
			requestOptions.body = {}
			api.body.forEach(p => {
				requestOptions.body[p] = this.state.dependsOn[p]
			})
		}
		try {
			let response = await identityServices.identityApi.request(requestOptions)
			if (api.response) {
				let coll
				if (api.response.ref) {
					let refs = api.response.ref.split('.')
					coll = refs.reduce((p, c) => {
						return p[c]
					}, response)
					// coll = response[api.response.ref]
				} else coll = response

				if (api.response.labelKey || api.response.valueKey) {
					coll = coll.map(o => {
						return { label: o[api.response.labelKey] || o, value: o[api.response.valueKey] || o }
					})
				}

				this.setState({
					...this.state,
					options: coll,
				})
			}
			return response.data
		} catch (err) {
			console.error(`Options fetch for ${this.state.field.name} failed with error ${err}. Url ${requestOptions.url}`)
		}
	}

	handleChange(event) {
		const { field } = this.state
		if (event) {
			this.props.handleChange && this.props.handleChange({ name: event.target.name, value: event.target.value, t: this.props.t })
			if (field && event.target.value) {
				field.value = event.target.value
				this.setState({
					...this.state,
					field: field,
				})
			}
		}
	}

	handleChangeSelect(field, event) {
		// alert(`FieldFilter selectField ${ JSON.stringify(event.value)}`);
		// const { formValues } = this.state;
		if (event && field.isMulti) {
			let value = event && event.map(o => o.value)
			this.props.handleChange && this.props.handleChange({ name: field.name, value, t: this.props.t })
		} else if (event) {
			this.props.handleChange && this.props.handleChange({ name: field.name, value: event.value || event.label, t: this.props.t })
		}

		// if(event) {
		//     this.setState({
		//         ...this.state,
		//         field.name.value =
		//     })
		// }
	}

	dateTimeChange(type, fieldName, event) {
		let calFilters = this.state.calFilters
		event = event.format('YYYY-MM-DD')
		if (type == 'From') {
			var calenderOpen = false
			calFilters.start_date = event
		} else {
			var calenderOpen = false
			calFilters.end_date = event
		}
		this.props.setDateRange(calFilters, this.props.t)
		this.props.handleChange({ name: fieldName, value: event, t: this.props.t })
		this.setState({
			calenderOpen: calenderOpen,
		})
	}

	toggleCal() {
		this.setState({
			calenderOpen: !this.state.calenderOpen,
		})
	}

	valid = (type, current) => {
		let today = moment()
		let created_at = this.props.tenant.createdAt
		created_at = moment(created_at).subtract(1, 'day')
		if (type == 'From') return current.isBefore(today) && current.isAfter(created_at)
		let Todate = current.isAfter(moment(this.state.calFilters.start_date).subtract(1, 'day').format('YYYY-MM-DD'))
		return (
			current.isAfter(moment(this.state.calFilters.start_date).subtract(1, 'day').format('YYYY-MM-DD')) &&
			current.isBefore(moment(this.state.calFilters.start_date).add(1, 'M').format('YYYY-MM-DD')) &&
			current.isBefore(today)
		)
	}

	calField(field) {
		const { formValues, forceDisable, forceEnable, defDisable } = this.state
		let { t, dependsOn } = this.props
		let label = field.label
		if (label) label = t(label)
		if (label === 'From') {
			field.value = this.state.calFilters.start_date
			// this.props.handleChange({name: field.name, value: value})
		} else {
			field.value = this.state.calFilters.end_date
		}
		let fieldOpen = this.state.calenderOpen

		return (
			<div className="filterKPIs">
				<FormGroup className="custom-margin-form-group">
					<Label for="ex-url">{label}</Label>
					<div className="custom-calendar">
						<span className="set-date-sec" key="schedule_date" onClick={this.toggleCal.bind(this, { label })}>
							{field.value ? moment(field.value, 'YYYY-MM-DD').format('DD MMM YY') : t('Select')}
							<span className="cal-ico">
								<i className="fa fa-calendar fa-lg" />
							</span>
						</span>
						<div className={`custom-calendar-inner ${field.label == 'From' ? 'cal-left-zero' : 'cal-right-zero'}`} style={{ display: fieldOpen ? 'block' : 'none' }}>
							<Datetime
								isValidDate={this.valid.bind(this, field.label)}
								defaultValue={field.value}
								input={false}
								onChange={this.dateTimeChange.bind(this, field.label, field.name)}
								viewDate={field.value}
								timeFormat={false}
							/>
						</div>
					</div>
				</FormGroup>
			</div>
		)
	}

	textField(field) {
		const { formValues, forceDisable, forceEnable, defDisable } = this.state

		let { t, dependsOn } = this.props
		// const t = this.props.t
		let disabled = forceDisable ? true : forceEnable ? false : field.disabled != undefined ? field.disabled : defDisable
		let label = field.label
		if (label) label = t(label)
		let value = formValues && formValues[field.name]
		if (!value) {
			value = field.value
			// this.props.handleChange({name: field.name, value: value})
		}
		if (dependsOn && Object.keys(dependsOn).length) {
			Object.keys(dependsOn).forEach(k => {
				if (field.valueFn) {
					let newValue = field.valueFn(dependsOn[k])
					if (!value || (newValue && value !== newValue)) {
						let newFormValues = {
							...formValues,
						}
						newFormValues[this.props.field.name] = newValue
						this.setState({
							...this.state,
							formValues: newFormValues,
						})
						field.readOnly && this.props.handleChange && this.props.handleChange({ name: field.name, value: newValue, t: this.props.t })
					}
				}
			})
		}

		let htmlProps = {
			label: label,
			name: field.name,
			value: value,
			onChange: this.handleChange.bind(this),
			min: field.min,
			max: field.max,
			type: field.type,
			pattern: field.regexPattern,
			width: field.width,
			disabled: forceDisable ? true : forceEnable ? false : field.disabled != undefined ? field.disabled : defDisable,
			id: field.name,
			placeholder: t(field.placeholder),
			className: field.classes,
			required: field.required,
		}
		let hidden = field.hidden ? true : false
		let fieldType = hidden ? 'hidden' : field.type
		htmlProps.className = '' + htmlProps.class + ' new-custom-input is-valid form-control'
		//{/* valid={this.state.touched && field.required && (!formValues[field.name] || formValues[field.name] === "") ? false : true} */}
		// this will render label above the field
		return (
			<>
				{!field.readOnly && field.type !== 'file' && (
					<div className="form-grp row clearfix">
						{!hidden && <Label for={field.name}>{t(field.label)}</Label>}
						<div className={field.classes}>
							<Input
								key={field.name}
								type={fieldType}
								name={field.name}
								value={value}
								id={field.name}
								readOnly={field.readOnly ? true : false}
								placeholder={field.placeholder}
								onChange={this.handleChange.bind(this)}
								maxLength={field.max || ''}
								disabled={disabled ? true : false}
								invalid={this.state.touched && field.required && (!formValues[field.name] || formValues[field.name] === '') ? true : false}></Input>
							{this.props.touched && field.required && (!formValues[field.name] || formValues[field.name] === '') && (
								<div className="error-message">{field.errorMessage || `${t(field.label)} ${t('is required')}.`}</div>
							)}
						</div>
					</div>
				)}
			</>
		)

		/* <>
        <Label  className="col-sm-3 col-form-label">{htmlProps.label}:</Label>
            <Input 
                {...htmlProps}
            />
        </> */
	}

	selectField(field) {
		const { formValues, forceEnable, defDisable } = this.state
		const t = this.props.t
		let disabled = field.forceDisable ? true : forceEnable ? false : field.disabled != undefined ? field.disabled : defDisable
		let options = this.state.options || field.options
		let label = field.label
		if (label) label = t(label)
		let value =
			field.isMulti && formValues && Array.isArray(formValues[field.name])
				? options.filter(o => (o.value != undefined && formValues[field.name].indexOf(o.value) !== -1) || formValues[field.name].indexOf(o.label) !== -1)
				: options.filter(o => formValues && ((o.value != undefined && formValues[field.name] === o.value) || formValues[field.name] === o.label))
		if (!value || !value.length) {
			value = field.value || ''
			// this.props.handleChange({name: field.name, value:value.value || value.label})
		}
		if (field && field.lengthCheck && options.length <= field.lengthCheck) {
			return
		}

		//alert(`FieldFilter selectField ${ JSON.stringify(value)}`);

		let htmlProps = {
			label: label,
			name: field.name,
			onChange: this.handleChangeSelect.bind(this, field),
			options: options,
			width: field.width,
			required: field.required,
			isMulti: field.isMulti,
			classes: field.classes,
			isDisabled: disabled,
			// value : value
		}
		return (
			options && (
				<div className={'custom-form-grp row clearfix'}>
					<div className="col-sm-4">
						<Label className="col-form-label">{htmlProps.label}:</Label>
					</div>
					<div className={field.classes || `col-sm-8`}>
						<Select {...htmlProps} noOptionsMessage={() => t('No options')} placeholder={t('Select')} />
					</div>
				</div>
			)
		)
	}

	render() {
		const { field, fieldType } = this.state
		// const { formValues} = this.props
		return (
			<>
				{fieldType && fieldType === 'calender' && this.calField(field)}
				{fieldType && fieldType !== 'select' && this.textField(field)}
				{fieldType && fieldType === 'select' && this.selectField(field)}
			</>
		)
	}
}
export default withTranslation()(FieldFilter)
