import { baseConstants } from '../../'
import { executeApi } from '../../helpers/apiHelper'

export class ScoreCardActions {
	constructor(options) {
		this.resourceName = options.resourceName
	}

	executeApi({ tenantId, values, apiMeta, targetProp, t, apiUrl }) {
		let that = this
		if (!apiMeta) return
		return dispatch => {
			dispatch(that.request({ method: 'GET_SCORE_CARDS' }))
			executeApi({ tenantId, values, apiMeta, apiUrl }).then(
				result => {
					if (result) {
						dispatch(
							that.success({ method: 'GET_SCORE_CARDS', item: result, targetProp: targetProp || (apiMeta.response && apiMeta.response.targetProp), data: { inputs: values } })
						)
					} else {
						let errorMsg = `Cannot  get Score Card Data  api ${apiMeta && apiMeta.path} on  ${that.resourceName} +  at this moment please try again later.`
						dispatch(that.failure({ method: 'GET_SCORE_CARDS', errorMsg }))
					}
				},
				error => {
					let errorMsg = `Cannot  get Score Card Data  api ${apiMeta && apiMeta.path} on  ${that.resourceName} +  at this moment please try again later.`
					dispatch(that.failure({ method: 'GET_SCORE_CARDS', errorMsg }))
				}
			)
		}
	}
	request({ method }) {
		let type
		switch (method) {
			case 'GET_SCORE_CARDS':
				type = baseConstants.GET_SCORE_CARDS_REQUEST
				break

			default:
		}
		type = type + '_' + this.resourceName
		return { type: type, resourceName: this.resourceName }
	}
	success({ method, item, itemType = null, targetProp, data }) {
		let type
		switch (method) {
			case 'GET_SCORE_CARDS':
				type = baseConstants.GET_SCORE_CARDS_SUCCESS
				break

			default:
		}
		type = type + '_' + this.resourceName
		return { type, item, resourceName: this.resourceName, itemType, data, targetProp }
	}
	failure({ method, errorMsg }) {
		let type
		switch (method) {
			case 'GET_SCORE_CARDS':
				type = baseConstants.GET_SCORE_CARDS_FAILURE
				break
			default:
		}
		type = type + '_' + this.resourceName
		console.error(`${type}:${errorMsg}`)
		return { type, error: errorMsg, resourceName: this.resourceName }
	}
	getFns() {
		return {
			executeApi: this.executeApi.bind(this),
		}
	}
}
