const mapProps = (props) => {
    if (props && props.type && props.type === 'jsonList') {
        return mapJsonListInput(props)
    }
    else if (props && props.type && props.type === 'jsonObj') {
        return mapJsonObjInput(props)
    }
    else if (props && props.type && props.type === 'jsonDict') {
        return mapJsonDictInput(props)
    }
}


const mapJsonDictInput=(props)=>{
    let returnProps=null;
if(props && isProps(props)){ returnProps=mapJsonObjInput(props)}

else if(props && props.keys && Array.isArray(props.keys) && props.keys.length>0){
   returnProps = mapInputWithDynamicKeys(props);
}

else{}

    return returnProps



}


const mapInputWithDynamicKeys = (props)=>{
    let inputProps=null;
    let mapProps = null;
    if (props && props.propsMeta) {
        mapProps = mapObjProps(props.propsMeta);
    }
    else {
        mapProps = [
            { label: 'Set Key', name: 'keyName', type: 'text' },
            { label: 'Set Value', name: 'keyValue', type: 'text' },

        ]
    }
if(props && props.propsMeta && Array.isArray(props.propsMeta) && props.propsMeta.length>0){
    inputProps = {
        name: props && props.name,
        type: props && props.type ? props.type : 'select',
        isProps: props && props.props && Array.isArray(props.props) ? true : false,
        label: props && props.label ? props.label : props.name ? props.name : 'Select',
        valueType: props && props.valueType ? props.valueType : 'list',
        placeholder: props && props.label ? props.label : props.name ? props.name : 'Select',
        dynamicKey:true,
        propsMeta : props.propsMeta,
        childProps: {
            label: props && props.label ? props.label : props.name ? props.name : 'Select',
            name: props && props.name,
            type: 'select',
            options: mapProps && Array.isArray(mapProps) && mapProps.length > 0 ? mapProps : []
        }
    }
}

    return inputProps
}



const mapJsonListInput = (props) => {
    let inputProps = {
        name: props && props.name ? props.name : 'jsonList',
        type: 'text',
        label: 'Add',
        valueType: 'list',
        placeholder: props && props.label ? props.label : 'Add',
        value: []
    }
    return inputProps
}


const mapJsonObjInput = (props) => {
    let mapProps = null;
    if (props && props.props) {
        mapProps = mapObjProps(props.props);
    }
    else {
        mapProps = [
            { label: 'Set Key', name: 'keyName', type: 'text' },
            { label: 'Set Value', name: 'keyValue', type: [
                {name:'text',label:'Text'},
                {name:'checkbox',label:'Boolean'},
                {name:'textarea',label:'Textarea'},
                // {name:'jsonList',label:'List of Options'}
            ] },

        ]
    }
    let inputProps = {
        name: props && props.name,
        type: props && props.type ? props.type : 'select',
        isProps: props && props.props && Array.isArray(props.props) ? true : false,
        label: props && props.label ? props.label : props.name ? props.name : 'Select',
        valueType: props && props.valueType ? props.valueType : 'list',
        placeholder: props && props.label ? props.label : props.name ? props.name : 'Select',
        childProps: {
            label: props && props.label ? props.label : props.name ? props.name : 'Select',
            name: props && props.name,
            type: 'select',
            options: mapProps && Array.isArray(mapProps) && mapProps.length > 0 ? mapProps : []
        }
    }
    return inputProps
}

const mapObjProps = (props) => {
    if (Array.isArray(props)) {
        let filterProps = props.length > 0 && props.filter((item, idx) => {
            if (isObject(item)) { return item }
            else return false
        }
        )
if(filterProps && filterProps.length>0){
    return filterProps
}
else if(props.length>0){
    props.map((item,idx)=>{
        return filterProps.push({label:item,name:item,type:'text'})

    })

}
else{return filterProps}
        return filterProps
    }
}

const isObject = (value) => {
    return value && typeof value === 'object' && value.constructor === Object;
}

const filterSelectKey = (value, list) => {
    let filteredKey = Array.isArray(list) && list.length > 0 && list.filter((item, idx) => {
        if (isObject(item) && item.name === value) { return item }
        else return false
    })[0]

    return filteredKey
}

const checkValueType = (props, value) => {
    let mapKeyValue = mapKeyValuePair(props, value)

    if (props && props.valueType && props.valueType === 'list') {
        let returnValue = props.value || [];
        returnValue.push(mapKeyValue);
        return returnValue

    }
    else {
        return { ...props.value, ...mapKeyValue }
    }
}

const isProps = (props) => {
    if (props && props.props && Array.isArray(props.props) && props.props.length > 0) { return true } else { return false }
}

const mapKeyValuePair = (props, value) => {
    if (isProps(props)) {
        return value
    }
    else {
        return { [value.keyName]: value.keyValue }
    }

}

const setInputName=(type,name,newName)=>{
    switch (type) {
        case 'jsonObj':
            return newName
            
            case 'jsonList':
            return newName
            

            case 'jsonDict':
            return newName
            

    
        default:
        return name
            
    }
}

export {
    mapProps,
    mapJsonListInput,
    filterSelectKey,
    checkValueType,
    isProps,
    mapKeyValuePair,
    setInputName,
    isObject
}