import React, { Component } from 'react';
// import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next'
 class DocumentDownload extends Component {
    constructor(props) {
        super(props)
        this.state = {
            file: this.props.doc,
            fileName: this.props.name,
            fileType: this.props.type,
            showImage: null
        }

    }
    async arrayBufferToBase64(buffer) {
        var binary = '';
        var bytes = [].slice.call(new Uint8Array(buffer.data));
        bytes.forEach((b) => binary += String.fromCharCode(b));
        return binary
    }

    async showFile(file) {
        let decodeBase64 = await this.arrayBufferToBase64(this.state.file);
        this.setState({ showImage: decodeBase64 })
    }
    async downloadFile() {
        let base64Image = await this.arrayBufferToBase64(this.state.file);
        if (base64Image) {
            this.setState({ showImage: base64Image })
            var win = window.open();
            win.document.write('<iframe src="' + base64Image + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');
        }
    }


    componentWillMount() {
        this.showFile()
    }
    componentWillReceiveProps(props) {
        this.setState({
            file: props.doc,
            fileName: props.name,
            fileType: props.type,
        })
        this.showFile()
    }

    render() {
        const { showImage, fileName } = this.state;
        const t = this.props.t
        return (
            <div className="row">
                <div className="col-md-4">

                    {showImage && <figure className="figure">
                        <img src={showImage} className="figure-img img-fluid rounded  image-figure" alt={fileName} />
                        <figcaption className="figure-caption text-center text-muted"><a color="link" href={showImage} download={fileName}>{t('Download')} {fileName} <i className="fa fa-download"></i></a></figcaption>
                    </figure>}

                </div>


            </div>
        )
    }
}

DocumentDownload.propTypes = {
    doc: PropTypes.object, name: PropTypes.string, type: PropTypes.string
};

export default withTranslation()(DocumentDownload)



// import React from "react";
// import {ticketingHelperFunc} from '../../helpers'
// export const DocumentDownload=async(props)=>{
//     const parse=async(doc)=>{
//        return await ticketingHelperFunc.arrayBufferToBase64(doc);
//     }

//     return(
//        <>
//           <div className="col-md-4">

//          <figure class="figure">
//   <img src={parse(props.doc)} class="figure-img img-fluid rounded" alt={props.name}/>
//   <figcaption class="figure-caption text-right link">{props.name}</figcaption>
// </figure>

//                        {/* <div className="row">
//               <Button color="link">Download {props.name} <i className="fa fa-download"></i></Button>
//                 </div> */}
//     </div>
//        </>
//     )
// }
