import { DashboardConstants } from '..'
import { executeApi } from '../../helpers/apiHelper'
export class DashboardTActions {
	constructor(options) {
		this.resourceName = options.resourceName
		this.preActionsHooks = options.preActionsHooks
		this.postActionsHooks = options.postActionsHooks
	}
	executeApi({ tenantId, values, apiMeta, targetProp, t, apiUrl }) {
		let that = this
		if (!apiMeta) return
		return dispatch => {
			dispatch(that.request({ method: 'EXECUTEAPI' }))
			executeApi({ tenantId, values, apiMeta, scriptLib: null, apiUrl }).then(
				result => {
					if (result) {
						if (that.postActionsHooks) {
							result = that.postActionsHooks({ actionName: 'executeApi', result })
						}
						dispatch(
							// @ts-ignore
							that.success({
								method: 'EXECUTEAPI',
								item: result,
								targetProp: targetProp || (apiMeta.response && apiMeta.response.targetProp),
								data: { inputs: values },
							})
						)
					} else {
						let errorMsg = `Cannot execute api ${apiMeta && apiMeta.path} on ${that.resourceName} at this moment please try again later. targetProp = ${targetProp}`
						dispatch(that.failure({ method: 'EXECUTEAPI', errorMsg }))
						// dispatch(alertActions.error(errorMsg));
					}
				},
				error => {
					let errorMsg = `Cannot execute api ${apiMeta && apiMeta.path} on ${that.resourceName} at this moment please try again later. targetProp = ${targetProp} and ${error}`
					dispatch(that.failure({ method: 'EXECUTEAPI', errorMsg }))
					// dispatch(alertActions.error(errorMsg));
				}
			)
		}
	}

	slaApi({ tenantId, values, apiMeta, targetProp, t, apiUrl }) {
		let that = this
		if (!apiMeta) return
		return dispatch => {
			dispatch(that.request({ method: 'SLAAPI' }))
			executeApi({ tenantId, values, apiMeta, scriptLib: null, apiUrl }).then(
				result => {
					if (result) {
						if (that.postActionsHooks) {
							result = that.postActionsHooks({ actionName: 'slaApi', result })
						}
						dispatch(
							// @ts-ignore
							that.success({
								method: 'SLAAPI',
								item: result,
								targetProp: targetProp || (apiMeta.response && apiMeta.response.targetProp),
								data: { inputs: values },
							})
						)
					} else {
						let errorMsg = `Cannot execute api ${apiMeta && apiMeta.path} on ${that.resourceName} at this moment please try again later. targetProp = ${targetProp}`
						dispatch(that.failure({ method: 'SLAAPI', errorMsg }))
						// dispatch(alertActions.error(errorMsg));
					}
				},
				error => {
					let errorMsg = `Cannot execute api ${apiMeta && apiMeta.path} on ${that.resourceName} at this moment please try again later. targetProp = ${targetProp} and ${error}`
					dispatch(that.failure({ method: 'SLAAPI', errorMsg }))
					// dispatch(alertActions.error(errorMsg));
				}
			)
		}
	}

	request({ method }) {
		let type
		switch (method) {
			case 'EXECUTEAPI':
				type = DashboardConstants.EXECUTEAPI_REQUEST
				break
			case 'SLAAPI':
				type = DashboardConstants.SLAAPI_REQUEST
				break
			default:
		}
		type = type + '_' + this.resourceName
		return { type: type, resourceName: this.resourceName }
	}
	success({ method, item, itemType, targetProp, data }) {
		let type
		switch (method) {
			case 'EXECUTEAPI':
				type = DashboardConstants.EXECUTEAPI_SUCCESS
				break
			case 'SLAAPI':
				type = DashboardConstants.SLAAPI_SUCCESS
				break

			default:
		}
		type = type + '_' + this.resourceName
		return { type, item, resourceName: this.resourceName, itemType, data, targetProp }
	}
	failure({ method, errorMsg }) {
		let type
		switch (method) {
			case 'EXECUTEAPI':
				type = DashboardConstants.EXECUTEAPI_FAILURE
				break
			case 'SLAAPI':
				type = DashboardConstants.SLAAPI_FAILURE
				break
			default:
		}
		type = type + '_' + this.resourceName
		console.error(`${type}:${errorMsg}`)
		return { type, error: errorMsg, resourceName: this.resourceName }
	}
	getFns() {
		return {
			executeApi: this.executeApi.bind(this),
			slaApi: this.slaApi.bind(this),
		}
	}
}
