import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Components, DashboardTActions, DashboardTService } from './'
import Dashboard from './components/Dashboard'
const { ReportList } = Components
// import CreateComponent from './components/CreateComponent';

export class DashboardT {
	constructor({
		name,
		title,
		fieldsMeta,
		cardsMeta,
		apisMeta,
		actionsMeta,
		sectionsMeta,
		listsMeta,
		dashboardMeta,
		components,
		methodSchemas,
		DashboardTSchema,
		collectionSchemas,
		idKey,
		ActionClass,
		ServiceClass,
		DashboardTUrlPaths,
		chartsMeta,
		preActionsHooks,
		postActionsHooks,
	}) {
		this.fieldsMeta = fieldsMeta
		this.actionsMeta = actionsMeta
		this.cardsMeta = cardsMeta
		this.chartsMeta = chartsMeta
		this.dashboardMeta = dashboardMeta
		this.listsMeta = listsMeta
		this.name = name
		this.DashboardTUrlPaths = DashboardTUrlPaths // dashboard level get metrics... with input filters and groups... / report url dictionary
		this.apisMeta = apisMeta
		this.title = title
		this.sectionsMeta = sectionsMeta
		this.methodSchemas = methodSchemas // ( used for post apis )
		this.DashboardTSchema = DashboardTSchema // all dashboards have to follow
		if (ServiceClass) this.service = new ServiceClass({ DashboardTUrlPaths: DashboardTUrlPaths || name, methodSchemas, DashboardTSchema })
		else this.service = new DashboardTService({ DashboardTUrlPaths: DashboardTUrlPaths || name, methodSchemas, DashboardTSchema })
		if (ActionClass)
			this.actionObj = new ActionClass({
				service: this.service,
				resourceName: name,
				methodSchemas,
				DashboardTSchema,
				collectionSchemas,
				preActionsHooks,
				postActionsHooks,
			})
		else
			this.actionObj = new DashboardTActions({
				service: this.service,
				resourceName: name,
				methodSchemas,
				DashboardTSchema,
				collectionSchemas,
				preActionsHooks,
				postActionsHooks,
			})
		this.collectionSchemas = collectionSchemas
		this.components = components || { Dashboard, ReportList }
		this.containers = {}
	}
	init() {
		let that = this
		const mapStateToProps = state => {
			const { identity, alert, tenant, reportList, dashboard, globalSetting, tenantConfig } = state
			const profile = identity ? identity.profile : null
			let props = { profile, alert, tenant, reportList, dashboard, globalSetting, tenantConfig }
			props.filterValues = that.getPropsFilterValues({ profile, globalSetting })
			// if(ownProps && ownProps.params) props.params = ownProps.params
			props.meta = {
				fields: this.fieldsMeta,
				actions: this.actionsMeta,
				apis: this.apisMeta,
				sections: this.sectionsMeta,
				lists: this.listsMeta,
				title: this.title,
				cards: this.cardsMeta,
				charts: this.chartsMeta,
				dashboardMeta: this.dashboardMeta,
			}
			props.moduleName = this.name
			// props.fetchDetailByApi = this.fetchDetailByApi
			return props
		}

		const mapDispatchToProps = dispatch => {
			let fns = this.getActionFns()
			return {
				...bindActionCreators(
					{
						...fns,
					},
					dispatch
				),
			}
		}

		this.containers.Dashboard = connect(mapStateToProps, mapDispatchToProps)(this.components.Dashboard)
	}

	getActionFns() {
		let fns = this.actionObj.getFns()

		return fns
	}

	getPropsFilterValues({ profile }) {}

	async getContainer(type) {
		if (!['Dashboard'].includes(type)) return
		if (this.containers[type]) return this.containers[type]
		else {
			this.init()
			return this.containers[type]
		}
	}
}
