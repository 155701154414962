import { registerTenantConstants, registerTenantServices } from '..'

const checkTenantExistsReqeust = () => ({
	type: registerTenantConstants.CHECK_TENANT_EXISTS_REQUEST,
})

const checkTenantExistsSuccess = payload => ({
	type: registerTenantConstants.CHECK_TENANT_EXISTS_SUCCESS,
	payload,
})

const checkTenantExistsFailure = payload => ({
	type: registerTenantConstants.CHECK_TENANT_EXISTS_FAILURE,
	payload,
})

export const checkTenantExists = (tenantId, t) => async dispatch => {
	try {
		dispatch(checkTenantExistsReqeust())
		let checkTenant = await registerTenantServices.checkTenantExists(tenantId)

		let tenantExists = {}
		if (checkTenant && typeof checkTenant === 'object' && checkTenant.tenantId && checkTenant.tenantId === tenantId) {
			tenantExists[tenantId] = true
		} else tenantExists[tenantId] = false
		dispatch(checkTenantExistsSuccess(tenantExists))
	} catch (error) {
		dispatch(checkTenantExistsFailure(((t && t(`Unable to check tenant details`)) || `Unable to check tenant details`) + error))
	}
}

const registerTenantReqeust = () => ({
	type: registerTenantConstants.REGISTER_TENANT_REQUEST,
})

const registerTenantSuccess = () => ({
	type: registerTenantConstants.REGISTER_TENANT_SUCCESS,
})

const registerTenantFailure = payload => ({
	type: registerTenantConstants.REGISTER_TENANT_FAILURE,
	payload,
})

export const registerTenant = (payload, t) => async dispatch => {
	try {
		let products = payload.products.map(product => product.value.toLowerCase())
		let productLabels = payload.products
		dispatch(registerTenantReqeust())
		let reqPayload = {
			tenantId: payload.tenantId,
			rootEmailId: payload.email,
			companyName: payload.companyName,
			copyRezolveTickets: payload.copyRezolveTickets,
			password: payload.password,
			firstName: payload.firstName,
			lastName: payload.lastName,
			products: products,
			defaultTeam: payload.defaultTeam,
			botName: payload.botName,
			phoneno: payload.phoneno,
			params: payload.params,
			teamName: payload.teamNames,
			productLabels: productLabels,
			region: (payload.region && payload.region.value) || '',
		}
		let tenant = await registerTenantServices.registerTenant(reqPayload)
		sessionStorage.setItem('apiUrl', tenant.apiUrl || (tenant.properties && tenant.properties.apiUrl))
		dispatch(registerTenantSuccess())
	} catch (error) {
		let errorMsg = (t && t('Unable to register tenant')) || 'Unable to register tenant'
		errorMsg = errorMsg + ' ' + (error ? error.message : '')
		dispatch(registerTenantFailure(errorMsg))
	}
}

const getTenantLifeCycleReqeust = () => ({
	type: registerTenantConstants.TENANT_LIFECYCLE_REQUEST,
})

const getTenantLifeCycleSuccess = payload => ({
	type: registerTenantConstants.TENANT_LIFECYCLE_SUCCESS,
	payload,
})

const getTenantLifeCycleFailure = payload => ({
	type: registerTenantConstants.TENANT_LIFECYCLE_FAILURE,
	payload,
})

export const getTenantLifeCycle = (tenantId, t) => async dispatch => {
	try {
		dispatch(getTenantLifeCycleReqeust())
		let success = false
		let counter = 0
		while (!success) {
			let lifecycle = await registerTenantServices.getTenantLifeCycle(tenantId)
			dispatch(getTenantLifeCycleSuccess(lifecycle))
			if (lifecycle.status === 'ACTIVE') success = true
			else {
				counter++
				if (counter > 100) throw new Error((t && t('Timeout Error: Taking too long, our systems are slow.')) || 'Timeout Error: Taking too long, our systems are slow.')
				await new Promise(resolve => setTimeout(resolve, 4000))
			}
		}
	} catch (error) {
		let errorMsg = (t && t('Unable to check tenant details')) || 'Unable to check tenant details'
		errorMsg = errorMsg + ' ' + (error ? error.message : '')

		dispatch(getTenantLifeCycleFailure(errorMsg))
	}
}

const validateEmailOTPReqeust = () => ({
	type: registerTenantConstants.VALIDATE_OTP_REQUEST,
})

const validateEmailOTPSuccess = payload => ({
	type: registerTenantConstants.VALIDATE_OTP_SUCCESS,
	payload,
})

const validateEmailOTPFailure = payload => ({
	type: registerTenantConstants.VALIDATE_OTP_FAILURE,
	payload,
})

export const validateEmailOTP = (payload, t) => async dispatch => {
	try {
		dispatch(validateEmailOTPReqeust())
		let data = await registerTenantServices.validateEmailOTP(payload)
		dispatch(validateEmailOTPSuccess(data))
		return data
	} catch (error) {
		let errorMsg = (t && t('Unable to check tenant details')) || 'Unable to check tenant details'
		errorMsg = errorMsg + ' ' + (error ? error.message : '')

		dispatch(validateEmailOTPFailure(errorMsg))
	}
}

const getOTPReqeust = () => ({
	type: registerTenantConstants.GET_OTP_REQUEST,
})

const getOTPSuccess = payload => ({
	type: registerTenantConstants.GET_OTP_SUCCESS,
	payload,
})

const getOTPFailure = payload => ({
	type: registerTenantConstants.GET_OTP_FAILURE,
	payload,
})

export const getOTP = (payload, t) => async dispatch => {
	try {
		dispatch(getOTPReqeust())
		let otpData = await registerTenantServices.getOTP(payload)

		// let tenantExists = {}
		// if ( checkTenant && typeof checkTenant === 'object' && checkTenant.tenantId &&  checkTenant.tenantId===payload.tenantId) {
		//     tenantExists[payload.tenantId] = true
		// } else  tenantExists[payload.tenantId] = false
		dispatch(getOTPSuccess(otpData))
		return otpData
	} catch (error) {
		let errorMsg = (t && t('Unable to check tenant details')) || 'Unable to check tenant details'
		errorMsg = errorMsg + ' ' + (error ? error.message : '')

		dispatch(getOTPFailure(errorMsg))
	}
}

// export const selectTenant = () => {
//     return dispatch => {
//         let tenant
//         dispatch(getTenantRequest());

//         const hostname = window.location.hostname

//         if ( hostname.substring(hostname.indexOf('.') + 1) === rootDomain )  {

//             if ( hostname.substring(0,hostname.indexOf('.')) !== defaultSubdomain )   {

//                 return tenantServices.getTenantService(hostname)
//                 .then ( result => {
//                         if (result.data && result.data.tenants && result.data.tenants.length === 1) {
//                             tenant = {
//                                 tenantId: hostname.substring(0,hostname.indexOf('.')),
//                                 url: hostname,
//                                 isTenant: true,
//                                 ...result.data.tenants[0]
//                             }
//                             dispatch(getTenantSuccess(tenant))
//                         } else {
//                             let error = "Cannot fetch the required tenant"
//                             dispatch(getTenantFailure(error));
//                             dispatch(alertActions.error(error));
//                         }
//                         setTimeout(() => {
//                             dispatch(alertActions.clear());
//                         }, 10000);
//                     }
//                 )
//                 .catch(
//                     error => {
//                         dispatch(getTenantFailure(error));
//                         dispatch(alertActions.error(error));
//                         setTimeout(() => {
//                             dispatch(alertActions.clear());
//                         }, 10000);
//                     });
//             } else {

//                 tenant = {
//                     tenantId: 'default',
//                     url: hostname,
//                     isTenant: false,
//                     landingPage: '/register'
//                 }
//                 dispatch(getTenantSuccess(tenant))

//             }

//         } else {
//             let error = "Current domain does not seem to match the expected domain , please contact your administrator"
//             dispatch(getTenantFailure(error));
//             dispatch(alertActions.error(error));
//         }

//         return new Promise((resolve, reject) => reject("Unable to fetch tenant details"))

//     };

// }
