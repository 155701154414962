import { settingsConstants } from '../';

export const ssoData = (state = {}, action) => {
  switch (action.type) {
   
    case settingsConstants.GET_SSODATA_REQUEST:
      return {
        ...state,
        loading: true
      };
    case settingsConstants.GET_SSODATA_SUCCESS:
      if (action.payload) {
        return {
          ...state,
          loading: false,
          data: action.payload
        }
      } else {
        return {
          ...state,
          loading: false
        }
      }
    case settingsConstants.GET_SSODATA_FAILURE:
      return {
        ...state,
        loading: false
      };


    default:
      return state
  }
}