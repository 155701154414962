import { namespaceConstants } from '../';

export const namespace = (state = {}, action) => {
  switch (action.type) {
    case namespaceConstants.GETALL_NAMESPACE_REQUEST:
      return {
        ...state,
        loading: true
      }
    case namespaceConstants.GETALL_NAMESPACE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload
      }

    case namespaceConstants.GETALL_NAMESPACE_FAILURE:
        return {
            ...state,
            loading: false
        }


    default:
      return state
  }
}