import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { default as ReactSelect } from 'react-select'
import 'url-search-params-polyfill'
import { globalTenantServiceUrl, isProd } from '../../../../config'
import { identityServices } from '../../../common'
import jsonData from './validateEmail.json'
import { Switch,Box,Text, Select, Button,Input } from '@chakra-ui/react'
import { CiWarning } from "react-icons/ci";
import { GrClose } from "react-icons/gr";
class SignupForm extends Component {
	constructor(props) {
		super(props)

		this.state = {
			fields: {
				companyName: '',
				email: '',
				phoneno: '',
				firstName: '',
				lastName: '',
				tenantId: '',
				password: '',
				//        'confirmPassword': '',
				termsCondition: true,
				botName: '',
				products: '',
				defaultTeam: null,
				teamNames: '',
				region: { label: 'US', value: 'us-west' },
				copyRezolveTickets:false
			},
			tooltip: false,
			showOtp: false,
			isValidated: false,
			otp: '',
			errors: {},
			isOtpButtonDisabled: true,
			otpResendTries: 0,
			productOptions: [],
			isSignupButtonDisable: false,
			ticketManage:false,
			serviceTeams:[],
			serviceValue:''
		}
	}

	componentWillReceiveProps(props) {
		if (props.isValidated) {
			this.setState({ isValidated: props.isValidated })
		}
	}

	componentWillMount() {
		this.getProducts()
	}

	handleProductSelect = selected => {
		let fields = this.state.fields
		fields['products'] = selected
		if (!fields['products'].find(product => product.value === 'Others')) {
			fields['teamNames'] = ''
		}
		//let morselSelect = fields['products'].filter(product => product.value === 'Morsel')
		let products = selected
		fields.defaultTeam = null
		this.setState(prevState => {
			return {
				...prevState,
				products: selected,
				defaultTeam: null,
				fields,
				errors: {
					...prevState.errors,
					products: products.length ? '' : prevState.errors.products,
				},
			}
		})
	}
	handleRegionSelect = selected => {
		let fields = this.state.fields
		fields['region'] = selected
		this.setState({
			region: selected,
			fields,
		})
	}
	handleDefaultProductSelect = selected => {
		let fields = this.state.fields
		fields['defaultTeam'] = selected
		this.setState(prevState => {
			return {
				...prevState,
				fields,
				errors: {
					...prevState.errors,
					defaultTeam: fields['defaultTeam'] ? '' : prevState.errors.defaultTeam,
				},
			}
		})
	}
	getProducts() {
		let apiUrlCustom = globalTenantServiceUrl
		const requestOptions = {
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			url: `${apiUrlCustom}/productui/getproducts`,
		}
		return identityServices.identityApi.request(requestOptions).then(response => {
			if (response.status == 200 && response.data) {
				let productOptions = response.data.map(product => {
					return { label: product.producttitle, value: product.productname }
				})
				this.setState({
					productOptions,
				})
			}
		})
	}
	handleConditionChange(e) {
		let checked = e.target.checked
		let fields = this.state.fields
		//   fields[field] = e.target.value;
		if (checked) {
			fields['termsCondition'] = true
			this.setState({ fields })
		} else {
			fields['termsCondition'] = false
			this.setState({ fields })
		}
	}
	handleValidation() {
		let requiredFields = [
			'teamNames',
			'defaultTeam',
			'companyName',
			'password',
			'email',
			'tenantId',
			'firstName',
			'lastName',
			'phoneno',
			'botName',
			'products',
			'region',
		]
		let formIsValid = true
		let fields = this.state.fields
		let errors = {}
		// const { serviceTeams } = this.state
		if (fields['products'].length === 0 && this.state.fields.products === '') {
			this.setState({
				fields : {
					...fields,
					products: this.state.serviceTeams
				}
			})
			fields = {...this.state.fields,products:this.state.serviceTeams}
		}
		

		requiredFields.forEach(key => {
			if (key === 'defaultTeam' && fields['products'].length <= 1 && fields['products'] !== '') {
				formIsValid = formIsValid && true
			} else if (key === 'teamNames') {
				const trimmedValue = fields[key].trim()
				const hasOther = fields['products'] && fields['products'].some(prod => prod.label === 'Other')

				if (hasOther) {
					if (fields['products'].some(prod => prod.label === trimmedValue)) {
						formIsValid = formIsValid && false
						errors[key] = 'Duplicate values are not allowed.'
					} else if (fields['products'].length >= 1 && trimmedValue === '') {
						formIsValid = formIsValid && false
						errors[key] = 'Required field'
					} else {
						formIsValid = formIsValid && true
					}
				} else {
					formIsValid = formIsValid && true
				}
			} else if (!fields[key] || (Array.isArray(fields[key]) && !fields[key].length)) {
				formIsValid = false
				errors[key] = 'Required field'
			}
		})
		if (fields['password'] && fields['password'].length < 8) {
			formIsValid = false
			errors['password'] = 'Password Length must be greater than 8 Characters!'
		}

		if (this.state.serviceTeams.length < 1 && fields['products'] && fields['products'].length < 8) {
			formIsValid = false
			errors['products'] = 'Atleast one service team is required!'
		}
		// if (fields["password"] !== fields["confirmPassword"]) {
		//   formIsValid = false;
		//   errors["confirmPassword"] = "Password do not match";
		// }
		let checkDomain = this.validateDomain(fields['tenantId'])
		if (checkDomain === false) {
			formIsValid = false
			errors['tenantId'] = 'Special characters and space are not allowed'
		}

		let checkEmail = this.validateEmail(fields['email'])
		let checkEmailFormat = this.validateEmailFormat(fields['email'])
		if (checkEmailFormat === false) {
			formIsValid = false
			errors['email'] = 'Please Enter a Valid Email Id'
		}
		if (checkEmail === true) {
			formIsValid = false
			errors['email'] = 'Enter a valid business email'
		}

		let checkPhone = this.validatePhone(fields['phoneno'])
		if (checkPhone === false) {
			formIsValid = false
			errors['phoneno'] = 'Enter a valid phone number'
		}

		let ValidatePassword = this.validatePassword(fields['password'])
		if (fields['password'] && ValidatePassword === false) {
			formIsValid = false
			errors['password'] = 'Password is not valid'
		}

		if (fields['termsCondition'] === false) {
			formIsValid = false
			errors['termsCondition'] = 'Please check the Terms & Conditions!'
		}

		this.setState({ errors: errors }, () => {
			this.setState({ errors: errors /* isValidated: false */ }) // Udaya:commented this as this is coming from props.
		})
		return formIsValid
	}
	handleCustomTeam(e) {
		const inputValue = e.target.value?.trim()
		if (inputValue !== '') {
			let defaults = this.state.products
			let customProduct = {}
			defaults = this.state.products.filter(product => {
				if (product.value !== 'Default') return product
			})
			const exists = defaults.some(product => product.value === inputValue)
			if (!exists) {
				customProduct.label = inputValue
				customProduct.value = inputValue
				defaults.push(customProduct)
				this.setState({ products: defaults })
			} else {
				const errors = { ...this.state.errors }
				errors['teamNames'] = 'Duplicate values are not allowed.'
				this.setState({ errors })
				return false
			}
		}
	}
	validatePhone(value) {
		var phoneno = /^[\+]?[0-9]{1,3}?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,8}$/im
		let matchCase = value.match(phoneno)
		if (matchCase) {
			return true
		} else if (value.length < 10) {
			return false
		} else {
			return false
		}
	}
	handleCheck = e => {
		e.preventDefault()
		let fields = this.state.fields
		if (fields['tenantId'] && fields['tenantId'] !== '') {
			this.props.checkTenantExists(fields['tenantId'])
		}
		// if (this.handleValidation()) {
		//   this.props.checkTenantExists(fields["tenantId"])
		// }
	}

	handleMouseOut() {
		const { fields } = this.state
		this.setState({ tooltip: !this.state.tooltip }, () => {
			if (!this.state.errors['tenantId'] && fields['tenantId'] && fields['tenantId'] !== '') {
				this.props.checkTenantExists(fields['tenantId'])
			}
		})
	}
	async handleRegistration(e) {
		e.preventDefault()
		this.setState({ isSignupButtonDisable: true })
		const { tenantExists } = this.props
		const { fields, isValidated, serviceTeams } = this.state
		const products=this.state.serviceTeams
		let payload = {
			...this.state.fields,
			products,
		}
		if (window.location.search || this.props.params) {
			let qObj = {}
			let qParams
			if (window.location.search) qParams = new URLSearchParams(window.location.search)
			qParams &&
				qParams.forEach((v, k) => {
					qObj[k] = v
				})
			if (this.props.params) qObj = Object.assign(qObj, this.props.params)
			payload.params = qObj
		}
		if (this.handleValidation()) {
			if (isValidated && fields['tenantId'] in tenantExists && tenantExists[fields['tenantId']] === false) {
				// await this.props.registerTenant(payload); this.props.registerHandler(payload)
				// debugger;;
				await this.props.getOTP(payload)
				this.setState({ showOtp: true, isOtpButtonDisabled: true })
				setTimeout(() => this.setState({ isOtpButtonDisabled: false }), 60000)
			} else {
				return false
			}
		}
		setTimeout(() => {
			this.setState({ isSignupButtonDisable: false })
		}, 2000)
	}

	async resendOtp(e) {
		e.preventDefault()
		const { tenantExists, isValidated } = this.props
		const { fields, products } = this.state
		let payload = {
			...this.state.fields,
			products : this.state.serviceTeams,
		}
		if (window.location.search || this.props.params) {
			let qObj = {}
			let qParams
			if (window.location.search) qParams = new URLSearchParams(window.location.search)
			qParams &&
				qParams.forEach((v, k) => {
					qObj[k] = v
				})
			if (this.props.params) qObj = Object.assign(qObj, this.props.params)
			payload.params = qObj
		}

		if (this.state.otpResendTries < 3) {
			await this.props.getOTP(payload)
			this.setState({ showOtp: true, isOtpButtonDisabled: true, otpResendTries: this.state.otpResendTries + 1 })
			setTimeout(() => this.setState({ isOtpButtonDisabled: false }), 60000)
		} else {
			this.setState({ isOtpButtonDisabled: true, errors: { otp: 'Haven’t received the OTP? Please write us at support@rezolve.ai' } })
		}
	}

	async validateOTP() {
		const { tenantExists, isValidated } = this.props
		const { fields, products } = this.state
		let payload = {
			...this.state.fields,
			products : this.state.serviceTeams,
			otp: this.state.otp,
		}
		if (!payload.otp || (payload.otp && payload.otp.trim().length < 6)) {
			this.setState({ errors: { otp: `Haven’t received the OTP? Please write us at support@rezolve.ai` } })
			return false
		}
		let data = await this.props.validateEmailOTP(payload)
		// console.log("\n\n\n::::~~~::  ", data)
		if (data && data.error == false) {
			// if validated otp then proceed
			if (window.location.search || this.props.params) {
				let qObj = {}
				let qParams
				if (window.location.search) qParams = new URLSearchParams(window.location.search)
				qParams &&
					qParams.forEach((v, k) => {
						qObj[k] = v
					})
				if (this.props.params) qObj = Object.assign(qObj, this.props.params)
				payload.params = qObj
			}
			await this.props.registerTenant(payload)
			this.props.registerHandler(payload)
		} else {
			this.setState({ errors: { otp: data.message } })
		}
	}

	validateDomain(value) {
		let errors = {}
		if (/^[a-zA-Z0-9-]*$/.test(value) === false) {
			errors['tenantId'] = 'Special characters and space are not allowed'
			this.setState({ errors: errors }, () => {
				this.setState({ errors: errors })
			})
			return false
		}
		return true
	}
	handleChange = field => e => {
		let fields = this.state.fields
		let value = field && field === 'tenantId' ? e.target.value.toLowerCase() : e.target.value
		fields[field] = value
		if (field === 'tenantId') {
			this.validateDomain(value)
		}
		if(field==='copyRezolveTickets'){
			let val=e.target.checked
			fields[field]=val
		}

		this.setState({ fields })
		this.handleChangeValidation(field, value)
	}

	handleSpecialCharacter = fieldName => event => {
		const valueData = event.target.value
		const errors = { ...this.state.errors }

		if (/[^\w\s]/.test(valueData)) {
			errors[fieldName] = 'Special characters are not allowed'
		} else {
			errors[fieldName] = ''
		}

		const text = event.target.value
		const value = text.replace(/[^\w\s]/gi, '')

		this.setState(prevState => ({
			fields: {
				...prevState.fields,
				[fieldName]: value,
			},
			errors: errors,
		}))
	}

	handleOtpChange = field => e => {
		// if (e.target.value.length <= 6) {
		this.setState({ otp: e.target.value })
		// }
	}

	handleChangeValidation = (field, value) => {
		// let formIsValid = true;
		// let fields = this.state.fields;
		const { errors } = this.state
		let message = ''

		if (value === '') {
			// formIsValid = false;
			message = 'Required field'
		} else {
			message = ''
		}

		this.setState({
			errors: {
				...errors,
				[field]: message,
			},
		})
	}

	validateEmailFormat(emailId) {
		var re =
			/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		return re.test(emailId)
	}
	validatePassword(password) {
		const passPattern = /^(?=.*[!@#$%^&*])(?=.*[0-9])[a-zA-Z0-9!@#$%^&*]{8,64}$/
		return passPattern.test(password)
	}
	validateEmail(emailId) {
		// var name = emailId.substring(0, emailId.lastIndexOf("@"));
		var domain = emailId.substring(emailId.lastIndexOf('@') + 1)
		const filterData = jsonData.filter(row => row === domain)
		if (filterData.length > 0) {
			return true
		} else {
			return false
		}
	}

	handleKeyPress = event => {
		if (event.key === '.') {
			event.preventDefault()
			return false
		}
	}
	handleAddProduct=()=>{
		if(this.state.serviceTeams.includes(this.state.serviceValue)){
			this.setState({serviceTeams:[...this.state.serviceTeams]})
			
		} 
		else{
			this.setState({serviceTeams:[...this.state.serviceTeams,{label:this.state.serviceValue,value:this.state.serviceValue}]})
		}
		this.setState({serviceValue:""})
	}

	handleProductSelectChange = (e) => {
        const value = e.target.value;
        let errors = {};
        const noSpecialCharsOrSpaces = /^[a-zA-Z0-9\s]*$/.test(value);
		 //const noSpecialCharsOrSpaces = value.replace(/[^a-zA-Z0-9]/g, '');

		if (value.length <= 15 && noSpecialCharsOrSpaces) {
            this.setState({ 
                serviceValue: value, 
                errors: {}  
            });
        } else {
            if (!noSpecialCharsOrSpaces) {
                errors['products'] = 'Special characters are not allowed';
            } else if (value.length > 15) {
                errors['products'] = 'Input must be less than or equal to 15 characters long';
            }
            this.setState({ 
                serviceValue: value, 
                errors: errors 
            });
        }
    }

	removeServiceTeams=(name)=>{
		const filteredValues=this.state.serviceTeams.filter(value=>{
			if(value.label!==name){
				return value
			}
			else return null
		}
		)
        this.setState({serviceTeams:filteredValues})
	}

	render() {
		const { tenantExists, product, t } = this.props
		const { fields, isValidated } = this.state
		const atleastOneSpecialChar = new RegExp(/(?=.*[@#$%^&+=!])/)
		const atleastOneNumber = new RegExp(/(?=.*[0-9])/)
		let gtLink
		if (product && product.toLowerCase() === 'james') gtLink = 'https://www.rezolve.ai/generalTerms'
		else if (product && product.toLowerCase() === 'morsel') gtLink = 'https://www.rezolve.ai/generalTerms'
		else gtLink = 'https://app.termly.io/document/terms-of-use-for-saas/4a768307-f9f4-4b1a-a67a-4b0754724e68'
		// Rezolve + Morsel --> will remove the Morsel from Default Team Selection.
		const { serviceTeams } = this.state
		const defaultTeamOption =
		serviceTeams && serviceTeams.length > 1 && serviceTeams.some(label => label.value === 'Morsel')
				? (() => {
					const index = serviceTeams.findIndex(label => label.label == 'Morsel')
					return serviceTeams.slice(0, index).concat(serviceTeams.slice(index + 1))
				})()
				: serviceTeams
		return (
			<div className="step1">
				<div className="orange-main register-style-left">
					<div className="o-heading">
						<h4>{this.props.stepConfig.stepTitle}</h4>
					</div>
					{/* <img src="/assets/img/avatar.png" alt="infoimg" /> */}
					<div className="o-heading oh-b m-rem">
						<ul className="signup-fl">
							<li>
								<span className="reglist-icon">
									<img src={this.props.stepConfig.stepImages.image1} alt="infoimg" />
								</span>
								{this.props.stepConfig.stepBullet.point1}
							</li>
							<li>
								<span className="reglist-icon">
									<img src={this.props.stepConfig.stepImages.image2} alt="infoimg" />
								</span>
								{this.props.stepConfig.stepBullet.point2}
							</li>
							<li>
								<span className="reglist-icon">
									<img src={this.props.stepConfig.stepImages.image3} alt="infoimg" />
								</span>
								{this.props.stepConfig.stepBullet.point3}
							</li>
							<li>
								<span className="reglist-icon">
									<img src={this.props.stepConfig.stepImages.image4} alt="infoimg" />
								</span>
								{this.props.stepConfig.stepBullet.point4}
							</li>
							<li>
								<span className="reglist-icon">
									<img src={this.props.stepConfig.stepImages.image5} alt="infoimg" />
								</span>
								{this.props.stepConfig.stepBullet.point5}
							</li>
							{/* <li>The Sign up process will take less than 2 minutes</li>
              <li>Once you sign up you can tryout and share James immediately</li>
              <li>You will be able to manage the knowledge base and configure the look and feel</li> */}
						</ul>
					</div>
					<div className="gradient-overlay"></div>
				</div>
				<div className="form-content">
					<div className="row">
						<div className="col-sm-12">
							<div className="form-card">
								<div className="card-body">
									<div className="o-heading oh-b m-rem">
										<h4>Sign Up</h4>
									</div>
									<div className="mdc-formclearfix">
										<div className="mdc-form">
											<label className="custom-label">Company *</label>
											<input
												type="text"
												id="companyName"
												data-testid="companyName"
												className="custom-input"
												placeholder="Company Name"
												onChange={this.handleChange('companyName')}
												value={this.state.fields['companyName']}
											/>
											<span style={{ color: 'red' }}>{this.state.errors['companyName']}</span>
										</div>
									</div>

									<div className="form-md-wraper clearfix">
										<div className="mdc-form clearfix">
											<label className="custom-label">Name *</label>
											<input
												type="text"
												id="firstName"
												data-testid="firstName"
												className="custom-input"
												placeholder="First "
												onChange={this.handleChange('firstName')}
												value={this.state.fields['firstName']}
											/>
											<span style={{ color: 'red' }}>{this.state.errors['firstName']}</span>
										</div>

										<div className="mdc-form clearfix">
											<label className="custom-label">&nbsp;</label>
											<input
												type="text"
												id="lastName"
												data-testid="lastName"
												className="custom-input"
												placeholder="Last "
												onChange={this.handleChange('lastName')}
												value={this.state.fields['lastName']}
											/>
											<span style={{ color: 'red' }}>{this.state.errors['lastName']}</span>
										</div>
									</div>

									<div className="mdc-form for-website setpos clearfix">
										<label className="custom-label">Select your site name *</label>

										{this.state.tooltip && <div className="showTooltip showTooltip-fontSize">Website-address for {this.props.stepConfig.websiteField}</div>}
										<input
											type="text"
											id="tenantId"
											data-testid="tenantId"
											onMouseOut={() => {
												this.handleMouseOut()
											}}
											onBlur={this.handleCheck}
											onMouseOver={() => this.setState({ tooltip: !this.state.tooltip })}
											className="custom-input"
											placeholder="yoursitename"
											onChange={this.handleChange('tenantId')}
											value={this.state.fields['tenantId']}
										/>
										<span className="site-text">.{this.props.rootDomain}</span>
									</div>
									<div className="mdc-form for-website clearfix">
										<span style={{ color: 'red' }}>{this.state.errors['tenantId']}</span>
									</div>
									{isValidated && !this.state.errors['tenantId'] && (
										<div className="mdc-form for-website clearfix">
											<label className="custom-label ">
												{' '}
												{!isValidated ? null : isValidated && tenantExists[fields['tenantId']] ? (
													<span style={{ color: 'red' }}>
														<svg className="crossmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
															<circle className="crossmark__circle" cx="26" cy="26" r="25" fill="none" />
															<path className="crossmark__check" fill="none" d="M16 16 36 36 M36 16 16 36" />
														</svg>{' '}
														Site name already used
													</span>
												) : (
													<svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
														<circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
														<path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
													</svg>
												)}{' '}
											</label>
										</div>
									)}
									{isProd && (
										<div className="form-md-wraper clearfix" style={{ width: '50%' }}>
											<div className="mdc-form info-icon pswwrd-f" data-testid="region">
												<label className="custom-label">Region</label>
												{/* <input type="select" options ={this.state.products}id="product" className="custom-input" placeholder="Products" onChange={this.handleProductSelect("products")} value={this.state.fields["products"]} /> */}
												<ReactSelect
													options={[
														{ label: 'US', value: 'us-west' },
														{ label: 'Europe', value: 'eur-north' },
														{ label: 'Canada', value: 'can-central' },
													]}
													closeMenuOnSelect={true}
													hideSelectedOptions={true}
													onChange={this.handleRegionSelect}
													value={this.state.region || { label: 'US', value: 'us-west' }}
												/>
											</div>
											<span style={{ color: 'red' }}>{this.state.errors['region']}</span>
										</div>
									)}
									<div className="form-md-wraper clearfix">
										<div className="mdc-form clearfix">
											<label className="custom-label">Email *</label>
											<input
												type="text"
												id="email"
												data-testid="email"
												className="custom-input"
												placeholder="Email Address"
												onChange={this.handleChange('email')}
												value={this.state.fields['email']}
											/>
											<span style={{ color: 'red' }}>{this.state.errors['email']}</span>
										</div>

										<div className="mdc-form clearfix">
											<label className="custom-label">Phone *</label>
											<input
												type="tel"
												id="phoneno"
												data-testid="phoneno"
												className="custom-input"
												placeholder="Phone Number"
												onChange={this.handleChange('phoneno')}
												value={this.state.fields['phoneno']}
											/>
											<span style={{ color: 'red' }}>{this.state.errors['phoneno']}</span>
										</div>
									</div>

									<div className="form-md-wraper clearfix">
										<div className="mdc-form info-icon pswwrd-f">
											<label className="custom-label">Password *</label>
											<input
												type="password"
												id="password"
												data-testid="password"
												className="custom-input"
												placeholder="Password"
												onChange={this.handleChange('password')}
												value={this.state.fields['password']}
											/>
											<span style={{ color: 'red' }}>{this.state.errors['password']}</span>
										</div>
										{/* <div className="mdc-form info-icon">
                      <label className="custom-label">Retype Your Password</label>
                      <input type="password" id="confirmPassword" className="custom-input" placeholder="Confirm Password" onChange={this.handleChange("confirmPassword")} value={this.state.fields["confirmPassword"]} />
                      <span style={{ color: "red" }}>{this.state.errors["confirmPassword"]}</span>
                    </div> */}
					
									</div>
									
									<div className="ps-require new-signup">
										<ul>
											<li className={fields.password && fields.password.length >= 8 ? 'ps-check' : fields.password && 'not-matched'}>
												password length (min 8 characters)
												<span className="min-check">
													<img src="/assets/img/paswrd-check.png" alt="ps-check" />
												</span>
												<span className="min-check wrong-ps ">
													<img src="/assets/img/wrong-check.png" alt="ps-check" />
												</span>
											</li>
											<li
												className={
													fields.password && atleastOneSpecialChar.exec(fields.password) && atleastOneSpecialChar.exec(fields.password).length > 0
														? 'ps-check'
														: fields.password && 'not-matched'
												}>
												1 special character
												<span className="min-check">
													<img src="/assets/img/paswrd-check.png" alt="ps-check" />
												</span>
												<span className="min-check wrong-ps ">
													<img src="/assets/img/wrong-check.png" alt="ps-check" />
												</span>
											</li>
											<li
												className={
													fields.password && atleastOneNumber.exec(fields.password) && atleastOneNumber.exec(fields.password).length > 0
														? 'ps-check'
														: fields.password && 'not-matched'
												}>
												at least 1 number
												<span className="min-check">
													<img src="/assets/img/paswrd-check.png" alt="ps-check" />
												</span>
												<span className="min-check wrong-ps ">
													<img src="/assets/img/wrong-check.png" alt="ps-check" />
												</span>
											</li>
											<li className={fields.password && fields.password.length <= 64 ? 'ps-check' : fields.password && 'not-matched'}>
												password length (max 64 characters)
												<span className="min-check">
													<img src="/assets/img/paswrd-check.png" alt="ps-check" />
												</span>
												<span className="min-check wrong-ps ">
													<img src="/assets/img/wrong-check.png" alt="ps-check" />
												</span>
											</li>
										</ul>
									</div>
									<Box class="ticket-management" display='flex' alignItems={'center'} >
										<Text marginRight={'30px'} > Use Rezolve.ai Ticket Management</Text>
										<Switch value={this.state.fields['copyRezolveTickets']} onChange={this.handleChange("copyRezolveTickets")} />
									</Box>
									{
										!this.state.fields.copyRezolveTickets?
										<Box display='flex'>
											<Text><CiWarning color='orange'  /></Text>
  											<Text ml='5px' as='p' lineHeight={'14px'} fontSize={'12px'} color='orange' >Your tenant would be created without tickets management, please reach out the customer service team if you're unsure.Your tenant would be created without tickets management</Text>
										</Box>
										:null
									}
									<div className="form-md-wraper clearfix" style={{marginTop:'10px'}}>
										<div className="mdc-form info-icon pswwrd-f" data-testid="service_teams">
											<label className="custom-label">Service Teams * </label>
											{/* {this.state.tooltip1 && <div className="showTooltip">{t('Select Service Teams. Morsel alone is learning product.')}</div>} */}
											{/* <input type="select" options ={this.state.products}id="product" className="custom-input" placeholder="Products" onChange={this.handleProductSelect("products")} value={this.state.fields["products"]} /> */}
											{/* <ReactSelect
												//options={this.state.products && this.state.products.some(pd => pd.value === 'Morsel') ? [] : this.state.productOptions}
												options={this.state.productOptions}
												isMulti
												// onHover={() => this.setState({ tooltip1: true })}
												// onBlur={() => this.setState({ tooltip1: false })}
												// onFocus={() => this.setState({ tooltip1: true })}
												closeMenuOnSelect={false}
												hideSelectedOptions={true}
												onChange={this.handleProductSelect}
												allowSelectAll={true}
												value={this.state.products}
											/> */}
											<Box display={"flex"} justifyContent={"spaceBetween"} >
												{/* <Select value={this.state.serviceValue} placeholder='Add service teams' onChange={(e)=>{this.handleProductSelectChange(e)}} >
													{
														this.state.productOptions.map((item)=>{
															return <option value={item.value}>{item.label}</option>
														})
													}
												</Select> */}
												<Input value={this.state.serviceValue} placeholder='Add service teams' id = 'products' onChange={(e)=>{this.handleProductSelectChange(e)}}/>
												<span style={{ color: 'red' }}>{this.state.errors['products']}</span>
												<Button  disabled={this.state.errors['products']?true:false || !this.state.serviceValue.trim()} onClick={this.handleAddProduct} marginLeft={'15px'}>Add</Button>
											</Box>
											{
												this.state.serviceTeams.length>0? <Box display="flex" alignItems='center' border='1px solid #3662e3' borderRadius={'5px'} padding="5px 10px" marginBottom={'15px'}>
												<Text>Service Teams:</Text>
												<Box display={'flex'} marginLeft={'10px'} >
													{
													
													this.state.serviceTeams.map((item,index)=>{
														return (
															<Box display='flex' key={index} marginRight={'10px'} background='#e0e8fa' borderRadius='5px' border='1px solid transparent' padding='2px 5px' alignItems='center'  >
														      <Text color='#3662e3' >{item.value}</Text>
															  <Text onClick={()=>{this.removeServiceTeams(item.value)}} marginLeft={'5px'} ><GrClose color='#97a286' /></Text>
														    </Box>
													)
													})
												  }
													
													
												</Box>

											</Box>:null
											}
										</div>
										<span style={{ color: 'red' }}>{this.state.errors['serviceTeams']}</span>
									</div>
									{this.state.serviceTeams && this.state.serviceTeams.some(product => product.label === 'Other') && (
										<div className="mdc-formclearfix">
											<div className="mdc-form">
												<label className="custom-label">Team Name *</label>
												<input
													type="text"
													id="teamNames"
													data-testid="teamNames"
													className="custom-input"
													placeholder="Name for Custom Product"
													onChange={this.handleChange('teamNames') && this.handleSpecialCharacter('teamNames')}
													value={this.state.fields['teamNames']}
													onBlur={this.handleCustomTeam.bind(this)}
												/>
												{this.state.errors['teamNames'] && <span style={{ color: 'red' }}>{this.state.errors['teamNames']}</span>}
											</div>
										</div>
									)}
									{this.state.serviceTeams && this.state.serviceTeams.length > 1 ? (
										<div className="form-md-wraper clearfix" style={{marginBottom:"10px"}} >
											<div className="mdc-form info-icon pswwrd-f" data-testid="default_team" >
												<label className="custom-label">Default Team *</label>
												<ReactSelect
													options={defaultTeamOption}
													isMulti={false}
													closeMenuOnSelect={true}
													hideSelectedOptions={false}
													onChange={this.handleDefaultProductSelect}
													allowSelectAll={false}
													value={this.state.defaultTeam || fields.defaultTeam}
												/>
											</div>
											<span style={{ color: 'red' }}>{this.state.errors['defaultTeam']}</span>
											{/* <div className="mdc-form info-icon">
                      <label className="custom-label">Retype Your Password</label>
                      <input type="password" id="confirmPassword" className="custom-input" placeholder="Confirm Password" onChange={this.handleChange("confirmPassword")} value={this.state.fields["confirmPassword"]} />
                      <span style={{ color: "red" }}>{this.state.errors["confirmPassword"]}</span>
                    </div> */}
										</div>
									) : (
										''
									)}

									<div className="mdc-formclearfix">
										<div className="mdc-form">
											<label className="custom-label">Bot Name *</label>
											<input
												type="text"
												id="botName"
												data-testid="botName"
												className="custom-input"
												placeholder="Bot Name"
												onChange={this.handleChange('botName')}
												value={this.state.fields['botName']}
											/>
											<span style={{ color: 'red' }}>{this.state.errors['botName']}</span>
										</div>
									</div>

									<div className="custom-check">
										{/* <input className="styled-checkbox" id="termsCondition" type="checkbox" data-toggle="modal" data-target="#error-msg" checked={this.state.fields["termsCondition"]} value={this.state.fields["termsCondition"]} onChange={this.handleConditionChange.bind(this)} /> */}
										<label htmlFor="termsCondition">
											By clicking below, you agree to the Rezolve.ai
											<a href={gtLink} target="_blank">
												{' '}
												Terms of Service
											</a>{' '}
											and{' '}
											<a href="https://www.rezolve.ai/privacyPolicy" target="_blank">
												Privacy Policy
											</a>
										</label>
										<span style={{ color: 'red' }}>{this.state.errors['termsCondition']}</span>
									</div>
									<div className="text-center btn-custom ">
										{
											<button
												className="btn btn-default waves-effect waves-light bl-bg btn-act next"
												id="next_otp"
												data-testid="next_otp"
												onClick={this.handleRegistration.bind(this)}
												disabled={this.state.isSignupButtonDisable}>
												Agree and Signup
											</button>
										}
									</div>
									{this.state.showOtp && (
										<div className="otp-overlay">
											<div className="otp-content">
												<div className="form-md-wraper clearfix">
													<div className="mdc-form clearfix">
														<p>Please enter the one time password that was sent to your email.</p>
														<input
															onKeyPress={this.handleKeyPress}
															type="number"
															id="otp"
															data-testid="otp"
															className="custom-input"
															placeholder="OTP"
															onChange={this.handleOtpChange('otp')}
															value={this.state.otp}
															min="6"
															max="6"
														/>
														{/* <span style={{ color: "red" }}>{(this.state.errors["otp"] && this.state.errors["otp"].indexOf('support@rezolve.ai') == -1) ? '<a href="mailto:support@rezolve.ai">rrrtrtr</a>' : '<a href="mailto:support@rezolve.ai">hhhhh</a>'}</span> */}
														{this.state.errors['otp'] && this.state.errors['otp'].indexOf('support@rezolve.ai') == -1 && (
															<span style={{ color: 'red' }}>{this.state.errors['otp']}</span>
														)}
														{this.state.errors['otp'] && this.state.errors['otp'].indexOf('support@rezolve.ai') != -1 && (
															<span style={{ color: 'red' }}>
																Haven’t received the OTP? Please write us at
																<a href="mailto:support@rezolve.ai"> support@rezolve.ai</a>
															</span>
														)}
													</div>
												</div>

												<div className="text-center btn-custom ">
													<button className="btn btn-default waves-effect waves-light bl-bg btn-act next" id="next" data-testid="next" onClick={this.validateOTP.bind(this)}>
														Submit OTP
													</button>

													<button
														className="btn btn-default waves-effect waves-light bl-bg btn-act next"
														id="regenerateOTP"
														data-testid="resendOTP"
														onClick={this.resendOtp.bind(this)}
														disabled={this.state.isOtpButtonDisabled}>
														Resend OTP
													</button>
												</div>
											</div>
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default withTranslation()(SignupForm)
