import Joi from 'joi-browser'
import moment from 'moment'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { Col, Row } from 'reactstrap'
import { Loader } from '../../'
import { getObjByPath } from '../../helpers/utils'
import * as schemas from '../schemas'
import { CardDetail, ChartDetail, ListDetail, SectionBoard } from './'

class Dashboard extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			filters: props.filters, // page level filters. array of filter keys. mapped to fields.
			url: props.url, // api endpoint for the call.
			filterValues: props.filterValues, // state of all filters in the dashboard page and non page with its current values.
			metrics: props.metrics, // metrics available to be shown in page.
			defaultFilters: props.defaultFilters,
			metricsData: [], // initial values of page level filters to get data. Not all filters need to be set for fetching data. Object of key value pairs.
			timerFilter: null,
			initialFilters: [],
			calFilters: {
				start_date: moment(new Date(new Date().getFullYear(), new Date().getMonth(), 1)).format().slice(0, -6),
				end_date: moment(new Date()).format().slice(0, -6),
			},
		}
		this.interval = null
	}
	componentDidMount() {
		let that = this
		let props = this.props
		const {
			meta: { dashboardMeta, apis },
			profile,
			tenantConfig,
		} = this.props

		if (dashboardMeta.initialValues && (!this.state || !this.state.initialValuesSet)) {
			this.setState(
				{
					...this.state,
					initialValuesSet: true,
					...dashboardMeta.initialValues,
				},
				() => {
					if (dashboardMeta.apis) {
						dashboardMeta.apis.forEach(a => {
							let api = apis[a]
							this.setState({ initialFilters: api.filters })
							let targetProp = (api && api.response && api.response.targetProp) || 'data'
							let obj = getObjByPath({ ref: props.dashboard, targetProp })
							if (api && !obj) that.executeApi({ tenantId: profile.tenantUid, apiMeta: api, values: this.state.filterValues, targetProp })
						})
					}
				}
			)
		} else {
			if (dashboardMeta.apis) {
				dashboardMeta.apis.forEach(a => {
					let api = apis[a]
					api.filters = this.state.initialFilters
					let targetProp = (api && api.response && api.response.targetProp) || 'data'
					let obj = getObjByPath({ ref: props.dashboard, targetProp })
					if (api && !obj) that.executeApi({ tenantId: profile.tenantUid, apiMeta: api, values: this.state.filterValues, targetProp })
				})
			}
		}

		if (typeof dashboardMeta.slaApi === 'string') {
			let api = apis[dashboardMeta.slaApi]
			let targetProp = (api && api.response && api.response.targetProp) || 'data'
			let obj = getObjByPath({ ref: props.dashboard, targetProp })
			if (api && !obj) that.slaApi({ tenantId: profile.tenantUid, apiMeta: api, values: this.state.filterValues, targetProp })
		}

		let tc = tenantConfig && tenantConfig.data && tenantConfig.data.length > 0 && tenantConfig.data.find(o => o.configType == 'defaultSetting' && o.name == 'metrics')
		let tcProperties = tc && tc.properties && tc.properties
		if (tcProperties && tcProperties['refreshTime'] && dashboardMeta.refresh) {
			this.interval = setInterval(() => {
				window.location.reload()
			}, tcProperties['refreshTime'])
		}
	}

	componentWillUnmount() {
		clearInterval(this.interval)
		let {
			dashboard,
			meta: { dashboardMeta, apis },
		} = this.props
		dashboard.data = []
		if (dashboardMeta.apis) {
			dashboardMeta.apis.forEach(a => {
				let api = apis[a]
				api.filters = this.state.initialFilters
			})
		}
	}

	executeApi({ tenantId, apiMeta, values, targetProp }) {
		this.props.executeApi({ tenantId, apiMeta, values, targetProp, t: this.props.t, apiUrl: this.props.tenant && this.props.tenant.apiUrl })
		this.setState({ timerFilter: new Date() })
	}

	slaApi({ tenantId, apiMeta, values, targetProp }) {
		this.props.slaApi({ tenantId, apiMeta, values, targetProp, t: this.props.t })
	}

	setDateRange(calFilters) {
		this.setState({ calFilters })
	}

	refreshData(props) {
		let {
			meta: { dashboardMeta, apis },
			profile,
		} = props
		let that = this
		let monthBegin = new Date(this.state.timerFilter)
		let monthBeginF = moment(monthBegin).format().slice(0, -6)

		if (dashboardMeta.apis) {
			dashboardMeta.apis.forEach(a => {
				let api = apis[a]
				dashboardMeta.refreshFilters.forEach(ftr => {
					ftr.value = this.state[ftr.valueRef] ? monthBeginF : ftr.value
				})
				api.filters = dashboardMeta.refreshFilters
				let targetProp = (api && api.response && api.response.targetProp) || 'data'
				let obj = getObjByPath({ ref: props.dashboard, targetProp })
				if (api && !obj) that.executeApi({ tenantId: profile.tenantUid, apiMeta: api, values: this.state.filterValues, targetProp })
			})
		}
	}

	componentWillReceiveProps(props) {
		let val = Joi.validate(props.meta, schemas.dashbordMeta)
		if (val.error) {
			console.error(`${this.props.moduleName || (this.props.entityList ? this.props.entityList.entityName : 'Entity')} has schema error: ${val.error}`)
		} else {
			let filterValues = this.state.filterValues || props.dashboard.inputs || props.filterValues

			this.setState({ filterValues: filterValues })
			// let filterValues = this.state && this.state.filterValues || props.filterValues
			// if (!filterValues) {
			//     filterValues = {}
			//     filterValues = Object.assign({}, props.defaultFilters || {})
			// }

			// this.setState({
			//     filters: props.filters,
			//     dataUrl: props.dataUrl,
			//     metrics: props.metrics,
			//     defaultFilters: props.defaultFilters,
			//     filterValues
			// })
		}
		if (props.dashboard && props.dashboard.data && props.meta && props.meta.dashboardMeta.refresh && props.meta.dashboardMeta.refreshFilters) {
			this.setState({ metricsData: this.state.metricsData ? [...new Set([...this.state.metricsData, ...props.dashboard.data])] : props.dashboard.data })
		} else {
			this.setState({ metricsData: props.dashboard.data })
		}
		if (props.meta && props.meta.dashboardMeta && props.meta.dashboardMeta.slaApi) {
			if (props.dashboard.sla) {
				this.setState({ sla: props.dashboard && props.dashboard.sla })
			}
		}
	}
	/**
	 * To be called from any deep child for updating the filters at Dashboard level
	 * @param {*} param0
	 */
	handleChange({ name, value }) {
		// value could be a single value or array of values if multiple non-continous.. if continuous array of two boundaries.
		let filterValues = this.state.filterValues || this.props.filterValues
		filterValues = filterValues || {}
		let oldValue = this.state.filterValues && this.state.filterValues[name]
		this.setState(
			{
				...this.state,
				filterValues: {
					...filterValues,
					[name]: value,
				},
			},
			() => this.checkCallApis({ name, newValue: value, oldValue })
		)
	}

	handleSelection({ name, value }) {
		let filterValues = this.state.filterValues || this.props.filterValues
		filterValues = filterValues || {}
		let oldValue = this.state.filterValues && this.state.filterValues[name]
		this.setState(
			{
				...this.state,
				filterValues: {
					...filterValues,
					[name]: value,
				},
			},
			() => this.checkCallApis({ name, newValue: value, oldValue })
		)
	}

	setPageFilters({ filterValues }) {
		this.setState({
			...this.state,
			filterValues,
		})
	}
	renderItem(item, i) {
		if (!item) return
		const { charts, sections, cards, lists, dashboardMeta } = this.props.meta
		const {
			dashboard: { data },
			t,
		} = this.props
		let { filterValues, sla, metricsData } = this.state

		const title = (dashboardMeta && dashboardMeta.title) || (this.props.meta.name || this.props.moduleName) + ' Dashboard'
		let itemDef

		let dependsOn
		switch (item.type) {
			case 'section':
				itemDef = sections[item.name]
				if (itemDef) {
					return (
						<div key={i}>
							{item.title && (
								<Row>
									<div className="col-sm-12">
										<div>
											<h5>{t(item.title)}</h5>
										</div>
									</div>
								</Row>
							)}
							<div className="row" key={item.name}>
								<SectionBoard
									{...this.props}
									calFilters={this.state.calFilters}
									sla={sla}
									meta={this.props.meta}
									title={title}
									data={metricsData}
									moduleName={this.props.moduleName}
									key={item.name}
									name={item.name}
									filterValues={filterValues}
									handleChange={this.handleChange.bind(this)}
									handleSelection={this.handleSelection.bind(this)}
									setPageFilters={this.setPageFilters.bind(this)}
									setDateRange={this.setDateRange.bind(this)}
								/>
							</div>
						</div>
					)
				}
				break
			case 'card':
				itemDef = cards[item.name]
				if (itemDef) {
					if (itemDef.dependsOnFields && itemDef.dependsOnFields.pop && filterValues) {
						dependsOn = {}
						itemDef.dependsOnFields.forEach(f => {
							dependsOn[f] = filterValues[f]
						})
					}
					return (
						<div key={item.name}>
							<CardDetail
								key={item.name}
								data={metricsData}
								dependsOn={dependsOn}
								name={item.name}
								card={itemDef}
								filterValues={filterValues}
								sla={sla}
								handleChange={this.handleChange.bind(this)}
								{...this.props}
							/>
						</div>
					)
				}
				break
			case 'chart':
				itemDef = charts[item.name]
				if (itemDef) {
					if (itemDef.dependsOnFields && itemDef.dependsOnFields.pop && filterValues) {
						dependsOn = {}
						itemDef.dependsOnFields.forEach(f => {
							dependsOn[f] = filterValues[f]
						})
					}
					return (
						<div key={item.name}>
							<ChartDetail chart={itemDef} {...this.props} data={metricsData} sla={sla} handleChange={this.handleChange.bind(this)} />
						</div>
					)
				}
				break
			case 'list':
				itemDef = lists[item.name]
				if (itemDef) {
					if (itemDef.dependsOnFields && itemDef.dependsOnFields.pop && filterValues) {
						dependsOn = {}
						itemDef.dependsOnFields.forEach(f => {
							dependsOn[f] = filterValues[f]
						})
					}
					return (
						<div key={item.name}>
							<ListDetail list={itemDef} {...this.props} data={metricsData} handleChange={this.handleChange.bind(this)} />
						</div>
					)
				}
				break

			default:
		}
	}
	checkCallApis({ name, newValue, oldValue }) {
		const {
			meta: { dashboardMeta, apis },
			profile,
		} = this.props
		let that = this
		if (dashboardMeta.apis) {
			dashboardMeta.apis.forEach(a => {
				let api = apis[a]
				let targetProp = (api && api.response && api.response.targetProp) || 'data'
				if (api && api.dependsOnFields && api.dependsOnFields.includes(name) && oldValue !== newValue) {
					that.executeApi({ apiMeta: api, values: this.state.filterValues, targetProp, tenantId: profile && profile.tenantUid })
				}
			})
		}
	}
	render() {
		const {
			dashboard: { loading },
			meta,
		} = this.props
		// let that = this
		// let filterValues = this.state.filterValues || (this.props.dashboard && this.props.dashboard.filterValues)
		const { dashboardMeta } = meta
		const itemsByCols = {}
		// const apisInvoked = (this.state && this.state.apis )|| {}
		const title = (dashboardMeta && dashboardMeta.title) || (this.props.meta.name || this.props.moduleName) + ' Dashboard'
		dashboardMeta &&
			dashboardMeta.cols &&
			dashboardMeta.items.forEach(i => {
				if (i.col) {
					itemsByCols[i.col] = itemsByCols[i.col] || []
					itemsByCols[i.col].push(i)
				} else {
					itemsByCols.unassigned = itemsByCols.unassigned || []
					itemsByCols.unassigned.push(i)
				}
			})
		itemsByCols.unassigned = itemsByCols.unassigned || (dashboardMeta && !dashboardMeta.cols && dashboardMeta.items)

		const t = this.props.t

		if (dashboardMeta.cols && itemsByCols.unassigned) return <div>{`Error: Some elements are not assigned to columns ${itemsByCols.unassigned} `}</div>
		else
			return (
				<div>
					<div className="animated fadeIn">{loading && <Loader />}</div>
					<div className={dashboardMeta.classes}>
						<div>
							<Row className="common-heading">
								<div className="col-sm-12">
									<div className="heading">
										<h5>{t(title)}</h5>
									</div>
								</div>
							</Row>
							<div className="main-section">
								<div className="main-inner dashboard-main">
									{dashboardMeta && dashboardMeta.cols && (
										<Row className="my-4">
											{dashboardMeta.cols.map((c, i) => {
												return (
													<Col sm={c}>
														{itemsByCols[i + 1] &&
															itemsByCols[i + 1].map((item, ind) => {
																return this.renderItem(item, ind)
															})}
													</Col>
												)
											})}
										</Row>
									)}
									{itemsByCols &&
										itemsByCols.unassigned &&
										itemsByCols.unassigned.map((item, ind) => {
											return this.renderItem(item, ind)
										})}
								</div>
							</div>
						</div>
					</div>
				</div>
			)
	}
}
export default withTranslation()(Dashboard)
