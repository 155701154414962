let textEditor = {}
const defaultToolbar = (props)=>{
    textEditor = props.textEditor || textEditor;
    let disabledVideo = props && props.disabledVideo ? true : false
    let disableImage = props && props.disableImage ? true : false
    let colorPicker = props && props.colorPicker ? true : false
    let defaultOptions = ['inline', 'blockType', 'list', 'link', 'history']
    let customComponents = [];
        if(!disabledVideo) customComponents.push({
          type: 'VIDEO',
          renderComponent: 'Upload',
          maxUploadSize: textEditor && textEditor.video && textEditor.video.maxUploadSize ? textEditor.video.maxUploadSize : 10,
          tab: textEditor && textEditor.video && textEditor.video.tab && typeof textEditor.video.tab === 'object' ? textEditor.video.tab : { upload: true, url: true },
          inputAccept: 'video/mp4',
          icon: 'fa fa-video-camera',
          allowed: textEditor && textEditor.video && textEditor.video.allowed && Array.isArray(textEditor.video.allowed) ? textEditor.video.allowed : ["mp4"],
          upload: textEditor && textEditor.video && textEditor.video.required || true,
          uploadCallback: props.uploadVideoValidate,
          customFileConfig: {},
          ignoreExtnValidation: props.ignoreExtnValidation
    })
    if(!disableImage) defaultOptions.push('image')
    if(colorPicker) defaultOptions.push('colorPicker')
    return {
    options: defaultOptions,
    inline: {
        options: ['bold', 'italic', 'monospace'],
    },
    blockType: {
        inDropdown: true,
        options: ['Normal', 'H1', 'H2', 'H3', 'Blockquote'],
    },
    list: {
        options: ['unordered', 'ordered'],
    },
    textAlign: {
        options: ['left', 'center', 'right', 'justify'],
    },
    link: {
        inDropdown: false,
        showOpenOptionOnHover: true,
        defaultTargetOption: '_self',
        options: ['link', 'unlink'],
    },
    image: {
        urlEnabled: true,
        uploadEnabled: true,
        alignmentEnabled: true,
        uploadCallback: props.uploadImageValidate,
        previewImage: true,
        inputAccept: "image/gif,image/jpeg,image/jpg,image/png",
        alt: { present: true, mandatory: false },
        defaultSize: {
          height: "auto",
          width: "auto"
        }
      },
    customComponents: customComponents,
    history: {
        inDropdown: false,
        component: undefined,
        options: ['undo', 'redo'],
    }
 }
}


export {
    defaultToolbar
}