import { combineReducers } from 'redux'
import * as clientReducers from '../app/clients/client.reducers'
import {
	alertReducers,
	identityReducers,
	LocaleReducer,
	namespaceReducers,
	profileReducers,
	settingsReducers,
	ssoDataReducers,
	tenantConfigReducer,
	tenantReducers,
	whiteLabelReducers,
} from '../app/common'
import { DashboardReducer, ReportReducer } from '../app/common/analytics'
import { EntityListReducer, EntityReducer } from '../app/common/base/entity'
import {
	caseDocumentConfig,
	caseFieldConfig,
	caseMasterListConfig,
	caseNoteConfig,
	caseSectionConfig,
	caseSectionFieldConfig,
	caseUserConfig,
	caseValueConfig,
	relatedCaseConfig,
	supportCaseDocumentConfig,
	supportCaseFieldConfig,
	supportCaseNoteConfig,
	supportCaseSectionConfig,
	supportCaseSectionFieldConfig,
	supportCaseValueConfig,
} from '../app/common/ticketing'
import { registerTenantReducers } from '../app/register-tenant'
import { roleReducers } from '../app/role-management'
import { ssoReducers } from '../app/sso-management'
import { SubscriptionReducers } from '../app/subscription'
// import {notificationReducers} from '../app/notification'
// import { botConfigReducers,botPublicConfigReducers } from '../app/bot'
// import { reportsReducers } from '../app/reports';
// import { ssoReducers } from '../app/sso-management';
// console.log(botSettingsReducers,'botSettingsReducers');
const rootReducer = combineReducers({
	...ReportReducer,
	...DashboardReducer,
	...identityReducers,
	...settingsReducers,
	...alertReducers,
	...tenantReducers,
	...registerTenantReducers,
	...EntityReducer,
	...EntityListReducer,
	...roleReducers,
	...ssoReducers,
	...profileReducers,
	...namespaceReducers,
	...ssoDataReducers,
	...SubscriptionReducers,
	// ...dashboardReducers,
	// ...roleReducers,
	// ...categoryReducers,
	// ...categoriesReducers,
	// ...qnaReducers,
	// ...trainReducers,
	// ...smallTalkReducers,
	// ...supportReducers,
	// ...taskReducers,
	// ...dimensionReducers,
	// ...customMetadataReducers,
	// ...notificationReducers,
	// ...reviewchatReducers,
	// ...botConfigReducers,
	// ...streamDataReducers,
	// ...intentReducers,
	// ...queueReducers,
	// ...qnacommonReducers,
	// ...reportsReducers,

	// ...botSettingsReducers,
	// ...botPublicConfigReducers
	...caseValueConfig,
	...caseFieldConfig,
	...caseSectionConfig,
	...caseSectionFieldConfig,
	...caseMasterListConfig,
	...caseNoteConfig,
	...caseDocumentConfig,
	...caseUserConfig,
	...relatedCaseConfig,
	...supportCaseValueConfig,
	...supportCaseFieldConfig,
	...supportCaseSectionConfig,
	...supportCaseSectionFieldConfig,
	...supportCaseNoteConfig,
	...whiteLabelReducers,
	...LocaleReducer,
	...supportCaseDocumentConfig,
	...tenantConfigReducer,
	...clientReducers,
})

export default rootReducer
