import DataSet from '@antv/data-set'
import { Axis, Chart, Geom, Label, Legend, Tooltip } from 'bizcharts'
import React from 'react'

class Lines extends React.Component {
	constructor(props) {
		super(props)
		this.state = { loading: true }
	}
	render() {
		let { t } = this.props
		const loading = this.state && this.state.loading
		if (loading) {
			this.setState({ loading: false })
			return <div>{(t && t('Loading')) || 'Loading...'}</div>
		}

		const data = [
			{
				month: 'Jan',
				Tokyo: 7.0,
				London: 3.9,
			},
			{
				month: 'Feb',
				Tokyo: 6.9,
				London: 4.2,
			},
			{
				month: 'Mar',
				Tokyo: 9.5,
				London: 5.7,
			},
			{
				month: 'Apr',
				Tokyo: 14.5,
				London: 8.5,
			},
			{
				month: 'May',
				Tokyo: 18.4,
				London: 11.9,
			},
			{
				month: 'Jun',
				Tokyo: 21.5,
				London: 15.2,
			},
			{
				month: 'Jul',
				Tokyo: 25.2,
				London: 17.0,
			},
			{
				month: 'Aug',
				Tokyo: 26.5,
				London: 16.6,
			},
			{
				month: 'Sep',
				Tokyo: 23.3,
				London: 14.2,
			},
			{
				month: 'Oct',
				Tokyo: 18.3,
				London: 10.3,
			},
			{
				month: 'Nov',
				Tokyo: 13.9,
				London: 6.6,
			},
			{
				month: 'Dec',
				Tokyo: 9.6,
				London: 4.8,
			},
		]
		const ds = new DataSet()
		const dv = ds.createView().source(data)
		dv.transform({
			type: 'fold', // fold
			fields: ['Tokyo', 'London'], // the keys that needed to be folded on

			key: 'city', // this is key's key

			value: 'temperature', // this is value's key
		})
		console.log(dv)
		const cols = {
			month: {
				range: [0, 1],
				alias: 'Month',
			},
		}
		/** have enough padding to show the titles and legends otherwise they are cut off */
		return (
			<div>
				<Chart height={200} data={dv} scale={cols} forceFit padding={[20, 20, 100, 40]}>
					<Legend />
					<Axis name="month" title />
					<Axis
						name="temperature"
						label={{
							formatter: val => `${val}°C`,
						}}
					/>
					<Tooltip
						crosshairs={{
							type: 'cross',
						}}
					/>
					<Geom type="line" position="month*temperature" size={2} color={'city'} shape={'smooth'} />
					{/* for drawing the points  */}
					<Geom
						type="point"
						position="month*temperature"
						size={4}
						shape={'circle'}
						color={'city'}
						style={{
							stroke: '#fff',
							lineWidth: 1,
						}}>
						<Label
							content={[
								'month*temperature',
								(month, temperature) => {
									return `${temperature}℃`
								},
							]}
						/>
					</Geom>
				</Chart>
			</div>
		)
	}
}

export default Lines
