import { settingsConstants } from '../';

export const tenantConfig = (state = {}, action) => {
  switch (action.type) {
   
    case settingsConstants.GET_TENANT_CONFIG_REQUEST:
      return {
        ...state,
        loading: true
      };
    case settingsConstants.GET_TENANT_CONFIG_SUCCESS:
      if (action.payload) {
        return {
          ...state,
          loading: false,
          data: {...state.data,...action.payload}
        }
      } else {
        return {
          ...state,
          loading: false
        }
      }
    case settingsConstants.GET_TENANT_CONFIG_FAILURE:
      return {
        ...state,
        loading: false
      };

      case settingsConstants.GETALL_TENANT_CONFIG_REQUEST:
      return {
        ...state,
        loading: true
      };
    case settingsConstants.GETALL_TENANT_CONFIG_SUCCESS:
      if (action.payload) {
        return {
          ...state,
          loading: false,
          data: action.payload
        }
      } else {
        return {
          ...state,
          loading: false
        }
      }
    case settingsConstants.GETALL_TENANT_CONFIG_FAILURE:
      return {
        ...state,
        loading: false
      };


    default:
      return state
  }
}