import Editor, { useMonaco } from '@monaco-editor/react'
import React, { useEffect, useMemo, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { Button, Modal, ModalBody } from 'reactstrap'
import { identityServices } from '../../../../common'
import { formFilters } from '../../../helpers/utils'

const MonacoEditor = props => {
	//let editor = null
	const [completionValue, setCompletionValue] = useState({})
	const [preview, setPreview] = useState(false)
	const [depends, setDepends] = useState('')
	const monaco = useMonaco()
	const editorOptions = useMemo(
		() => ({
			wordWrap: 'on',
			scrollBeyondLastLine: false,
			automaticLayout: true,
			selectionHighlight: true,
			autoSize: true,
		}),
		[]
	)
	const [editorValue, setEditorValue] = useState(props.value)

	const handleChange = (_value, event) => {
		let value = _value
		if (value) {
			setEditorValue(value)
			props.onComponentChange(props.name, value)
		}
	}
	// useEffect(() => {
	//   window.addEventListener('resize', handleResize);
	// },[editor])
	// const handleResize = () => editor && editor.layout()
	// const handleEditorDidMount = editor => editor = editor;
	const previewModal = () => {
		setPreview(!preview)
	}
	useEffect(() => {
		if (props.formValues && props.dependsOn && props.params && !props.params.itemId) {
			let dependsOnKey = Object.keys(props.dependsOn)
			setDepends(props.formValues[dependsOnKey[0]])
		}
	}, [props])
	useEffect(() => {
		const ShowAutocompletion = obj => {
			// Disable default autocompletion for javascript
			monaco.languages.typescript.javascriptDefaults.setCompilerOptions({ noLib: true })

			// Helper function to return the monaco completion item type of a thing
			function getType(thing, isMember) {
				isMember = isMember == undefined ? (typeof isMember == 'boolean' ? isMember : false) : false // Give isMember a default value of false

				switch ((typeof thing).toLowerCase()) {
					case 'object':
						return monaco.languages.CompletionItemKind.Class

					case 'function':
						return isMember ? monaco.languages.CompletionItemKind.Method : monaco.languages.CompletionItemKind.Function

					default:
						return isMember ? monaco.languages.CompletionItemKind.Property : monaco.languages.CompletionItemKind.Variable
				}
			}

			// Register object that will return autocomplete items
			monaco.languages.registerCompletionItemProvider(props.language, {
				// Run this function when the period or open parenthesis is typed (and anything after a space)
				triggerCharacters: ['.', '('],

				// Function to generate autocompletion results
				provideCompletionItems: function (model, position, token) {
					// Split everything the user has typed on the current line up at each space, and only look at the last word
					var last_chars = model.getValueInRange({ startLineNumber: position.lineNumber, startColumn: 0, endLineNumber: position.lineNumber, endColumn: position.column })
					var words = last_chars.replace('\t', '').split(' ')
					var active_typing = words[words.length - 1] // What the user is currently typing (everything after the last space)

					// If the last character typed is a period then we need to look at member objects of the obj object
					var is_member = active_typing.charAt(active_typing.length - 1) == '.'

					// Array of autocompletion results
					var result = []

					// Used for generic handling between member and non-member objects
					var last_token = obj
					var prefix = ''
					let removeTrigger = active_typing

					if (is_member) {
						// Is a member, get a list of all members, and the prefix
						var parents = removeTrigger.substring(0, active_typing.length - 1).split('.')
						last_token = obj[parents[0]]
						prefix = parents[0]

						// Loop through all the parents the current one will have (to generate prefix)
						for (var i = 1; i < parents.length; i++) {
							if (last_token.hasOwnProperty(parents[i])) {
								prefix += '.' + parents[i]
								last_token = last_token[parents[i]]
							} else {
								// Not valid
								return result
							}
						}

						prefix += '.'
					}

					// Get all the child properties of the last token
					for (var prop in last_token) {
						// Do not show properites that begin with "__"
						if (last_token && last_token.hasOwnProperty(prop) && !prop.startsWith('__')) {
							// Get the detail type (try-catch) incase object does not have prototype
							var details = ''
							try {
								details = last_token[prop].__proto__.constructor.name
							} catch (e) {
								details = typeof last_token[prop]
							}

							// Create completion object
							var to_push = {
								label: prefix + prop,
								kind: getType(last_token[prop], is_member),
								detail: details,
								insertText: prop,
							}

							// Change insertText and documentation for functions
							if (to_push.detail.toLowerCase() == 'function') {
								to_push.insertText += '('
								to_push.documentation = last_token[prop].toString().split('{')[0] // Show function prototype in the documentation popup
							}
							if (to_push.detail.toLowerCase() == 'object') {
								//to_push.insertText = `{${active_typing + to_push.insertText}}`;
								//to_push.documentation = (last_token[prop].toString()).split("{")[0]; // Show function prototype in the documentation popup
							}
							if (to_push.detail.toLowerCase() == 'string') {
								//to_push.insertText = `{${active_typing + to_push.insertText}}`;
								//to_push.documentation = (last_token[prop].toString()).split("{")[0]; // Show function prototype in the documentation popup
							}
							// Add to final results
							result.push(to_push)
						}
					}
					return {
						suggestions: result,
					}
				},
			})
		}

		async function fetchData(api) {
			const requestOptions = {
				method: `${api.method || 'GET'}`,
				headers: { 'Content-Type': 'application/json' },
				url: `${props.apiUrl || (props.tenant && props.tenant.apiUrl)}/${api.path}`,
			}
			if (api.path.indexOf('?') !== -1) requestOptions.url += '&'
			else requestOptions.url += '?'
			if (api.filters && api.filters.pop) {
				requestOptions.url += '$filter='
				requestOptions.url += formFilters({ filters: api.filters })
			} else if (api.params && api.params.filter && api.params.filter.pop) {
				let filterValues = Object.assign({}, props.formValues, props.dependsOn || {})
				if (requestOptions.url.indexOf('?') !== -1) requestOptions.url += '&$filter='
				else requestOptions.url += '$filter='
				api.params.filter.forEach((p, i, a) => {
					if (i === a.length - 1) requestOptions.url += `${p} eq '${filterValues[p] || null}'`
					else requestOptions.url += `${p} eq '${filterValues[p] || null}' and`
				})
			}

			if (api.params && api.params.orderBy && api.params.orderBy.pop) {
				requestOptions.url += '&$orderby='
				api.params.orderBy.forEach((p, i, a) => {
					if (i === a.length) requestOptions.url += `${p}`
					else requestOptions.url += `${p}`
				})
			}

			let limit = api.limit
			if (api && api.limit) {
				requestOptions.url += `&$top=${limit}`
			}

			if (api.body && api.body.pop) {
				requestOptions.json = true
				requestOptions.body = {}
				api.body.forEach(p => {
					requestOptions.body[p] = props.dependsOn[p]
				})
			}
			try {
				let res
				let response = await identityServices.identityApi.request(requestOptions)
				if (api.response) {
					if (api.response.ref) {
						let refs = api.response.ref.split('.')
						res = refs.reduce((p, c) => {
							return p[c]
						}, response)
					} else res = response
					if (res && res.length && api.response.value) {
						if (api.response.value.includes('.')) {
							let refsValue = api.response.value.split('.')
							res = res[0]
							res = refsValue.reduce((p, c) => {
								return p[c]
							}, res)
						} else {
							res = api.response.value
						}
					}

					if (res) setCompletionValue(res)
				}
				return res
			} catch (err) {
				console.error(`Attributes fetch for ${props.name} failed with error ${err}. Url ${requestOptions.url}`)
			}
		}
		if (props.autoCompletionApiProps && monaco) {
			let api = props.autoCompletionApiProps.api
			if (!api) {
				return
			}
			fetchData(api)
			ShowAutocompletion(completionValue)
		}
		if (monaco) {
			setEditorValue(props.value)
		}
	}, [depends, monaco, completionValue, props.autoCompletionApiProps, props.formValues, props.name, props.language])

	return (
		<>
			<div className={editorValue || props.value ? 'monaco-editor-Container' : 'monaco-editor-Container-noContent'} style={{ height: props.height }}>
				<Editor defaultLanguage={props.language || 'JSON'} defaultValue={props.value} onChange={handleChange} options={editorOptions} />
			</div>
			{(editorValue || props.value) && props.language === 'html' && <Button onClick={previewModal}>{props.t('Preview')}</Button>}
			{preview && (
				<Modal autoFocus={false} backdrop={true} isOpen={preview} toggle={previewModal} className="preview-card ticket-form">
					<h4 className="modal-min-heading">{props.t('Preview HTML')}</h4>
					<ModalBody className="ticket-inner-form">
						<div dangerouslySetInnerHTML={{ __html: editorValue || props.value }} />
					</ModalBody>
					<div className="button-wrap" style={{ textAlign: 'center' }}>
						<Button color="primary" onClick={previewModal}>
							<img src={`assets/img/no.png`} alt="no" />
							{props.t('Close')}
						</Button>
					</div>
				</Modal>
			)}
		</>
	)
}

export default withTranslation()(MonacoEditor)
