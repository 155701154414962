import { identityConstants, profileConstants, profileServices } from '../'
import { alertActions } from '../../common'

const modifyChangePasswordReqeust = () => ({
	type: profileConstants.MODIFY_CHANGE_PASSWORD_REQUEST,
})

const modifyChangePasswordSuccess = () => ({
	type: profileConstants.MODIFY_CHANGE_PASSWORD_SUCCESS,
})

const modifyChangePasswordFailure = error => ({
	type: profileConstants.MODIFY_CHANGE_PASSWORD_FAILURE,
	error,
})

export const changeUserPassword = (tenandId, userId, body, t, apiUrl) => async dispatch => {
	dispatch(modifyChangePasswordReqeust())
	let errorMsg = (t && t('Unable to change password, Please try later')) || 'Unable to change password, Please try later'
	try {
		let resp = await profileServices.changeUserPassword(tenandId, userId, body, apiUrl)
		if (resp === 204) {
			dispatch(modifyChangePasswordSuccess())
			dispatch(alertActions.success((t && t('Password has been changed successfully')) || 'Password has been changed successfully'))
		} else dispatch(alertActions.success(errorMsg))
	} catch (error) {
		errorMsg = errorMsg + ' ' + (error ? error.message : '')
		dispatch(modifyChangePasswordFailure(errorMsg))
		dispatch(alertActions.error(errorMsg))
	}
}

const modifyUsersReqeust = () => ({
	type: profileConstants.MODIFY_USERS_REQUEST,
})

const modifyUsersSuccess = users => ({
	type: profileConstants.MODIFY_USERS_SUCCESS,
	userUpdate: users,
})

const modifyUsersFailure = () => ({
	type: profileConstants.MODIFY_USERS_FAILURE,
})

export const modifyUsers = (tenandId, userId, userPayload, t, apiUrl) => async (dispatch, getState) => {
	dispatch(modifyUsersReqeust())
	let errorMsg = (t && t('Unable to update user, Please try later')) || 'Unable to update user, Please try later.'
	try {
		if (tenandId?.errorMsg) throw errorMsg = tenandId.errorMsg
		await profileServices.modifyUsers(tenandId, userId, userPayload, apiUrl)
		dispatch(modifyUsersSuccess(userPayload))
		const { identity } = getState()
		let newIdentity = identity
		newIdentity.profile.name = userPayload ? userPayload.firstName + ' ' + userPayload.lastName : newIdentity.profile.name
		dispatch(setIdentityData(newIdentity))
		dispatch(alertActions.success((t && t('User updated successfully')) || 'User updated successfully'))
	} catch (error) {
		dispatch(modifyUsersFailure())
		dispatch(alertActions.error(errorMsg))
	}
}

export const setIdentityData = newIdentity => {
	return function (dispatch) {
		return dispatch({
			type: identityConstants.SET_IDENTITY,
			payload: newIdentity,
		})
	}
}

const getUserReqeust = () => ({
	type: profileConstants.GET_USER_REQUEST,
})

const getUserSuccess = payload => ({
	type: profileConstants.GET_USER_SUCCESS,
	payload,
})

const getUserFailure = payload => ({
	type: profileConstants.GET_USER_FAILURE,
	payload,
})

export const getUserById = (tenandId, userId, t, apiUrl) => async dispatch => {
	dispatch(getUserReqeust())
	let errorMsg = (t && t('Cannot fetch the User at this moment, please try again later')) || 'Cannot fetch the at this moment, please try again later'
	try {
		let users = await profileServices.getUserById(tenandId, userId, apiUrl)
		dispatch(getUserSuccess(users))
	} catch (error) {
		errorMsg = errorMsg + ' ' + (error ? error.message : '')
		dispatch(getUserFailure(errorMsg))
		dispatch(alertActions.error(errorMsg))
	}
}
