
export const arraysEqual = (a, b) => {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    for (let i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) return false;
    }
    return true;
}
/**
 * construct the filters object with values from given inputValues and meta
 * @param {*} param0 
 */
export const getObjByPath = ({ ref, path }) => {
    if (!ref || !path) return
    let objs = path.split('.')
    return objs.reduce((p, c) => {
        let arrays = c.split('[')
        let obj = p[arrays[0]]
        if (arrays.length === 1) return obj
        else {
            arrays = arrays.slice(1)
            return arrays.reduce((p1, c1) => {
                let index = parseInt(c1.slice(0, -1))
                if (isNaN(index)) return p1[c1.slice(0, -1)]
                else return p1[index]
            }, obj)
        }
    }, ref)
}
/**
 * 
 * @param {data} expected to be an array of rows to filter
 * @param {filters} expected to be an array of filter objects...
 * each object in filter has following structure...
 *  field: row key
 *  value / values: value if single value, values if multiple values as array of value.
 *  operator: string of certain operators ( in, range, is , is not, contains, does not contain, is empty, is not empty)
 */
export const filterMetricRow = ({ data, filters, dv }) => {
    if ((!data || !data.pop) && !dv) return
    if (!filters) return data
    const filterFn = r => {
        return filters.every(f => {

            let x = r[f.field]
            if (f.values && f.operator) {
                switch (f.operator) {
                    case 'in':
                        return f.values.includes(x)

                    case 'range':
                        if (isNaN(new Date(x).valueOf()) || !isNaN(Number(x)))
                            return f.values[0] <= x && x <= f.values[1]  // for numbers, strings, booleans
                        else { // for date comparisions
                            let y = new Date(x)
                            return new Date(f.values[0]) <= y && y <= new Date(f.values[1])
                        }

                    default:
                        return false
                }
            } else if (f.value) {
                let rv = f.value
                if (!(isNaN(new Date(x).valueOf()) || !isNaN(Number(x)))) { //if type of x is not date
                    x = new Date(x)
                    rv = new Date(rv)
                }
                if (f.operator) {
                    switch (f.operator) {
                        case 'is':
                            return x === rv
                        case 'is not':
                            return x !== rv
                        case 'is empty':
                            return x == undefined
                        case 'is not empty':
                            return x !== undefined
                        case 'contains':
                            return x.includes(rv)
                        case 'does not contain':
                            return !x.includes(rv)
                        case 'eqOrEmpty':
                            return x === rv || x == undefined
                        case '>':
                            return x > rv
                        case '<':
                            return x < rv
                        case '<=':
                            return x <= rv
                        case '>=':
                            return x >= rv
                        default:
                            return x === rv
                    }
                }
                return x === f.value || (f.value === 'empty' && x == undefined)
            }
            else return false
        })
    }
    if (data) return data.filter(filterFn)
    else if (dv) {
        dv.transform({
            type: 'filter',
            callback: filterFn

        })
        return dv
    }
}
//TODO: Currently supports only String. Need to support other data types
export const formFilters = ({ filters }) => {
    let filterString = ""
    if (!filters || !filters.pop) return filterString
    filters.every(f => {
        if (filterString != "") {
            filterString += ' and '
        }
        if (f.field && f.operator && f.value) {
            switch (f.operator) {
                case 'is':
                    return filterString += `${f.field} eq '${f.value}'`
                case 'is not':
                    return filterString += `${f.field} ne '${f.value}'`
                case 'is empty':
                    return filterString += `${f.field} ne null OR length(${f.field}) eq 0`
                case 'is not empty':
                    return filterString += `${f.field} ne null`
                case 'contains':
                    return filterString += `substringof(${f.value}, ${f.field})`
                // case 'does not contain':
                //     return !x.includes(rv)
                // case 'eqOrEmpty':
                //     return x === rv || x == undefined
                // case '>':
                //     return x > rv
                // case '<':
                //     return x < rv
                // case '<=':
                //     return x <= rv
                // case '>=':
                //     return x >= rv
                default:
                    return null
            }
        }
    })
    return filterString;
}

export const prepareFilters = ({ filtersObj, values }) => {
    let filters = []
    if (!filtersObj) return
    Object.keys(filtersObj).forEach(field => {
        let filter = filtersObj[field]
        let filterObj
        if (typeof filter === 'object') {
            filterObj = { field }
            if (filter.values) filterObj.values = filter.values.map(v => (values && values[v]) || v)
            if (filter.value) filterObj.value = (values && values[filter.value]) || filter.value
            filterObj.operator = filter.operator
            filters.push(filterObj)
        } else {
            if(values[filter]){
            filterObj = { field, value: (values && values[filter]) || filter }
            filters.push(filterObj)
            }
        }
    })
    return filters
}
export const formatJson = (obj, ind = 0, sep = '\n') => {
    // let that = this
    if (!obj) return
    if (typeof obj !== 'object') return obj
    let txt = ''
    let iTxt = ''
    if (!ind) ind = 0
    if (ind) iTxt = `\t`.repeat(ind)
    
    txt += Object.keys(obj).map(k => {
        if (obj[k] && obj[k].pop) return `${iTxt}${k}:${sep}${obj[k].map(e => formatJson(e, ind + 1)).join(`,${sep}${iTxt}`)}`
        else if (obj[k] && typeof obj[k] === 'object') return `${iTxt}${k}:${sep}${formatJson(obj[k], ind + 1)}`
        else return `${iTxt}${k}:\t${obj[k]}`
    }).join(`${sep}`)
    return txt
}

export const flatternJson = (data, colToFlat) => {

    if (!data || !colToFlat) return
    if (typeof data !== 'object') return data
    if (!Array.isArray(data)) {
        data = [data]
    }
    let retDatas = []
    let retData = {}
    data.forEach(d => {
        let valueJson = d[colToFlat];
        if (valueJson) {
            retData = {
                ...d
            }
            Object.keys(valueJson).forEach(v => {
                if (valueJson[v] !== 'object') {
                    retData[v] = valueJson[v]
                } else {
                   // console.log(`${v} is an object. Nested object flattern`)
                }
            })
            retDatas.push(retData)
        } else {
            //console.log(`${colToFlat} is not found in data.`)
        }
    })

    return retDatas
}

export const cloneObject = (obj) => {
    var clone = {};
    for (var i in obj) {
        if (obj[i] != null && obj[i].pop)
         clone[i] = obj[i].map(j=>Object.assign({}, j));
        else if (obj[i] != null && typeof (obj[i]) == "object")
            clone[i] = cloneObject(obj[i]);
        else
            clone[i] = obj[i];
    }
    return clone;
}

export const mathOperation = ({ operation }) => {
    let operationString = ""
    if (!operation) return operationString
    switch (operation) {
            case 'sum':
                return operationString = '+'
            case 'diff':
                return operationString = '-'
            case 'divide':
                return operationString = '/'
            case 'multiple':
                return operationString = '*'
            default:
                return null
    }
}

export const aggregateOperation = ({operation, data, key}) => {
    let total = 0
    if (!operation && data.length < 0 && !key) return total
    switch (operation) {
            case 'sum':
                for ( var i = 0, _len = data.length; i < _len; i++ ) {
                    total += Number(data[i][key])
                }
                return total
            case 'diff':
                total = Number(data[0][key]);
                for (var i = 1, _len = data.length; i < _len; i++)
                {
                    total -= Number(data[i][key]);
                }
                return total;
            default:
                return null
    } 
}

export const getSLA = ({data, metricValue}) => {
    data = data[0] || null
    if(data){
        let ret=null
        data.levels && data.levels.map(level =>{
          if(level.max && !level.min && metricValue <= level.max){
            ret = level
          }
          else if(level.max && level.min && metricValue >= level.min && metricValue <=level.max){
            ret = level
          }
          else if(level.min && !level.max && metricValue >= level.min){
            ret = level
          }  
          })
          return ret
    }else{
        return data
    }
}