import React, { Suspense } from 'react'
import { withTranslation } from 'react-i18next'
import { Action, ChatWidget, ContentView, Field, GridLayout, TabDetail, Treeview, VideoPlayer, WorkflowButton } from '.'
import * as EntityComponents from './'
class SectionDetail extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			meta: props.meta,
			envityValues: props.entityValues,
			name: props.name,
			idKey: props.meta && props.meta.idKey,
			itemId: props.params && props.params.itemId,
			propsValues: this.props.propsValues || {},
			switchTabIcon: false,
		}
	}
	componentWillReceiveProps(props) {
		const { entityValues, meta, name } = this.props
		const { sections } = meta
		// const itemsByCols = {}
		const secDef = sections[name]
		if (props.propsValues) {
			this.setState({ propsValues: props.propsValues })
		} else {
			this.setState({ propsValues: null })
		}

		let linked = this.props.linked || {}
		let linkedCount = this.state.linkedListsCount || {}
		if (secDef && secDef.loadList && entityValues) {
			let l = secDef.loadList

			linked[l.targetProp] = this.props.entity[l.itemType]
			linkedCount[l.targetProp] = this.props.entity[l.itemType + '_count']
			if (!linked || !linked[l.targetProp]) {
				this.props.getAllLinked({ itemType: l.itemType, filter: l.filter, orderBy: l.orderBy, top: l.top, t: this.props.t })
				linked[l.targetProp] = true
			}
		}

		if (this.props.updateLists) this.props.updateLists(linked, linkedCount)
		this.setState({
			meta: props.meta,
			entityValues: props.entityValues,
			idKey: props.meta.idKey,
			itemId: props.params && props.params.itemId,
			linkedLists: linked,
			linkedListsCount: linkedCount,
		})
	}
	renderItem(item, t) {
		if (!item) return
		const { fields, actions, sections, tabs, workflowActions, gridMeta } = this.state.meta
		const { entityValues } = this.state
		let dependsOn
		let itemDef, columns, refData, newProps
		switch (item.type) {
			case 'section':
				itemDef = sections[item.name]
				if (itemDef && !itemDef.hide)
					return (
						<div key={item.name}>
							<div className="clearfix"></div>
							<div className="col-sm-12" key={`${item.name}_1`}>
								<SectionDetail key={item.name} entityValues={entityValues} {...this.props} name={item.name} />
							</div>
						</div>
					)
				break
			case 'field':
				itemDef = fields[item.name]
				if (itemDef) {
					if (itemDef.dependsOn && itemDef.dependsOn.pop && entityValues) {
						dependsOn = {}
						itemDef.dependsOn.forEach(f => {
							dependsOn[f] = entityValues[f]
						})
					}
					if (entityValues && !entityValues[itemDef.name]) {
						entityValues[itemDef.name] = itemDef.default ? itemDef.default : null
					}
					return (
						<React.Fragment key={item.name}>
							<Field key={item.name} name={item.name} dependsOn={dependsOn} field={itemDef} formValues={entityValues} {...this.props} />
						</React.Fragment>
					)
				}
				break
			case 'tabGroup':
				itemDef = tabs[item.name]
				if (itemDef)
					return (
						<React.Fragment key={item.name}>
							<TabDetail
								key={item.name}
								{...this.props}
								tabGroupItem={itemDef}
								sections={sections}
								entityValues={entityValues}
								handleClickArrow={this.handleClickArrow.bind(this)}
								switchTabIcon={this.state.switchTabIcon}
								handleCustomAction={this.props.handleCustomAction}
							/>
						</React.Fragment>
					)
				break
			case 'action':
				itemDef = actions[item.name]
				if (itemDef)
					return (
						<div key={item.name}>
							<Action key={item.name} actionMeta={itemDef} {...this.props} />
						</div>
					)
				break
			case 'workflowAction':
				itemDef = workflowActions[item.name]
				if (itemDef) {
					let possibleStates = []
					if (this.props.entity && this.props.entity.entityValues) {
						possibleStates = this.props.entity.entityValues[itemDef.ref]
					}
					return (
						<div key={item.name}>
							<WorkflowButton workflowActionMeta={itemDef} {...this.props} possibleStates={possibleStates} />
						</div>
					)
				}
				break
			case 'cardList':
				itemDef = gridMeta[item.name]
				columns =
					itemDef &&
					itemDef.items &&
					itemDef.items.map(c => {
						c.shown = true
						return c
					})
				newProps = { ...this.props }
				if (itemDef.dataSource.ref) {
					let refs = itemDef.dataSource.ref.split('.')
					refData = refs.reduce((p, c) => {
						return p && p[c]
					}, entityValues)
				} else refData = entityValues
				if (itemDef && columns)
					return (
						<GridLayout
							key={item.name}
							meta={this.props.meta}
							list={refData}
							columns={columns}
							fields={fields}
							entity={this.props.entity}
							itemDef={itemDef}
							itemClick={this.props.itemClick}
							currentItem={item}
							entityValues={this.state.entityValues}
							{...newProps}
						/>
					)
				break
			case 'grid':
				itemDef = gridMeta[item.name]
				columns = itemDef && itemDef.items
				if (itemDef.dataSource.ref) {
					let refs = itemDef.dataSource.ref.split('.')
					refData = refs.reduce((p, c) => {
						return p && p[c]
					}, entityValues)
				} else refData = entityValues
				newProps = { ...this.props }
				if (newProps.columns) delete newProps['columns']
				if (newProps.itemDef) delete newProps['itemDef']
				if (newProps.list) delete newProps['list']
				if (itemDef && columns)
					return (
						// <div key={item.name} className="col-sm-12">
						
						<GridLayout
							key={item.name}
							lookUpMapping={this.state.lookUpMapping}
							meta={this.props.meta}
							list={refData}
							columns={columns}
							fields={fields}
							totalCount={this.props.totalCount}
							importData={this.props.importData}
							handleListAction={this.props.handleListAction}
							entity={this.props.entity}
							itemDef={itemDef}
							itemClick={this.props.itemClick}
							currentItem={item}
							gridParentData={this.state.gridParentData}
							entityValues={this.state.entityValues}
							{...newProps}
						/>
						// </div>
					)
				break
			case 'player':
				if (this.state.propsValues && (this.state.propsValues.videoUrl || this.state.propsValues.refUrl)) {
					return <VideoPlayer key={item.name} {...this.state.propsValues} />
				} else {
					return <span key={item.name}> </span>
				}
			// break;
			case 'chat':
				if (window.EmbeddableWidget && window.EmbeddableWidget.el) window.EmbeddableWidget.unmount()
				if (this.state.propsValues && this.state.propsValues.flowName) {
					return <ChatWidget key={item.name} params={item.params} classNames={item.classNames} {...this.state.propsValues} {...this.props} />
				} else {
					return <span key={item.name}> </span>
				}
			case 'treeView':
				return <Treeview key={item.name} {...this.props} />

			// break;
			case 'contentView':
				return <ContentView key={item.name} {...this.props} />

			// break;
			default:
		}
	}
	handleClickArrow() {
		let body = document.body
		if (!this.state.switchTabIcon) body.classList.add('arrow-left-right')
		else body.classList.remove('arrow-left-right')
		this.setState({ switchTabIcon: !this.state.switchTabIcon })
	}
	render() {
		const { entityValues, meta, name, linkedLists, t } = this.props
		const { sections } = meta
		const itemsByCols = {}
		const secDef = sections[name]
		secDef &&
			secDef.cols &&
			secDef.items.forEach(i => {
				if (i.col) {
					itemsByCols[i.col] = itemsByCols[i.col] || []
					itemsByCols[i.col].push(i)
				} else {
					itemsByCols.unassigned = itemsByCols.unassigned || []
					itemsByCols.unassigned.push(i)
				}
			})
		itemsByCols.unassigned = itemsByCols.unassigned || (secDef && !secDef.cols && secDef.items)
		let Component
		let compProps = {}
		if (secDef) {
			// let cPath = path.join('./../../../..' , (secDef.path||'') ,  secDef.component)
			// path = './test/DetailHeader'
			// console.error(`Components: ${Object.keys(EntityComponents)}`)

			Component = secDef.component !== 'DetailHeader' && EntityComponents[secDef.component]
			// Component = React.lazy(() => import( `${path}`))
			if (secDef.props) {
				Object.keys(secDef.props).forEach(k => {
					if (typeof secDef.props[k] === 'string')
						compProps[k] = (entityValues && entityValues[secDef.props[k]]) || (linkedLists && linkedLists[secDef.props[k]])
				})
			}
			if (secDef.loadList && this.state.linkedLists) {
				// the lists that are attached to this component and loaded here.
				compProps[secDef.loadList.targetProp] = this.state.linkedLists[secDef.loadList.targetProp]
			}
		}
		if (secDef && secDef.cols && itemsByCols.unassigned) {
			console.error(`Some elements are not assigned to columns ${itemsByCols.unassigned}`)
			return <div></div>
		} else
			return (
				<Suspense fallback={<h2> {(t && t('Loading')) || 'Loading...'}</h2>}>
					{secDef && secDef.cols && (
						<>
							{secDef.cols.map((c, i, arr) => {
								if (this.state.switchTabIcon) {
									c = i === 0 ? '' : 12
								}
								return (
									<div className={`col-sm-${c} section-frame`} key={i + 1}>
										{itemsByCols[i + 1] &&
											itemsByCols[i + 1].map(item => {
												return this.renderItem(item, t)
											})}
									</div>
								)
							})}
						</>
					)}
					{itemsByCols &&
						itemsByCols.unassigned &&
						itemsByCols.unassigned.map(item => {
							return this.renderItem(item, t)
						})}
					{
						// component
						Component && <Component {...this.props} {...compProps} propsMap={secDef.otherProps} />
					}
				</Suspense>
			)
	}
}
export default withTranslation()(SectionDetail)
