import React, { useEffect, useState } from 'react';
import { FormGroup, Label } from 'reactstrap';
import { MapInput, SelectInput, TextInput } from './formInput';
import * as helper from './helper';


// Json List Component for simple json Array 
const JsonDictComponent=(props)=>{
    const [propList, setPropList]=useState(null)
    const [isProps, setIsProps]=useState(false);
    console.log(propList, isProps)
    useEffect(()=>{
        setIsProps(helper.isProps(props))
        setPropList(props)
    },[props]
    )

    // const {childProps}=props;

    const saveToParent=()=>{
        props.handleInputSubmit()
    }
    const changeToParent=(inputName,inputValue)=>{
        props.handleInputChange(props.name,inputValue)
    }

    const switchAddComponent=()=>{
        if(props && props.dynamicKey){
            return (
                <AddComponentWithDynamicKey
                {...props}
                changeToParent={changeToParent}
                saveToParent={saveToParent}
                />
            )
        }
        else{
            return (
                <AddComponent
            {...props}
            changeToParent={changeToParent}
            saveToParent={saveToParent}
            />
        )
        }
        }

    return (
        <div className="row">
        <div className="col-sm-11">
        
        {switchAddComponent()}


        </div>
        <div className="col-sm-1 listbtn">
        <div onClick={props.handleInputSubmit}  className="listbtn success"><i className="fa fa-plus"></i></div>
        </div>
        </div>
   

    )


}




const AddComponent=(props)=>{
    const [propList,setPropList]=useState({})
    const [formKeyValue,setFormKeyValue]=useState('');
    const [state,setState]=useState({
        keyName:'',
        inputValue:'',
        filteredProps:'',
        toggleForm:false,
        inputFormKey:null,
        keyValuePair:null,
    })

    useEffect(()=>{setPropList(props)},[props])

    const inputChange=(inputKey,inputName)=>{
        const {childProps:{options}}=propList;
        let filteredProps=helper.filterSelectKey(inputKey,options)
        if(filteredProps && filteredProps!=undefined){
            let inpKey={...state.inputFormKey,[inputKey]:''}
        setState({inputValue:inputKey,filteredProps:filteredProps,toggleForm:true,inputFormKey:inpKey})
        }
        else{
        setState({toggleForm:false})
        }
        //props.handleInputChange(inputName,inputValue)

    }

    const handleInputChange=(formKeyValue)=>{
        let inputFormKey={...formKeyValue}
        setFormKeyValue(inputFormKey)
        props.changeParent(inputFormKey)
    }


    const handleKeyDown=()=>{
        props.saveParent()
        setState({
            keyName:'',
            inputValue:'',
            filteredProps:'',
            toggleForm:false,
            inputFormKey:null,
        });
        setFormKeyValue({}) 
        }

      const {inputValue,toggleForm,filteredProps,inputFormKey}=state

      return (
        <>
        <SelectInput
            {...propList.childProps}
            handleChange={inputChange}
            value={inputValue}
            handleEnter={handleKeyDown}

        />

        {toggleForm && 
        <InputForm
        {...filteredProps}
        isProps={propList.isProps}
        selectedKey={inputValue}
        preTag={props.preTag}
        mapppingKey={props.name}
        inputFormKey={formKeyValue || inputFormKey}
        handleChange={handleInputChange}
        handleEnter={handleKeyDown}
        
        />
        
        }
        </>
    )
}






const InputForm=(props)=>{
    const [inputValue,setInputValue]=useState({});
    const [state,setState]=useState({selectedKey:props.selectedKey})
    
    useEffect(()=>{
        setInputValue(props.inputFormKey)
        setState({selectedKey:props.selectedKey})
    },[props])


const inputChange=(outPutValue,outPutName)=>{
    let InputFormKeyValue={...inputValue,[outPutName]:outPutValue}

    props.handleChange(InputFormKeyValue)
    setInputValue(InputFormKeyValue)


}
const handleInputSubmit=()=>{
    props.handleEnter()
}
const componentChange =(inputName,inputValue)=>{
    inputChange(inputValue,inputName)
}


const {selectedKey}=state;
return (
    <div className="row">
    <div className="col-sm-12 ">
    <FormGroup className="bgwhite border">
    <Label>{props.label || props.name}</Label>
    <MapInput
    {...props}
    handleChange={inputChange}
    value={inputValue[selectedKey]}
    placeholder={'Edit'}
    handleEnter={handleInputSubmit}
    onComponentChange={componentChange}


/>
</FormGroup>
</div>
</div>
)

}



const AddComponentWithDynamicKey=(props)=>{
    const [state,setState]=useState({
        newKey:'',
        keyValue:null,
        toggleAddComponent:false
    })

const handleKeyChange=(KeyValue,inutName)=>{
    setState({...state,newKey:KeyValue})    
}

const addKey=()=>{
    setState({...state,toggleAddComponent:true})
}

const changeParent=(inputValue)=>{
    const {newKey}=state
    let newValues={...state.keyValue,[newKey]:inputValue}
    setState({...state,keyValue:newValues})
    props.changeToParent(props.name,newValues)

}

const saveParent=()=>{
    props.saveToParent()
}


    const {newKey,toggleAddComponent}=state
    return(
    <>  
    {/* {props.preTag(state)} */}
        <TextInput
            name="createKey"
            value={newKey || ''}
            handleChange={handleKeyChange}
            handleEnter={addKey}
            type="text"
        />


{toggleAddComponent && 
        <AddComponent
    {...props}
    name = {newKey}
    changeParent={changeParent}
    saveParent={saveParent}
    />

}


    </>
)

}


const JsonDictListingComponent=(props)=>{
}




export {
    JsonDictComponent,
    AddComponent,
    JsonDictListingComponent
};
