import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import Select from 'react-select'
import { Input, Label } from 'reactstrap'
import { identityServices } from '../../../../common'

/**
 * fe actions
 *  handleChange
 *  handleChangeSelect
 *  setDependency
 *  fetchApi
 *  textField
 *  selectField
 *  mapSelectFields
 *  setSelectField
 * prop actions
 *  handleChange ( parent component )
 * props prop
 *  field : {type, property : {options: {isStatic, apiUrl, moduleName, listMap: {key, value}}, enums:[] }}
 *  resetFilter
 *  apiOptions : { <field>: []}
 * state
 *  formValues, formField, selectOptions, options, isSelect, fieldType, formValue, resetValue, formOptions, apiOptions, shouldUpdate, onChangeOption
 */
class SearchTextField extends Component {
	constructor(props) {
		super(props)
		this.state = {
			field: props.field || false,
			options: props.field.options || [], // processed options
			isSelect: false, // bool indicating if type is select
			fieldType: (props.field && props.field.type) || 'text', // field.type
			formValues: props.formValues || {}, // dict of field name and field value passed from the parent.

			dependsOn: props.dependsOn,
			forceDisable: props.forceDisable, // override field level disable false to true
			forceEnable: props.forceEnable, // overrided field level disable true to false
			defDisable: props.defDisable, // the default option if not specified at field level
		}
		this.handleChange = this.handleChange.bind(this)
	}
	componentWillMount() {
		const { field } = this.props
		// let formOptions
		this.setState({
			isSelect: field.type === 'select' ? true : false,
			fieldType: field.type || 'text',
			formValues: this.props.formValues,
		})
		field && field.api && this.getOptions()
	}
	componentWillReceiveProps(props) {
		let dependsOn = this.props.dependsOn
		let changed
		if (Object.keys(dependsOn).length) {
			changed = Object.keys(dependsOn).some(k => {
				if (dependsOn[k] !== this.state.dependsOn) return true
				return false
			})
		} else {
			changed = true
		}
		changed && this.getOptions()
		// this.setState({
		//     field: props.field || false,
		//     options: props.field.options && [],  // processed options
		//     isSelect: false,  // bool indicating if type is select
		//     fieldType: (props.field && props.field.type) || 'text', // field.type
		//    // formValues: props.resetFilter ? {} : props.formValues, // dict of field name and field value passed from the parent.
		//     dependsOn: (changed && props.dependsOn) || this.state.dependsOn,
		//     forceDisable: props.forceDisable, // override field level disable false to true
		//     forceEnable: props.forceEnable, // overrided field level disable true to false
		//     defDisable: props.defDisable // the default option if not specified at field level
		// })
	}
	async getOptions() {
		let api = this.state.field && this.state.field.api
		if (!api) return
		const requestOptions = {
			method: `${api.method || 'GET'}`,
			headers: { 'Content-Type': 'application/json' },
			url: `${this.props.apiUrl || (this.props.tenant && this.props.tenant.apiUrl)}/${api.path}`,
		}
		if (api.params && api.params.length) {
			requestOptions.url += '&$filter='
			api.params.forEach(p => {
				requestOptions.url += `${p} eq ${this.state.dependsOn[p]}`
			})
		}
		if (api.body && api.body.length) {
			requestOptions.json = true
			requestOptions.body = {}
			api.body.forEach(p => {
				requestOptions.body[p] = this.state.dependsOn[p]
			})
		}
		let response = await identityServices.identityApi.request(requestOptions)
		if (api.response) {
			let coll
			if (api.response.ref) {
				let refs = api.response.ref.split('.')
				coll = refs.reduce((p, c) => {
					return p[c]
				}, response)
				// coll = response[api.response.ref]
			} else coll = response

			if (api.response.labelKey || api.response.valueKey) {
				coll = coll.map(o => {
					return { label: o[api.response.labelKey] || o, value: o[api.response.valueKey] || o }
				})
			}
			this.setState({ options: coll })
		}
		return response.data
	}

	handleChange(event) {
		const { formValues } = this.state
		if (event) {
			this.setState({
				formValues: {
					...formValues,
					[event.target.name]: event.target.value,
				},
				resetValue: false,
			})
			this.props.handleChange(event.target.name, event.target.value)
		}
	}

	handleChangeSelect(field, event) {
		const { formValues } = this.state
		if (event) {
			this.setState({
				formValues: {
					...formValues,
					[field.name]: event.value,
				},
				resetValue: false,
			})
			if (event && field.isMulti) {
				let value = event && event.map(o => o.value)
				this.props.handleChange(field.name, value)
			} else if (event) {
				this.props.handleChange(field.name, event.value || event.label)
			}
		}
	}
	handleKeyPress(target) {
		if (target.charCode === 13) {
			this.props.handleSearch()
		}
	}

	textField(field) {
		const { formValues, t } = this.props
		const { forceDisable, forceEnable, defDisable } = this.state
		let label = field.label
		if (label) label = t(label)
		let htmlProps = {
			label: label,
			name: field.name,
			value: (formValues && formValues[field.name]) || '',
			onChange: this.handleChange.bind(this),
			onKeyPress: this.handleKeyPress.bind(this),
			min: field.min,
			max: field.max,
			type: field.type,
			pattern: field.regexPattern,
			width: field.width,
			disabled: forceDisable ? true : forceEnable ? false : field.disabled != undefined ? field.disabled : defDisable,
		}
		return (
			<span>
				<Label>{htmlProps.label}:</Label>
				<Input {...htmlProps} />
			</span>
		)
	}

	selectField(field) {
		const { formValues, t, filters } = this.props
		const { options } = this.state
		let label = field.label
		if (label) label = t(label)
		const fieldFilter = filters && filters[field.name]
		let { allowedOptions } = this.state
		if (field.dynamicOptions && fieldFilter)
			allowedOptions = options && options.pop && options.filter(o => (fieldFilter && fieldFilter.includes(o.value || o.label)) || !fieldFilter)
		if (allowedOptions && allowedOptions.length === 1 && !this.state.allowedOptions) {
			this.setState({
				formValues: {
					...formValues,
					[field.name]: allowedOptions[0].value || allowedOptions[0].label,
				},
				allowedOptions,
				resetValue: false,
			})
			this.props.handleChange(field.name, allowedOptions[0].value || allowedOptions[0].label)
		}
		// const { apiOptions } = this.props;
		let htmlProps = {
			label: label,
			name: field.name,
			onChange: this.handleChangeSelect.bind(this, field),
			options: allowedOptions || options,
			width: field.width,
			value:
				field.isMulti && formValues && Array.isArray(formValues[field.name])
					? options.filter(o => formValues[field.name].indexOf(o.value) !== -1)
					: options.filter(o => formValues && formValues[field.name] === o.value),
			isMulti: field.isMulti,
		}
		return (
			((allowedOptions && allowedOptions.length > 1) || (!allowedOptions && options && options.length > 0)) && (
				<>
					<Label>{htmlProps.label}:</Label>
					<Select {...htmlProps} />
				</>
			)
		)
	}

	render() {
		const { field, fieldType } = this.state
		return (
			<>
				{fieldType && (fieldType === 'text' || fieldType === 'label') && this.textField(field)}
				{fieldType && fieldType === 'select' && this.selectField(field)}
			</>
		)
	}
}
export default withTranslation()(SearchTextField)
