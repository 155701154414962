import Joi from 'joi-browser'
import isEqual from 'lodash.isequal'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import Pagination from 'react-js-pagination'
//Button, Modal, ModalBody, Form, FormGroup,Label,Input,
// import {
//     Table
// } from "reactstrap";
import { Loader } from '../../..'
// import CardLayout from './Card'
import { localTest } from '../../../../../config'
import { history } from '../../../../../redux/history'
import SectionBoard from '../../../analytics/components/SectionBoard'
import { getObjByPath } from '../../../helpers/utils' // formatJson
import * as schemas from '../schemas'
// import { Link } from "react-router-dom";
import { ConfrimDialog, FullSearchComponent, SectionDetail } from './'
import MainLayout from './grid/MainGrid'
var d3 = require('d3-dsv')

/**
 * This Is the root component for Entity
 *  Component Hierarchy:
 *      Entity ( contains the table by default )
 *         Search ( included as child here )
 *         Create (route path)
 *         View ( route path)
 *         Edit ( action based if inline)
 *
 */
/**
 * fe 
 * 
 * props actions
 *  addItem , load, getAll, deleteItem, editItem
 * Props prop schema
 *  entity : { itemData, loading}
 *  identity: {profile:{tenantUid}},
 *  name,
 *  meta: {fields:{<f>:{label, min,max, type, required,placeholder, readOnly, regexPattern, width
 *  dependents, (if other fields are dependent on this field)
 *  if type is select : options [{label, value}](if static), api :{url, method, params [fieldKey], body[fieldKey], response } (if dynamic)
	}}: , actions: {<a>:{label, action, condition, classes}}, title, sections:{<s>:{fields:[], object:{ref}, component: {name, props, propFns}}}, screens:{<s>:{fields, actions, sections}}  }
 */
export class EntityComponent extends Component {
	constructor(props) {
		super(props)
		this.state = {
			newItem: {},
			editItem: {},
			modalAdd: false,
			editModal: false,
			submitted: false,
			deleteModal: false,
			itemId: '',
			entityList: null,
			itemData: null,
			filteredData: null,
			sort: [],
			limit: props.limit || 50,
			activePage: 1,
			totalCount: 0,
			propsValues: null,
		}
		// console.log(localTest)
	}

	/**
	 * one time checks on loading of component....
	 */
	componentWillMount() {
		let { profile, baseUrl, routeProps, meta, t } = this.props
		// let profile = identity ? identity.profile : null;

		let tenantId = profile && profile.tenantUid
		let data = this.checkData()
		let queryStr
		let select = null
		if (meta && meta.selectedFields) {
			select = meta.selectedFields
		} else {
			select = null
		}

		if (this.props.params && this.props.params.baseResource) {
			queryStr = { modelName: this.props.params.baseResource }
		}
		if (routeProps) {
			if (routeProps.filter) queryStr = Object.assign({}, queryStr || {}, routeProps.filter)
		}
		// @ts-ignore
		if (window.EmbeddableWidget && window.EmbeddableWidget.el) window.EmbeddableWidget.unmount()

		if (profile) {
			if (profile.roles && profile.roles.includes('ASC Admin')) {
				this.props.getAuditConfig(t)
			}

			const {
				meta: { screens },
			} = this.props
			const listScreen = screens.list
			if (listScreen && listScreen.hideList) return true
			let skip
			if (listScreen && listScreen.storeList === true) {
				let activePageData = localStorage.getItem('activePageList')
				let activePageList = activePageData && JSON.parse(activePageData)
				skip = activePageList && activePageList.skip
				this.setState({ activePage: (activePageList && activePageList.activePage) || this.state.activePage })
			}

			//this.props.getAllCount({ tenantId: profile.tenantUid, filter: queryStr })
			this.props.load({
				tenantId: profile.tenantUid,
				skip: skip ? skip : '0',
				top: this.state.limit,
				queryStr,
				baseUrl,
				filter: listScreen.filter || null,
				orderBy: listScreen.orderBy || null,
				select,
				t,
				id: this.props.params && this.props.params.id,
			})
		} else if (localTest && !data) {
			// if (profile && profile.roles && profile.roles.includes('ASC Admin'));
			// {
			//     this.props.getAuditConfig();
			// }
			const {
				meta: { screens },
			} = this.props
			const listScreen = screens.list
			if (listScreen && listScreen.hideList) return true
			let skip
			if (listScreen && listScreen.storeList === true) {
				let activePageData = localStorage.getItem('activePageList')
				let activePageList = activePageData && JSON.parse(activePageData)
				skip = activePageList && activePageList.skip
				this.setState({ activePage: (activePageList && activePageList.activePage) || this.state.activePage })
			}

			this.props.load({ tenantId: this.props.tenant && this.props.tenant.id, skip: skip ? skip : '0', top: this.state.limit, queryStr, baseUrl, select, t })
			//this.props.getAllCount({ tenantId: defaultTenantId, filter: queryStr })
		}
		this.setState({ tenantId })
	}
	async componentDidMount() {
		let that = this
		let props = this.props
		const {
			meta: { screens, apis },
		} = this.props
		const listScreen = screens.list
		if (listScreen && listScreen.apis) {
			listScreen.apis.forEach(a => {
				let api = apis[a]
				let targetProp = (api && api.response && api.response.targetProp) || 'data'
				let obj = getObjByPath({ ref: props.entityList, targetProp })
				if (api && !obj) that.executeApi({ apiMeta: api, values: this.state.filterValues, targetProp })
			})
		}
	}
	checkData() {
		let entityList = this.state.entityList
		if (entityList && Object.entries(entityList).length === 0) return true
		entityList = this.props.entityList

		if (
			entityList &&
			entityList.entityName === this.props.moduleName &&
			(entityList.itemData || entityList.filteredData) &&
			((this.props.routeProps && isEqual(entityList.searchParams, this.props.routeProps.filter)) || !this.props.routeProps)
		)
			return true
		return false
	}
	/**  componentWillReceiveProps
	 * use this if state is used for rendering and state should be updated on props change
	 * if props is directly used for rendering, this method is not required.
	 */
	componentWillReceiveProps(props) {
		let val = Joi.validate(props, schemas.propsSchema)
		if (val.error) {
			console.error(`${this.props.moduleName || (this.props.entityList ? this.props.entityList.entityName : 'Entity')} has schema error: ${val.error}`)
		} else {
			let meta = this.props.meta
			this.setState({
				itemData: this.props.entityList && this.props.entityList.itemData,
				entityList: this.props.entityList,
				orderBy: meta && meta.screens && meta.screens.list && meta.screens.list.orderBy,
				totalCount: this.props.entityList.count || (this.props.entity && this.props.entity.count),
			})
		}
		if (!this.state.entityList || (Object.entries(this.state.entityList).length === 0 && this.state.entityList.constructor === Object && this.props.entityList)) {
			this.setState({
				entityList: this.props.entityList,
				itemData: this.props.entityList.itemData,
				filteredData: this.props.entityList.filteredData,
				totalCount: this.props.entityList.count || (this.props.entity && this.props.entity.count),
			})
		}
	}

	componentDidUpdate(prevProps) {
		let { profile, userRoles, tenantConfig, workspace, baseUrl, routeProps, meta, globalSetting } = this.props
		let queryStr
		if (
			globalSetting &&
			!globalSetting.loading &&
			globalSetting.uiFeature &&
			globalSetting.uiFeature.workspaceFilters &&
			globalSetting.uiFeature.workspaceFilters.enabled &&
			globalSetting.uiFeature.workspaceFilters.enabled == 'on' &&
			workspace &&
			!workspace.loading
		) {
			console.log('prevProps::: ', prevProps)
			console.log('Updated Props:: ', this.props)

			if (workspace.selectedWs != null && prevProps.workspace.selectedWs != workspace.selectedWs) {
				if (this.props.params && this.props.params.baseResource) {
					queryStr = { modelName: this.props.params.baseResource }
				}
				if (routeProps) {
					if (routeProps.filter) queryStr = Object.assign({}, queryStr || {}, routeProps.filter)
				}

				const {
					meta: { screens },
					t,
				} = this.props
				const listScreen = screens.list
				if (listScreen && listScreen.hideList) return true

				let select = null
				if (meta && meta.selectedFields) {
					select = meta.selectedFields
				} else {
					select = null
				}
				this.props.load({
					tenantId: profile.tenantUid,
					skip: '0',
					top: this.state.limit,
					queryStr,
					baseUrl,
					filter: listScreen.filter || null,
					orderBy: listScreen.orderBy || null,
					select,
					t,
				})
			}
		}
	}
	/**
	 * regular class methods for usage in html
	 */
	toggleModaladdItem() {
		this.setState({
			modalAdd: !this.state.modalAdd,
		})
	}
	executeApi({ apiMeta, values, targetProp }) {
		this.props.executeApi({ apiMeta, values, targetProp, t: this.props.t, apiUrl: this.props.tenant && this.props.tenant.apiUrl })
	}

	// addItem(event) {
	//     event.preventDefault();
	//     let { identity } = this.props;
	//     let { profile } = identity;
	//     this.setState({ submitted: true });
	//     this.props.addNewItem(this.state.newItem, profile.tenantUid);
	//     this.setState({ modalAdd: false })
	// }

	// viewAuditLog(event) {
	//     event.preventDefault();
	//     let { identity } = this.props;
	//     let { profile } = identity;
	//     this.setState({ submitted: true });
	//     this.props.getAuditLog(profile.tenantUid);
	//     this.setState({ modalAdd: false })
	// }

	updateItem(id) {
		const {
			entityList: { itemData },
		} = this.props
		this.setState({
			editModal: !this.state.editModal,
		})

		let editItemData = itemData.filter(data => {
			return data.id === id
		})

		editItemData = editItemData[0]

		this.setState({
			editItem: {
				id: editItemData.id,
				name: editItemData.name,
				label: editItemData.label,
				status: editItemData.status,
				description: editItemData.description,
			},
		})
	}

	updateItemSave(event) {
		event.preventDefault()
		let { identity, t } = this.props
		let { profile } = identity
		this.setState({ submitted: true })
		this.props.editRecord(this.state.editItem, profile.tenantUid, t)
		this.setState({ editModal: false })
	}

	handlePageChange(pageNumber) {
		if(pageNumber===this.state.activePage) return
		const { limit, filter, tenantId, orderBy, sort } = this.state
		let {
			routeProps,
			meta: {
				screens: { list },
				selectedFields,
			},
			t,
		} = this.props
		let newSkip = limit * (pageNumber - 1)
		this.setState({ activePage: pageNumber }, () => {
			localStorage.setItem('activePageList', JSON.stringify({ activePage: pageNumber, skip: newSkip }))
		})
		let queryStr = {}
		if (routeProps) {
			if (routeProps.filter) queryStr = Object.assign({}, queryStr, routeProps.filter)
		}
		let currFilter
		if (filter && !Array.isArray(filter) &&  Object.keys(filter).length > 0) currFilter = Object.assign({}, filter, queryStr)
		else if (filter && Array.isArray(filter) && filter.length > 0) currFilter = filter
		else currFilter = queryStr

		let sortOrder = null
		if (sort && Array.isArray(sort) && sort.length > 0) sortOrder = sort
		else if (orderBy && Array.isArray(orderBy) && orderBy.length > 0) sortOrder = orderBy

		if (currFilter && Array.isArray(currFilter) && currFilter.length > 0 && list.filter && Array.isArray(list.filter) && list.filter.length > 0)
			currFilter = [...currFilter, ...list.filter]

		this.props.getAll({
			skip: newSkip,
			top: limit,
			filter: Object.entries(currFilter).length === 0 ? list.filter : currFilter,
			tenantId,
			orderBy: sortOrder,
			select: selectedFields,
			t,
			id: this.props.params.id,
		})
	}

	/**
	 * Call the server to list records in table as per the specified search inputs
	 * @param {*} searchParams
	 */
	handleSearch(searchParams) {
		let { t } = this.props
		const { limit, orderBy } = this.state
		// let keys = searchParams && Object.keys(searchParams)
		let meta = this.props.meta
		const {
			screens: { list },
			selectedFields,
		} = meta
		let tenantUid = this.props.identity && this.props.identity.profile && this.props.identity.profile.tenantUid
		this.setState({ activePage: 1, filter: searchParams, orderBy: meta && meta.screens && meta.screens.list && meta.screens.list.orderBy })
		// this.setState({ loading: true })
		let filter = searchParams || {}
		searchParams &&
			!searchParams.pop &&
			Object.keys(searchParams).forEach(k => {
				if (meta.fields[k].type === 'text') filter[k] = `%${searchParams[k]}%`
				else filter[k] = searchParams[k]
			})
		if (filter && Array.isArray(filter) && filter.length > 0 && list.filter && Array.isArray(list.filter) && list.filter.length > 0) filter = [...filter, ...list.filter]
		if (searchParams || (this.props.routeProps && this.props.routeProps.filter)) {
			this.props.getAll({
				tenantId: tenantUid,
				filter: filter && filter.length > 0 ? filter : list.filter,
				orderBy: orderBy || (meta && meta.screens && meta.screens.list && meta.screens.list.orderBy),
				skip: '0',
				top: limit,
				select: selectedFields,
				t,
				id: this.props.params.id,
			})
		} else {
			this.props.getAll({
				tenantId: tenantUid,
				orderBy: orderBy || (meta && meta.screens && meta.screens.list && meta.screens.list.orderBy),
				skip: '0',
				top: limit,
				filter: list.filter,
				select: selectedFields,
				t,
				id: this.props.params.id,
			})
		}
		//if (profile) this.props.getAllCount({ tenantId: profile.tenantUid, filter: filter, orderBy: this.state.orderBy })
	}

	/**
	 * Call the server to list records in table as per the specified search inputs
	 * @param {*} sortOrder
	 */
	handleSort(sortOrder) {
		let { t } = this.props
		const { limit, filter } = this.state
		let meta = this.props.meta
		const {
			screens: { list },
			selectedFields,
		} = meta

		let tenantUid = this.props.identity && this.props.identity.profile && this.props.identity.profile.tenantUid
		this.setState({ orderBy: sortOrder, sort: sortOrder, activePage: 1 })

		let currFilter = null
		if (filter && Array.isArray(filter) && filter.length > 0) currFilter = filter
		else if (this.props.routeProps && this.props.routeProps.filter) currFilter = this.props.routeProps.filter

		if (currFilter && Array.isArray(currFilter) && currFilter.length > 0 && list.filter && Array.isArray(list.filter) && list.filter.length > 0)
			currFilter = [...currFilter, ...list.filter]
		else currFilter = list && list.filter ? list.filter : null

		if ((sortOrder && sortOrder.pop && sortOrder.length > 0) || (this.props.routeProps && this.props.routeProps.filter)) {
			this.props.getAll({ tenantId: tenantUid, filter: currFilter, orderBy: sortOrder, skip: '0', top: limit, select: selectedFields, t, id: this.props.params.id })
		} else {
			this.props.getAll({
				tenantId: tenantUid,
				orderBy: meta && meta.screens && meta.screens.list && meta.screens.list.orderBy,
				skip: '0',
				top: limit,
				select: selectedFields,
				filter: list.filter || null,
				t,
				id: this.props.params.id,
			})
		}
		// if (profile) this.props.getAllCount({ tenantId: profile.tenantUid, filter: filter, orderBy: sortOrder })
	}

	handleAction(searchParams, actionMeta) {
		if (!searchParams) return
		let actionFn = actionMeta.action
		if (!this.props[actionFn]) console.error(`Action Fn ${actionFn} is not found.`)
		else {
			let keys = searchParams && Object.keys(searchParams)
			let meta = this.props.meta
			let tenantUid = this.props.identity && this.props.identity.profile && this.props.identity.profile.tenantUid
			this.setState({ filter: searchParams, orderBy: meta && meta.screens && meta.screens.list && meta.screens.list.orderBy })
			if (searchParams && keys) {
				this.props[actionFn]({ tenantId: tenantUid, filter: searchParams, orderBy: meta && meta.screens && meta.screens.list && meta.screens.list.orderBy })
			} else {
				this.props[actionFn]({ tenantId: tenantUid, orderBy: meta && meta.screens && meta.screens.list && meta.screens.list.orderBy })
			}
		}
	}
	handleStateReset(stateName) {
		this.setState({ [stateName]: null })
	}

	handleListAction(actionMeta, entity) {
		if (!entity) return
		let actionFn = actionMeta.action
		if (!this.props[actionFn]) console.error(`Action Fn1 ${actionFn} is not found.`)
		else {
			//let modelData = {}
			const { modelData } = this.state
			if (actionMeta.confirmAction && !actionMeta.callApiAction) {
				this.setState({
					modelData: {
						...modelData,
						openModal: true,
						confirmActionMessage: actionMeta.confirmActionMessage,
						actionMeta: actionMeta,
					},
					entity: entity,
				})
			} else {
				delete actionMeta.callApiAction
				let tenantUid = this.props.identity && this.props.identity.profile && this.props.identity.profile.tenantUid
				if (!entity.tenantId) entity.tenantId = tenantUid
				this.props[actionFn]({ entityValues: entity, id: entity.id })
			}
		}
	}

	/**
	 * Filter the records in ui?/server? based on the search string in simple search box: ui for now
	 * @param {*} value
	 */
	handleTextSearch(value) {
		let { profile, baseUrl, routeProps, meta, t } = this.props
		let queryStr
		let select = null
		if (meta && meta.selectedFields) {
			select = meta.selectedFields
		} else {
			select = null
		}

		if (this.props.params && this.props.params.baseResource) {
			queryStr = { modelName: this.props.params.baseResource }
		}
		if (routeProps) {
			if (routeProps.filter) queryStr = Object.assign({}, queryStr || {}, routeProps.filter)
		}
		const {
			meta: { screens },
		} = this.props
		const listScreenSearch =
			screens.search &&
			screens.search.items &&
			screens.search.items.filter(field => {
				if (field.type === 'link') return field
			})
		const listScreen = screens.list
		if (listScreenSearch) {
			this.props.load({
				tenantId: profile.tenantUid,
				skip: '0',
				top: this.state.limit,
				queryStr,
				baseUrl,
				filter: listScreen.filter || null,
				orderBy: listScreen.orderBy || null,
				select,
				searchText: value,
				searchName: listScreenSearch[0] && listScreenSearch[0].name,
				t,
			})
			this.setState({ searchText: value })
			return true
		}
		const itemData = this.props.entityList && this.props.entityList.itemData

		if (!value || value === '') {
			this.setState({ filteredData: null })
			this.props.filterData({ filteredData: null, searchText: '' })
			return
		}
		this.setState({ loading: true, searching: true })

		let filteredData =
			itemData &&
			itemData.length &&
			itemData.filter(r => {
				if (r) {
					return Object.values(r).some(c => {
						if (!c) return false
						if (typeof c === 'string') return c.toLowerCase().includes(value.toLowerCase())
						if (c.pop) return c.find(x => x.toString().toLowerCase() === value.toLowerCase())
						if (typeof c === 'object') return Object.keys(c).find(x => x.toLowerCase() === value.toLowerCase()) || Object.values(c).find(x => x === value)
						return c === value
					})
				}
				return false
			})
		this.setState({ filteredData: filteredData })
		this.props.filterData({ filteredData, searchText: value })
	}

	handleChange(event) {
		const { name, value } = event.target
		const { newItem } = this.state
		this.setState({
			newItem: {
				...newItem,
				[name]: value,
			},
			clear: false,
		})
	}

	handleChangeEdit(event) {
		const { name, value } = event.target
		const { editItem } = this.state
		this.setState({
			editItem: {
				...editItem,
				[name]: value,
			},
			clear: false,
		})
	}

	confirmDelete() {
		let { identity } = this.props
		let { profile } = identity
		this.props.deleteItem(this.state.itemId, profile.tenantUid)
		this.setState({
			deleteModal: !this.state.deleteModal,
		})
	}

	toggleDeleteModal() {
		this.setState({
			deleteModal: !this.state.deleteModal,
		})
	}

	toggleModaleditItem() {
		this.setState({
			editModal: !this.state.editModal,
		})
	}

	setItemId(id) {
		this.setState({
			itemId: id,
			deleteModal: !this.state.deleteModal,
		})
	}

	getItem(id) {
		// console.log('hi')
	}

	itemClick(id, rowData) {
		let idKey
		if (typeof id === 'object') {
			idKey = ''
			idKey = Object.values(id).join('__')
		} else idKey = id
		let url = history.location.pathname + '/update/'
		if (this.props.meta.entityUrlPaths.updateRedirectPath) {
			url = this.props.meta.entityUrlPaths.updateRedirectPath
			if (!idKey) idKey = this.props.params && this.props.params.id
		}
		history.push({
			pathname: url + idKey,
			state: { rowData },
		})
		// history.push(url + idKey)
	}

	addItem() {
		let url = history.location.pathname + '/create'
		if (this.props.meta.entityUrlPaths.createRedirectPath) {
			url = `${this.props.meta.entityUrlPaths.createRedirectPath}/create/${this.props.params && this.props.params.id}`
		}
		history.push({
			pathname: url,
			state: { processFlow: this.props.location.state },
		})
	}
	redirectItem(redirectPath) {
		console.log('history.location', history.location)
		history.push(`/${redirectPath}`)
	}

	viewAuditLog() {
		history.push(history.location.pathname + '/auditLogList')
	}

	handleTabChange() {
		this.setState({
			propsValues: null,
		})
	}

	renderItem(item) {
		if (!item) return
		const { sections } = this.props.meta
		const {
			entityList: { data },
			t,
		} = this.props
		let title = this.props.meta && this.props.meta.screens && this.props.meta.screens.list && this.props.meta.screens.list.title
		title = t(title) || title
		const searchParams = this.props.entityList && this.props.entityList.searchParams
		let itemDef
		switch (item.type) {
			case 'section':
				itemDef = sections[item.name]
				if (itemDef && this.props.meta.screens.list.type)
					return (
						<div className="row" key={item.name}>
							<SectionDetail
								meta={this.state.meta}
								title={title}
								data={data}
								moduleName={this.props.moduleName}
								key={item.name}
								name={item.name}
								filterValues={searchParams}
								{...this.props}
								handleChange={this.handleChange.bind(this)}
								handleAction={this.handleAction.bind(this)}
								itemClick={this.itemClick.bind(this)}
								importData={this.importData.bind(this)}
								viewAuditLog={this.viewAuditLog.bind(this)}
								handleSearch={this.handleSearch.bind(this)}
								preFilters={this.props.routeProps && this.props.routeProps.filter}
								handleSort={this.handleSort.bind(this)}
								handleListAction={this.handleListAction.bind(this)}
								handleExportData={this.handleExportData.bind(this)}
								handleTabChange={this.handleTabChange.bind(this)}
								propsValues={this.state.propsValues}
							/>
						</div>
					)
				else if (itemDef)
					return (
						<div className="row" key={item.name}>
							<SectionBoard
								meta={this.state.meta}
								title={title}
								data={data}
								moduleName={this.props.moduleName}
								key={item.name}
								name={item.name}
								filterValues={searchParams}
								{...this.props}
								handleChange={this.handleChange.bind(this)}
								handleAction={this.handleAction.bind(this)}
							/>
						</div>
					)
				break
			default:
		}
	}
	importData() {
		history.push(history.location.pathname + '/importData')
	}

	handleExportData(data) {
		const { moduleName } = this.props
		let tsvData = d3.tsvFormat(data)
		let filename = (moduleName && moduleName + '_template.tsv') || 'template.tsv'

		if (!tsvData.match(/^data:text\/tsv/i)) {
			tsvData = 'data:text/tab-seprated-value;charset=utf-8,' + tsvData
		}
		let filePath = encodeURI(tsvData)

		let link = document.createElement('a')
		link.setAttribute('href', filePath)
		link.setAttribute('download', filename)
		link.click()
	}

	/**
	 * main render method: called whenever state changes or dependent vars change
	 */
	render() {
		const { modelData } = this.state
		const sideBarMinimized = this.props.meta && this.props.meta.sideBarMinimized
		if (sideBarMinimized) document.body.classList.remove('sidebar-lg-show')
		else document.body.classList.add('sidebar-lg-show')
		const { alert } = this.props
		let entityList

		// const { entityList } = this.state;

		entityList = this.props.entityList
		const { meta, t } = this.props
		// const {  entity:{itemData }, name } = this.props;
		// const meta = this.props.entity? this.props.entity.meta : undefined
		let itemData = entityList && entityList.itemData
		const loading = entityList && entityList.loading
		const filteredData = this.state.filteredData || (this.props.entityList && this.props.entityList.filteredData)
		const searchText = this.state.searchText || (this.props.entityList && this.props.entityList.searchText)
		const searchParams = this.props.entityList && this.props.entityList.searchParams

		if (filteredData) itemData = filteredData
		const { entity } = this.props
		let auditConfig = entity && entity.auditConfig
		let enableAuditLogAction = auditConfig ? true : false
		let createAction
		// let viewAction
		// let editAction
		// let deleteAction;
		let auditLogAction
		// let importAction
		let redirectAction
		if (enableAuditLogAction) {
			auditLogAction = {
				label: 'View Audit Log',
				name: 'auditLog',
				type: 'button',
				action: 'getAuditLog',
			}
		}
		if (meta && meta.actions) {
			createAction = meta.actions.create
			// viewAction = meta.actions.View
			// editAction = meta.actions.Update
			// deleteAction = meta.actions.Delete
			// importAction = meta.actions.import
			redirectAction = meta.actions.redirect
		}
		// const { globalSetting: { globalSettingData } } = this.props;
		const listScreen = meta.screens && meta.screens.list
		let itemsByCols
		if (listScreen && listScreen.renderItems) {
			itemsByCols = {}
			listScreen.cols &&
				listScreen.renderItems.forEach(i => {
					if (i.col) {
						itemsByCols[i.col] = itemsByCols[i.col] || []
						itemsByCols[i.col].push(i)
					} else {
						itemsByCols.unassigned = itemsByCols.unassigned || []
						itemsByCols.unassigned.push(i)
					}
				})
			// @ts-ignore
			itemsByCols.unassigned = itemsByCols.unassigned || (listScreen && !listScreen.cols && listScreen.renderItems)
			// @ts-ignore
			if (listScreen.cols && itemsByCols.unassigned) return <div>{`Error: Some elements are not assigned to columns ${itemsByCols.unassigned} `}</div>
		}

		let metaTitle = meta.title
		if (window.location.pathname.toLowerCase().includes('/processflow/overriddenstates')) {
			const productSubcribed = window.localStorage.getItem("productSubcribed")
			const productSubcribedParse = productSubcribed ? JSON.parse(productSubcribed) : "";
			if (productSubcribedParse && productSubcribedParse.length > 1) {
				const product = window.localStorage.getItem("product")
				const getProductLabel = productSubcribedParse.find(ps => ps.value.toLowerCase() === product)
				metaTitle += ` - (${getProductLabel.label})`
			}
		}

		const headingContent = meta ? (t ? t(metaTitle) : metaTitle) : t ? t('Meta missing') : t('Meta missing')

		return (
			<div>
				{loading && <Loader />}

				<div className="common-heading row">
					{alert.message && <div className={`alert ${alert.type}`}>{t ? t(alert.message) : alert.message}</div>}
					<div className="col-sm-6">
						<div className="heading">
							<h5>{headingContent}</h5>
						</div>
					</div>
				</div>

				<div className="main-section">
					<div className="main-inner">
						<div className="row table-title">
							<div className="col-sm-8">
								<h5>{listScreen && listScreen.title && t(listScreen.title || 'Listing')}</h5>
							</div>
							<div className="col-sm-4">
								<div className="search-section">
									{meta && meta.screens && meta.screens.search && (
										<FullSearchComponent
											meta={this.props.meta}
											handleSearch={this.handleSearch.bind(this)}
											handleTextSearch={this.handleTextSearch.bind(this)}
											searchText={searchText}
											searchForm={searchParams}
											handleAction={this.handleAction.bind(this)}
											filters={this.props.routeProps && this.props.routeProps.filter}
										/>
									)}
								</div>
							</div>
						</div>
						<div className="row">
							{createAction && (
								<div className="col-sm-6">
									<div className="show-section">
										<h4 className="add">
											<span className="add-ico" onClick={this.addItem.bind(this)}>
												<i className="fa fa-plus-square-o" aria-hidden="true"></i> {t ? t(createAction.label) : createAction.label}
											</span>
										</h4>
									</div>
								</div>
							)}
							{redirectAction && (
								<div className="col-sm-6">
									<div className="show-section">
										<h4 className="add">
											{' '}
											<span className="add-ico" onClick={this.redirectItem.bind(this, redirectAction.redirectPath)}>
												<img src={`assets/img/add-q.png`} alt="Add" /> {t ? t(redirectAction.label) : redirectAction.label}
											</span>
										</h4>
									</div>
								</div>
							)}
							{/* {
                        auditLogAction &&
                            <div className="col-sm-6">
                                <div className="show-section">
                                    <span className="add-ico" onClick={this.viewAuditLog.bind(this)}><img src={"https://nonprodcdn.blob.core.windows.net/cdn/add-q.png"} alt="AuditLog" /></span>
                                    <h4 className="add">{auditLogAction.label}</h4>
                                </div>
                            </div>
                    } */}
						</div>
						{/* 

                    {
                        importAction &&
                        <div className="row">
                            <div className='col-md-6 pull-right'>
                                <button className="btn  pull-right" onClick={this.importData.bind(this)}>{t('Import')}</button>
                            </div>
                        </div>
                    } */}
						{/* <div className="row">
                        <div className="col-sm-12">
                            <Table className="custom-table table-responsive ">
                                <thead >
                                    <tr>
                                        <th key="0" width="10%">SR #</th>
                                        {
                                            meta && meta.screens && meta.screens.list && meta.screens.list.items && meta.screens.list.items.map((f, i, a) => {

                                                if ((f.type === 'field' || f.type === 'link') && meta.fields[f.name]) return (
                                                    <th key={i + 1} width={meta.fields[f.name].width}> {meta.fields[f.name].label} </th>
                                                )
                                                else if (f.type === 'field') return (<th key={i + 1}>Error: Could not find field meta</th>)
                                                else if (f.type === 'actions') return <th key={i + 1}>Actions</th>
                                                else return <span key={i+1}></span>
                                            })
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {itemData && itemData.length > 0 && itemData.map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                {
                                                    meta.screens && meta.screens.list && meta.screens.list.items && meta.screens.list.items.map((f, i) => {
                                                        if (f.type === 'field') {
                                                            if (!item[f.name]) return <td key={i}>--</td>
                                                            let itemValue;
                                                            if (item[f.name].pop)
                                                                itemValue = item[f.name].join(', ');
                                                            else if (typeof item[f.name] === 'object')
                                                                itemValue = formatJson(item[f.name], 0, `\n`);
                                                            else
                                                                itemValue = item[f.name]
                                                            return (<td key={i}>{typeof itemValue === 'string' && itemValue.split('\n').map((p, i) => <p key={i}>{p.split('\t').map(t => <> {t} &#32; </>)}</p>)} {typeof itemValue !== 'string' && itemValue}</td>)
                                                        }
                                                        else if (f.type === 'link')
                                                            return (<td key={i}>  <button className="btn btn-link" onClick={this.itemClick.bind(this, idKey && item[idKey] || (index != undefined ? index : null))}> {item[f.name]} </button></td>)
                                                        else if (f.type === 'actions') {
                                                            return (<td key={i}>
                                                                {f.actions.map((a, i, arr) => {
                                                                    let aDef = meta.actions[a]
                                                                    if (aDef && aDef.type === 'span') {
                                                                        return (<span key={i} className="al-icon-col" onClick={this[aDef.action].bind(this, item.id)}>
                                                                            {aDef.classes && <i className={aDef.classes}></i>
                                                                            }
                                                                        </span>)
                                                                    }
                                                                    else if (aDef && aDef.type === 'button') {
                                                                        return (<button key={a} className="min-btn" onClick={this[aDef.action].bind(this, item.id)}>{aDef.label || a}</button>)
                                                                    }
                                                                    else if (aDef && aDef.type === 'link') {
                                                                        return <button key={a} className="btn btn-link" onClick={this[aDef.action].bind(this, item.id)}>{aDef.label || a}</button>
                                                                    }
                                                                    else return <span key={a}></span>
                                                                })
                                                                } </td>)
                                                        } else return <span key={i}></span>
                                                    })
                                                }



                                            </tr>
                                        )
                                    })
                                    }
                                    {itemData && itemData.length == 0 &&
                                        <tr><td colSpan={meta && meta.screens && meta.screens.list && meta.screens.list.items && meta.screens.list.items.length + 1}><div className="no-content">No Record to Display</div></td></tr>
                                    }
                                </tbody>
                            </Table>

                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="pagination-control pull-right clearfix">
                                        {itemData && !loading && itemData.length > 0 &&
                                            <Pagination
                                                activePage={this.state.activePage}
                                                totalItemsCount={this.state.totalCount}
                                                onChange={this.handlePageChange.bind(this)}
                                                itemsCountPerPage={this.state.limit}
                                            />
                                        }


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
						{itemsByCols && listScreen && (
							<div className={`row ${listScreen.classes}`}>
								{listScreen.cols &&
									Array.isArray(listScreen.cols) &&
									listScreen.cols.map((c, i) => {
										return (
											<div className="col-sm-{c}" key={i}>
												{itemsByCols[i + 1] &&
													itemsByCols[i + 1].map(item => {
														return this.renderItem(item)
													})}
											</div>
										)
									})}
								{itemsByCols &&
									// @ts-ignore
									itemsByCols.unassigned &&
									// @ts-ignore
									itemsByCols.unassigned.map(item => {
										return this.renderItem(item)
									})}
							</div>
						)}
						<div className="row">
							<div className="col-sm-12">
								{meta.screens && meta.screens.list && !meta.screens.list.hideList && meta.screens.list.items && (
									<div>
										<MainLayout
											data={itemData}
											columns={meta.screens.list.items}
											fields={meta.fields}
											meta={meta}
											totalCount={this.state.filteredData ? this.state.filteredData.count : (isNaN(this.props.entityList.count) ? (this.props.entity && this.props.entity.count) || this.state.totalCount : this.props.entityList.count)}
											itemClick={this.itemClick.bind(this)}
											importData={this.importData.bind(this)}
											viewAuditLog={this.viewAuditLog.bind(this)}
											handleSearch={this.handleSearch.bind(this)}
											preFilters={this.props.routeProps && this.props.routeProps.filter}
											handleSort={this.handleSort.bind(this)}
											handleListAction={this.handleListAction.bind(this)}
											handleExportData={this.handleExportData.bind(this)}
											auditLogAction={auditLogAction}
											{...this.props}
										/>
										{meta.screens &&
											meta.screens.list &&
											(!meta.screens.list.layoutType ||
												!meta.screens.list.layoutType.excluded ||
												(!meta.screens.list.layoutType.excluded.all && meta.screens.list.layoutType.excluded.paging)) && (
												<div className="row">
													<div className="col-sm-12">
														<div className="pagination-control pull-right clearfix">
															{itemData && !loading && itemData.length > 0 && (
																<Pagination
																	activePage={this.state.activePage}
																	totalItemsCount={parseInt(
																		this.state.filteredData ? this.state.filteredData.count : this.state.totalCount || this.props.entityList.count || (this.props.entity && this.props.entity.count && this.props.entity.count)
																	)}
																	onChange={this.handlePageChange.bind(this)}
																	itemsCountPerPage={this.state.limit}
																/>
															)}
														</div>
													</div>
												</div>
											)}
									</div>
								)}
							</div>
						</div>
					</div>
					{modelData && (
						<ConfrimDialog
							openModal={modelData.openModal}
							message={modelData.confirmActionMessage}
							actionMeta={modelData.actionMeta}
							actionObj={modelData.actionObj}
							actionPayload={modelData.actionPayload}
							entity={this.state.entity}
							handleStateReset={this.handleStateReset.bind(this)}
							handleAction={this.handleAction.bind(this)}
							handleListAction={this.handleListAction.bind(this)}></ConfrimDialog>
					)}
				</div>
			</div>
		)
	}
}

export default withTranslation()(EntityComponent)
