import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { Button, CustomInput, FormGroup, Input } from 'reactstrap'
class NotesControl extends Component {
	constructor(props) {
		super(props)
		this.state = {
			allProps: props,
			noteData: {
				tenantId: '',
				note: '',
				isInternal: false,

				status: 'Published',
				createdBy: '',
			},
			isNotify: false,
			noteButtonText: 'Send Response',
		}
	}
	checkUserRole() {
		const { userRoles } = this.props
		let { notifyRoles } = this.props
		notifyRoles = notifyRoles || ['User Admin', 'Support User']
		let roles = userRoles && userRoles.length > 0 && userRoles.filter(role => notifyRoles.includes(role.name)) //
		if (roles && roles.length > 0) {
			this.setState(state => ({
				isNotify: true,
			}))
		} else {
			this.setState(state => ({
				isNotify: false,
				noteButtonText: this.state.noteButtonText,
			}))
		}
	}
	componentWillMount() {
		// const { identity: { profile } } = this.props;
		const { userName } = this.props
		const { noteData } = this.state

		this.setState({
			noteData: {
				...noteData,
				createdBy: userName,

				// tenantId added by final screen that is using this component.
			},
		})
		this.checkUserRole()
	}

	handleChange(e) {
		const { noteData } = this.state
		this.setState({
			noteData: {
				...noteData,
				[e.target.name]: e.target.value,
			},
		})
	}
	handleCheckBox(e) {
		const { noteData } = this.state
		this.setState({
			noteData: {
				...noteData,
				[e.target.name]: e.target.checked,
			},
		})
		this.setState({
			noteButtonText: e.target.checked ? 'Save' : 'Send Response',
		})
	}

	handleSubmit() {
		const { noteData } = this.state
		const { entityValues, propsMap, meta, t } = this.props

		if (noteData.note && noteData.note !== '') {
			noteData[meta.idKey] = entityValues[meta.idKey] // parent reference.
			let error = this.props.validateCollectionItem({ collectionItem: noteData, collectionPropName: (propsMap && propsMap.collectionProp) || 'notes', t })
			if (!error) {
				this.props.addChildItem({ entityValues, childItem: noteData, propKey: propsMap && propsMap.collectionProp, entityIdKey: meta && meta.idKey, t })
				this.setState({
					noteData: {
						...noteData,
						note: '',
						isInternal: false,
					},
				})
			}
		}
		if (!noteData.isInternal) {
			this.props.handleAction({ actionMeta: { action: 'notifyEmail' }, actionPayload: { id: entityValues[meta.idKey] }, t })
		}
	}

	handleNotify() {
		this.props.notifyEmail(this.props.id, 'CaseValue', this.props.t) // may need to make this generic action prop. get the emailing inputs as params.
	}

	render() {
		let t = this.props.t

		const { noteData, isNotify, noteButtonText } = this.state
		return (
			<div>
				{/* <h5>Add Notes</h5> */}
				<FormGroup>
					<Input
						type="textarea"
						name="note"
						value={noteData.note || ''}
						onChange={this.handleChange.bind(this)}
						id="notes"
						rows="5"
						draggable={false}
						disabled={false}
						// {...isDisabled}
						placeholder={t('Enter Your Notes')}
					/>
				</FormGroup>
				{isNotify && (
					<FormGroup>
						<div>
							<CustomInput
								type="checkbox"
								checked={this.state.isInternal}
								onClick={this.handleCheckBox.bind(this)}
								name="isInternal"
								id="isExternal"
								label={t('Hide for Customer')}
							/>
						</div>
					</FormGroup>
				)}
				<div className="note-new1 clearfix">
					<Button type="button" onClick={this.handleSubmit.bind(this)} color="primary">
						<i className="fa fa-plus"></i> {t(noteButtonText)}{' '}
					</Button>{' '}
					{/* {isNotify && <Button type="button" onClick={this.handleNotify.bind(this)} color="danger">Notify <i className="fa fa-bell"></i></Button>} */}
				</div>
			</div>
		)
	}
}

NotesControl.propTypes = {
	emailTemplate: PropTypes.string,
	notifyRoles: PropTypes.arrayOf(PropTypes.string), // which roles require notification?
	addItemWithParams: PropTypes.func.isRequired,
	notifyEmail: PropTypes.func,
	userName: PropTypes.string, // delink profile being passed in props.
	userRoles: PropTypes.arrayOf(PropTypes.string), // delink profile being passed
}

export default withTranslation()(NotesControl)
