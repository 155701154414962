import { Chip, CircularProgress, FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Step from '@material-ui/core/Step'
import StepContent from '@material-ui/core/StepContent'
import StepLabel from '@material-ui/core/StepLabel'
import Stepper from '@material-ui/core/Stepper'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { Done as DoneIcon } from '@material-ui/icons'
import PropTypes from 'prop-types'
import React from 'react'
import { withTranslation } from 'react-i18next'
import ConfigurationForm from './ConfigurationForm'
import Finalize from './Finalize'
const styles = theme => ({
	root: {
		width: '70%',
	},
	button: {
		marginTop: theme.spacing.unit,
		marginRight: theme.spacing.unit,
	},
	actionsContainer: {
		marginBottom: theme.spacing.unit * 2,
	},
	resetContainer: {
		padding: theme.spacing.unit * 3,
	},
	chip: {
		marginLeft: theme.spacing.unit,
		fontSize: 12,
	},
})
const configTypes = {
	oidc: {
		title: 'OpenID',
		keys: [
			{
				name: 'Authorization URL',
				prop: 'authorizationUrl',
				required: true,
			},
			{
				name: 'Token URL',
				prop: 'tokenUrl',
				required: true,
			},
			{
				name: 'Client ID',
				prop: 'clientId',
				required: true,
			},
			{
				name: 'Client Secret',
				prop: 'clientSecret',
				required: true,
				type: 'password',
			},
			{
				name: 'Issuer',
				prop: 'issuer',
				required: false,
			},
			{
				name: 'Logout URL',
				prop: 'logoutUrl',
				required: false,
			},
		],
	},
	saml: {
		title: 'SAML',
		keys: [
			{
				name: 'Single Sign-On Service URL',
				prop: 'singleSignOnServiceUrl',
				required: true,
			},
			{
				name: 'Single Logout Service URL ',
				prop: 'singleLogoutServiceUrl',
				required: false,
			},
		],
	},
}

const steps = ['SSO Configuration type', 'SSO Configuration', 'Finalize']

class SsoManagementComponent extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			activeStep: 0,
			configType: 'oidc',
			data: {},
			errors: {},
		}
	}

	componentDidMount() {
		const { profile, t } = this.props
		this.props.getSsoConfig(profile.tenantId, t)
	}

	componentDidUpdate(prevProps, prevState) {
		const { ssoConfig } = this.props
		if (
			ssoConfig &&
			ssoConfig.loading !== prevProps.ssoConfig.loading &&
			!ssoConfig.error &&
			ssoConfig.data.length === 1 &&
			['saml', 'oidc'].includes(ssoConfig.data[0].alias)
		) {
			let payload = configTypes[ssoConfig.data[0].alias].keys
				.filter(value => value.type !== 'password')
				.map(value => value.prop)
				.reduce((a, e) => ((a[e] = ssoConfig.data[0].config[e]), a), {})

			this.setState({
				configType: ssoConfig.data[0].alias,
				data: payload,
				configured: ssoConfig.data[0].alias,
			})
		}
	}

	getStepContent = () => {
		const { classes, t } = this.props
		switch (this.state.activeStep) {
			case 0:
				return (
					<FormControl component="div">
						<RadioGroup aria-label="SSO Configuration" name="SSOConfig" value={this.state.configType} onChange={this.handleConfigChange}>
							{Object.keys(configTypes).map(idp => (
								<FormControlLabel
									key={`fcl-${idp}`}
									value={idp}
									control={<Radio />}
									label={
										this.state.configured && this.state.configured === idp ? (
											<Typography component={'span'}>
												{t(configTypes[idp].title)}
												<Chip className={classes.chip} icon={<DoneIcon />} label={t("configured")} color="secondary" variant="outlined" />
											</Typography>
										) : (
											<Typography component={'span'}>{configTypes[idp].title}</Typography>
										)
									}
								/>
							))}
						</RadioGroup>
					</FormControl>
				)

			case 1:
				return (
					<ConfigurationForm
						data={this.state.data}
						errors={this.state.errors}
						headers={configTypes[this.state.configType].keys}
						handleChange={this.handleChange}
					/>
				)
			case 2:
				return <Finalize data={this.state.data} headers={configTypes[this.state.configType].keys} />
			default:
				return 'Unknown step'
		}
	}

	handleChange = evt => {
		const { value, name } = evt.target
		this.setState(state => ({
			data: { ...state.data, [name]: value },
			errors: {},
		}))
	}

	handleNext = () => {
		let errors = {}
		let data = {}
		if (this.state.activeStep === 0 && this.state.configType !== this.state.configured) this.setState({ data: {} })
		if (this.state.activeStep === 1) {
			//let required = header.map(value => ({[value.prop]:value.required})).reduce((final,value) => ({...final,...value}))
			let required = configTypes[this.state.configType].keys.filter(value => value.required)
			const data = this.state.data
			required.forEach(item => {
				if (typeof data[item.prop] === 'undefined' || data[item.prop] === '') {
					errors = { ...errors, [item.prop]: `${item.name} cannot be left blank` }
				}
			})
		}

		if (this.state.activeStep === 2) {
			const { profile, t } = this.props
			if (this.state.configured && this.state.configType === this.state.configured)
				this.props.modifySsoConfig(profile.tenantId, this.state.configType, this.state.data, t)
			else this.props.addSsoConfig(profile.tenantId, { ...this.state.data, alias: this.state.configType, t })
		}

		if (Object.keys(errors).length === 0) this.setState(state => ({ activeStep: state.activeStep + 1, errors: {} }))
		else this.setState({ errors: errors })
	}

	handleBack = () => {
		this.setState(state => {
			const { profile, t } = this.props
			if (state.activeStep - 1 === 0) this.props.getSsoConfig(profile.tenantId, t)
			return {
				activeStep: state.activeStep - 1,
			}
		})
	}

	handleReset = () => {
		const { profile, t } = this.props
		this.setState({
			activeStep: 0,
			data: {},
		})
		this.props.getSsoConfig(profile.tenantId, t)
	}

	handleConfigChange = event => {
		this.setState({ configType: event.target.value })
	}

	render() {
		const { classes, ssoConfig, t } = this.props
		const { activeStep } = this.state
		return ssoConfig && ssoConfig.loading ? (
			<CircularProgress />
		) : ssoConfig && ssoConfig.data && ssoConfig.data.length < 2 ? (
			<div className={classes.root}>
				<Stepper activeStep={activeStep} orientation="vertical">
					{steps.map((label, index) => (
						<Step key={label}>
							<StepLabel>{t(label)}</StepLabel>
							<StepContent>
								{this.getStepContent(index)}
								<div className={classes.actionsContainer}>
									<div>
										<Button disabled={activeStep === 0} onClick={this.handleBack} className={classes.button}>
											{t('BACK')}
										</Button>
										<Button variant="contained" color="primary" onClick={this.handleNext} className={classes.button}>
											{activeStep === steps.length - 1 ? t('Finish') : t('NEXT')}
										</Button>
									</div>
								</div>
							</StepContent>
						</Step>
					))}
				</Stepper>
				{activeStep === steps.length && (
					<Paper square elevation={0} className={classes.resetContainer}>
						<Typography>{t('Your SSO / Identity Provider is now configured')} .. </Typography>
						<Button onClick={this.handleReset} variant="contained" color="secondary">
							{t('Reconfigure')}
						</Button>
					</Paper>
				)}
			</div>
		) : (
			<Paper square elevation={0} className={classes.resetContainer}>
				<Typography color="error">{t('Your SSO / Identity Provider configuration is misconfigured, contact your system admininstrator')}</Typography>
			</Paper>
		)
	}
}

SsoManagementComponent.propTypes = {
	classes: PropTypes.object,
}
const ssoManagementComponent = withStyles(styles)(SsoManagementComponent)
export default withTranslation()(ssoManagementComponent)
