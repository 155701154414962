import { baseConstants } from '../..'

/**
 *
 * Convert this to a base class
 */

export default class BaseReducer {
	constructor(options) {
		this.resourceName = options.resourceName
		this.type = '_' + this.resourceName
		if (options.targetTenantDomain) {
			this.targetTenantDomain = options.targetTenantDomain
			this.type += '_' + this.targetTenantDomain
		}
	}
	item(state = { itemData: [] }, action) {
		// let type = action.type.slice(0,action.type.length- this.resourceName.length -1)
		switch (action.type) {
			case baseConstants.GETALL_REQUEST + this.type:
				return {
					...state,
					loading: true,
					itemData: [],
				}
			case baseConstants.GETALL_SUCCESS + this.type:
				return {
					...state,
					loading: false,
					itemData: action.item,
				}

			case baseConstants.GETALL_FAILURE + this.type:
				return {
					...state,
					loading: false,
					// itemData: action.item
				}
			case baseConstants.GET_REQUEST + this.type:
				return {
					...state,
					loading: true,
				}
			case baseConstants.GET_SUCCESS + this.type:
				return {
					...state,
					loading: false,
					itemData: action.item,
				}
			case baseConstants.GET_FAILURE + this.type:
				return {
					...state,
					loading: false,
				}
			case baseConstants.ADDNEW_REQUEST + this.type:
				return {
					...state,
					loading: true,
				}
			case baseConstants.ADDNEW_SUCCESS + this.type:
				let retItemData = state.itemData || []
				if (Array.isArray(action.item)) {
					retItemData = retItemData.length > 0 ? [...retItemData, ...action.item] : [retItemData, ...action.item]
				} else if (retItemData.rows) {
					retItemData.rows.push(action.item)
				} else {
					retItemData.push(action.item)
				}
				return {
					...state,
					loading: false,
					itemData: retItemData,
				}
			case baseConstants.ADDNEW_FAILURE + this.type:
				return {
					...state,
					loading: false,
				}
			case baseConstants.EDIT_REQUEST + this.type:
				return {
					...state,
					loading: true,
					stateUpdated: false,
				}
			case baseConstants.EDIT_SUCCESS + this.type:
				if (action.item) {
					return {
						...state,
						itemData: action.item,
						loading: false,
						stateUpdated: true,
					}
				}
				break
			case baseConstants.EDIT_FAILURE + this.type:
				return {
					...state,
					loading: false,
				}

			case baseConstants.CHANGE_STATUS_REQUEST + this.type:
				return {
					...state,
					loading: true,
				}
			case baseConstants.CHANGE_STATUS_FAILURE + this.type:
				return {
					...state,
					loading: false,
				}
			case baseConstants.CHANGE_STATUS_SUCCESS + this.type:
				if (action.item) {
					return {
						...state,
						itemData: action.item,
						loading: false,
					}
				}
				break
			case baseConstants.DELETE_REQUEST + this.type:
				return {
					...state,
					loading: true,
				}
			case baseConstants.DELETE_SUCCESS + this.type:
				if (action.item) {
					return {
						...state,
						itemData: state.itemData.filter(item => {
							return item.id !== action.item
						}),
						loading: false,
					}
				}
				break
			case baseConstants.LOADER_FALSE + this.type:
				// if(action.item) {
				return {
					...state,
					loading: false,
				}
			//}

			default:
				return {
					...state,
				}
		}
	}
}
