import { roleConstants } from '../'

export const userRoles = (state = { loading: false }, action) => {
	switch (action.type) {
		case roleConstants.GETALL_USERS_REQUEST:
			return {
				...state,
				loading: true,
			}
		case roleConstants.GETALL_USERS_SUCCESS:
			if (action.payload) {
				return {
					...state,
					loading: false,
					users: action.payload,
				}
			} else {
				return {
					...state,
					loading: false,
				}
			}
		case roleConstants.GETALL_USERS_FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload,
			}

		case roleConstants.ADD_USERS_REQUEST:
			return {
				...state,
				modified: false,
				loading: true,
			}
		case roleConstants.ADD_USERS_SUCCESS:
			return {
				...state,
				modified: true,
				loading: false,
				payload: action.payload,
			}
		case roleConstants.ADD_USERS_FAILURE:
			return {
				...state,
				modified: false,
				loading: false,
				payload: {},
			}

		case roleConstants.MODIFY_USERS_REQUEST:
			return {
				...state,
				modified: false,
				loading: true,
			}
		case roleConstants.MODIFY_USERS_SUCCESS:
			let { user } = state
			if (action.userUpdate.attributes) {
				user.attributes = action.userUpdate.attributes
			}

			return {
				...state,
				modified: true,
				loading: false,
				user: user,
			}
		case roleConstants.MODIFY_USERS_FAILURE:
			return {
				...state,
				modified: false,
				loading: false,
				payload: {},
			}

		case roleConstants.GET_ROLES_REQUEST:
			return {
				...state,
				loading: true,
			}
		case roleConstants.GET_ROLES_SUCCESS:
			if (action.payload) {
				return {
					...state,
					loading: false,
					selectedRoles: action.payload,
				}
			} else {
				return {
					...state,
					loading: false,
				}
			}
		case roleConstants.GET_ROLES_FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload,
			}

		case roleConstants.GET_USERS_COUNT_REQUEST:
			return {
				...state,
				loading: true,
			}
		case roleConstants.GETUSERS_COUNT_SUCCESS:
			if (action.payload) {
				return {
					...state,
					loading: false,
					usersCount: action.payload,
				}
			} else {
				return {
					...state,
					loading: false,
				}
			}
		case roleConstants.GETUSERS_COUNT_FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload,
			}

		case roleConstants.PASSWORD_RESET_EMAIL_REQUEST:
			return {
				...state,
				loading: true,
			}
		case roleConstants.PASSWORD_RESET_EMAIL_SUCCESS:
			if (action) {
				return {
					...state,
					loading: false,
				}
			} else {
				return {
					...state,
					loading: false,
				}
			}
		case roleConstants.PASSWORD_RESET_EMAIL_FAILURE:
			return {
				...state,
				loading: false,
				error: action,
			}

		case roleConstants.GET_USER_REQUEST:
			return {
				...state,
				loading: true,
			}
		case roleConstants.GET_USER_SUCCESS:
			if (action.payload) {
				return {
					...state,
					loading: false,
					user: action.payload,
				}
			} else {
				return {
					...state,
					loading: false,
				}
			}
		case roleConstants.GET_USER_FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload,
			}

		case roleConstants.MODIFY_CHANGE_PASSWORD_REQUEST:
			return {
				...state,
				loading: true,
			}
		case roleConstants.MODIFY_CHANGE_PASSWORD_SUCCESS:
			return {
				...state,
				loading: false,
			}
		case roleConstants.MODIFY_CHANGE_PASSWORD_FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload,
			}

		case roleConstants.GET_ALL_USER_ATTRIBUTES_REQUEST:
			return {
				...state,
				loading: true,
			}
		case roleConstants.GET_ALL_USER_ATTRIBUTES_SUCCESS:
			return {
				...state,
				userAttributes: action.payload || [],
				loading: false,
			}
		case roleConstants.GET_ALL_USER_ATTRIBUTES_FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload,
			}

		default:
			return state
	}
}
