// import { apiUrl } from '../../../config';
import { identityServices } from '..'
import { flatternJson, getObjByPath } from './utils'

const buildFilters = ({ filtersMeta, data }) => {
	if (!filtersMeta || !filtersMeta.pop) return
	if (!data) return
	let res = filtersMeta.slice()
	return res.map((f, i) => {
		if (f.field) {
			if (f.value) {
				f.value = data[f.valueRef] || data[f.field] || data[f.value] || f.defaultValue || f.value
			} else if (f.valuesRef) {
				if (f.values) {
					f.values = f.valuesRef.map((v, i) => {
						return data[v] || (f.defaultValues && f.defaultValues[i]) || v
					})
				}
			} else if (f.values) {
				f.values = f.values.map((v, i) => {
					return data[v] || (f.defaultValues && f.defaultValues[i]) || v
				})
			}
		}
		return f
	})
}
const buildApiBody = ({ apiMeta, data }) => {
	let body = {}
	let filters = data ? buildFilters({ filtersMeta: apiMeta.filters, data }) : apiMeta.filters
	if (apiMeta.bodyOdataMap) {
		if (filters) body[apiMeta.bodyOdataMap.filters || 'filters'] = filters
		if (apiMeta.select) body[apiMeta.bodyOdataMap.select || 'select'] = apiMeta.select
		if (apiMeta.groupBy) body[apiMeta.bodyOdataMap.groupBy || 'groupBy'] = apiMeta.groupBy
		if (apiMeta.groupByAgg) body[apiMeta.bodyOdataMap.groupByAgg || 'groupByAgg'] = apiMeta.groupByAgg
		if (apiMeta.orderBy) body[apiMeta.bodyOdataMap.orderBy || 'orderBy'] = apiMeta.orderBy
		if (apiMeta.top) body[apiMeta.bodyOdataMap.top || 'top'] = apiMeta.top
	} else if (apiMeta.bodyMap && apiMeta.bodyMap.pop) {
		apiMeta.bodyMap.forEach(i => {
			body[i.key] = data[i.valueKey] || i.valueKey
		})
	} else
		body = { filters, groupBy: apiMeta.groupBy, select: apiMeta.select, orderBy: apiMeta.orderBy, groupByAgg: apiMeta.groupByAgg, top: apiMeta.top, metricType: apiMeta.metricType }
	return body
}
const oDataQuery = ({ filters, groupBy, groupByAgg, orderBy, select, top }) => {
	let query = ''
	if (filters && filters.pop) {
		query += '$filter='

		filters.forEach((f, i) => {
			if (i !== 0) query += ` ${f.andOrOr} `

			switch (f.operator) {
				case 'contains':
					query += `contains(${f.field}, '${f.value}')`
					break
				case 'does not contain':
					query += `not contains(${f.field}, '${f.value}')`
					break
				case 'is':
					query += `${f.field} eq ${f.value}`
					break
				case 'is not':
					query += `${f.field} ne ${f.value}`
					break
				case 'is empty':
					query += `${f.field} eq NULL`
					break
				case 'is not empty':
					query += `${f.field} ne NULL`
					break
				case 'eqOrEmpty':
					query += `${f.field} eq ${f.value} or (${f.field} eq NULL)`
					break
				case 'is within':
					if (f.value && f.value.pop) {
						if (f.value.length === 2) {
							if (isNaN(f.value[0]) && isNaN(Date.parse(f.value[0]))) {
								query += `${f.field} in ('${f.value.join(', ')}')`
							} else if (!isNaN(Date.parse(f.value[0]))) {
								query += `${f.field}  gt '${f.value[0]}' and ${f.field} lt '${f.value[1]}'`
							} else {
								query += `${f.field}  gt ${f.value[0]} and ${f.field} lt ${f.value[1]}`
							}
						} else {
							query += `${f.field} in ('${f.value.join(', ')}')`
						}
					} else query += `${f.field} lt '${f.value}'`
					break
				case '<':
					query += `${f.field} lt ${f.value}`
					break
				case '>':
					query += `${f.field} gt ${f.value}`
					break
				case '<=':
					query += `${f.field} le ${f.value}`
					break
				case '>=':
					query += `${f.field} ge ${f.value}`
					break
				default:
					query += '1 eq 1'
			}
		})
		query += ';'
	}
	if (groupBy && groupBy.pop) {
		query += '$apply=groupby(('
		query += `${groupBy.join(',')}`
		query += `),` // for groups
		if (groupByAgg && groupByAgg.pop) {
			query += 'aggregate('
			groupByAgg
				.map(a => {
					return `${a.field + ' with ' + a.operation}`
				})
				.join(',')
			query += ')'
		}
		query += ');'
	}
	if (orderBy && orderBy.pop) {
		query += '$orderBy='
		query += orderBy.join(',')
		query += ';'
	}
	if (select && select.pop) {
		query += '$select='
		query += select.join(',')
		query += ';'
	}
	if (top) {
		query += `$top=${top};`
	}
	return query
}
export const executeApi = ({ tenantId, values, apiMeta, scriptLib = null, apiUrl }) => {
	/**
     * 
     * api: Joi.object().keys({
    path: Joi.string(),
    method: Joi.string().valid('GET', 'POST'),
    response: Joi.object().keys({
        ref: Joi.string(),
        valueKey: Joi.string(),
        computeFn: Joi.string() 
    }).xor('valueKey', 'computeFn'),
    filters: fitlersSchema,
    groupBy: Joi.array().items(Joi.string())
}),
     */
	let filters = values ? buildFilters({ filtersMeta: apiMeta.filters, data: values }) : apiMeta.filters
	let query
	if (!apiUrl) apiUrl = sessionStorage.getItem('apiUrl')
	let requestOptions = {}
	requestOptions.method = apiMeta.method
	if (apiMeta.filters && apiMeta.method === 'GET') {
		query = oDataQuery({ filters, groupBy: apiMeta.groupBy, groupByAgg: apiMeta.groupByAgg, orderBy: apiMeta.orderBy, select: apiMeta.select, top: apiMeta.top })
	} else if (apiMeta.method === 'POST') {
		requestOptions.data = buildApiBody({ apiMeta, data: values })
		requestOptions.json = true
	}
	requestOptions.url = `${apiUrl}/${apiMeta.path}?${query || ''}`

	requestOptions.headers = { 'Content-Type': 'application/json' }
	//console.log(`metrics request: ${JSON.stringify(requestOptions)}`)

	return identityServices.identityApi
		.request(requestOptions)
		.then(response => {
			if (response) {
				let resValues
				if (apiMeta.response) {
					let res
					if (apiMeta.response.ref) {
						res = getObjByPath({ ref: response, path: apiMeta.response.ref })
					} else res = response

					if (!res) return Promise.reject(`No response obtained`)
					else {
						if (apiMeta.response.computeFn && scriptLib && scriptLib[apiMeta.response.computeFn])
							try {
								resValues = scriptLib[apiMeta.computeFn]({ apiResponse: res, data: values })
								return Promise.resolve(resValues)
							} catch (err) {
								return Promise.reject(`Error in computFn ${apiMeta.computeFn}`)
							}
						else if (apiMeta.response.computeFn) {
							if (apiMeta.response.computeFn === 'flatternJson') {
								resValues = flatternJson(response.data, apiMeta.response.colToFlat)
								return Promise.resolve(resValues)
							} else {
								return Promise.reject(`${apiMeta.response.computeFn} not available`)
							}
						} else if (apiMeta.response.valueKey) {
							return Promise.resolve(res[apiMeta.response.valueKey])
						} else {
							return Promise.reject(`${apiMeta.response.computeFn} script not available`)
						}
					}
				} else {
					//console.log(`metrics response: ${JSON.stringify(response.data)}`)
					return Promise.resolve(response.data)
					//return response.data;
				}
			}
		})
		.catch(err => {
			return Promise.reject(`Error in api call ${err.message}`)
		})
}
