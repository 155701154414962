import { AddIcon } from '@chakra-ui/icons'
import { Button, Container, HStack, Select, Stack, Tag, TagCloseButton, Text, Textarea, Tooltip, useToast, Wrap, WrapItem } from '@chakra-ui/react'
import React, { useCallback, useEffect, useState } from 'react'
import { identityServices } from '../../common'
var subdomain = window.location.host.split('.')[1] ? window.location.host.split('.')[0] : false
const Roles = props => {
	const [list, setList] = useState([])
	const [selectedRole, setSelectedRole] = useState('')
	const [tooltip, setTooltip] = useState('')
	const [roles, setRoles] = useState([])
	const toast = useToast()
	useEffect(() => {
		const getRoles = async () => {
			const requestOptions = {
				url: `${props.apiUrl || (props.tenant && props.tenant.apiUrl)}/role/tenant-roles/null?tenantId=${subdomain}`,
				method: 'GET',
			}

			let result = await identityServices.identityApi.request(requestOptions)
			let result1 = result && result.data && result.data.rows ? result.data.rows : result.data && result.data.length > 0 ? result.data : []
			return result1
		}
		let mounted = true
		getRoles().then(items => {
			if (mounted) {
				setList(items)
			}
		})
		return () => (mounted = false)
	}, [props.apiUrl, props.tenant])
	useEffect(() => {
		if (props.roles && props.roles.length > 0) {
			setRoles([...props.roles])
		}
	}, [props])

	const handleRoleChange = useCallback(
		e => {
			if (e.target.value) {
				setSelectedRole(e.target.value)
				let currentRole = list ? list.find(r => r.name === e.target.value) : null
				if (currentRole && currentRole.description) setTooltip(currentRole.description)
				else setTooltip('')
			}
		},
		[list]
	)
	const handleTooltip = e => {
		if (e.target.value) setTooltip(e.target.value)
		else setTooltip('')
	}
	const handleRoleAdd = useCallback(() => {
		if (selectedRole && tooltip) {
			let existRole = roles.find(r => r.role === selectedRole)
			if (existRole) {
				toast({
					title: 'Info',
					description: `${selectedRole}: Role is already added.`,
					status: 'info',
					duration: 3000,
					isClosable: true,
					position: 'top-right',
				})
				return true
			}
			let newRoles = [
				...roles,
				{
					role: selectedRole,
					description: tooltip,
				},
			]
			setRoles(newRoles)
			props.handleRoleUpdate(newRoles)
			setSelectedRole('')
			setTooltip('')
		}
	}, [props, roles, selectedRole, toast, tooltip])
	const handleDeleteRole = useCallback(
		role => {
			let newRoles = roles.filter(r => r.role !== role)
			setRoles(newRoles)
			props.handleRoleUpdate(newRoles)
		},
		[props, roles]
	)

	return (
		<Container backgroundColor="white" padding="0" maxWidth="100%" margin="-46px 24px" width="96%">
			<Text ml="18px">{props.t('Roles')}:</Text>
			<Stack spacing={3} padding="18px" width="44.3%" marginLeft="14.4%" marginTop="-47px">
				<Select placeholder={props.t('Select Roles')} value={selectedRole} onChange={handleRoleChange}>
					{list &&
						list.map((r, index) => {
							return (
								<option value={r.name} key={index}>
									{r.name}
								</option>
							)
						})}
				</Select>
				<Textarea placeholder={props.t('Tooltip')} size="md" value={tooltip} onChange={handleTooltip} />
				<Button leftIcon={<AddIcon />} colorScheme="blue" variant="outline" size="sm" width="130px" onClick={handleRoleAdd}>
					{props.t('Add New Role')}
				</Button>
				<HStack spacing={4} style={{marginBottom: "50px"}}>
					<Wrap>
						{roles &&
							roles.map((r, i) => (
								<WrapItem key={i}>
									<Tag size={'lg'} key={'lg'} borderRadius="full" variant="solid" colorScheme="blue">
										<Tooltip hasArrow label={r.description} bg="gray.300" color="black">
											{r.role}
										</Tooltip>
										<TagCloseButton onClick={e => handleDeleteRole(r.role)} />
									</Tag>
								</WrapItem>
							))}
					</Wrap>
				</HStack>
			</Stack>
		</Container>
	)
}

export default Roles
