// import {
// apiUrl
// } from '../../../config';
import { identityServices } from '../../common'

export const getSecret = (tenantId, apiUrl) => {
	if (tenantId === undefined || tenantId == null) Promise.reject('`Error : Missing Tenant Id ')
	if (!apiUrl) apiUrl = sessionStorage.getItem('apiUrl')
	const requestOptions = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
		},
		url: `${apiUrl}/role-management/client/secret?tenantId=${tenantId}`,
	}

	return identityServices.identityApi.request(requestOptions).then(response => {
		if (response.status !== 200) {
			return Promise.reject(`Unable to fetch the api secret : Error code : ${response.status}`)
		}

		return response.data
	})
}

export const resetSecret = (tenantId, clientId, apiUrl) => {
	if (tenantId === undefined || tenantId == null) Promise.reject('`Error : Missing Tenant Id ')
	if (clientId === undefined || clientId == null) Promise.reject('`Error : Missing Client Id ')
	if (!apiUrl) apiUrl = sessionStorage.getItem('apiUrl')
	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		data: {
			realm: tenantId,
			client: clientId,
		},
		url: `${apiUrl}/role-management/client/secret`,
	}

	return identityServices.identityApi.request(requestOptions).then(response => {
		if (response.status !== 200) {
			return Promise.reject(`Unable to reset the api secret : Error code : ${response.status}`)
		}

		return response.data
	})
}

export const getAllUsers = (tenantId, apiUrl) => {
	if (tenantId === undefined || tenantId == null) Promise.reject('`Error : Missing Tenant Id ')
	if (!apiUrl) apiUrl = sessionStorage.getItem('apiUrl')
	const requestOptions = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
		},
		url: `${apiUrl}/role-management/users?tenantId=${tenantId}`,
	}

	return identityServices.identityApi.request(requestOptions).then(response => {
		if (response.status !== 200) {
			return Promise.reject(`Unable to fetch the list of users : Error code : ${response.status}`)
		}

		return response.data
	})
}

export const addUsers = (tenanId, userPayload, apiUrl) => {
	if (tenanId === undefined || tenanId == null) Promise.reject('`Error : Missing Tenant Id ')
	if (!apiUrl) apiUrl = sessionStorage.getItem('apiUrl')
	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		url: `${apiUrl}/role-management/users?tenantId=${tenanId}`,
		data: userPayload,
	}

	return identityServices.identityApi.request(requestOptions).then(response => {
		if (response.status !== 201) {
			return Promise.reject(`Unable to add new user : Error code : ${response.status}`)
		}

		return response.data
	})
}

export const modifyUsers = (tenanId, userId, userPayload, apiUrl) => {
	if (tenanId === undefined || tenanId == null) Promise.reject('`Error : Missing Tenant Id ')
	if (!apiUrl) apiUrl = sessionStorage.getItem('apiUrl')
	const requestOptions = {
		method: 'PUT',
		headers: {
			'Content-Type': 'application/json',
		},
		url: `${apiUrl}/role/users/${userId}?tenantId=${tenanId}`,
		data: userPayload,
	}

	return identityServices.identityApi.request(requestOptions).then(response => {
		if (response.status !== 201) {
			return Promise.reject(`Unable to update the user role : Error code : ${response.status}`)
		}

		return response.data
	})
}

export const getUserRoles = (tenanId, userId, apiUrl) => {
	if (tenanId === undefined || tenanId == null) Promise.reject('`Error : Missing Tenant Id ')
	if (!apiUrl) apiUrl = sessionStorage.getItem('apiUrl')
	const requestOptions = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
		},
		url: `${apiUrl}/role-management/roles/${userId}?tenantId=${tenanId}`,
	}

	return identityServices.identityApi.request(requestOptions).then(response => {
		if (response.status !== 200) {
			return Promise.reject(`Unable to fetch the list of users : Error code : ${response.status}`)
		}

		return response.data
	})
}

export const getUsersCount = (tenanId, apiUrl) => {
	if (tenanId === undefined || tenanId == null) Promise.reject('`Error : Missing Tenant Id ')
	if (!apiUrl) apiUrl = sessionStorage.getItem('apiUrl')
	const requestOptions = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
		},
		url: `${apiUrl}/role-management/users/count?tenantId=${tenanId}`,
	}

	return identityServices.identityApi.request(requestOptions).then(response => {
		if (response.status !== 200) {
			return Promise.reject(`Unable to fetch the list of users : Error code : ${response.status}`)
		}

		return response.data
	})
}

export const registerUserRequiredActions = (tenanId, userId, body, apiUrl) => {
	if (tenanId === undefined || tenanId == null) Promise.reject('`Error : Missing Tenant Id ')
	if (!apiUrl) apiUrl = sessionStorage.getItem('apiUrl')
	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		url: `${apiUrl}/role-management/users/required-actions/${userId}?tenantId=${tenanId}`,
		data: body,
	}

	return identityServices.identityApi.request(requestOptions).then(response => {
		if (response.status !== 200) {
			return Promise.reject(`Unable to fetch the list of users : Error code : ${response.status}`)
		}

		return response.data
	})
}

export const getUserById = (tenanId, userId, apiUrl) => {
	if (tenanId === undefined || tenanId == null) Promise.reject('`Error : Missing Tenant Id ')
	if (!apiUrl) apiUrl = sessionStorage.getItem('apiUrl')
	const requestOptions = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
		},
		url: `${apiUrl}/role/users/${userId}?tenantId=${tenanId}`,
	}

	return identityServices.identityApi.request(requestOptions).then(response => {
		if (!apiUrl) apiUrl = sessionStorage.getItem('apiUrl')
		if (response.status !== 200) {
			return Promise.reject(`Unable to fetch the list of users : Error code : ${response.status}`)
		}

		return response.data
	})
}

export const changeUserPassword = (tenanId, userId, body, apiUrl) => {
	if (!apiUrl) apiUrl = sessionStorage.getItem('apiUrl')
	if (tenanId === undefined || tenanId == null) Promise.reject('`Error : Missing Tenant Id ')
	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		url: `${apiUrl}/role/users/change-password/${userId}?tenantId=${tenanId}`,
		data: body,
	}

	return identityServices.identityApi.request(requestOptions).then(response => {
		if (response.status !== 200) {
			return Promise.reject(`Unable to fetch the list of users : Error code : ${response.status}`)
		}

		return response.data
	})
}

export const getTenantRoles = (tenanId, roleName, apiUrl) => {
	if (tenanId === undefined || tenanId == null) Promise.reject('`Error : Missing Tenant Id ')
	if (!apiUrl) apiUrl = sessionStorage.getItem('apiUrl')
	const requestOptions = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
		},
		url: `${apiUrl}/role/tenant-roles/${roleName}?tenantId=${tenanId}`,
	}

	return identityServices.identityApi.request(requestOptions).then(response => {
		if (response.status !== 200) {
			return Promise.reject(`Unable to fetch the list of roles : Error code : ${response.status}`)
		}

		return response.data
	})
}

export const getAllUserAttributes = apiUrl => {
	if (!apiUrl) apiUrl = sessionStorage.getItem('apiUrl')
	const requestOptions = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
		},
		url: `${apiUrl}/UserAttribute`,
	}

	return identityServices.identityApi.request(requestOptions).then(response => {
		if (response.status !== 200) {
			return Promise.reject(`Unable to fetch the list of roles : Error code : ${response.status}`)
		}

		return response.data
	})
}
