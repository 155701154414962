import React from "react";

const SetupFinished = (props) => {
    let adminUrl = `${props.activeRegistration.tenantId}.${props.rootDomain}:${window.location.port}`
    return (
        <div className="steps final-step efl-main clearfix">
          <div className="knowledge-base-setup">
          <h4>{props.stepConfig.stepTitle}</h4>
            <div className="panel-separation clearfix">
              <div className="panel-left">
                 <h3>{props.stepConfig.stepDesc}</h3>
                <ul className="sm-question">
                    <li>{props.stepConfig.stepBullet.point1}</li>
                    <li>{props.stepConfig.stepBullet.point2}</li>
                    <li>{props.stepConfig.stepBullet.point3}</li>
                    <li>{props.stepConfig.stepBullet.point4}</li>
                    {props.stepConfig.stepBullet.point5 ? <li>
                      {props.stepConfig.stepBullet.point5}
                      </li> : null}
                    {props.stepConfig.stepBullet.point6 ? <li>
                      {props.stepConfig.stepBullet.point6}
                    </li> : null}           
                </ul>
                <div className="try-out-links" data-testid="try_out" style={{display:'none'}}>
                  <a href="#!" className="orange"><span><img src="assets/img/note.png" alt="Tryout" /></span>Click here to Try it out</a>
                </div>
              <div className="try-out-links" data-testid="go_to_management_console">
                <a href={"https://" + adminUrl} className="orange"><span><img src="assets/img/note.png" alt="Admin Console" /></span>Go to management console</a>
              </div>
              </div>
              {/* <div className="panel-right">
                <h3>You can also go to the admin console to manage the Assistant. here you can</h3>
                <ul className="sm-question">
                      <li>Manage knowledge base and add, edit questions</li>
                      <li>View analytics</li>
                      <li>Manage users and share</li>
                      <li>Review past user chats and train the system</li>
                      <li>You can always change the questions later on, once the set-up is complete</li>
                      <li>And much more</li>
                </ul>
                <div className="try-out-links">
                  <a href={"http://"+adminUrl} className="orange"><span><img src="assets/img/note.png" alt="Admin Console" /></span>Click here to go to admin console</a>
                </div>
              </div> */}
            </div>
            <div className="sp-note" data-testid="go_to_console">
              <p>You can always get back to your admin console at<a href={"https://"+adminUrl}>{"https://"+adminUrl}</a></p>
            </div>
          </div>
        </div>
    )
}


export default SetupFinished;