import React from 'react'
import ReactPlayer from 'react-player'
import { CustomListGroup } from './'

class VideoPlayer extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			videoUrl: this.props.videoUrl || null,
			playing: this.props.playing || true,
			control: this.props.control || true,
			type: this.props.type || 'video',
			light: false,
		}
	}
	// componentWillReceiveProps(props){
	//     console.log("props", props)
	//     if(props.videoUrl){
	//                 this.setState({videoUrl : props.videoUrl, type: props.type})
	//     }
	//     if(props.refUrl){
	//         this.setState({videoUrl: props.refUrl, type: props.type})
	//     }
	// }
	render() {
		let { t } = this.props
		return (
			<div className="video-frame">
				{/* <h4 className="video-title">Video Title</h4> */}
				{!this.props.videoUrl && (
					<div className="noVideo">
						<p className="video-icon">
							<i className="far fa-play-circle"></i>
						</p>
						<p>{t('Please select item from the list')}.</p>
					</div>
				)}
				{this.props.type && this.props.type.indexOf('image') !== -1 ? (
					<img src={this.props.videoUrl} alt="" />
				) : (
					this.props.videoUrl && (
						<ReactPlayer
							className="react-player"
							url={this.props.videoUrl}
							playing={this.state.playing || false}
							width="100%"
							height={'100%'}
							controls={this.state.control || false}
							light={this.state.light || false}
							pip={false}
						/>
					)
				)}
				<CustomListGroup items={this.props.otherProps} />
			</div>
		)
	}
}
export default VideoPlayer
