import React, { Component } from 'react'
// import {
//   ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText,
//   Card, CardBody, Button, CardTitle, CardText, CardImg
// } from 'reactstrap';
// import moment from 'moment';
import { withTranslation } from 'react-i18next'
// const tz = require('moment-timezone');
class NotesList extends Component {
	constructor(props) {
		super(props)
		this.state = {
			list: this.props.list,
			noteList: false,
		}
	}

	// componentWillReceiveProps(props){
	//     //console.log('!!!!!!!! component will receive props')

	// const {CaseNoteConfig}=props
	// if(CaseNoteConfig && CaseNoteConfig.itemData && CaseNoteConfig.itemData.length>0)
	//     this.setState({caseNoteList:CaseNoteConfig.itemData})
	// }

	render() {
		const { list } = this.props
		// const now = new Date()
		const t = this.props.t

		return (
			<>
				{list &&
					list.length > 0 &&
					list.pop &&
					list.map((note, index) => {
						// let olderThanDay = (now - note.createdAt ) / 3600 / 24 / 1000 > 1
						return (
							<div key={index} className="row" style={{ borderBottom: 'thin solid #eaeaea', padding: '5px 0px', marginBottom: '5px' }}>
								<div className="col-md-12">{note.note}</div>
								<div className="col-md-12">
									<p className="text-muted  pull-right" style={{ fontSize: '12px', fontStyle: 'italic' }}>
										{t('Created by')} <strong>{note.createdBy}</strong> {t('on')} <strong>{t('{{date, date}}', { date: note.createdAt })}</strong>
									</p>
								</div>
							</div>
							//   <Card>
							//   <CardBody>
							//     <CardText>{note.note}</CardText>
							//     <CardText className="text-muted">Created on <strong>{moment(note.createdAt).tz(moment.tz.guess()).format('MMM-DD-YYYY hh:mm:ss')}</strong> by  <strong>{note.createdBy}</strong></CardText>
							//   </CardBody>
							// </Card>
						)
					})}
			</>
		)
	}
}

export default withTranslation()(NotesList)
