export const CHECK_TENANT_EXISTS_REQUEST = 'CHECK_TENANT_EXISTS_REQUEST';
export const CHECK_TENANT_EXISTS_SUCCESS = 'CHECK_TENANT_EXISTS_SUCCESS';
export const CHECK_TENANT_EXISTS_FAILURE = 'CHECK_TENANT_EXISTS_FAILURE';

export const REGISTER_TENANT_REQUEST = 'REGISTER_TENANT_REQUEST';
export const REGISTER_TENANT_SUCCESS = 'REGISTER_TENANT_SUCCESS';
export const REGISTER_TENANT_FAILURE = 'REGISTER_TENANT_FAILURE';


export const TENANT_LIFECYCLE_REQUEST = 'TENANT_LIFECYCLE_REQUEST';
export const TENANT_LIFECYCLE_SUCCESS = 'TENANT_LIFECYCLE_SUCCESS';
export const TENANT_LIFECYCLE_FAILURE = 'TENANT_LIFECYCLE_FAILURE';

export const VALIDATE_OTP_REQUEST = 'VALIDATE_OTP_REQUEST';
export const VALIDATE_OTP_SUCCESS = 'VALIDATE_OTP_SUCCESS';
export const VALIDATE_OTP_FAILURE = 'VALIDATE_OTP_FAILURE';

export const GET_OTP_REQUEST = 'GET_OTP_REQUEST';
export const GET_OTP_SUCCESS = 'GET_OTP_SUCCESS';
export const GET_OTP_FAILURE = 'GET_OTP_FAILURE';