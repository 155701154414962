import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { compose } from 'redux'
// import { error } from "util";
import { identityActions } from '../'
import { localTest, realmName } from '../../../config'
import AppModal from './AppModal'
class Home extends Component {
	constructor(props) {
		super(props)

		this.state = {
			loginInitiated: false, //props.identity && props.identity.isInitiated
		}
	}
	componentWillMount() {
		let { identity, tenant, t } = this.props
		const isExpired = identity && identity.exp ? new Date((identity.exp + identity.timeSkew) * 1000) < new Date() : false
		if (isExpired) this.props.checkIdentity(t)
		if (!localTest && identity && !identity.isAuthenticated && !identity.profile && !this.state.loginInitiated && tenant.tenantId !== 'default') {
			this.setState({ loginInitiated: true })
			this.props.checkIdentity(t)
		}
		// localStorage.removeItem("routeName");
	}

	render() {
		const { tenant, identity, match, t } = this.props
		let landingPage = tenant.tenantId === 'default' ? tenant.landingPage : '/'
		if (tenant.tenantId == realmName) landingPage = tenant.landingPage

		if (identity && identity.isAuthenticated) {
			return true
		}
		const { publicApps, publicRoutes } = tenant
		if (match && match.path === '/login' && publicApps && publicApps.length === 1) {
			if (!localTest && identity && !identity.isAuthenticated && !identity.profile) this.props.checkIdentity(t)
			return <Redirect to={{ pathname: '/home' }} />
		} else if (publicApps && publicApps.length === 1 && match && match.path !== '/login') {
			const routePath = publicApps[0]
			window.location.href = `${window.location.protocol}//${window.location.hostname}/${routePath}`
		} else if (publicApps && publicApps.length > 1 && match && match.path !== '/login') {
			let routes =
				publicApps &&
				publicApps.map(x => {
					return { name: x, app: { path: x } }
				})
			return <AppModal routeList={routes} isOpen={true} placeholder="--- Choose App ---" />
		} else if (publicRoutes && publicRoutes.length > 0) {
			landingPage = publicRoutes[0]
		}
		// else if(!this.state.loginInitiated && tenant.tenantId!=='default'){
		//   this.setState({
		//     loginInitiated: true
		//   })
		//   !identity.isAuthenticated && this.props.checkIdentity();//landingPage='/';
		//   return <></>
		// }
		return <Redirect to={{ pathname: landingPage || '/error' }} />
	}
}

const mapStateToProps = state => {
	const { tenant, identity } = state
	return {
		tenant,
		identity,
	}
}
const mapDispatchToProps = dispatch => ({
	checkIdentity: t => dispatch(identityActions.checkIdentity(t)),
})
export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(Home)
