/**
 * RU on all settings including whitelisting
 * This is in common as all apps would need it
 * Setting Key value pairs come from db of the app.
 */
import { Entity } from '../common/base/entity/entity'
import { TenantAction } from './TenantAction'
// import {EntityComponent} from '../entity'
//import { methodSchemas, entitySchema, collectionSchemas } from './LandingPageSchema'
import { collectionSchemas, entitySchema, methodSchemas } from './TenantPageSchema'
// import {    baseConstants} from '../../entity';
// import {rootDomain } from '../../config'

/**
 * Instructions:
 * 1. For simple usage steps:
 * a. fieldsMeta, actionsMeta, screens, sectionsMeta definition and creation of BaseEntity obj with these inputs
 * 2. For simple overrides of baseEntity
 * a. create an extended class of baseEntity and override its methods...
 * 3. For more customization
 * a. create a derived Service, derived actions and use them in derived BasedEntity class.
 * b. for component customization pass those components to the constuctor of derived BaseEntity class. SearchList component is the root component.
 *
 *
 */

class TenantMgmtEntity extends Entity {}
const fieldsMeta = {
	companyName: {
		label: 'Company Name',
		type: 'text',
		required: true,
		placeholder: 'Company Name',
		name: 'companyName',
		readOnlyOnEdit: true,
	},
	firstName: {
		label: 'First Name',
		type: 'text',
		required: true,
		placeholder: 'First Name',
		name: 'firstName',
	},
	lastName: {
		label: 'Last Name',
		type: 'text',
		required: true,
		placeholder: 'Last Name',
		name: 'lastName',
	},
	rootEmailId: {
		label: 'Root Email',
		type: 'text',
		required: true,
		placeholder: 'Email Address',
		name: 'rootEmailId',
		regexPattern:
			'^(([^<>()[\\]\\.,;:\\s@\\"]+(.[^<>()[\\]\\.,;:\\s@\\"]+)*)|(\\".+\\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
		error: 'Email Id is not valid',
		readOnlyOnEdit: true,
	},
	password: {
		label: 'Password',
		type: 'password',
		required: true,
		placeholder: 'Password',
		name: 'password',
		error: 'Password is not valid',
		regexPattern: '^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$',
	},
	userAuthReqd: {
		label: 'User Auth Reqd',
		type: 'select',
		required: true,
		placeholder: 'userAuthReqd',
		readOnly: false,
		name: 'userAuthReqd',
		options: [
			{ label: 'true', value: 'true' },
			{ label: 'false', value: 'false' },
		],
	},
	domain: {
		label: 'Domain',
		type: 'text',
		required: true,
		placeholder: 'Domain',
		name: 'domain',
		suffix: `.${window.location.hostname.split('.').slice(1).join('.')}`,
	},
	status: {
		label: 'Status',
		type: 'select',
		placeholder: 'Status',
		options: [
			{ label: 'Active', value: 'ACTIVE' },
			{ label: 'Inactive', value: 'INACTIVE' },
		],
		name: 'status',
		readOnly: false,
		required: true,
		disabled: false,
		disabledEval: 'status === "INACTIVE"',
	},
	plan: {
		label: 'Plan',
		type: 'select',
		placeholder: 'ChangePlan',
		options: [
			{ label: 'Free', value: 'Free' },
			{ label: 'Premium', value: 'Premium' },
		],
		name: 'plan',
		readOnly: false,
		disabled: false,
		disabledEval: 'plan === "Premium"',
		valueMatch: 'value === "Premium"',
		default: 'Free',
		required: true,
		prompt: true,
		promptMessage: 'Change Plan',
	},
	leadId: {
		label: 'Lead Id',
		type: 'text',
		required: true,
		placeholder: 'Hub spot Id',
		name: 'leadId',
	},
	aadId: {
		label: 'AAD Id',
		type: 'text',
		required: true,
		placeholder: 'AAD Id',
		name: 'aadId',
	},
	createdBy: {
		label: 'Created By',
		name: 'createdBy',
		readOnly: true,
		type: 'text',
	},
	createdAt: {
		label: 'Created At',
		name: 'createdAt',
		readOnly: true,
		type: 'datetime-local',
	},
}

const actionsMeta = {
	addSetting: {
		type: 'button',
		label: 'Add New',
		action: 'addItem',
		name: 'AddNew',
	},
	update: {
		type: 'button',
		label: 'Update',
		name: 'update',
		action: 'editItem',
		disabledEval: 'status === "Inactive"',
	},
	upsert: {
		type: 'button',
		label: 'Update2',
		name: 'Update',
		action: 'upsertItem',
	},
	create: {
		label: 'Create',
		name: 'create',
		type: 'button',
		action: 'addNewItem',
	},
	createItem: {
		label: 'Create',
		action: 'addNewItem',
		name: 'create',
		type: 'button',
	},
	view: {
		label: 'View',
		name: 'view',
		action: 'getItem',
		classes: 'fa fa-eye fa-lg',
		type: 'span',
	},
}

const sectionsMeta = {
	create: {
		title: 'Ticket Mgmt',
		items: [
			{ name: 'companyName', type: 'field' },
			{ name: 'firstName', type: 'field' },
			{ name: 'lastName', type: 'field' },
			{ name: 'rootEmailId', type: 'field' },
			{ name: 'password', type: 'field' },
			{ name: 'userAuthReqd', type: 'field' },
			{ name: 'domain', type: 'field' },
			{ name: 'leadId', type: 'field' },
			{ name: 'aadId', type: 'field' },
		],
		classes: 'ticket-form',
	},
	detail: {
		title: 'Tenant Details',
		items: [
			{ name: 'companyName', type: 'field' },
			{ name: 'rootEmailId', type: 'field' },
			{ name: 'userAuthReqd', type: 'field' },
			{ name: 'status', type: 'field' },
			{ name: 'plan', type: 'field' },
		],
		classes: 'composite-form',
	},

	headerComponent: {
		component: 'DetailHeader',

		// props: [] by default pass all parent props as props to the component.
	},
	tenantMgmtCreateForm: {
		cols: [7, 5],
		items: [
			{ name: 'create', type: 'section', col: 1 },
			{ name: 'createItem', type: 'action', col: 1 },
		],
		classes: 'composite-inner-form',
	},
	tenantMgmtDetailsForm: {
		cols: [7, 5],
		items: [
			{ name: 'detail', type: 'section', col: 1 },
			{ name: 'update', type: 'action', col: 1 },
		],
		classes: 'composite-inner-form',
	},
}

const apisMeta = {
	tenantLifeCycle: {
		name: 'tenantLifeCycle',
		path: 'tenant/lifecycle/tenantId',
		method: 'GET',
		pollingEval: 'status==="ACTIVE"',
		// response: {
		//     targetProp: 'metrics'
		// },
	},
}

const tabsMeta = {}

const screens = {
	create: {
		items: [
			{ name: 'headerComponent', type: 'section' },
			{ name: 'tenantMgmtCreateForm', type: 'section' },
		],
		apis: ['tenantLifeCycle'],
	},
	view: {
		items: [
			{ name: 'headerComponent', type: 'section' },
			{ name: 'tenantMgmtDetailsForm', type: 'section' },
		],
		classes: 'composite-form',
	},
	list: {
		items: [
			{ name: 'companyName', type: 'link' },
			{ name: 'status', type: 'field' },
			{ name: 'rootEmailId', type: 'field' },
			{ name: 'createdAt', type: 'field' },
			{ name: 'createdBy', type: 'field' },
			// {name: 'actions', type: 'actions', actions: ['view', 'update', 'create'] }
		],
		filter: [
			{
				name: 'status',
				operator: 'eq',
				value: 'ACTIVE',
			},
		],
		orderBy: ['createdAt desc'],
		multiselect: false,
	},
	search: {
		items: [
			{ name: 'companyName', type: 'field' },
			{ name: 'status', type: 'field' },
		],
	},
}
// const components = { searchList: EntityComponent} // can overrides the components like this if needed
// @ts-ignore
const TenantMgmtPage = new TenantMgmtEntity({
	ActionClass: TenantAction,
	fieldsMeta,
	actionsMeta,
	sectionsMeta,
	apisMeta,
	tabsMeta,
	screens,
	name: 'tenant',
	title: 'Tenant Mgmt',
	methodSchemas,
	collectionSchemas,
	entitySchema,
	idKey: 'id',
	fetchDetailByApi: true,
	entityUrlPaths: { update: 'tenant/update', getAll: 'tenant/findAndCountAll', default: 'tenant/registerJames', getOne: 'tenant/findByName' },
	updateOnlyWritable: true,
})

const TenantMgmtPageListSearchContainer = TenantMgmtPage.getContainer('ListSearch')
const TenantMgmtPageCreateContainer = TenantMgmtPage.getContainer('Create')
const TenantMgmtPageDetailContainer = TenantMgmtPage.getContainer('Detail')
export { TenantMgmtPageListSearchContainer, TenantMgmtPageCreateContainer, TenantMgmtPageDetailContainer }
