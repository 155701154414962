import React, { useMemo, useState } from 'react'
import {
    Box,
    Text,
    Button
} from "@chakra-ui/react"
import _ from 'lodash'
const ReadMoreLess = (props) =>{
  const { content, maxLength } = props
  const [expanded, setExpanded] = useState(false)
  const truncatedContent = _.truncate(content, {length: maxLength})
  const shouldShowButton = content.length > maxLength

  const handleToggle = (e) => {
    setExpanded(!expanded)
    e.stopPropagation()
  }

  return (
      <Box px={'8%'}>
        <Text {...props}>
        {expanded ? (
                  <div dangerouslySetInnerHTML={{ __html: content.trim() }} />
              ) : (
                  <div dangerouslySetInnerHTML={{ __html: truncatedContent.trim() }} />
              )}
          {shouldShowButton && (
            <Button {...props} color='#2563EB' variant="link" size="xs" onClick={handleToggle}>
              {expanded ? ' Read less' : 'Read more'}
            </Button>
          )}
        </Text>
      </Box>
  )
}
export default ReadMoreLess