'use strict'

import { EntityActions } from '../common/base/entity';
// import {EntityComponent} from '../entity'

export class CalendarAction extends EntityActions {
    constructor(options) {
        super(options);  
    }

    // postActionsHooks({actionName, item, tenantId }){
    //     switch (actionName) {
    //         case 'getOne':
    //               item = Object.assign({}, item, item.config);
    //               delete item.config;
    //               return item
        
    //         default:
    //             return item
    //     }
    // }
    toCamelCase = (str)=> {
        return str.replace(/^([A-Z])|\s(\w)/g, function(match, p1, p2, offset) {
            if (p2) return p2.toUpperCase();
            return p1.toLowerCase();        
        });
    };
    preActionsHooks({actionName, item }){
        if(!item.name && item.label)
        item.name=this.toCamelCase(item.label)
        return item
        // switch (actionName) {
        //     case 'editItem':
        //         //item = payload;
        //         return item;
        //     default:
        //         return item
        // }
    }
    getFns() {
        let that = this
        let fns = super.getFns()
        Object.values(fns).forEach( f => f.bind(that))
        return fns
    }
}