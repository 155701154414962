import { WorkspaceConstants, WorkspaceServices } from '..'
const GetWSForUserRequest = () => ({
	type: WorkspaceConstants.GETALL_REQ_WS,
})

const GetWSForUserSuccess = records => ({
	type: WorkspaceConstants.GETALL_SUCCESS_WS,
	records,
})

const GetWSForUserFailure = error => ({
	type: WorkspaceConstants.GETALL_FAILURE_WS,
	error,
})

export const GetWSForUser = ({ wsType, attributes, apiUrl }) => {
	return dispatch => {
		dispatch(GetWSForUserRequest())
		WorkspaceServices.GetWSForUser({ wsType, attributes, apiUrl }).then(
			records => {
				//console.log("::::::records:::::", records);
				records.wsType = wsType
				if (attributes && attributes.defaultTeam) {
					if (Array.isArray(attributes.defaultTeam) && attributes.defaultTeam.length > 0) {
						records.defaultTeam = attributes.defaultTeam[0]
					} else records.defaultTeam = attributes.defaultTeam
				}
				dispatch(GetWSForUserSuccess(records))
			},
			error => dispatch(GetWSForUserFailure(WorkspaceConstants.ERROR_MESSAGE))
		)
	}
}

const setSelectedWorkspaceRequest = () => ({
	type: WorkspaceConstants.SET_SELECTED_WS_REQ,
})

const setSelectedWorkspaceSuccess = selectedWs => ({
	type: WorkspaceConstants.SET_SELECTED_WS_SUCCESS,
	data: selectedWs,
})

export const setSelectedWorkspace = ({ selectedWs, wsType, reLoad }) => {
	return dispatch => {
		dispatch(setSelectedWorkspaceRequest())
		dispatch(setSelectedWorkspaceSuccess({ selectedWs, wsType }))
		setTimeout(() => {
			if (localStorage['persist:root']) {
				let newLocalStorage = JSON.parse(localStorage.getItem('persist:root'))
				if (newLocalStorage && newLocalStorage.globalSetting) delete newLocalStorage.globalSetting
				if (newLocalStorage && newLocalStorage.whiteLabelEntity) delete newLocalStorage.whiteLabelEntity
				localStorage.setItem('persist:root', JSON.stringify(newLocalStorage))
			}
			if (!reLoad) {
				window.location.reload()
			}
		}, 1000)
		// wsHelper.setWSDataToLS({key: 'workspace', wsType, wsData: selectedWs})
	}
}
