import React from "react";

const SignupForm = (props) => {
    return (
        <div className="step2 steps clearfix">
          <div className="empty-frame efl">
          </div>
          <div className="efl-main hr-agent-setup">
            <span className="agent-setup-img">
              <img src="/assets/img/agent-setup.png" alt="img" />
            </span>
            <h4>{props.stepConfig.stepTitle}</h4>
            <div className="add-info">
            <p>{props.stepConfig.stepDesc}</p>
            {/* <p>We are populating your knowledge base with 10 popular HR questions and sample answers.
              At the end of the setup you can edit these Q&A and make them your own</p> */}
            </div>
            <div className="qa-content">
              <div className="qa-box mCustomScrollbar">
                <ul className="qlist">
                  <li>
                    <h5 className="q-m">{props.stepConfig.smallTalk.smallTalk1.question1}</h5>
                    <p className="ans-m">
                    {props.stepConfig.smallTalk.smallTalk1.para1}
                    </p>
                    {props.stepConfig.smallTalk.smallTalk1.para2 ? <p>
                      {props.stepConfig.smallTalk.smallTalk1.para2}
                    </p> : null}
                  </li>
                  <li>
                    <h5 className="q-m">{props.stepConfig.smallTalk.smallTalk2.question2}</h5>
                    <p className="ans-m">{props.stepConfig.smallTalk.smallTalk2.para1}
                    </p>
                   
                  </li>
                  <li>
                    <h5 className="q-m">{props.stepConfig.smallTalk.smallTalk3.question3}</h5>
                    <p className="ans-m">{props.stepConfig.smallTalk.smallTalk3.para1}</p>
                  </li>
                    {
                      props.stepConfig.smallTalk.steps ?<li> <p>
                      1.    {props.stepConfig.smallTalk.steps.step1}<br></br>
                      2.    {props.stepConfig.smallTalk.steps.step2} {'>'} Reset settings.
                    </p> </li>: null
                    }
                </ul>
              </div>
            </div>
          <div className="sign-control-btn clearfix"><button className="btn btn-primary" onClick={props.nextPage}>Next</button></div>
           
          </div>
        </div>
        )
  }

export default SignupForm;