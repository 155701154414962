/**
 * RU on all settings including whitelisting
 * This is in common as all apps would need it
 * Setting Key value pairs come from db of the app.
 */
import moment from 'moment'
import { Entity } from '../common/base/entity/entity'
// import {EntityComponent} from '../entity'
import { collectionSchemas, entitySchema, methodSchemas } from './calendar.schema'
import { CalendarAction } from './CalendarAction'
let zoneList = moment.tz.names()
const createZoneListOptions = () => {
	let arrObj = []
	zoneList &&
		Array.isArray(zoneList) &&
		zoneList.map((item, idx) => {
			let obj = { label: item, value: item }
			arrObj.push(obj)
		})
	return arrObj
}
// import {    baseConstants} from '../../entity';

/**
 * Instructions:
 * 1. For simple usage steps:
 * a. fieldsMeta, actionsMeta, screens, sectionsMeta definition and creation of BaseEntity obj with these inputs
 * 2. For simple overrides of baseEntity
 * a. create an extended class of baseEntity and override its methods...
 * 3. For more customization
 * a. create a derived Service, derived actions and use them in derived BasedEntity class.
 * b. for component customization pass those components to the constuctor of derived BaseEntity class. SearchList component is the root component.
 *
 *
 */

class calendarEntity extends Entity {
	// override the following if needed.
	// derived actionObj
	// derived Service obj
	// getActionFns if you want to suppress the baseActionobj methods
	// getActionFns() {
	//     let fns = super.getActionFns()
	//     let that = this
	//     return {
	//         ...fns,
	//         addNewItem: (itemData, customerTenantID) => {
	//             // No Create allowed on Settings.
	//             return dispatch => {
	//                 let errorMsg = 'Creating ' + that.name + ' is not allowed'
	//                 dispatch(fns.failure('ADDNEW', errorMsg));
	//                 dispatch(alertActions.error(errorMsg));
	//             }
	//         },
	//         deleteItem: (id, customerTenantID) => {
	//             // No Create allowed on Settings.
	//             return dispatch => {
	//                 let errorMsg = 'Deleting ' + that.name + ' is not allowed'
	//                 dispatch(fns.failure('DELETE', errorMsg));
	//                 dispatch(alertActions.error(errorMsg));
	//             }
	//         }
	//     }
	// }
}

const fieldsMeta = {
	// name: {
	//     label: 'Business Hours Name',
	//     required: true,
	//     type: 'text',
	//     name: 'name',
	//     placeholder:'Business Hours Name',
	//     readOnlyOnEdit:true,

	// },
	label: {
		label: 'Calendar Label',
		required: true,
		type: 'text',
		name: 'label',
		placeholder: 'Calendar Label',
	},
	timeZone: {
		label: 'TimeZone',
		required: true,
		type: 'select',
		name: 'timeZone',
		placeholder: 'timeZone',
		options: createZoneListOptions(),
		filterDropDown: true,
	},
	holidays: {
		label: 'Holidays',
		required: true,
		type: 'select',
		name: 'holidays',

		placeholder: 'Please specify the holidays',
		api: {
			path: 'Holidays',
			method: 'GET',
			// params: { filter: ['status'] },
			filters: [{
                field: 'status',
                operator: 'is',
                value : 'Published'
            }],
			response: {
				ref: 'data',
				labelKey: 'label',
				valueKey: 'name',
			},
		},
		filterDropDown: true,
		readOnly: false,
	},
	businessHours: {
		label: 'Business Hours',
		required: true,
		name: 'businessHours',
		type: 'select',

		placeholder: 'Please specify the Business Hours',
		api: {
			path: 'BusinessHours',
			method: 'GET',
			// params: { filter: ['status'] },
			filters: [{
                field: 'status',
                operator: 'is',
                value : 'Published'
            }],
			response: {
				ref: 'data',
				labelKey: 'label',
				valueKey: 'name',
			},
		},
		filterDropDown: true,
		readOnly: false,
	},
	status: {
		label: 'Status',
		name: 'status',
		options: [
			{ label: 'Published', value: 'Published' },
			{ label: 'Draft', value: 'Draft' },
		],
		type: 'select',
		required: true,
		default: 'Published',

		// width:'10%',
	},
	createdBy: {
		label: 'Created By',
		name: 'createdBy',
		// readOnly: true,
		required: true,
		type: 'text',
	},
	createdAt: {
		label: 'Created At',
		name: 'createdAt',
		// readOnly: true,
		required: true,
		type: 'text',
	},
	updatedBy: {
		label: 'Last Edited By',
		name: 'updatedBy',
		// readOnly: true,
		required: true,
		type: 'text',
	},
	updatedAt: {
		label: 'Last Edited At',
		name: 'updatedAt',
		// readOnly: true,
		required: true,
		type: 'datetime-local',
	},
}

const actionsMeta = {
	doSomething: {
		type: 'button',
		label: 'Update',
		action: 'editItem',
		name: 'doSomething',
	},
	editMap: {
		label: 'Edit Map',
		name: 'editMap',
		action: 'showMap',
		condition: 'typeof value === "object"',
	},
	save: {
		label: 'Save',
		name: 'save',
		action: 'save',
	},
	cancel: {
		label: 'Cancel',
		name: 'cancel',
		action: 'cancelEdit',
	},
	update: {
		label: 'Update',
		name: 'update',
		action: 'upsertItem',
		classes: 'fa fa-pencil fa-lg ',
		type: 'span',
	},
	list: {
		label: 'List All',
		name: 'list',
		action: 'load',
	},
	search: {
		label: 'Search',
		name: 'search',
		action: 'search',
	},
	view: {
		label: 'View',
		name: 'view',
		action: 'getItem',
		classes: 'fa fa-eye fa-lg',
		type: 'span',
	},

	create: {
		label: 'Create',
		name: 'create',
		type: 'button',
		action: 'addNewItem',
	},
	createItem: {
		label: 'Create',
		action: 'upsertItem',
		name: 'create',
		type: 'button',
	},
}
const sectionsMeta = {
	general: {
		items: [
			{ name: 'status', type: 'field' },
			{ name: 'updatedBy', type: 'field' },
			{ name: 'updatedAt', type: 'field' },
		],
	},
	basic: {
		title: 'Queue Details',
		items: [
			{ name: 'label', type: 'field' },
			{ name: 'timeZone', type: 'field' },
			{ name: 'holidays', type: 'field' },
			{ name: 'businessHours', type: 'field' },
			{ name: 'status', type: 'field' },
		],
		classes: 'ticket-form user-queue',
	},
	// object: {
	//     object: {        ref: 'value' }
	//       // show all value object keys as grid key values. All fields are defaulted to text. If value is not of type object then this section is ignored.

	// },
	headerComponent: {
		component: 'DetailHeader',

		// props: [] by default pass all parent props as props to the component.
	},
	queueForm: {
		cols: [7, 5],
		items: [
			{ name: 'basic', type: 'section', col: 1 },
			{ name: 'update', type: 'action', col: 1 },
			{ name: 'deleteConfig', type: 'action', col: 1 },
		],
		classes: 'composite-inner-form',
	},
	createForm: {
		cols: [7, 5],
		items: [
			{ name: 'basic', type: 'section', col: 1 },
			{ name: 'createItem', type: 'action', col: 1 },
		],
		classes: 'composite-inner-form',
	},
}

const screens = {
	// edit: {
	//     title:{label: 'Edit Settings', col: 1, },
	//     items: [{name:'heading', type: 'section'}],
	//     actions: ['editMap', 'cancel', 'save']
	// },
	view: {
		items: [
			{ name: 'headerComponent', type: 'section' },
			{ name: 'queueForm', type: 'section' },
		],
		// classes: 'composite-form',
		title: 'Calendar Details',
	},
	create: {
		items: [
			{ name: 'headerComponent', type: 'section' },
			{ name: 'createForm', type: 'section' },
		],
	},
	edit: {
		items: [
			{ name: 'headerComponent', type: 'section' },
			{ name: 'queueForm', type: 'section' },
		],
	},
	// view: {
	//     sections: ['general', 'details'],
	//     actions: ['edit']
	// },
	list: {
		items: [
			{ name: 'label', type: 'link' },
			{ name: 'timeZone', type: 'field' },
			{ name: 'holidays', type: 'field' },
			// { name: 'Calendar', type: 'field' },
			{ name: 'status', type: 'field' },
		],
		// actions: ['view', 'update']
		filter: [
			{
				name: 'status',
				operator: 'ne',
				value: 'Deleted',
			},
		],
		multiselect: false,
	},
	search: {
		items: [
			{ name: 'label', type: 'link' },
			{ name: 'holidays', type: 'link' },
		],
	},
}
// const components = { searchList: EntityComponent} // can overrides the components like this if needed
// @ts-ignore
const calendar = new calendarEntity({
	ActionClass: CalendarAction,
	fieldsMeta,
	actionsMeta,
	sectionsMeta,
	screens,
	name: 'calendar',
	title: 'Calendar',
	methodSchemas,
	collectionSchemas,
	entitySchema,
	idKey: 'id',
	entityUrlPaths: { update: 'Calendar', getAll: 'Calendar', default: 'Calendar' },
	fetchDetailByApi: false,
})

const calendarListSearchContainer = calendar.getContainer('ListSearch')
const calendarCreateContainer = calendar.getContainer('Create')
const calendarDetailContainer = calendar.getContainer('Detail')
export { calendarListSearchContainer, calendarCreateContainer, calendarDetailContainer }

