import React, { useEffect, useState } from 'react';
import { Table } from 'reactstrap';
import { MapInput } from './formInput';
import * as helper from './helper';


// Json List Component for simple json Array 
const JsonListComponent = (props) => {
    const [propList, setPropList] = useState({})
    useEffect(() => { setPropList(props) })
    return (
        <div className="row">

            <div className="col-sm-11">
                <AddComponent
                    {...props}
                />
            </div>
            <div className="col-sm-1 listbtn">
                <div onClick={props.handleInputSubmit} className="listbtn success"><i className="fa fa-plus"></i></div>
            </div>
        </div>


    )


}




const AddComponent = (props) => {
    const value = props.value || '';
    const inputChange = (inputValue, inputName) => {
        props.handleInputChange(inputName, inputValue)
    }
    const handleKeyDown = () => {
        props.handleInputSubmit()
    }



    return (
        <MapInput
            {...props}
            handleChange={inputChange}
            value={value}
            handleEnter={handleKeyDown}

        />
    )
}



const JsonListingComponent = (props) => {
    const [propList, setPropList] = useState({})
    const [state, setState] = useState({ toggleEdit: false, editValue: props.value || '', previousValue: '', id: null })

    useEffect(() => {
        setState({ editValue: props.value, previousValue: props.value })
        setPropList(props)
    }, [props]
    )

    const remove = (idx) => {
        const { editValue } = state
        let filterRemoved = editValue.filter((x, i) => i !== idx)
        props.handleInputUpdate(filterRemoved)
    }
    const editItem = (idx, item) => {
        setState({ ...state, toggleEdit: true, id: idx, editValue: props.value[idx] })
    }

    const inputChange = (inputValue, inputName) => {
        // const {id}=state;
        setState({ ...state, editValue: inputValue })
    }
    const handleInputSubmit = () => {
        const { editValue, previousValue, id } = state;
        if (id !== -1) {
            previousValue[id] = editValue;
            props.handleInputUpdate(previousValue)
        }
    }




    const { value } = propList;
    const { toggleEdit, id, editValue } = state;
    return (
        <div className="row">
            <div className="col-sm-12">

                {value && Array.isArray(value) && value.length > 0 && <Table className="bgWhite" size="sm">
                    <tbody>
                        {value.map((item, idx) => {
                            if (!helper.isObject(item)) {
                                return (
                                    <tr key={idx}>
                                        <td>
                                            <div className="row">
                                                <div className="col-sm-9 listlabel">
                                                    {id !== idx && item}
                                                    {toggleEdit && id === idx &&
                                                        <MapInput
                                                            {...props}
                                                            handleChange={inputChange}
                                                            value={editValue}
                                                            placeholder={'Edit'}
                                                            handleEnter={handleInputSubmit}

                                                        />
                                                    }
                                                </div>
                                                <div className="col-sm-3">

                                                    {toggleEdit &&
                                                        <span onClick={handleInputSubmit} className="listbtn success"><i className="fa fa-plus"></i></span>
                                                    } &nbsp;
                    <span className="listbtn danger" onClick={remove.bind(this, idx)}><i className="fa fa-trash"></i></span> &nbsp;
                    {!toggleEdit &&
                                                        <span className="listbtn primary" onClick={editItem.bind(this, idx, item)}><i className="fa fa-edit"></i></span>
                                                    }
                                                </div>

                                            </div>
                                        </td>
                                    </tr>
                                )

                            }


                            else { props.preTag(value) }



                        })}


                    </tbody>
                </Table>
                }
            </div>

        </div>


    )


}




export {
    JsonListComponent,
    AddComponent,
    JsonListingComponent
};

