import pica from 'pica'
const resizer = pica({
  // features: ["js", "wasm", "ww"]
  // features: ["ww", "js"]
})
const compressFile = async file => {
  const dataUrl = URL.createObjectURL(file)
  const imgCanavas = await asyncImage(dataUrl)
  const reziedCanavasImg = await resizeImage(imgCanavas)
  const outputFile = await resizer.toBlob(reziedCanavasImg, file.type)
  const newFile = new File([outputFile], file.name, {
    type: file.type,
  })
  return newFile
}

const asyncImage = (src, ...constructorProps) => {
  return new Promise((resolve, reject) => {
    let img = new Image(...constructorProps)
    img.onload = () => resolve(img)
    img.setAttribute('crossOrigin', '')
    img.onerror = reject
    img.src = src
  })
}

const resizeImage = image => {
  const newDimensions = calculateAspectRatioFit(image.naturalWidth, image.naturalHeight, 1024, 768)
  const offScreenCanvas = document.createElement('canvas')
  offScreenCanvas.width = newDimensions.width
  offScreenCanvas.height = newDimensions.height

  return resizer.resize(image, offScreenCanvas, {
    quality: 3, // this is the max (recommended)
    alpha: true, // use alpha channel
    unsharpAmount: 20,
    unsharpRadius: 0.6,
    unsharpThreshold: 2,
    transferable: true,
  })
}

const calculateAspectRatioFit = (srcWidth, srcHeight, maxWidth, maxHeight) => {
  var ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight)

  return { width: srcWidth * ratio, height: srcHeight * ratio }
}

export { compressFile }

