/**
 * RU on all settings including whitelisting
 * This is in common as all apps would need it
 * Setting Key value pairs come from db of the app.
 */
import { alertActions } from '../common';
import { Entity } from '../common/base/entity/entity';
import { ContentAction } from "./ContentAction";
// import {EntityComponent} from '../entity'
import { collectionSchemas, entitySchema, methodSchemas } from './LandingPageSchema';
// import {    baseConstants} from '../../entity';

/**
 * Instructions: 
 * 1. For simple usage steps:
 * a. fieldsMeta, actionsMeta, screens, sectionsMeta definition and creation of BaseEntity obj with these inputs
 * 2. For simple overrides of baseEntity 
 * a. create an extended class of baseEntity and override its methods...
 * 3. For more customization
 * a. create a derived Service, derived actions and use them in derived BasedEntity class.
 * b. for component customization pass those components to the constuctor of derived BaseEntity class. SearchList component is the root component.
 *
 * 
 */


class LandingPageEntity extends Entity {
    getActionFns() {
        let fns = super.getActionFns()
        let that = this
        return {
            ...fns,

            addNewItem: (itemData, customerTenantID) => {
                // No Create allowed on Settings.
                return dispatch => {
                    let errorMsg = 'Creating ' + that.name + ' is not allowed'
                    dispatch(fns.failure('ADDNEW', errorMsg));
                    dispatch(alertActions.error(errorMsg));
                }
            },
            deleteItem: (id, customerTenantID) => {
                // No Create allowed on Settings.
                return dispatch => {
                    let errorMsg = 'Deleting ' + that.name + ' is not allowed'
                    dispatch(fns.failure('DELETE', errorMsg));
                    dispatch(alertActions.error(errorMsg));
                }
            },
        }
    }
}

let parentFields = { 
    appName: {
        label: 'App Name',
        min: 3,
        max: 20,
        type: 'select',
        required: true,
        placeholder: 'Please specify the App Name',
        options: [
            { label: 'virtualagent' , value: "/virtualagent"},
        ],
        readOnly: false,
        name: 'appName'
    },
    pageName: {
        label: 'Page Name',
        type: 'text',
        required: true,
        placeholder: 'Please specify the Page Name',
        readOnly: true,
        name: 'pageName',
    },
    micrositeName: {
        label: 'Microsite Name',
        type: 'text',
        required: true,
        placeholder: 'Please specify the Microsite Name',
        name: 'micrositeName',
    },
    publicPrivate:{
        label: 'Is Public/Private',
        type: 'select',
        options: [{ label: 'Public', value: "public" }, { label: 'Private' , value: "private"}],
        required: true,
        placeholder: 'Please specify the public/Private',
        readOnly: false,
        name: 'publicPrivate', 
    },};

let childFields= {
        landingPageImage: {
            label: 'landingPage Image',
            type: 'url',
            required: true,
            placeholder: 'Please specify the landingPage Image Url',
            readOnly: false,
            name: 'landingPageImage',
        },
        landingPageImageStyle:{
            label: 'Background Image',
            type: 'textarea',
            placeholder: 'Please specify Background Image',
            name: 'landingPageImageStyle',
        },
        landingPageColorStyle:{
            label: 'Background Color or style',
            type: 'textarea',
            placeholder: 'Please specify Background Color or Style',
            name: 'landingPageColorStyle',
        },
        botName:{
            label: 'Bot Name',
            name: 'botName',
            type: 'text',
            readOnly: true
        },  
        botTheme: {
            label: 'Bot Theme',
            type: 'color',
            placeholder: 'Please specify botTheme color',
            name: 'botTheme',
        },
        showBot: {
            label: 'Default Show Bot',
            type: 'select',
            placeholder: 'Please specify default chat bot Open/Close',
            name: 'showBot',
            options: [{ label: 'True', value: 'true' }, { label: 'False' , value: 'false'}],
        },
        botImage: {
            label: 'Bot Icon',
            type: 'text',
            placeholder: 'Please specify Bot Icon Url',
            name: 'botImage',
        },
        agentImage: {
            label: 'Agent Icon',
            type: 'text',
            placeholder: 'Please specify Agent Icon Url',
            name: 'agentImage',
        },
        botOpenIcon: {
            label: 'Bot Open Icon',
            type: 'url',
            placeholder: 'Please specify Bot Open Icon Url',
            name: 'botOpenIcon',
        },
        botCloseIcon: {
            label: 'Bot Close Icon',
            type: 'url',
            placeholder: 'Please specify Bot Close Icon Url',
            name: 'botCloseIcon',
        },
        botStyle: {
            label: 'Bot Style',
            type: 'textarea',
            placeholder: 'Please specify Bot Style',
            name: 'botStyle',
        },
        companyLink: {
            label: 'Company Link',
            type: 'url',
            placeholder: 'Please specify Company website Url',
            name: 'companyLink',
        },
        companyName: {
            label: 'Company Name',
            type: 'text',
            placeholder: 'Please specify Company Name',
            name: 'companyName',
        },
        contantInfo: {
            label: 'Contant Info',
            type: 'text',
            placeholder: 'Please specify Contant Info',
            name: 'contantInfo',
        },
        companyLogo: {
            label: 'Company Logo',
            type: 'url',
            required: true,
            placeholder: 'Please specify the logo Url',
            readOnly: false,
            name: 'companyLogo',
        },
        companyStyle: {
            label: 'Company Style',
            type: 'textarea',
            placeholder: 'Please specify the Company Style',
            readOnly: false,
            name: 'companyStyle',
        },
        title: {
            label: 'Title',
            type: 'text',
            placeholder: 'Please specify Title',
            name: 'title',
        },
        content:{ 
            label: 'Content',
            type: 'textarea',
            placeholder: 'Please specify Content',
            name: 'content',
        },
        contentStyle: {
            label: 'Content Style',
            type: 'textarea',
            placeholder: 'Please specify Content Style',
            name: 'contentStyle',
        },
        privacy: {
            label: 'Privacy',
            type: 'text',
            placeholder: 'Please specify Privacy',
            name: 'privacy',
        },
        tandC:{
            label: 'tand C',
            type: 'text',
            placeholder: 'Please specify the tandC',
            name: 'tandC',
        },
        isHeader: {
            label: 'Header',
            type: 'select',
            placeholder: 'Do you need header',
            name: 'isHeader',
            options: [{ label: 'True', value: 'true' }, { label: 'False' , value: 'false'}],
        },
        isQnaLog:{
            label: 'Qna Log',
            type: 'select',
            placeholder: 'Do you need Qna Log',
            name: 'isQnaLog',
            options: [{ label: 'True', value: 'true' }, { label: 'False' , value: 'false'}],
        },
        accordianForQna:{
            label: 'Qna Log (Accordian) ',
            type: 'select',
            placeholder: 'Do you need Accordian layout',
            name: 'accordianForQna',
            options: [{ label: 'True', value: 'true' }, { label: 'False' , value: 'false'}],
        }, 
        isPowerdBy:{
            label: 'Powerd By',
            type: 'select',
            placeholder: 'Do you need Powerd By',
            name: 'isPowerdBy',
            value: true,
            options: [{ label: 'True', value: 'true' }, { label: 'False' , value: 'false'}],
        },   
        emailTranscript:{
            label: 'Email Transcript',
            type: 'select',
            placeholder: 'Do you need Email Transcript',
            name: 'emailTranscript',
            value: true,
            options: [{ label: 'True', value: 'true' }, { label: 'False' , value: 'false'}],
        },   
        printTranscript:{
            label: 'Print Transcript',
            type: 'select',
            placeholder: 'Do you need Print Transcript',
            name: 'printTranscript',
            value: true,
            options: [{ label: 'True', value: 'true' }, { label: 'False' , value: 'false'}],
        }, 
        menuFlow:{
            label: 'Menu Flow',
            type: 'textarea',
            placeholder: 'Please specify menu flow in fromat of json array of object like: [{name: flowName, value: flowTextValue,iconUrl:"http://abc.com/icon.png"}]',
            name: 'menuFlow',
        },
        welcomeMessage: {
            label: 'Welcome Message',
            type: 'textarea',
            placeholder: 'Please specify Welcome Message',
            name: 'welcomeMessage',
        },
        headerHtmlText: {
            label: 'Header Html/Text',
            type: 'textarea',
            placeholder: 'Please specify Header Html Text',
            name: 'headerHtmlText',
        },
        autoScrollSnapOnActivity: {
            label: 'Auto Scroll Activity',
            type: 'select',
            placeholder: 'Please specify Auto Scroll Activity',
            name: 'autoScrollSnapOnActivity',
            options: [{ label: 'True', value: 'true' }, { label: 'False' , value: 'false'}],
        },
        gaCodeId:{
            label: 'Google Analytics Id',
            type: 'text',
            placeholder: 'Please specify Google Analytics Id',
            name: 'gaCodeId',
        },

    };

const fieldsMeta = Object.assign({}, parentFields, childFields);

const listsMeta = {
    myItems: {
        name: 'myItems',
        api: {
            path: '/getMySettings',
            method: 'GET',
            params: undefined,
            body: undefined,
            response: {
                ref: 'data', // ref key .. could be defaulted to body. Prop that holds the 
                map: { // return columns as. Key is api response object keys. value is what they are returned as
                    caseId: 'itemId',
                    shortDesc: 'title'
                }
            }  
        }
    }
}

const actionsMeta = {
    addSetting: {
        type: 'button',
        label: 'Add New',
        action: 'addItem',
        name: 'AddNew'
    },
    update: {
        type: 'button',
        label: 'Update',
        name: 'upsert',
        action: 'editItem',
    },
    upsert: {
        type: 'button',
        label: 'Update',
        name: 'Update',
        action: 'upsertItem',
    },
    Create:{
         label: 'Add Setting',
         action: 'addItem',
     },
    view: {
        label: 'View',
        name: 'view',
        action: 'getItem',
        classes: 'fa fa-eye fa-lg',
        type: 'span'
    }
}
let createItems =   Object.keys(fieldsMeta).map(prop=>{ return { name: prop, type: 'field'} })
const sectionsMeta = {
    // general: {
    //     items: [{name:'status', type: 'field'}, {name:'updatedBy', type: 'field'}, {name:'updatedAt', type: 'field'}]
    // },
    basic: {
        title: 'White Label Settings',
        items: createItems,
        classes:"ticket-form"
    },

    headerComponent: {
        component: 'DetailHeader',
        
        // props: [] by default pass all parent props as props to the component.
    },
    LandingPageSettingForm: {
        cols: [7,5],
        items:[
        {name: 'basic', type: 'section', col: 1}, 
        {name: 'upsert', type: 'action', col: 1}
    ],
        classes: 'composite-inner-form'
    },
    documents:{
        component: 'DocumentList', // contains upload control too
        props: { docs :'itemDocuments'}, // means DocumentList will get props.docs with value of collection = itemDocuemnts
        
    },
    notifications: {
        title: 'All Notes',
        type: 'tab',
        items: [{name: 'notesCtrl', type: 'section'}, {name: 'notesList', type: 'section'}]
    },
    stats: {
        type: 'group',
        items: [{name: 'status', type: 'field'},{name: 'submittedBy', type:'field'}, {name: 'requestedFor', type: 'field'},{name:'assignedTo', type: 'field'}
        ],
        classes: 'section-stat'
    },
    userInfo: {
        type: 'group',
        items:[{name:'email', type: 'field'}, {name:'firstName', type: 'field'}, {name:'lastName', type: 'field'}, {name:'location', type: 'field'}]
    },
    notesCtrl: {
        component: 'NotesControl',
        otherProps: { collectionProp: 'notes'}
    },
    notesList: {
        component: 'NotesList',
        props:{list:'notes'}
    },
    relList:{
        component: 'List',
        props:{list:'relatedCases'}
    },
}


const tabsMeta = {
    tabGroup1: {
        tabs: [ {
            name: 'notifications',
            type: 'section'
        },{
            name: 'relatedCases',
            type: 'section'
        }],
        defaultActive: '1',
        name: 'tabGroup1'
    }
}

const screens = {
    view: {
        items: [{name:'headerComponent', type: 'section'}, {name: 'LandingPageSettingForm', type: 'section'}],
        classes: 'composite-form',
        title: 'Landing Page Settings',
    },
    edit:{
        items: [{name:'headerComponent', type: 'section'}, {name: 'LandingPageSettingForm', type: 'section'}]
    },
    create:{
        items: [{name:'headerComponent', type: 'section'}, {name: 'LandingPageSettingForm', type: 'section'}]
    },
    list: {
        items: [
            {name:'appName', type: 'field'},
            {name:'pageName', type: 'field'}, 
            {name:'publicPrivate', type: 'link'},
            {name:'micrositeName', type: 'link'},
            // {name: 'actions', type: 'actions', actions: ['view', 'update', 'create'] }
            ],
            filter : [{
                name: "status",
                operator: "eq",
                value: "Published"
            }],
        multiselect:false,
    },
    search: {
        items: [{name:'appName', type: 'link'}, {name:'publicPrivate', type: 'field'}]
    },
}
// const components = { searchList: EntityComponent} // can overrides the components like this if needed
const LandingPageSetting = new LandingPageEntity({ fieldsMeta, actionsMeta, sectionsMeta, tabsMeta, screens, name: 'whiteLabel', title: 'White label Settings', methodSchemas, ActionClass: ContentAction, collectionSchemas,  entitySchema, idKey: 'id', fetchDetailByApi: true, entityUrlPaths:{update: "whiteLabel/update", upsert: "whiteLabel/upsert", getAll:'whiteLabel/findAndCountAllWithDefaultTenant', default: "whiteLabel/findWithDefaultTenant" }})

const LandingPageSettingListSearchContainer = LandingPageSetting.getContainer('ListSearch')
const LandingPageSettingCreateContainer = LandingPageSetting.getContainer('Create')
const LandingPageSettingDetailContainer = LandingPageSetting.getContainer('Detail')
export { LandingPageSettingListSearchContainer, LandingPageSettingCreateContainer, LandingPageSettingDetailContainer, parentFields, childFields };

