import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { i18n, identityActions, settingsActions, tenantActions } from '../'
import { appRootPath, localTest, singleTenant } from '../../../config'
import { history } from '../../../redux/history'
import { RootRouter } from '../../../redux/routes'
// import AppModal from './AppModal'
import '../scss/_variables.scss'

class Root extends Component {
	componentDidMount() {
		if (window.location.pathname.includes(appRootPath)) {
			this.props.selectTenant(this.props.t)
			const { identity } = this.props
			// localStorage.removeItem("routeName");
			// if (singleTenant && ((identity && !identity.isInitiated) || !identity)) this.props.checkIdentity(this.props.t)
		}
	}

	redirectAppToDefaultLandingPage(identity, settingData) {
		let rPath = localStorage.getItem('redirectAppPath')
		//console.log(`redirectAppPath in localstorage ${rPath}`)
		let skipRoles = ['trainee', 'Trainee', 'ticket_user','Ticket User', 'uma_authorization', 'offline_access']
		let extraRoles = identity && identity.profile && identity.profile.roles && identity.profile.roles.length && identity.profile.roles.filter(r => skipRoles.indexOf(r) == -1)
		if (rPath) {
			if (!extraRoles || extraRoles.length === 0) {
				return rPath
			} else return false
		}
		// if (settingData && settingData.commonUiOptions)
		//{
		let commonUiOptions = settingData && settingData.commonUiOptions
		if (commonUiOptions && commonUiOptions.roles && commonUiOptions.rolePriority) {
			let rolesPriority = commonUiOptions.rolePriority || Object.keys(commonUiOptions.roles)
			let roles4Redirect = []
			if (extraRoles && extraRoles.length > 0 && rolesPriority) {
				roles4Redirect = rolesPriority.filter(r => extraRoles.indexOf(r) != -1)
			} else {
				roles4Redirect = rolesPriority
			}
			roles4Redirect &&
				roles4Redirect.some(r => {
					if (identity.profile && identity.profile.roles.length && identity.profile.roles.indexOf(r) != -1) {
						let routeData = commonUiOptions.roles[r]
						let uiOption = routeData && routeData.defaultLandingApp && Object.keys(routeData.defaultLandingApp).length > 0 ? routeData.defaultLandingApp : null
						if (uiOption && uiOption.appName && uiOption.path) {
							rPath = `${window.location.protocol}//${window.location.hostname}/${uiOption.appName}/${uiOption.path}`
							return true
						} else {
							uiOption = routeData && routeData.defaultLandingRoute
							if (uiOption) {
								rPath = `${window.location.protocol}//${window.location.hostname}/${uiOption}`
								return true
							}
						}
					}
					return false
				})
			if (!rPath) {
				skipRoles &&
					skipRoles.some(r => {
						if (identity.profile && identity.profile.roles.length && identity.profile.roles.indexOf(r) != -1) {
							let routeData = commonUiOptions.roles[r]
							let uiOption =
							routeData && routeData.defaultLandingApp && Object.keys(routeData.defaultLandingApp).length > 0 ? routeData.defaultLandingApp : null
							if (uiOption && uiOption.appName && uiOption.path) {
								rPath = `${window.location.protocol}//${window.location.hostname}/${uiOption.appName}/${uiOption.path}`
								return true
							}
						}
						return false
					})
			}
		}
		//}
		//console.log(`Setting redirectAppPath in localstorage ${rPath}`)
		if (rPath) {
			localStorage.setItem('redirectAppPath', rPath)
		}
		return rPath
	}
	render() {
		const { tenant, identity, globalSetting } = this.props

		let settingData = globalSetting && globalSetting.uiFeature
		if (!this.i18n && !tenant.loading && !tenant.failed && tenant && tenant.id) {
			const token = identity && identity.token ? identity.token : null
			this.i18n = i18n
			if (!singleTenant) this.props.getAllTenantConfigs((tenant && tenant.id) || null, this.props.t)
		}
		const checkRoutes = (route, settings) => {
			return (
				route.children &&
				route.children.some(route2 => {
					let isAllowedRoute =
						(settings && settings[route2.name] && settings[route2.name].status == 0 ? false : route2.isNav) &&
						!route2.children &&
						identity.roles &&
						identity.roles.some(role => route2.allowedRole && route2.allowedRole.pop && route2.allowedRole.includes(role))
					if (isAllowedRoute && !route2.children) {
						if (route2.app) window.location.href = route2.path
						else history.push(route2.path)
						localStorage.removeItem('routeName')
						return true
					} else if (route2.children) {
						return checkRoutes(route2, settings && settings[route2.name])
					} else return false
				})
			)
		}
		let currentAppRoutes = this.props.routeList
		if (
			(window.location.pathname.includes(appRootPath) || window.location.pathname.includes(window.location.pathname.split('/')[1])) &&
			identity &&
			identity.isAuthenticated &&
			((!singleTenant && settingData) || singleTenant)
		) {
			let navApp = localStorage.getItem('routeName')
			//check for any ownroutes children

			let baseNames = window.location.pathname.split('/')
			if (baseNames.length == 2 && navApp) {
				//   localStorage.setItem('routeName', 'admin');
				currentAppRoutes = currentAppRoutes.filter(route => {
					return route.name === 'admin'
				})
				//console.log('Admin', currentAppRoutes)
			} else if (baseNames.length > 2 && navApp) {
				let filterApp = currentAppRoutes.find(r => (r.app && r.app.path === baseNames[2]) || r.name === navApp)
				if (filterApp) {
					currentAppRoutes = currentAppRoutes.filter(route => {
						return route.name === filterApp.name
					})
					//console.log(filterApp.name, currentAppRoutes)
					// localStorage.setItem('routeName', filterApp.name);
				}
			} else if (navApp) {
				currentAppRoutes = currentAppRoutes.filter(route => {
					return route.name === navApp
				})
			} else if (window.location.pathname && (window.location.pathname === appRootPath || window.location.pathname === appRootPath + '/')) {
				currentAppRoutes = currentAppRoutes.filter(route => {
					return route.path === '/' || route.name === 'admin'
				})
			}

			if (
				this.props.routeList &&
				(history.location.pathname === '/' || 
				history.location.pathname === '/home' || 
				history.location.pathname === '/login' || 
				history.location.pathname === '/logout')
			) {
				let redirectAppPath = this.redirectAppToDefaultLandingPage(identity, settingData)
				if (redirectAppPath) {
					window.location.href = redirectAppPath
					return true
				}

				// if(navApp){
				//     currentAppRoutes = this.props.routeList.filter(route=>{ return route.name === navApp})
				//    // localStorage.removeItem("routeName");
				// }
				let isDone = currentAppRoutes.some(route => {
					let allowed = identity.roles && identity.roles.some(role => route.allowedRole && route.allowedRole.pop && route.allowedRole.includes(role))
					if (
						allowed && 
						route && 
						route.app && 
						!navApp && 
						!route.children && 
						((settingData && settingData[route.name] && settingData[route.name].status) || route.isNav)
						) {
						const routePath = route.app.path
						localStorage.removeItem('routeName')
						if (settingData.commonUiOptions) {
							let commonUiOptions = settingData.commonUiOptions
							if (commonUiOptions && commonUiOptions.roles) {
								let addedRoles = Object.keys(commonUiOptions.roles)
								let routeName = route.name
								addedRoles &&
									addedRoles.every(r => {
										if (identity.profile.roles.indexOf(r) != -1 && routeName) {
											let routeData = commonUiOptions.roles[r]
											let uiOption = routeData && routeData.default && Object.keys(routeData.default).length > 0 ? routeData.default : null
											if (uiOption && uiOption.defaultLandingRoute) {
												let landingPage = uiOption.defaultLandingRoute
												window.location.href = `${window.location.protocol}//${window.location.hostname}/${landingPage}`
												return false
											}
											window.location.href = `${window.location.protocol}//${window.location.hostname}/${routePath}`
											return true
										} else {
											window.location.href = `${window.location.protocol}//${window.location.hostname}/${routePath}`
											return true
										}
									})
							} else {
								window.location.href = `${window.location.protocol}//${window.location.hostname}/${routePath}`
							}
							return true
						}
						window.location.href = `${window.location.protocol}//${window.location.hostname}/${routePath}`
						return true
					}
					if (allowed) {
						if (checkRoutes(route, settingData ? settingData[route.name] : null)) {
							return true
						}
						return false
					} else return false
				})
				return <div className="router-path">{tenant.loading ? <div className="loader"></div> : <RootRouter />}</div>
				// if(isDone)  return  (<div>{tenant.loading ? <div className="loader"></div> : <RootRouter />}</div>)
				// else
				// return <h3 className="text-danger">Sorry! you are not allowed to access this page.</h3>
			} else {
				return <div>{tenant.loading ? <div className="loader"></div> : <RootRouter />}</div>
			}
		} else if (window.location.pathname && (window.location.pathname === appRootPath || window.location.pathname === appRootPath + '/')) {
			currentAppRoutes = currentAppRoutes.filter(route => {
				return route.path === '/'
			})
			let redirectAppPath = this.redirectAppToDefaultLandingPage()
			if (redirectAppPath) {
				window.location.href = redirectAppPath
				return true
			} else if (!tenant.loading && window.location.pathname !== '/') window.location.href = '/'
		} else {
			let path1 = this.props.routeList.find(r => !r.allowedRole && r.path && window.location.pathname === (appRootPath + r.path).replace('//', '/'))
			if (path1) {
				// history.push(path1)
				return <div>{tenant.loading ? <div className="loader"></div> : <RootRouter />}</div>
			}
		}
		if (!localTest && tenant.failed) history.push('/error')
		if ((identity && !identity.isAuthenticated) || (!singleTenant && !settingData)) {
			return <div>{tenant.loading ? <div className="loader"></div> : <RootRouter />}</div>
		}
	}
}

const mapStateToProps = state => {
	const { tenant, identity, globalSetting } = state
	return {
		tenant,
		identity,
		globalSetting,
	}
}

const mapDispatchToProps = dispatch => ({
	selectTenant: t => dispatch(tenantActions.selectTenant(t)),
	checkIdentity: t => dispatch(identityActions.checkIdentity(t)),
	getAllGlobalSettings: (tenantUid, username, t) => dispatch(settingsActions.getAllGlobalSettings(tenantUid, username, t)),
	getAllTenantConfigs: (tenantId, t) => dispatch(settingsActions.getAllTenantConfig(tenantId, t)),
})
export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(Root)
