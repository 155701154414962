'use strict'

import { EntityActions } from '../common/base/entity';
// import {EntityComponent} from '../entity'

export class CustomerAction extends EntityActions {
    constructor(options) {
        super(options);
    }

    getFns() {
        let that = this
        let fns = super.getFns()
        Object.values(fns).forEach(f => f.bind(that))
        return fns
    }


    preActionsHooks({ item }) {
        if (item) {
            let emailDomain = item.emailDomain
            if (emailDomain) {
               emailDomain = emailDomain.map((domain) => {
                    return domain?.split('@').pop().split('.')[0]
                })
                item.emailDomain = emailDomain
            }
        }

        return item
    }


}