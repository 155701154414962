import classNames from 'classnames'
import {AppSidebarToggler } from '@coreui/react'
import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import { Badge, Nav, NavItem, NavLink as NavLink1 } from 'reactstrap'
import {
  localTest
} from '../../../config'
import { history } from '../../../redux/history'
import { routeList } from '../../../redux/routes'
// Locale
import { withTranslation } from 'react-i18next'
import {AiOutlineLeftCircle} from 'react-icons/ai'
import { MdOutlineArrowBackIosNew, MdArrowForwardIos } from 'react-icons/md'
import { Flex } from '@chakra-ui/react'
import { identityServices } from '../../common'
//Locale

const knowledge_source_map = {
  'Documents': 'Upload Documents',
  'Sharepoint connect':'SharePoint Integration',
  'Crawled URLs':'Crawl URL',
  'Knowledge articles':'Knowledge Articles'
}
class Sidebar extends Component {

  constructor(){
    super()
    this.sidebarHover = this.sidebarHover.bind(this)
    this.state = {
      disabledSources:[],
			isSidebarOpen: false,
		}
}
sidebarHover(flag){
 if(flag){
   //document.querySelector('body').classList.add('sidebarExtend')
 }
 else{
  // document.querySelector('body').classList.remove('sidebarExtend')
 }


}



  componentWillMount() {
    const { identity } = this.props
    if (identity) {
      // let { profile } = identity;
      // if (profile)
      //   this.props.getAllGlobalSettings(profile.tenantUid, profile.preferred_username);
    }

  }
  componentDidMount() {
    try{
			const getAiConfigData = async (apiUrl) => {
				const apiUrlCustom = apiUrl || sessionStorage.getItem('apiUrl')
				let ep = apiUrlCustom
				const requestOptions = {
					method: 'GET',
					headers: { 'Content-Type': 'application/json' },
					url: `${apiUrlCustom}/botConfig/findWithParentAndCountAll?$filter=status eq 'Published' and fullName eq 'uiGenAIConfig'`,
				}
			
        await identityServices.identityApi.request(requestOptions).then(response => {
					if (response.status !== 200) {
						return Promise.reject(response)
          }
          if (response.data) {
            this.setState({disabledSources: response.data[0].properties.sections.find(section => section.htmlElement.some(element => element.data.title in knowledge_source_map)).htmlElement.filter(section=>section.data.title in knowledge_source_map && !section.data.toggleValue).map(section=>section.data) })
          }
				})
			}
		const { tenant } = this.props
		 getAiConfigData(tenant.apiUrl)
		}
		catch(err){
			console.log("err",err)
		}
    var childElement = document.querySelector('.nav-link.active')
    if (childElement) {
      var parentElement = childElement.parentElement.parentElement.parentElement
      parentElement.classList.toggle('open')
      parentElement.firstChild.classList.toggle('open')
      parentElement.lastChild.classList.toggle('show-panel')
      var parentMostElement = childElement.parentElement.parentElement.parentElement.parentElement.parentElement
      if (parentMostElement) {
        parentMostElement.classList.toggle('open')
        parentMostElement.firstChild.classList.toggle('open')
        parentMostElement.lastChild.classList.toggle('show-panel')
      }
    }
  }

  handleClick(e) {
    e.preventDefault()
    e.target.parentElement.parentElement.classList.toggle('open')
    e.target.parentElement.classList.toggle('open')
    e.target.parentElement && e.target.parentElement.nextElementSibling && e.target.parentElement.nextElementSibling.classList.toggle('show-panel')

  }

  activeRoute(routeName, props) {
    return 'nav-item nav-dropdown drop-togle'
  }
  
  hadleAppRedirect(pathInfo){
    const { identity,globalSetting } = this.props
    let settingData = globalSetting && globalSetting.uiFeature
    let rPath = pathInfo?.app?.path || null
    const accessKey=pathInfo?.conditionalRoute?.accessKey || null
    
    if(accessKey){
      let newRoute=accessKey.split('.').reduce((acc, key) => (acc && acc[key]) || null, settingData)
      if(newRoute)
          rPath=newRoute
    }
    return rPath
  }

  handleAppClick(route) {
    let { tenant } = this.props
    let getAppPath=this.hadleAppRedirect(route)
    let redirectPath=route.app && route.app.path !== '/' ? '../../../' + route.app.path : '/'
    if(getAppPath && getAppPath!="")
      redirectPath=getAppPath !== '/' ? `/${getAppPath}` : '/'

    window.location.href = redirectPath || '/'
    localStorage.setItem("routeName", route.name)
  }


  render() {

    const {t}=this.props
    const checkAllowedRoutes = (route, ProductSettings1) => {
      return route.children && route.children.map(route2 => {
        if (ProductSettings1) {
          if (ProductSettings1[route2.name] && (ProductSettings1[route2.name].status || ProductSettings1[route2.name].status === 1)) {
            route2.isNav = true
            if (route2.children) {
              route2.children = checkAllowedRoutes(route2, ProductSettings1[route2.name])
            }
          }
          else if (ProductSettings1[route2.name] && (ProductSettings1[route2.name].status || ProductSettings1[route2.name].status === 0)) {
            route2.isNav = false
          }
        }
        if (route2.path?.includes('aiconsole') && this.state.disabledSources.some(source=>this.state.disabledSources.some(source=> source.title === (Object.keys(knowledge_source_map).find(key=> knowledge_source_map[key]===route2.name))))) {
          route2.isNav = false
        }
        if (route2.name == 'knowledgeBase')
          route2.children = checkAllowedRoutes(route2, ProductSettings1[route2.name])
        return route2
      })
    }

    const { identity } = this.props
    const activeRoute = this.activeRoute
    const handleClick = this.handleClick

    const { globalSetting } = this.props
    if (globalSetting) {
      const { uiFeature } = globalSetting
      let settingData =  uiFeature

      // let isContainsApproval=routeList.filter(obj => obj.path == '/approvals')
      // //console.log("************globalSetting",globalSetting)
      // if(!settingData.tickets.showRequestsManagement && isContainsApproval.length > 0){routeList.find(obj => obj.path == '/approvals').allowedRole = []}

      settingData && routeList && routeList.map(route => {
        if (settingData[route.name] && (settingData[route.name].status || settingData[route.name].status === 1)) {
          //if(settingData.tickets.showRequestsManagement){}
          route.isNav = true
          if (route.children) {
            route.children = checkAllowedRoutes(route, settingData[route.name])
          }
          if(!settingData.tickets.showRequestsManagement && route.path=='/approvals')
          {
            route.isNav=false
          }
        }
        else if (settingData[route.name] && (settingData[route.name].status || settingData[route.name].status === 0)) {
          route.isNav = false
        }

        return route
      })
    }

    // badge addon to NavItem
    const badge = (badge) => {
      if (badge) {
        const classes = classNames(badge.class)
        return (<Badge className={classes} color={badge.variant}>{badge.text}</Badge>)
      }
    }

    // simple wrapper for nav-title item
    const wrapper = item => { return (!item.wrapper ? item.name : (React.createElement(item.wrapper.element, item.wrapper.attributes, item.name))) }

    // nav list section title
    const title = (title, key) => {
      const classes = classNames("nav-title", title.class)
      return (<li key={key} className={classes}>{wrapper(t(title))} </li>)
    }

    // nav list divider
    const divider = (divider, key) => (<li key={key} className="divider"></li>)

    // nav item with nav link
    const navItem = (item, key) => {
      // const classes = classNames("nav-link", item.class);
      if (item.app && item.app.path) {
        return (
          <NavItem key={key} className="nav-item nav-dropdown">
            <NavLink1 onClick={this.handleAppClick.bind(this, item)} className={"nav-link"} href={item.path} >
            <div  className="text-span">
             <div className="sp-col-1 sp-frame">
             {
            typeof item?.icon === 'object' ? item.icon : <i className={item.icon}></i> 
          }
             </div>
             {t(item.label) || t(item.name)}{badge(item.badge)}
           </div>
            </NavLink1>
          </NavItem>
        )
      }
      return (
        <NavItem key={key} className="nav-item nav-dropdown">
          <NavLink to={item.app ? '../' + item.app.path : item.path} className={"nav-link "} activeClassName="active">
            <div  className="text-span">
            <div className="sp-col-1 sp-frame">            {
            typeof item?.icon === 'object' ? item.icon : <i className={item.icon}></i> 
          }
            </div>
            {t(item.label) || t(item.name)}{badge(item.badge)}
            </div>
          </NavLink>
        </NavItem>
      )
    }
    const filterNavLink = (children) => {
      return children.filter(item => (localTest && item.isNav) || (item.isNav && identity.roles && identity.roles.some(role => item.allowedRole && item.allowedRole.pop && item.allowedRole.includes(role))))
    }
    // nav dropdown
    const navDropdown = (item, key) => {
      let clName='nav-link'
      if(item?.className)
        clName=`${clName} ${item?.className}`
      return (
        <li key={key} className={`${activeRoute(item.path, this.props)} ${item?.className || ''}`}>
          <a className={clName} onClick={handleClick.bind(this)}>
            <div className="sp-col-1 sp-frame">  
          {
            typeof item?.icon === 'object' ? item.icon : <i className={item.icon}></i> 
          }
          {/* <i className={item.icon}></i> */}
          </div> 
          <span className="drop-text">{t(item.label) || t(item.name)}</span>
          </a>
          <ul className={`nav nav-list-items nested-child${key>1 ? '-last':''}`}>
            {!this.state.isSidebarOpen && navList(filterNavLink(item.children))}
          </ul>
        </li>)
    }

    // nav link
    const navLink = (item, idx) =>
      item.title ? title(item, idx) :
        item.divider ? divider(item, idx) :
          item.children ? navDropdown(item, idx)
            : navItem(item, idx)

            // let isContainsApproval=routeList.filter(obj => obj.path == '/approvals')
            // //console.log("************globalSetting",globalSetting)
            // if(!globalSetting.uiFeature.tickets.showRequestsManagement && isContainsApproval.length > 0){routeList.find(obj => obj.path == '/approvals').allowedRole = []}

    //pop excluded module for customer
    let navList
    let filterNav = routeList.filter(item => {
      if (identity && identity.roles)
        return item.isNav && identity.roles && identity.roles.some(role => item.allowedRole && item.allowedRole.pop && item.allowedRole.includes(role))
      else if (localTest)
        return item.isNav
      else return false
    })
    navList = (filterNav) => {
      return filterNav.map((item, index) => navLink(item, index))
    }

    const toggleSidebar = () => {
      this.setState((prevState) => ({
        isSidebarOpen: !prevState.isSidebarOpen,
      }))
      this.props.toggleSidebar()
    }

    // sidebar-nav root
    if (filterNav.length > 1 || (filterNav.length === 1 && filterNav[0].children))
      return (

        <div className="sidebar" style={{paddingTop:'10px'}} onMouseOver={this.sidebarHover.bind(this,true)}  onMouseOut={this.sidebarHover.bind(this,false)} >
         {/* <AppSidebarToggler className="d-md-down-none sidebarToggle" display="lg">
          <AiOutlineLeftCircle/>
         </AppSidebarToggler> */}
         <Flex justifyContent='flex-end' className="sticky-arrow"
         left={this.state.isSidebarOpen ? '20px' : '202px'}
         >
							<AppSidebarToggler
								className="d-md-down-none sidebarToggle" display="lg">
                              <div className='toggle-bar' onClick={toggleSidebar}>
								{this.state.isSidebarOpen ? (<MdArrowForwardIos className='arrow-forward' />) : (<MdOutlineArrowBackIosNew className='arrow-backward' />)}
                </div>
							</AppSidebarToggler>
         </Flex>

          <nav className="sidebar-nav side-menu">
            <Nav className={this.state.isSidebarOpen ? 'side-nav collapse-sidebar' : 'side-nav'}>
              {navList(filterNav)}
            </Nav>
          </nav>
        </div>

      )
    else if (filterNav.length === 1) {
      let item = filterNav[0]
      if (item.app && item.app.path) {
        window.location = `${window.location.protocol}//${window.location.hostname}/${item.app.path}`
      } else {
        history.push(item.path)
      }
      return (<></>)
    }
    else return <></>
  }
}
export default withTranslation()(Sidebar)