import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { Button } from 'reactstrap'

/**
 * Can be used to render one action or collection of actions
 * action is rendered based on action type (link, span, or button)
 * actionDef contains the name of field, action function name to invoke. Passed as params to parent prop func
 *    handleAction(actionDef, actionObj) actionDef is metadata and actionObj is instance of action containing name, label, and other properties that are custom to that action.
 */

class Action extends Component {
	constructor(props) {
		super(props)
		this.state = {
			actions: props.actions, // collection of action items
			actionMeta: props.actionMeta, // actionDef
			actionsMeta: props.actionsMeta || (props.meta && props.meta.actions),
			fieldType: (props.field && props.field.type) || 'text', // field.type
			dashboard: props.dashboard || {}, // dict of field name and field value passed from the parent.
			forceDisable: props.forceDisable, // override field level disable false to true
			forceEnable: props.forceEnable, // overrided field level disable true to false
			defDisable: props.defDisable, // the default option if not specified at field level
		}
		this.handleAction = this.handleAction.bind(this)
		this.PrepareActionUI = this.PrepareActionUI.bind(this)
	}
	componentWillMount() {
		let props = this.props
		// let formOptions;
		this.setState({
			actions: props.actions, // collection of action items
			actionMeta: props.actionMeta, // actionDef
			actionsMeta: props.actionsMeta || (props.meta && props.meta.actions),
			fieldType: (props.field && props.field.type) || 'text', // field.type
			dashboard: props.dashboard || {}, // dict of field name and field value passed from the parent.
			forceDisable: props.forceDisable, // override field level disable false to true
			forceEnable: props.forceEnable, // overrided field level disable true to false
			defDisable: props.defDisable, // the default option if not specified at field level
		})
		this.handleAction.bind(this)
	}
	componentWillReceiveProps(props) {
		let actionsMeta = this.state.actionsMeta || props.actionsMeta || (props.meta && props.meta.actions)

		this.setState({
			actions: props.actions,
			actionMeta: props.actionMeta,
			actionsMeta: actionsMeta,
			dashboard: props.resetFilter ? {} : props.dashboard, // dict of field name and field value passed from the parent.
			forceDisable: props.forceDisable, // override field level disable false to true
			forceEnable: props.forceEnable, // overrided field level disable true to false
			defDisable: props.defDisable, // the default option if not specified at field level
		})
	}

	handleAction(actionMeta, actionObj) {
		if (this.props.handleAction) this.props.handleAction({ actionMeta, actionObj, t: this.props.t })
	}

	spanAction(actionDef, actionObj) {
		// const t = this.props.t
		// let label = (actionObj && (actionObj.label || actionObj[actionDef.labelProp])) || actionDef.label
		// if(t) label = t(label)
		return (
			<span disabled={this.props.disabled} key={actionDef.name} className="al-icon-col pull-right" onClick={this.handleAction.bind(this, actionDef, actionObj)}>
				{actionDef.classes && <i className={actionDef.classes}></i>}
			</span>
		)
	}

	linkAction(actionDef, actionObj) {
		const t = this.props.t
		let label = (actionObj && (actionObj.label || actionObj[actionDef.labelProp])) || actionDef.label
		if (t && !actionDef.ignoreT) label = t(label)
		return (
			<Button
				type="button"
				disabled={this.props.disabled}
				key={actionDef.name}
				className="btn btn-link pull-right"
				onClick={this.handleAction.bind(this, actionDef, actionObj)}>
				{label}
			</Button>
		)
	}
	buttonAction(actionDef, actionObj) {
		const t = this.props.t
		let label = (actionObj && (actionObj.label || actionObj[actionDef.labelProp])) || actionDef.label
		if (t) label = t(label)
		return (
			<Button type="button" key={actionDef.name} className="min-btn pull-right" onClick={this.handleAction.bind(this, actionDef, actionObj)}>
				{label}
			</Button>
		)
	}

	PrepareActionUI(props) {
		switch (props.type) {
			case 'button':
				return this.buttonAction(props.actionMeta, props.action)
			case 'span':
				return this.buttonAction(props.actionMeta, props.action)
			case 'link':
				return this.linkAction(props.actionMeta, props.action)
			default:
				break
		}
	}
	renderAction(actionMeta, actionObj) {
		if (!actionMeta) return <></>
		let type = actionMeta.type
		if (actionObj && actionObj.pop) {
			return actionObj.map((a, i) => {
				return <this.PrepareActionUI type={type} actionMeta={actionMeta} action={a} />
			})
		} else {
			return <this.PrepareActionUI type={type} actionMeta={actionMeta} action={actionObj} />
		}
	}
	render() {
		const { actions, actionMeta, actionsMeta } = this.state
		const { dashboard } = this.props
		let actionObj
		// let actionType
		if (actionMeta) {
			actionObj = dashboard && dashboard[actionMeta.name]
			// actionType = actionMeta.type
		}
		// const { entityValues} = this.props
		return (
			<>
				{actionMeta && this.renderAction(actionMeta, actionObj)}
				{actions &&
					actions.pop &&
					actions.map(a => {
						let aDef = actionsMeta[a.name]
						let aObj = dashboard[a.name]
						return <>{aDef && this.renderAction(aDef, aObj)}</>
					})}
			</>
		)
	}
}
export default withTranslation()(Action)
