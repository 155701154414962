import React from 'react'
import { withTranslation } from 'react-i18next'
import { identityServices } from '../../common'
import { EntityComponent } from '../../common/base/entity/components/ListSearchComponent'
import { Loader } from '../../common/index'
class UserList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isSsoEnabled: false,
      isSsoFteched: false,
    }
  }
  async componentDidMount() {
    let apiUrl = sessionStorage.getItem('apiUrl')
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      url: `${apiUrl}/sso-management/idp`,
    }
    // @ts-ignore
    let ssoData = await identityServices.identityApi.request(requestOptions)
    if (ssoData?.status == 200 && ssoData?.data?.length > 0) this.setState({ isSsoEnabled: true })
    this.setState({ isSsoFteched: true })
  }
  render() {
    const { profile } = this.props
    let listMeta = this.props?.meta?.screens.list?.items?.map(f => {
      if (f.type === 'actions' && this.state.isSsoEnabled) {
        f.actions = f.actions.filter(a => a !== 'resetPassword')
      }
      if (f.type === 'actions' && !(profile.roles.includes('asc_admin') || profile.roles.includes('ASC Admin'))) {
        f.actions = f.actions.filter(a => a !== 'deleteUser')
      }
      return f
    })
     listMeta = listMeta.filter((f) => {
      if (f.name === 'Microsoft Teams Install' && !(profile.roles.includes('asc_admin') || profile.roles.includes('ASC Admin'))) {
        return false
      }
      return true
    })
    
    let newProps = { ...this.props }
    newProps.meta.screens.list.items = listMeta
    if (!this.state.isSsoFteched) return <Loader></Loader>
    return <EntityComponent {...newProps} />
  }
}
export default withTranslation()(UserList)
