import React from "react";
// import { ListGroup, ListGroupItem } from 'reactstrap';

class CustomListGroup extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            items: this.props.items || null,
        }
    }
    componentWillReceiveProps(props){
        if(props.items)
        this.setState({
            items: props.items
        })
    }
    render() {
        if( this.state.items && this.state.items.pop && this.state.items.length < 1)
            return (<></>)
        return (
            <div className="video-content">
                {
                    this.state.items && this.state.items.pop &&
                    this.state.items.map((text, i) => {
                        if(!text) return true;
                        return (
                            <p key={i}>
                                <span dangerouslySetInnerHTML={{__html: text}}></span>
                            </p>
                        )
                    })
                }
            </div>
        )
    }
}
export default CustomListGroup;

