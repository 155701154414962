import { ReportConstants } from '..'
import { ReportService } from '../'
import { alertActions } from '../..'
import { executeApi } from '../../helpers/apiHelper'
export class ReportActions {
	constructor(options) {
		this.resourceName = options.resourceName
	}
	executeApi({ tenantId, values, apiMeta, targetProp, apiUrl }) {
		let that = this
		if (!apiMeta) return
		return dispatch => {
			dispatch(that.request({ method: 'EXECUTEAPI' }))
			executeApi({ tenantId, values, apiMeta, scriptLib: null, apiUrl }).then(
				result => {
					if (result) {
						dispatch(
							// @ts-ignore
							that.success({
								method: 'EXECUTEAPI',
								item: result,
								targetProp: targetProp || (apiMeta.response && apiMeta.response.targetProp),
								data: { inputs: values },
							})
						)
					} else {
						let errorMsg = `Cannot execute api ${apiMeta && apiMeta.path}on ' + that.resourceName + ' at this moment please try again later`
						dispatch(that.failure({ method: 'EXECUTEAPI', errorMsg }))
						// dispatch(alertActions.error(errorMsg));
					}
				},
				error => {
					let errorMsg = `Cannot execute api ${apiMeta && apiMeta.path}on ' + that.resourceName + ' at this moment please try again later`
					dispatch(that.failure({ method: 'EXECUTEAPI', errorMsg }))
					// dispatch(alertActions.error(errorMsg));
				}
			)
		}
	}
	getPastList({ tenantId, filter, orderBy, skip, limit, top, t, apiUrl }) {
		let that = this
		return dispatch => {
			dispatch(that.request({ method: 'GETPASTLIST' }))

			ReportService.getPastList({ tenantId, filter, orderBy, skip, limit, top, apiUrl }).then(
				items => {
					if (items) {
						dispatch(
							// @ts-ignore
							that.success({ method: 'GETPASTLIST', item: items, data: { filter } })
						)
					} else {
						let errorMsg = 'Cannot fetch ' + that.resourceName + ' at this moment please try again later'
						dispatch(that.failure({ method: 'GETPASTLIST', errorMsg: errorMsg }))
						dispatch(alertActions.error(errorMsg))
					}
				},
				error => {
					let errorMsg = 'Cannot fetch ' + that.resourceName + ' at this moment please try again later'
					dispatch(that.failure({ method: 'GETPASTLIST', errorMsg }))
					dispatch(alertActions.error(errorMsg))
				}
			)
		}
	}
	getPastReport({ tenantId, reportType, reportId, apiUrl }) {
		// should fetch a blob
		let that = this
		return dispatch => {
			dispatch(that.request({ method: 'GETPASTREPORT' }))

			ReportService.getPastReport({ tenantId, reportType, reportId, apiUrl }).then(
				items => {
					if (items) {
						dispatch(
							// @ts-ignore
							that.success({ method: 'GETPASTREPORT', item: items, data: { reportId } })
						)
					} else {
						let errorMsg = 'Cannot fetch ' + that.resourceName + ' at this moment please try again later'
						dispatch(that.failure({ method: 'GETPASTREPORT', errorMsg: errorMsg }))
						dispatch(alertActions.error(errorMsg))
					}
				},
				error => {
					let errorMsg = 'Cannot fetch ' + that.resourceName + ' at this moment please try again later'
					dispatch(that.failure({ method: 'GETPASTREPORT', errorMsg }))
					dispatch(alertActions.error(errorMsg))
				}
			)
		}
	}
	getAllMeta({ tenantId, filter, orderBy, skip, limit, top }) {
		let that = this
		return dispatch => {
			dispatch(that.request({ method: 'GETALLMETA' }))

			ReportService.getAllMeta({ tenantId, filter, orderBy, skip, limit, top }).then(
				items => {
					if (items) {
						dispatch(
							// @ts-ignore
							that.success({ method: 'GETALLMETA', item: items, data: { filter } })
						)
					} else {
						let errorMsg = 'Cannot fetch ' + that.resourceName + ' at this moment please try again later'
						dispatch(that.failure({ method: 'GETALLMETA', errorMsg: errorMsg }))
						dispatch(alertActions.error(errorMsg))
					}
				},
				error => {
					let errorMsg = 'Cannot fetch ' + that.resourceName + ' at this moment please try again later'
					dispatch(that.failure({ method: 'GETALLMETA', errorMsg }))
					dispatch(alertActions.error(errorMsg))
				}
			)
		}
	}
	saveEmailReport({ tenantId, reportType, reportData, reportMeta, actionType }) {
		// should fetch a blob
		let that = this
		return dispatch => {
			dispatch(that.request({ method: 'SAVEEMAIL' }))

			ReportService.saveEmailReport({ tenantId, reportType, reportData, reportMeta, actionType }).then(
				items => {
					if (items) {
						dispatch(
							// @ts-ignore
							that.success({ method: 'SAVEEMAIL', item: items, data: { type: actionType } })
						)
					} else {
						let errorMsg = `Cannot perform action ${actionType} on ${that.resourceName}  at this moment please try again later`
						dispatch(that.failure({ method: 'SAVEEMAIL', errorMsg: errorMsg }))
						dispatch(alertActions.error(errorMsg))
					}
				},
				error => {
					let errorMsg = `Cannot perform action ${actionType} on ${that.resourceName}  at this moment please try again later`
					dispatch(that.failure({ method: 'SAVEEMAIL', errorMsg }))
					dispatch(alertActions.error(errorMsg))
				}
			)
		}
	}

	getSchedules({ tenantId, reportType, filter, orderBy, skip, limit, top }) {
		// should fetch a blob
		let that = this
		return dispatch => {
			dispatch(that.request({ method: 'GETSCHEDULESLIST' }))

			ReportService.getSchedules({ tenantId, reportType, filter, orderBy, skip, limit, top }).then(
				items => {
					if (items) {
						dispatch(
							// @ts-ignore
							that.success({ method: 'GETSCHEDULESLIST', item: items, data: { filter } })
						)
					} else {
						let errorMsg = 'Cannot fetch ' + that.resourceName + ' at this moment please try again later'
						dispatch(that.failure({ method: 'GETSCHEDULESLIST', errorMsg: errorMsg }))
						dispatch(alertActions.error(errorMsg))
					}
				},
				error => {
					let errorMsg = 'Cannot fetch ' + that.resourceName + ' at this moment please try again later'
					dispatch(that.failure({ method: 'GETSCHEDULESLIST', errorMsg }))
					dispatch(alertActions.error(errorMsg))
				}
			)
		}
	}
	addSchedule({ tenantId, reportType, scheduleData }) {
		// should fetch a blob
		let that = this
		return dispatch => {
			dispatch(that.request({ method: 'ADDSCHEDULE' }))

			ReportService.addSchedule({ tenantId, reportType, scheduleData }).then(
				items => {
					if (items) {
						dispatch(
							// @ts-ignore
							that.success({ method: 'ADDSCHEDULE', item: items, data: { scheduleData } })
						)
					} else {
						let errorMsg = 'Cannot fetch ' + that.resourceName + ' at this moment please try again later'
						dispatch(that.failure({ method: 'ADDSCHEDULE', errorMsg: errorMsg }))
						dispatch(alertActions.error(errorMsg))
					}
				},
				error => {
					let errorMsg = 'Cannot fetch ' + that.resourceName + ' at this moment please try again later'
					dispatch(that.failure({ method: 'ADDSCHEDULE', errorMsg }))
					dispatch(alertActions.error(errorMsg))
				}
			)
		}
	}
	updateSchedule({ tenantId, reportType, scheduleData }) {
		// should fetch a blob
		let that = this
		return dispatch => {
			dispatch(that.request({ method: 'UPDATESCHEDULE' }))

			ReportService.updateSchedule({ tenantId, reportType, scheduleData }).then(
				items => {
					if (items) {
						dispatch(
							// @ts-ignore
							that.success({ method: 'UPDATESCHEDULE', item: items, data: { scheduleData } })
						)
					} else {
						let errorMsg = 'Cannot fetch ' + that.resourceName + ' at this moment please try again later'
						dispatch(that.failure({ method: 'UPDATESCHEDULE', errorMsg: errorMsg }))
						dispatch(alertActions.error(errorMsg))
					}
				},
				error => {
					let errorMsg = 'Cannot fetch ' + that.resourceName + ' at this moment please try again later'
					dispatch(that.failure({ method: 'UPDATESCHEDULE', errorMsg }))
					dispatch(alertActions.error(errorMsg))
				}
			)
		}
	}
	request({ method }) {
		let type
		switch (method) {
			case 'EXECUTEAPI':
				type = ReportConstants.EXECUTEAPI_REQUEST
				break
			case 'GETALLMETA':
				type = ReportConstants.GETALLMETA_REQUEST
				break
			case 'GETPASTREPORT':
				type = ReportConstants.GETPASTREPORT_REQUEST
				break
			case 'GETPASTLIST':
				type = ReportConstants.GETPASTLIST_REQUEST
				break
			case 'GETSCHEDULESLIST':
				type = ReportConstants.GETSCHEDULESLIST_REQUEST
				break
			case 'ADDSCHEDULE':
				type = ReportConstants.ADDSCHEDULE_REQUEST
				break
			case 'UPDATESCHEDULE':
				type = ReportConstants.UPDATESCHEDULE_REQUEST
				break
			case 'SAVEEMAIL':
				type = ReportConstants.SAVEEMAIL_REQUEST
				break
			default:
		}
		type = type + '_' + this.resourceName
		return { type: type, resourceName: this.resourceName }
	}
	success({ method, item, itemType, targetProp, data }) {
		let type
		switch (method) {
			case 'EXECUTEAPI':
				type = ReportConstants.EXECUTEAPI_SUCCESS
				break
			case 'GETALLMETA':
				type = ReportConstants.GETALLMETA_SUCCESS
				break
			case 'GETPASTREPORT':
				type = ReportConstants.GETPASTREPORT_SUCCESS
				break
			case 'GETPASTLIST':
				type = ReportConstants.GETPASTLIST_SUCCESS
				break
			case 'GETSCHEDULESLIST':
				type = ReportConstants.GETSCHEDULESLIST_SUCCESS
				break
			case 'ADDSCHEDULE':
				type = ReportConstants.ADDSCHEDULE_SUCCESS
				break
			case 'UPDATESCHEDULE':
				type = ReportConstants.UPDATESCHEDULE_SUCCESS
				break
			case 'SAVEEMAIL':
				type = ReportConstants.SAVEEMAIL_SUCCESS
				break
			default:
		}
		type = type + '_' + this.resourceName
		return { type, item, resourceName: this.resourceName, itemType, data, targetProp }
	}
	failure({ method, errorMsg }) {
		let type
		switch (method) {
			case 'EXECUTEAPI':
				type = ReportConstants.EXECUTEAPI_FAILURE
				break
			default:
			case 'GETALLMETA':
				type = ReportConstants.GETALLMETA_FAILURE
				break
			case 'GETPASTREPORT':
				type = ReportConstants.GETPASTREPORT_FAILURE
				break
			case 'GETPASTLIST':
				type = ReportConstants.GETPASTLIST_FAILURE
				break
			case 'GETSCHEDULESLIST':
				type = ReportConstants.GETSCHEDULESLIST_FAILURE
				break
			case 'ADDSCHEDULE':
				type = ReportConstants.ADDSCHEDULE_FAILURE
				break
			case 'UPDATESCHEDULE':
				type = ReportConstants.UPDATESCHEDULE_FAILURE
				break
			case 'SAVEEMAIL':
				type = ReportConstants.SAVEEMAIL_FAILURE
				break
		}
		type = type + '_' + this.resourceName
		console.error(`${type}:${errorMsg}`)
		return { type, error: errorMsg, resourceName: this.resourceName }
	}
	getFns() {
		return {
			executeApi: this.executeApi.bind(this),
		}
	}
}
