import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Redirect, Route } from 'react-router-dom'
import { alertActions, identityActions, MainLayout, profileServices } from '../'
import { localTest } from '../../../config'
import { localeLoader } from '../../../redux/routes'
import ErrorBoundary from '../components/ErrorBoundary'
class PrivateRoute extends Component {
	constructor(props) {
		super(props)
		this.state = {
			allowRoute: true,
			fallBackPath: null,
			isLoggedIn: false,
		}
	}
	componentDidMount() {
		const { globalSetting, identity, tenant, allowAccess, path } = this.props
		allowAccess &&
			allowAccess(globalSetting, identity, tenant, profileServices).then(allowRoute => {
				const { access, fallBackPath } = allowRoute
				allowRoute && this.setState({ allowRoute: access, fallBackPath })
			})
	}
	componentWillMount() {
		const { identity, path, tenant, t } = this.props
		let { profile } = identity
		if (!localTest && !this.state.isLoggedIn) {
			this.props.checkIdentity(t)
			this.setState({ isLoggedIn: true })
		} // comment for testing private routes without login
		if (window.EmbeddableWidget && window.EmbeddableWidget.el) window.EmbeddableWidget.unmount()
		if (localeLoader && path) {
			let tenantId = null
			if (profile) tenantId = profile.tenantUid
			else if (tenant) tenantId = tenant.id
			localeLoader(path, tenantId)
		}
	}

	render() {
		const {
			tenant,
			identity,
			allowedRole,
			path,
			isNav,
			exact,
			component: Component,
			moduleName,
			computedMatch: { params },
			baseResource,
			baseUrl,
			routeProps,
			name,
			t,
			newAppRoute,
			globalSetting
		} = this.props
		const { allowRoute, fallBackPath } = this.state

		if (tenant.loading && !identity.isAuthenticated && identity.isInititated) return <div className="loader"></div>
		let isAllowedRoute
		if (identity.roles && identity.roles.some(role => allowedRole && allowedRole.pop && allowedRole.includes(role))) isAllowedRoute = true
		if (localTest) isAllowedRoute = true
		if (path === '/home') {
			return <Route path={path} exact={exact} render={() => <></>} />
		} else if (identity.isAuthenticated && (!identity.roles || (identity.roles && identity.roles.length === 0))) {
			return <h3 className="text-danger text-center">{t('Please contact your administrator')}.</h3>
		}
		else if (identity.isAuthenticated) {
			if( identity.isAuthenticated && !globalSetting.uiFeature?.tickets?.showRequestsManagement && path.includes('/approvals')) isAllowedRoute=false
			if (!allowRoute && fallBackPath) return <Redirect to={{ pathname: fallBackPath }} />
			else
				return (
					<ErrorBoundary>
						<Route
							path={path}
							exact={exact}
							render={() => (
								<MainLayout location={this.props.location} routeName={name} newAppRoute={newAppRoute}>
									{isAllowedRoute ? (
										<Component
											location={this.props.location}
											moduleName={moduleName}
											baseResource={baseResource}
											baseUrl={baseUrl}
											params={params}
											routeProps={routeProps}
										/>
									) : !isNav ? (
										<h3 className="text-danger text-center">{t('Please contact your administrator')}.</h3>
									) : (
										<h3 className="text-danger text-center">{t('Sorry! you are not allowed to access this page')}.</h3>
									)}
								</MainLayout>
							)}
						/>
					</ErrorBoundary>
				)
		} else {
			return <></>
		}
	}
}

const mapStateToProps = state => {
	const { identity, tenant, globalSetting } = state
	return {
		identity,
		tenant,
		globalSetting,
	}
}

const mapDispatchToProps = dispatch => ({
	checkIdentity: () => dispatch(identityActions.checkIdentity()),
	alertError: msg => dispatch(alertActions.error(msg)),
})
// const MyComponent = withTranslation()(PrivateRoute)

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(PrivateRoute))
