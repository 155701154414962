import Joi from 'joi-browser'

const intentBot = Joi.object().keys({
    id: Joi.number().integer().positive(),
    tenantId: Joi.string().uuid(),
    botId: Joi.string().allow(null),
    issueType :  Joi.string().required(),
    skill :  Joi.string().allow('',null),
    subjectArea :  Joi.string(),
    intentName :  Joi.string().allow('', null),
    conditionsName :  Joi.string(),
    conditions : Joi.string(),
    priority: Joi.number().integer().allow(null),
    ticketCategory : Joi.string().allow('', null),
    ticketSubCategory : Joi.string().allow('', null),
    assignmentGroup : Joi.string().allow('', null),
    ticketObj : Joi.string().required(),
    createdBy: Joi.string().allow('', null),
    createdAt : Joi.date().allow(null),
    updatedBy: Joi.string().allow('', null),
    updatedAt : Joi.date().allow(null),
    status: Joi.string().allow(null),
    deletedSeq :Joi.number().integer().allow(null),
    luisname: Joi.string().allow(null),
    classificationItem: Joi.string().allow(null),
    resolutionNotes: Joi.string().allow(null),
    BotConfig: Joi.any().allow(null),
})
// These are the schemas of the return object from the api server
export const methodSchemas = {
    getAll: Joi.array().items(intentBot),
    getOne: intentBot,
    addItem: intentBot,
    deleteItem: Joi.object().keys({id: Joi.number().integer().positive()}),
    editItem: intentBot
}
export const collectionSchemas = {
  //  notes: note
}
export const entitySchema = intentBot