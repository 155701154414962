import React, { useEffect, useState } from 'react'
import { Badge } from 'reactstrap'
import { CustomView } from '..'
const CustomFieldView = props => {
	const [field, setField] = useState(props.field || null)
	const [value, setValue] = useState(props.cellData || [])
	useEffect(() => {
		props.field && setField(props.field)
		props.cellData && setValue(props.cellData)
	}, [props])

	const switchAddComponent = () => {
		switch (field.component) {
			case 'Categories':
				return <Category data={value} />

			case 'Tags':
				return <Tags data={value} />

			default:
				return <CustomView data={value} />
		}
	}

	return switchAddComponent()
}

export default CustomFieldView

const Category = props => {
	return (
		<span>
			{props &&
				props.data &&
				props.data.length > 0 &&
				props.data.map((item, idx) => {
					return (
						<Badge key={idx} style={{ margin: '1px' }} href="#" color="primary">
							{item.value}
						</Badge>
					)
				})}
		</span>
	)
}

const Tags = props => {
	return (
		<span>
			{props &&
				props.data &&
				props.data.length > 0 &&
				props.data.map((item, idx) => {
					return (
						<Badge key={idx} style={{ margin: '1px' }} href="#" color="primary">
							{props.t(item.value)}
						</Badge>
					)
				})}
		</span>
	)
}
