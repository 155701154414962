import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Avatar, SkeletonCircle,
    SkeletonText,Box, Button, Drawer, DrawerContent, DrawerHeader, DrawerOverlay, Flex, Icon, Link, Portal, Switch, Tab, TabList, Tabs, Text, Tooltip, useColorModeValue, useDisclosure } from '@chakra-ui/react'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { BiBell, BiCheckCircle, BiChevronLeftCircle } from 'react-icons/bi'
import { FaFileExcel, FaFileImage, FaFileWord, FaFilePdf, FaRegCheckCircle } from 'react-icons/fa'
import { BiChevronDown, BiChevronUp } from 'react-icons/bi' 
import { identityServices } from '..'
import { formatDistanceToNow } from 'date-fns'
import { RxCrossCircled, RxCross1 } from 'react-icons/rx'
import { MdOutlineNotificationsOff } from 'react-icons/md'
// import { useHistory, NavLink } from 'react-router-dom'
import { history } from '../../../redux/history'
import moment from 'moment'
import _ from 'lodash'
import ReadMoreLess from './ReadMoreLess'
const Notification = (props) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    let [ticksCount,setTicksCount] = useState(0)
    let [liveChatCount,setLiveChatCount] = useState(0)
    const [expandedItems, setExpandedItems] = useState(new Set())
    const [deletedItems, setDeletedItems] = useState([]) 
    const [unreadNotificationsCount,setUnreadNotificationsCount] = useState(0)
    const listRef = useRef(null)
    const [isFetching,setIsFetching] = useState(false)
    const NotificationTabs = [
        {
            name: 'allActivities',
            label: 'All Activities'
        },
        {
            name: 'tickets',
            label: 'Tickets',
            filterType: 'Tickets'
        },
        {
            name: 'missedChat',
            label: 'Live Chat',
            filterType: 'LiveChat'
        }
    ]
    const tabBgColor = useColorModeValue('gray.100', '#2b313c')
    const bgColor = useColorModeValue('white', '#3b4453')
    const cardBgColor = useColorModeValue('white', '#273141')
    const [filterType, setFilterType] = useState('')
    const [notificationList, setNotificationList] = useState([])
    const [notificationData,setNotificationData] = useState([])
    const [top, setTop] = useState(50)
    const [skip, setSkip] = useState(0)
    const { email } = props.profile
    let localShowUnread = localStorage.getItem('showUnreadNotifications')
    const [showUnread, setShowUnread] = useState(localShowUnread=='true' ? true : false)
    const onShowReadToggle = useCallback((e)=>{
        localStorage.setItem('showUnreadNotifications', e.target.checked)
        setShowUnread(e.target.checked)
    }, [showUnread])
    let apiUrl = sessionStorage.getItem('apiUrl')
    const refetchCounts = useCallback(() => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            url: `${apiUrl}/ticketing/NotificationLogView/count?$filter=notifiedTo eq '${email}' and status ne 'Deleted' and type eq 'Tickets'${showUnread ? ` and status ne 'Read'` : ''}`,
        }
        const requestOptions1 = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            url: `${apiUrl}/ticketing/NotificationLogView/count?$filter=notifiedTo eq '${email}' and status ne 'Deleted' and type eq 'LiveChat'${showUnread ? ` and status ne 'Read'` : ''}`,
        }
        identityServices.identityApi.request(requestOptions).then(res=>setTicksCount(res.data)).catch()
        identityServices.identityApi.request(requestOptions1).then(res=>setLiveChatCount(res.data)).catch()
    },[showUnread])
    const notificationCount = () =>{
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                clientApp:'tickets',
                useCase:'james'
            },
            url: `${apiUrl}/ticketing/NotificationLogView/count?$filter=notifiedTo eq '${email}' and status eq 'Created'`,
        }
        identityServices.identityApi.request(requestOptions).then(res=>setUnreadNotificationsCount(res.data)).catch()
    }
    
    useEffect(()=>{
        refetchCounts()
        notificationCount()
        const requestOptions2 = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                clientApp:'tickets',
                useCase:'james'
            },
            url: `${apiUrl}/ticketing/NotificationLogView/findAndCountAll?$filter=notifiedTo eq '${email}' and status ne 'Deleted'${!!filterType ? ` and type eq '${filterType}'` : ''}${showUnread ? ` and status ne 'Read'` : ''}&$orderby=notifiedAt desc&$top=50&$skip=0`,
        }
        identityServices.identityApi.request(requestOptions2).then(res=>{if(res.data.rows){
            setNotificationData(res.data.rows)
            const items = groupNotificationByParams({
                    data: res?.data?.rows, 
                    groupingParams: ['groupId', 'type'], 
                    deletedItems,
                    expandedItems
                })
            setNotificationList(items)
        }}).catch()
    },[showUnread])
    useEffect(()=>{
        const requestOptions2 = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                clientApp:'tickets',
                useCase:'james'
            },
            url: `${apiUrl}/ticketing/NotificationLogView/findAndCountAll?$filter=notifiedTo eq '${email}' and status ne 'Deleted'${!!filterType ? ` and type eq '${filterType}'` : ''}${showUnread ? ` and status ne 'Read'` : ''}&$orderby=notifiedAt desc&$top=50&$skip=0`,
        }
        setIsFetching(true)
        setNotificationList([])
        identityServices.identityApi.request(requestOptions2).then(res=>{if(res.data.rows){
            setIsFetching(false)
            const items = groupNotificationByParams({
                    data: res?.data?.rows, 
                    groupingParams: ['groupId', 'type'], 
                    deletedItems,
                    expandedItems
                })
            setNotificationList(items)
            refetchCounts()
        }}).catch()
    },[filterType,showUnread])
    useEffect(()=>{
    },[notificationList])
    
    const handleOpen = () => {
        onOpen()
    }
    const allAccordionIndices = useMemo(()=>{
        return Array.from({length: 100}, (_, index)=> index)
    }, [])
    const handleExpandNotifications = useCallback((pKey, cKey)=>{
        setNotificationList(prevData => {
            return prevData.map(item => {
              if (item.date === pKey) {
                return {
                  ...item,
                  data: item.data.map(childItem => {
                    if (childItem.key === cKey) {
                        !childItem.isExpanded ? setExpandedItems(prev=>prev.add(cKey)) : 
                        setExpandedItems(prev=>{
                            prev.delete(cKey)
                            return prev    
                        })
                        return { ...childItem, isExpanded: !childItem.isExpanded }
                    }
                    return childItem
                  }),
                }
              }
              return item
            })
        })
    }, [notificationList, expandedItems])
    const groupNotificationByParams = ({data, groupingParams, expandedItems, deletedItems}) => {
        if(!data || !Array.isArray(data) || _.isEmpty(data)) return []
        const groupedItems = {}
        _.map(data, elem => {
                if(!deletedItems.includes(elem.id)){
                    const key = groupingParams.map(param => elem[param]).join('-')
                    if (!groupedItems[key]) {
                        groupedItems[key] = {
                            groupId: elem.groupId,
                            user: elem.properties?.actionBy || elem?.properties?.user_full_name,
                            data: [],
                            key,
                            isExpanded: expandedItems.has(key) ? true : false
                        }
                    }
                    if(!groupedItems[key].latestNotification || 
                        new Date(groupedItems[key].latestNotification.notifiedAt) < new Date(elem.notifiedAt)
                    ){
                        groupedItems[key].latestNotification = elem
                        groupedItems[key].latestNotifiedAt = (new Date(elem.notifiedAt)).toISOString().split('T')[0]
                    }
                    groupedItems[key].data.push(elem)
                }
            
        })
        let obj={}
        let currentDate = new Date()
        let items = Object.values(groupedItems)
        let yesterday = new Date(currentDate)
        yesterday.setDate(currentDate.getDate() - 1)
        items && Array.isArray(items) &&
        items.forEach(item=>{
            if(!obj[item.latestNotifiedAt]){
                obj[item.latestNotifiedAt]={
                    date: currentDate.toISOString().split('T')[0] === item.latestNotifiedAt ? 'Today' : 
                        yesterday.toISOString().split('T')[0] === item.latestNotifiedAt ? 'Yesterday'
                    :
                    moment(item.latestNotifiedAt).format('DD MMMM'),
                    data: []
                }
            }
            obj[item.latestNotifiedAt].data.push(item)
        })
        return Object.values(obj)
    }
    
    const handleRemove = useCallback(id=>{
        setDeletedItems(prev=>{
            return _.uniq([...prev, id])
        })
        const requestOptions = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                clientApp: 'tickets',
                useCase: 'james',
                itemType: 'default'
            },
            url: `${apiUrl}/ticketing/NotificationLog/bulkUpdateByIds`,
            data: {
                value: 'Deleted',
                property: 'status',
                notificationIds: [id]
            }
        };
        //await identityServices.identityApi.request(requestOptions);
        identityServices.identityApi.request(requestOptions).then((res)=>{
            refetchCounts()
            refetchNotifications()
            notificationCount()
        }).catch(error => {console.log (error)})
    }, [deletedItems,filterType])
    
    const bulkDeleteNotifications = useCallback(async (item) => {
            let ids = item?.data?.map(n => n.id);
            ids && !_.isEmpty(ids) && setDeletedItems(prev => { 
                return [...prev, ...ids]
                });
           
                const requestOptions = {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        clientApp: 'tickets',
                        useCase: 'james',
                        itemType: 'default'
                    },
                    url: `${apiUrl}/ticketing/NotificationLog/bulkUpdateByIds`,
                    data: {
                        value: 'Deleted',
                        property: 'status',
                        notificationIds: ids
                    }
                };
                //await identityServices.identityApi.request(requestOptions);
                identityServices.identityApi.request(requestOptions).then((res)=>{
                    refetchCounts()
                    refetchNotifications()
                    notificationCount()
                }).catch(error => {console.log (error)})
    }, [deletedItems,filterType]);
    
    
    const refetchNotifications = useCallback(() => {
        const requestOptions2 = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                clientApp:'tickets',
                useCase:'james'
            },
            url: `${apiUrl}/ticketing/NotificationLogView/findAndCountAll?$filter=notifiedTo eq '${email}' and status ne 'Deleted'${!!filterType ? ` and type eq '${filterType}'` : ''}${showUnread ? ` and status ne 'Read'` : ''}&$orderby=notifiedAt desc&$top=50&$skip=0`,
        }
        identityServices.identityApi.request(requestOptions2).then(res=>{if(res.data.rows){
            setNotificationData(res.data.rows)
            const items = groupNotificationByParams({
                    data: res?.data?.rows, 
                    groupingParams: ['groupId', 'type'], 
                    deletedItems,
                    expandedItems
                })
            setNotificationList(items)
        }}).catch()
    },[showUnread,filterType])
    const onScroll = useCallback(() => {
        if (listRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = listRef.current
            let st = scrollTop
            // let st = scrollTop + clientHeight;
            if (st + clientHeight === scrollHeight) {
                // onListScroll && onListScroll(true)
                setSkip(prevSkip => prevSkip + 50); // Increment skip value
                setTop(50); // Reset top value
            }
            // if (st >= scrollHeight) {
            //     setSkip(prevSkip => prevSkip + 50); // Increment skip value
            //     setTop(50); // Reset top value
            // }
        }
    }, [])
    useEffect(()=>{
        if( skip > 50){
        const requestOptions2 = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                clientApp:'tickets',
                useCase:'james'
            },
            url: `${apiUrl}/ticketing/NotificationLogView/findAndCountAll?$filter=notifiedTo eq '${email}' and status ne 'Deleted'${!!filterType ? ` and type eq '${filterType}'` : ''}${showUnread ? ` and status ne 'Read'` : ''}&$orderby=notifiedAt desc&$top=${top}&$skip=${skip}`,
        }
        setIsFetching(true)
        identityServices.identityApi.request(requestOptions2).then(res=>{if(res.data.rows){
            setIsFetching(false)
            setNotificationData(res.data.rows)
            const items = groupNotificationByParams({
                    data: res?.data?.rows, 
                    groupingParams: ['groupId', 'type'], 
                    deletedItems,
                    expandedItems
                })
            if(items) setNotificationList(prevList => [...prevList, ...items])
        }}).catch()
    }
    },[top,skip])
    const countConfig = useMemo(()=>{
        return {
            Tickets: `${ticksCount || 0}`,
            LiveChat: `${liveChatCount || 0}`
        }
    }, [ticksCount,liveChatCount])
    const markAllAsRead = useCallback(async ()=>{
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                clientApp:'tickets',
                useCase:'james',
                itemType:'default'
            },
            url: `${apiUrl}/NotificationLog/markAllAsRead`,
        }
        const requestOptions1 = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                clientApp:'tickets',
                useCase:'james',
                itemType:'default'
            },
            url: `${apiUrl}/ticketing/NotificationLog/markAllAsRead`,
        }   
        identityServices.identityApi.request(requestOptions).then((res)=>{
            refetchCounts()
            refetchNotifications()
            notificationCount()
        }).catch()
        identityServices.identityApi.request(requestOptions1).then((res)=>{
            refetchCounts()
            refetchNotifications()
            notificationCount()
        }).catch()
    }, [showUnread,filterType])
  return (
    <>
        <Box height={'30px'} display={'flex'} alignItems='self-start' alignSelf='flex-end'>
        <Button _focus={{boxShadow:'none'}} _hover={{bgColor: 'none'}} position='relative' bg='none'  size='xs' p='0' onClick={handleOpen}>
                <BiBell fontSize='24px' color='#6c7280' />
                {!!unreadNotificationsCount && unreadNotificationsCount!==0 && (
                    <Text color='white' fontSize='x-small' bgColor='red' px='3px' borderRadius='full' top='-1px' left='12px' position='absolute' as='span'>
                        {unreadNotificationsCount > 99 ? '99+': unreadNotificationsCount}
                    </Text>
                )}
            </Button>
        </Box>
        <Portal>
            <Drawer
                isOpen={isOpen}
                placement='right'
                onClose={() => {
                    onClose();
                    setFilterType(''); 
                }}
                // finalFocusRef={btnRef}
                size='lg'
                // width={'100%'}
            >
                <DrawerOverlay />
                <DrawerContent borderRadius='4px' className='notification-card-div'>
                    <DrawerHeader padding='8px' display='flex' justifyContent='space-between'>
                        <Flex alignItems='center'>
                            <Button size='xs' bg='none' border={'none'} onClick={onClose} _hover={{bg: 'none'}} _focus={{boxShadow: 'none'}} >
                                <BiChevronLeftCircle color='#1DA1F2' size='20' />
                            </Button>
                            <Text fontSize='14px'>{'Notifications'}</Text>
                        </Flex>
                        <Flex gap='2' display={'flex'} justifyContent={'center'}>
                            <Flex display={'flex'} gap='1.5' alignItems={'center'}>
                                <Box alignItems={'center'}>
                                    <Text color='gray.500' fontSize='x-small'>{'Show unread only'}</Text>
                                </Box>
                                <Box>
                                    <Switch isChecked={showUnread} onChange={onShowReadToggle} size='sm' />
                                </Box>
                            </Flex>
                            <Flex alignItems={'center'}>
                                <Button onClick={markAllAsRead} color='gray.500' leftIcon={<BiCheckCircle fontSize='15px' />} size='xs' bg='none' pr='2'>
                                    <Text fontSize='x-small'>{'Mark all as read'}</Text>
                                </Button>
                            </Flex>
                        </Flex>
                    </DrawerHeader>
                    <Tabs padding={'16px 4px 16px 24px'}  >
                        <TabList className='custom-tabs' maxH='40px' h='40px' padding={'6px'} bgColor={tabBgColor} border={0} borderRadius='6px' w='100%'>
                            {NotificationTabs.map(tab=>(
                                <Tab fontSize='12px' key={tab.name} onClick={()=>{
                                        setFilterType(tab.filterType || '')
                                        // refetchTabCount[tab.filterType] && refetchTabCount[tab.filterType]()
                                    }} _selected={{bg: `${bgColor}`, boxShadow: 'md', color: `${'gray.500'}`}} color='gray.500' borderRadius='md' bgColor='transparent'>
                                    <Flex justifyContent='space-between' alignItems='center' p={2}>
                                        <Text fontSize='12px' textAlign={'center'}>{tab.label}</Text>
                                        {countConfig && countConfig[tab.filterType] && 
                                            <Text ml='2' px='2' borderRadius='full' bg={tabBgColor} fontSize='xs' as='span'>{countConfig[tab.filterType]}</Text>
                                        }
                                    </Flex>
                                </Tab>
                            ))}
                        </TabList>
                        <Flex 
                            ref={listRef}
                            h='82vh'
                            className='divScroll' 
                            onScroll={onScroll}
                            px='0' 
                            mt='4'
                            gap='2'
                            flexDir='column' 
                            overflowY='scroll' 
                        >
                            <Accordion defaultIndex={allAccordionIndices} allowMultiple >
                            {notificationList && 
                                notificationList.length > 0 &&
                                notificationList.map((listItems, idx)=>(
                                    <AccordionItem key={idx} border='none'>
                                        <Flex zIndex='99999' position='sticky' top='0' bgColor={ 'white'} pr='6' justifyContent='space-between' alignItems='center'>
                                            <Text fontSize='14px' className='date-text' pb='16px' style={{color:'#1a202c !important'}} fontWeight='600'>{listItems.date}</Text>
                                            <AccordionButton w='fit-content' p='0' border='1px' borderRadius='full' borderColor='gray.300' color='gray.500' size='sm'>
                                                <AccordionIcon fontSize='20px' />
                                            </AccordionButton>
                                        </Flex>
                                        <AccordionPanel pl='0' pr='16px' pt='8px' pb='20px' >
                                            {listItems.data && Array.isArray(listItems.data) && 
                                                listItems.data.map(item=>(
                                                <Flex key={item.key} pl='4' ml='3' borderLeft='1px solid' borderColor='gray.300' flexDir='column'>
                                                    <Flex>
                                                        <Avatar 
                                                            name={typeof(item?.user)=='string' ? item?.user : `${item?.user?.firstName} ${item?.user?.lastName}`}
                                                            bgColor='#2EC1C5' 
                                                            color='#ffff' 
                                                            size='xs' 
                                                            width='24px'
                                                            height='24px'
                                                        />
                                                        <Flex ms='2' w='full' pr={!item?.isExpanded && '3'} justifyContent='space-between'>
                                                            <Flex flexDir='column' >
                                                                <Flex fontSize='12px'>
                                                                    <Text color={'#2563EB'} fontWeight='bold'>
                                                                        {typeof(item?.user)=='string' ? item?.user : `${item?.user?.firstName} ${item?.user?.lastName}`}
                                                                    </Text>
                                                                    <Text ms='1' className='notification-subject-text'>
                                                                        {item?.latestNotification?.properties?.notificationInfo?.subject}
                                                                    </Text>
                                                                </Flex>
                                                                <Text fontSize='10px' color={'gray.500'}>{formatDistanceToNow(new Date(item?.latestNotification?.notifiedAt), { addSuffix: true })}</Text>
                                                            </Flex>
                                                            {item?.data?.length > 1 && (
                                                                <Flex>
                                                                    <Button
                                                                        onClick={()=>handleExpandNotifications(listItems.date, item?.key)}
                                                                        leftIcon={item.isExpanded ? 
                                                                            <BiChevronUp fontSize='14px' style={{marginRight:'-.3rem'}} /> : 
                                                                            <BiChevronDown fontSize='14px' style={{marginRight:'-.3rem'}} />
                                                                        } 
                                                                        mb='2'
                                                                        borderRadius='xl' 
                                                                        color='gray.500' 
                                                                        variant='outline' 
                                                                        size='xs' 
                                                                        fontSize='x-small'
                                                                        _focus={{outline: 'none',boxShadow: 'none'}}
                                                                    >
                                                                        {item.isExpanded ? 'Show less' : 'Show more' }
                                                                    </Button>
                                                                    {item.isExpanded && (
                                                                        <Button onClick={() => {
                                                                            bulkDeleteNotifications(item)}}                                                                         
                                                                        mt='0.2' bg='none' _hover={{bg:'none'}} color='gray.500' size='xs'>
                                                                            <RxCrossCircled fontSize='21px' />
                                                                        </Button>
                                                                    )}
                                                                </Flex>
                                                            )}
                                                        </Flex>
                                                    </Flex>
                                                    <Flex padding='12px' mt={item?.data?.length>1 && '12px'} flexDir='column' position='relative' minH='160px' border={0} gap='8px' >
                                                        {item.data && 
                                                            Array.isArray(item.data) &&
                                                            item.data.map((notification, idx)=>(
                                                                <NotificationItem 
                                                                    key={idx} 
                                                                    index={idx} 
                                                                    item={item}
                                                                    // theme={theme}
                                                                    onClose={onClose}
                                                                    cardBgColor={cardBgColor} 
                                                                    notification={notification} 
                                                                    listItems={listItems}
                                                                    handleRemove={handleRemove}
                                                                    refetchCounts={refetchCounts}
                                                                    // refetchCounts={()=>{
                                                                    //     refetchChatsCount()
                                                                    //     refetchTicsCount()
                                                                    // }}
                                                                    notificationCount={notificationCount}
                                                                    refetchNotifications={refetchNotifications}
                                                                    showUnread={showUnread}
                                                                    handleExpandNotifications={handleExpandNotifications}
                                                                    // t={t}
                                                                />
                                                            )
                                                        )}
                                                    </Flex>
                                                </Flex>
                                            ))}
                                        </AccordionPanel>
                                    </AccordionItem>
                                )
                            )}
                            </Accordion>
                            {isFetching && (
                            <Box padding='6' borderRadius='md' boxShadow='lg'>
                                <SkeletonCircle size='8' />
                                <SkeletonText mt='4' noOfLines={3} spacing='4' skeletonHeight='2' />
                            </Box>
                            )}
                            {
                                !isFetching && 
                                notificationList && 
                                (!notificationList.length || !notificationList) && (
                                    <Flex flexDir='column' gap='6' color='gray.500' h='60%' justifyContent='center' alignItems='center'>
                                        <MdOutlineNotificationsOff style={{opacity: '0.7'}} fontSize='10rem' />
                                        <Text fontSize='xl'>{'No new notifications'}</Text>
                                    </Flex>
                                )
                            }
                        </Flex>
                    </Tabs>
                </DrawerContent>
            </Drawer>
        </Portal>
    </>
  )
}
export default Notification
const NotificationItem = props => {
    const { 
        notification, 
        index, 
        item, 
        cardBgColor, 
        // theme, 
        onClose,
        // refetchCount,
        refetchNotifications, 
        handleExpandNotifications,
        handleRemove,
        listItems, 
        refetchCounts,
        showUnread,
        notificationCount
        // t 
    } = props
    const topPosition = {0: '0rem', 1: '.5rem', 2: '1rem'}
    const [isCardHovered, setIsCardHovered] = useState(false)
    const [isToggleReadHovered, setIsToggleReadHovered] = useState(false)
    let apiUrl = sessionStorage.getItem('apiUrl')
    const [isUpdating,setIsUpdating] = useState(false)
    const updateNotification = useCallback(async(property, value, id, type)=>{
        if(!id) return
        let payload = { property, value, id }
        const requestOptions = {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                clientApp:'tickets',
                useCase:'james'
            },
            url: `${apiUrl}/${type && type?.toLowerCase()=='tickets' ? 'ticketing/' : ''}NotificationLog/`,
            data: payload,
        }
        setIsUpdating(true)
        identityServices.identityApi.request(requestOptions).then((res)=>{
            setIsUpdating(false)
            if(res.data){
                refetchCounts()
                notificationCount()
            }
            if(showUnread){
                setTimeout(()=>{
                    refetchNotifications()
                }, 3000)
            }
        })
    }, [])
    const handleCardClick = useCallback((item, notification)=>{
        if (!item.isExpanded) {
            handleExpandNotifications(listItems.date, item?.key);
          }
        
          if ((item.isExpanded || item?.data?.length === 1) && notification?.type?.toLowerCase() !== 'livechat') {
            if (window.location.pathname.includes('agent/inbox')) {
              const paths = window.location.pathname.split('inbox')
              const groupId = paths?.length > 1 ? paths[1] : notification?.groupId
              window.location.href = `/agent/inbox/${groupId}`
            } else {
              window.location.href = `/agent/inbox/${notification?.groupId}`
            }
        
            if (notification?.status?.toLowerCase() !== 'read') {
              updateNotification('status', 'Read', notification.id, notification?.type)
            }
        
            onClose();
          }
    }, [])
    let dynamicCardStyle = useMemo(()=>{
        return {
            zIndex: 10/(index+1),
            top: topPosition[index] || 0,
            w: item?.data?.length==1 ? 'full' : !item.isExpanded ? '96%' : 'full',
            position: (!item.isExpanded && item?.data?.length>1) && 'absolute',
            boxShadow: !item.isExpanded && index>2 ? 'none' : '0px 2px 10px 0px rgba(0,0,0, 0.1)',
            bgColor: cardBgColor,
            h: (!item?.isExpanded && item?.data?.length>1) && '128px',
            overflowY: (!item?.isExpanded && item?.data?.length>1) && 'hidden',
            minH: (item?.isExpanded || item?.data?.length==1) && '128px',
            cursor: 'pointer',
            overflowX: 'hidden'
        }
    }, [item, index, cardBgColor])
    let content = useMemo(()=>{
        return formatHtmlNotification(notification?.message)
        // return notification?.message?.replace(/<style[\s\S]*?<\/style>|<[^>]*>/gi, '')?.replace(/\s+/g, ' ')
        //return notification?.message?.replace(/<a\s*[^>]*>(.*?)<\/a>/g, '')?.replace(/<div[^>]*id="address"[^>]*>[\s\S]*?<\/div>/i, '')?.replace(/<style[\s\S]*?<\/style>|<[^>]*>/gi, '')?.replace(/\s+/g, ' ').replace(/&nbsp;/g, '')
    }, [notification])
    const toggleReadUnread = useCallback((e, notification) => {
        let status = notification?.status?.toLowerCase() === 'read' ? 'Created' : 'Read'
        notification.status = status
        updateNotification('status', status, notification.id, notification?.type)
        e.stopPropagation()
    }, [updateNotification])
    // const handleMarkAllAsRead = () => {
    //     // Check if there are any unread notifications
    //     const hasUnreadNotifications = notifications.some(notification => notification.status.toLowerCase() === 'unread');
        
    //     if (!hasUnreadNotifications) {
    //         return;
    //     }
    //     for (let notification of notifications) {
    //         if (notification.status.toLowerCase() === 'unread') {
    //             updateNotification('status', 'Read', notification.id, notification.type)
    //         }
    //     }
    // }
    const getFileType = useCallback(filename =>{
        const extension = filename.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2)
        switch (extension.toLowerCase()) {
            case 'jpg':
            case 'jpeg':
            case 'png':
                return {
                    icon: FaFileImage,
                    color: 'blue.400'
                }
            case 'pdf':
                return {
                    icon: FaFilePdf,
                    color: 'red'
                }
            case 'xlsx':
                return {
                    icon: FaFileExcel,
                    color: 'green'
                }
            case 'doc':
            case 'docx':
                return {
                    icon: FaFileWord,
                    color: 'blue.400'
                }
            default:
                return 'Unknown'
        }
    })
    return (
        <Flex
            key={notification?.id}
            p='8px'
            pb='16px'
            mr='1'
            gap='1' 
            fontSize='x-small' 
            flexDir='column'
            font = '12px inter'
            borderRadius='md'
            onMouseOver={()=>setIsCardHovered(true)}
            onMouseLeave={()=>setIsCardHovered(false)}
            onClick={()=> handleCardClick(item, notification)}
            {...dynamicCardStyle}
        >
            <Flex justifyContent='space-between'>
                <Text fontWeight='600' fontSize='12px' className='subject-text' style={{color:"#1a202c !important"}}>
                    {notification?.subject}
                </Text>
                {(item.isExpanded || item.data?.length==1) && (
                    <Flex alignItems='center' position='relative'>
                        {/* <Tooltip fontSize='x-small' label={notification?.status?.toLowerCase()!=='read' ? 'Mark as Read' : 'Mark as Unread'}> */}
                            <Button 
                                p='0' 
                                position='absolute'
                                right={isCardHovered ? '7' : '0'}
                                transition='.4s all'
                                border='1px solid' 
                                borderColor='gray.300' 
                                borderRadius='full'
                                size='xs' 
                                bg='none'
                                disabled={isUpdating}
                                onMouseOver={()=>setIsToggleReadHovered(true)}
                                onMouseLeave={()=>setIsToggleReadHovered(false)}
                                leftIcon={isToggleReadHovered && (<FaRegCheckCircle style={{color: 'lightgray',fontSize:'8px'}} />)} 
                                h='16px'
                                minW='16px'
                                w='0'
                                overflow='hidden'
                                _hover={{bg: 'none', w: '80px'}}
                                visibility={(isCardHovered || notification.status?.toLowerCase()!=='read') ? 'visible' : 'hidden'}
                                onClick={(e)=>toggleReadUnread(e, notification)}>
                                    <Text display={isToggleReadHovered ? 'block': 'none'} alignItems={'center'} transition='all 1s' py='3px' fontSize='8px' ms='-3px' fontWeight='light' >
                                            {notification?.status?.toLowerCase()!=='read' ? ('Mark as Read') : ('Mark as Unread')}
                                        </Text>
                            {notification?.status?.toLowerCase()!=='read' && !isToggleReadHovered && (
                                <Text 
                                    as='span' 
                                    d='inline-block' 
                                    borderRadius='full' 
                                    w='.4rem' h='.4rem' 
                                    bgColor='blue.400'  />
                                )}
                            </Button>                        
                        {/* </Tooltip> */}
                        <Button 
                            onClick={(e)=>{
                                handleRemove(notification.id)
                                updateNotification('status', 'Deleted', notification.id, notification?.type)
                                e.stopPropagation()
                            }}
                            p='0'
                            position='absolute'
                            transition='.4s position'
                            right={isCardHovered ? '0' : '-10'}
                            mt='0.3' bg='none' _hover={{bg:'none'}} color='gray.500' size='xs'>
                            <RxCross1 fontSize='12px' />
                        </Button>
                    </Flex>
                )}
            </Flex>
            <Flex w='fit-content' my='2' borderBottom='1px solid' borderColor={(!(item.isExpanded || item?.data?.length==1) || notification?.type?.toLowerCase()=='livechat') ? 'transparent' : '#2563EB'}>
                <Text color={'#2563EB'} fontWeight='bold'>
                    {notification?.properties?.user_full_name || `${notification?.properties?.actionBy?.firstName} ${notification?.properties?.actionBy?.lastName}`}
                </Text>
                <Text ms='1' color={'gray.500'}>
                    {notification?.properties?.notificationInfo?.subject}
                </Text>
            </Flex>
            {(item.isExpanded || item?.data?.length==1) ? 
                <ReadMoreLess
                    fontSize='12px'
                    style={{wordSpacing: '2px', lineHeight: '16px'}}
                    fontWeight='500'
                    color={'gray.500'}
                    content={content} 
                    maxLength={250} 
                /> : 
                <Text 
                    color={'gray.500'}
                    style={{wordSpacing: '2px', lineHeight: '16px'}} 
                    fontWeight='500' fontSize='12px' >{_.truncate(content, {length: 250})}</Text>
            }
            {notification?.properties?.attachments &&
            !_.isEmpty(notification?.properties?.attachments) &&
            item?.isExpanded &&
                <Flex alignItems='center' gap='1' mt='1'>
                    <Text>{'Attachments'}:</Text>
                    <Flex gap='1'>
                        {notification.properties.attachments.map(attachment=>(
                            <Link 
                                d='flex' 
                                gap='1' 
                                px='2' 
                                href={attachment?.doc} 
                                alignItems='center' 
                                cursor='pointer'
                                color='gray.500' 
                                borderRadius='md' 
                                border='1px solid lightgrey'
                                isExternal
                                
                            >
                                <Icon
                                    as={getFileType(attachment?.filename)?.icon} 
                                    color={getFileType(attachment?.filename)?.color} 
                                />
                                <Tooltip fontSize='10px' label={attachment?.filename}>
                                    {_.truncate(attachment?.filename, {length: 15})}
                                </Tooltip>
                            </Link>
                        ))}
                    </Flex>
                </Flex>
            }
        </Flex>
    )
}

export const formatHtmlNotification = (htmlString) => {
    let obj = {}
    const parser = new DOMParser()
    const htmlContent = parser.parseFromString(htmlString, 'text/html')
    obj.headTitle = htmlContent.getElementById('main_title')?.innerText?.replace(/\t/g, '')?.trim()
    if(obj.headTitle && obj.headTitle[obj.headTitle.length -1]!=='!'){
        obj.headTitle = `${obj.headTitle},`
    }
    obj.textElement = htmlContent.querySelector('.text')
    if(obj.textElement){
        obj.textElement = obj.textElement.querySelector('div')?.innerText?.replace(/\t/g, '')?.trim()
    }
    obj.introTitle = htmlContent.querySelector('#intro_title')?.innerText?.replace(/\t/g, '')?.replace(':', ' is')?.trim()
    const { headTitle, introTitle, textElement } = obj 
    if(!headTitle || !introTitle || !textElement){
        return htmlString?.replace(/<a\s*[^>]*>(.*?)<\/a>/g, '')?.replace(/<div[^>]*id="address"[^>]*>[\s\S]*?<\/div>/i, '')?.replace(/<style[\s\S]*?<\/style>|<[^>]*>/gi, '')?.replace(/\s+/g, ' ').replace(/&nbsp;/g, '')
    }
    return `${headTitle} The ${introTitle}. ${textElement}.`
}