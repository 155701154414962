import React from 'react';
import { withTranslation } from 'react-i18next';
//Button, Form, Col, FormGroup, Label, Input, FormFeedback, FormText,
import { CustomInput, Label } from 'reactstrap';


class FieldFile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            field: props.field,
            filePreview: [],
            errorUpload: false,
            setInputKey: '',
            formValues: props.formValues || []
        };
    }

    fileChange(name, event) {
        this.setState({ setInputKey: 'upload' })
        this.props.handleUploadChange(name, event);
    }
    componentWillReceiveProps(props) {
        const { previewUpload } = props;
        let filePreview = this.filePreview(previewUpload)
        if (filePreview) {
            this.setState({ errorUpload: false, field: props.field, formValues: props.formValues || {} })
        }
        this.setState({
            field: props.field || false,
            formValues: props.resetFilter ? {} : props.formValues, // dict of field name and field 
            forceDisable: props.forceDisable, // override field level disable false to true
            forceEnable: props.forceEnable, // overrided field level disable true to false
            defDisable: props.defDisable // the default option if not specified at field level
        })
    }
    filePreview(fileArray) {
        const t = this.props.t
        let fileSize = 0;
        let maxFileSize = 5000000;
        // let fileExtension = /(.*?)\.(jpg|bmp|jpeg|JPG|JPEG|PNG|png|gif|GIF|doc|docx|csv|CSV|txt|TXT)$/;

        fileArray && fileArray.length > 0 && fileArray.forEach((file, key) => {
            fileSize = parseInt(fileSize) + parseInt(file.fileSize);
            if (fileSize > maxFileSize) {
                this.setState({ errorUpload: t('Uploaded File Size Exceeds to 5MB !') })
                setTimeout(() => {
                    this.removeFile(file.fileName);
                }, 5000);
            }


        });
        if (fileSize < maxFileSize) {
            this.setState({ errorUpload: false, filePreview: fileArray })
            return true
        }


    }

    removeFile(fileName) {
        this.props.removeUploadFile(fileName)
        this.setState({ setInputKey: '' })

    }
    componentWillMount() {
    }

    render() {
        const {  formValues, field,t } = this.props;
        const { filePreview, errorUpload } = this.state;
        let label = field && field.label
        if(label) label = t(label)
        return (
            <div className={"custom-form-grp clearfix"}>
                <Label for={field && field.label} sm={3}>{label}</Label>
                <div className={"grp-split"}>
                    <CustomInput
                        multiple
                        type={field.type}
                        id={field.name}
                        name={field.name}
                        key={this.state.setInputKey || ''}
                        onChange={this.fileChange.bind(this, field.name)}
                    />

                    {this.props.touched &&
                        field.required &&
                        (!formValues[field.name] || formValues[field.name] === "") &&
                        <div className="error-message">
                            {t(field.errorMessage)}
                        </div>
                    }

                    {
                        errorUpload &&
                        <div className="error-message">
                            {errorUpload}
                        </div>
                    }

                </div>
                <p></p>
                <div className="row">
                    {filePreview && filePreview.length > 0 && filePreview.map((preview, index) => {
                        return (

                            <div key={index} className="col-md-4">
                                <button type="button" onClick={this.removeFile.bind(this, preview.fileName)} className="close" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                <img className="img img-thumbnail" src={preview.fileUrl} alt={preview.fileName}/>
                                <i className="text-muted">{preview.fileName}</i>
                            </div>
                        )
                    })
                    }
                </div>
            </div>
        )

    }
}

export default withTranslation()( FieldFile);
