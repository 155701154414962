'use strict'
import { EntityActions } from '../common/base/entity'
// import {EntityComponent} from '../entity'

export class ContentAction extends EntityActions {
    postActionsHooks({ actionName, item }) {
        switch (actionName) {
            case 'getAll':
                item = item.map(elem => {
                    if(elem.BotConfig && elem.BotConfig.fullName) {
                        elem.intentLabel = elem.BotConfig.fullName
                    } else {
                        elem.intentLabel = elem.intentName
                    }
                    delete elem.BotConfig

                    return elem
                })
                return item

            default:
                return item
        }
    }


    preActionsHooks({ actionName, item, propFields }) {
        if (!item) return
        if (item && (actionName == 'addNewItem') || actionName == 'editItem') {
            item.botId = 'emma'
            return item
        } else {
            return item
        }
    }

}
