import Joi from 'joi-browser'
const holidays = Joi.object().keys({
  id : Joi.string().uuid(),
  tenantId: Joi.string().uuid().required(),
  name:Joi.string().required(),
  label:Joi.string().required(),
  calendar:Joi.any(),
  status: Joi.string().valid( 'Published', 'Draft').required().default('Published'),
  createdAt: Joi.date().allow(null),
  createdBy: Joi.string().allow(null),
  updatedAt: Joi.string().allow(null),
  updatedBy: Joi.string().allow(null),
  deletedSeq: Joi.number().allow(null)
})
// These are the schemas of the return object from the api server
export const methodSchemas = {
    getAll: Joi.array().items(holidays),
    getOne: holidays,
    addItem: holidays,
    editItem: holidays
}
export const collectionSchemas = {
 
}
export const entitySchema = holidays