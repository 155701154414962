import React, { useState, useEffect } from 'react';
import {  Label, FormGroup } from 'reactstrap';
import { MapInput, SelectInput } from './jsonInput/formInput'
import * as helper from './jsonInput/helper'


// Json List Component for simple json Array 
const JsonObjComponent = (props) => {
    const [ propList, setPropList] = useState({})
    const [ isProps, setIsProps] = useState(false);


    useEffect(() => {
        setIsProps(helper.isProps(props))
        setPropList(props)
    })


    const saveToParent = () => {
        props.handleInputSubmit()
    }
    const changeToParent = (inputValue) => {
        if (inputValue)
            props.onComponentChange(props.name, inputValue)
    }

    const switchAddComponent = () => {
        return (
            <AddComponent
                {...props}
                changeToParent={changeToParent}
                saveToParent={saveToParent}
            />
        )
    }


    return (
        <div className="row">
            <div className="col-sm-11">
                {switchAddComponent()}
            </div>
        </div>


    )


}




const AddComponent = (props) => {

    const [propList, setPropList] = useState({})
    const [formKeyValue, setFormKeyValue] = useState('');
    const [selectVal, setSelectVal] = useState({});
    const [state, setState] = useState({
        keyName: '',
        inputValue: '',
        filteredProps: '',
        toggleForm: false,
        inputFormKey: null,
        keyValuePair: null
    })

    useEffect(() => {
        setValParameters()
        setPropList(props)
    }, [props]
    )

    const setValParameters = () => {
        setSelectVal({ ...selectVal, ...propList.value })
    }

    const inputChange = (inputVal, inputName) => {
        let setSelect = { ...selectVal, [inputName]: inputVal }
        setSelectVal({ ...selectVal, ...setSelect })
        // console.log("@!@!@!@!", propList.value)
        propList.value[inputName] = inputVal
        props.changeToParent(setSelect)

    }

    const handleInputChange = (formKeyValue) => {
        let inputFormKey = {...formKeyValue }
        setFormKeyValue(inputFormKey)
        props.changeToParent(inputFormKey)
    }


    const handleKeyDown = () => {
        props.saveToParent()
        setState({
            keyName: '',
            inputValue: '',
            filteredProps: '',
            toggleForm: false,
            inputFormKey: null,
        });
        setFormKeyValue({})
    }

    const { inputValue,  filteredProps, inputFormKey } = state
    const { t } = props
    return (
        <>
            {/* {props.preTag(state)} */}
            {propList && propList.props && propList.props.length > 0 && propList.props.map((elem, i) => {
                if (elem.type === 'select') {
                    return (<div key={i}><Label>{t(elem.label)} : </Label><SelectInput
                        {...propList.props}
                        handleChange={inputChange}
                        value={t(selectVal[elem.name]) || ''}
                        name={elem.name}
                        handleEnter={handleKeyDown}
                        options={elem.options}
                    /></div>)
                } else {
                    return <InputForm
                        {...filteredProps}
                        isProps={propList.isProps}
                        selectedKey={inputValue}
                        preTag={props.preTag}
                        inputFormKey={formKeyValue || inputFormKey}
                        handleChange={handleInputChange}
                        handleEnter={handleKeyDown}

                    />
                }

            })}
        </>
    )
}






const InputForm = (props) => {
    const [inputValue, setInputValue] = useState({ keyPair: {} });
    const [state, setState] = useState({ selectedKey: props.selectedKey, inputType: null, inputList: false, inputSelected: '' })

    useEffect(() => {
        const { keyPair } = inputValue;
        let pairing = { ...keyPair, ...props.inputFormKey }
        setInputValue({ ...inputValue, keyPair: pairing })
        setState({ ...state, selectedKey: props.selectedKey })


    }, [props])

    useEffect(() => {
        checkInputType(props)
    }, [props.type])

    const checkInputType = (props) => {
        if (props.type && Array.isArray(props.type)) {
            let setOptions = { options: props.type, name: 'mapInputType' };
            setState({ ...state, inputList: setOptions, inputType: null })
        }
        else { setState({ ...state, inputType: props.type, inputList: false }) }
    }

    const inputChange = (outPutValue, outPutName) => {
        let InputFormKeyValue = { ...inputValue, keyPair: { ...inputValue.keyPair, [outPutName]: outPutValue } }
        props.handleChange(InputFormKeyValue.keyPair)
        setInputValue(InputFormKeyValue)
    }

    const componentChange = (outPutName, outPutValue) => {
        let InputFormKeyValue = { ...inputValue, keyPair: { ...inputValue.keyPair, [outPutName]: outPutValue } }
        props.handleChange(InputFormKeyValue.keyPair)
        setInputValue(InputFormKeyValue)

    }
    const handleInputSubmit = () => {
        props.handleEnter()
    }
    const inputSelectChange = (value, name) => {
        setState({ ...state, inputType: value, inputSelected: value })
    }

    const { selectedKey, inputType, inputList, inputSelected } = state;
    const { t } = props
    const { keyPair } = inputValue;
    return (
        <div className="row">
            <div className="col-sm-12 ">
                <FormGroup className="bgwhite border">
                    {inputList &&
                        <>
                            <Label>{t('Value Type')} </Label>
                            <SelectInput
                                {...inputList}
                                type={'select'}
                                handleChange={inputSelectChange}
                                value={inputSelected}

                            />
                        </>
                    }
                    {inputType && <Label>{t(props.label) || t(props.name)}</Label>
                    }
                    {inputType && <MapInput
                        {...props}
                        type={inputType}
                        handleChange={inputChange}
                        value={keyPair[selectedKey]}
                        placeholder={t('Edit')}
                        handleEnter={handleInputSubmit}
                        handleInputSubmit={handleInputSubmit}
                        onComponentChange={componentChange}
                    />
                    }
                </FormGroup>
            </div>
        </div>
    )

}


export {
    JsonObjComponent
}