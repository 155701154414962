'use strict'

import { EntityActions } from '../../common/base/entity'

export class UserAttributeActions extends EntityActions {

    preActionsHooks({ actionName, item, propFields }) {
        if (!item) return
        if (item && (actionName == 'addNewItem') || actionName == 'editItem') {
            if(item && !item.validationRule ) {
                return{
                    error:{message : 'Validation Rule is required.'}
                }   
            }
            if(item && item.validationRule && (item.validationRule.field == 'Predefined List' || item.validationRule.field == '') && (!item.valueLabels || item.valueLabels.length < 1)) {
                return{
                    error:{message : 'Value is required in case of Predefined List'}
                }   
            }
            delete item.userAttrData 
            return item
        } else {
            return item
        }
    }
    postActionsHooks({ actionName, item, propFields }){
        if(!item) return
        return item
    }
    
    getFns() {
        let that = this
        let fns = super.getFns()
        Object.values(fns).forEach( f => f.bind(that))
        return fns
    }

}
