import { ChakraProvider } from '@chakra-ui/react';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { DetailComponent } from '../../common/base/entity/components';
import Roles from './multi.roles';
class RoleGroupDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      entityValues: {},
    };
    this.handleRoleUpdate = this.handleRoleUpdate.bind(this)
  }
  componentWillReceiveProps(props) {
    if (props.entity && props.entity.entityValues) {
      this.setState({
        entityValues: props.entity.entityValues,
      });
    }
  }

  handleRoleUpdate(roles){
  this.setState({
    entityValues:{
      ...this.state.entityValues,
      roleInfo: roles
    }
  })
  }

  render() {
    const { entityValues } = this.state;
    let newProps = Object.assign({}, { ...this.props });
    if (newProps.entity && newProps.entity.entityValues)
      newProps.entity.entityValues = entityValues;
    return (
      <>
        <DetailComponent {...newProps} entityValues={entityValues} />
        <ChakraProvider>
          <Roles 
          {...this.props}
          handleRoleUpdate={this.handleRoleUpdate} 
          roles={this.state.entityValues.roleInfo} 
          />
        </ChakraProvider>
      </>
    );
  }
}
export default withTranslation()(RoleGroupDetail);
