import { ContentState, EditorState } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import marked from 'marked'
import React, { Component } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import './editor.css'
class TextEditor extends Component {
	constructor(props) {
		super(props)
		this.state = {
			editorState: '',
			editorHtml: this.props.value,
			edit: false,
		}
	}

	componentWillMount() {}

	parseInput = input => {
		if (input && input.length > 0) {
			const contentStateeditor = marked(input, { renderer: this.render_content() })
			const contentBlock = htmlToDraft(contentStateeditor)
			const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
			this.setState({ editorState: EditorState.createWithContent(contentState) })
		}
	}

	componentDidMount() {}

	componentWillReceiveProps(nextProps) {
		let input = nextProps.value.replace(/<[\S]+?><\/[\S]+>/gim, '')
		if (input && input.length > 0) {
			this.parseInput(input)
			this.setState({ edit: true })
		} else {
			this.setState({ edit: false })
		}
	}

	// shouldComponentUpdate(nextProps, nextState) {

	// }

	// componentWillUpdate(nextProps, nextState) {

	// }

	// componentDidUpdate(prevProps, prevState) {

	// }
	preTag = value => {
		return <pre>{JSON.stringify(value, null, 2)}</pre>
	}
	componentWillUnmount() {}
	onEditorStateChange = editorState => {
		let htmlValue = draftToHtml(editorState)
		if (this.state.editorHtml === htmlValue) return
		this.setState({
			editorState,
			editorHtml: htmlValue,
		})
		// this.setState({editorHtml:htmlValue})
		//let stripEmptyTags = htmlValue.replace(/(<(?!\/)[^>]+>)+(<\/[^>]+>)+/gm, '').trim()
		this.props.onChange(this.props.name, htmlValue)
	}

	UploadImages(file) {}
	checkData = () => {
		const { editorState } = this.state
		this.setState({ editorHtml: draftToHtml(editorState) })
	}

	render_content = () => {
		let render = new marked.Renderer()
		render.list = function (body, ordered, start) {
			let newtext = body.replace(/(<p>)/gim, '').replace(/<\/p>/gim, '')
			if (ordered) {
				return `<ol>${newtext}</ol>`
			} else {
				return `<ul>${newtext}</ul>`
			}
		}

		render.blockquote = function (quote) {
			let newtext = quote.replace(/(<p>)/gim, '').replace(/<\/p>/gim, '')
			return `<blockquote>${newtext}</blockquote>`
		}
		render.code = function (string) {
			return string
		}
		return render
	}

	render() {
		const { editorState, edit } = this.state
		const setEditorReference = ref => {
			this.editorReferece = ref
			if (ref) {
				ref.focus()
			}
		}
		const toolbar = {
			options: ['inline', 'blockType', 'list', 'link', 'image', 'history'],
			inline: {
				options: ['bold', 'italic', 'monospace'],
			},
			blockType: {
				inDropdown: true,
				options: ['Normal', 'H1', 'H2', 'H3', 'Blockquote'],
			},
			list: {
				options: ['unordered', 'ordered'],
			},
			textAlign: {
				options: ['left', 'center', 'right', 'justify'],
			},
			link: {
				inDropdown: false,
				showOpenOptionOnHover: true,
				defaultTargetOption: '_self',
				options: ['link', 'unlink'],
			},
			image: {
				urlEnabled: true,
				uploadEnabled: false,
				alignmentEnabled: true,
				uploadCallback: this.UploadImages.bind(this),
				previewImage: true,
				inputAccept: 'image/gif,image/jpeg,image/jpg,image/png',
				alt: { present: true, mandatory: false },
				defaultSize: {
					height: 'auto',
					width: 'auto',
				},
			},
			history: {
				inDropdown: false,
				component: undefined,
				options: ['undo', 'redo'],
			},
		}
		return (
			<div>
				{edit && (
					<Editor
						className="txt-editor"
						toolbar={toolbar}
						editorRef={setEditorReference}
						defaultEditorState={editorState}
						value={editorState}
						onChange={this.onEditorStateChange.bind(this)}
						readOnly={this.props.readOnly ? true : false}
					/>
				)}
				{!edit && (
					<Editor className="txt-editor" toolbar={toolbar} value={editorState} onChange={this.onEditorStateChange.bind(this)} readOnly={this.props.readOnly ? true : false} />
				)}

				{/* <a href="#" onClick={this.checkData.bind(this)}>Click to show</a> */}
			</div>
		)
	}
}

export default TextEditor
