import { createStore, compose,  applyMiddleware } from 'redux'

import thunk from 'redux-thunk'
import reducers from '../redux/reducers'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['identity', 'globalSetting', 'namespace', 'whiteLabelEntity', 'workspace']
}
const middlewares = [thunk]
const enhancers = []
let composeEnhancers = compose

if (process.env.NODE_ENV === `development`) {
  const { logger } = require(`redux-logger`)

  middlewares.push(logger)

  if (typeof window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ === 'function') {
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  }

}

const persistedReducer = persistReducer(persistConfig, reducers)

export default () => {
  let store = createStore(persistedReducer,
    composeEnhancers(
      applyMiddleware(...middlewares),
      ...enhancers
    )
  )
  let persistor = persistStore(store)
  return { store, persistor }
}