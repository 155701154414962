import DataSet from '@antv/data-set'
import { Axis, Chart, Coord, Geom, Legend, Tooltip } from 'bizcharts'
import React from 'react'

class Bars extends React.Component {
	constructor(props) {
		super(props)
		this.state = { loading: true }
	}

	render() {
		let { t } = this.props
		const loading = this.state && this.state.loading
		if (loading) {
			this.setState({ loading: false })
			return <div>{(t && t('Loading')) || 'Loading...'}</div>
		}

		const data = [
			{
				label: 'Monday',
				series1: 2800,
				series2: 2260,
			},
			{
				label: 'Tuesday',
				series1: 1800,
				series2: 1300,
			},
			{
				label: 'Wednesday',
				series1: 950,
				series2: 900,
			},
			{
				label: 'Thursday',
				series1: 500,
				series2: 390,
			},
			{
				label: 'Friday',
				series1: 170,
				series2: 100,
			},
		]
		const ds = new DataSet()
		const dv = ds.createView().source(data)
		dv.transform({
			type: 'fold',
			fields: ['series1', 'series2'],

			key: 'type',

			value: 'value',
		})
		const dv1 = ds.createView().source(dv.rows)
		dv1.transform({
			type: 'aggregate',
			fields: ['value'],
			operations: ['sum'],
			as: ['Sales'],
			// groupBy: ['type']
		})
		// console.log(dv1)
		return (
			<div>
				<Chart height={200} data={dv} forceFit padding={[20, 20, 50, 75]}>
					<Legend />
					<Coord transpose scale={[1, -1]} />
					<Axis
						name="label"
						label={{
							offset: 10,
						}}
					/>
					<Axis name="value" position={'right'} />
					<Tooltip />
					<Geom
						type="interval"
						position="label*value"
						color={'type'}
						adjust={[
							{
								type: 'dodge',
								marginRatio: 1 / 32,
							},
						]}
					/>
				</Chart>
			</div>
		)
	}
}

export default Bars
