import DataSet from '@antv/data-set'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { Card, CardBody, CardTitle } from 'reactstrap'
import { aggregateOperation, filterMetricRow, getObjByPath, getSLA, mathOperation } from '../../helpers/utils'
class CardDetail extends React.Component {
	constructor(props) {
		super(props)
		this.state = {}
	}

	componentWillReceiveProps(props) {
		let dependsOn = props.dependsOn
		let changed
		let body = (this.state && this.state.body) || '-'
		if (dependsOn && Object.keys(dependsOn).length) {
			if (this.state && this.state.dependsOn)
				changed = Object.keys(dependsOn).some(k => {
					if (dependsOn[k] !== this.state.dependsOn[k]) return true
					return false
				})
			else changed = true
		} else {
			changed = true
		}
		body = this.processData(props)

		this.setState({ ...props, dependsOn, body }, () => {
			changed && props.card.api && this.getValue()
		})
	}

	handleAction(item) {}
	getValue() {
		let api = this.props.card.api
		let t = this.props.t
		let apiMeta
		if (this.props.card.api) {
			apiMeta = this.props.meta.apis && this.props.meta.apis[api]
			let targetProp = apiMeta.response && apiMeta.response.targetProp
			targetProp = targetProp || 'cards.' + this.props.card.name
			this.props.executeApi({ apiMeta, values: this.props.filterValues, targetProp, t, apiUrl: this.props.tenant && this.props.tenant.apiUrl })
		}
	}
	processData(props) {
		let data
		const { card, sla } = props
		let targetProp
		let apiMeta
		let metrics = props.card && props.card.metrics // usually this is an aggregate transform on data
		if (card && card.api) {
			apiMeta = this.props.meta.apis && this.props.meta.apis[card.api]
			targetProp = apiMeta && apiMeta.response && apiMeta.response.targetProp
			targetProp = targetProp || 'cards.' + this.props.card.name
			data = props.dashboard && props.dashboard[targetProp]
		} else data = props.data
		// let title = props.card && props.card.title
		const { dataProp } = props.card
		//const { dependsOn } = this.state
		const { filterValues, tenantConfig } = props
		let dv

		let body
		let cardData
		let cardColor
		if (metrics && metrics.pop && metrics.length > 0) {
			if (data) {
				if (dataProp) {
					if (dataProp === 'data') cardData = data
					else cardData = getObjByPath({ ref: data, path: dataProp })
				} else cardData = data
				let metricsData = []
				let filters = []
				let as
				metrics.forEach(metric => {
					as = metric.as || metric.fields
					let filteredData = filterMetricRow({ data: cardData, filters: card.filters || [{ field: 'metricName', value: metric.name }] })
					if (card.dynFilters) {
						Object.keys(card.dynFilters).forEach(field => {
							let filter = card.dynFilters[field]
							let filterObj = {}
							if (typeof filter === 'object') {
								// @ts-ignore
								filterObj = { field }
								if (filter.values) filterObj.values = filter.values.map(v => filterValues[v] || v)
								if (filter.value) filterObj.value = filterValues[filter.value] || filter.value
								filterObj.operator = filter.operator
								filters.push(filterObj)
							} else {
								if (filterValues[filter]) {
									// @ts-ignore
									filterObj = { field, value: filterValues[filter] || filter }
									filters.push(filterObj)
								}
							}
						})
						filteredData = filterMetricRow({ data: filteredData, filters }) // dynamic filters
					}
					// @ts-ignore
					dv = new DataSet.View().source(filteredData)
					dv.transform({
						type: 'aggregate',
						fields: metric.fields,
						operations: metric.operations,
						groupBy: metric.groupBy,
						as: as,
					})
					let dataDV = dv.rows && dv.rows.pop && dv.rows[0] && dv.rows[0][as[0]] && parseFloat(dv.rows[0][as[0]]).toFixed(2)
					if (filteredData.length > 0 && !card.filters) {
						metricsData.push({ name: metric.name, value: dataDV })
					} else if (metricsData.length > 0) {
					} else {
						metricsData = filteredData
					}
				})

				if (metricsData.length > 0 && props.card.metricsOperations) {
					as = ['value']
					// @ts-ignore
					dv = new DataSet.View().source(metricsData)
					let finalValue = aggregateOperation({
						data: metricsData,
						key: as && as[0],
						operation: props.card && props.card.metricsOperations && props.card.metricsOperations[0],
					})
					dv.rows = [{ name: card.name, value: finalValue }]
				}
				body = dv.rows && dv.rows.pop && dv.rows[0] && dv.rows[0][as[0]] && parseFloat(dv.rows[0][as[0]])
				body = body && !Number.isInteger(body) ? body.toFixed(2) : body
				if (body) {
					if (card.settingValue) {
						let settingValue = card.settingValue
						let tc = tenantConfig && tenantConfig.data && tenantConfig.data.length > 0 && tenantConfig.data.find(o => o.configType == 'defaultSetting' && o.name == 'metrics')
						let tcProperties = tc && tc.properties && tc.properties
						if (tcProperties && tcProperties[settingValue.name]) {
							let operationSymbol = mathOperation({ operation: settingValue.operation })
							body = parseFloat(eval(`${body} ${operationSymbol} ${tcProperties[settingValue.name]}`)).toFixed(2)
						}
					}
					if (typeof card.bodyPrefix === 'object') {
						let prefix = metricsData.length > 0 ? metricsData[0][card.bodyPrefix['field']] : ''
						if (prefix) {
							body = prefix + ' : ' + body
						}
					} else {
						body = (card.bodyPrefix ? ' ' + card.bodyPrefix : '') + body
					}
					body += card.bodySuffix ? ' ' + card.bodySuffix : ''

					body = body || `- Default Value ${metricsData.length}`
				} else {
					body = 'NA'
				}
			}
		}
		if (body !== 'NA' && sla && sla.length > 0) {
			let slaFilter = sla.filter(s => {
				return s.metricName === card.name
			})
			if (slaFilter.length > 0) {
				let slaValue = getSLA({ data: slaFilter, metricValue: body })
				//get color
				if (card.color && slaValue) {
					cardColor = slaValue && slaValue.styles && slaValue.styles.color
				}
			}
		}
		return { data: body, cardColor }
	}
	render() {
		const { card, t } = this.props
		let { title } = card
		if (title) title = t(title)
		const body = this.state.body
		const colors = ['cyan', 'lb', 'lg', 'ly', 'db', 'lpurple', 'bg', 'mb', 'lpink']
		let color = (body && body.cardColor) || 'lpink'
		if (card.color && body && body.cardColor && card.classes && !card.classes.includes('bg-gradient-')) {
			card.classes = card.classes + ' bg-gradient-' + color.toLowerCase()
		} else if ((!card.color && card.classes && !card.classes.includes('bg-gradient-')) || (body && body.data === 'NA')) {
			card.classes = card.classes + ' bg-gradient-' + color
		}

		let colSize
		if (card.icon) colSize = 10
		else colSize = 12
		switch (card.size) {
			case 'sm':
				return (
					<div className={'shadow mx-2 btn btn-sm input-group rounded border border-' + color} onClick={this.handleAction.bind(this, card)}>
						{card.icon && (
							<div className="input-group-prepend ">
								<span className="pt-2 pr-2">
									<i className={' ' + (card.icon || ' fa fa-search ')}></i>
								</span>{' '}
							</div>
						)}
						<div className={'text-center px-0 col-sm-' + colSize}>
							<div className=" text-center small">{title}:</div>
							<div className="text-center small"> {body && body.data}</div>
						</div>
					</div>
				)
			case 'md':
				return (
					<CardBody className={'row     ' + color} onClick={this.handleAction.bind(this, card)}>
						{card.icon && (
							<div className="bg-db col-sm-2">
								<i className={'pt-3 ' + (card.icon || ' fa fa-search ')}></i>
							</div>
						)}
						<div className="col-sm-10 text-center small">
							{title}: {body && body.data}
						</div>
					</CardBody>
				)
			case 'xs':
				return (
					<div className={'shadow mx-2 btn btn-sm input-group rounded border border-' + color} onClick={this.handleAction.bind(this, card)}>
						{card.icon && (
							<div className="input-group-prepend ">
								<span className="pr-2">
									<i className={' ' + (card.icon || ' fa fa-search ')}></i>
								</span>{' '}
							</div>
						)}
						<div className={'text-center px-0 col-sm-' + colSize}>
							<div className=" text-center small ">{title + ': ' + ((body && body.data) || '')}</div>
						</div>
					</div>
				)
			// return (<div className={"btn btn-sm btn-outline-" + color} onClick={this.handleAction.bind(this, card)}>
			//     {card.icon && <i className={"pr-2 " + (card.icon || "fa fa-search ")}></i>}<div className="small">{title}: {body}</div>
			// </div>)
			case 'xl':
			default:
				return (
					<Card className={'shadow rounded text-white ' + (card.classes || 'bg-gradient-' + color)}>
						<CardBody>
							<CardTitle>{title}</CardTitle>
							<h1 className="text-center"> {body && body.data}</h1>
						</CardBody>
					</Card>
				)
		}
	}
}

export default withTranslation()(CardDetail)
