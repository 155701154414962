import { ssoConstants, ssoServices } from '../'
import { alertActions } from '../../common'

const getSsoConfigReqeust = () => ({
	type: ssoConstants.GET_SSO_REQUEST,
})

const getSsoConfigSuccess = payload => ({
	type: ssoConstants.GET_SSO_SUCCESS,
	payload,
})

const getSsoConfigFailure = payload => ({
	type: ssoConstants.GET_SSO_FAILURE,
	payload,
})

export const getSsoConfig = (tenandId, t) => async dispatch => {
	try {
		dispatch(getSsoConfigReqeust())
		let config = await ssoServices.getSsoConfig(tenandId)
		dispatch(getSsoConfigSuccess(config))
	} catch (error) {
		let errorMsg = (t && t('Unable to fetch SSO Configuration')) || 'Unable to fetch SSO Configuration'
		errorMsg = errorMsg + ' ' + (error ? error.message : '')
		dispatch(getSsoConfigFailure(errorMsg))
		dispatch(alertActions.error(errorMsg))
	}
}

const addSsoConfigReqeust = () => ({
	type: ssoConstants.ADD_SSO_REQUEST,
})

const addSsoConfigSuccess = () => ({
	type: ssoConstants.ADD_SSO_SUCCESS,
})

const addSsoConfigFailure = payload => ({
	type: ssoConstants.ADD_SSO_FAILURE,
	payload,
})

export const addSsoConfig = (tenandId, configPayload, t) => async dispatch => {
	try {
		dispatch(addSsoConfigReqeust())
		let config = await ssoServices.addSsoConfig(tenandId, configPayload)
		dispatch(addSsoConfigSuccess())
		dispatch(alertActions.success((t && t('Successfully added new SSO Configuration')) || 'Successfully added new SSO Configuration'))
	} catch (error) {
		let errorMsg = (t && t('Unable to add new SSO Configuration')) || 'Unable to add new SSO Configuration'
		errorMsg = errorMsg + ' ' + (error ? error.message : '')
		dispatch(addSsoConfigFailure(errorMsg))
		dispatch(alertActions.error(errorMsg))
	}
}

const modifySsoConfigReqeust = () => ({
	type: ssoConstants.MODIFY_SSO_REQUEST,
})

const modifySsoConfigSuccess = () => ({
	type: ssoConstants.MODIFY_SSO_SUCCESS,
})

const modifySsoConfigFailure = () => ({
	type: ssoConstants.MODIFY_SSO_FAILURE,
})

export const modifySsoConfig = (tenandId, configType, configPayload, t) => async dispatch => {
	try {
		dispatch(modifySsoConfigReqeust())
		let config = await ssoServices.modifySsoConfig(tenandId, configType, configPayload)
		dispatch(modifySsoConfigSuccess())
		dispatch(alertActions.success((t && t('SSO Configuration updated successfully')) || 'SSO Configuration updated successfully'))
	} catch (error) {
		dispatch(modifySsoConfigFailure())
		let errorMsg = (t && t('Unable to update SSO Configuration')) || 'Unable to update SSO Configuration'
		errorMsg = errorMsg + ' ' + (error ? error.message : '')

		dispatch(alertActions.error(errorMsg))
	}
}
