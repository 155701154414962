/**
 * get workspace related data from localstorage
 */
export const getWSDataFromLS = () => {
	return localStorage.getItem('workspace')
}

export const setWSDataToLS = ({ key, wsType, wsData }) => {
	localStorage.setItem(key, JSON.stringify({ [wsType]: { data: wsData } }))
}

export const getWsQuery = ({ wsType, req, oData = null }) => {
	let wsDat = localStorage.getItem('workspace')
	let method = req.method || 'get'
	let wsData = wsDat && wsDat != undefined ? JSON.parse(wsDat) : null
	method = method.toLowerCase()
	//
	if (wsData && wsData[wsType] && wsType === 'qna') {
		if (method == 'post' || method == 'put' || method == 'delete') {
			// append to body
			// @ts-ignore
			req.data = Object.assign({}, req.data, { workspace: wsData[wsType].props })
		} else if (method == 'get' && wsData[wsType] && wsData[wsType].props) {
			// foreach properties create a workspace key and value combination
			if (req.url.indexOf('$filter=') != -1) {
				let filter = '$filter='
				for (const [key, value] of Object.entries(wsData[wsType].props)) {
					filter += `workspace_${key} eq '${value}' and `
				}
				// wsData[wsType].props.forEach(elem => {
				//     filter += `workspace_${Object.keys(elem)[0]} eq '${elem[Object.keys(elem)[0]]}' and `
				// });
				req.url = req.url.replace('$filter=', filter)
				// req.url = req.url.replace('$filter=', `$filter=workspace_key eq '${wsData[wsType].key}' and workspace_value eq '${wsData[wsType].value}' and `)
			} else if (oData) {
				let filter = '$filter='
				let filterCond = []
				for (const [key, value] of Object.entries(wsData[wsType].props)) {
					//filter += `workspace_${key} eq '${value}' and `
					filterCond.push(`workspace_${key} eq '${value}'`)
				}
				filter += filterCond.join(' and ')
				// wsData[wsType].props.forEach(elem => {
				//     filter += `workspace_${Object.keys(elem)[0]} eq '${elem[Object.keys(elem)[0]]}' and `
				// });
				req.url += filter
				// req.url += `$filter=workspace_key eq '${wsData[wsType].key}' and workspace_value eq '${wsData[wsType].value}' `
			} else {
				req.url += '&workspace=' + JSON.stringify(wsData[wsType].props)
			}
		}
	}

	return req
}

export const getWsQueryTicketing = ({ wsType, url, body, method, config, oData }) => {
	let wsDat = localStorage.getItem('workspace')
	method = method || 'get'
	let wsData = wsDat && wsDat != undefined ? JSON.parse(wsDat) : null
	method = method.toLowerCase()
	//
	if (wsData && wsData[wsType]) {
		if (method == 'post' || method == 'put' || (method == 'delete' && body)) {
			// append to body
			body = Object.assign({}, body, { workspace: { key: wsData[wsType].key, value: wsData[wsType].value } })
		} else if (method == 'get' && wsData[wsType].key && wsData[wsType].value) {
			if (url.indexOf('$filter=') != -1) {
				url = url.replace('$filter=', `$filter=workspace_key eq '${wsData[wsType].key}' and workspace_value eq '${wsData[wsType].value}' and `)
			} else if (oData) {
				url += `&$filter=workspace_key eq '${wsData[wsType].key}' and workspace_value eq '${wsData[wsType].value}' `
			} else {
				url += '&workspace=' + JSON.stringify({ key: wsData[wsType].key, value: wsData[wsType].value })
			}
		}
	}

	return { url, body }
}

export const getWsQueryGetTicketing = ({ wsType, url, method, oData }) => {
	let wsDat = localStorage.getItem('workspace')
	method = method || 'get'
	let wsData = wsDat && wsDat != undefined ? JSON.parse(wsDat) : null
	method = method.toLowerCase()
	if (wsData && wsData[wsType]) {
		if (method == 'get' && wsData[wsType].key && wsData[wsType].value) {
			if (url.indexOf('$filter=') != -1) {
				url = url.replace('$filter=', `$filter=workspace_key eq '${wsData[wsType].key}' and workspace_value eq '${wsData[wsType].value}' and `)
			} else if (oData) {
				url += `&$filter=workspace_key eq '${wsData[wsType].key}' and workspace_value eq '${wsData[wsType].value}' `
			}
			// else {
			//     url += '&workspace=' + JSON.stringify({key: wsData[wsType].key, value: wsData[wsType].value})
			// }
		}
		// else {
		//     return false
		// }
	}

	return url
}

export const getWsBodyPostTicketing = ({ wsType, body, method, oData }) => {
	let wsDat = localStorage.getItem('workspace')
	method = method || 'post'
	let wsData = wsDat && wsDat != undefined ? JSON.parse(wsDat) : null
	method = method.toLowerCase()
	//
	if (wsData && wsData[wsType]) {
		if (method == 'post' || method == 'put' || (method == 'delete' && body)) {
			// append to body
			body = Object.assign({}, body, { workspace: { key: wsData[wsType].key, value: wsData[wsType].value } })
		} else {
			return {}
		}
	}

	return body
}
