/**
 * RU on all settings including whitelisting
 * This is in common as all apps would need it
 * Setting Key value pairs come from db of the app.
 */
import { Entity } from '../../entity/entity'
// import {EntityComponent} from '../entity'
import { methodSchemas, entitySchema, collectionSchemas } from './settings.schemas'
import { alertActions } from '../../..'
// import {    baseConstants} from '../../entity';
import moment from 'moment-timezone'
/**
 * Instructions: 
 * 1. For simple usage steps:
 * a. fieldsMeta, actionsMeta, screens, sectionsMeta definition and creation of BaseEntity obj with these inputs
 * 2. For simple overrides of baseEntity 
 * a. create an extended class of baseEntity and override its methods...
 * 3. For more customization
 * a. create a derived Service, derived actions and use them in derived BasedEntity class.
 * b. for component customization pass those components to the constuctor of derived BaseEntity class. SearchList component is the root component.
 *
 * 
 */


class SettingsEntity extends Entity {
    // override the following if needed.
    // derived actionObj
    // derived Service obj
    // getActionFns if you want to suppress the baseActionobj methods
    getActionFns() {
        let fns = super.getActionFns()
        let that = this
        return {
            ...fns,

            addNewItem: (itemData, customerTenantID) => {
                // No Create allowed on Settings.
                return dispatch => {
                    let errorMsg = 'Creating ' + that.name + ' is not allowed'
                    dispatch(fns.failure('ADDNEW', errorMsg));
                    dispatch(alertActions.error(errorMsg));
                }
            },
            deleteItem: (id, customerTenantID) => {
                // No Create allowed on Settings.
                return dispatch => {
                    let errorMsg = 'Deleting ' + that.name + ' is not allowed'
                    dispatch(fns.failure('DELETE', errorMsg));
                    dispatch(alertActions.error(errorMsg));
                }
            }
        }
    }
}


// const cardsMeta = {
//     myResponseSLA: {
//         title: 'My ResponseSLA',
//         // metrics:{name: 'responseSLA', fields:['responseSLA'], operations:['mean'] },
//         api: {
//             path: '/metrics',
//             method: 'GET',
//             response: {
//                 ref: 'data', // ref key .. could be defaulted to body. Prop that holds the collection
//                 valueKey: 'caseId'
//             },
//             filterFields: ['createdDate'] // notifies page to inject this filter if page filter has it.
//             // page filters are always added dynamically by the page.
//         },
//         dependsOn: ['createdDate' ],
//         classes: 'sm'
//     },
// }
const fieldsMeta = {
    key: {
        label: 'Setting Name',
        min: 3,
        max: 20,
        type: 'text',
        required: true,
        placeholder: 'Please specify the key name',
        readOnly: true,
        regexPattern: '/[a-zA-Z]+\\w*/',
        width: '20%',
        name: 'key'
    },
    value: {
        label: 'Value',
        required: true,
        type: 'text',
        name: 'value',
        width: '20%'
    },
    status: {
        label: "Status",
        name: 'status',
        options: [{ label: 'Published' }, { label: 'Draft' }],
        type: 'select',
        required: true,
        default: 'Published',
        width: '20%'

    },
    updatedBy: {
        label: 'Last Edited By',
        name: 'updatedBy',
        readOnly: true,
        type: 'text'
    },
    updatedAt: {
        label: 'Last Edited At',
        name: 'updatedAt',
        readOnly: true,
        type: 'datetime-local'
    },
    subject: {
        label: 'Subject',
        min: 3,
        max: 20,
        type: 'text',
        required: true,
        placeholder: 'Please specify the key name',
        readOnly: true,
        regexPattern: '/[a-zA-Z]+\\w*/',
        width: '20%',
        name: 'subject'
    },
    category: {
        label: 'Category',
        min: 3,
        max: 20,
        type: 'select',
        required: true,
        placeholder: 'Please specify the key name',
        options: [{ label: 'catA' }, { label: 'catB' }],
        readOnly: false,
        regexPattern: '/[a-zA-Z]+\\w*/',
        width: '20%',
        name: 'category'
    },
    subCategory: {
        label: 'Sub Category',
        min: 3,
        max: 20,
        type: 'select',
        required: true,
        placeholder: 'Please specify the key name',
        dependsOn: ['category'],
        api: {
            path: 'subcateory',
            params: { filter: ['category', 'key'] },
            response: {
                ref: 'data',
                labelKey: 'a',
                valueKey: 'b'
            }
        },
        readOnly: false,
        width: '20%',
        name: 'subCategory'
    },
    description: {
        label: 'Description',
        min: 3,
        max: 20,
        type: 'textarea',
        required: true,
        placeholder: 'Please specify the key name',
        // readOnly: true,
        regexPattern: '/[a-zA-Z]+\\w*/',
        width: '20%',
        name: 'description'
    },
    possibleRelItems: {
        label: 'Item',
        type: 'select',
        name: 'possibleRelItems',
        api: {
            path: '/getMySettings',
            method: 'GET',
            params: undefined,
            body: undefined,
            response: {
                ref: 'data', // ref key .. could be defaulted to body. Prop that holds the collection
                labelKey: 'shortDescription',
                valueKey: 'caseId'
            }
        },
    },
    relType: {
        label: 'Related As',
        type: 'select',
        options: [{ label: 'Parent Of', value: 'Parent Of' }, { label: 'Child Of', value: 'Child Of' }, { label: 'Depends On', value: 'Depends On' }, { label: 'Depended By', value: 'Depended By' }]
    }
}


let now = new Date()
let nowF = moment(now).format().slice(0, -6)
let monthBegin = new Date(now.getFullYear(), now.getMonth(), 1)
let monthBeginF =
    moment(monthBegin).tz(moment.tz.guess()).format().slice(0, -6)

const apisMeta = {
    pageMetrics: {
        name: 'pageMetrics',
        /**
         * get most metrics if not all associated with the page.
         */
        dependsOnFields: ['queue'], // page filters
        filters: [{
            field: 'createdDate',
            operator: 'is within',
            values: ['fromDate', 'toDate'],
            defaultValues: [monthBeginF, nowF] // to be injected
        }, {
            field: 'agent',
            operator: 'is',
            value: 'agent1' // to be injected
        },
        {
            field: 'queue',
            operator: 'contains',
            value: ['queue1'] // to be injected.
        }
        ],
        select: ['metricName', 'createdDate'],
        groupBy: ['queue', 'agent'], // date, metricname are default grouped
        groupByAgg: [{ field: 'metric', operation: 'average', as: 'metric' }],
        path: '/tickets/metrics',
        method: 'POST',

        // response: {
        //     targetProp: 'metrics'
        // },
        orderBy: [{ field: 'createdDate', order: 'desc' }]
    }
}

const actionsMeta = {
    doSomething: {
        type: 'button',
        label: 'Click Me For Some Action',
        action: 'addLinkedItem',
        name: 'doSomething'
    },
    editMap: {
        label: 'Edit Map',
        name: 'editMap',
        action: 'showMap',
        condition: 'typeof value === "object"',

    },
    save: {
        label: 'Save',
        name: 'save',
        action: 'save'
    },
    cancel: {
        label: 'Cancel',
        name: 'cancel',
        action: 'cancelEdit'
    },
    update: {
        label: 'Edit',
        name: 'update',
        action: 'updateItem',
        classes: 'fa fa-pencil fa-lg ',
        type: 'span'
    },
    list: {
        label: 'List All',
        name: 'list',
        action: 'load'
    },
    search: {
        label: 'Search',
        name: 'search',
        action: 'search'
    },
    view: {
        label: 'View',
        name: 'view',
        action: 'getItem',
        classes: 'fa fa-eye fa-lg',
        type: 'span'
    }
    //  Create:{
    //      label: 'Add Setting',
    //      action: 'addItem',
    // title: "Add New Setting"
    //  }
}
const cardsMeta = {
    myResponseSLA: {
        title: 'My Response SLA',
        metrics: [{ name: 'responseSLA', fields: ['metric'], operations: ['mean'], groupBy: ['queue'] }],
        filters: [{ field: 'metricName', value: 'responseSLA' }, { field: 'agent', value: 'agent1' }],
        size: 'xs',
        // dynFilters: {
        // createdDate: { operator: 'range', values: ['fromDate', 'toDate'] },
        // queue: 'queue'
        // },

        // api: {
        //     path: '/metrics',
        //     method: 'GET',
        //     response: {
        //         ref: 'data', // ref key .. could be defaulted to body. Prop that holds the collection
        //     },
        //     select: ['metricsName'],
        //     dependsOnFields: ['createdDt'],
        //     filters: [{ field: 'metricsName', operator: 'is', value: 'responseSLA' }, { field: 'createdDate', operator: 'is within', value: [/** to be injected */] }, { field: 'agent', operator: 'is', value: '' }, { andOrOr: 'or', field: 'queue', operator: 'contains', value: '' }],
        //     groupBy: ['queue', '']

        //     // page filters are always added dynamically by the page.
        // },
        dependsOnFields: ['fromDate', 'toDate', 'queue'],
        // dataProp: 'data', jsonpath to the rows collection.
        bodySuffix: 'hrs',
        classes: 'sm'
    },
    queueResponseSLA: {
        title: 'Queue Response SLA',
        metrics: [{ name: 'responseSLA', fields: ['metric'], operations: ['mean'], groupBy: ['queue'] }],
        filters: [{ field: 'metricName', value: 'responseSLA' }],
        // dynFilters: {
        //     createdDate: { operator: 'range', values: ['fromDate', 'toDate'] },
        //     queue: 'queue'
        // },
        size: 'xs',
        dependsOnFields: ['fromDate', 'toDate', 'queue'],
        // dataProp: 'data', jsonpath to the rows collection.
        bodySuffix: 'hrs',
        classes: 'sm'
    },
    myResolutionSLA: {
        title: 'My Resolution SLA',
        metrics: [{ name: 'resolutionSLA', fields: ['metric'], operations: ['mean'], groupBy: ['queue'] }],
        filters: [{ field: 'metricName', value: 'resolutionSLA' }, { field: 'agent', value: 'agent1' }],
        size: 'xs',
        // dynFilters: {
        //     createdDate: { operator: 'range', values: ['fromDate', 'toDate'] },
        //     queue: 'queue'
        // },

        // api: {
        //     path: '/metrics',
        //     method: 'GET',
        //     response: {
        //         ref: 'data', // ref key .. could be defaulted to body. Prop that holds the collection
        //     },
        //     select: ['metricsName'],
        //     dependsOnFields: ['createdDt'],
        //     filters: [{ field: 'metricsName', operator: 'is', value: 'resolutionSLA' }, { field: 'createdDate', operator: 'is within', value: [/** to be injected */] }, { field: 'agent', operator: 'is', value: '' }, { andOrOr: 'or', field: 'queue', operator: 'contains', value: '' }],
        //     groupBy: ['queue', '']

        //     // page filters are always added dynamically by the page.
        // },
        dependsOnFields: ['fromDate', 'toDate', 'queue'],
        // dataProp: 'data', jsonpath to the rows collection.
        bodySuffix: 'hrs',
        classes: 'sm'
    },
    queueResolutionSLA: {
        title: 'Queue Resolution SLA',
        metrics: [{ name: 'resolutionSLA', fields: ['metric'], operations: ['mean'], groupBy: ['queue'] }],
        filters: [{ field: 'metricName', value: 'resolutionSLA' }],
        size: 'xs',
        // dynFilters: {
        //     createdDate: { operator: 'range', values: ['fromDate', 'toDate'] },
        //     queue: 'queue'
        // },
        dependsOnFields: ['fromDate', 'toDate', 'queue'],
        // dataProp: 'data', jsonpath to the rows collection.
        bodySuffix: 'hrs',
        classes: 'sm'
    }
}
const sectionsMeta = {
    cards: {
        cols: [3, 3, 3, 3],
        items: [{ name: 'myResponseSLA', type: 'card', col: 1 }, { name: 'queueResponseSLA', type: 'card', col: 2 }, { name: 'myResolutionSLA', type: 'card', col: 3 }, { name: 'queueResolutionSLA', type: 'card', col: 4 }]
    },

    general: {
        items: [{ name: 'key', type: 'field' }, { name: 'value', type: 'field' }, { name: 'status', type: 'field' }, { name: 'updatedBy', type: 'field' }, { name: 'updatedAt', type: 'field' }]
    },
    basic: {
        title: 'Query Details',
        items: [{ name: 'updatedAt', type: 'field' }, { name: 'key', type: 'field' }, { name: 'category', type: 'field' }, { name: 'subCategory', type: 'field' }, { name: 'description', type: 'field' }]
    },

    // object: {
    //     object: {        ref: 'value' }
    //       // show all value object keys as grid key values. All fields are defaulted to text. If value is not of type object then this section is ignored.

    // },
    headerComponent: {
        component: 'DetailHeader',

        // props: [] by default pass all parent props as props to the component.
    },
    ticketForm: {
        cols: [9, 3],
        items: [{ name: 'basic', type: 'section', col: 1 },
        { name: 'documents', type: 'section', col: 1 },
        { name: 'doSomething', type: 'action', col: 1 },
        { name: 'tabGroup1', type: 'tabGroup', col: 1, position: 1 },
        // {name: 'notifications', type: 'section', col: 1, position: 1},
        // {name: 'relatedCases', type: 'section', col: 1, position: 2},
        { name: 'stats', type: 'section', col: 2 },
        { name: 'userInfo', type: 'section', col: 2 }],
        classes: 'composite-inner-form'
    },
    documents: {
        component: 'DocumentList', // contains upload control too
        props: { docs: 'itemDocuments' }, // means DocumentList will get props.docs with value of collection = itemDocuemnts

    },
    notifications: {
        title: 'All Notes',
        type: 'tab',
        items: [
            { name: 'notesList', type: 'section' },
            { name: 'notesCtrl', type: 'section' },
        ]
    },
    relatedCases: {
        title: 'Related Cases',
        items: [{ name: 'itemRelCtrl', type: 'section' }, { name: 'relList', type: 'section' }],
        type: 'tab'
    },
    stats: {
        type: 'group',
        items: [{ name: 'status', type: 'field' }, { name: 'submittedBy', type: 'field' }, { name: 'requestedFor', type: 'field' }, { name: 'assignedTo', type: 'field' }
        ],
        classes: 'section-stat'
    },
    userInfo: {
        type: 'group',
        items: [{ name: 'email', type: 'field' }, { name: 'firstName', type: 'field' }, { name: 'lastName', type: 'field' }, { name: 'location', type: 'field' }]
    },
    notesCtrl: {
        component: 'NotesControl',
        otherProps: { collectionProp: 'notes' }
    },
    notesList: {
        component: 'NotesList',
        props: { list: 'notes' }
    },
    relList: {
        component: 'List',
        props: { list: 'relatedCases' }
    },
    itemRelCtrl: {
        component: 'RelationControl', // source1 and its fields, source2 and its fields, relation fields , source1List, source2List
        // propsMeta: { fields: ['relType', 'possibleRelItems'] },
        // source 1 and source 2 are getting related.
        // source 1 and source 2 can both be dynamic or either static.
        // static would mean they can come from meta definition  ( not usual case)
        // dynamic ( usual case) can link to a api linked field or api linked list.
        // if api linked field can be part of meta definition of the field.
        // if api linked list have a list meta to get the lists from apis
        // lists: [{ name: 'myItems', as: 'source2' }],
        // source2: {type: 'action', name: 'relatedItems'}
        // props:{ source2List: 'relCases'} // these are picked from entityValues obj
    }


    // component: {component: {
    //     name: 'objComponent',
    //     props: [],
    //     propFns: []
    // }}
    // heading:{
    //     items:[{name: 'screenHeading', type: 'static', col: 1 }, 
    //     {name:'listLink', type:'link', col: 2}],
    //     cols: [6,6]
    // },
    // iconAndButtons:{
    //     items: [{name: 'itemIcon', type: 'other', col: 1 }, {name:'itemRef', type:'static', col: 1}, {name: 'buttonGroup', type: 'section', col: 2}],
    //     cols: [3,9]
    // },
    // buttonGroup: {
    //     items:  []
    // }
}


const tabsMeta = {
    tabGroup1: {
        tabs: [{
            name: 'notifications',
            type: 'section'
        }, {
            name: 'relatedCases',
            type: 'section'
        }],
        defaultActive: '1',
        name: 'tabGroup1'
    }
}

const screens = {
    // edit: {
    //     title:{label: 'Edit Settings', col: 1, },
    //     items: [{name:'heading', type: 'section'}],
    //     actions: ['editMap', 'cancel', 'save']
    // },
    view: {
        items: [{ name: 'headerComponent', type: 'section' }, { name: 'ticketForm', type: 'section' }],
        classes: 'composite-form',
        title: 'Ticket Details',
        loadLists: [{ itemType: 'notes', filter: { caseId: '' }, }]
    },
    edit: {
        items: [{ name: 'headerComponent', type: 'section' }, { name: 'ticketForm', type: 'section' }]
    },
    // view: {
    //     sections: ['general', 'details'],
    //     actions: ['edit']
    // },
    list: {
        renderItems: [{ name: 'cards', type: 'section' }],
        items: [{ name: 'key', type: 'link' }, { name: 'value', type: 'field' }, { name: 'status', type: 'field' }, { name: 'updatedBy', type: 'field' }, { name: 'updatedAt', type: 'field' }, { name: 'actions', type: 'actions', actions: ['view', 'update'] }],
        apis: ['pageMetrics'],
        identifierField: 'key',
        // gridItems: ['key', 'value', 'status', 'updatedBy'],
        // cardItems: [ 'key', 'status', 'updatedBy']

        // actions: ['view', 'update']
    },
    search: {
        items: [{ name: 'key', type: 'field' }, { name: 'value', type: 'field' }]
    }
}
// const components = { searchList: EntityComponent} // can overrides the components like this if needed
const linkedItemTypes = {
    notes: {
        itemType: 'notes',
        urlGetAllPath: '/ticketing/notes/:caseId',
        urlCreatePath: '/ticketing/notes/:caseId',
        urlEditPath: '/ticketing/notes/:caseId',
        urlEditMethod: 'PUT'
    }
}

// const recordValidator = (recs) => {
//     let errors = recs && recs.pop && recs.map((r, i) => {
//         let e

//         if (r.key == undefined || r.key === '') e = `${keys.key.header} is mandatory`

//     })
// }
const keys = [
    'key',
    'value',
    'status'
]

const Settings = new SettingsEntity({ fieldsMeta, actionsMeta, sectionsMeta, tabsMeta, cardsMeta, apisMeta, screens, name: 'Settings', title: 'Settings', methodSchemas, collectionSchemas, entitySchema, idKey: 'key', linkedItemTypes, importKeys: keys })

const SettingsListSearchContainer = Settings.getContainer('ListSearch')
const SettingsCreateContainer = Settings.getContainer('Create')
const SettingsDetailContainer = Settings.getContainer('Detail')
const SettingsImportContainer = Settings.getContainer('ImportData')
export { SettingsListSearchContainer, SettingsCreateContainer, SettingsDetailContainer, SettingsImportContainer }