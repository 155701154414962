import React, {Component} from "react";

class SetupTenant extends Component {


  render() {
    console.log(this.props.tenantLifeCycle)
    return (    <div className="step3 steps progress-screen clearfix">
                  <div className="setup-progress">
                  <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
                                <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/></svg> 
                                <h4>We are now done with bot configuration , proceed to the next step </h4>
                  </div>
            <button className="btn btn-primary" onClick={this.props.nextPage}>Next</button> 
            </div>
    )}
  }

export default SetupTenant;