import { i18n } from '../..'
import { alertActions } from '../../'
// import {appRootPath} from '../../../../config'
import { history } from '../../../../redux/history'
import * as baseConstants from '../../constants/base.constants'
import { ticketingHelperFunc } from '../helpers'
//import CommonBaseActions from '../../common/ticketing/actions/CommonBaseActions'
//let CommonBaseActions = require('../../common/ticketing/actions/CommonBaseActions')

const toastMessages = ticketingHelperFunc.getToastMessages()

export default class BaseActions {
	constructor(options) {
		//super(options)
		this.service = options.service
		this.resourceName = options.resourceName
		this.toastMessage = toastMessages[options.resourceName]
	}
	getAll(tenantId, queryStringArr, targetTenantDomain, isAdavanceSearch = false, t, apiUrl, headerData) {
		let that = this
		return dispatch => {
			dispatch(that.request('GETALL', targetTenantDomain))
			try {
				that.service.getAll(tenantId, queryStringArr, targetTenantDomain, isAdavanceSearch, apiUrl, headerData).then(
					item => {
						if (item) {
							dispatch(that.success('GETALL', item, targetTenantDomain))
						} else {
							//let errorMsg = 'Cannot fetch ' + that.resourceName +  ' at this moment please try again later'
							let errorMsg = i18n.t(that.toastMessage.getFailure || 'Cannot fetch ' + that.resourceName + ' at this moment please try again later')
							dispatch(that.failure('GETALL', errorMsg, targetTenantDomain))
							dispatch(alertActions.error(errorMsg))
						}
					},
					error => {
						// let errorMsg = 'Cannot fetch ' + that.resourceName +  ' at this moment please try again later'
						let errorMsg = i18n.t(that.toastMessage.getFailure || 'Cannot fetch ' + that.resourceName + ' at this moment please try again later')
						dispatch(that.failure('GETALL', errorMsg, targetTenantDomain))
						dispatch(alertActions.error(errorMsg))
					}
				)
			} catch (e) {
				let errorMsg = i18n.t(that.toastMessage.getFailure || 'Cannot fetch ' + that.resourceName + ' at this moment please try again later')
				dispatch(that.failure('GETALL', errorMsg, targetTenantDomain))
				dispatch(alertActions.error(errorMsg))
			}
		}
	}

	getSubmittedByTickets(tenantId, queryStringArr, sortOrder, targetTenantDomain, t, apiUrl) {
		let that = this
		return dispatch => {
			dispatch(that.request('GETALL'))
			that.service.getSubmittedByTickets(tenantId, queryStringArr, sortOrder, targetTenantDomain, apiUrl).then(
				item => {
					if (item) {
						dispatch(that.success('GETALL', item))
					} else {
						//let errorMsg = 'Cannot fetch ' + that.resourceName +  ' at this moment please try again later'
						let errorMsg = i18n.t(that.toastMessage.getFailure || 'Cannot fetch ' + that.resourceName + ' at this moment please try again later')
						dispatch(that.failure('GETALL', errorMsg))
						dispatch(alertActions.error(errorMsg))
					}
				},
				error => {
					// let errorMsg = 'Cannot fetch ' + that.resourceName +  ' at this moment please try again later'
					let errorMsg = i18n.t(that.toastMessage.getFailure || 'Cannot fetch ' + that.resourceName + ' at this moment please try again later')
					dispatch(that.failure('GETALL', errorMsg))
					dispatch(alertActions.error(errorMsg))
				}
			)
		}
	}

	getTicketsByFilter(tenantId, queryStringArr, sortOrder, targetTenantDomain, t, apiUrl) {
		let that = this
		return dispatch => {
			dispatch(that.request('GETALL'))
			that.service.getTicketsByFilter(tenantId, queryStringArr, sortOrder, targetTenantDomain, apiUrl).then(
				item => {
					if (item) {
						dispatch(that.success('GETALL', item))
					} else {
						//let errorMsg = 'Cannot fetch ' + that.resourceName +  ' at this moment please try again later'
						let errorMsg = i18n.t(that.toastMessage.getFailure || 'Cannot fetch ' + that.resourceName + ' at this moment please try again later')
						dispatch(that.failure('GETALL', errorMsg))
						dispatch(alertActions.error(errorMsg))
					}
				},
				error => {
					// let errorMsg = 'Cannot fetch ' + that.resourceName +  ' at this moment please try again later'
					let errorMsg = i18n.t(that.toastMessage.getFailure || 'Cannot fetch ' + that.resourceName + ' at this moment please try again later')
					dispatch(that.failure('GETALL', errorMsg))
					dispatch(alertActions.error(errorMsg))
				}
			)
		}
	}

	getAllWatchingTickets(tenantId, queryStringArr, moduleName, sortOrder, searchParams, targetTenantDomain, t, apiUrl) {
		let that = this
		return dispatch => {
			dispatch(that.request('GETALL'))
			try {
				that.service.getAllWatchingTickets(tenantId, queryStringArr, sortOrder, searchParams, targetTenantDomain, apiUrl).then(
					item => {
						if (item) {
							dispatch(that.success('GETALL', item))
						} else {
							//let errorMsg = 'Cannot fetch ' + that.resourceName +  ' at this moment please try again later'
							let errorMsg = i18n.t(that.toastMessage.getFailure || 'Cannot fetch ' + that.resourceName + ' at this moment please try again later')
							dispatch(that.failure('GETALL', errorMsg))
							dispatch(alertActions.error(errorMsg))
						}
					},
					error => {
						// let errorMsg = 'Cannot fetch ' + that.resourceName +  ' at this moment please try again later'
						let errorMsg = i18n.t(that.toastMessage.getFailure || 'Cannot fetch ' + that.resourceName + ' at this moment please try again later')
						dispatch(that.failure('GETALL', errorMsg))
						dispatch(alertActions.error(errorMsg))
					}
				)
			} catch (e) {
				let errorMsg = i18n.t(that.toastMessage.getFailure || 'Cannot fetch ' + that.resourceName + ' at this moment please try again later')
				console.log(e)
				dispatch(that.failure('GETALL', errorMsg))
				dispatch(alertActions.error(errorMsg))
			}
		}
	}
	getAllMSPAssignedTickets(tenantId, queryStringArr, moduleName, sortOrder, searchParams, targetTenantDomain, t, apiUrl) {
		let that = this
		return dispatch => {
			dispatch(that.request('GETALL'))
			try {
				that.service.getAllMSPAssignedTickets(tenantId, queryStringArr, sortOrder, searchParams, targetTenantDomain, apiUrl).then(
					item => {
						if (item) {
							dispatch(that.success('GETALL', item))
						} else {
							//let errorMsg = 'Cannot fetch ' + that.resourceName +  ' at this moment please try again later'
							let errorMsg = i18n.t(that.toastMessage.getFailure || 'Cannot fetch ' + that.resourceName + ' at this moment please try again later')
							dispatch(that.failure('GETALL', errorMsg))
							dispatch(alertActions.error(errorMsg))
						}
					},
					error => {
						// let errorMsg = 'Cannot fetch ' + that.resourceName +  ' at this moment please try again later'
						let errorMsg = i18n.t(that.toastMessage.getFailure || 'Cannot fetch ' + that.resourceName + ' at this moment please try again later')
						dispatch(that.failure('GETALL', errorMsg))
						dispatch(alertActions.error(errorMsg))
					}
				)
			} catch (e) {
				let errorMsg = i18n.t(that.toastMessage.getFailure || 'Cannot fetch ' + that.resourceName + ' at this moment please try again later')
				console.log(e)
				dispatch(that.failure('GETALL', errorMsg))
				dispatch(alertActions.error(errorMsg))
			}
		}
	}

	getAllMSPAssignedQueueTickets(tenantId, queryStringArr, moduleName, sortOrder, searchParams, targetTenantDomain, t, apiUrl) {
		let that = this
		return dispatch => {
			dispatch(that.request('GETALL'))
			try {
				that.service.getAllMSPAssignedQueueTickets(tenantId, queryStringArr, sortOrder, searchParams, targetTenantDomain, apiUrl).then(
					item => {
						if (item) {
							dispatch(that.success('GETALL', item))
						} else {
							//let errorMsg = 'Cannot fetch ' + that.resourceName +  ' at this moment please try again later'
							let errorMsg = i18n.t(that.toastMessage.getFailure || 'Cannot fetch ' + that.resourceName + ' at this moment please try again later')
							dispatch(that.failure('GETALL', errorMsg))
							dispatch(alertActions.error(errorMsg))
						}
					},
					error => {
						// let errorMsg = 'Cannot fetch ' + that.resourceName +  ' at this moment please try again later'
						let errorMsg = i18n.t(that.toastMessage.getFailure || 'Cannot fetch ' + that.resourceName + ' at this moment please try again later')
						dispatch(that.failure('GETALL', errorMsg))
						dispatch(alertActions.error(errorMsg))
					}
				)
			} catch (e) {
				let errorMsg = i18n.t(that.toastMessage.getFailure || 'Cannot fetch ' + that.resourceName + ' at this moment please try again later')
				console.log(e)
				dispatch(that.failure('GETALL', errorMsg))
				dispatch(alertActions.error(errorMsg))
			}
		}
	}

	getAllPendingWithMeTickets(tenantId, queryStringArr, moduleName, sortOrder, searchParams, targetTenantDomain, t, apiUrl) {
		let that = this
		return dispatch => {
			dispatch(that.request('GETALL'))
			try {
				that.service.getAllPendingWithMeTickets(tenantId, queryStringArr, sortOrder, searchParams, targetTenantDomain, apiUrl).then(
					item => {
						if (item) {
							dispatch(that.success('GETALL', item))
						} else {
							//let errorMsg = 'Cannot fetch ' + that.resourceName +  ' at this moment please try again later'
							let errorMsg = i18n.t(that.toastMessage.getFailure || 'Cannot fetch ' + that.resourceName + ' at this moment please try again later')
							dispatch(that.failure('GETALL', errorMsg))
							dispatch(alertActions.error(errorMsg))
						}
					},
					error => {
						// let errorMsg = 'Cannot fetch ' + that.resourceName +  ' at this moment please try again later'
						let errorMsg = i18n.t(that.toastMessage.getFailure || 'Cannot fetch ' + that.resourceName + ' at this moment please try again later')
						dispatch(that.failure('GETALL', errorMsg))
						dispatch(alertActions.error(errorMsg))
					}
				)
			} catch (e) {
				let errorMsg = i18n.t(that.toastMessage.getFailure || 'Cannot fetch ' + that.resourceName + ' at this moment please try again later')
				console.log(e)
				dispatch(that.failure('GETALL', errorMsg))
				dispatch(alertActions.error(errorMsg))
			}
		}
	}

	getAssignedTickets(tenantId, queryStringArr, sortOrder, searchParams, t, apiUrl) {
		let that = this

		return dispatch => {
			dispatch(that.request('GETALL'))
			try {
				that.service.getAssignedTickets(tenantId, queryStringArr, sortOrder, searchParams, apiUrl).then(
					item => {
						if (item) {
							dispatch(that.success('GETALL', item))
						} else {
							//let errorMsg = 'Cannot fetch ' + that.resourceName +  ' at this moment please try again later'
							let errorMsg = i18n.t(that.toastMessage.getFailure || 'Cannot fetch ' + that.resourceName + ' at this moment please try again later')
							dispatch(that.failure('GETALL', errorMsg))
							dispatch(alertActions.error(errorMsg))
						}
					},
					error => {
						//let errorMsg = 'Cannot fetch ' + that.resourceName +  ' at this moment please try again later'
						let errorMsg = i18n.t(that.toastMessage.getFailure || 'Cannot fetch ' + that.resourceName + ' at this moment please try again later')
						dispatch(that.failure('GETALL', errorMsg))
						dispatch(alertActions.error(errorMsg))
					}
				)
			} catch (e) {
				let errorMsg = i18n.t(that.toastMessage.getFailure || 'Cannot fetch ' + that.resourceName + ' at this moment please try again later')
				dispatch(that.failure('GETALL', errorMsg))
				dispatch(alertActions.error(errorMsg))
			}
		}
	}
	getMyAllTickets(tenantId, queryStringArr, sortOrder, searchParams, t, apiUrl) {
		let that = this

		return dispatch => {
			dispatch(that.request('GETALL'))
			try {
				that.service.getMyAllTickets(tenantId, queryStringArr, sortOrder, searchParams, apiUrl).then(
					item => {
						if (item) {
							dispatch(that.success('GETALL', item))
						} else {
							//let errorMsg = 'Cannot fetch ' + that.resourceName +  ' at this moment please try again later'
							let errorMsg = i18n.t(that.toastMessage.getFailure || 'Cannot fetch ' + that.resourceName + ' at this moment please try again later')
							dispatch(that.failure('GETALL', errorMsg))
							dispatch(alertActions.error(errorMsg))
						}
					},
					error => {
						//let errorMsg = 'Cannot fetch ' + that.resourceName +  ' at this moment please try again later'
						let errorMsg = i18n.t(that.toastMessage.getFailure || 'Cannot fetch ' + that.resourceName + ' at this moment please try again later')
						dispatch(that.failure('GETALL', errorMsg))
						dispatch(alertActions.error(errorMsg))
					}
				)
			} catch (e) {
				let errorMsg = i18n.t(that.toastMessage.getFailure || 'Cannot fetch ' + that.resourceName + ' at this moment please try again later')
				dispatch(that.failure('GETALL', errorMsg))
				dispatch(alertActions.error(errorMsg))
			}
		}
	}

	getAllTickets(tenantId, queryStringArr, sortOrder, t, apiUrl) {
		let that = this

		return dispatch => {
			dispatch(that.request('GETALL'))
			try {
				that.service.getAllTickets(tenantId, queryStringArr, sortOrder, apiUrl).then(
					item => {
						if (item) {
							dispatch(that.success('GETALL', item))
						} else {
							//let errorMsg = 'Cannot fetch ' + that.resourceName +  ' at this moment please try again later'
							let errorMsg = i18n.t(that.toastMessage.getFailure || 'Cannot fetch ' + that.resourceName + ' at this moment please try again later')
							dispatch(that.failure('GETALL', errorMsg))
							dispatch(alertActions.error(errorMsg))
						}
					},
					error => {
						//let errorMsg = 'Cannot fetch ' + that.resourceName +  ' at this moment please try again later'
						let errorMsg = i18n.t(that.toastMessage.getFailure || 'Cannot fetch ' + that.resourceName + ' at this moment please try again later')
						dispatch(that.failure('GETALL', errorMsg))
						dispatch(alertActions.error(errorMsg))
					}
				)
			} catch (e) {
				let errorMsg = i18n.t(that.toastMessage.getFailure || 'Cannot fetch ' + that.resourceName + ' at this moment please try again later')
				dispatch(that.failure('GETALL', errorMsg))
				dispatch(alertActions.error(errorMsg))
			}
		}
	}

	getSearchItems(tenantId, queryStringArr, moduleName, sortOrder, t, apiUrl) {
		let that = this
		return dispatch => {
			dispatch(that.request('GETALL'))

			try {
				that.service.getSearchItems(tenantId, queryStringArr, sortOrder, apiUrl).then(
					item => {
						if (item) {
							dispatch(that.success('GETALL', item))
						} else {
							//let errorMsg = 'Cannot fetch ' + that.resourceName +  ' at this moment please try again later'
							let errorMsg = i18n.t(that.toastMessage.getFailure || 'Cannot fetch ' + that.resourceName + ' at this moment please try again later')
							dispatch(that.failure('GETALL', errorMsg))
							dispatch(alertActions.error(errorMsg))
						}
					},
					error => {
						//let errorMsg = 'Cannot fetch ' + that.resourceName +  ' at this moment please try again later'
						let errorMsg = i18n.t(that.toastMessage.getFailure || 'Cannot fetch ' + that.resourceName + ' at this moment please try again later')
						dispatch(that.failure('GETALL', errorMsg))
						dispatch(alertActions.error(errorMsg))
					}
				)
			} catch (e) {
				let errorMsg = i18n.t(that.toastMessage.getFailure || 'Cannot fetch ' + that.resourceName + ' at this moment please try again later')
				dispatch(that.failure('GETALL', errorMsg))
				dispatch(alertActions.error(errorMsg))
			}
		}
	}

	addWithParams(itemData, id, customerTenantID, type, t, apiUrl) {
		let that = this
		return dispatch => {
			dispatch(that.request('ADDNEW'))

			try {
				that.service.addNewWithParams(itemData, id, customerTenantID, apiUrl).then(
					item => {
						if (item) {
							dispatch(that.success('ADDNEW', item))
							if (!type) dispatch(alertActions.success(i18n.t(that.toastMessage.addSuccess || that.resourceName + ' added successfully')))
						} else {
							//let errorMsg = "Cannot insert new "+ that.resourceName+ " at this moment please try again later"
							let errorMsg = i18n.t(that.toastMessage.getFailure || 'Cannot fetch ' + that.resourceName + ' at this moment please try again later')
							dispatch(that.failure('ADDNEW', errorMsg))
							dispatch(alertActions.error(errorMsg))
						}
					},
					error => {
						//let errorMsg = "Cannot insert new "+ that.resourceName+ " at this moment please try again later" + (error?error.message:'')
						let errorMsg = i18n.t(that.toastMessage.getFailure || 'Cannot fetch ' + that.resourceName + ' at this moment please try again later')
						dispatch(that.failure('ADDNEW', errorMsg))
						dispatch(alertActions.error(errorMsg))
					}
				)
			} catch (e) {
				let errorMsg = i18n.t(that.toastMessage.getFailure || 'Cannot fetch ' + that.resourceName + ' at this moment please try again later')
				dispatch(that.failure('ADDNEW', errorMsg))
				dispatch(alertActions.error(errorMsg))
			}
		}
	}
	getMyQueueTickets(tenantId, queryStringArr, sortOrder, searchParams, t, apiUrl) {
		let that = this

		return dispatch => {
			dispatch(that.request('GETALL'))
			try {
				that.service.getMyQueueTickets(tenantId, queryStringArr, sortOrder, searchParams, apiUrl).then(
					item => {
						if (item) {
							dispatch(that.success('GETALL', item))
						} else {
							//let errorMsg = 'Cannot fetch ' + that.resourceName +  ' at this moment please try again later'
							let errorMsg = i18n.t(that.toastMessage.getFailure || 'Cannot fetch ' + that.resourceName + ' at this moment please try again later')
							dispatch(that.failure('GETALL', errorMsg))
							dispatch(alertActions.error(errorMsg))
						}
					},
					error => {
						//let errorMsg = 'Cannot fetch ' + that.resourceName +  ' at this moment please try again later'
						let errorMsg = that.toastMessage.getFailure || 'Cannot fetch ' + that.resourceName + ' at this moment please try again later'
						dispatch(that.failure('GETALL', errorMsg))
						dispatch(alertActions.error(errorMsg))
					}
				)
			} catch (e) {
				let errorMsg = i18n.t(that.toastMessage.getFailure || 'Cannot fetch ' + that.resourceName + ' at this moment please try again later')
				dispatch(that.failure('GETALL', errorMsg))
				dispatch(alertActions.error(errorMsg))
			}
		}
	}

	getAllWithParams(tenantId, id, queryStringArr, targetTenantDomain, t, apiUrl) {
		let that = this

		return dispatch => {
			dispatch(that.request('GETALL'))
			try {
				that.service.getAllWithParams(tenantId, id, queryStringArr, targetTenantDomain, apiUrl).then(
					item => {
						if (item) {
							dispatch(that.success('GETALL', item, targetTenantDomain))
						} else {
							//let errorMsg = 'Cannot fetch ' + that.resourceName +  ' at this moment please try again later'
							let errorMsg = i18n.t(that.toastMessage.getFailure || 'Cannot fetch ' + that.resourceName + ' at this moment please try again later')
							dispatch(that.failure('GETALL', errorMsg, targetTenantDomain))
							dispatch(alertActions.error(errorMsg))
						}
					},
					error => {
						//let errorMsg = 'Cannot fetch ' + that.resourceName +  ' at this moment please try again later'
						let errorMsg = i18n.t(that.toastMessage.getFailure || 'Cannot fetch ' + that.resourceName + ' at this moment please try again later')
						let jsonError = error && JSON.stringify(error)
						if (jsonError && jsonError.includes('401')) {
							history.push('/')
						} else {
							dispatch(that.failure('GETALL', errorMsg, targetTenantDomain))
							dispatch(alertActions.error(errorMsg))
						}
					}
				)
			} catch (e) {
				let errorMsg = i18n.t(that.toastMessage.getFailure || 'Cannot fetch ' + that.resourceName + ' at this moment please try again later')
				dispatch(that.failure('GETALL', errorMsg, targetTenantDomain))
				dispatch(alertActions.error(errorMsg))
			}
		}
	}

	deleteItemWithParams(id, paramId, customerTenantID, t, apiUrl) {
		let that = this
		return dispatch => {
			dispatch(that.request('DELETE'))

			try {
				that.service.deleteRecordWithParams(id, paramId, customerTenantID, apiUrl).then(
					item => {
						if (item) {
							dispatch(that.success('DELETE', item))
							dispatch(alertActions.success(i18n.t(that.toastMessage.deleteSuccess || that.resourceName + ' Deleted successfully')))
						} else {
							//let errorMsg = "Cannot delete "+that.resourceName+" at this moment please try again later"
							let errorMsg = i18n.t(that.toastMessage.getFailure || 'Cannot fetch ' + that.resourceName + ' at this moment please try again later')
							dispatch(that.failure('DELETE', errorMsg))
							dispatch(alertActions.error(errorMsg))
						}
					},
					error => {
						//let errorMsg = "Cannot delete "+that.resourceName+" at this moment please try again later" + (error?error.message: '')
						let errorMsg = i18n.t(that.toastMessage.getFailure || 'Cannot fetch ' + that.resourceName + ' at this moment please try again later')
						dispatch(that.failure('DELETE', errorMsg))
						dispatch(alertActions.error(errorMsg))
					}
				)
			} catch (e) {
				let errorMsg = i18n.t(that.toastMessage.getFailure || 'Cannot fetch ' + that.resourceName + ' at this moment please try again later')
				dispatch(that.failure('DELETE', errorMsg))
				dispatch(alertActions.error(errorMsg))
			}
		}
	}

	addNewItem(itemData, customerTenantID, messageType, targetTenantDomain, userRoles, t, apiUrl, headerData) {
		let that = this
		let successMsg = null
		if (messageType && messageType.toLowerCase() === 'submit') {
			successMsg = i18n.t(that.toastMessage.addSubmit)
			if (window.location.pathname.split('/')[1] === 'approvals') successMsg = i18n.t('Request Submitted')
		} else {
			successMsg = i18n.t(that.toastMessage.addSuccess)
			if (window.location.pathname.split('/')[1] === 'approvals') successMsg = i18n.t('Request Submitted')
		}
		return dispatch => {
			dispatch(that.request('ADDNEW', targetTenantDomain))

			try {
				that.service.addNew(itemData, customerTenantID, targetTenantDomain, apiUrl, headerData).then(
					item => {
						if (item) {
							dispatch(that.success('ADDNEW', item, targetTenantDomain))
							dispatch(alertActions.success(i18n.t(successMsg || that.resourceName + ' added successfully')))
							setTimeout(function () {
								that.goBackUrl(userRoles)
							}, 1500)
						} else {
							//let errorMsg = "Cannot insert new "+ that.resourceName+ " at this moment please try again later"
							let errorMsg = that.toastMessage.addFailure || 'Cannot fetch ' + that.resourceName + ' at this moment please try again later'
							dispatch(that.failure('ADDNEW', errorMsg, targetTenantDomain))
							dispatch(alertActions.error(errorMsg))
						}
					},
					error => {
						//let errorMsg = "Cannot insert new "+ that.resourceName+ " at this moment please try again later" + (error?error.message:'')
						let errorMsg = i18n.t(that.toastMessage.addFailure || 'Cannot fetch ' + that.resourceName + ' at this moment please try again later')
						dispatch(that.failure('ADDNEW', errorMsg, targetTenantDomain))
						dispatch(alertActions.error(errorMsg))
					}
				)
			} catch (e) {
				let errorMsg = i18n.t(that.toastMessage.getFailure || 'Cannot fetch ' + that.resourceName + ' at this moment please try again later')
				dispatch(that.failure('ADDNEW', errorMsg, targetTenantDomain))
				dispatch(alertActions.error(errorMsg))
			}
		}
	}

	addNewWithParams(itemData, id, customerTenantID, targetTenantDomain, t, apiUrl) {
		let that = this
		return dispatch => {
			dispatch(that.request('ADDNEW'))

			try {
				that.service.addNewWithParams(itemData, id, customerTenantID, targetTenantDomain, apiUrl).then(
					item => {
						if (item && item.type && item.message && item.type === 'error') {
							dispatch(alertActions.error(item.message))
						} else if (item) {
							dispatch(that.success('ADDNEW', item, targetTenantDomain))
							dispatch(alertActions.success(i18n.t(that.toastMessage.addSuccess || that.resourceName + ' added successfully')))
							if(item.noteType === 'resolution'){
								let path = 'my-tickets'
								if (window.location.pathname.split('/')[1] === 'approvals') {
									let requestsTab = history && history.location && history.location.pathname && history.location.pathname.toLowerCase().includes('requests')
									if (requestsTab) path = 'my-requests'
									else path = 'my-approvals'
								}
								setTimeout(() => {
									history.push(`/${window.location.pathname.split('/')[1]}/${path}`)
								}, 4000)
							} else if (that.resourceName === 'CaseNote') {
								setTimeout(function () {
									window.location.reload()
								}, 3000)
							}
						} else {
							//let errorMsg = "Cannot insert new "+ that.resourceName+ " at this moment please try again later"
							let errorMsg = i18n.t(that.toastMessage.getFailure || 'Cannot fetch ' + that.resourceName + ' at this moment please try again later')
							dispatch(that.failure('ADDNEW', errorMsg, targetTenantDomain))
							dispatch(alertActions.error(errorMsg))
						}
					},
					error => {
						//let errorMsg = "Cannot insert new "+ that.resourceName+ " at this moment please try again later" + (error?error.message:'')
						let errorMessage
						if(itemData.properties.checkChildrenStatus){
							errorMessage = "Related cases are open, please close and try again."
						}
						let errorMsg = i18n.t(errorMessage||that.toastMessage.getFailure || 'Cannot fetch ' + that.resourceName + ' at this moment please try again later')
						dispatch(that.failure('ADDNEW', errorMsg, targetTenantDomain))
						dispatch(alertActions.error(errorMsg))
						setTimeout(function () {
							window.location.reload()
						}, 3000)
					}
				)
			} catch (e) {
				let errorMsg = i18n.t(that.toastMessage.getFailure || 'Cannot fetch ' + that.resourceName + ' at this moment please try again later')
				dispatch(that.failure('ADDNEW', errorMsg, targetTenantDomain))
				dispatch(alertActions.error(errorMsg))
			}
		}
	}

	addAttachmentWithParams(itemData, id, customerTenantID, isDirty, targetTenantDomain, t, apiUrl) {
		let that = this
		return dispatch => {
			dispatch(that.request('ADDNEW'))

			try {
				that.service.addAttachmentWithParams(itemData, id, customerTenantID, targetTenantDomain, apiUrl).then(
					item => {
						if (item) {
							dispatch(that.success('ADDNEW', item))
							!isDirty && dispatch(alertActions.success(i18n.t(that.toastMessage.addSuccess || that.resourceName + ' added successfully')))
						} else {
							//let errorMsg = "Cannot insert new "+ that.resourceName+ " at this moment please try again later"
							let errorMsg = i18n.t(that.toastMessage.getFailure || 'Cannot fetch ' + that.resourceName + ' at this moment please try again later')
							dispatch(that.failure('ADDNEW', errorMsg))
							// dispatch(alertActions.error(errorMsg));
						}
					},
					error => {
						//let errorMsg = "Cannot insert new "+ that.resourceName+ " at this moment please try again later" + (error?error.message:'')
						let errorMsg = i18n.t(that.toastMessage.getFailure || 'Cannot fetch ' + that.resourceName + ' at this moment please try again later')
						dispatch(that.failure('ADDNEW', errorMsg))
						//dispatch(alertActions.error(errorMsg));
					}
				)
			} catch (e) {
				let errorMsg = i18n.t(that.toastMessage.getFailure || 'Cannot fetch ' + that.resourceName + ' at this moment please try again later')
				dispatch(that.failure('ADDNEW', errorMsg))
				dispatch(alertActions.error(errorMsg))
			}
		}
	}
	deleteAttachmentWithParams(itemData, editId, paramId, customerTenantID, isDirty, targetTenantDomain, apiUrl) {
		let that = this
		return dispatch => {
			dispatch(that.request('DELETE'))

			try {
				that.service.editRecordWithParams(itemData, paramId, customerTenantID, editId, targetTenantDomain, apiUrl).then(
					item => {
						if (item) {
							dispatch(that.success('DELETE', item))
							dispatch(alertActions.success(i18n.t(that.toastMessage.deleteSuccess || that.resourceName + ' Deleted successfully.')))
							setTimeout(function () {
								window.location.reload()
							}, 3000)
						} else {
							//let errorMsg = "Cannot insert new "+ that.resourceName+ " at this moment please try again later"
							let errorMsg = i18n.t(that.toastMessage.getFailure || 'Cannot fetch ' + that.resourceName + ' at this moment please try again later')
							dispatch(that.failure('DELETE', errorMsg))
							dispatch(alertActions.error(errorMsg))
						}
					},
					error => {
						//let errorMsg = "Cannot insert new "+ that.resourceName+ " at this moment please try again later" + (error?error.message:'')
						let errorMsg = i18n.t(that.toastMessage.getFailure || 'Cannot fetch ' + that.resourceName + ' at this moment please try again later')
						dispatch(that.failure('DELETE', errorMsg))
						dispatch(alertActions.error(errorMsg))
					}
				)
			} catch (e) {
				let errorMsg = i18n.t(that.toastMessage.getFailure || 'Cannot fetch ' + that.resourceName + ' at this moment please try again later')
				dispatch(that.failure('DELETE', errorMsg))
				dispatch(alertActions.error(errorMsg))
			}
		}
	}

	editItem(itemData, customerTenantID, editId, targetTenantDomain, t, apiUrl) {
		let that = this
		return dispatch => {
			dispatch(that.request('EDIT'))

			that.service.editRecord(itemData, customerTenantID, editId, targetTenantDomain, apiUrl).then(
				item => {
					if (item) {
						if (item.data && !item.data.customer) dispatch(that.success('EDIT', item))
						if (item.data && item.data.customer) that.toastMessage.updateSuccess = i18n.t(that.resourceName + ` Updated successfully`)
						if (item.data && window.location.pathname.split('/')[1] === 'approvals')
							that.toastMessage.updateSuccess = i18n.t('This Request has been updated AutoRefresh in 5 sec')
						dispatch(alertActions.success(i18n.t(that.toastMessage.updateSuccess || that.resourceName + ' Updated successfully')))
						setTimeout(function () {
							window.location.reload()
						}, 5000)
						// let path = 'my-tickets'
						// if (item.data && !item.data.customer) {
						// 	if (window.location.pathname.split('/')[1] === 'approvals') {
						// 		let requestsTab = history && history.location && history.location.pathname && history.location.pathname.toLowerCase().includes('requests')
						// 		if (requestsTab) path = 'my-requests'
						// 		else path = 'my-approvals'
						// 	}
						// 	setTimeout(() => {
						// 		history.push({
						// 			pathname: `/${window.location.pathname.split('/')[1]}/${path}`,
						// 			state: {
						// 				fromDetailPage: true,
						// 			},
						// 		})
						// 	}, 3000)
						// } else {
						// 	setTimeout(function () {
						// 		window.location.reload()
						// 	}, 5000)
						// }
					} else {
						//let errorMsg = "Cannot update the "+ that.resourceName+" at this moment please try again later"
						let errorMsg = i18n.t(that.toastMessage.updateFailure || 'Cannot fetch ' + that.resourceName + ' at this moment please try again later')
						dispatch(that.failure('EDIT', errorMsg))
						dispatch(alertActions.error(errorMsg))
					}
				},
				error => {
					//let errorMsg = "Cannot update the "+ that.resourceName+" at this moment please try again later" + (error?error.message:'')
					let errorMsg = i18n.t(that.toastMessage.updateFailure || 'Cannot fetch ' + that.resourceName + ' at this moment please try again later')
					dispatch(that.failure('EDIT', errorMsg))
					dispatch(alertActions.error(errorMsg))
				}
			)
		}
	}
	updateTicketStatus(itemData, customerTenantID, editId, targetTenantDomain, t, apiUrl) {
		let that = this
		return dispatch => {
			dispatch(that.request('EDIT'))

			that.service.updateTicketStatus(itemData, customerTenantID, editId, targetTenantDomain, apiUrl).then(
				item => {
					if (item) {
						dispatch(that.success('EDIT', item))
						if (item && window.location.pathname.split('/')[1] === 'approvals') that.toastMessage.updateSuccess = 'Request Status Updated'
						dispatch(alertActions.success(i18n.t(that.toastMessage.statusUpdateSuccess || that.resourceName + ' Updated successfully')))
					} else {
						//let errorMsg = "Cannot update the "+ that.resourceName+" at this moment please try again later"
						let errorMsg = i18n.t(that.toastMessage.updateFailure || 'Cannot fetch ' + that.resourceName + ' at this moment please try again later')
						dispatch(that.failure('EDIT', errorMsg))
						dispatch(alertActions.error(errorMsg))
					}
				},
				error => {
					//let errorMsg = "Cannot update the "+ that.resourceName+" at this moment please try again later" + (error?error.message:'')
					let errorMsg = i18n.t(that.toastMessage.updateFailure || 'Cannot fetch ' + that.resourceName + ' at this moment please try again later')
					dispatch(that.failure('EDIT', errorMsg))
					dispatch(alertActions.error(errorMsg))
				}
			)
		}
	}

	notifyEmail(id, t, apiUrl) {
		let that = this
		return dispatch => {
			that.service.notifyEmail(id, apiUrl).then(
				item => {
					if (item.status === 200) {
						dispatch(alertActions.success(i18n.t(that.toastMessage.addSuccess || that.resourceName + ' added successfully')))
					} else {
						//let errorMsg = "Cannot notify at this moment please try again later"
						let errorMsg = that.toastMessage.getFailure || 'Cannot fetch ' + that.resourceName + ' at this moment please try again later'
						dispatch(alertActions.error(errorMsg))
					}
				},
				error => {
					let errorMsg = that.toastMessage.getFailure || 'Cannot fetch ' + that.resourceName + ' at this moment please try again later'
					//let errorMsg = "Cannot notify at this moment please try again later" + (error?error.message:'')
					dispatch(alertActions.error(errorMsg))
				}
			)
		}
	}

	request(method, targetTenantDomain) {
		let type
		switch (method) {
			case 'GETALL':
				type = baseConstants.GETALL_REQUEST
				break
			case 'ADDNEW':
				type = baseConstants.ADDNEW_REQUEST
				break
			case 'EDIT':
				type = baseConstants.EDIT_REQUEST
				break
			case 'DELETE':
				type = baseConstants.DELETE_REQUEST
				break
			default:
		}
		type = type + '_' + this.resourceName
		if (targetTenantDomain && targetTenantDomain !== 'undefined') {
			type += '_' + targetTenantDomain
		}
		return { type: type }
	}
	success(method, item, targetTenantDomain) {
		let type
		switch (method) {
			case 'GETALL':
				type = baseConstants.GETALL_SUCCESS
				break
			case 'ADDNEW':
				type = baseConstants.ADDNEW_SUCCESS
				break
			case 'EDIT':
				type = baseConstants.EDIT_SUCCESS
				break
			case 'DELETE':
				type = baseConstants.DELETE_SUCCESS
				break
			default:
		}
		type = type + '_' + this.resourceName
		if (targetTenantDomain && targetTenantDomain !== 'undefined') {
			type += '_' + targetTenantDomain
		}
		return { type, item }
	}
	failure(method, error, targetTenantDomain) {
		let type
		switch (method) {
			case 'GETALL':
				type = baseConstants.GETALL_FAILURE
				break
			case 'ADDNEW':
				type = baseConstants.ADDNEW_FAILURE
				break
			case 'EDIT':
				type = baseConstants.EDIT_FAILURE
				break
			case 'DELETE':
				type = baseConstants.DELETE_FAILURE
				break
			default:
		}
		type = type + '_' + this.resourceName
		if (targetTenantDomain && targetTenantDomain !== 'undefined') {
			type += '_' + targetTenantDomain
		}
		return { type, error }
	}

	goBackUrl = userRoles => {
		let that = this
		let pathName = window.location.pathname.split('/')
		let protocol = window.location.protocol + '//' || 'http://'
		let goBackUrl = null
		let path = '/'
		let pathUrl = 'my-tickets'
		if (pathName && pathName.length > 0) {
			if (pathName.indexOf('Support') !== -1) {
				if (window.location.pathname.split('/')[1] === 'tickets') path = `/${window.location.pathname.split('/')[1]}/Support`
				else path = `/Support`
			} else {
				if (window.location.pathname.split('/')[1] === 'approvals') {
					let requestsTab = history && history.location && history.location.pathname && history.location.pathname.toLowerCase().includes('requests')
					if (requestsTab && userRoles && userRoles.includes('requestor')) pathUrl = 'my-requests'
					else pathUrl = 'my-approvals'
				}
				path = `/${window.location.pathname.split('/')[1]}/${pathUrl}`
			}
		} else {
			path = `/${that.resourceName}`
		}
		if (window.location.origin) {
			goBackUrl = window.location.origin
		} else {
			goBackUrl = protocol + (window.location.host || window.location.hostname)
		}
		if (goBackUrl) history.replace(`${path}`, goBackUrl)
		else history.goBack()
	}
}
