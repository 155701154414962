// import {EntityComponent} from '../entity'
import { alertActions } from '../common'

import * as clientConstants from './clients.constants'

const getClientSecretReqeust = () => ({
	type: clientConstants.GET_CLIENT_SECRET_REQUEST,
})

const getClientSecretSuccess = payload => ({
	type: clientConstants.GET_CLIENT_SECRET_SUCCESS,
	payload,
})

const getClientSecretFailure = payload => ({
	type: clientConstants.GET_CLIENT_SECRET_FAILURE,
	payload,
})

const resetClientSecretReqeust = () => ({
	type: clientConstants.RESET_CLIENT_SECRET_REQUEST,
})

const resetClientSecretSuccess = payload => ({
	type: clientConstants.RESET_CLIENT_SECRET_SUCCESS,
	payload,
})

const resetClientSecretFailure = payload => ({
	type: clientConstants.RESET_CLIENT_SECRET_FAILURE,
	payload,
})

const getAllClientsReqeust = () => ({
	type: clientConstants.GETALL_CLIENTS_REQUEST,
})

const getAllClientsSuccess = payload => ({
	type: clientConstants.GETALL_CLIENTS_SUCCESS,
	payload,
})

const getAllClientsFailure = payload => ({
	type: clientConstants.GETALL_CLIENTS_FAILURE,
	payload,
})

export class ClientActions {
	constructor(options) {
		this.service = options.service
		this.apiUrl = options.apiUrl
	}
	getClientSecret(tenantId, clientId, t) {
		let that = this
		return async dispatch => {
			try {
				dispatch(getClientSecretReqeust())
				let secretData = await that.service.getClientSecret(tenantId, clientId)
				dispatch(getClientSecretSuccess(secretData))
			} catch (error) {
				let errorMsg = (t && t('Unable to fetch the secret for client')) || 'Unable to fetch the secret for client'
				errorMsg = errorMsg + ' ' + (error ? error.message : '')
				dispatch(getClientSecretFailure(errorMsg))
				dispatch(alertActions.error(errorMsg))
			}
		}
	}
	resetClientSecret(tenantId, clientId, t) {
		let that = this
		return async dispatch => {
			try {
				dispatch(resetClientSecretReqeust())
				let secretData = await that.service.resetClientSecret(tenantId, clientId)
				dispatch(resetClientSecretSuccess(secretData))
				dispatch(alertActions.info((t && t('Secret reset successfully')) || 'Secret reset successfully'))
			} catch (error) {
				let errorMsg = (t && t('Unable to reset the secret for client')) || 'Unable to reset the secret for client'
				errorMsg = errorMsg + ' ' + (error ? error.message : '')
				dispatch(resetClientSecretFailure(errorMsg))
				dispatch(alertActions.error(errorMsg))
			}
		}
	}

	getAllClients(tenantId, t) {
		let that = this
		return async dispatch => {
			try {
				dispatch(getAllClientsReqeust())
				let secretData = await that.service.getAllClients(tenantId)
				dispatch(getAllClientsSuccess(secretData))
			} catch (error) {
				let errorMsg = (t && t('Unable to fetch clients')) || 'Unable to fetch clients'
				errorMsg = errorMsg + ' ' + (error ? error.message : '')
				dispatch(getAllClientsFailure(errorMsg))
				dispatch(alertActions.error(errorMsg))
			}
		}
	}
	load({ tenantId, filter, orderBy, skip, limit, top, queryStr, baseUrl, select, searchText, searchName, t, id }) {
		//queryStr = queryStr || { status: 'Published' }

		if (filter && !filter.pop) filter = Object.assign({}, filter, queryStr)
		else if (queryStr) filter = queryStr
		return this.getAllClients(tenantId, t)
	}

	getFns() {
		let that = this
		let fns = {}
		fns.resetClientSecret = that.resetClientSecret.bind(that)
		fns.getClientSecret = that.getClientSecret.bind(that)
		fns.getAllClients = that.getAllClients.bind(that)
		fns.load = that.load.bind(that)
		return fns
	}
}
