import React from 'react'
import { withTranslation } from 'react-i18next'
import DropdownList from 'react-widgets/lib/DropdownList'
import Multiselect from 'react-widgets/lib/Multiselect'
import { Button, Badge, Input, Label } from 'reactstrap'
// import { session } from '../../../config'
import { identityServices } from '../../../../common'
class MultiselectField extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			fields: [],
			apiField: null,
			selectedFields: null,
			selectedValues: null,
			apiFieldData: null,
			isEdit: false,
			fieldType: false,
			options: this.props.field.options,
			fieldsMapping: {},
			entityValues: {
				fieldsMapping: {},
			},
		}
		this.handleAttrChangeSelect = this.handleAttrChangeSelect.bind(this)
		this.handleAddAttrubutes = this.handleAddAttrubutes.bind(this)
		this.handleAttrValueChange = this.handleAttrValueChange.bind(this)
		this.handleDeleteAttributes = this.handleDeleteAttributes.bind(this)
	}
	async fieldFetch() {
		let api = this.props?.field?.api
		let useCase = localStorage.getItem('product')
		if(api) {
			const requestOptions = {
				method: 'GET',
				headers: { 'Content-Type': 'application/json' },
				url: `${this.props.apiUrl || (this.props.tenant && this.props.tenant.apiUrl)
					}/${api?.path}?$filter=status eq 'Published' and (substringof('default', lookupProps_itemType)) and (substringof('${useCase || 'james'}', lookupProps_usecase)) or (substringof('default', lookupProps_usecase))`,
			}
			return identityServices.identityApi
				.request(requestOptions)
				.then(response => {
					if (response.status != 200) {
						return Promise.reject(response)
					}
					let items = response.data
					this.setState({
						options: items.list.filter( a => a.fieldProperties?.type === 'select' && a.fieldName !== 'assignedTo' && a.fieldName !== 'source' && a.fieldName !== 'assignedQueueTo' && a.fieldName !== 'priority' && a.status === 'Published').map(i => { return { label: i?.fieldProperties?.label, value: i?.fieldName, type: i?.fieldProperties?.type, selectOptions: i?.fieldProperties?.options?.enums, apiUrl: i?.fieldProperties?.options?.apiUrl }}),
					})
				})
				.catch(err => {
					return Promise.reject(`Error in api call ${(err.response && err.response.data && err.response.data.message) || err.message || err}`)
				})
		}
	}
	componentWillMount() {
		this.fieldFetch()
	}
	async handleAttrChangeSelect(event) {
		const { fields, options } = this.state
		let selectedValue = event?.label
		if (!options) this.fieldFetch()
		let apiField
		if (event?.apiUrl) {
			const requestOptions = {
				method: 'GET',
				headers: { 'Content-Type': 'application/json' },
				url: `${this.props.apiUrl || (this.props.tenant && this.props.tenant.apiUrl)
					}/ticketing${event.apiUrl}`,
			}
			await identityServices.identityApi
				.request(requestOptions)
				.then(response => {
					if (response.status != 200) {
						return Promise.reject(response)
					}
					apiField = response.data
				})
				.catch(err => {
					return Promise.reject(`Error in api call ${(err.response && err.response.data && err.response.data.message) || err.message || err}`)
				})
		} else {
			apiField = event.selectOptions
		}
		(this.props?.field?.childFieldType || event.type === 'select') ? this.setState({fieldType: true}) : this.setState({fieldType: false})
		if (selectedValue) {
			let item = event || fields && fields.find(o => o.label === selectedValue)
			this.setState({
				apiFieldData: apiField,
				selectedFields: item,
				selectedValues: null,
			})
		}
	}

	componentWillReceiveProps(props) {
		let fieldValues = props.formValues.properties
		let propsFields = props.field.name
		let newFieldValues
		if (!this.state.isEdit && fieldValues && Object.keys(fieldValues)) {
			newFieldValues = Object.keys(fieldValues).filter(a => a === propsFields)
			fieldValues = fieldValues[newFieldValues]
			this.setState({
				fieldsMapping: fieldValues
			})
		}
	}

	handleAttrValueChange(event) {
		let value
		if (event && event.target && event.target.value) {
			value = event.target.value
		} else if (event && event.pop) {
			value = event.map(i => i.value)
		} else if (event && typeof event === 'object') {
			value = event.value
		}
		if (value) {
			this.setState({
				selectedValues: value,
				selectedLabel: event?.label || event?.value,
				isEdit: true,
			})
		}
	}
	handleAddAttrubutes() {
		const { selectedFields, selectedValues, selectedLabel, fieldsMapping, entityValues } = this.state
		if (!selectedValues) return true
		let key = selectedFields.value
		let value = selectedValues
		let updatedAttr = Object.assign({}, fieldsMapping, { [key]: value })
		if(selectedLabel){
			updatedAttr[`${key}--Label`] =  selectedLabel
		}
		this.setState({
			fieldsMapping: updatedAttr,
			selectedFields: null,
			selectedValues: null,
		})
		this.props.handleChange && this.props.handleChange(this.props?.field?.name, updatedAttr || this.state.fieldsMapping)
	}
	handleDeleteAttributes(key) {
		const { fieldsMapping } = this.state
		let filterItems = fieldsMapping
		if (filterItems[key]) delete filterItems[key]
		this.setState({
			fieldsMapping: filterItems,
			isEdit: true,
		})
		this.props.handleChange && this.props.handleChange(this.props?.field?.name, this.state.fieldsMapping)
	}
	render() {
		const { t, field } = this.props
		const { fields, fieldType, options, selectedFields, apiFieldData, selectedValues, fieldsMapping, entityValues } = this.state
		let htmlProps = {
			data: options || apiFieldData && apiFieldData.map(i => { return { label: i.label, value: i.name || i.value } }) ||
				fields &&
				fields.map(i => {
					return { label: i.label, value: i.name || i.value }
				}),
			valueField: 'value',
			textField: 'label',
			value: (selectedFields && selectedFields.value) || null,
			containerClassName: 'asc-multiselect-dropdownlist',
			onChange: this.handleAttrChangeSelect,
			placeholder: t('---Select Field Mapping---'),
			readOnly: false,
			disabled: false,
			busy: false,
			messages: {
				moveBack: t('Navigate back'),
				moveForward: t('Navigate forward'),
				dateButton: t('Select date'),
				timeButton: t('Select time'),
				openCombobox: t('open combobox'),
				openDropdown: t('open dropdown'),
				emptyList: t('There are no items in this list'),
				emptyFilter: t('The filter returned no results'),
			},
		}

		let htmlProps1 = {
			data: field.subOptions || apiFieldData && apiFieldData.map(i => { return { label: i.label, value: i.name || i.value } }) || [],
			valueField: 'value',
			textField: 'label',
			// containerClassName: 'asc-multiselect-dropdownlist',
			onChange: this.handleAttrValueChange,
			placeholder: t('Type'),
			readOnly: false,
			disabled: false,
			busy: false,
			messages: {
				moveBack: t('Navigate back'),
				moveForward: t('Navigate forward'),
				dateButton: t('Select date'),
				timeButton: t('Select time'),
				openCombobox: t('open combobox'),
				openDropdown: t('open dropdown'),
				emptyList: t('There are no items in this list'),
				emptyFilter: t('The filter returned no results'),
			},
		}
		return (
			<div className="col-sm-8 section-frame">
				<div className={'custom-form-grp row clearfix'}>
					<div className={`col-sm-5 attribute-select`}>
						<DropdownList {...htmlProps} data-testid = {field.name} />
					</div>
					{selectedFields && (fieldType) && (
						<div className={`col-sm-5 attribute-select`}>{selectedFields && <DropdownList {...htmlProps1} />}</div>
					)}
					{selectedFields && (!fieldType) && (
						<div className={`col-sm-5 attribute-text`}>
							<Input type='text' placeholder={t('Type')} onChange={this.handleAttrValueChange} />
						</div>
					)}
					{selectedFields && (
						<div className="col-sm-1">
							<Button className="for-add-field btn-add-attr" onClick={this.handleAddAttrubutes}>
								<i className="fa fa-plus-square-o" aria-hidden="true"></i>
							</Button>
						</div>
					)}
				</div>
				<div className={'custom-form-grp row clearfix'}>
				</div>
				<div className="row">
					<div className="col-sm-3"></div>
					<div className="col-sm-9">
						{fieldsMapping && Object.keys(fieldsMapping)?.filter(item=> !item.includes('--Label')).map((item, i) => {
							let key = item
							let value = fieldsMapping[item] && fieldsMapping[item].pop ? fieldsMapping[item].join(', ') : fieldsMapping[`${item}--Label`] || fieldsMapping[item]
							return (
								<Badge className="attr-badge" key={key + i} color="primary">
									{key}: {value}
									<i className="fa fa-times" aria-hidden="true" onClick={this.handleDeleteAttributes.bind(this, item)}></i>
								</Badge>
							)
						})}
					</div>
				</div>
			</div>
		)
	}
}
export default withTranslation()(MultiselectField)
