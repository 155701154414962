import React, { Component } from "react";
// import ReactDOM from 'react-dom';
import './widget.css'
import { CustomListGroup } from './';
class ChatWidget extends Component {
    constructor(props) {
        super(props)
        this.state = {
            flowName: this.props.flowName || null,
            classNames: this.props.classNames || '',
            renderWidget:true,
            params: this.props.params
            // chat:  window.EmbeddableWidget.mount('ascjames')
        }
    }
    componentWillReceiveProps(props) {
        const {flowName}=this.state
        if (props.flowName){
            if(props.flowName!==flowName){this.setState({renderWidget:true})}
            else{this.setState({renderWidget:false})}    
            this.setState({ flowName: props.flowName })
        }
        else 
            this.setState({ flowName: null })
        // if(props.params){
        //     this.setState({params: props.params})
        // }
    }
    componentDidMount(){
        document.querySelector('body').classList.remove('arrow-left-right');
    }
    componentDidUpdate() {
        // const { tenant } = this.props;
        // let props= {flowName: this.state.flowName, classNames: this.state.classNames};
        // if(window.EmbeddableWidget && window.EmbeddableWidget.el)
        //     window.EmbeddableWidget.unmount();
        // if (tenant && tenant.tenantId)
        //     tenant && tenant.tenantId && window.EmbeddableWidget.mount(tenant.tenantId, null, null, props);


    }

    render() {
        const { tenant } = this.props;
        let props= {flowName: this.props.flowName, classNames: this.state.classNames, ...this.props.params};
        if(window.EmbeddableWidget && window.EmbeddableWidget.el && this.state.renderWidget)
            window.EmbeddableWidget.unmount();
        if (tenant && tenant.tenantId)
            tenant && tenant.tenantId && window.EmbeddableWidget && window.EmbeddableWidget.mount(tenant.tenantId, null, null, props);
        return (
            <div className='chat-widget-wrapper'>
                {this.state.flowName && <div>
                    <div id="chat-component" >
                    </div>
                    <CustomListGroup 
                    items={ this.props.otherProps}
                    />
                </div>
                }
            </div>
        )
    }
}
export default ChatWidget;

