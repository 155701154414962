import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { FormGroup } from 'reactstrap'
import { CategorySelectInput } from './jsonInput/formInput'
class CategoriesComponent extends Component {
	constructor(props) {
		super(props)
		this.state = {
			categoriesMeta: this.props && this.props.meta && this.props.meta.categoriesMeta ? this.props.meta.categoriesMeta : null,
			name: this.props && this.props.name,
			categoryList: this.props && this.props.entity && this.props.entity.refEntityList ? this.props.entity.refEntityList[this.props.name] : [],
			categoryMap: [],
			parentCategory: [],
			parentValue: '',
			valueMap: {},
			selectedIndex: {},
			collectedValues: [],
			fieldToCollect: [],
			formValues: [],
			editCounter: false,
		}
	}

	componentWillMount() {
		const {
			meta: { categoriesMeta },
			name,
			value,
		} = this.props
		this.setState({ categoriesMeta, fieldToCollect: categoriesMeta[name].fieldToCollect })
		let itemDef = categoriesMeta[name].api
		//if(itemDef.filter){itemDef.filter.push({name:'type', value:moduleName})}
		itemDef && this.props.getRefItems({ apiDef: itemDef, filter: itemDef.filter, t: this.props.t })

		if (value && value.length > 0) {
			this.setState({ formValues: value })
		}
	}

	componentDidMount() {}

	componentWillReceiveProps(nextProps) {
		const { editCounter } = this.state
		const { entityValues } = nextProps
		let categoryList =
			nextProps && nextProps.entity && nextProps.entity.refEntityList && nextProps.entity.refEntityList[nextProps.name]
				? nextProps.entity.refEntityList[nextProps.name]
				: []

		let formValue = entityValues[nextProps.name]
		let mapProps = []

		if (formValue && formValue.length > 0 && !editCounter) {
			this.setState({ editCounter: true })
			this.mapFormValue(formValue)
		}

		let filterSelect = categoryList && categoryList.length > 0 && categoryList.filter(x => !x.parent)
		let optionList = { selectBox: filterSelect }
		mapProps.push(optionList)

		this.setState({
			categoryList: categoryList || [],
			parentCategory: mapProps,
		})
	}
	mapFormValue(value) {
		const { categoryList, selectedIndex, valueMap } = this.state
		let formValues = value
		if (formValues && formValues.length > 0) {
			let filterParentValue = formValues.find(x => !x.parent)
			if (filterParentValue && Object.keys(filterParentValue).length > 0) {
				let mapList =
					categoryList && Array.isArray(categoryList) && categoryList.length > 0 && categoryList.find(x => x.key === filterParentValue.key.toString())

				mapList && Object.keys(mapList).length > 0 && this.setState({ parentValue: mapList.value })
			}
			let filterChildValue = formValues.filter(x => x)
			let selectIndexValue = { ...selectedIndex }
			//   let setSubCategory=[]
			let itemIndex = 0
			filterChildValue &&
				filterChildValue.length > 0 &&
				filterChildValue.forEach((item, idx) => {
					if (item.parent != null && categoryList && Array.isArray(categoryList) && categoryList.length > 0) {
						let getCategoryValue = categoryList.find(x => x.key === item.key)
						selectIndexValue = { ...selectIndexValue, [itemIndex]: getCategoryValue.value }
						itemIndex++
					}

					let obj = { index: idx }
					obj = { ...obj, ...item }
					this.mapSelectBox(item.value, obj, selectIndexValue)
					this.setState({ valueMap: { ...valueMap, [item.value]: item.value }, selectedIndex: selectIndexValue })
				})
		}
	}

	handleChange(value, inputProps) {
		const { valueMap, selectedIndex, categoryMap } = this.state
		let selectIndexValue = { ...selectedIndex, [inputProps.index]: value }
		this.setState({ valueMap: { ...valueMap, [value]: value }, selectedIndex: selectIndexValue })
		if (value) {
			this.mapSelectBox(value, inputProps, selectIndexValue)
		} else {
			let filterCat = categoryMap && categoryMap.length > 0 && categoryMap.filter((x, idx) => idx < inputProps.index + 1)
			console.log('!!!! filterCat', filterCat)
			this.setState({ categoryMap: filterCat })
		}
	}

	handleParentChange(value, inputProps) {
		this.setState({ parentValue: value, categoryMap: [], selectedIndex: {} })
		this.mapParentSelectBox(value, inputProps, true)
		this.collectField(value)
	}

	collectField(parentVal, childVal) {
		const { fieldToCollect, categoryList } = this.state
		let pickedField = {}
		let fieldList = []
		let mergeChild = []
		// selectedKeys && selectedKeys.length>0 && selectedKeys.forEach((item)=>{
		//     pickedItem[item] = selectedIndex[item]
		// })
		categoryList &&
			categoryList.length > 0 &&
			categoryList.forEach(item => {
				if (item.value && parentVal && item.value === parentVal.toString()) {
					fieldToCollect &&
						fieldToCollect.length > 0 &&
						fieldToCollect.forEach(elem => {
							pickedField[elem] = item[elem]
						})
				}
			})
		if (pickedField && Object.keys(pickedField).length > 0) {
			fieldList.push(pickedField)
		}

		childVal &&
			Object.keys(childVal).length > 0 &&
			Object.keys(childVal).map(function (key) {
				//return [childVal[key]];
				return mergeChild.push(childVal[key])
			})

		let filterSelectedChild = []
		categoryList &&
			categoryList.length > 0 &&
			categoryList.forEach(item => {
				mergeChild &&
					mergeChild.length > 0 &&
					mergeChild.forEach(elem => {
						if (item.value === elem) filterSelectedChild.push(item)
					})
			})

		filterSelectedChild &&
			filterSelectedChild.length > 0 &&
			filterSelectedChild.forEach(item => {
				let pickedchild = {}
				mergeChild &&
					mergeChild.length > 0 &&
					mergeChild.forEach(melem => {
						if (item.value && melem && item.value === melem.toString()) {
							fieldToCollect &&
								fieldToCollect.length > 0 &&
								fieldToCollect.forEach(elem => {
									pickedchild[elem] = item[elem]
								})

							fieldList.push(pickedchild)
						}
					})
			})

		if (fieldList && fieldList.length > 0) {
			this.setState({ collectedValues: fieldList })
			this.props.onComponentChange(this.props.name, fieldList)
		}
	}
	cloneObject = obj => {
		var clone = {}
		for (var i in obj) {
			if (obj[i] != null && typeof obj[i] == 'object') clone[i] = this.cloneObject(obj[i])
			else clone[i] = obj[i]
		}
		return clone
	}
	categoryObjRef = categoryList => {
		let obj = []

		categoryList &&
			categoryList.length > 0 &&
			categoryList.forEach((item, idx) => {
				obj.push(this.cloneObject(item))
			})
		return obj
	}
	mapParentSelectBox(value, inputProps, type) {
		const { categoryList, categoryMap } = this.state
		// const {options}= inputProps;
		let catList = this.categoryObjRef(categoryList)
		if (value) {
			let keyName
			if (inputProps && inputProps.options) {
				let getCategoryVal = catList && catList.length > 0 && catList.find(x => (x.value = value))
				let filterKey =
					getCategoryVal &&
					Object.keys(getCategoryVal).length > 0 &&
					inputProps.options &&
					inputProps.options.length > 0 &&
					inputProps.options.find(x => x.key === getCategoryVal.key)
				keyName = filterKey && filterKey.key ? filterKey.key : ''
			} else {
				keyName = inputProps.key
			}

			let mapList = catList && Array.isArray(catList) && catList.length > 0 && catList.filter(x => x.parent === keyName)
			if (mapList && mapList.length > 0) {
				let optionList = categoryMap
				if (type) {
					optionList = [{ selectBox: mapList }]
				} else {
					optionList.push({ selectBox: mapList })
				}
				this.setState({
					categoryMap: optionList,
				})
			}
		} else {
			this.setState({ categoryMap: [] })
		}
	}

	mapSelectBox(value, inputProps, childVal) {
		const { categoryList, categoryMap, selectedIndex, parentValue } = this.state
		let keyName
		let catList = this.categoryObjRef(categoryList)
		let catListNew = this.categoryObjRef(categoryList)
		if (value) {
			if (inputProps && inputProps.options) {
				let objCat = {}
				let objCat1 = {}
				catListNew &&
					catListNew.length > 0 &&
					catListNew.forEach(x => {
						if (x.value === value) {
							objCat = x
						}
					})

				inputProps.options &&
					inputProps.options.length > 0 &&
					inputProps.options.forEach(x => {
						if (x.key === objCat.key) {
							objCat1 = x
						}
					})
				//let filterKey=inputProps.options && inputProps.options.length>0 && inputProps.options.find(x=>x.key==value);

				keyName = objCat1.key || ''
			} else {
				keyName = inputProps.key
			}
			let mapList = catList && Array.isArray(catList) && catList.length > 0 && catList.filter(x => x.parent == keyName)
			if (mapList && mapList.length > 0) {
				let optionList = categoryMap
				optionList.push({ selectBox: mapList })

				this.setState({
					categoryMap: optionList,
				})
				this.collectField(parentValue, childVal)
			} else {
				let filterCat = categoryMap && categoryMap.length > 0 && categoryMap.filter((x, idx) => idx < inputProps.index + 1)
				let selectedKeys = Object.keys(selectedIndex).filter(x => x < inputProps.index)
				let pickedItem = {}
				selectedKeys &&
					selectedKeys.length > 0 &&
					selectedKeys.forEach(item => {
						pickedItem[item] = selectedIndex[item]
					})
				pickedItem[inputProps.index] = value
				this.setState({ categoryMap: filterCat, selectedIndex: pickedItem })
				this.collectField(parentValue, pickedItem)
			}
		}
	}

	// shouldComponentUpdate(nextProps, nextState) {

	// }

	// componentWillUpdate(nextProps, nextState) {

	// }

	// componentDidUpdate(prevProps, prevState) {

	// }

	// componentWillUnmount() {

	// }

	render() {
		const { categoryMap, parentCategory, parentValue, selectedIndex } = this.state

		return (
			<div>
				{parentCategory &&
					parentCategory.length > 0 &&
					parentCategory.map((elem, index) => {
						return (
							<FormGroup key={index}>
								<CategorySelectInput
								{...this.props}
									type={'select'}
									name={'selectbox' + index}
									value={parentValue}
									handleChange={this.handleParentChange.bind(this)}
									options={elem.selectBox}
								/>
							</FormGroup>
						)
					})}

				{categoryMap &&
					categoryMap.length > 0 &&
					categoryMap.map((elem, index) => {
						return (
							<FormGroup key={index}>
								<CategorySelectInput
									index={index}
									type={'select'}
									name={'selectbox' + index}
									value={selectedIndex[index] || ''}
									handleChange={this.handleChange.bind(this)}
									options={elem.selectBox}
								/>
							</FormGroup>
						)
					})}
			</div>
		)
	}



    handleChange(value,inputProps){
        const {valueMap,selectedIndex,categoryMap}=this.state
        let selectIndexValue={...selectedIndex,[inputProps.index]:value}
        this.setState({valueMap:{...valueMap,[value]:value},selectedIndex:selectIndexValue})
        if(value){
        this.mapSelectBox(value,inputProps,selectIndexValue);
        }else{
                let filterCat=categoryMap && categoryMap.length>0 && categoryMap.filter((x,idx)=>idx<inputProps.index+1)
                //console.log('!!!! filterCat',filterCat)
                this.setState({categoryMap:filterCat})
        }
    }


    
    handleParentChange(value,inputProps){
        this.setState({parentValue:value,categoryMap:[],selectedIndex:{}})
        this.mapParentSelectBox(value,inputProps,true);
        this.collectField(value)
 
    }

    collectField(parentVal,childVal){
        const {fieldToCollect,categoryList}=this.state
        let pickedField={}
        let fieldList=[]
        let mergeChild=[]
        // selectedKeys && selectedKeys.length>0 && selectedKeys.forEach((item)=>{
        //     pickedItem[item] = selectedIndex[item]
        // })
        categoryList && categoryList.length>0 && categoryList.forEach((item)=>{
           if(item.value && parentVal && item.value === parentVal.toString()){
               fieldToCollect && fieldToCollect.length>0 && fieldToCollect.forEach((elem)=>{
                   pickedField[elem]=item[elem]
               })
           }
       })
       if(pickedField && Object.keys(pickedField).length>0){
           fieldList.push(pickedField)
       }





       childVal && Object.keys(childVal).length>0 && Object.keys(childVal).map(function(key) {
        //return [childVal[key]];
        return mergeChild.push(childVal[key])
      });
    

      let filterSelectedChild=[]
      categoryList && categoryList.length>0 && categoryList.forEach((item)=>{
        mergeChild && mergeChild.length>0 && mergeChild.forEach((elem)=>{
            if(item.value === elem)
            filterSelectedChild.push(item)
        })
      })
  
      filterSelectedChild && filterSelectedChild.length>0 && filterSelectedChild.forEach((item)=>{
        let pickedchild={}
        mergeChild && mergeChild.length>0 && mergeChild.forEach((melem)=>{
            if(item.value && melem && item.value === melem.toString()){
                fieldToCollect && fieldToCollect.length>0 && fieldToCollect.forEach((elem)=>{
                    pickedchild[elem]=item[elem]
                })
     
                fieldList.push(pickedchild)
              
            }
        })
     
    })
    
 
    if(fieldList && fieldList.length>0){
        this.setState({collectedValues:fieldList})
        this.props.onComponentChange(this.props.name,fieldList)
    }
    }
 cloneObject = (obj) => {
        var clone = {};
        for (var i in obj) {
            if (obj[i] != null && typeof (obj[i]) == "object")
                clone[i] = this.cloneObject(obj[i]);
            else
                clone[i] = obj[i];
        }
        return clone;
    }
    categoryObjRef=(categoryList)=>{
        let obj=[]

        categoryList && categoryList.length>0 && categoryList.forEach((item,idx)=>{
            obj.push(this.cloneObject(item))
        })
        return obj
    }
    mapParentSelectBox(value,inputProps,type){
        const {categoryList,categoryMap}=this.state;
        // const {options}= inputProps;
        let catList=this.categoryObjRef(categoryList)
        if(value){
        let keyName;
        if(inputProps && inputProps.options){
             let getCategoryVal=catList && catList.length>0 && catList.find(x=>x.value=value)
             let filterKey=getCategoryVal && Object.keys(getCategoryVal).length>0 && inputProps.options && inputProps.options.length>0 && inputProps.options.find(x=>x.key === getCategoryVal.key);
             keyName=filterKey && filterKey.key ? filterKey.key : ''
        }else{keyName=inputProps.key}

        let mapList=catList && Array.isArray(catList) && catList.length>0 && catList.filter(x=>x.parent===keyName)
        if(mapList && mapList.length>0){
            let optionList=categoryMap
            if(type){optionList=[{selectBox:mapList}]; }

            else{ optionList.push({selectBox: mapList});}
            this.setState({
                categoryMap:optionList
            })

        }
    }else{this.setState({categoryMap:[]})}
    }



    mapSelectBox(value,inputProps,childVal){
        const {categoryList,categoryMap,selectedIndex,parentValue}=this.state;
        let keyName;
        let catList=this.categoryObjRef(categoryList)
        let catListNew=this.categoryObjRef(categoryList)
        if(value){
        if(inputProps && inputProps.options){
            let objCat={}
            let objCat1={}
            catListNew && catListNew.length>0 && catListNew.forEach((x)=>{
                if(x.value===value){
                    objCat=x
                }
            })

            inputProps.options && inputProps.options.length>0 && inputProps.options.forEach((x)=>{
                if(x.key===objCat.key){
                    objCat1=x
                }
            })
             //let filterKey=inputProps.options && inputProps.options.length>0 && inputProps.options.find(x=>x.key==value);
            
             keyName=objCat1.key || ''
        }else{keyName=inputProps.key}
        let mapList=catList && Array.isArray(catList) && catList.length>0 && catList.filter(x=>x.parent==keyName)
        if(mapList && mapList.length>0){
        let optionList=categoryMap
         optionList.push({selectBox: mapList});

            this.setState({
                categoryMap:optionList
            })
            this.collectField(parentValue,childVal)
        }else{
            let filterCat=categoryMap && categoryMap.length>0 && categoryMap.filter((x,idx)=>idx<inputProps.index+1)
            let selectedKeys=Object.keys(selectedIndex).filter(x=>x < inputProps.index)
            let pickedItem={};
            selectedKeys && selectedKeys.length>0 && selectedKeys.forEach((item)=>{
                pickedItem[item] = selectedIndex[item]
            })
            pickedItem[inputProps.index]=value
            this.setState({categoryMap:filterCat,selectedIndex:pickedItem})
            this.collectField(parentValue,pickedItem)
        }
    }
}




    // shouldComponentUpdate(nextProps, nextState) {

    // }

    // componentWillUpdate(nextProps, nextState) {

    // }

    // componentDidUpdate(prevProps, prevState) {

    // }

    // componentWillUnmount() {

    // }

    render() {
        const {categoryMap,parentCategory,parentValue,selectedIndex}=this.state

        return (
            <div>
    {parentCategory && parentCategory.length>0 && parentCategory.map((elem,index)=>{
                   return(
                    <FormGroup key={index}>
                    <CategorySelectInput
                            type={"select"}
                            name={'selectbox'+index}
                            value ={parentValue}
                            handleChange={this.handleParentChange.bind(this)}
                            options={elem.selectBox}
                    />
    </FormGroup>
                   )
               })} 



               {categoryMap && categoryMap.length>0 && categoryMap.map((elem,index)=>{
                   return(
                    <FormGroup key={index}>
                    <CategorySelectInput
                            index={index}
                            type={"select"}
                            name={'selectbox'+index}
                            value ={selectedIndex[index] || ''}
                            handleChange={this.handleChange.bind(this)}
                            options={elem.selectBox}
                    />
            </FormGroup>
                   )
               })} 

     
          
            </div>
        );
    }
	}

export default withTranslation()(CategoriesComponent)
