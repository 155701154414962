import { connect } from 'react-redux';
import  * as ssoActions  from '../actions/sso.actions'
import { bindActionCreators } from 'redux'
import SsoManagementComponent from '../components/SsoManagmentComponent'
const mapStateToProps = (state) => {
    const { identity,ssoConfig } = state;
    const { profile } = identity
    return {
        profile,
        ssoConfig
    };
}

const mapDispatchToProps = (dispatch) => ({
    ...bindActionCreators(
        {
          ...ssoActions,
        },
        dispatch,
      ),
 })

 export default connect(mapStateToProps,mapDispatchToProps)(SsoManagementComponent);