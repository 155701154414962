import { alertActions, baseConstants } from '..'
// import { request } from 'https';

/**
 * @todo Custom dynamic function with dynamic resourceName and service
 */
export class BaseActions {
	constructor(options) {
		this.service = options.service
		this.resourceName = options.resourceName
	}
	getAll(tenantId, queryStringArr, t, apiUrl) {
		let that = this

		return dispatch => {
			dispatch(that.request('GETALL'))
			let errorMsg = t && t('Cannot fetch {{resourceName}} at this moment please try again later', { resourceName: this.resourceName })
			that.service.getAll(tenantId, queryStringArr, apiUrl).then(
				item => {
					if (item) {
						dispatch(that.success('GETALL', item))
					} else {
						errorMsg = errorMsg || 'Cannot fetch ' + that.resourceName + ' at this moment please try again later'
						dispatch(that.failure('GETALL', errorMsg))
						dispatch(alertActions.error(errorMsg))
					}
				},
				error => {
					errorMsg = errorMsg || 'Cannot fetch ' + that.resourceName + ' at this moment please try again later'
					dispatch(that.failure('GETALL', errorMsg))
					dispatch(alertActions.error(errorMsg))
				}
			)
		}
	}
	addNewItem(itemData, customerTenantID, t, apiUrl) {
		let that = this
		return dispatch => {
			dispatch(that.request('ADDNEW'))
			let errorMsg = t && t('Cannot insert {{resourceName}} at this moment please try again later', { resourceName: this.resourceName })
			that.service.addNew(itemData, customerTenantID, apiUrl).then(
				item => {
					if (item) {
						dispatch(that.success('ADDNEW', item))
						dispatch(alertActions.success((t && t('{{resourceName}} added successfully', { resourceName: this.resourceName })) || that.resourceName + ' added successfully'))
					} else {
						errorMsg = errorMsg || 'Cannot insert new ' + that.resourceName + ' at this moment please try again later'
						dispatch(that.failure('ADDNEW', errorMsg))
						dispatch(alertActions.error(errorMsg))
					}
				},
				error => {
					errorMsg = errorMsg || 'Cannot insert new ' + that.resourceName + ' at this moment please try again later' + (error ? error.message : '')
					dispatch(that.failure('ADDNEW', errorMsg))
					dispatch(alertActions.error(errorMsg))
				}
			)
		}
	}
	editItem(itemData, customerTenantID, editId, t, apiUrl) {
		let that = this
		return dispatch => {
			dispatch(that.request('EDIT'))
			let errorMsg = t && t('Cannot update {{resourceName}} at this moment please try again later', { resourceName: this.resourceName })

			that.service.editRecord(itemData, customerTenantID, editId, apiUrl).then(
				item => {
					if (item) {
						dispatch(that.success('EDIT', item))
						dispatch(alertActions.success((t && t('{{resourceName}} updated successfully', { resourceName: this.resourceName })) || that.resourceName + ' updated successfully'))
					} else {
						errorMsg = errorMsg || 'Cannot update the ' + that.resourceName + ' at this moment please try again later'
						dispatch(that.failure('EDIT', errorMsg))
						dispatch(alertActions.error(errorMsg))
					}
				},
				error => {
					errorMsg = errorMsg || 'Cannot update the ' + that.resourceName + ' at this moment please try again later' + (error ? error.message : '')
					dispatch(that.failure('EDIT', errorMsg))
					dispatch(alertActions.error(errorMsg))
				}
			)
		}
	}
	upsertItem(itemData, customerTenantID, msg = false, t, apiUrl) {
		let that = this
		return dispatch => {
			dispatch(that.request('EDIT'))
			let errorMsg = t && t('Cannot update {{resourceName}} at this moment please try again later', { resourceName: this.resourceName })

			that.service.upsertRecord(itemData, customerTenantID, apiUrl).then(
				item => {
					if (item) {
						dispatch(that.success('EDIT', item))
						!msg &&
							dispatch(alertActions.success((t && t('{{resourceName}} updated successfully', { resourceName: this.resourceName })) || that.resourceName + ' updated successfully'))
					} else {
						errorMsg = errorMsg || 'Cannot update the ' + that.resourceName + ' at this moment please try again later'
						dispatch(that.failure('EDIT', errorMsg))
						dispatch(alertActions.error(errorMsg))
					}
				},
				error => {
					errorMsg = errorMsg || 'Cannot update the ' + that.resourceName + ' at this moment please try again later' + (error ? error.message : '')
					dispatch(that.failure('EDIT', errorMsg))
					dispatch(alertActions.error(errorMsg))
				}
			)
		}
	}
	deleteItem(id, customerTenantID, t, apiUrl) {
		let that = this
		return dispatch => {
			dispatch(that.request('DELETE'))
			let errorMsg = t && t('Cannot delete {{resourceName}} at this moment please try again later', { resourceName: this.resourceName })

			that.service.deleteRecord(id, customerTenantID, apiUrl).then(
				item => {
					if (item) {
						dispatch(that.success('DELETE', item))
						dispatch(alertActions.success((t && t('{{resourceName}} deleted successfully', { resourceName: this.resourceName })) || that.resourceName + ' deleted successfully'))
					} else {
						errorMsg = errorMsg || 'Cannot delete ' + that.resourceName + ' at this moment please try again later'
						dispatch(that.failure('DELETE', errorMsg))
						dispatch(alertActions.error(errorMsg))
					}
				},
				error => {
					errorMsg = errorMsg || 'Cannot delete ' + that.resourceName + ' at this moment please try again later' + (error ? error.message : '')
					dispatch(that.failure('DELETE', errorMsg))
					dispatch(alertActions.error(errorMsg))
				}
			)
		}
	}
	request(method) {
		let type
		switch (method) {
			case 'GETALL':
				type = baseConstants.GETALL_REQUEST
				break
			case 'ADDNEW':
				type = baseConstants.ADDNEW_REQUEST
				break
			case 'EDIT':
				type = baseConstants.EDIT_REQUEST
				break
			case 'DELETE':
				type = baseConstants.DELETE_REQUEST
				break
			default:
		}
		type = type + '_' + this.resourceName
		return { type: type }
	}
	success(method, item) {
		let type
		switch (method) {
			case 'GETALL':
				type = baseConstants.GETALL_SUCCESS
				break
			case 'ADDNEW':
				type = baseConstants.ADDNEW_SUCCESS
				break
			case 'EDIT':
				type = baseConstants.EDIT_SUCCESS
				break
			case 'DELETE':
				type = baseConstants.DELETE_SUCCESS
				break
			default:
		}
		type = type + '_' + this.resourceName
		return { type, item }
	}
	failure(method, error) {
		let type
		switch (method) {
			case 'GETALL':
				type = baseConstants.GETALL_FAILURE
				break
			case 'ADDNEW':
				type = baseConstants.ADDNEW_FAILURE
				break
			case 'EDIT':
				type = baseConstants.EDIT_FAILURE
				break
			case 'DELETE':
				type = baseConstants.DELETE_FAILURE
				break
			default:
		}
		type = type + '_' + this.resourceName
		return { type, error }
	}
}
