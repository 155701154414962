import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { Loader } from '../../..'
import * as CustomComponent from './'
import './style.css'
class ContentViewComponent extends Component {
	componentWillMount() {
		const {
			meta: { contentMeta },
		} = this.props
		contentMeta && contentMeta.staticContent && contentMeta.staticContent.content && this.apiRequest(contentMeta.staticContent.content)
	}
	apiRequest = async apiDef => {
		if (apiDef && apiDef.pop && apiDef.length > 0) {
			apiDef.forEach(async (item, idx) => {
				item.api && item.api.method && item.api.method === 'GET' && (await this.props.getRefItems({ apiDef: item.api, filter: item.filter, t: this.props.t }))
			})
		}
	}

	renderView(content) {
		if (content && content.contentType && CustomComponent[content.contentType]) {
			let Custom = CustomComponent[content.contentType]
			return <Custom {...this.props} />
		}
	}
	render() {
		const {
			meta: { contentMeta },
			entity: { loading },
		} = this.props

		return (
			<div>
				{loading && <Loader />}
				{!loading && contentMeta && contentMeta.staticContent && contentMeta.staticContent.contentType && this.renderView(contentMeta.staticContent)}
			</div>
		)
	}
}
export default withTranslation()(ContentViewComponent)
