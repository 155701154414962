// import { apiUrl } from '../../../config';
import { identityServices } from '../../common'

export const GetWSForUser = ({ wsType, attributes, apiUrl }) => {
	if (!apiUrl) apiUrl = sessionStorage.getItem('apiUrl')
	const requestOptions = {
		method: 'POST',
		headers: { Pragma: 'no-cache', 'Content-Type': 'application/json' },
		url: `${apiUrl}/workspace/getFilterItemsForUser`,
		data: { wsType, attributes },
	}

	return identityServices.identityApi.request(requestOptions).then(response => {
		if (response.status !== 200) {
			return Promise.reject(response)
		}
		return response.data
	})
}
