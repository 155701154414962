
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { tenantActions, profileActions,settingsActions} from '../'
import ProfileComponent from '../components/ProfileComponent'
const mapStateToProps = (state) => {
    const { identity, tenant, ProfileEntity,ssoData } = state;
    const { profile } = identity
    return {
        profile,
        tenant,
        ProfileEntity,
        ssoData
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        ...tenantActions,
        ...profileActions,
        ...settingsActions
    }, dispatch)
}

export default connect(mapStateToProps,mapDispatchToProps)(ProfileComponent);