import queryString from 'query-string'
import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { globalTenantServiceUrl } from '../../../../config'
import { identityServices } from '../../../common'
// import 'mdbreact/dist/css/mdb.css';
import './index.css'
import ProductUsage from './ProductUsage'
import SetupFinished from './SetupFinished'
import SetupQna from './SetupQna'
import SetupTenant from './SetupTenant'
import SignupForm from './SignupForm'

class RegisterComponent extends Component {
	constructor(props) {
		super(props)
		this.state = {
			pageStep: 0,
			stepname: '',
			totalStep: 4,
			activeRegistration: {},
			registerConfig: [],
			product: 'James',
			logo: 'https://as-cdn.azureedge.net/cdn/logo.png',
		}
		this.renderSteps = this.renderSteps.bind(this)
	}

	componentDidMount() {
		let paramssearch = queryString.parse(this.props.location.search)
		paramssearch && paramssearch.product
			? this.setState({ product: paramssearch.product }, () => {
					this.getRegisterConfig(this.state.product)
			  })
			: this.getRegisterConfig(this.state.product)
	}

	getRegisterConfig(product) {
		console.log(product, 'product')
		let apiUrlCustom = globalTenantServiceUrl
		const requestOptions = {
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			url: `${apiUrlCustom}/productui/findproduct/${product}`,
		}
		return identityServices.identityApi.request(requestOptions).then(response => {
			if (response.status == 200 && response.data) {
				this.setState({ registerConfig: response.data.stepConfig, logo: (response.data.properties && response.data.properties.logo) || this.state.logo })
			}
		})
	}

	registerHandler = payload => {
		this.setState({
			pageStep: this.state.pageStep === 0 ? this.state.pageStep + 1 : this.state.pageStep,
			activeRegistration: payload,
		})
	}

	nextPage = () => {
		this.setState({
			pageStep: this.state.pageStep < this.state.totalStep ? this.state.pageStep + 1 : this.state.pageStep,
		})
	}

	previousPage = () => {
		this.setState({
			pageStep: this.state.pageStep > 0 ? this.state.pageStep - 1 : this.state.pageStep,
		})
	}

	renderSteps = item => {
		const {
			tenant,
			tenant: { rootDomain },
			registerNewTenant,
			registerNewTenant: { isValidated, registerTenantExists },
		} = this.props
		let params = this.props.params || (this.props.match && this.props.match.params) || (this.props.computedMatch && this.props.computedMatch.params)

		switch (item.stepName) {
			case 'SignupForm':
				return (
					<SignupForm
						registerHandler={this.registerHandler}
						tenantExists={registerTenantExists}
						isValidated={isValidated}
						registerTenant={this.props.registerTenant}
						checkTenantExists={this.props.checkTenantExists}
						rootDomain={rootDomain}
						params={params}
						stepConfig={item}
						product={this.state.product}
						key={item.stepName}
						getOTP={this.props.getOTP}
						validateEmailOTP={this.props.validateEmailOTP}
					/>
				)

				break
			case 'ProductUsage':
				return <ProductUsage nextPage={this.nextPage} activeRegistration={this.state.activeRegistration} stepConfig={item} key={item.stepName} />
				break
			case 'SetupTenant':
				return (
					<SetupTenant
						nextPage={this.nextPage}
						activeRegistration={this.state.activeRegistration}
						registrationFailed={registerNewTenant.registrationFailed}
						tenantLifeCycle={registerNewTenant.tenantLifeCycle}
						getTenantLifeCycle={this.props.getTenantLifeCycle}
						stepConfig={item}
						key={item.stepName}
					/>
				)
			case 'SetupQna':
				return <SetupQna nextPage={this.nextPage} activeRegistration={this.state.activeRegistration} stepConfig={item} key={item.stepName} />
			case 'SetupFinished':
				return <SetupFinished rootDomain={rootDomain} activeRegistration={this.state.activeRegistration} stepConfig={item} key={item.stepName} />

			default:
				break
		}
	}

	render() {
		const { pageStep, registerConfig, logo } = this.state
		const {
			tenant,
			tenant: { rootDomain },
			registerNewTenant,
			registerNewTenant: { isValidated, registerTenantExists },
		} = this.props
		let params = this.props.params || (this.props.match && this.props.match.params) || (this.props.computedMatch && this.props.computedMatch.params)
		// const { registerNewTenant  } = this.props
		// const {isValidated,registerTenantExists}=registerNewTenant;
		// const {rootDomain}=tenant;

		return tenant.tenantId !== 'default' ? (
			<Redirect to={{ pathname: tenant.landingPage || '/error' }} />
		) : (
			<div className="new-signup login-bg bg2">
				<header className="custom-header for-new-registration">
					<div className="container-fluid">
						<div className="row">
							<div className="col-sm-12">
								<div className=" clearfix">
									<a className="nav-brand pull-left" href="#!">
										<img src={logo} alt="Rezolve.ai" />
									</a>
									{/* <div className="nav-right pull-right">
              <ul className="nav-cta ">						
                <li><a href="#!" style={{padding:'8px 15px'}} className="help-center active">HELP</a></li>
              </ul>
            </div> */}
								</div>
							</div>
						</div>
					</div>
				</header>
				<main className="main registration-update">
					<div className="container-fluid">
						<div className="row">
							<div className="col-sm-12">
								<div className="main-frame">
									<div className="blue-frame">
										<div className="inner-content-seprator clearfix">
											<div className="after-reg-steps">
												{registerConfig.map((stepValues, index) => {
													let props
													return index == pageStep && this.renderSteps(stepValues)
												})}

												{/* {pageStep === 1 && (
                    <SignupForm 
                    registerHandler={this.registerHandler} 
                    tenantExists={registerTenantExists}
                    isValidated={isValidated}
                    registerTenant={this.props.registerTenant}
                    checkTenantExists={this.props.checkTenantExists}
                    rootDomain={rootDomain}                    
                    params={params}
                    />
                  )} */}
												{/* {pageStep === 2 && (
                    <ProductUsage
                      nextPage={this.nextPage}
                      activeRegistration={this.state.activeRegistration}
                    />
                  )} */}

												{/* {pageStep === 3 && (
                    <SetupTenant
                      nextPage={this.nextPage}
                      activeRegistration={this.state.activeRegistration}
                      registrationFailed={registerNewTenant.registrationFailed}
                      tenantLifeCycle={registerNewTenant.tenantLifeCycle}
                      getTenantLifeCycle={this.props.getTenantLifeCycle}
                    />
                  )}       */}

												{/* {pageStep === 4 && (
                    <SetupQna
                      nextPage={this.nextPage}
                      activeRegistration={this.state.activeRegistration}
                    />
                  )} */}
												{/* {pageStep === 4 && (
                    <SetupFinished
                      rootDomain={rootDomain}
                      activeRegistration={this.state.activeRegistration}

                    />
                  )}   */}

												<div className="switch-indicator">
													<ul className="switch">
														<li>
															<span className="circle-border  active" />
														</li>
														<li>
															<span className="circle-border" />
														</li>
														<li>
															<span className="circle-border" />
														</li>
														<li>
															<span className="circle-border" />
														</li>
													</ul>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</main>
			</div>
		)
	}
}

export default RegisterComponent
