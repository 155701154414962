import { DashboardConstants } from '..';

/**
 * 
 * Convert this to a base class 
 */

// export class BaseReducer {

export const dashboard = (state = {}, action) => {
    // console.log('%%%%%%%%STATE IS ',state);
    // console.log('************* ACTIONS',action)
    // let type = action.type.slice(0,action.type.length- this.resourceName.length -1) 

    switch (action.type) {
        case (DashboardConstants.EXECUTEAPI_REQUEST + '_' + action.resourceName):
            return {
                ...state,
                loading: true,
                entityName: action.resourceName
            }
        case (DashboardConstants.EXECUTEAPI_SUCCESS + '_' + action.resourceName):
            let returnObj = 
            {
                ...state,
                loading: false,
                entityName: action.resourceName
            }
            if(action.targetProp) {
                returnObj[action.targetProp] = action.item
            }
            else {
                returnObj.data = action.item
            }
            returnObj.inputs = action.data && action.data.inputs
            return returnObj
        case (DashboardConstants.EXECUTEAPI_FAILURE + '_' + action.resourceName):
            return {
                ...state,
                loading: false,
                entityName: action.resourceName
            }
        
        case (DashboardConstants.GETONE_REQUEST + '_' + action.resourceName):
            return {
                ...state,
                loading: true,
                entityName: action.resourceName
            }
        case (DashboardConstants.GETONE_SUCCESS + '_' + action.resourceName):
            return {
                ...state,
                loading: false,
                entityValues: action.item,
                entityName: action.resourceName
            }
        case (DashboardConstants.GETONE_FAILURE + '_' + action.resourceName):
            return {
                ...state,
                loading: false,
                entityName: action.resourceName
            }
        case (DashboardConstants.SLAAPI_REQUEST + '_' + action.resourceName):
            return {
                ...state,
                loading: true,
                entityName: action.resourceName
            }
        case (DashboardConstants.SLAAPI_SUCCESS + '_' + action.resourceName):
            let slaObj = 
            {
                ...state,
                loading: false,
                entityName: action.resourceName
            }
            if(action.targetProp) {
                slaObj[action.targetProp] = action.item
            }
            else {
                slaObj.sla = action.item
            }
            slaObj.inputs = action.data && action.data.inputs
            return slaObj
        case (DashboardConstants.SLAAPI_FAILURE + '_' + action.resourceName):
            return {
                ...state,
                loading: false,
                entityName: action.resourceName
            }
        
        case (DashboardConstants.LOADER_FALSE + '_' + action.resourceName):
            // if(action.item) {
            return {
                ...state,
                loading: false,
                entityName: action.resourceName
            }
        //} 




        default:
            return state
    }
}
// }
