/**
 * may not be needed once moved to "entity" module parent structure
 */
import { alertActions, namespaceConstants, namespaceServices } from '../'

const getScoketNamespaceRequest = () => ({
	type: namespaceConstants.GETALL_NAMESPACE_REQUEST,
})

const getScoketNamespaceSuccess = data => ({
	type: namespaceConstants.GETALL_NAMESPACE_SUCCESS,
	payload: {
		...data,
	},
})

const getScoketNamespaceFailure = error => ({
	type: namespaceConstants.GETALL_NAMESPACE_FAILURE,
	payload: {
		error,
	},
})

export const getScoketNamespace = (tenantId, userName, module, t, apiUrl) => {
	return dispatch => {
		dispatch(getScoketNamespaceRequest())
		namespaceServices.getScoketNamespace(tenantId, userName, module, apiUrl).then(
			data => {
				if (data && data.namespaceData) {
					dispatch(getScoketNamespaceSuccess(data.namespaceData))
				} else {
					let errorMsg = (t && t('Unable to fetch settings from server try refreshing the page')) || 'Unable to fetch settings from server try refreshing the page'
					dispatch(getScoketNamespaceFailure(errorMsg))
					// dispatch(alertActions.error(errorMsg));
				}
			},
			error => {
				let errorMsg = (t && t('Unable to fetch settings from server try refreshing the page')) || 'Unable to fetch settings from server try refreshing the page'
				dispatch(getScoketNamespaceFailure(errorMsg))
				dispatch(alertActions.error(errorMsg))
			}
		)
	}
}
