import React, { memo } from 'react';
import { EntityDetail } from '../../common/base/entity/components/DetailComponent';
import { withTranslation } from 'react-i18next';
class GroupMainView extends EntityDetail {
  // async componentDidUpdate() {
  //   const { aadGroupId } = this.state.entityValues;
  //   console.log(9898982229,aadGroupId,this.state.curAadGroupId)
  //   if (aadGroupId && this.state.curAadGroupId != aadGroupId) {
  //     this.setState({
  //       curAadGroupId: aadGroupId,
  //     });
  //   }
  // }
}

export default withTranslation()(GroupMainView);
