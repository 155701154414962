import { AppBar, Dialog, DialogTitle, Fab, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { Add as AddIcon } from '@material-ui/icons'
import React, { Component } from 'react'
// Locale
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'
import { Loader } from '../../common'
import AddUserForm from './AddUserForm'
import UsersTable from './UsersTable'

//Locale

const styles = theme => ({
	fab: {
		margin: theme.spacing.unit,
	},
	dialogTitle: {
		color: 'white',
	},
})
// const emailRegx = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/
class RoleManagementComponent extends Component {
	constructor(props) {
		super(props)
		this.state = {
			addModal: false,
			data: [],
			searchString: null,
			userAttributeKey: '',
			selectedAttr: null,
			userAttributeValues: [],
			userAttributeValue: '',
			selectedKeyValAttributes: {},
		}
		this.toggleModal = this.toggleModal.bind(this)
	}
	async componentWillMount() {
		const { profile, t } = this.props
		this.props.getSsoData(profile.tenantId, t)
		await this.props.getAllUserAttributes(t)
		await this.props.getAllUsers(profile.tenantId, t)
		this.setState({ data: this.props.userRoles.users ? this.props.userRoles.users : [] })
		//await this.props.getUserRoles(profile.tenantId, profile.userId)
		await this.props.getTenantRoles(profile.tenantId, null, t)
	}

	saveNewUser = async newUserData => {
		const { profile, t } = this.props
		await this.props.addUsers(profile.tenantId, newUserData, t)
		this.setState({ addModal: !this.state.addModal })
		await this.props.getAllUsers(profile.tenantId, t)
		this.setState({ data: this.props.userRoles.users })
	}

	handleLocking = async i => {
		const { profile, t } = this.props
		let userPayload = this.state.data[i]
		await this.props.modifyUsers(profile.tenantId, userPayload.id, {
			enabled: !userPayload['enabled'],
			username: userPayload['username'],
			firstName: userPayload['firstName'],
			lastName: userPayload['lastName'],
			t,
		})
		await this.props.getAllUserAttributes(t)
		await this.props.getAllUsers(profile.tenantId, t)
		await this.props.getTenantRoles(profile.tenantId, null, t)
		this.setState({ data: this.props.userRoles.users })
	}

	handleRequiredAction = async i => {
		const { profile, t } = this.props
		let userPayload = this.state.data[i]
		await this.props.registerUserRequiredActions(profile.tenantId, userPayload.id, {
			firstName: userPayload.firstName,
			userName: userPayload.username,
			email: userPayload.email,
			actions: { requiredActions: ['UPDATE_PASSWORD'], t },
		})
	}

	startEditing = (i, id) => {
		let { userRoles } = this.props,
			selectedKeyValAttributes = {}
		let { users, userAttributes } = userRoles
		users = users.find(o => o.id == id)
		if (users.attributes && Object.keys(users.attributes).length > 0) {
			Object.keys(users.attributes).map(key => {
				let tempAttr = userAttributes.find(o => o.key == key)

				if (tempAttr) {
					return (selectedKeyValAttributes[tempAttr.key] = users.attributes[key])

					// if(tempAttr.validationRule && tempAttr.validationRule.field && tempAttr.validationRule.field == 'text') {
					//   selectedKeyValAttributes[tempAttr.key].push({value: users.attributes[key].join(','), valueLabel: users.attributes[key].join(',')})
					// } else {
					//   users.attributes[key].forEach(attrVal => {
					//     tempAttr.valueLabels.forEach(elem => {
					//       if(elem.value == attrVal) {
					//         selectedKeyValAttributes[tempAttr.key].push(elem)
					//       }
					//     })
					//   })
					// }
				} else {
					return (selectedKeyValAttributes[key] = users.attributes[key])
				}
			})
		}

		this.setState({ editIdx: i, selectedKeyValAttributes })
	}

	stopEditing = async () => {
		const { profile, t } = this.props
		let userPayload = this.state.data[this.state.editIdx],
			{ selectedKeyValAttributes } = this.state
		userPayload.attributes = {}
		if (selectedKeyValAttributes && Object.keys(selectedKeyValAttributes).length > 0) {
			Object.keys(selectedKeyValAttributes).forEach(elem => {
				userPayload.attributes[elem] = []
				selectedKeyValAttributes[elem].forEach(attr => {
					userPayload.attributes[elem].push(attr)
				})
			})
		}
		await this.props.modifyUsers(profile.tenantId, userPayload.id, userPayload, t)
		this.setState({ editIdx: -1 })
		// await this.props.getUserRoles(profile.tenantId, profile.userId)
		await this.props.getTenantRoles(profile.tenantId, null, t)
	}
	handleSearch() {
		this.search()
	}
	handleSearchChange(e) {
		this.setState({ searchString: e.target.value.trim() || null })
	}
	handleKeyPress(e) {
		if (e.key === 'Enter') this.search()
	}
	search() {
		const { searchString } = this.state
		if (!searchString && this.props.userRoles.users.length > 0) {
			this.setState({
				data: this.props.userRoles.users,
			})
		}

		if (searchString && searchString.length > 0) {
			let user = this.props.userRoles.users
			user = user.filter(el => {
				for (let key in el) {
					if (typeof el[key] == 'string' && el[key].toLowerCase().includes(searchString.toLowerCase())) {
						return true
					}
				}
				return false
			})
			this.setState({
				data: user,
			})
		} else {
			this.setState({
				data: this.props.userRoles.users,
			})
		}
	}

	toggleModal = () => {
		this.setState({ addModal: !this.state.addModal, touched: false })
	}

	handleChange = (e, name, i) => {
		const { value } = e.target
		this.setState(state => ({
			data: state.data.map((row, j) => (j === i ? { ...row, [name]: value } : row)),
		}))
	}

	handleChangeAttr = evt => {
		if (evt.target.name == 'userAttributeValues' || evt.target.name == 'userAttributeValue') {
			this.setState({
				[evt.target.name]: evt.target.value,
			})
		} else {
			let {
				userRoles: { userAttributes },
			} = this.props

			let selectedAttr = userAttributes.find(o => o.key == evt.target.value)

			this.setState({
				[evt.target.name]: evt.target.value,
				selectedAttr,
			})
		}
	}

	addSelectedAttributes = () => {
		let { userAttributeKey, selectedAttr, userAttributeValues, userAttributeValue, selectedKeyValAttributes } = this.state

		if (userAttributeValues && userAttributeValues.length > 0) {
			selectedKeyValAttributes[userAttributeKey] = userAttributeValues
		}

		if (userAttributeValue && userAttributeValue.trim() != '') {
			selectedKeyValAttributes[userAttributeKey] = []
			selectedKeyValAttributes[userAttributeKey].push(userAttributeValue)
		}

		this.setState({
			selectedKeyValAttributes,
			userAttributeKey: '',
			selectedAttr: null,
			userAttributeValues: [],
			userAttributeValue: '',
		})
	}

	removeAttr = (evnt, key) => {
		let { selectedKeyValAttributes } = this.state
		delete selectedKeyValAttributes[key]

		this.setState({ selectedKeyValAttributes })
	}

	render() {
		const {
			userRoles,
			classes,
			globalSetting: { productSetting },
			identity: { roles },
			t,
			ssoData,
		} = this.props
		let currUserRoles = userRoles && userRoles.selectedRoles,
			userAttributes = userRoles && userRoles.userAttributes
		let hideRole = ['Tenant Admin', 'ASC Admin']
		//if(roles && roles.indexOf("tenant_admin") === -1){
		currUserRoles = currUserRoles && currUserRoles.filter(role => hideRole.indexOf(role.name) === -1)

		//}
		currUserRoles &&
			productSetting &&
			Object.keys(productSetting).map(key => {
				if (productSetting[key] && !productSetting[key].status && productSetting[key].roles) {
					productSetting[key].roles.pop &&
						productSetting[key].roles.forEach(role => {
							var removeIndex = currUserRoles.map(item => item.name).indexOf(role)
							removeIndex != -1 && currUserRoles.splice(removeIndex, 1)
						})
				}
				return true
			})
		const title = 'User Management'

		let hasSso = false
		if (ssoData.data) {
			if (Object.keys(ssoData.data).length > 0) {
				hasSso = true
			}
		}

		return (
			<div className="animated fadeIn">
				{userRoles.loading && <Loader />}
				<div className="common-heading">
					<div className="row">
						<div className="col-sm-6">
							<div className="heading">
								<h5>{t(title)} </h5>
								
							</div>
						</div>
						<div className="col-sm-6">
							<div className="search-frame">
								<i className="fa fa-search" onClick={this.handleSearch.bind(this)}></i>
								<input
									type="text"
									className="custom-search"
									id="search-user"
									placeholder={t('Search')}
									onChange={this.handleSearchChange.bind(this)}
									onKeyPress={this.handleKeyPress.bind(this)}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className="main-section">
					<div className="row">
						<div className="col-sm-4">
							{!userRoles.loading && !hasSso && (
								<div className="show-section">
									<Fab color="primary" aria-label="Add" className={classes.fab} onClick={this.toggleModal}>
										<AddIcon />
									</Fab>
									<h4 className="ad-faq">{t('ADD NEW USER')}</h4>
								</div>
							)}
						</div>
						<div className="col-sm-8">
							<div className="fiter-section">
								{/* <button className="dwnld" onClick={this.downloadAll.bind(this)}><i className="fa fa-download"></i>Export to CSV</button> */}
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-sm-12">
							<UsersTable
								{...this.props}
								handleLocking={this.handleLocking}
								startEditing={this.startEditing}
								editIdx={this.state.editIdx}
								stopEditing={this.stopEditing}
								handleChange={this.handleChange}
								data={this.state.data}
								roles={currUserRoles}
								attributes={userAttributes}
								loading={userRoles.loading}
								handleRequiredAction={this.handleRequiredAction}
								userAttributeKey={this.state.userAttributeKey}
								selectedAttr={this.state.selectedAttr}
								userAttributeValues={this.state.userAttributeValues}
								userAttributeValue={this.state.userAttributeValue}
								selectedKeyValAttributes={this.state.selectedKeyValAttributes}
								handleChangeAttr={this.handleChangeAttr}
								addSelectedAttributes={this.addSelectedAttributes}
								removeAttr={this.removeAttr}
								header={[
									{
										name: t('Username'),
										prop: 'username',
										disabled: true,
									},
									{
										name: t('Firstname'),
										prop: 'firstName',
										disabled: false,
									},
									{
										name: t('Lastname'),
										prop: 'lastName',
										disabled: false,
									},
									{
										name: t('Email'),
										prop: 'email',
										disabled: false,
									},
									{
										name: t('Roles'),
										prop: 'roles',
										disabled: false,
									},
									{
										name: t('Attributes'),
										prop: 'attributes',
										disabled: false,
									},
								]}
							/>
						</div>
					</div>
				</div>
				<Dialog open={this.state.addModal}>
					<AppBar position="static">
						<DialogTitle>
							<Typography className={classes.dialogTitle}>{t('Add a new user')}</Typography>
						</DialogTitle>
					</AppBar>
					<div style={{ padding: '20px' }}>
						<AddUserForm
							{...this.props}
							touched={this.state.touched}
							saveNewUser={this.saveNewUser}
							toggleModal={this.toggleModal}
							selectedRoles={currUserRoles}
							userAttributes={userAttributes}
							globalSetting={this.props.globalSetting}
						/>
					</div>
				</Dialog>
			</div>
		)
	}
}

export default compose(withTranslation(),withStyles(styles))(RoleManagementComponent)
