import {
	Box,
	Button,
	Center,
	Flex,
	FormControl,
	FormLabel,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Select,
	useDisclosure,
	useToast,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { Spinner } from 'reactstrap'
import { identityServices } from '../../..'
import '../../../css/style.css'

const TeamsPackageModal = props => {
	const { user } = props
	const { isOpen, onOpen, onClose } = useDisclosure()
	const toast = useToast()
	const [aadTenants, setAadTenants] = useState([])
	const [selectedAadTenant, setSelectedAadTenant] = useState('')
	const [isLoading, setIsLoading] = useState(false)
	const [teamsPackages, setTeamsPackages] = useState([])
	const [teamsPackageId, setTeamsPackageId] = useState('')
	const [userId, setUserId] = useState(null)
	const [disableInstallBtn, setDisableInstallBtn] = useState(false)
	const [installBtnText, setInstallBtnText] = useState('Install')

	const fetchTeamsId = () => {
		if (selectedAadTenant === '') return
		setIsLoading(true)
		let apiUrl = sessionStorage.getItem('apiUrl')
		const requestOptions = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
			url: `${apiUrl}/msteams/getPackageDetails?aadTenantId=${selectedAadTenant}`,
		}
		
		identityServices.identityApi
			.request(requestOptions)
			.then(res => {
				if (res.data && res.data.length === 1) {
					setTeamsPackageId(res.data[0].id)
				}
				setTeamsPackages(res.data)
				setIsLoading(false)
			})
			.catch(err => {
				toast({
					title: 'Error',
					description: err.message,
					status: 'error',
					duration: 5000,
					isClosable: true,
					position: 'top-right',
				})
				setIsLoading(false)
			})
	}

	const handleClose = event => {
		props.close()
		onClose()
	}

	const handleTenantSelect = event => {
		const selectedTenantId = event.target.value
		setSelectedAadTenant(selectedTenantId)
	}

	const handleTeamsPackageSelect = event => {
		const selectedBotId = event.target.value
		setTeamsPackageId(selectedBotId)
	}

	useEffect(() => {
		let apiUrl = sessionStorage.getItem('apiUrl')
		const fetchData = async () => {
			const requestOptions = {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
				},
				url: `${apiUrl}/msteams?$select=aadTenantId,clientId,clientSecret,botAppId,botAppSecret,botDirectlineSecret,botMSId,tenantName,attributeMap,status,tenantId,createdBy&$skip=0&$top=50`,
			}
			identityServices.identityApi
				.request(requestOptions)
				.then(res => {
					const aadTenantIds = res.data && res.data.filter((tenant) => tenant.status === 'Published')
					setAadTenants(aadTenantIds)

					if (aadTenantIds.length === 1) {
						setSelectedAadTenant(res.data[0].aadTenantId)
					}
				})
				.catch(err =>
					toast({
						title: 'Error',
						description: err.message,
						status: 'error',
						duration: 5000,
						isClosable: true,
						position: 'top-right',
					})
				)
		}
		fetchData()
	}, [])


	const installTeams = () => {
		setDisableInstallBtn(true)
		setInstallBtnText('Installing...')
		let apiUrl = sessionStorage.getItem('apiUrl')
		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			url: `${apiUrl}/msteams/installPackage?users=${user.email}&botId=${teamsPackageId}&teamsPackageId=${teamsPackageId}&aadTenantId=${selectedAadTenant}&isEmail=true`,
		}
		identityServices.identityApi
			.request(requestOptions)
			.then(res => {
				if (res.data.data && res.data.data.length > 0) {
					toast({
						title: 'Success',
						description: `installed successfully for ${res.data.data.length === 1 ? res.data.data[0] : res.data.data.join(',')}`,
						status: 'success',
						isClosable: true,
						position: 'top-right',
						duration: 5000,
					})
					setTimeout(() => {
						window.location.reload()
					}, 1000)
				} else if (res.data.error) {
					if (res.data.error.error && res.data.error.error.length > 0) {
						toast({
							title: 'error',
							description: `Failed to install Package, please check the logs`,
							status: 'error',
							duration: 5000,
							isClosable: true,
							position: 'top-right',
						})
					} else if (res.data.error.conflict && res.data.error.conflict.length > 0) {
						toast({
							title: 'error',
							description: `Teams app is already installed for the requested user`,
							status: 'error',
							duration: 5000,
							isClosable: true,
							position: 'top-right',
						})
					} else if (res.data.error.not_in_azure && res.data.error.not_in_azure.length > 0) {
						let users = res.data.error.not_in_azure.length > 1 ? res.data.error.not_in_azure.join(',') : res.data.error.not_in_azure[0]
						toast({
							title: 'error',
							description: `${users} is not present in azure directory`,
							status: 'error',
							duration: 5000,
							isClosable: true,
							position: 'top-right',
						})
					}
				}
				props.close()
				onClose()
			})
			.catch(err => {
				toast({
					title: 'Error',
					description: err.message,
					status: 'error',
					duration: 5000,
					isClosable: true,
					position: 'top-right',
				})
				props.close()
				onClose()
			})
	}

	useEffect(() => {
		onOpen()
	})

	useEffect(() => {
		fetchTeamsId()
	}, [selectedAadTenant])

	useEffect(() => {
		if (userId) {
			installTeams()
		}
	}, [userId])

	return (
		<div>
			<Modal isOpen={isOpen} onClose={onClose} size="4xl">
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Import from Azure AD</ModalHeader>
					<ModalCloseButton onClick={handleClose} />
					<hr />
					<ModalBody m={3} height="600px">
						{isLoading ? (
							<Center>
								<Spinner  />
							</Center>
						) : (
							<>
								<FormControl>
									<Flex justifyContent={'space-between'} mb={7} alignItems={'center'}>
										<FormLabel>AadTenant ID:</FormLabel>
										<Select onChange={handleTenantSelect} value={selectedAadTenant} w="75%">
											<option value="">Choose AAD Tenant ID</option>
											{aadTenants &&
												aadTenants.length &&
												aadTenants?.map(tenant => (
													<option key={tenant.aadTenantId} value={tenant.aadTenantId}>
														{tenant.aadTenantId}
													</option>
												))}
										</Select>
									</Flex>
								</FormControl>
								<FormControl>
									<Flex justifyContent={'space-between'} mb={7} alignItems={'center'}>
										<FormLabel>Teams ID:</FormLabel>
										<Select onChange={handleTeamsPackageSelect} value={teamsPackageId} w="75%">
											<option value="">Choose Teams Bot ID</option>
											{teamsPackages &&
												teamsPackages.length &&
												teamsPackages?.map(teamsPackage => (
													<option key={teamsPackage.id} value={teamsPackage.id}>
														{teamsPackage.id}
													</option>
												))}
										</Select>
									</Flex>
								</FormControl>
							</>
						)}
					</ModalBody>
					{!isLoading && (
						<Box>
							<Flex p={4} justifyContent="flex-end">
								<Button disabled={disableInstallBtn} mr={4} onClick={installTeams}>
									{installBtnText}
								</Button>
							</Flex>
						</Box>
					)}
				</ModalContent>
			</Modal>
		</div>
	)
}

export default TeamsPackageModal
