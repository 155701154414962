import PropTypes from 'prop-types'
import React, { createRef } from 'react'
import Dropzone from 'react-dropzone'
import { withTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { Button, Col, Container, Row } from 'reactstrap'
import { Loader } from '../../..'
import { getRegexMatch, validateType } from '../../../helpers/validators'
import { Errors } from './'
var d3 = require('d3-dsv')

// Disable click and keydown behavior on the <Dropzone>

export class ImportData extends React.Component {
	constructor(props) {
		super(props)
		this.dropzoneRef = createRef()
		this.openDialog.bind(this)
		this.state = {
			acceptedFiles: undefined,
		}
	}
	openDialog() {
		// Note that the ref is set async,
		// so it might be null at some point
		if (this.dropzoneRef && this.dropzoneRef.current) {
			this.dropzoneRef.current.open()
		}
	}
	shouldComponentUpdate(nextProps, nextState, nextContext) {
		if (nextState.newFile) return true
		if (this.state.acceptedFiles == undefined && nextState.acceptedFiles == undefined) return false
		if (!!this.state.acceptedFiles !== !!nextState.acceptedFiles) return true
		if (this.state.acceptedFiles && nextState.acceptedFiles && this.state.acceptedFiles.length !== nextState.acceptedFiles.length) return true
		if (this.state.validated !== nextState.validated) return true
		if (nextState.validationErrors) return true
		let nVSE = nextProps.entityList && nextProps.entityList.valServerErrors
		let cVSE = this.props.entityList && this.props.entityList.valServerErrors
		if (!!cVSE !== !!nVSE || !cVSE || cVSE.length !== nVSE.length) return true
		// if ((cVSE == undefined && nVSE) || (nVSE == undefined && cVSE) || (cVSE.length !== nVSE.length) ) return true
		if (nextProps.entity && nextProps.entity.error !== this.props.entity.error) return true
		return false
	}
	componentDidMount() {}
	componentWillReceiveProps(props) {
		if (this.state.serverValidationRequested && props.entity && !props.entity.loading)
			this.setState({ ...this.state, valServerErrors: props.valServerErrors || (props.entity && props.entity.error), validated: true })

		// this.setState({ ...this.state, valServerErrors: props.valServerErrors, error: props.entity && props.entity.error })
	}

	renderDropzone() {
		const { acceptedFiles } = this.state
		const { t } = this.props
		// let that = this
		return (
			<Dropzone ref={this.dropzoneRef} noKeyboard multiple={false} onDrop={this.onDrop.bind(this)}>
				{({ getRootProps, getInputProps }) => {
					return (
						<Container>
							<div {...getRootProps({ className: 'dropzone' })} className=" bg-lgrey pb-4">
								<input {...getInputProps({ disabled: false })} />
								<p className="text-center">{t('Drag `n` drop the filled template here')}</p>
								<div className="d-flex justify-content-center">
									<Button className="btn btn-sm btn-lg border shadow ">{t('Browse')}</Button>
								</div>
								<Row className="row d-block"></Row>
							</div>
							<aside className="pull-right">
								<ul>{acceptedFiles && acceptedFiles.map(file => <li key={file.path}>{file.path}</li>)}</ul>
							</aside>
						</Container>
					)
				}}
			</Dropzone>
		)
	}
	onDrop(acceptedFiles) {
		const {t} = this.props
		if (acceptedFiles) {
			if (acceptedFiles.length > 1) return toast.error(t(`Please upload only the template file`))
			if (acceptedFiles[0].path && acceptedFiles[0].path.includes('.tsv')) {
				const reader = new FileReader()

				reader.onabort = () => console.log('file reading was aborted')
				reader.onerror = () => console.log('file reading has failed')
				reader.onload = () => {
					// Do whatever you want with the file contents
					// const text = reader.result
					var records = d3.tsvParseRows(reader.result)
					//let records = text.split(/\r?\n/)
					this.setState({
						...this.state,
						acceptedFiles,
						records: records,
						validated: false,
						serverValidationRequested: false,
						validationErrors: undefined,
						newFile: true,
						valServerErrors: undefined,
					})
				}

				acceptedFiles.forEach(file => reader.readAsText(file))
			} else return toast.error(t(`Please upload a tsv file, Download the template from clicking the link above`))
		}
	}
	downloadTemplate() {
		let { meta, moduleName } = this.props
		let { importKeys, fields } = meta
		let tsvHeader = []
		tsvHeader = importKeys
			.map(k => {
				return fields[k] && fields[k].label
			})
			.filter(label => label && label !== 'Last Edited By' && label !== 'Last Edited At' && label !== 'Created By' && label !== 'Created At')
		let tsv = tsvHeader.join('\t')
		let filename = (moduleName && moduleName + '_template.tsv') || 'template.tsv'

		if (!tsv.match(/^data:text\/tsv/i)) {
			tsv = 'data:text/tab-seprated-value;charset=utf-8,' + tsv
		}
		let filePath = encodeURI(tsv)

		let link = document.createElement('a')
		link.setAttribute('href', filePath)
		link.setAttribute('download', filename)
		link.click()
	}
	cancel() {
		this.setState({
			...this.state,
			newFile: false,
			acceptedFiles: undefined,
			validated: false,
			serverValidationRequested: false,
			records: undefined,
			validationErrors: undefined,
			valServerErrors: undefined,
		})
	}
	fixTypes(val) {
		switch (val) {
			case 'TRUE':
				return true
			case 'true':
				return true
			case 'FALSE':
				return false
			case 'false':
				return false
			default:
				if (val.indexOf('[') !== -1 || val.indexOf('{') !== -1) {
					return JSON.parse(val)
				} else return val
		}
	}
	validate() {
		const { records } = this.state
		const { meta } = this.props
		const { fields } = meta || {}
		if (!records || !records.length) {
			toast.error(`No records were found in the uploaded file. Please upload again.`)
			this.cancel()
			return
		}
		let headers = records && records[0]
		if (headers && headers.filter(h => meta.importKeys && meta.importKeys.find(k => meta.fields[k] && meta.fields[k].label === h)).length !== headers.length) {
			toast.error(`Please upload template with right headers. Download Template from the link below.`)
			this.cancel()
			return
		}

		// let keys = meta.importKeys.filter( k => headers.find( h => meta.fields[k] && meta.fields[k].label === h))
		let keys = []
		headers.forEach(h => {
			let f = Object.values(meta.fields).find(f => f.label === h)
			meta.importKeys && meta.importKeys.includes(f.name) && keys.push(f.name)
		})
		let recs = records.map((r, i) => {
			if (i === 0) return null
			let vals = r
			let obj = {}
			headers.forEach((k, j) => {
				let f = Object.values(meta.fields).find(f => f.label === k)
				if (f && f.name) obj[f.name] = vals[j] && this.fixTypes(vals[j].trim())
			})
			return obj
		})
		recs = recs.filter(r => r)
		let validationErrors
		let finalRecs = []
		if (meta.recordValidator) {
			validationErrors = meta.recordValidator(recs)
		} else {
			validationErrors = []

			recs.forEach((r, i) => {
				let e = `Row: ${i + 1}:`
				let flag = false
				keys.forEach(k => {
					let f = fields && fields[k]
					if (!f) e += `Meta error for field ${k}`
					if (f.required && (r[k] == undefined || r[k] == '')) {
						e += `${f.label} is mandatory;`
						flag = true
					}
					if (f.regexPattern) {
						if (!getRegexMatch(f.regexPattern, r[k])) {
							flag = true
							e += `${f.label} pattern does not match ${f.regexPattern};`
						}
					}
					let err = r[k] && validateType({ type: f.type, value: r[k], label: f.label, options: f.options, isMulti: f.isMulti })
					if (err) {
						e += err
						flag = true
					} else if (f.type === 'select' && r[k]) {
						let option = f.options.find(o => o.label === r[k] || o.value === r[k] || o === r[k])
						r[k] = option.value || option.label || option
					}
				})
				if (flag) validationErrors.push(e)
				else finalRecs.push(r)
			})
		}
		validationErrors = validationErrors && validationErrors.pop && validationErrors.filter(e => e)
		if (validationErrors && validationErrors.length && validationErrors.length > 0)
			this.setState({ ...this.state, newFile: false, validationErrors, validated: true })
		// validate the uploaded records for cols schema and unique constraint if any.
		else if (this.props.validateItems) {
			this.setState(
				{ ...this.state, serverValidationRequested: true, validationErrors: undefined, records: finalRecs, newFile: false, valServerErrors: undefined },
				() => {
					this.props.validateItems({ items: finalRecs, method: 'bulkUpsert' })
				}
			)
		} else this.setState({ ...this.state, validated: true, validationErrors: undefined, records: finalRecs, newFile: false, valServerErrors: undefined })
	}
	add() {
		// const { entity: { entityValues }, meta: { idKey } } = this.props

		if (!this.props.upsertItems) console.error(`Incremental insert action is not found.`)
		else this.props.upsertItems({ items: this.state.records, t: this.props.t })
	}
	import() {
		if (!this.props.importItems) console.error(`Import action is not found.`)
		else this.props.importItems({ items: this.state.records, t: this.props.t })
	}
	render() {
		const { title, meta, t } = this.props

		const { acceptedFiles, validated, validationErrors, valServerErrors, serverValidationRequested } = this.state
		const { entityList } = this.props
		const loading = entityList && entityList.loading

		// const { valServerErrors} = entityList || {}
		return (
			<>
				{loading && <Loader />}

				<Container className="container">
					<Row className="mb-3">
						<Col md="6">
							<div className="heading">
								<h5>{t(title || (meta && meta.title) || 'Test Upload')}</h5>
							</div>
						</Col>
						<Col md="6" className="pull-right">
							<button className="btn btn-link pull-right" onClick={this.downloadTemplate.bind(this)}>
								{t('Download Template')}
							</button>
						</Col>
					</Row>

					<Row className="d-flex-row justify-content-center my-3 ">
						<Col md="5">{this.renderDropzone()}</Col>
					</Row>
					<Row className="d-flex-row justify-content-end mb-2">
						<Col md="5">
							{acceptedFiles && !validated && !serverValidationRequested && (
								<>
									<button className="mx-2 btn btn-lgrey  shadow border rounded " onClick={this.validate.bind(this)}>
										{t('Validate')}
									</button>
									<button className="mx-2 btn btn-lgrey shadow border rounded " onClick={this.cancel.bind(this)}>
										{t('Cancel')}
									</button>
								</>
							)}
							{acceptedFiles && validated && !validationErrors && !valServerErrors && (
								<>
									{/* <button className="mx-2 btn btn-lgrey  shadow border rounded " onClick={this.add.bind(this)}>{t('Add')}</button> */}
									<button className="mx-2 btn btn-lgrey  shadow border rounded " onClick={this.import.bind(this)}>
										{t('Import')}
									</button>
									<button className="mx-2 btn btn-lgrey shadow border rounded " onClick={this.cancel.bind(this)}>
										{t('Cancel')}
									</button>
								</>
							)}
						</Col>
					</Row>
					<Row className="d-flex justify-content-center">
						<Col md="10">
							{(validationErrors || valServerErrors) && (
								<>
									<p> {t('The following errors were found in records. Please correct them and upload again...')} </p>
									<Errors data={validationErrors || valServerErrors} />
								</>
							)}
						</Col>
					</Row>
				</Container>
			</>
		)
	}
}
ImportData.propTypes = {
	params: PropTypes.object,
	// entityValues: PropTypes.object,
	entity: PropTypes.shape({ entityValues: PropTypes.object, error: PropTypes.string }),

	loading: PropTypes.bool,
}
export default withTranslation()(ImportData)
