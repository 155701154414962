import { Alert, AlertIcon, ChakraProvider, Stack, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import React, { Component, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { Button, Form, FormGroup, Input, Label } from 'reactstrap'
// import { apiUrl, appRootPathPrefix } from '../../config'
import { history } from '../../redux/history'
import { Loader } from '../common'
import { identityServices } from '../common/index'
import './style.css'

class HolidaysComponent1 extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: false,
			editMode: false,
			errorMsg: null,
			tenantId: null,
			formMeta: this.props && this.props.meta && this.props.meta.fields && this.props.meta.fields ? this.props.meta.fields : {},
			calendar: [],
			formValues: { name: '', label: '', calendar: [], status: '' },
			tenant: props && props.tenant,
		}
		this.handleChange = this.handleChange.bind(this)
		this.handleCalendarChange = this.handleCalendarChange.bind(this)
		this.onSubmit = this.onSubmit.bind(this)
	}
	async componentDidMount() {
		let props = this.props
		const { entity, profile, t } = props
		this.setState({ tenantId: profile.tenantUid || null, tenant: props && props.tenant })
		if (props && props.params && props.params.itemId) {
			this.setState({ loading: true })
			await this.props.getOne({ filter: [{ name: 'id', value: props.params.itemId }], t })
		}
		if (props && props.meta && props.meta.fields && props.meta.fields) {
			this.setState({ formMeta: props.meta.fields })
		}
	}

	componentWillReceiveProps(props) {
		const { entity } = props
		if (entity && entity.entityValues && typeof entity.entityValues === 'object' && Object.keys(entity.entityValues).length > 0) {
			let entityValue = entity.entityValues
			this.setState({ formValues: entityValue, loading: entity.loading, editMode: true })
			if (entityValue.calendar) {
				this.setState({ calendar: entityValue.calendar })
			}
		} else {
			this.setState({
				formValues: { name: '', label: '', calendar: [], status: '' },
				calendar: [],
				editMode: false,
			})
		}
	}

	toCamelCase = str => {
		return str.replace(/^([A-Z])|\s(\w)/g, function (match, p1, p2, offset) {
			if (p2) return p2.toUpperCase()
			return p1.toLowerCase()
		})
	}
	typeModal(item) {
		const {
			meta: { categoriesMeta },
		} = this.props
		if (categoriesMeta && categoriesMeta[item]) return categoriesMeta[item]
	}

	handleCalendarChange(name, item) {
		this.setState({
			[name]: item,
		})
	}
	handleChange(event) {
		const { formValues } = this.state
		if (event.target.name === 'name') event.target.value = this.toCamelCase(event.target.value).trim()

		this.setState({
			formValues: {
				...formValues,
				[event.target.name]: event.target.value,
			},
		})
	}

	onSubmit(e) {
		e.preventDefault()
		let { t } = this.props
		const { formValues, calendar } = this.state
		let value = { ...formValues, calendar }
		if (value.name === '') value.name = this.toCamelCase(value.label).trim()

		this.setState({ loading: true })
		this.props.upsertItem({ entityValues: value, t })
		setTimeout(() => {
			this.setState({ loading: false })
		}, 300)
	}

	async delete() {
		const { formValues, tenant } = this.state
		let { t } = this.props
		this.setState({ loading: true })
		formValues.status = 'Deleted'
		let config = { apiUrl: '/Calendar', filter: `?$filter=holidays eq '${formValues.name}'` }
		let checkExists = await this.handleFieldApi(config)
		if (checkExists && checkExists.length > 0 && checkExists[0].tenantId === this.state.tenantId) {
			this.setState({ errorMsg: t(`Cannot Delete "${formValues.label}" as this is tagged to "${checkExists[0].label}" Calendar`) })
		} else {
			this.props.upsertItem({ entityValues: formValues, t })
			this.setState({ errorMsg: null })
		}
		setTimeout(() => {
			this.setState({ loading: false })
		}, 300)
	}

	async handleFieldApi(config) {
		const { profile } = this.props
		let tenant = this.state.tenant || this.props.tenant
		let apiUrlCustom = profile.tenantUid !== undefined && profile.tenantUid !== null ? `${tenant.apiUrl}` : tenant.apiUrl
		if (config && config && config.apiUrl) {
			let endPoint = config.apiUrl.replace(/`/g, "'")
			const requestOptions = {
				method: 'GET',
				headers: { Pragma: 'no-cache', 'Content-Type': 'application/json', clientApp: window.location.pathname.split('/')[1] },
				url: `${apiUrlCustom}${endPoint}${config.filter || ''}`,
			}

			let response = await identityServices.identityApi.request(requestOptions)
			if (response && response.status && response.status === 200) {
				if (response && response.data && response.data.rows) {
					response.data = response.data.rows
				}
				return response.data
			} else return false
		} else return false
	}
	back() {
		history.goBack()
	}
	render() {
		const { loading, formValues, formMeta, editMode, calendar, errorMsg } = this.state
		const { t } = this.props
		return (
			<div className="animated fadeIn">
				<div className="main-section">
					<div className="main-inner">
						{loading && <Loader />}
						<div className="common-heading">
							<div className="row table-title">
								<div className="col-sm-6">
									<div className="heading">
										<h5>{t('Holidays')}</h5>
									</div>
								</div>
								<div className="col-sm-6">
									<Button color="link" className="pull-right" onClick={e => history.goBack()}>
										{t('Back to List')}
									</Button>
								</div>
							</div>
						</div>
						<section>
							{errorMsg && <AlertBox status="error" title="Cannot Delete" msg={errorMsg} />}
							<Form onSubmit={this.onSubmit}>
								<pre>{JSON.stringify(this.state.timeZoneList, null, 2)}</pre>
								{formMeta &&
									Object.keys(formMeta).map((field, idx) => {
										let formField = formMeta[field]
										if (formField.create) {
											return (
												<FormGroup className="row" key={idx}>
													<div className="col-sm-3">
														<Label for="exampleEmail">{formField && formField.label ? t(formField.label) : ''}</Label>
													</div>
													<div className="col-sm-9">
														{formField && formField.type === 'select' && (
															<Input
																type={formField && formField.type ? formField.type : 'text'}
																name={formField && formField.name ? formField.name : 'formField'}
																value={formValues && formValues[formField.name]}
																className="col-sm-4"
																placeholder={formField && formField.placeholder ? t(formField.placeholder) : ''}
																required={(formField && formField.required) || false}
																onChange={this.handleChange}
																disabled={formField && formField.readOnlyOnEdit && editMode}>
																<option value="">{t('--Select--')}</option>
																{formField &&
																	formField.options &&
																	Array.isArray(formField.options) &&
																	formField.options.map((elem, id) => (
																		<option key={id} value={elem.value}>
																			{t(elem.label)}
																		</option>
																	))}
															</Input>
														)}
														{formField && formField.type === 'text' && formField.borderRead && (
															<Input
																type={formField && formField.type ? formField.type : 'text'}
																name={formField && formField.name ? formField.name : 'formField'}
																value={formValues && formValues[formField.name]}
																className="col-sm-4"
																id={!window.location.pathname.includes('create') ? "borderStyle" : ""}
																placeholder={formField && formField.placeholder ? t(formField.placeholder) : ''}
																required={(formField && formField.required) || false}
																onChange={this.handleChange}
																disabled={formField && formField.readOnlyOnEdit && editMode}
															/>
														)}
														{formField && formField.type === 'text' && !formField.borderRead && (
															<Input
																type={formField && formField.type ? formField.type : 'text'}
																name={formField && formField.name ? formField.name : 'formField'}
																value={formValues && formValues[formField.name]}
																className="col-sm-4"
																placeholder={formField && formField.placeholder ? t(formField.placeholder) : ''}
																required={(formField && formField.required) || false}
																onChange={this.handleChange}
																disabled={formField && formField.readOnlyOnEdit && editMode}
															/>
														)}

														{formField && formField.type === 'jsonObj' && formField.name === 'calendar' && (
															<TypeModal {...this.props} handleChange={this.handleCalendarChange} meta={calendar} config={formField} />
														)}
													</div>
												</FormGroup>
											)
										}
									})}
								<FormGroup>
									<div className="pull-right">
										{editMode && formValues.tenantId === this.state.tenantId && (
											<Button onClick={this.delete.bind(this, formValues.id)} style={{ cursor: 'pointer' }} color="danger">
												{t('Delete')}
											</Button>
										)}{' '}
										<Button color="primary">{editMode ? t('Update') : t('Create')}</Button>
									</div>
									<div className="clearfix"></div>
								</FormGroup>
							</Form>
						</section>
					</div>
				</div>
			</div>
		)
	}
}
export const HolidaysComponent = withTranslation()(HolidaysComponent1)
const TypeModal = props => {
	const { meta, config } = props
	const [calendar, setCalendar] = useState(meta)
	const [newMeta, setNewMeta] = useState({ name: '', date: '' })
	const [editMeta, setEditMeta] = useState({ name: '', date: '', id: -1 })
	const [addToggle, setAddToggle] = useState(false)
	const [editToggle, setEditToggle] = useState(false)

	useEffect(() => {
		setCalendar(meta)
	}, [meta])
	const onChange = e => {
		let name = e.target.name
		let value = e.target.value
		setNewMeta({ ...newMeta, ...{ [name]: value } })
	}
	const onEditChange = e => {
		let name = e.target.name
		let value = e.target.value
		setEditMeta({ ...editMeta, ...{ [name]: value } })
	}

	const add = () => {
		if (newMeta.name && newMeta.date) {
			calendar.push(newMeta)
			props.handleChange(config.name, calendar)
			setAddToggle(false)
		}
	}

	const edit = (id, itemName, itemDate) => {
		setEditToggle(true)
		setEditMeta({ id, name: itemName, date: itemDate })
	}

	const remove = id => {
		let filterCalendar = calendar
		filterCalendar = filterCalendar.filter((x, idx) => idx != id)
		props.handleChange(config.name, filterCalendar)
	}

	const editUpdate = () => {
		if (editMeta.name && editMeta.date) {
			calendar[editMeta.id] = { name: editMeta.name, date: editMeta.date }
			props.handleChange(config.name, calendar)
			setEditToggle(false)
			setEditMeta(null)
		}
	}
	const renderText = (item, idx, editItem) => {
		if (editItem && editItem.id && editItem.id == idx) return ''
		else if (editItem && editItem.id && editItem.id != idx) return item
		else return item
	}
	return (
		<div className="calendar">
			<ChakraProvider>
				<Table size="sm">
					<Thead>
						<Tr>
							<Th>{props.t('Name')}</Th>
							<Th>{props.t('Date')}</Th>
							<Th>
								{props.t('Action')}{' '}
								<span
									onClick={() => {
										setAddToggle(true)
									}}
									className="text-primary fa fa-plus-circle"
									style={{ fontSize: '16px', cursor: 'pointer' }}></span>
							</Th>
						</Tr>
					</Thead>
					<Tbody>
						{addToggle && (
							<Tr>
								<Td>
									<Input size="sm" type="text" name="name" onChange={onChange.bind(this)} />
								</Td>
								<Td>
									<Input size="sm" type="date" name="date" onChange={onChange.bind(this)} />
								</Td>
								<Td>
									<span
										onClick={() => {
											add()
										}}
										style={{ padding: '4px', fontSize: '16px', cursor: 'pointer' }}
										className="text-success fa fa-save"></span>
									<span
										onClick={() => {
											setAddToggle(false)
										}}
										style={{ padding: '4px', fontSize: '16px', cursor: 'pointer' }}
										className="text-danger fa fa-times-circle"></span>
								</Td>
							</Tr>
						)}

						{calendar &&
							Array.isArray(calendar) &&
							calendar.map((item, idx) => {
								return (
									<Tr key={idx}>
										<Td>
											{renderText(item.name, idx, editMeta)}
											{editToggle && editMeta.id == idx && <Input bsSize="sm" type="text" name="name" value={editMeta.name} onChange={onEditChange.bind(this)} />}
										</Td>
										<Td>
											{renderText(item.date, idx, editMeta)}
											{editToggle && editMeta.id == idx && <Input bsSize="sm" type="date" name="date" value={editMeta.date} onChange={onEditChange.bind(this)} />}
										</Td>
										<Td>
											{!editToggle && (
												<span
													onClick={() => {
														edit(idx, item.name, item.date)
													}}
													style={{ padding: '4px', fontSize: '16px', cursor: 'pointer' }}
													className="text-primary fa fa-edit"></span>
											)}
											{editToggle && editMeta.id == idx && (
												<span
													onClick={() => {
														editUpdate()
													}}
													style={{ padding: '4px', fontSize: '16px', cursor: 'pointer' }}
													className="text-success fa fa-save"></span>
											)}
											<span
												onClick={() => {
													remove(idx)
												}}
												style={{ padding: '4px', fontSize: '16px', cursor: 'pointer' }}
												className="text-danger fa fa-times-circle"></span>
										</Td>
									</Tr>
								)
							})}
					</Tbody>
				</Table>
			</ChakraProvider>
		</div>
	)
}

const AlertBox = props => {
	return (
		<Stack spacing={3}>
			<Alert status={props.status} variant="solid">
				<AlertIcon />
				{props.msg}
			</Alert>
			<br />
		</Stack>
	)
}
