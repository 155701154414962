import { AppHeader, AppSidebar, AppSidebarFooter, AppSidebarForm, AppSidebarHeader, AppSidebarMinimizer, AppSidebarToggler } from '@coreui/react'
import _ from 'lodash'
import queryString from 'query-string'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Container } from 'reactstrap'
import { bindActionCreators, compose } from 'redux'
import { profileActions, whiteLabelActions, WorkspaceAction } from '..'
import { alertActions, Footer, Header, identityActions, LocaleAction, settingsActions, Sidebar } from '../'
import { singleTenant } from '../../../config'
class MainLayout extends Component {
	constructor(props) {
		super(props)
		this.state = {
			isSidebarOpen: false,
		}
	}
	toggleSidebar = () => {
		this.setState((prevState) => ({
		  isSidebarOpen: !prevState.isSidebarOpen,
		}))
	  }
	componentWillMount() {
		const { globalSetting, identity, tenant } = this.props
		if ((!globalSetting || _.isEmpty(globalSetting)) && identity && identity.profile) {
			this.props.getAllGlobalSettings(identity.profile.tenantUid, null, this.props.t)
			this.props.getDataWithDefaultTenant(identity.profile.tenantUid, null, tenant && tenant.apiUrl)
		}
	}

	render() {
		const {
			identity,
			globalSetting: { uiFeature, loading },
			routeName,
		} = this.props
		const { profile } = identity
		let paramssearch,
			retenHeadSide = true,
			teamClass
		if (this.props.location && this.props.location.search) {
			paramssearch = queryString.parse(this.props.location.search)
		}
		if ((paramssearch && paramssearch.isTeams) || sessionStorage.getItem('isTeams')) retenHeadSide = false
		if (paramssearch && paramssearch.theme) teamClass = `teams-${paramssearch.theme}`

		//  hide Sidebar, header(logo, toggle, profile)
		let sidebarHide, headerHide, footerHide
		if (uiFeature && uiFeature.commonUiOptions && profile && profile.roles) {
			let commonUiOptions = uiFeature.commonUiOptions
			if (commonUiOptions && commonUiOptions.roles) {
				let priorityRoles = commonUiOptions.rolePriority || Object.keys(commonUiOptions.roles)
				priorityRoles &&
					priorityRoles.every(r => {
						if (profile.roles.indexOf(r) != -1 && routeName) {
							let routeData = commonUiOptions.roles[r]
							let uiOption =
								routeData && routeData[routeName]
									? routeData[routeName]
									: routeData && routeData.default && Object.keys(routeData.default).length > 0
										? routeData.default
										: null
							if (uiOption) {
								if (uiOption.sideNavbarHide) {
									sidebarHide = true
									let styleEL = document.querySelector('.sidebar-fixed .main')
									styleEL && setTimeout(() => styleEL.style.setProperty('margin-left', '0px', 'important'), 1000)
								}
								if (uiOption.headerHide) {
									headerHide = uiOption.headerHide
								}
								if (uiOption.footerHide) {
									footerHide = uiOption.footerHide
								}
								return false
							}
							return true
						}
					})
			}
		}
		return (
			// !localTest && !identity.isAuthenticated || loading ? <div className="loader"></div> : // commented for testing private routes without login
			// <div className="app">
			//   <Header {...this.props} />
			//   <div className="app-body">
			//     <Sidebar {...this.props} />
			//     <div className="main">
			//       {/* <Breadcrumb {...this.props} /> */}
			//       <Container fluid>
			//         {this.props.children}
			//       </Container>
			//     </div>
			//     <Aside {...this.props} />
			//   </div>
			//   <Footer />
			// </div>

			<div className="app">
				
				{retenHeadSide && (
					<AppHeader fixed style={{ padding: '4px' }}>
						<Header {...this.props} isSidebarOpen={this.state.isSidebarOpen} headerHide={headerHide} />
					</AppHeader>
				)}
				<div className={`app-body ${teamClass ? teamClass : ''}`}>

					{retenHeadSide && !sidebarHide && (
						<AppSidebar fixed display="lg">
							<AppSidebarHeader />
							<AppSidebarForm />
							{(uiFeature || singleTenant) && <Sidebar {...this.props}  toggleSidebar={this.toggleSidebar}/>}
							<AppSidebarFooter />
							<AppSidebarMinimizer />
						</AppSidebar>
					)}
					<div className={`main`}>
						{/* <div className='toggle-bar' onClick={this.toggleSidebar}>
							<AppSidebarToggler
								className="d-md-down-none sidebarToggle" display="lg">
								{this.state.isSidebarOpen ? (<MdArrowForwardIos className='arrow-forward' />) : (<MdOutlineArrowBackIosNew className='arrow-backward' />)}
							</AppSidebarToggler>
						</div> */}
						<Container fluid>{this.props.children}</Container>
					</div>
				</div>
				{!footerHide && <Footer {...this.props} />}
			</div>
		)
	}
}

const mapStateToProps = state => {
	const { identity, tenant, alert, settings, globalSetting, ProfileEntity, namespace, ssoData, whiteLabelEntity, localeReducers, workspace, tenantConfig } =
		state
	return {
		identity,
		tenant,
		alert,
		settings,
		globalSetting,
		ProfileEntity,
		namespace,
		ssoData,
		whiteLabelEntity,
		localeReducers,
		WorkspaceAction,
		workspace,
		tenantConfig,
	}
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			...settingsActions,
			...alertActions,
			...identityActions,
			...profileActions,
			...LocaleAction,
			...WorkspaceAction,
			...whiteLabelActions,
		},
		dispatch
	)
}

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(MainLayout)
