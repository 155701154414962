import Joi from 'joi-browser'
import * as schemas from '../../base/entity/schemas'
import { apiSchema, cardsMeta, filterSchema } from '../../schemas'

export const chartsMeta = Joi.object().pattern(
	/^/,
	Joi.object()
		.keys({
			name: Joi.string(),
			title: Joi.string(),
			metrics: Joi.array().items(
				Joi.object().keys({
					filters: Joi.object().pattern(/^/, filterSchema),
					series: Joi.string(),
					field: Joi.string(),
					operations: Joi.array().items(Joi.string()),
					groupBy: Joi.array().items(Joi.string()),
				})
			),
			filters: Joi.array().items(filterSchema), // dashboard filters are included.
			dynFilters: Joi.object().pattern(/^/, filterSchema),
			groupBy: Joi.array().items(Joi.string()), // if further group by needed... some of these could be in dependsOn too...
			dependsOnFields: Joi.array().items(Joi.string()),
			type: Joi.string().valid('pie', 'ring', 'dualAxis'),
			api: Joi.string(),
			dataProp: Joi.string(), // path to property that holds chart data.
			xy: Joi.string().when('chartType', { is: Joi.only(['lines', 'bars', 'histogram', 'dualAxis']), then: Joi.required(), otherwise: Joi.forbidden() }),
			xy2: Joi.string().when('chartType', { is: 'dualAxis', then: Joi.required(), otherwise: Joi.forbidden() }),
			chartType: Joi.string().required().valid('dualAxis', 'ring', 'lines', 'bars', 'histogram'),
			axes: Joi.array().items(
				Joi.object().keys({ field: Joi.string().required(), alias: Joi.string(), type: Joi.string().valid('cat'), range: Joi.array().items(Joi.number()), label: Joi.string() })
			),
			series: Joi.string(),
			value: Joi.string().when('chartType', { is: 'ring', then: Joi.required(), otherwise: Joi.forbidden() }),
			padding: Joi.array().items(Joi.number()).max(4),
			height: Joi.number(),
		})
		.without('api', ['filters', 'operations'])
		.xor('api', 'metrics', 'dataProp')
		.without('xy', 'value')
)
const itemSchema = Joi.object().keys({
	name: Joi.string(),
	type: Joi.string().valid('action', 'card', 'chart', 'table', 'section', 'filterForm', 'filter', 'reportTable', 'list', 'tabGroup'),
	col: Joi.number().integer().positive().max(5),
	position: Joi.number().integer().positive(),
	visible: Joi.string(),
})
const listMeta = Joi.object().keys({
	api: Joi.string(),
	dependsOnFields: Joi.array().items(Joi.string()),
	dataProp: Joi.string(), // path to property that holds chart data.
	title: Joi.string(),
	name: Joi.string(),
	filters: Joi.array().items(filterSchema), // dashboard filters are included.
	dynFilters: Joi.object().pattern(/^/, filterSchema),
	height: Joi.number(),
	colProps: Joi.array().items(
		Joi.object().keys({
			field: Joi.string().required(),
			className: Joi.string(),
			route: Joi.string(),
			label: Joi.string(),
			width: Joi.number().positive().min(10).max(100),
		})
	),
	className: Joi.string(),
})

const tabMeta = Joi.object().keys({
	name: Joi.string(),
	defaultActive: Joi.number().positive().integer(),

	tabs: Joi.array().items(itemSchema),
})

const apisMeta = Joi.object().pattern(/^/, apiSchema)
const listsMeta = Joi.object().pattern(/^/, listMeta)
const tabsMeta = Joi.object().pattern(/^/, tabMeta)
export const dashbordMeta = Joi.object().keys({
	actions: schemas.actionsMeta,
	fields: schemas.fieldsMeta,
	sections: schemas.sectionsMeta,
	cards: Joi.object().pattern(/^/, cardsMeta),
	charts: chartsMeta,
	apis: apisMeta,
	lists: listsMeta,
	title: Joi.string(),
	dashboardMeta: Joi.object().keys({
		title: Joi.string(),
		items: Joi.array().items(itemSchema),
		cols: Joi.array().items(Joi.number().integer().max(11).min(1)).min(1),
		name: Joi.string(),
		classes: Joi.string(),
		initialValues: Joi.object(),
		apis: Joi.array().items(Joi.string()),
		refresh: Joi.boolean(),
	}),
})
const treeTable = Joi.object().keys({
	api: Joi.string(),
	dependsOnFields: Joi.array().items(Joi.string()),
	dataProp: Joi.string(), // path to property that holds chart data.
	title: Joi.string(),
	name: Joi.string(),
	filters: Joi.array().items(filterSchema), // dashboard filters are included.
	dynFilters: Joi.object().pattern(/^/, filterSchema),
	height: Joi.number(),
	fields: Joi.array().items(Joi.object().keys({ name: Joi.string(), label: Joi.string(), headerClass: Joi.string().allow('', null), type: Joi.string() })),
})
export const reportsMeta = Joi.object().keys({
	actions: schemas.actionsMeta,
	fields: schemas.fieldsMeta,
	sections: schemas.sectionsMeta,
	cards: Joi.object().pattern(/^/, cardsMeta),
	charts: chartsMeta,
	tabs: tabsMeta,
	apis: apisMeta,
	lists: listsMeta,
	title: Joi.string(),
	treeTables: Joi.object().pattern(/^/, treeTable),
	reportsMeta: Joi.object().keys({
		title: Joi.string(),
		items: Joi.array().items(itemSchema),
		cols: Joi.array().items(Joi.number().integer().max(11).min(1)).min(1),
		name: Joi.string(),
		classes: Joi.string(),
		initialValues: Joi.object(),
		apis: Joi.array().items(Joi.string()),
	}),
})
