import { tenantConstants } from '../';

export const tenant = (state = {loading: true, isValidated: false, registrationInitiated: false, isRegistering: false}, action) => {
    switch (action.type) {

        case tenantConstants.GET_TENANT_REQUEST:
          return {
            ...state,
            loading: true
          };
        case tenantConstants.GET_TENANT_SUCCESS:
          if(action.payload) {
            return {
              ...state,
              loading: false,
              ...action.payload
            }
          } else {
            return {
              ...state,
              loading: false
            }
          }
        case tenantConstants.GET_TENANT_FAILURE:
          return {
            ...state,
            loading: false,
            failed: true,
            error: action.payload
          };


          default:
          return state
  }
}