import Joi from 'joi-browser'

const RoleGroup = Joi.object().keys({
  id: Joi.number().integer(),
  tenantId: Joi.string().required(),
  roleGroup: Joi.string().required(),
  roleInfo: Joi.array().items(Joi.object()
    .keys({
      role: Joi.string(),
      description: Joi.string(),
    }))
    .allow(null),
  status: Joi.string()
    .default('Published')
    .valid('Published', 'Deleted', 'Draft'),
  createdAt: Joi.date(),
  updatedAt: Joi.date().allow(null),
  createdBy: Joi.string().allow(null),
  updatedBy: Joi.string().allow(null),
  deletedSeq: Joi.number().integer(),
});
// These are the schemas of the return object from the api server
export const methodSchemas = {
    getAll: Joi.array().items(RoleGroup),
    getOne: RoleGroup,
    addItem: RoleGroup,
    deleteItem: Joi.object().keys({id: Joi.number().integer().positive()}),
    editItem: RoleGroup
}
export const collectionSchemas = {
  //  notes: note
}
export const entitySchema = RoleGroup