'use strict';
import { EntityActions } from '../common/base/entity';
// import {EntityComponent} from '../entity'

export class ContentAction extends EntityActions {
  postActionsHooks({ actionName, item }) {
    switch (actionName) {
      case 'getAll':
        item =
          item &&
          item.pop &&
          item.map(i => {
            if (i.roleInfo) {
              i.roleInfo = i.roleInfo.map(r => r.role);         
            }
            return i;
          });

        return item;

      default:
        return item;
    }
  }

  preActionsHooks({ actionName, item, propFields }) {
    if (!item) return;
    if ((item && actionName == 'addNewItem') || actionName == 'editItem') {
      return item;
    } else {
      return item;
    }
  }
}
