import { SubscriptionConstants } from '../'
import { alertActions } from '../../common'

export class SubscriptionActions {
	constructor(options) {
		this.service = options.service
	}
	getSubscriptions({ tenantId, t }) {
		let that = this
		return dispatch => {
			dispatch(
				that.request({
					method: 'GETSUBSCRIPTIONS',
				})
			)
			let errorMsg =
				(t && t('Cannot get the subscriptions at this moment, please try again later')) || 'Cannot get the subscriptions at this moment, please try again later'
			that.service
				.getSubscriptions({
					tenantId,
				})
				.then(result => {
					dispatch(
						that.success({
							method: 'GETSUBSCRIPTIONS',
							myPlans: result.tenantPlans,
							mySubscriptions: result.subscriptions,
							defaultPlans: result.defaultPlans,
							defaultProducts: result.defaultProducts,
						})
					)
				})
				.catch(error => {
					errorMsg = errorMsg + ' ' + (error ? error.message : '')
					dispatch(
						that.failure({
							method: 'GETSUBSCRIPTIONS',
							errorMsg,
						})
					)
					dispatch(alertActions.error(errorMsg))
				})
		}
	}

	upsert({ subscription, t }) {
		let that = this
		return dispatch => {
			dispatch(
				that.request({
					method: 'UPSERTSUBSCRIPTION',
				})
			)
			let errorMsg =
				(t && t('Cannot update the subscriptions at this moment, please try again later')) ||
				'Cannot update the subscriptions at this moment, please try again later'
			that.service
				.upsert({ subscription })
				.then(result => {
					dispatch(
						// @ts-ignore
						that.success({
							method: 'UPSERTSUBSCRIPTION',
							myPlans: result.tenantPlans,
							mySubscriptions: result.subscriptions,
						})
					)
				})
				.catch(error => {
					// let errorMsg = `Error from server. ${error && error.message || error || 'Please try again later.'}`
					errorMsg = errorMsg + ' ' + (error ? error.message : '')
					dispatch(that.failure({ method: 'UPSERTSUBSCRIPTION', errorMsg }))
					dispatch(alertActions.error(errorMsg))
				})
		}
	}

	request({ method }) {
		let type
		switch (method) {
			case 'GETSUBSCRIPTIONS':
				type = SubscriptionConstants.GETSUBSCRIPTIONS_REQUEST
				break
			case 'UPSERTSUBSCRIPTION':
				type = SubscriptionConstants.UPSERTSUBSCRIPTION_REQUEST
				break
			default:
		}
		// if (this.resourceName) type = type + '_' + this.resourceName
		return { type: type }
	}
	failure({ method, errorMsg }) {
		let type
		switch (method) {
			case 'GETSUBSCRIPTIONS':
				type = SubscriptionConstants.GETSUBSCRIPTIONS_FAILURE
				break
			case 'UPSERTSUBSCRIPTION':
				type = SubscriptionConstants.UPSERTSUBSCRIPTION_FAILURE
				break
			default:
		}
		// if (this.resourceName) type = type + '_' + this.resourceName
		console.error(`${type}:${errorMsg}`)
		return {
			type,
			error: errorMsg,
			// resourceName: this.resourceName
		}
	}
	success({ method, defaultProducts, defaultPlans, myPlans, mySubscriptions }) {
		let type
		switch (method) {
			case 'GETSUBSCRIPTIONS':
				type = SubscriptionConstants.GETSUBSCRIPTIONS_SUCCESS
				break
			case 'UPSERTSUBSCRIPTION':
				type = SubscriptionConstants.UPSERTSUBSCRIPTION_SUCCESS
				break
			default:
		}

		return {
			type,
			// resourceName: this.resourceName,
			defaultProducts,
			defaultPlans,
			myPlans,
			mySubscriptions,
		}
	}
	getFns() {
		return {
			getSubscriptions: this.getSubscriptions.bind(this),
			upsert: this.upsert.bind(this),
		}
	}
}
