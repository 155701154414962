import React from 'react';
import {withTranslation} from 'react-i18next';
import {Button, } from 'reactstrap';

class WorkflowButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedOption:null
        };
        this.handleWorkFlow = this.handleWorkFlow.bind(this);
    }

    componentWillMount() {
        let props = this.props
        this.setState({
            workflowActionMeta: props.workflowActionMeta, // actionDef
            entityValues: props.entityValues || {}, 
        })
        this.handleWorkFlow.bind(this)
    }

    handleWorkFlow(workflowActionMeta, workflowState) {
        if (!workflowActionMeta) return
        this.setState({ touched: true });
        const { entity: { entityValues }, meta: { idKey } } = this.props
        let actionFn = workflowActionMeta.action
      
        if (!this.props[actionFn]) console.error(`Workflow Action Fn ${actionFn} is not found.`)
        else this.props[actionFn]({ entityValues: this.state.entityValues, id: idKey && entityValues[idKey], workflowState, workflowActionMeta })
       // setTimeout(() => this.handleClearAll(), 3000);
    }
    
      handleClearAll() {
        this.setState({
            touched: false
        });
      }

  
    render() {
        const {t,   workflowActionMeta, possibleStates} = this.props
 

        return (
            <div className="tckt-edit-btn">
            {                 
                possibleStates && Array.isArray(possibleStates) 
                && possibleStates.map(workflowState=>
                    <Button type="button" key={workflowState.label} className="min-btn pull-right" onClick={this.handleWorkFlow.bind(this, workflowActionMeta, workflowState)}>{t(workflowState.label)}</Button>
                )
            }
            </div>
        )
    }
}

export default withTranslation()(WorkflowButton);

