export const GET_SSO_REQUEST = 'GET_SSO_REQUEST';
export const GET_SSO_SUCCESS = 'GET_SSO_SUCCESS';
export const GET_SSO_FAILURE = 'GET_SSO_FAILURE';

export const ADD_SSO_REQUEST = 'ADD_SSO_REQUEST';
export const ADD_SSO_SUCCESS = 'ADD_SSO_SUCCESS';
export const ADD_SSO_FAILURE = 'ADD_SSO_FAILURE';

export const MODIFY_SSO_REQUEST = 'MODIFY_SSO_REQUEST';
export const MODIFY_SSO_SUCCESS = 'MODIFY_SSO_SUCCESS';
export const MODIFY_SSO_FAILURE = 'MODIFY_SSO_FAILURE';
