import { toast } from 'react-toastify';
import { alertConstants, i18n } from '../';

export const success = message => dispatch => {
  dispatch({ type: alertConstants.SUCCESS, payload: message })
  toast.success(i18n.t(message === null ? message : message.charAt(0).toUpperCase() + message.slice(1)))
  dispatch(clear())
}

export const info = message => dispatch => {
  dispatch({ type: alertConstants.INFO, payload: message })
  toast.info(i18n.t(message))
  dispatch(clear())
}

export const error = message => dispatch => {
  dispatch({ type: alertConstants.ERROR, payload: message })
  toast.error(message)
  dispatch(clear())
}

export const warn = message => dispatch => {
  dispatch({ type: alertConstants.WARN, payload: message })
  toast.info(i18n.t(message))
  dispatch(clear())
}
export const clear = () => ({ type: alertConstants.CLEAR })
