import Joi from 'joi-browser'

const note = Joi.object().keys({note: Joi.string(), tenantId: Joi.string().allow(''), createdAt: Joi.date().timestamp(), createdBy: Joi.string(), status: Joi.string(), isInternal: Joi.boolean(), key: Joi.string()})
const support = Joi.object().keys({
    id: Joi.number().integer().positive(),
    tenantId: Joi.string().uuid(),
    key : Joi.string().required(),
    value: Joi.any().required(),
    status: Joi.string().valid('Published', 'Draft').default('Draft'),
    updatedBy: Joi.string(),
    updatedAt: Joi.date().timestamp(),
    notes: Joi.array().items(note),
    doSomething: Joi.array().items({label: Joi.string(), toState: Joi.string()} )
})
// These are the schemas of the return object from the api server
export const methodSchemas = {
    getAll: Joi.array().items(support),
    getOne: support,
    addItem: support,
    deleteItem: Joi.object().keys({id: Joi.number().integer().positive()}),
    editItem: support
}
export const collectionSchemas = {
    notes: note
}
export const entitySchema = support