import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { Button, Modal, ModalBody } from 'reactstrap'
class ConfirmDialog extends Component {
	constructor(props) {
		super(props)
		this.confirmOk = this.confirmOk.bind(this)
		this.toggleModal = this.toggleModal.bind(this)
		this.state = {
			openModal: this.props.openModal ? this.props.openModal : false,
		}
	}

	componentWillReceiveProps(nextProps) {
		this.setState(nextProps)
	}

	toggleModal() {
		let { openModal } = this.state
		this.setState({ openModal: !openModal })
		if (this.props.entity && this.props.entity.status === 'Deleted') {
			this.props.entity.status = 'Published'
		}
		if (this.props.handleStateReset) {
			this.props.handleStateReset('modelData')
		}
	}
	confirmOk() {
		let { actionMeta, actionObj, actionPayload, entity } = this.props
		actionMeta.callApiAction = true
		if (this.props.handleListAction) {
			this.props.handleListAction(actionMeta, entity, this.props.t)
		} else if (this.props.handleAction) {
			this.props.handleAction({ actionMeta, actionObj, actionPayload, t: this.props.t })
		}
		if (this.props.handleStateReset) {
			this.props.handleStateReset('modelData')
		}
	}

	render() {
		let { openModal } = this.state
		const { t } = this.props
		return (
			<div>
				<Modal autoFocus={false} isOpen={openModal} toggle={this.toggleModal} className={this.props.className || 'delete-card'}>
					<ModalBody>
						<p>{t(this.props.message)}</p>
					</ModalBody>
					<div className="button-wrap">
						<Button color="primary" onClick={this.confirmOk}>
							<img src={`assets/img/yes.png`} alt="" />
							{t('Yes')}
						</Button>{' '}
						<Button color="secondary" onClick={this.toggleModal}>
							<img src={`assets/img/no.png`} alt="" />
							{t('No')}
						</Button>
					</div>
				</Modal>
			</div>
		)
	}
}

ConfirmDialog.propTypes = {}

export default withTranslation()(ConfirmDialog)
