import {SubscriptionConstants} from '../'
export const subscription = (state = {}, action) => {
    switch (action.type) {
        case (SubscriptionConstants.UPSERTSUBSCRIPTION_REQUEST):
            return {
                ...state,
                loading: true,
                error: null,
                myPlans: null,
                mySubscriptions: null
            }
        case (SubscriptionConstants.GETSUBSCRIPTIONS_REQUEST):
            return {
                ...state,
                loading: true,
                error: null,
                defaultPlans: null,
                defaultProducts: null,
                myPlans: null,
                mySubscriptions: null
            }
        case (SubscriptionConstants.UPSERTSUBSCRIPTION_FAILURE):
            return {
                ...state,
                loading: false,
                error: action.error,

            }
        case (SubscriptionConstants.GETSUBSCRIPTIONS_FAILURE):
            return {
                ...state,
                loading: false,
                error: action.error,
                defaultPlans: null,
                defaultProducts: null,
                myPlans: null,
                mySubscriptions: null
            }
        case (SubscriptionConstants.UPSERTSUBSCRIPTION_SUCCESS):
            return {
                ...state,
                loading: false,
                error: null,
                myPlans: action.myPlans,
                mySubscriptions: action.mySubscriptions
            }
        case (SubscriptionConstants.GETSUBSCRIPTIONS_SUCCESS):
            return {
                ...state,
                loading: false,
                error: null,
                defaultPlans: action.defaultPlans,
                defaultProducts: action.defaultProducts,
                myPlans: action.myPlans,
                mySubscriptions: action.mySubscriptions
            }

        default:
            return state
    }
}
