import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Input, Label } from 'reactstrap'
import { settingsActions } from '../'

class Setting extends Component {
	componentWillMount() {
		var { profile } = this.props
		this.props.getAllGlobalSettings(profile.tenantUid, null, this.props.t)
	}

	handleSwitch = (settingData, settingId, status, area) => {
		let { profile, t } = this.props
		status = status === 0 ? 1 : 0
		let obj = settingData.data || settingData.setting_json
		obj[area].status = status
		this.props.updateGlobalSetting(obj, profile.tenantUid, t)
	}

	render() {
		const { globalSetting, t } = this.props
		let settingData = globalSetting.data || globalSetting.setting_json
		let tagsMgmt = settingData && settingData.tagsManagement
		return (
			<div className="row setting-global">
				<h4>
					{t('Global Settings')} <span className="badge badge-secondary"></span>
				</h4>
				{settingData && (
					<ul className="list-group list-group-flush">
						{tagsMgmt && (
							<li className="list-group-item d-flex justify-content-between align-items-center">
								{t(tagsMgmt.label)}
								<span className="">
									<Label className="switch switch-text switch-primary">
										<Input
											type="checkbox"
											defaultChecked={tagsMgmt.status ? true : false}
											className="switch-input"
											onClick={this.handleSwitch.bind(this, globalSetting, globalSetting.id, tagsMgmt.status, 'tagsManagement')}
										/>
										<span className="switch-label" data-on="On" data-off="Off"></span>
										<span className="switch-handle"></span>
									</Label>
								</span>
							</li>
						)}
					</ul>
				)}
			</div>
		)
	}
}

const mapStateToProps = state => {
	const { globalSetting, identity, alert } = state
	const { profile } = identity
	return {
		profile,
		alert,
		globalSetting,
	}
}

const mapDispatchToProps = dispatch => ({
	getAllGlobalSettings: (customerTenantId, userName, t) => dispatch(settingsActions.getAllGlobalSettings(customerTenantId, userName, t)),
	updateGlobalSetting: (settingData, settingId, t) => dispatch(settingsActions.updateGlobalSetting(settingData, settingId, t)),
})
const MyComponent = withTranslation()(Setting)

export default connect(mapStateToProps, mapDispatchToProps)(MyComponent)
