import { alertActions } from '../common'
import { Entity } from '../common/base/entity/entity'
import { ContentAction } from './ContentAction'
import { UserEntityService } from './EntityService'
import { collectionSchemas, entitySchema, methodSchemas } from './UserSchema'
import ImportTsvComponent from './components/ImportTsvComponent'
import DetailWithAttributes from './components/detailWithAttributes'
import UserList from './components/userList'
var subdomain = window.location.host.split('.')[1] ? window.location.host.split('.')[0] : false

class UserManagementEntity extends Entity {
	getActionFns() {
		let fns = super.getActionFns()
		let that = this
		return {
			...fns,
			resetPassword: ({ entityValues, id }) => {
				let updateItem = { ...entityValues }
				updateItem.actions = { requiredActions: ['UPDATE_PASSWORD'] }
				return dispatch => {
					this.service
						.resetPassword({
							item: updateItem,
							editId: entityValues.uid || id,
							path: 'role-management/users',
						})
						.then(item => {
							if (item) dispatch(alertActions.success(item))
							else dispatch(alertActions.success('Server error, please try later'))
						})
				}
			},
			deleteUser: ({ entityValues, id }) => {
				let updateItem = { ...entityValues }
				return dispatch => {
					this.service
						.deleteUser({
							item: updateItem,
							id: entityValues.uid || id,
							path: `role-management/users/deleteUser`,
						})
						.then(item => {
							if (item) {
								dispatch(alertActions.success(`${item.email} deleted successfully`))
								setTimeout(()=>window.location.reload(true),5000)  
							}
							else dispatch(alertActions.success('Server error, please try later'))
						})
						.catch(err => {
							// ${entityValues.email}
							dispatch(alertActions.error(`${err}`))
						})
				}
			}
		}
	}
}
const fieldsMeta = {
	username: {
		name: 'username',
		label: 'User Name',
		type: 'text',
		readOnly: true,
		required: true,
		placeholder: 'Username',
		ignoreT: true,
	},
	username1: {
		name: 'username',
		label: 'User Name',
		type: 'text',
		required: true,
		placeholder: 'Username',
		readOnly: true,
		ignoreT: true,
	},
	email: {
		name: 'email',
		label: 'Email',
		type: 'text',
		required: true,
		placeholder: 'Email',
		ignoreT: true,
		readOnlyOnEdit: true,
	},
	emailUpdate: {
		name: 'email',
		label: 'Email',
		type: 'text',
		required: true,
		placeholder: 'Email',
		disabled: true,
	},
	firstName: {
		name: 'firstName',
		label: 'First Name',
		type: 'text',
		required: true,
		placeholder: 'First Name',
		ignoreT: true,
	},
	lastName: {
		name: 'lastName',
		label: 'Last Name',
		type: 'text',
		required: true,
		placeholder: 'Last name',
		ignoreT: true,
	},
	roles: {
		label: 'Roles',
		name: 'roles',
		required: true,
		type: 'select',
		api: {
			path: `role/tenant-roles/null?tenantId=${subdomain}`,
			method: 'GET',
			response: {
				ref: 'data',
				labelKey: 'name',
				valueKey: 'name',
			},
			filters: [],
			uiFilter: {
				cond: "username != 'adminuser'",
				excludedValues: [],
			},
		},
		isMulti: true,
		placeholder: '---Select Roles---',
		// readOnly: true
		
	},
	importRoles: {
		name: 'roles',
		label: 'Roles',
		type: 'text',
		required: true,
		placeholder: 'Roles',
		filterable: false,
	},
	enabled: {
		name: 'enabled',
		label: 'Status',
		type: 'select',
		options: [
			{ label: 'Active', value: 'true' },
			{ label: 'Inactive', value: 'false' },
		],
		placeholder: '---Select Status---',
	},
	enabled1: {
		name: 'enabled1',
		label: 'Status',
		type: 'select',
		options: [
			{ label: 'Active', value: 'true' },
			{ label: 'Inactive', value: 'false' },
		],
		placeholder: '---select Status---',
	},
	attributes: {
		name: 'attributes',
		label: 'Attributes',
		type: 'text',
		placeholder: 'Attributes',
		filterable: false,
		required: true,
	},
}

const actionsMeta = {
	addSetting: {
		type: 'button',
		label: 'Add New',
		action: 'addItem',
		name: 'AddNew',
	},
	update: {
		type: 'button',
		label: 'Update',
		name: 'upsert',
		action: 'editItem',
	},
	upsert: {
		type: 'button',
		label: 'Update',
		name: 'Update',
		action: 'upsertItem',
	},
	create: {
		label: 'Add New User',
		action: 'addItem',
		name: 'create',
		type: 'button',
	},
	addNew: {
		label: 'Create',
		action: 'addNewItem',
		name: 'addNew',
		type: 'button',
	},
	view: {
		label: 'View',
		name: 'view',
		action: 'getItem',
		classes: ' ',
		type: 'link',
	},
	activate: {
		label: 'Activate',
		name: 'activate',
		action: 'getItem',
		classes: 'fa fa-eye fa-lg',
		type: 'span',
	},
	import: {
		label: 'Import',
		name: 'import',
		action: 'importData',
		classes: 'fa fa-eye fa-lg',
		type: 'span',
	},
	resetPassword: {
		label: 'Reset Password',
		name: 'resetPassword',
		action: 'resetPassword',
		classes: 'fa fa-user fa-lg',
		type: 'span',
		confirmAction: true,
		confirmActionMessage: 'Are you sure, You want to reset password?',
	},
	deleteUser: {
		label: 'Delete User',
		name: 'deleteUser',
		action: 'deleteUser',
		classes: 'fa fa-trash fa-lg',
		type: 'span',
		confirmAction: true,
		confirmActionMessage: 'Are you sure, You want to delete user?',
	},
	installTeamsPackage: {
		label: 'Install',
		name: 'installTeamsPackage',
		action: 'installTeamsPackage',
		type: 'button'
	}
}
let createFields = Object.keys(fieldsMeta)
	.filter(k => k != 'importRoles' && k != 'emailUpdate')
	.map(prop => {
		return { name: prop, type: 'field' }
	})
createFields = createFields.filter(f => f.name !== 'username1' && f.name != 'enabled1' && f.name != 'attributes')
let updateFields = Object.keys(fieldsMeta)
	.filter(k => k != 'importRoles' && k != 'email')
	.map(prop => {
		return { name: prop, type: 'field' }
	})
updateFields = updateFields.filter(f => f.name !== 'username' && f.name != 'enabled1' && f.name != 'attributes')

const sectionsMeta = {
	basicCreate: {
		title: 'White Label Settings',
		items: createFields,
		classes: 'ticket-form',
	},
	basicUpdate: {
		title: 'White Label Settings',
		items: updateFields,
		classes: 'ticket-form',
	},

	headerComponent: {
		component: 'DetailHeader',
	},
	updateUserForm: {
		cols: [8, 4],
		items: [
			{ name: 'basicUpdate', type: 'section', col: 1 },
			{ name: 'update', type: 'action', col: 2 },
		],
		classes: 'composite-inner-form',
	},
	createUserForm: {
		cols: [8, 4],
		items: [
			{ name: 'basicCreate', type: 'section', col: 1 },
			{ name: 'addNew', type: 'action', col: 2 },
		],
		classes: 'composite-inner-form',
	},
}
const screens = {
	view: {
		items: [
			{ name: 'headerComponent', type: 'section' },
			{ name: 'updateUserForm', type: 'section' },
		],
		classes: 'composite-form',
		title: 'Update User',
	},
	edit: {
		items: [
			{ name: 'headerComponent', type: 'section' },
			{ name: 'updateUserForm', type: 'section' },
		],
	},
	create: {
		items: [
			{ name: 'headerComponent', type: 'section' },
			{ name: 'createUserForm', type: 'section' },
		],
		title: 'Create User',
	},
	search: {
		items: [
			{ name: 'username', type: 'link' },
			{ name: 'firstName', type: 'field' },
			{ name: 'lastName', type: 'field' },
			{ name: 'email', type: 'field' },
		],
	},
	list: {
		items: [
			{ name: 'username', type: 'link' },
			{ name: 'firstName', type: 'field' },
			{ name: 'lastName', type: 'field' },
			{ name: 'email', type: 'field' },
			{ name: 'roles', type: 'field' },
			{ name: 'attributes', type: 'field' },
			{ name: 'enabled1', type: 'field' },
			{ name: 'Microsoft Teams Install', type: 'actions', actions: ['installTeamsPackage']},
			{ name: 'Actions', type: 'actions', actions: ['resetPassword','deleteUser','view'] },
		],
		layoutType: {
			type: ['Grid'],
			backgroundColor: '#fff',
			cardPerRow: 6,
			color: '#333',
			excluded: {
				hideField: false,
				filter: false,
				sort: true,
				paging: true,
				grid: false,
				all: false,
			},
		},
		title: 'Users',
		multiselect: false,
	},
}
const UserManagementModule = new UserManagementEntity({
	ServiceClass: UserEntityService,
	fieldsMeta,
	actionsMeta,
	sectionsMeta,
	screens,
	name: 'userManagement',
	title: 'User Management',
	methodSchemas,
	ActionClass: ContentAction,
	collectionSchemas,
	entitySchema,
	idKey: 'uid',
	fetchDetailByApi: true,
	entityUrlPaths: {
		update: 'role/users',
		add: 'role-management/users',
		getAll: 'botmgmt-service/appusers/getUsersWithTeamsPackage',
		getOne: 'botmgmt-service/appusers/findAndCountAll',
		default: 'role-management/users',
		import: 'role-management/bulkUpsert',
	},
	components: {
		Create: DetailWithAttributes,
		Detail: DetailWithAttributes,
		ImportData: ImportTsvComponent,
		ListSearch: UserList
	},
	importKeys: ['username', 'firstName', 'lastName', 'email', 'importRoles', 'attributes'],
	importLabel: ['User Name', 'First Name', 'Last Name', 'Email', 'Roles', 'Attributes'],
})

const UserManagementListSearchContainer = UserManagementModule.getContainer('ListSearch')
const UserManagementCreateContainer = UserManagementModule.getContainer('Create')
const UserManagementDetailContainer = UserManagementModule.getContainer('Detail')
const UserManagementImportContainer = UserManagementModule.getContainer('ImportData')
export { UserManagementCreateContainer, UserManagementDetailContainer, UserManagementImportContainer, UserManagementListSearchContainer }

