import { BaseService } from './base.services';
//import { apiUrl } from '../../../config';
//import { identityServices } from '../../common'
// let options = {};
// options.urlItemName ='CaseValue';
// options.wsType = 'ticket'
// options.wsEnabled = true
export default class TicketCommonServiceModel extends BaseService {
    // constructor(){
    //     super(options)
    // }
}
