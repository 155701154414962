// import { apiUrl, apiHostUrl } from '../../../config';
// import { identityServices } from '..'
import { BaseService } from '../services/base.services'
let options = {};
options.urlItemName = 'Locale';

class LocaleServices extends BaseService {
   
}
export const LocaleService = new LocaleServices(options);
