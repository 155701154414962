import _ from 'lodash'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import Select from 'react-select'
import { Button, FormGroup, Label, Modal, ModalBody, ModalHeader } from 'reactstrap'
import Input from 'reactstrap/lib/Input'
import styled from 'styled-components'
import { identityServices } from '../../..'
import noImage from '../../../../../assets/img/no.png'
import yesImage from '../../../../../assets/img/yes.png'

const ModelBtnStyled = styled.span`
 > img {
	max-width: 40%;
 }
`;

class CopyAction extends Component {
	constructor(props) {
		super(props)
		this.confirmOk = this.confirmOk.bind(this)
		this.toggleModal = this.toggleModal.bind(this)
		this.state = {
			openModal: this.props.mode ? this.props.mode : false,
			perPageOption: [],
			newRowData: {}
		}
	}
	
  getUniqueArray(array, compareProps) {
    const uniqueMap = new Map();
    array.forEach(obj => {
      const key = compareProps.map(prop => obj[prop]).join('|');
      uniqueMap.set(key, obj);
    });
    return Array.from(uniqueMap.values());
  }

  async componentDidMount(){
    let { tenant, fieldMeta } = this.props
    if(fieldMeta && !fieldMeta.modalActionMeta){
        let apiUrlCustom = `${(tenant && tenant.apiUrl)}/ticketing/queue?$filter=status eq 'Published'&$top=50`
        const requestOptions = {
          method: 'GET',
          headers: { 'Content-Type': 'application/json'},
          url: `${apiUrlCustom}`
        }
    
        let response = await identityServices.identityApi.request(requestOptions)
        if (response && response.status && response.status === 200) {
          if (response && response.data && response.data.rows) {
            response.data = response.data.rows
          }
                let datas =	response.data.map(data => ({ label: data.queueName, value: data.queueName }))
                datas=this.getUniqueArray(datas,['label','value'])
                this.setState({
                    perPageOption: datas
                })
        } else return false
    }
  }

	toggleModal() {
		let { openModal } = this.state
		this.setState({ openModal: !openModal })
		window.location.reload()
	}
	handleChange(field, newRowData) {
        if(field && field.name){
            this.setState({ 
                newRowData: {
                    ...this.state.newRowData,
                    [field.name]: ((newRowData.target && newRowData.target.value) || newRowData.value)
                }
            })
        }else {
            this.setState({ newRowData })
        }
	}
	confirmOk() {
		let { t, rowData, fieldMeta } = this.props
		let entityValues = rowData
        if(fieldMeta && fieldMeta.modalActionMeta && fieldMeta.modalActionMeta.fields){
            entityValues = {...entityValues, ...this.state.newRowData}
            delete entityValues.id
        }else {
            entityValues.queue = this.state.newRowData.value || rowData.queue
        }
		this.props.copyItem({entityValues, t})
		setTimeout(function () {
			window.location.reload()
		}, 1000)
	}

	render() {
    let { openModal, perPageOption, newRowData } = this.state
		let { t, rowData, fieldMeta } = this.props
        let modalActionMeta
        if(fieldMeta && fieldMeta.modalActionMeta){
            modalActionMeta = fieldMeta.modalActionMeta
        }
		return (
			<div>
				<Modal isOpen={openModal} className="workflow-modal">
          <ModalHeader className="copy-model-header">
            {t(modalActionMeta && modalActionMeta.headerMessage || 'Copy Email Template')}
          </ModalHeader>
          <ModalBody>
          <FormGroup className="row">
            {modalActionMeta && modalActionMeta.fields && Object.keys(modalActionMeta.fields).length > 0 ?
                <>
                 {
                     Object.keys(modalActionMeta.fields).map(field => {
                         if(modalActionMeta.fields[field] && modalActionMeta.fields[field].type === 'text'){
                            return (
                                <>
                                    <div className="col-sm-4">
                                    <Label style={{ fontSize: "1rem", fontWeight: "bolder" }}>{modalActionMeta.fields[field].label}</Label>
                                    </div>
                                    <div className="col-sm-8">
                                        <Input type='text' className='model-input' value={newRowData && newRowData[modalActionMeta.fields[field].name]} onChange={this.handleChange.bind(this, modalActionMeta.fields[field])} />
                                    </div>
                                </>
                            )
                         }else if(modalActionMeta.fields[field] && modalActionMeta.fields[field].type === 'select'){
                            return (
                                <>
                                    <div className="col-sm-4">
                                    <Label style={{ fontSize: "1rem", fontWeight: "bolder" }}>{modalActionMeta.fields[field].label}</Label>
                                    </div>
                                    <div className="col-sm-8">
                                        <Select className="pageSelect" value={_.isEmpty(newRowData[modalActionMeta.fields[field].name]) ? modalActionMeta.fields[field].default: modalActionMeta.fields[field].options.length > 0 && modalActionMeta.fields[field].options.filter(option => option.value === newRowData[modalActionMeta.fields[field].name]).length && modalActionMeta.fields[field].options.filter(option => option.value === newRowData[modalActionMeta.fields[field].name])[0]} options={modalActionMeta.fields[field].options} onChange={this.handleChange.bind(this, modalActionMeta.fields[field])}/>
                                    </div>
                                </>
                            )
                         }
                         return <></>
                     })
                 }
                </>
             : 
            <>
            <div className="col-sm-4">
              <Label style={{ fontSize: "1rem", fontWeight: "bolder" }}>{t('Template Name')}:</Label>
            </div>
            <div className="col-sm-8">
              <Input type='text' className='model-input' value={rowData.templateName} readOnly/>
            </div>
            <div className="col-sm-4">
              <Label style={{ fontSize: "1rem", fontWeight: "bolder" }}>{t('From Queue')}:</Label>
            </div>
            <div className="col-sm-8">
              <Input type='text' className='model-input' value={rowData.queue} readOnly/>
            </div>
            <div className="col-sm-4">
              <Label style={{ fontSize: "1rem", fontWeight: "bolder" }}>{t('To Queue')}:</Label>
            </div>
            <div className="col-sm-8">
              <Select className="pageSelect"  defaultValue={perPageOption[0]} value={_.isEmpty(newRowData) ? perPageOption[0]: newRowData} options={perPageOption} onChange={this.handleChange.bind(this, 'queue')}/>
            </div></>}
          </FormGroup>
          </ModalBody>
					<div className="copy-button-wrap">
						<Button color="secondary" onClick={this.toggleModal}>
							<ModelBtnStyled>
								<img src={noImage} alt="Cancle" />
							</ModelBtnStyled>
							{t('Cancel')}
						</Button>
						<Button color="primary" onClick={this.confirmOk}>
							<ModelBtnStyled>
								<img src={yesImage} alt="Copy" />
							</ModelBtnStyled>
							{t('Copy')}
						</Button>
					</div>
				</Modal>
			</div>
		)
	}
}


export default withTranslation()(CopyAction)
