import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { Label } from 'reactstrap'
// Stateless Component for TextEditor ::  Components Button
const EditorIcon1 = props => {
	const { toggle, icon } = props
	return (
		<div className="rdw-option-wrapper editor-icon" title="Upload" onClick={toggle || null}>
			<div className={icon || ''}></div>
		</div>
	)
}
// Stateless Component for TextEditor ::  Components Button

// Stateless Component for TextEditor :: Upload File Tab
const EditorUploadTab1 = props => {
	const { tab, switchTab, toggleTab } = props
	const [toggle, setToggle] = useState(switchTab)
	const { t } = props
	useEffect(() => {
		if (toggleTab && typeof toggleTab === 'function') toggleTab(toggle)
	}, [toggle])
	return (
		<div className="rdw-image-modal-header">
			{tab && tab.upload && (
				<span className="rdw-image-modal-header-option" onClick={() => setToggle(true)}>
					{(t && t('File Upload'))}{' '}
					{switchTab ? (
						<span className="rdw-image-modal-header-label rdw-image-modal-header-label-highlighted"></span>
					) : (
						<span className="rdw-image-modal-header-label"></span>
					)}
				</span>
			)}
			{tab && tab.url && (
				<span className="rdw-image-modal-header-option" onClick={() => setToggle(false)}>
					URL{' '}
					{!switchTab ? (
						<span className="rdw-image-modal-header-label rdw-image-modal-header-label-highlighted"></span>
					) : (
						<span className="rdw-image-modal-header-label"></span>
					)}
				</span>
			)}
		</div>
	)
}
// Stateless Component for TextEditor :: Upload File Tab

// Stateless Component for TextEditor :: Upload File Loader
const EditorLoader1 = props => {
	return (
		<>
			{props && props.loading && (
				<div className="rdw-image-modal-spinner">
					<div className="rdw-spinner">
						<div className="rdw-bounce1"></div>
						<div className="rdw-bounce2"></div>
						<div className="rdw-bounce3"></div>
					</div>
				</div>
			)}
		</>
	)
}
// Stateless Component for TextEditor :: Upload File Loader

// Stateless Component for TextEditor :: File Url Buttons
const UploadUrlButton1 = props => {
	const { url, btndisabled, addInputUrl, toggle, t } = props
	return (
		<span className="rdw-image-modal-btn-section">
			{url && (
				<button className="rdw-image-modal-btn" disabled={btndisabled} type="button" onClick={addInputUrl}>
					{(t && t('Add')) || 'Add'}
				</button>
			)}
			{url && (
				<button className="rdw-image-modal-btn" type="button" onClick={toggle}>
					{(t && t('Cancel')) || 'Cancel'}
				</button>
			)}
		</span>
	)
}
// Stateless Component for TextEditor :: File Url Buttons

// Stateless Component for TextEditor :: File Url Input Field
const UploadUrlInput1 = props => {
	const { url, inputUrl, inputChange } = props
	return (
		<>
			{url && (
				<div className="rdw-image-modal-url-section">
					<input className="rdw-image-modal-url-input" type="url" onChange={e => inputChange(e.target.value)} name="imgSrc" value={inputUrl} />
					<span className="rdw-image-mandatory-sign">*</span>
				</div>
			)}
		</>
	)
}
// Stateless Component for TextEditor :: File Url Input Field

const CustomInputField = props => {
	const [input, setInput] = useState({})

	const onChange = e => {
		let inputValue = { [e.target.name]: e.target.value }
		setInput(inputValue)
		props.inputChange(inputValue)
	}
	const { inputConfig, t } = props
	return (
		<>
			{inputConfig &&
				Array.isArray(inputConfig) &&
				inputConfig.length > 0 &&
				inputConfig.map((field, idx) => {
					return (
						<div key={idx} style={{ padding: '2px' }}>
							<Label for="file">{(t && t(field.label || 'Enter Input')) || field.label || 'Enter Input'}</Label>
							<input
								type="text"
								style={{ fontSize: '12px' }}
								value={input[field.fieldName] ? input[field.fieldName] : ''}
								onChange={onChange.bind(this)}
								name={field.fieldName || 'input'}
								placeholder={field.placeholder || 'Enter Input'}
							/>
						</div>
					)
				})}
		</>
	)
}

// Stateless Component for TextEditor :: File Upload Field
const UploadFileInput1 = props => {
	const [content, setContent] = useState()
	const [btndisabled, setBtndisabled] = useState(false)
	const onChange = event => {
		if (event && event.target.files && event.target.files[0]) setContent(event.target.files[0])
	}
	const uploadFile = () => {
		setBtndisabled(true)
		if (content) {
			props.onChange(content)
			setContent(null)
			setBtndisabled(false)
		}
	}
	const { inputAccept, upload, customFileConfig, onCustomInputChange, t } = props
	return (
		<>
			{upload && (
				<div className="video-modal">
					<div className="rdw-image-modal-upload-option">
						<Label for="file" className="rdw-image-modal-upload-option-label">
							{
								// @ts-ignore
								(t && t(content && content.name ? content.name : `Click to upload`)) || (content && content.name ? content.name : `Click to upload`)
							}
						</Label>
					</div>
					<input type="file" id="file" accept={inputAccept || '*'} onChange={onChange.bind(this)} className="rdw-image-modal-upload-option-input" />
					{customFileConfig && customFileConfig.customName && customFileConfig.inputField && (
						<CustomInputField inputConfig={customFileConfig.inputField} inputChange={onCustomInputChange} />
					)}

					<div className="rdw-image-modal-btn-section btnupload">
						<button className="rdw-image-modal-btn" type="button" onClick={uploadFile.bind(this)}>
							{(t && t('Upload')) || 'Upload'}
						</button>
					</div>
				</div>
			)}
		</>
	)
}
// Stateless Component for TextEditor :: File Upload Field

// Stateless Component for TextEditor :: Multiple Files Upload Field
const UploadMultipleFileInput1 = props => {
	const [content, setContent] = useState([])
	const [files, setFiles] = useState()
	const [btndisabled, setBtndisabled] = useState(false)
	const onChange = async event => {
		let files = []
		if (event && event.target.files && event.target.files[0]) {
			let uploadedFile = event.target.files
			let filesData = Object.keys(uploadedFile)
			await asyncForEach(filesData, async key => {
				props.validateFileSize(uploadedFile[key].size)
				let fileObj = {
					lastModified: uploadedFile[key].lastModified,
					lastModifiedDate: uploadedFile[key].lastModifiedDate,
					name: uploadedFile[key].name,
					size: uploadedFile[key].size,
					type: uploadedFile[key].type,
					webkitRelativePath: uploadedFile[key].webkitRelativePath,
				}
				files.push(fileObj)
			})
			setContent(files)
			setFiles(uploadedFile)
		}
	}

	const asyncForEach = async (array, callback) => {
		for (let index = 0; index < array.length; index++) {
			await callback(array[index], index, array)
		}
	}

	const uploadFile = () => {
		setBtndisabled(true)
		if (files && content) {
			props.onChange(content, files)
			setFiles(null)
			setContent([])
			setBtndisabled(false)
		}
	}
	const { inputAccept, upload, t } = props
	return (
		<>
			{upload && (
				<div className="video-modal">
					<div className="rdw-image-modal-upload-option">
						<Label for="file" className="rdw-image-modal-upload-option-label">
							{content && content.length === 0 && (t(`Click to upload`) || 'Click to upload')}
							{content &&
								content.length > 0 &&
								content.map(c => {
									return c && ((t && t(c.name)) || c.name)
								})}
						</Label>
					</div>
					<input
						type="file"
						id="file"
						accept={inputAccept || '*'}
						onChange={onChange.bind(this)}
						className="rdw-image-modal-upload-option-input"
						multiple={props.multipleFile ?? true}
					/>

					<div className="rdw-image-modal-btn-section btnupload">
						<button className="rdw-image-modal-btn" type="button" onClick={uploadFile.bind(this)}>
							{(t && t('Upload')) || 'Upload'}
						</button>
					</div>
				</div>
			)}
		</>
	)
}
// Stateless Component for TextEditor :: Multiple Files Upload Field

//StateLess Component for TextEditor :: ErrorMsg
const Alert1 = props => {
	const { msg, t } = props
	return (
		<>
			{msg && (
				<div className="text-danger" style={{ fontSize: '12px' }}>
					{(t && t(msg)) || msg}
				</div>
			)}
		</>
	)
}
//StateLess Component for TextEditor :: ErrorMsg
const EditorIcon = withTranslation()(EditorIcon1)
const EditorUploadTab = withTranslation()(EditorUploadTab1)
const EditorLoader = withTranslation()(EditorLoader1)
const UploadUrlButton = withTranslation()(UploadUrlButton1)
const UploadUrlInput = withTranslation()(UploadUrlInput1)
const UploadFileInput = withTranslation()(UploadFileInput1)
const UploadMultipleFileInput = withTranslation()(UploadMultipleFileInput1)
const Alert = withTranslation()(Alert1)
export { EditorIcon, EditorUploadTab, EditorLoader, UploadUrlButton, UploadUrlInput, UploadFileInput, UploadMultipleFileInput, Alert }

