import Joi from 'joi-browser'

const filters = Joi.object().keys({
	key: Joi.string(), //used to match user attribute key
	defaultValues: Joi.array()
		.items({
			value: Joi.string(),
			valueLabel: Joi.string(),
			parentValue: Joi.string().allow(null),
		})
		.allow(null),
	values: Joi.array().items({
		value: Joi.string(),
		valueLabel: Joi.string(),
		parentValue: Joi.string().allow(null),
	}),
	level: Joi.number().positive().integer(),
	label: Joi.string(),
	userAttrKey: Joi.string().allow(null),
	hierarchy: Joi.any(),
})

const Workspace = Joi.object().keys({
	id: Joi.number().integer().positive(),
	tenantId: Joi.string(),
	workspaceType: Joi.string().valid('qna', 'ticket', 'livechat', 'dashboard').required(),
	name: Joi.string().required(),
	label: Joi.string(),
	role: Joi.string().valid('Read', 'Edit', 'Approve'),
	properties: Joi.array().items(filters),
	createdAt: Joi.date().allow(null),
	updatedAt: Joi.date().allow(null),
	createdBy: Joi.string().allow(null),
	updatedBy: Joi.string().allow(null),
	status: Joi.string().default('Published'),
	deletedSeq: Joi.number().integer(),
})

export const methodSchemas = {
	getAll: Joi.array().items(Workspace),
	getOne: Workspace,
	addItem: Workspace,
	deleteItem: Joi.object().keys({ id: Joi.number().integer().positive() }),
	editItem: Workspace,
}
export const collectionSchemas = {
	//  notes: note
}
export const entitySchema = Workspace
