import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
// import { Link } from 'react-router-dom';
import { identityActions } from '../'
import '../css/master.css'
class Logout extends Component {
	componentWillMount() {}

	render() {
		const t = this.props.t
		//return <div> {t('You are successfully logged out')} <Link to="/">{t('Back to Home Page')} </Link></div>
		return (
			<div>
				<main className="new-login fp">
					<section>
						<div className="container-fluid">
							<div className="row">
								<div className="col-sm-12">
									<div className="forgot-page clearfix">
										<div className="forgot-img">
											{' '}
											<img src={`assets/img/desk.jpg`} alt="logout" />
										</div>
										<div className="logout-right ">
											<div className=" table-box">
												<div className="inner-table-box">
													<h5 className="logout-text">{t('You are now logged out')}.</h5>
													<a href="../../login" className="login-link btn-custom">
														{t('Login')}
													</a>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
				</main>
			</div>
		)
	}
}

const MyComponent = withTranslation()(Logout)
const mapDispatchToProps = dispatch => ({
	logout: () => dispatch(identityActions.logout()),
})
export default connect(null, mapDispatchToProps)(MyComponent)
