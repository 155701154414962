import React from 'react';
import {  Modal, ModalHeader, ModalBody, ModalFooter, Badge } from 'reactstrap';
// import 'react-select/dist/react-select.css'
import 'react-virtualized/styles.css'
import "react-virtualized-select/styles.css";
import Select from "react-virtualized-select";
class AppModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: this.props.isOpen || false,
            placeholder: this.props.placeholder || '-- select --- ',
            appCount: (this.props.routeList && this.props.routeList.length) || 0
        };
    }

    handleChange(e) {
        this.setState({
            selectedRoute: e,
            modal: false
        })
        setTimeout(() => window.location = `${window.location.protocol}//${window.location.hostname}/${e.value}`, 1000);
    }

    render() {
        const { routeList } = this.props;
        let options = routeList && routeList.map(route => {
            return { label: route.label || route.name, value: route.app.path }
        })
        return (
            <div>
                <Modal isOpen={this.state.modal} className={this.props.className}>
                    <ModalHeader>Apps <Badge color="secondary">{ this.state.appCount}</Badge></ModalHeader>
                    <ModalBody>
                        <Select
                            options={options}
                            placeholder={ this.state.placeholder }
                            isSearchable={false}
                            value={this.state.selectedRoute}
                            onChange={this.handleChange.bind(this)}
                        />
                    </ModalBody>
                    <ModalFooter>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default AppModal;