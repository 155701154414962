import { Box, FormControl, FormLabel, Input } from '@chakra-ui/react'
import React, { Component } from 'react'
// Locale
import { withTranslation } from 'react-i18next'
// import { apiUrl } from '../../../config'
import { identityServices } from '../../common'
//Locale
import './identity/index.css'
import PasswordChange from './PasswordChange'

class ProfileComponent extends Component {
	constructor(props) {
		super(props)
		this.state = {
			userProfile: {
				firstName: '',
				lastName: '',
				profilePic: null,
			},
			errors: {},
			file: null,
			loader: false,
			isSsoEnabled: false
		}
		this.handleChange = this.handleChange.bind(this)
		this.updateUser = this.updateUser.bind(this)
		this.chooseFile = this.chooseFile.bind(this)
	}
	componentWillReceiveProps(props) {
		const {
			ProfileEntity: { user },
		} = props
		const { userProfile } = this.state
		if (userProfile && userProfile.firstName !== '' && userProfile.lastname !== '') return true
		if (user) {
			let profilePicUrl = null
			if (user && user.attributes && user.attributes.profile_pic && user.attributes.profile_pic.length > 0) {
				profilePicUrl = user.attributes.profile_pic[0]
			}
			this.setState({
				userProfile: {
					firstName: user.firstName ? user.firstName : '',
					lastName: user.lastName ? user.lastName : '',
					profilePic: profilePicUrl,
				},
			})
		}
	}
	async componentWillMount() {
		const { profile, t } = this.props
		this.props.getUserById(profile.tenantId, profile.userId, t)
		// this.props.getSsoData(profile.tenantId, t)
		let apiUrl = sessionStorage.getItem('apiUrl')
		const requestOptions = {
		  method: 'GET',
		  headers: {
			'Content-Type': 'application/json',
		  },
		  url: `${apiUrl}/sso-management/idp`,
		}
		// @ts-ignore
		let ssoData = await identityServices.identityApi.request(requestOptions)
		if (ssoData?.status == 200 && ssoData?.data?.length > 0) this.setState({ isSsoEnabled: true })
	}
	handleChange(e) {
		const { userProfile } = this.state
		const { name, value } = e.target
		this.setState({
			userProfile: {
				...userProfile,
				[name]: value,
			},
		})
	}

	async updateUser(event) {
		event.preventDefault()
		const { userProfile } = this.state
		const { profile, ProfileEntity: { user } } = this.props
		const file = this.state.file ? this.state.file : null
		userProfile.profileUi=true
		if (file) {
			let imageLink = await this.UploadProfileImage()
			if (imageLink && imageLink.data && imageLink.data.link) {
				if(user.attributes && Object.keys(user.attributes).length > 0){
				    userProfile.attributes = { ...user.attributes, profile_pic: [imageLink.data.link] }	
				}else {
				userProfile.attributes = { profile_pic: [imageLink.data.link] }
				}
				this.setState({ file: null })
				this.setState({
					userProfile: {
						firstName: userProfile.firstName ? userProfile.firstName : '',
						lastName: userProfile.lastName ? userProfile.lastName : '',
						profilePic: imageLink.data.link,
						profileUi:true
					},
				})
			} else if (imageLink?.data?.errorMsg) {
				return this.props.modifyUsers({ errorMsg: imageLink.data.errorMsg })
			}
		}
		if (userProfile.firstName.trim() === '' || userProfile.lastName.trim() === '') return true
		if (userProfile) userProfile.username = profile.preferred_username
		this.props.modifyUsers(profile.tenantId, profile.userId, userProfile, this.props.t , this.props.tenant && this.props.tenant.apiUrl)
	}

	chooseFile(event) {
		const { userProfile } = this.state
		const file = event.target.files[0]
		let previewUrl = URL.createObjectURL(file)
		if (file) {
			this.setState({
				userProfile: {
					...userProfile,
					profilePic: previewUrl,
				},
				file: file,
			})
		}
	}

	UploadProfileImage() {
		if (!this.state.file) {
			this.setState({ errorMsg: 'No profile image is set to upload' })
			return new Promise((resolve, reject) => {
				resolve({ data: { link: null } })
			})
		}
		const file = this.state.file

		this.setState({ errorMsg: false })
		let fileSize = parseInt(file['size'] / 1024 / 1024)
		let fileExtension = /(.*?)\.(jpg|bmp|jpeg|JPG|JPEG|PNG|png|gif|GIF)$/
		if (!file['name'].match(fileExtension)) {
			this.setState({ errorMsg: 'Upload only jpg, gif or png format Image' })
			return new Promise((resolve, reject) => {
				resolve({ data: { link: null, errorMsg: 'Upload only jpg, gif or png format Image' } })
			})
		}
		if (fileSize > 5) {
			this.setState({ errorMsg: 'Uploading Image Size must be less than 5MB' })
			return new Promise((resolve, reject) => {
				resolve({ data: { link: null } })
			})
		} else {
			this.setState({ errorMsg: false })
			let { profile } = this.props
			let fileInfo = { type: 'image', access: 'public', uploadPublic: 'true' }
			let customerTenantID = profile.tenantUid
			let apiUrlCustom =
				customerTenantID !== undefined && customerTenantID !== null
					? `${this.props.apiUrl || (this.props.tenant && this.props.tenant.apiUrl)}`
					: this.props.apiUrl || (this.props.tenant && this.props.tenant.apiUrl)
			const formData = new FormData()
			formData.append('uploadConfig', JSON.stringify(fileInfo))
			formData.append('upload', file)

			const requestOptions = {
				method: 'POST',
				headers: { Pragma: 'no-cache', 'Content-Type': 'application/json' },
				data: formData,
				url: `${apiUrlCustom}/botmgmt-service/train/uploadContent`,
			}

			return new Promise((resolve, reject) => {
				identityServices.identityApi.request(requestOptions).then(res => {
					this.setState({ loader: false })
					resolve(res.data)
				})
			})
		}
	}

	render() {
		const { profile, tenant, ProfileEntity, t, ssoData } = this.props
		const { userProfile, loader, isSsoEnabled } = this.state
		let { email, profilePicUrl } = profile // given_name, family_name,, gender
		const { tenantId } = tenant
		// const { ProfileEntity: { user } } = this.props;

		let profilePic
		if (profilePicUrl) {
			profilePic = <img style={{ width: 100, height: 100, borderRadius: 15 }} src={profilePicUrl} alt="ProfileAvatar" />
		} else {
			profilePic = <img style={{ width: 100, height: 100 }} src="/assets/img/avatars/u-upload.png" alt="ProfileAvatar" />
		}

		if (userProfile.profilePic) {
			profilePic = <img style={{ width: 100, height: 100, borderRadius: 15 }} src={userProfile.profilePic} alt="ProfileAvatar" />
		}

		return (
			<div className="animated fadeIn" style={{padding:'5px',paddingLeft:'12px'}}>
				{((ProfileEntity && ProfileEntity.loading === true) || loader === true) && (
					<div className="outer-overlay">
						<div className="ov-content">
							<div className="row qnamaker-spinner">
								<div className="spinner-circle-mod">
									<img src="assets/img/main-loader.gif" alt="loading" />
								</div>
							</div>
							<div className="row spinner">
								<div className="loading-text">{t(this.state.customLoadingMessage)}</div>
								<div className="loading-text"></div>
							</div>
						</div>
					</div>
				)}

				<div className="common-heading">
					<div className="row">
						<div className="col-sm-6">
							<div className="heading" style={{marginBottom:'10px',marginTop:'10px'}}>
								<h5>{t('Update Profile')}</h5>
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-sm-6">
						<div className="general-form card">
							<div className="gp-heading">
								<h4>{t('General Info')}</h4>
							</div>
							<Box px={8} py={4}>
								<form className="custom-from profile-form-custom">
									<FormControl mb={8}>
										<div className="img-upload">
											<div className="upload-custom">
												<div className="outer-frame">
													{profilePic}
													<input type="file" name="ProfilePic" onChange={this.chooseFile} className="upload-custom-up" accept="image/png, image/jpg, image/jpeg, image/gif" />
													<p>{t('Upload Photo')}</p>
												</div>
											</div>
										</div>
									</FormControl>

									<FormControl mb={8}>
										<FormLabel mb={2}>{t('First Name')}</FormLabel>
										<Input
											required
											isInvalid={userProfile.firstName.trim() === '' ? true : false}
											placeholder={t('First Name')}
											errorBorderColor="#f44336"
											name="firstName"
											value={userProfile.firstName}
											onChange={this.handleChange}
											margin="normal"
										/>
									</FormControl>
									<FormControl mb={8}>
										<FormLabel mb={2}>{t('Last Name')}</FormLabel>
										<Input
											required
											isInvalid={userProfile.lastName.trim() === '' ? true : false}
											placeholder={t('Last Name')}
											errorBorderColor="#f44336"
											name="lastName"
											value={userProfile.lastName}
											onChange={this.handleChange}
											margin="normal"
										/>
									</FormControl>
									<FormControl mb={8}>
										<FormLabel mb={2}>{t('Email')}</FormLabel>
										<Input disabled name="Email" value={email} margin="normal" />
									</FormControl>
									<FormControl mb={8}>
										<FormLabel mb={2}>{t('Company Name')}</FormLabel>
										<Input disabled name="tenantId" value={tenantId} margin="normal" />
									</FormControl>
									<div className="col-sm-12">
										<button className="for-md-btn" onClick={this.updateUser}>
											{t('Save')}
										</button>
									</div>
									{/* <Fab aria-label="Save">
                                    <SaveIcon />
                                </Fab> */}
								</form>
							</Box>
						</div>
					</div>

					{!isSsoEnabled && <PasswordChange {...this.props} />}
				</div>
			</div>
		)
	}
}
export default withTranslation()(ProfileComponent)
