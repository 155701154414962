import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import {RichUtils} from 'draft-js'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './editor.css';
import * as CustomEditorComponent from './plugin/';
import { defaultToolbar } from './plugin/config';
import * as editorFunc from './plugin/customBlock';

class TextEditor extends Component {
	constructor(props) {
		super(props)
		this.state = {
			editorToolBar: defaultToolbar,
			value: this.props.value,
			customComponent: [],
			customConfig: {},
			disabledVideo: this.props.disabledVideo,
			disableImage: this.props.disableImage,
			colorPicker: this.props.colorPicker,
			ignoreExtnValidation: this.props.ignoreExtnValidation,
			edit: false,
		}
		this.onChange = this.onChange.bind(this)
		this.uploadImageValidate = this.uploadImageValidate.bind(this)
		this.uploadVideoValidate = this.uploadVideoValidate.bind(this)
	}

	componentWillMount() {
		this.setToolBar()
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.notModal && nextProps.defaultEditorState) {
			this.setState({ edit: true })
		}
		this.setState({ value: nextProps.value })
	}
	uploadImageValidate(file) {
		const { t } = this.props
		if (file) {
			this.setState({ errorMsg: false })
			let fileSize = parseInt(file['size'] / 1024 / 1024)
			let fileExtension = /(.*?)\.(jpg|bmp|jpeg|JPG|JPEG|PNG|png|gif|GIF)$/
			if (!file['name'].match(fileExtension)) {
				this.setState({ errorMsg: t('Upload only jpg, gif or png format Image') })
				return new Promise((resolve, reject) => {
					resolve({ data: { link: null } })
				})
			}
			if (fileSize > 1) {
				this.setState({ errorMsg: t('Uploading Image Size must be less than 2MB') })
				return new Promise((resolve, reject) => {
					resolve({ data: { link: null } })
				})
			} else {
				this.setState({ errorMsg: false })
				return this.props.uploadImage(file, this.props.isPublicUpload)
			}
		}
	}
	uploadVideoValidate(file, fileInfo) {
		return this.props.uploadVideo(file, fileInfo, this.props.isPublicUpload)
	}
	setToolBar() {
		const { customComponent } = this.state
		let toolbar =
			this.props.toolbar ||
			defaultToolbar({
				uploadImageValidate: this.uploadImageValidate,
				uploadVideoValidate: this.uploadVideoValidate,
				disabledVideo: this.state.disabledVideo,
				disableImage: this.state.disableImage,
				colorPicker: this.state.colorPicker,
				ignoreExtnValidation: this.state.ignoreExtnValidation,
			})
		if (toolbar) {
			this.setState({ editorToolBar: toolbar })
		}
		let customComponents = toolbar && toolbar.customComponents
		//importing custom Components from toolbar config
		if (customComponents && Array.isArray(customComponents) && customComponents.length > 0) {
			customComponents.forEach((component, idx) => {
				if (component.upload && component.renderComponent && CustomEditorComponent[component.renderComponent]) {
					let Custom = CustomEditorComponent[component.renderComponent]
					customComponent.push(<Custom key={idx} {...component} />)
					this.setState({ customConfig: component, customComponent })
				}
			})
		}
		//importing custom Components from toolbar config
	}
	onChange(value) {
		this.props.onChange(value)
	}

	handleKeyCommand(command,editorState){
		const newState = RichUtils.handleKeyCommand(editorState, command)
        if (newState) {
          this.onChange(newState)
          return "handled"
        }
	}
	render() {
		const { editorToolBar, value, customComponent, edit } = this.state
		const editorConfig = {
			toolbar: editorToolBar,
			onChange: this.onChange,
			toolbarCustomButtons: customComponent || [],
		}

		if (this.props && this.props.defaultEditorState && !this.props.notModal) {
			editorConfig.defaultEditorState = this.props.defaultEditorState
		} else if (this.props && this.props.defaultEditorState && edit) {
			editorConfig.defaultEditorState = this.props.defaultEditorState
		}
		if (this.props && this.props.editorRef) {
			editorConfig.editorRef = this.props.editorRef
		}
		let lang = (this.props && this.props.i18n && this.props.i18n.language) || 'en'
		lang = lang && lang.slice(0, 2)
		return (
			<>
				{edit && (
					<Editor
						value={value}
						localization={{
							locale: lang,
						}}
						handlePastedText={() => false}
						blockRendererFn={editorFunc.mediaBlockRenderer}
						handleKeyCommand={this?.handleKeyCommand}
						{...editorConfig}
					/>
				)}
				{!edit && (
					<Editor
						value={value}
						localization={{
							locale: lang,
						}}
						handlePastedText={() => false}
						blockRendererFn={editorFunc.mediaBlockRenderer}
						handleKeyCommand={this?.handleKeyCommand}
						{...editorConfig}
					/>
				)}
			</>
		)
	}
}

TextEditor.propTypes = {
	toolbar: PropTypes.object,
	value: PropTypes.object,
	onChange: PropTypes.func.isRequired,
}

export default TextEditor
