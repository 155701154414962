// import {
//     apiUrl
// } from '../../../config';
import { identityServices } from '../../common'

export const getSsoConfig = (tenanId, apiUrl) => {
	if (tenanId === undefined || tenanId == null) Promise.reject('`Error : Missing Tenant Id ')
	if (!apiUrl) apiUrl = sessionStorage.getItem('apiUrl')
	const requestOptions = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
		},
		url: `${apiUrl}/sso-management/idp`,
	}

	return identityServices.identityApi.request(requestOptions).then(response => {
		if (response.status !== 200) {
			return Promise.reject(`Unable to fetch SSO Configurations : Error code : ${response.status}`)
		}

		return response.data
	})
}

export const addSsoConfig = (tenanId, configPayload, apiUrl) => {
	if (tenanId === undefined || tenanId == null) Promise.reject('`Error : Missing Tenant Id ')
	if (!apiUrl) apiUrl = sessionStorage.getItem('apiUrl')
	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		url: `${apiUrl}/sso-management/idp`,
		data: configPayload,
	}

	return identityServices.identityApi.request(requestOptions).then(response => {
		if (response.status !== 201) {
			return Promise.reject(`Unable to add new SSO Configuration : Error code : ${response.status}`)
		}

		return response.data
	})
}

export const modifySsoConfig = (tenanId, alias, configPayload, apiUrl) => {
	if (tenanId === undefined || tenanId == null) Promise.reject('`Error : Missing Tenant Id ')
	if (!apiUrl) apiUrl = sessionStorage.getItem('apiUrl')
	const requestOptions = {
		method: 'PUT',
		headers: {
			'Content-Type': 'application/json',
		},
		url: `${apiUrl}/sso-management/idp/${alias}`,
		data: configPayload,
	}

	return identityServices.identityApi.request(requestOptions).then(response => {
		if (response.status !== 201) {
			return Promise.reject(`Unable to update the SSO Configuration for ${alias} : Error code : ${response.status}`)
		}

		return response.data
	})
}
