import { EntityConstants } from '..';

/**
 * 
 * Convert this to a base class 
 */

// export class BaseReducer {

export const entityList = (state = {}, action) => {
    // console.log('%%%%%%%%STATE IS ',state);
    // console.log('************* ACTIONS',action)
    // let type = action.type.slice(0,action.type.length- this.resourceName.length -1) 
    let returnObj
    switch (action.type) {
        case (EntityConstants.APPEND_REQUEST + '_' + action.resourceName):
            return {
                ...state,
                loading: true,
                entityName: action.resourceName
            }
        case (EntityConstants.APPEND_SUCCESS + '_' + action.resourceName):
            returnObj =
                {
                    ...state,
                    loading: false,
                    items: action.items,
                    valServerErrors: action.errors
                }

            return returnObj
        case (EntityConstants.APPEND_FAILURE + '_' + action.resourceName):
            return {
                ...state,
                loading: false,
                entityName: action.resourceName
            }
        case (EntityConstants.IMPORT_REQUEST + '_' + action.resourceName):
            return {
                ...state,
                loading: true,
                entityName: action.resourceName
            }
        case (EntityConstants.IMPORT_SUCCESS + '_' + action.resourceName):
            returnObj =
                {
                    ...state,
                    loading: false,
                    // items: action.items,
                    // valServerErrors: action.errors
                }

            return returnObj
        case (EntityConstants.IMPORT_FAILURE + '_' + action.resourceName):
            return {
                ...state,
                loading: false,
                entityName: action.resourceName
            }

        case (EntityConstants.EXECUTEAPI_REQUEST + '_' + action.resourceName):
            return {
                ...state,
                loading: true,
                entityName: action.resourceName
            }
        case (EntityConstants.EXECUTEAPI_SUCCESS + '_' + action.resourceName):
            returnObj =
                {
                    ...state,
                    loading: false,
                    entityName: action.resourceName
                }
            if (action.targetProp) returnObj[action.targetProp] = action.item
            else returnObj.data = action.item
            returnObj.inputs = action.data && action.data.inputs
            return returnObj
        case (EntityConstants.EXECUTEAPI_FAILURE + '_' + action.resourceName):
            return {
                ...state,
                loading: false,
                entityName: action.resourceName
            }
        case (EntityConstants.FILTEREDDATA_SUCCESS + '_' + action.resourceName):
            return {
                ...state,
                filteredData: action.data && action.data.filteredData,
                searchText: action.data && action.data.searchText,
                entityName: action.resourceName
            }
        case (EntityConstants.GETALL_REQUEST + '_' + action.resourceName):

            return {
                ...state,
                loading: true,
                itemData:[],
                entityName: action.resourceName
            }
        case (EntityConstants.GETALL_SUCCESS + '_' + action.resourceName):
            return {
                ...state,
                loading: false,
                itemData: action.item.rows || action.item,
                count : action.item.count || action.item.length,
                searchParams: action.data && action.data.filter,
                entityName: action.resourceName
            }
        case (EntityConstants.GETALL_FAILURE + '_' + action.resourceName):
            return {
                ...state,
                loading: false,
                entityName: action.resourceName
            }
        case (EntityConstants.ADDNEW_REQUEST + '_' + action.resourceName):
            return {
                ...state,
                loading: true,
                entityName: action.resourceName
            }
        case (EntityConstants.ADDNEW_SUCCESS + '_' + action.resourceName):
            return {
                ...state,
                loading: false,
                itemData: [state.itemData, action.item],
                entityName: action.resourceName
            }
        case (EntityConstants.ADDNEW_FAILURE + '_' + action.resourceName):
            return {
                ...state,
                loading: false,
                entityName: action.resourceName,
            }
    
        case (EntityConstants.EDIT_REQUEST + '_' + action.resourceName):
                return {
                    ...state,
                    loading: true,
                    entityName: action.resourceName,
                }
        case (EntityConstants.EDIT_SUCCESS + '_' + action.resourceName):
            if (action.item && action.item.status && action.item.status==="Deleted") {
                return {
                    ...state,
                    itemData: state.itemData && state.itemData.filter(item=>item.status!=='Deleted')
                    ,
                    loading: false,
                    entityName: action.resourceName,
                    }
                }
            else if (action.item) {
                return {
                    ...state,
                    itemData:  state.itemData && state.itemData.map(item=>{ 
                        if(item.id===action.item.id){
                          item = action.item;
                     }
                     return item
                    })
                    ,
                    loading: false,
                    entityName: action.resourceName,
                }
            }
            break;
        case (EntityConstants.EDIT_FAILURE + '_' + action.resourceName):
            return {
                ...state,
                loading: false,
                entityName: action.resourceName,
            }

        case (EntityConstants.DELETE_REQUEST + '_' + action.resourceName):
            return {
                ...state,
                loading: true,
                entityName: action.resourceName
            }
        case (EntityConstants.DELETE_SUCCESS + '_' + action.resourceName):
            if (action.item) {
                return {
                    ...state,
                    itemData:
                        state.itemData.filter(item => {
                            return item.id !== action.item[1][0].id
                        })
                    ,
                    loading: false,
                    entityName: action.resourceName
                }
            }
            break;
        case (EntityConstants.LOADER_FALSE + '_' + action.resourceName):
            // if(action.item) {
            return {
                ...state,
                loading: false,
                entityName: action.resourceName
            }
        //} 




        default:
            return state
    }
}
// }
