import { Axis, Chart, Geom, Legend, Tooltip } from 'bizcharts'
import React from 'react'

class DoubleAxes extends React.Component {
	constructor(props) {
		super(props)
		this.state = { loading: true }
	}
	// componentDidUpdate() {
	//     let that = this
	//     this.timeout && clearTimeout(this.timeout) && (this.timeout = null)
	//     this.timeout = setTimeout(() => {
	//         that.chartIns && that.chartIns.forceFit()
	//     }, 300)
	// }

	render() {
		let { t } = this.props
		const loading = this.state && this.state.loading
		if (loading) {
			this.setState({ loading: false })
			return <div>{(t && t('Loading')) || 'Loading...'}</div>
		}
		const data = [
			{
				time: '10:10',
				call: 4,
				waiting: 2,
				people: 2,
			},
			{
				time: '10:15',
				call: 2,
				waiting: 6,
				people: 3,
			},
			{
				time: '10:20',
				call: 13,
				waiting: 2,
				people: 5,
			},
			{
				time: '10:25',
				call: 9,
				waiting: 9,
				people: 1,
			},
			{
				time: '10:30',
				call: 5,
				waiting: 2,
				people: 3,
			},
			{
				time: '10:35',
				call: 8,
				waiting: 2,
				people: 1,
			},
			{
				time: '10:40',
				call: 13,
				waiting: 1,
				people: 2,
			},
		]
		const scale = {
			call: {
				min: 0,
			},
			people: {
				min: 0,
			},
			waiting: {
				min: 0,
			},
		}

		// {/* onGetG2Instance={chart => {
		//     that.chartIns = chart;
		//     that.chatIns && that.chatIns.forceFit()
		// }} */}
		return (
			<div>
				<Chart
					scale={scale}
					height={200}
					width={500}
					data={data}
					padding={[20, 20, 60, 40]}
					forceFit
					onGetG2Instance={chart => {
						this.chartIns = chart
					}}>
					<Legend
						custom={true}
						allowAllCanceled={true}
						items={[
							{
								value: 'waiting',
								marker: {
									symbol: 'square',
									fill: '#3182bd',
									radius: 5,
								},
							},
							{
								value: 'people',
								marker: {
									symbol: 'hyphen',
									stroke: '#ffae6b',
									radius: 5,
									lineWidth: 3,
								},
							},
						]}
						onClick={ev => {
							const item = ev.item
							const value = item.value
							const checked = ev.checked
							const geoms = this.chartIns.getAllGeoms()

							for (let i = 0; i < geoms.length; i++) {
								const geom = geoms[i]

								if (geom.getYScale().field === value) {
									if (checked) {
										geom.show()
									} else {
										geom.hide()
									}
								}
							}
						}}
					/>
					<Axis
						name="people"
						grid={null}
						label={{
							textStyle: {
								fill: '#fdae6b',
							},
						}}
					/>
					<Tooltip />
					<Geom type="interval" position="time*waiting" color="#3182bd" />
					<Geom type="line" position="time*people" color="#fdae6b" size={3} shape="smooth" />
					<Geom type="point" position="time*people" color="#fdae6b" size={3} shape="circle" />
				</Chart>
			</div>
		)
	}
	// render() {
	//     const dimensions  = this.state && this.state.dimensions;

	//     return (
	//         <div ref={el => (this.container = el)}>
	//             {dimensions && this.renderContent()}
	//         </div>
	//     );
	// }
}
export default DoubleAxes
