import Joi from 'joi-browser'


const auditLog = Joi.object().keys({
    id: Joi.number().integer().positive(),
    tenantId: Joi.string().uuid(),
    modelName: Joi.string().max(100).required(),
    requestPath: Joi.string().max(100).required(),
    requestMethod: Joi.string().max(100).required(),
    requestData: Joi.any().required(),
    responseStatus: Joi.number().integer().positive().required(),
    userId: Joi.string().max(100).required(),
    timeAt: Joi.date().iso(),
    userRoles: Joi.array().items(Joi.string().max(100)).required()
})
// These are the schemas of the return object from the api server
export const methodSchemas = {
    getAll: Joi.array().items(auditLog),
    getOne: auditLog,
    addItem: auditLog,
    deleteItem: Joi.object().keys({id: Joi.number().integer().positive()}),
    editItem: auditLog
}
export const collectionSchemas = {
  //  notes: note
}
export const entitySchema = auditLog