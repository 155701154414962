import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-xhr-backend'
// import { single } from "rxjs/operators";
import moment from 'moment-timezone'
import { initReactI18next } from 'react-i18next'
import { globalTenantServiceUrl, i18nSaving, localeUrl } from '../../../config'
// not like to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init
// const tenantId = '123123'

let customHeader = {}
const geti18n = (tenantId, token) => {
	if (token) {
		customHeader = {
			authorization: 'Bearer ' + token,
			'x-tenantid': tenantId,
			clientApp: window.location.pathname.split('/')[1],
			usecase: localStorage.getItem('product'),
		}
	} else {
		customHeader['x-tenantid'] = tenantId
		customHeader['clientApp'] = window.location.pathname.split('/')[1]
		customHeader['usecase'] = localStorage.getItem('product')
	}

	if (!tenantId) {
		tenantId = ''
		customHeader['x-tenantid'] = ''
		customHeader['clientApp'] = window.location.pathname.split('/')[1]
		customHeader['usecase'] = localStorage.getItem('product')
	}
	var localePath = `${globalTenantServiceUrl}`
	if (localeUrl) {
		localePath += localeUrl
	}

	localePath += `/Locale/{{lng}}/{{ns}}`
	// @ts-ignore
	i18n
		// load translation using xhr -> see /public/locales
		// learn more: https://github.com/i18next/i18next-xhr-backend
		.use(Backend)
		// detect user language
		// learn more: https://github.com/i18next/i18next-browser-languageDetector
		.use(LanguageDetector)
		// pass the i18n instance to react-i18next.
		.use(initReactI18next)
		// init i18next
		// for all options read: https://www.i18next.com/overview/configuration-options
		.init({
			backend: {
				loadPath: localePath,
				// loadPath: `${globalTenantServiceUrl}/languages/{{lng}}/{{ns}}/${tenantId}`,
				allowMultiLoading: false,
				crossDomain: true,
				addPath: `${globalTenantServiceUrl}/Locale/add/{{lng}}/{{ns}}`,
				customHeaders: customHeader,
				// addPath: `${globalTenantServiceUrl}/locales/add/{{lng}}/{{ns}}`
			},
			// resources:{
			//     en: {
			//         translation:{
			//             Welcome: 'Welcome to the new!',
			//             hey: 'hi there'
			//         }
			//     },
			//     de: {
			//         translation:{
			//             Welcome: 'Bon Jereaeaw!',
			//             hey: 'asdfads'
			//         }
			//     }

			// },
			// fallbackLng: {
			//   'default': userLanguage
			// },
			fallbackLng: 'en',
			// saveMissingTo:'current',
			// debug: true,
			saveMissing: i18nSaving,
			interpolation: {
				escapeValue: false, // not needed for react as it escapes by default

				format: function (value, format, lng) {
					if (value instanceof Date) {
						let now = new Date()
						let diff = now.getTime() - value.getTime()
						let olderThanDay = diff / 3600 / 24 / 1000 > 1
						return olderThanDay ? moment(value).tz(moment.tz.guess()).format('MMM-DD-YYYY') : moment(value).tz(moment.tz.guess()).fromNow()
					}

					return value
				},
			},
			react: {
				wait: true,
			},
			nonExplicitWhitelist: false,
			useSuspense: false,
		})
	i18n.on('languageChanged', function (lng) {
		moment.locale(lng)
	})
	return i18n
}
export const reloadResources = (tenantId, token) => {
	let customHeader = {}
	if (token) {
		customHeader = {
			authorization: 'Bearer ' + token,
			'x-tenantid': tenantId,
			clientApp: window.location.pathname.split('/')[1],
			usecase: localStorage.getItem('product'),
		}
	} else {
		customHeader['x-tenantid'] = tenantId
		customHeader['clientApp'] = window.location.pathname.split('/')[1]
		customHeader['usecase'] = localStorage.getItem('product')
	}

	if (!tenantId) {
		tenantId = ''
		customHeader['x-tenantid'] = ''
		customHeader['clientApp'] = window.location.pathname.split('/')[1]
		customHeader['usecase'] = localStorage.getItem('product')
	}
	// @ts-ignore
	i18n.init({
		backend: {
			loadPath: `${globalTenantServiceUrl}/Locale/{{lng}}/{{ns}}`,
			// loadPath: `${globalTenantServiceUrl}/languages/{{lng}}/{{ns}}/${tenantId}`,
			allowMultiLoading: false,
			crossDomain: true,
			addPath: `${globalTenantServiceUrl}/Locale/add/{{lng}}/{{ns}}`,
			customHeaders: customHeader,
			// addPath: `${globalTenantServiceUrl}/locales/add/{{lng}}/{{ns}}`
		},
		fallbackLng: 'en',
		// saveMissingTo:'current',
		// debug: true,
		saveMissing: i18nSaving,
		interpolation: {
			escapeValue: false, // not needed for react as it escapes by default

			format: function (value, format, lng) {
				if (value instanceof Date) {
					let now = new Date()
					let olderThanDay = (now.getTime() - value.getTime()) / 3600 / 24 / 1000 > 1
					return olderThanDay ? moment(value).tz(moment.tz.guess()).format('MMM-DD-YYYY') : moment(value).tz(moment.tz.guess()).fromNow()
				}

				return value
			},
		},
		react: {
			wait: true,
		},
		nonExplicitWhitelist: false,
		useSuspense: false,
	})
	i18n.on('languageChanged', function (lng) {
		moment.locale(lng)
	})
	return i18n
}
export default geti18n()
