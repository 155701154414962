import { baseConstants } from '..';

/**
 * 
 * Convert this to a base class 
 */

export class BaseReducer {
    constructor(options) {
        this.resourceName = options.resourceName
    }
    item(state = {}, action) {
        // console.log('%%%%%%%%STATE IS ',state);
        // console.log('************* ACTIONS',action)
        // let type = action.type.slice(0,action.type.length- this.resourceName.length -1) 
        switch (action.type) {
            case (baseConstants.GETALL_REQUEST + '_' + this.resourceName):
                return {
                    ...state,
                    loading: true
                }
            case (baseConstants.GETALL_SUCCESS + '_' + this.resourceName):
                return {
                    ...state,
                    loading: false,
                    itemData: action.item
                }
            case (baseConstants.GET_REQUEST + '_' + this.resourceName):
                return {
                    ...state,
                    loading: true
                }
            case (baseConstants.GET_SUCCESS + '_' + this.resourceName):
                return {
                    ...state,
                    loading: false,
                    itemData: action.item
                }
            case (baseConstants.ADDNEW_REQUEST + '_' + this.resourceName):
                return {
                    ...state,
                    loading: true
                }
            case (baseConstants.ADDNEW_SUCCESS + '_' + this.resourceName):
                return {
                    ...state,
                    loading: false,
                    itemData: [...state.itemData, action.item]
                }

            case (baseConstants.EDIT_REQUEST + '_' + this.resourceName):
                return {
                    ...state,
                    loading: true
                }
            case (baseConstants.EDIT_SUCCESS + '_' + this.resourceName):
                if (action.item) {
                    return {
                        ...state,
                        itemData: state.itemData.map(item => {
                            return item.id === action.item.id ? action.item : item
                        })
                        ,
                        loading: false
                    }
                }
                break;
            case (baseConstants.EDIT_FAILURE + '_' + this.resourceName):
                return {
                    ...state,
                    loading: false
                }

            case (baseConstants.CHANGE_STATUS_REQUEST + '_' + this.resourceName):
                return {
                    ...state,
                    loading: true
                }
            case (baseConstants.CHANGE_STATUS_SUCCESS + '_' + this.resourceName):
                if (action.item) {
                    return {
                        ...state,
                        itemData: {
                            data: state.itemData.data.map(item => {
                                return item.id === action.item.data.id ? action.item.data : item
                            })
                        },
                        loading: false
                    }
                }
                break;
            case (baseConstants.DELETE_REQUEST + '_' + this.resourceName):
                return {
                    ...state,
                    loading: true
                }
            case (baseConstants.DELETE_SUCCESS + '_' + this.resourceName):
                if (action.item) {
                    return {
                        ...state,
                        itemData:
                            state.itemData.filter(item => {
                                return item.id !== action.item[1][0].id
                            })
                        ,
                        loading: false
                    }
                }
                break;
                case (baseConstants.LOADER_FALSE+ '_' + this.resourceName):
               // if(action.item) {
                    return {
                        ...state,
                        loading: false 
                    }
                //} 


            default:
                return state
        }
    }
}
