/**
 * may not be needed once moved to "entity" module parent structure
 */
import { alertActions, namespaceActions, settingsConstants, settingsServices } from '../'

const changeStatusRequest = () => ({
	type: settingsConstants.CHANGE_STATUS,
})

const changeStatusSuccess = setting => ({
	type: settingsConstants.CHANGE_STATUS,
	payload: {
		...setting,
	},
})

const changeStatusFailure = error => ({
	type: settingsConstants.CHANGE_STATUS_FAILURE,
	payload: {
		error,
	},
})

//to be deleted
export const changeStatus = (status, id, t, apiUrl) => {
	return dispatch => {
		dispatch(changeStatusRequest())

		settingsServices.changeStatus(status, id, apiUrl).then(
			setting => {
				if (setting) {
					dispatch(changeStatusSuccess(setting))
				} else {
					let error = (t && t('Cannot update status at this moment try again later')) || 'Cannot update status at this moment try again later'
					dispatch(changeStatusFailure(error))
					dispatch(alertActions.error(error))
				}
			},
			error => {
				dispatch(changeStatusFailure(error))
				dispatch(alertActions.error(error))
			}
		)
	}
}

const getAllGlobalSettingsRequest = () => ({
	type: settingsConstants.GETALL_REQUEST,
})

const getAllGlobalSettingsSuccess = setting => ({
	type: settingsConstants.GETALL_SUCCESS,
	payload: {
		...setting,
	},
})

const getAllGlobalSettingsFailure = error => ({
	type: settingsConstants.GETALL_FAILURE,
	payload: {
		error,
	},
})

export const getAllGlobalSettings = (tenantId, userName, t, apiUrl) => {
	return dispatch => {
		dispatch(getAllGlobalSettingsRequest())
		let errorMsg = (t && t('Unable to fetch settings from server try refreshing the page')) || 'Unable to fetch settings from server try refreshing the page'
		settingsServices.getAllGlobalSettings(tenantId, apiUrl).then(
			data => {
				if (data) {
					dispatch(getAllGlobalSettingsSuccess(data))
					if (data.liveChat && data.liveChat.status === 1 && data.liveChat.agent_queue === 1) {
						dispatch(namespaceActions.getScoketNamespace(tenantId, userName, 'livechat', apiUrl))
					}
				} else {
					dispatch(getAllGlobalSettingsFailure(errorMsg))
					dispatch(alertActions.error(errorMsg))
				}
			},
			error => {
				// let errorMsg = 'Unable to fetch settings from server try refreshing the page'
				dispatch(getAllGlobalSettingsFailure(errorMsg))
				dispatch(alertActions.error(errorMsg))
			}
		)
	}
}

const updateGlobalSettingsRequest = () => ({
	type: settingsConstants.UPDATE_GLOBAL_SETTING_REQUEST,
})

const updateGlobalSettingsSuccess = setting => ({
	type: settingsConstants.UPDATE_GLOBAL_SETTING_SUCCESS,
	payload: {
		...setting,
	},
})

const updateGlobalSettingsFailure = error => ({
	type: settingsConstants.UPDATE_GLOBAL_SETTING_FAILURE,
	payload: {
		error,
	},
})

export const updateGlobalSetting = (settingData, settingId, t, apiUrl) => {
	return dispatch => {
		dispatch(updateGlobalSettingsRequest())
		let errorMsg = (t && t('Unable to fetch settings from server try refreshing the page')) || 'Unable to fetch settings from server try refreshing the page'
		settingsServices.updateGlobalSetting(settingData, settingId, apiUrl).then(
			setting => {
				if (setting) {
					dispatch(updateGlobalSettingsSuccess(setting))
					dispatch(alertActions.success((t && t('updated successfully')) || 'updated successfully'))
				} else {
					// let errorMsg = 'Unable to update settings try refreshing the page'
					dispatch(updateGlobalSettingsFailure(errorMsg))
					dispatch(alertActions.error(errorMsg))
				}
			},
			error => {
				// let errorMsg = 'Unable to update settings try refreshing the page'
				dispatch(updateGlobalSettingsFailure(errorMsg))
				dispatch(alertActions.error(errorMsg))
			}
		)
	}
}

const getCustomerSettingsRequest = () => ({
	type: settingsConstants.GET_SETTINGS_REQUEST,
})

const getCustomerSettingsSuccess = setting => ({
	type: settingsConstants.GET_SETTINGS_SUCCESS,
	payload: {
		...setting,
	},
})

const getCustomerSettingsFailure = error => ({
	type: settingsConstants.GET_SETTINGS_FAILURE,
	payload: {
		error,
	},
})

export const getSettingByCustomer = (customerName, t, apiUrl) => {
	return dispatch => {
		dispatch(getCustomerSettingsRequest())

		settingsServices.getSettingByCustomer(customerName, apiUrl).then(
			setting => {
				dispatch(getCustomerSettingsSuccess(setting))
				if (window.location.pathname === '/' && setting && setting.setting && setting.setting.setting && setting.setting.setting.isSignupHide) console.log("history.push('/login')")
			},
			error => {
				let errorMsg = (t && t('Unable to fetch customer settings from server try refreshing the page')) || 'Unable to fetch customer settings from server try refreshing the page'
				dispatch(getCustomerSettingsFailure(errorMsg))
			}
		)
	}
}

const getSsoDataRequest = () => ({
	type: settingsConstants.GET_SSODATA_REQUEST,
})

const getSsoDataSuccess = data => ({
	type: settingsConstants.GET_SSODATA_SUCCESS,
	payload: {
		...data,
	},
})

const getSsoDataFailure = error => ({
	type: settingsConstants.GET_SSODATA_FAILURE,
	payload: {
		error,
	},
})

export const getSsoData = (tenanId, t, apiUrl) => {
	return dispatch => {
		dispatch(getSsoDataRequest())

		settingsServices.getSsoData(tenanId, apiUrl).then(
			data => {
				dispatch(getSsoDataSuccess(data))
			},
			error => {
				let errorMsg = (t && t('Unable to fetch customer settings from server try refreshing the page')) || 'Unable to fetch customer settings from server try refreshing the page'
				dispatch(getSsoDataFailure(errorMsg))
			}
		)
	}
}

const getTenantConfigRequest = () => ({
	type: settingsConstants.GET_TENANT_CONFIG_REQUEST,
})

const getTenantConfigSuccess = setting => ({
	type: settingsConstants.GET_TENANT_CONFIG_SUCCESS,
	payload: {
		...setting,
	},
})

const getTenantConfigFailure = error => ({
	type: settingsConstants.GET_TENANT_CONFIG_FAILURE,
	payload: {
		error,
	},
})

export const getTenantConfig = (configType, tenantId, t, apiUrl) => {
	return dispatch => {
		dispatch(getTenantConfigRequest())
		let errorMsg = (t && t('Unable to fetch Tenant Config from server try refreshing the page')) || 'Unable to fetch Tenant Config from server try refreshing the page'
		settingsServices.getTenantConfig(configType, tenantId, apiUrl).then(
			data => {
				if (data) {
					dispatch(getTenantConfigSuccess(data))
				} else {
					dispatch(alertActions.error(errorMsg))
				}
			},
			error => {
				// let errorMsg = 'Unable to fetch Tenant Config from server try refreshing the page'
				dispatch(getTenantConfigFailure(errorMsg))
				dispatch(alertActions.error(errorMsg))
			}
		)
	}
}

const getAllTenantConfigRequest = () => ({
	type: settingsConstants.GETALL_TENANT_CONFIG_REQUEST,
})

const getAllTenantConfigSuccess = setting => ({
	type: settingsConstants.GETALL_TENANT_CONFIG_SUCCESS,
	payload: setting,
})

const getAllTenantConfigFailure = error => ({
	type: settingsConstants.GETALL_TENANT_CONFIG_FAILURE,
	payload: {
		error,
	},
})

export const getAllTenantConfig = (tenantId, t, apiUrl, userInfo) => {
	return (dispatch, getState) => {
		const { identity } = getState()
		dispatch(getAllTenantConfigRequest())
		let errorMsg = (t && t('Unable to fetch Tenant Config from server try refreshing the page')) || 'Unable to fetch Tenant Config from server try refreshing the page'
		settingsServices.getAllTenantConfig(tenantId, apiUrl, userInfo).then(
			data => {
				if (data) {
					dispatch(getAllTenantConfigSuccess(data))
				} else {
					// let errorMsg = 'Unable to fetch Tenant Config from server try refreshing the page'
					dispatch(alertActions.error(errorMsg))
				}
			},
			error => {
				// let errorMsg = 'Unable to fetch Tenant Config from server try refreshing the page'
				dispatch(getAllTenantConfigFailure(errorMsg))
				dispatch(alertActions.error(errorMsg))
			}
		)
	}
}
