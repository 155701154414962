import Joi from 'joi'
import { localTest } from '../../../config'
import { identityServices } from '../../common'
import { entitySchema, methodSchemas } from '../schemas/subscription.schema'

const entityUrlPaths = {
	upsert: 'subscription/upsert',
	default: 'subscription',
}

export class SubscriptionService {
	constructor(options) {
		this.entityUrlPaths = entityUrlPaths
		this.methodSchemas = methodSchemas
		this.entitySchema = entitySchema
	}

	getSubscriptions({ tenantId, apiUrl }) {
		if (!apiUrl) apiUrl = sessionStorage.getItem('apiUrl')
		let url = `${apiUrl}`
		let that = this
		let requestOptions = {
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			url: `${url}/${this.entityUrlPaths.getAll || this.entityUrlPaths.default || this.entityUrlPaths}`,
		}
		if (localTest) requestOptions.headers['x-user-payload'] = '{"tenantId": "a756723d-b48b-4be5-8c68-61831bf5a52b"}'
		let val
		return identityServices.identityApi
			.request(requestOptions)
			.then(response => {
				if (response.status !== 200) {
					return Promise.reject(response)
					// return res
				}
				if (response && response.data == null) return []
				if (that.methodSchemas && that.methodSchemas.getAll) {
					val = Joi.validate(response.data, that.methodSchemas.getAll)
					if (!val.error) return response.data
					else return Promise.reject(`Api response did not pass schema validation for Get All ${val.error}`)
				}
				// return res
				// console.log("user queue", response)
				return response.data
			})
			.catch(err => {
				return Promise.reject(`Error in api call ${(err.response && err.response.data && err.response.data.message) || err.message || err}`)
			})
	}
	upsert({ subscription, apiUrl }) {
		let that = this
		if (!apiUrl) apiUrl = sessionStorage.getItem('apiUrl')
		let apiUrlCustom = apiUrl
		if (this.entityUrlPaths.upsert) {
			apiUrlCustom += `/${this.entityUrlPaths.upsert}`
		} else {
			apiUrlCustom += `/${this.entityUrlPaths.default}/upsert`
		}
		//console.log("upsert",apiUrlCustom )
		let requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			data: JSON.stringify(subscription),
			url: apiUrlCustom,
		}
		if (localTest) requestOptions.headers['x-user-payload'] = '{"tenantId": "a756723d-b48b-4be5-8c68-61831bf5a52b"}'
		return identityServices.identityApi
			.request(requestOptions)
			.then(response => {
				if (response.status > 202) {
					return Promise.reject(response)
				}
				if (that.methodSchemas && that.methodSchemas.upsert) {
					let val = Joi.validate(response.data, that.methodSchemas.upsert)
					if (!val.error) return response.data
					else return Promise.reject(`Api response did not pass schema validation for Edit item ${val.error}`)
				}
				return response.data
			})
			.catch(err => {
				return Promise.reject(`Error in api call ${(err.response && err.response.data && err.response.data.message) || err.message || err}`)
			})
	}
}
