export const GETALLMETA_REQUEST='GETALLMETA_REQUEST'
export const GETALLMETA_SUCCESS='GETALLMETA_SUCCESS'
export const GETALLMETA_FAILURE='GETALLMETA_FAILURE'
export const GETPASTREPORT_REQUEST='GETPASTREPORT_REQUEST'
export const GETPASTREPORT_SUCCESS='GETPASTREPORT_SUCCESS'
export const GETPASTREPORT_FAILURE='GETPASTREPORT_FAILURE'
export const GETPASTLIST_REQUEST='GETPASTLIST_REQUEST'
export const GETPASTLIST_SUCCESS='GETPASTLIST_SUCCESS'
export const GETPASTLIST_FAILURE='GETPASTLIST_FAILURE'
export const GETSCHEDULESLIST_REQUEST='GETSCHEDULESLIST_REQUEST'
export const GETSCHEDULESLIST_SUCCESS='GETSCHEDULESLIST_SUCCESS'
export const GETSCHEDULESLIST_FAILURE='GETSCHEDULESLIST_FAILURE'
export const ADDSCHEDULE_REQUEST='ADDSCHEDULE_REQUEST'
export const ADDSCHEDULE_SUCCESS='ADDSCHEDULE_SUCCESS'
export const ADDSCHEDULE_FAILURE='ADDSCHEDULE_FAILURE'
export const UPDATESCHEDULE_REQUEST='UPDATESCHEDULE_REQUEST'
export const UPDATESCHEDULE_SUCCESS='UPDATESCHEDULE_SUCCESS'
export const UPDATESCHEDULE_FAILURE='UPDATESCHEDULE_FAILURE'
export const SAVEEMAIL_REQUEST='SAVEEMAIL_REQUEST'
export const SAVEEMAIL_SUCCESS='SAVEEMAIL_SUCCESS'
export const SAVEEMAIL_FAILURE='SAVEEMAIL_FAILURE'
export const DELETE_REQUEST = 'DELETE_REQUEST'
export const EXECUTEAPI_REQUEST = 'EXECUTEAPIREPORT_REQUEST'
export const DELETE_SUCCESS = 'DELETE_SUCCESS'
export const EXECUTEAPI_SUCCESS = 'EXECUTEAPIREPORT_SUCCESS'
export const DELETE_FAILURE = 'DELETE_FAILURE'
export const EXECUTEAPI_FAILURE = 'EXECUTEAPIREPORT_FAILURE'

export const LOADER_FALSE = 'LOADER_FALSE'