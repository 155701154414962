import React, { useEffect, useState } from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import { i18n } from '../../../..';
import JsonInput from '../JsonInput';


const MapInput = (props) => {
const [mapInput,setMapInput]=useState({})
    useEffect(()=>{
        let htmlProps={
            type:props && props.mapProps && props.mapProps.type ? props.mapProps.type.toLowerCase() : props.type || 'text',
            label : i18n.t(props && props.label ? props.label : props.name || 'Add'),
            valid : props && props.valid ? props.valid : false,
            name : props && props.name ? props.name : 'inputText',
            value : props && props.value ? props.value : '',
            handleChange : props && props.handleChange,
            handleEnter:props && props.handleEnter?props.handleEnter : null,
            placeholder: i18n.t(props && props.placeholder ? props.placeholder : `Enter ${props.label || props.name}`),
            rows : props && props.mapProps && props.mapProps.rows ? props.mapProps.rows : 5,
            options : props && props.mapProps && props.mapProps.options ? props.mapProps.options : props && props.options ? props.options : []
        }
        setMapInput(htmlProps);
    },[props]);

    const switchInput=()=>{
            const {type}=mapInput
            switch (type) {
                case  'text':
                    return (<TextInput {...mapInput}/>)
                
                case  'number':
                return (<NumberInput {...mapInput}/>)
            
            case  'date':
                return (<DateInput {...mapInput}/>)
            
                case 'checkbox':
                    return (<CheckInput {...mapInput}/>)
            
                case 'select':
                     return (<SelectInput {...mapInput}/>)
            
                 case 'textarea':
                    return (<TextAreaInput {...mapInput}/>)
            
                 case 'jsonObj':
                 return (<JsonInput {...props}/>)
            
                 case 'jsonList':
                 return (<JsonInput {...props}/>)
            
                 case 'jsonDict':
                 return (<JsonInput {...props}/>)
            
                default:
                 return (<TextInput {...mapInput}/>)
            
            }


    }
    return switchInput()
};



const TextInput = (props) => {
    const handleChange = (event)=>{
        props.handleChange(event.target.value,props.name)
    }
    const handleEnter=(event)=>{
        if(event.key==='Enter')
        props.handleEnter()
    }
    return (
        <Input
            type={props.type || 'text'}
            valid={props.valid || false}
            name={props.name}
            value={props.value || ''}
            onChange={handleChange.bind(this)}
            placeholder={props.label || props.placeholder}
            onKeyDown={handleEnter.bind(this)}
        />

    );
};

const NumberInput = (props) => {
    const handleChange = (event)=>{
        props.handleChange(event.target.value,props.name)
    }
    const handleEnter=(event)=>{
        if(event.key==='Enter')
        props.handleEnter()
    }
    return (
        <Input
            type={props.type || 'number'}
            valid={props.valid || false}
            name={props.name}
            min={1}
            value={props.value || ''}
            onChange={handleChange.bind(this)}
            placeholder={props.label || props.placeholder}
            onKeyDown={handleEnter.bind(this)}
        />

    );
};

const DateInput = (props) => {
    const handleChange = (event)=>{
        props.handleChange(event.target.value,props.name)
    }
    const handleEnter=(event)=>{
        if(event.key==='Enter')
        props.handleEnter()
    }
    return (
        <Input
            type={props.type || 'date'}
            valid={props.valid || false}
            name={props.name}
            min={1}
            value={props.value || ''}
            onChange={handleChange.bind(this)}
            placeholder={props.label || props.placeholder}
            onKeyDown={handleEnter.bind(this)}
        />

    );
};

const TextAreaInput = (props) => {
    const handleChange = (event)=>{
        props.handleChange(event.target.value,props.name)
    
    }
    return (
        <Input
            type={props.type || "textarea"}
            rows={props.rows || 5}
            valid={props.valid || false}
            name={props.name}
            value={props.value || ''}
            onChange={handleChange.bind(this)}
            placeholder={props.placeholder || props.label}
        />

    );
};


const CheckInput = (props) => {
    const handleChange = (event)=>{
    props.handleChange(event.target.checked,props.name,props)

}
    return (
        <FormGroup check inline>
            <Label>
        <Input
            type={props.type || "checkbox"}
            name={props.name}
            className="form-control jsoncheck"
            checked={props.value && props.value!='' ? props.value : false}
            onChange={handleChange.bind(this)}
        />
        </Label>
        </FormGroup>
    );
};



const SelectInput = (props) => {
    const [options,setOptions]=useState([]);
    
    useEffect(()=>{
            props && props.options && setOptions(props.options)
    },[props])

    const handleChange=(e)=>{
        props.handleChange(e.target.value,props.name)
    }
    return (
        <Input
            type={props.type || "select"}
            valid={props.valid || false}
            name={props.name}
            value ={props.value || ''}
            onChange={handleChange}
        >
        <option value="">{i18n.t('--select--')}</option>
       {options && options.map((item,index)=>{
           return (
            <option key={index} value={item.value || item.name || item.label}>{i18n.t(item.label || item.valueLabel)}</option>
           )
       })}
        </Input>

    );
};


const CategorySelectInput = (props) => {
    const [options,setOptions]=useState([]);
    const [placeholder,setPlaceholder]=useState('')
    useEffect(()=>{
            props && props.options && setOptions(props.options)
            props && props.options && props.options.length>0 && props.options[0].levelLabel && props.options[0].levelLabel.length>0 && setPlaceholder(props.options[0].levelLabel)
    },[props])

    const handleChange=(e)=>{
        props.handleChange(e.target.value,props)
    }
    return (
        <Input
            type={props.type || "select"}
            valid={props.valid || false}
            name={props.name}
            value ={props.value || ''}
            onChange={handleChange}
        >
        <option value="">--{i18n.t('Select')}{` ${placeholder || ''}`}--</option>
       {options && options.map((item,index)=>{
           return (
            <option key={index} value={item.value || item.name || item.label}>{i18n.t(item.label || item.valueLabel)}</option>
           )
       })}
        </Input>

    );
};


const DropDownInput = (props) => {
    const [options,setOptions]=useState([]);
    const [value,setValue]=useState('');
    useEffect(()=>{
            props && setOptions(props.options)
    },[props])

    const handleChange=(e)=>{
setValue(e.target.value)
let selectOptions=Array.isArray(options) && options.length>0 && options.filter(x=>x.name===e.target.value)
props.handleChange(selectOptions[0])
    }
    return (
        <Input
            type={props.type || "select"}
            name={props.name}
            value ={props.value || value || ''}
            onChange={handleChange}
        >
        <option value="">{i18n.t('--select--')}</option>
       {options && options.map((item,index)=>{
           return (
            <option key={index} value={item.value || item.name || item.label}>{i18n.t(item.label)}</option>
           )
       })}
        </Input>

    );
};


export {
    TextInput,
    SelectInput,
    CheckInput,
    TextAreaInput,
    MapInput,
    DropDownInput,
    CategorySelectInput
};

