import { ReportConstants } from '..';

/**
 * 
 * Convert this to a base class 
 */

// export class BaseReducer {

export const reports = (state = {}, action) => {
    // console.log('%%%%%%%%STATE IS ',state);
    // console.log('************* ACTIONS',action)
    // let type = action.type.slice(0,action.type.length- this.resourceName.length -1) 
    // get all reports generated list from the past for the chosen report type
    // get the metadata of all report types
    // get the data for the current report ( execute api)
    // get the scheduled records for the report
    // save a report on server..
    // email a report
    // get a past report
    // 
    switch (action.type) {
        // case (ReportConstants.FILTEREDDATA_SUCCESS + '_' + action.resourceName):
        //     return {
        //         ...state,
        //         filteredData: action.data && action.data.filteredData,
        //         searchText: action.data && action.data.searchText,
        //         entityName: action.resourceName
        //     }
        case (ReportConstants.GETALLMETA_REQUEST):

            return {
                ...state,
                loading: true,
            }
        case (ReportConstants.GETALLMETA_SUCCESS):
            return {
                ...state,
                loading: false,
                reportsMeta: action.item,
                searchParams: action.data && action.data.filter,
            }
        case (ReportConstants.GETALLMETA_FAILURE):
            return {
                ...state,
                loading: false,
                reportsMeta: []
            }
        case (ReportConstants.GETPASTREPORT_REQUEST + '_' + action.resourceName):

            return {
                ...state,
                loading: true,
                reportType: action.resourceName
            }
        case (ReportConstants.GETPASTREPORT_SUCCESS + '_' + action.resourceName):
            return {
                ...state,
                loading: false,
                pastReportData: action.item,
                searchParams: action.data && action.data.filter,
                reportType: action.resourceName
            }
        case (ReportConstants.GETPASTREPORT_FAILURE + '_' + action.resourceName):
            return {
                ...state,
                loading: false,
                pastReportData: null,
                reportType: action.resourceName
            }

        case (ReportConstants.GETPASTLIST_REQUEST + '_' + action.resourceName):

            return {
                ...state,
                loading: true,
                reportType: action.resourceName
            }
        case (ReportConstants.GETPASTLIST_SUCCESS + '_' + action.resourceName):
            return {
                ...state,
                loading: false,
                pastReports: action.item,
                searchParams: action.data && action.data.filter,
                reportType: action.resourceName
            }
        case (ReportConstants.GETPASTLIST_FAILURE + '_' + action.resourceName):
            return {
                ...state,
                loading: false,
                pastReports: [],
                reportType: action.resourceName
            }
        case (ReportConstants.GETSCHEDULESLIST_REQUEST + '_' + action.resourceName):

            return {
                ...state,
                loading: true,
                reportType: action.resourceName
            }
        case (ReportConstants.GETSCHEDULESLIST_SUCCESS + '_' + action.resourceName):
            return {
                ...state,
                loading: false,
                schedules: action.item,
                searchParams: action.data && action.data.filter,
                reportType: action.resourceName
            }
        case (ReportConstants.GETSCHEDULESLIST_FAILURE + '_' + action.resourceName):
            return {
                ...state,
                loading: false,
                pastReports: [],
                reportType: action.resourceName
            }
        case (ReportConstants.ADDSCHEDULE_REQUEST + '_' + action.resourceName):

            return {
                ...state,
                loading: true,
                reportType: action.resourceName
            }
        case (ReportConstants.ADDSCHEDULE_SUCCESS + '_' + action.resourceName):
            return {
                ...state,
                loading: false,
                schedules: [...state.schedules, action.item],
                searchParams: action.data && action.data.filter,
                reportType: action.resourceName
            }
        case (ReportConstants.ADDSCHEDULE_FAILURE + '_' + action.resourceName):
            return {
                ...state,
                loading: false,
                reportType: action.resourceName
            }
        case (ReportConstants.UPDATESCHEDULE_REQUEST + '_' + action.resourceName):

            return {
                ...state,
                loading: true,
                reportType: action.resourceName
            }
        case (ReportConstants.UPDATESCHEDULE_SUCCESS + '_' + action.resourceName):
            return {
                ...state,
                loading: false,

                searchParams: action.data && action.data.filter,
                reportType: action.resourceName
            }
        case (ReportConstants.UPDATESCHEDULE_FAILURE + '_' + action.resourceName):
            return {
                ...state,
                loading: false,
                reportType: action.resourceName
            }

        case (ReportConstants.SAVEEMAIL_REQUEST + '_' + action.resourceName):
            return {
                ...state,
                loading: true,
                reportType: action.resourceName
            }
        case (ReportConstants.SAVEEMAIL_SUCCESS + '_' + action.resourceName):
            let pastReports = [...state.pastReports]
            if ((action.data && action.data.type === 'SAVE') || action.data.type === 'SAVE_EMAIL') {
                pastReports.push(action.item)
            }
            return {
                ...state,
                loading: false,
                pastReports,
                reportType: action.resourceName
            }
        case (ReportConstants.SAVEEMAIL_FAILURE + '_' + action.resourceName):
            return {
                ...state,
                loading: false,
                reportType: action.resourceName
            }
        case (ReportConstants.DELETE_REQUEST + '_' + action.resourceName):
            return {
                ...state,
                loading: true,
                reportType: action.resourceName
            }
        case (ReportConstants.DELETE_SUCCESS + '_' + action.resourceName):
            if (action.item) {
                return {
                    ...state,
                    pastReports:
                        state.pastReports.filter(item => {
                            return item.id !== action.item[1][0].id
                        })
                    ,
                    loading: false,
                    reportType: action.resourceName
                }
            }
            break;

        case (ReportConstants.EXECUTEAPI_REQUEST + '_' + action.resourceName):
            return {
                ...state,
                loading: true,
                reportType: action.resourceName
            }
        case (ReportConstants.EXECUTEAPI_SUCCESS + '_' + action.resourceName):
            let returnObj =
            {
                ...state,
                loading: false,
                reportType: action.resourceName
            }
            if (action.targetProp) returnObj[action.targetProp] = action.item
            else returnObj.data = action.item
            returnObj.inputs = action.data && action.data.inputs
            return returnObj

        case (ReportConstants.EXECUTEAPI_FAILURE + '_' + action.resourceName):
            return {
                ...state,
                loading: false,
                reportType: action.resourceName
            }

        case (ReportConstants.LOADER_FALSE + '_' + action.resourceName):
            // if(action.item) {
            return {
                ...state,
                loading: false,
                reportType: action.resourceName
            }
        //} 




        default:
            return state
    }
}
// }
