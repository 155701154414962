import React from 'react'
import { withTranslation } from 'react-i18next'
import DropdownList from 'react-widgets/lib/DropdownList'
import Multiselect from 'react-widgets/lib/Multiselect'
import { Badge, Input, Label } from 'reactstrap'
import { session } from '../../../config'
import { identityServices } from '../../common'
import './style.css'
import Creatable  from 'react-select/creatable';
class Attributes extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			userAttributes: [],
			selectedAttribute: null,
			selectedValues: null,
			attributes: props.attributes || {},
			entityValues: {
				attributes: {},
			},
		}
		this.handleAttrChangeSelect = this.handleAttrChangeSelect.bind(this)
		this.handleAddAttrubutes = this.handleAddAttrubutes.bind(this)
		this.handleAttrValueChange = this.handleAttrValueChange.bind(this)
		this.handleDeleteAttributes = this.handleDeleteAttributes.bind(this)
	}
	componentWillMount() {
		const requestOptions = {
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			url: `${
				this.props.apiUrl || (this.props.tenant && this.props.tenant.apiUrl) || session.get('apiUrl') || sessionStorage.getItem('apiUrl') || localStorage.getItem('apiUrl')
			}/UserAttribute`,
		}
		return identityServices.identityApi
			.request(requestOptions)
			.then(response => {
				if (response.status != 200) {
					return Promise.reject(response)
				}
				let items = response.data
				let attrReq =
					items &&
					Array.isArray(items) &&
					items.filter(val => val.status == 'Active' && val.validationRule && val.validationRule.required && val.validationRule.required.toLowerCase() === 'yes')
					let attributes = []
					items && items.forEach(i => i.status == 'Active' && i.validationRule.required == 'Yes' && attributes.push(i.label))
				if (attrReq && Array.isArray(attrReq) && attrReq.length > 0) {
					this.props.handleAddDeleteAttrubutes({ attributes: {}, attrReq: true, userAttributes: attributes })
				} else {
					this.props.handleAddDeleteAttrubutes({ attributes: {}, attrReq: false, userAttributes: attributes })
				}
				this.setState({
					userAttributes: items && items.filter(i => i.status == 'Active'),
				})
			})
			.catch(err => {
				return Promise.reject(`Error in api call ${(err.response && err.response.data && err.response.data.message) || err.message || err}`)
			})
	}
	handleAttrChangeSelect(event) {
		const { userAttributes } = this.state
		if (event.value) {
			let selectedValue = event.value
			let item = userAttributes && userAttributes.find(o => o.key === selectedValue)
			this.setState({
				selectedAttribute: item,
				selectedValues: null,
			})
		}
	}
	handleAttrValueChange(event) {
		let value
		if (event && event.target && event.target.value) {
			value = event.target.value
		} else if (event && event.pop) {
			value = event.map(i => i.value)
		} else if (event && typeof event === 'object') {
			value = event.value
		} 

		if (value) {
			this.setState({
				selectedValues: value,
			})
		}
	}
	handleAddAttrubutes() {
		const { selectedAttribute, selectedValues, attributes, entityValues } = this.state
		if (!selectedValues) return true
		let key = selectedAttribute.key
		let value = selectedValues
		let updatedAttr = Object.assign({}, attributes, { [key]: value })
		this.setState({
			attributes: updatedAttr,
			selectedAttribute: null,
			selectedValues: null,
		})
		this.props.handleAddDeleteAttrubutes({ attributes: updatedAttr })
	}
	handleDeleteAttributes(key) {
		const { attributes } = this.state
		let filterItems = attributes
		if (filterItems[key]) delete filterItems[key]
		this.setState({
			attributes: filterItems,
		})
		this.props.handleAddDeleteAttrubutes({ attributes: filterItems })
	}
	componentWillReceiveProps(props) {
		let attr= props?.attributes
		if(Array.isArray(attr.team) && !attr?.team?.includes('global'))
			attr.team.push('global')
		this.setState({attributes: attr})
	}
	render() {
		const { t } = this.props
		const { userAttributes, selectedAttribute, selectedValues, attributes, entityValues } = this.state
		let htmlProps = {
			data:
				userAttributes &&
				userAttributes.map(i => {
					return { label: i.label, value: i.key }
				}),
			valueField: 'value',
			textField: 'label',
			value: (selectedAttribute && selectedAttribute.key) || null,
			containerClassName: 'asc-multiselect-dropdownlist',
			onChange: this.handleAttrChangeSelect,
			placeholder: t('---Select Attributes---'),
			readOnly: false,
			disabled: false,
			busy: false,
			messages: {
				moveBack: t('Navigate back'),
				moveForward: t('Navigate forward'),
				dateButton: t('Select date'),
				timeButton: t('Select time'),
				openCombobox: t('open combobox'),
				openDropdown: t('open dropdown'),
				emptyList: t('There are no items in this list'),
				emptyFilter: t('The filter returned no results'),
			},
		}
		let htmlProps1 = {
			data:
				selectedAttribute &&
				selectedAttribute.valueLabels ?
				selectedAttribute.valueLabels.map(i => {
					return { label: i, value: i }
				}): [],
			valueField: 'value',
			value:selectedValues,
			textField: 'label',
			containerClassName: 'asc-multiselect-dropdownlist',
			onChange: this.handleAttrValueChange,
			placeholder: t('Attribute Values'),
			readOnly: false,
			disabled: false,
			busy: false,
			messages: {
				moveBack: t('Navigate back'),
				moveForward: t('Navigate forward'),
				dateButton: t('Select date'),
				timeButton: t('Select time'),
				openCombobox: t('open combobox'),
				openDropdown: t('open dropdown'),
				emptyList: t('There are no items in this list'),
				emptyFilter: t('The filter returned no results'),
			},
		}
		let htmlProps2 = {
			options: selectedAttribute && selectedAttribute.valueLabels
			  ? selectedAttribute.valueLabels.map(i => ({ label: i, value: i }))
			  : [],
			onChange: this.handleAttrValueChange,
			placeholder: t('Attribute Values'),
			isDisabled: false,
			isLoading: false,
			noOptionsMessage: () => t('There are no items in this list'),
			isMulti: selectedAttribute && selectedAttribute.isMultivalue === "Yes",
			closeMenuOnSelect: selectedAttribute && selectedAttribute.isMultivalue !== "Yes"
		  };
		return (
			<div className="col-sm-8 section-frame">
				<div className={'custom-form-grp row clearfix'}>
					<div className="col-sm-3">
						<Label className="col-form-label attribute-label">{`${t('User Attributes')} *`}</Label>
					</div>
					<div data-testid="something" style={{maxWidth: "73.7%" ,minWidth:'inherit', marginLeft: "7.1px"}} className={`col-sm-9 attribute-select`}>
						<DropdownList {...htmlProps} />
					</div>
				</div>
				<div className={'custom-form-grp row clearfix'}>
					<div className="col-sm-3"></div>
					{selectedAttribute &&
						(selectedAttribute.isMultivalue == 'Yes' || selectedAttribute.isMultivalue == 'No') &&
						(!selectedAttribute.validationRule || selectedAttribute.validationRule.field == 'Predefined List') && (
							<div className={`col-sm-8 attribute-select`}>{selectedAttribute.isMultivalue == 'Yes' ? <Multiselect {...htmlProps1} /> : <DropdownList {...htmlProps1} />}</div>
						)}
					{selectedAttribute && selectedAttribute.validationRule && (selectedAttribute.validationRule.field == 'Free Text' || selectedAttribute.validationRule.field == 'number') && (
						<div className={`col-sm-8 attribute-text`}>
							<Input type={selectedAttribute.validationRule.field == 'number' ? 'number' : 'text'} placeholder={t('User Attribute Value')} onChange={this.handleAttrValueChange} />
						</div>
					)}
					{selectedAttribute && selectedAttribute.validationRule && ( selectedAttribute.validationRule.field == 'Unique Values') && (
						<div className={`col-sm-8 attribute-select`}>
							<Creatable {...htmlProps2} />
						</div>
					)}
					{selectedAttribute && (
						<div className="col-sm-1">
							<span className="for-add-field btn-add-attr" onClick={this.handleAddAttrubutes}>
								<i className="fa fa-plus-square-o" aria-hidden="true"></i>
							</span>
						</div>
					)}
				</div>
				<div className="row">
					<div className="col-sm-3"></div>
					<div className="col-sm-9">
						{Object.keys(attributes).map((item, i) => {
							let key = item
							let value = attributes[item] && attributes[item].pop ? attributes[item].join(', ') : attributes[item]
							return (
								<Badge className="attr-badge" key={key + i} color="primary">
									{key}: {value}
									<i className="fa fa-times" aria-hidden="true" onClick={this.handleDeleteAttributes.bind(this, item)}></i>
								</Badge>
							)
						})}
					</div>
				</div>
			</div>
		)
	}
}
export default withTranslation()(Attributes)
