'use strict'
import { EntityActions } from '../common/base/entity'
import { rootDomain } from '../../config'
// import {EntityComponent} from '../entity'

export class TenantAction extends EntityActions {
    executeFollowUp({actionName, apiMeta, tenantId }){
        switch (actionName) {
            case 'followUp':
                let apiMetaUrl = {path: apiMeta.path}
                if(apiMeta.path && apiMeta.path.includes('tenantId')){
                    apiMetaUrl = {path: apiMeta.path.replace('tenantId', tenantId)};
                }
                apiMeta = Object.assign({}, apiMeta, apiMetaUrl);            
                return apiMeta
            default:
                return apiMeta
        }
    }

    preActionsHooks({actionName, item }){
        switch (actionName) {
            case 'update':
            case 'registerJames':
                let payload={...item};
                payload.userAuthReqd = item.userAuthReqd === "true" ? true : false;
                item = payload;
                return item;
            default:
                return item
        }
    }

    postActionsHooks({actionName, item }){
        switch (actionName) {
            case 'getOne':
                let payload={...item};
                payload.userAuthReqd = item.userAuthReqd ? "true" : "false";
                item = payload;
                return item;
            default:
                return item
        }
    }
}
