/**
 * RU on all settings including whitelisting
 * This is in common as all apps would need it
 * Setting Key value pairs come from db of the app.
 */
import { Entity } from '../common/base/entity/entity'
import { methodSchemas, entitySchema, collectionSchemas } from './intentBot.schema'
import IntentBotDetail from './component/intent.bot.detail'
import { ContentAction } from "./ContentAction";

/**
 * Instructions: 
 * 1. For simple usage steps:
 * a. fieldsMeta, actionsMeta, screens, sectionsMeta definition and creation of BaseEntity obj with these inputs
 * 2. For simple overrides of baseEntity 
 * a. create an extended class of baseEntity and override its methods...
 * 3. For more customization
 * a. create a derived Service, derived actions and use them in derived BasedEntity class.
 * b. for component customization pass those components to the constuctor of derived BaseEntity class. SearchList component is the root component.
 *
 * 
 */


class IntentBotEntity extends Entity {
    // override the following if needed.
    // derived actionObj
    // derived Service obj
    // getActionFns if you want to suppress the baseActionobj methods
    constructor(options) {
        super(options);
    }
}

const fieldsMeta = {

    // botId: {
    //     label: 'Bot Id',
    //     min: 3,
    //     max: 100,
    //     required: true,
    //     type: 'text',
    //     name: 'botId',
    //     value: 'emma',
    //    // width: '10%',
    //     readOnly: true,
    // },
    intentLabel: {
        label: 'Intent Label',
        min: 3,
        max: 100,
        required: false,
        type: 'text',
        name: 'intentLabel',
        // width: '10%',
        readOnly: false,
    },
    issueType: {
        label: 'Issue Type',
        min: 3,
        max: 100,
        required: true,
        type: 'text',
        name: 'issueType',
        // width: '10%',
        readOnly: false,
    },
    skill: {
        label: 'Skill',
        min: 3,
        max: 100,
        required: false,
        type: 'text',
        name: 'skill',
        readOnly: false,
    },
    subjectArea: {
        label: 'Subject Area',
        min: 3,
        max: 100,
        required: true,
        type: 'text',
        name: 'subjectArea',
        readOnly: false,
    },
    intentName: {
        label: 'Intent Name',
        min: 3,
        max: 100,
        required: true,
        type: 'text',
        name: 'intentName',
        readOnly: false,
    },
    conditionsName: {
        label: 'Conditions Name',
        min: 3,
        max: 100,
        required: true,
        type: 'text',
        name: 'conditionsName',
        readOnly: false,
    },
    conditions: {
        label: 'Conditions',
        min: 3,
        max: 100,
        required: true,
        type: 'text',
        name: 'conditions',
        readOnly: false,
    },
    assignmentGroup: {
        label: 'Assignment Group',
        min: 3,
        max: 100,
        required: true,
        type: 'text',
        name: 'assignmentGroup',
        readOnly: false,
    },
    ticketCategory: {
        label: 'Ticket Category',
        min: 3,
        max: 100,
        required: true,
        type: 'text',
        name: 'ticketCategory',
        readOnly: false,
    },
    ticketSubCategory: {
        label: 'Ticket SubCategory',
        min: 3,
        max: 100,
        required: true,
        type: 'text',
        name: 'ticketSubCategory',
        readOnly: false,
    },
    classificationItem: {
        label: 'Classification Item',
        required: false,
        type: 'text',
        name: 'classificationItem',
        readOnly: false,
    },
    resolutionNotes: {
        label: 'Resolution Notes',
        required: false,
        type: 'text',
        name: 'resolutionNotes',
        readOnly: false,
    },
    ticketObj: {
        label: 'Ticket Obj',
        required: false,
        type: 'text',
        name: 'ticketObj',
        readOnly: false,
    },
    status: {
        label: "Status",
        name: 'status',
        options: [{ label: 'Published', value: 'Published' }, { label: 'Deleted', value: 'Deleted' }],
        type: 'select',
        required: true,
        default: 'Published',
        width: '20%'
    }
}

const listsMeta = {
    myIntent: {
        name: 'myIntent',
        api: {
            path: '/IntentBot',
            method: 'GET',
            params: undefined,
            body: undefined,
            response: {
                ref: 'data', // ref key .. could be defaulted to body. Prop that holds the 
                map: { // return columns as. Key is api response object keys. value is what they are returned as
                    caseId: 'botId',
                    issueType: 'issueType'
                }
            }
        }
    }
}

const actionsMeta = {
    bulkPublish: {
        label: 'Bulk Publish',
        name: 'bulkPublish',
        action: 'publishItems',
        classes: 'fa fa-pencil fa-lg ',
        type: 'button'
    },
    editItem: {
        type: 'button',
        label: 'Update',
        action: 'editItem',
        name: 'editItem'
    },
    editMap: {
        label: 'Edit Map',
        name: 'editMap',
        action: 'showMap',
        condition: 'typeof value === "object"',

    },
    save: {
        label: 'Save',
        name: 'save',
        action: 'save'
    },
    cancel: {
        type: 'button',
        label: 'Cancel',
        action: 'cancelEdit',
        name: 'cancel'
    },
    update: {
        label: 'Edit',
        name: 'update',
        action: 'editItem',
        classes: 'fa fa-pencil fa-lg ',
        type: 'span'
    },
    list: {
        label: 'List All',
        name: 'list',
        action: 'load'
    },
    search: {
        label: 'Search',
        name: 'search',
        action: 'search'
    },
    view: {
        label: 'View',
        name: 'view',
        action: 'getItem',
        classes: 'fa fa-eye fa-lg',
        type: 'span'
    },

    create: {
        label: 'Create',
        name: 'create',
        type: 'button',
        action: 'addNewItem',
    },
    createItem: {
        label: 'Create',
        action: 'addNewItem',
        name: 'create',
        type: 'button'
    },
    deleteItem: {
        label: 'Delete',
        action: 'deleteItem',
        name: 'delete',
        type: 'button'
    }

}
const sectionsMeta = {
    general: {
        items: [{ name: 'skill', type: 'field' }, { name: 'subjectArea', type: 'field' }, { name: 'intentName', type: 'field' }]
    },
    basic: {
        title: 'Intent Bot Details',
        items: [{ name: 'issueType', type: 'field' }, { name: 'subjectArea', type: 'field' }, 
        { name: 'intentName', type: 'field' }, { name: 'conditionsName', type: 'field' },
        { name: 'conditions', type: 'field' }, { name: 'priority', type: 'field' },
        { name: 'ticketObj', type: 'field' }, { name: 'ticketCategory', type: 'field' }, 
        { name: 'ticketSubCategory', type: 'field' }, { name: 'assignmentGroup', type: 'field' }, 
        { name: 'classificationItem', type: 'field' }, { name: 'resolutionNotes', type: 'field' },
        { name: 'status', type: 'field' }],
        classes: "ticket-form"
    },
    headerComponent: {
        component: 'DetailHeader',
    },
    intentBotForm: {
        cols: [7, 5],
        items: [{ name: 'basic', type: 'section', col: 1 },
        { name: 'editItem', type: 'action', col: 1 },
        { name: 'cancel', type: 'action', col: 1 }],
        classes: 'composite-inner-form'
    },


    intentBotCreateForm: {
        cols: [7, 5],
        items: [{ name: 'basic', type: 'section', col: 1 },
        { name: 'createItem', type: 'action', col: 1 }],
        classes: 'composite-inner-form'
    },
}


const tabsMeta = {

}

const screens = {
    view: {
        items: [{ name: 'headerComponent', type: 'section' }, { name: 'intentBotForm', type: 'section' }],
        classes: 'composite-form',
        title: 'Intent Bot Details'
    },
    create: {
        items: [{ name: 'headerComponent', type: 'section' }, { name: 'intentBotCreateForm', type: 'section' }]
    },
    edit: {
        items: [{ name: 'headerComponent', type: 'section' }, { name: 'intentBotForm', type: 'section' }]
    },
    list: {
        items: [{ name: 'issueType', type: 'field' },
        { name: 'subjectArea', type: 'field' }, { name: 'intentName', type: 'link' }, { name: 'conditionsName', type: 'field' }, { name: 'conditions', type: 'field' }, { name: 'ticketCategory', type: 'field' }, { name: 'ticketSubCategory', type: 'field' }, { name: 'assignmentGroup', type: 'field' },
        { name: 'ticketObj', type: 'field' }, { name: 'status', type: 'field' },
        { name: 'classificationItem', type: 'field' }, { name: 'resolutionNotes', type: 'field' },
        { name: 'intentLabel', type: 'field' }
        ]
    },
    search: {
        items: [{ name: 'botId', type: 'field' }, { name: 'intentName', type: 'field' }]
    }
}
// const components = { searchList: EntityComponent} // can overrides the components like this if needed
const IntentBot = new IntentBotEntity({ ActionClass: ContentAction, fieldsMeta, actionsMeta, sectionsMeta, tabsMeta, screens, name: 'IntentBot', title: 'Intent Bot', methodSchemas, collectionSchemas, entitySchema/*, bKeys: ['botId', 'tenantId', 'intentName', 'conditionsName']*/, idKey: "id", entityUrlPaths: { bulkPublish: 'intentBot/publish', update: "intentBot/update", default: "IntentBot" }, fetchDetailByApi: false, components: {Detail: IntentBotDetail} })

const IntentBotListSearchContainer = IntentBot.getContainer('ListSearch')
const IntentBotCreateContainer = IntentBot.getContainer('Create')
const IntentBotDetailContainer = IntentBot.getContainer('Detail')
export { IntentBotListSearchContainer, IntentBotCreateContainer, IntentBotDetailContainer }