import React, { Component,Suspense } from 'react';
import { Loader } from '../../..';
import PropTypes from 'prop-types';

const DocumentDownload=React.lazy(() => import('./DocumentDownload'));
export default class DocumentList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            allProps: this.props,
            docs: false,
            showImage:null,
        }


    }
    
    render() {
        const { docs } = this.props;
        
        return (
            <>
            
                {
                    docs && docs.length  > 0 && docs.pop &&
                    <div className="download-document">
                        <div>
                            {docs.pop  && docs.map((doc, index) => {
                                return (
                                    <Suspense  key={index} fallback={<Loader/>}>
                                   <DocumentDownload
                                   key={index}
                                   doc={doc.doc}
                                   name={doc.docName}
                                   type={doc.docType}
                                   />
                                   </Suspense>
                                )
                            })}
                        </div>
                       
                    </div>
                }
            </>
        )
    }
}

DocumentList.propTypes = {
    docs: PropTypes.arrayOf(PropTypes.shape({doc: PropTypes.object, docName: PropTypes.string, docType: PropTypes.string }))    
};
