import PropTypes from 'prop-types'
import React, { Component } from 'react'
// Locale
import { withTranslation } from 'react-i18next'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
// import { MapInput } from '../jsonInput/formInput'
import ExpendableDataGrid from './ExpendableDataGrid'
import './react-select.css'
//Locale
export class LookUp extends Component {
	constructor(props) {
		super(props)
		this.state = {
			modal: this.props.toggle || true,
			columnInfo: null,
			column: null,
			titleValue: '',
			lookUpMeta: null,
			data: [],
			meta: this.props.meta,
			fields: this.props.fields,
			importData: this.props.importData,
			entity: this.props.entity,
			lookUpItemDef: this.props.lookUpItemDef || this.props.itemDef,
			searchText: '',
			gridParentData: this.props.gridParentData,
			selectedIndexes: this.props.selectedIndexes,
			entityValues: (this.props.entity && this.props.entity.entityValues) || this.props.entity,
		}

		this.toggle = this.toggle.bind(this)
	}
	toggle() {
		this.setState(prevState => ({
			modal: !prevState.modal,
		}))
		this.props.itemCellLookUpClick()
	}
	componentWillMount() {
		const { meta, lookUpItemDef } = this.state
		const { lookUpMeta } = meta
		if (lookUpItemDef) {
			let itemDef = lookUpMeta[lookUpItemDef.name]
			this.getRefItems(itemDef)
		}
		this.setState({
			lookUpMeta: lookUpMeta,
		})
	}
	getRefItems(itemDef) {
		if (itemDef) {
			let filter = itemDef.api.filter || [{ name: 'status', value: 'Published' }]
			itemDef && this.props.getRefItems({ apiDef: itemDef.api, filter: filter, t: this.props.t })
		}
	}

	componentDidMount() {}

	componentWillReceiveProps(nextProps) {
		const { lookUpItemDef, lookUpMeta } = this.state
		if (nextProps.entity && nextProps.entity.refEntityList) {
			let itemDef = lookUpMeta[lookUpItemDef.name]
			this.setState({
				data: itemDef && itemDef.api && nextProps.entity.refEntityList[itemDef.api.entityName],
			})
		}
		if (nextProps.gridParentData) this.setState({ gridParentData: nextProps.gridParentData })
	}

	// componentWillUpdate(nextProps, nextState) {

	// }

	// componentDidUpdate(prevProps, prevState) {

	// }

	componentWillUnmount() {}
	handleChange = (value, name) => {
		this.setState({ titleValue: value })
	}
	handleSearchTextChange(e) {
		const { value } = e.target
		this.setState({
			searchText: value,
		})
	}

	searchLookUp() {
		const { searchText, lookUpMeta, lookUpItemDef } = this.state
		let itemDef = lookUpMeta[lookUpItemDef.name]
		if (!itemDef || !searchText || searchText.trim() === '') return true
		let searchFields = itemDef.searchFields
		let filters =
			searchFields &&
			searchFields.map(fieldName => {
				return { name: fieldName, value: searchText, cond: 'and', operator: 'like' }
			})
		let filter = itemDef.api.filter || [{ name: 'status', value: 'Published' }]
		if (filters) filter = [...filter, ...filters]
		itemDef && this.props.getRefItems({ apiDef: itemDef.api, filter: filter, t: this.props.t })
	}

	resetSearchLookUp() {
		const { lookUpMeta, lookUpItemDef } = this.state
		let itemDef = lookUpMeta[lookUpItemDef.name]
		this.getRefItems(itemDef)
		this.setState({
			searchText: '',
		})
	}

	handleAction({ actionDef, itemDef }) {
		const { data } = this.state
		let parentData = [...this.state.gridParentData]
		if (!data || data.length === 0 || !this.props.selectedIndexes || this.props.selectedIndexes.length === 0) return true
		let selectedItems = data.filter((item, index) => this.props.selectedIndexes.indexOf(index) !== -1)
		if (selectedItems && selectedItems.length > 0 && itemDef.fieldToCollect) {
			selectedItems = selectedItems.map(item => {
				return this.getObectWithFields(item, itemDef.fieldToCollect)
			})
			if (parentData && parentData.length > 0) {
				let updatedItems
				updatedItems = parentData.reduce((p, c) => {
					let childItem = c.value
					if (childItem) childItem[itemDef.name] = selectedItems
					let parentItem = p.value
					if (parentItem) {
						parentItem[c.key] =
							parentItem[c.key] &&
							parentItem[c.key].map((r, i) => {
								if (i === c.rowIndex) return childItem
								else return r
							})
					}
					return parentItem
				})

				if (updatedItems) {
					let entityValues = { ...this.state.entityValues }
					let parentNode = parentData[0]
					entityValues[parentNode.key] =
						entityValues[parentNode.key] &&
						entityValues[parentNode.key].map((p, index) => {
							if (index === parentNode.rowIndex) return updatedItems
							else return p
						})
					this.props.handleUpdateEntity(entityValues)
				}
			}
			this.toggle()
		}
	}

	getObectWithFields = function (obj, props) {
		if (!obj || !props) return
		var picked = {}
		props.forEach(function (prop) {
			picked[prop] = obj[prop]
		})
		return picked
	}

	renderLookItems(itemDef) {
		const { entity, data, meta, fields, importData, gridParentData } = this.state
		let columns =
			itemDef.gridColumns &&
			itemDef.gridColumns.map(c => {
				return { name: fields[c].name || c, label: fields[c].label || c, key: fields[c].name || c }
			})
		let list = data
		const preSelectedIndexes = []
		if (gridParentData) {
			let items =
				gridParentData &&
				gridParentData.reduce((p, c) => {
					if (c.key === itemDef.parentOf) return c.value
					return null
				}, gridParentData)
			let collectFields = itemDef && itemDef.fieldToCollect
			list &&
				list.forEach((o1, index) => {
					items &&
						items[itemDef.name] &&
						items[itemDef.name].forEach(o2 => {
							let count = 0
							collectFields.forEach(function (prop) {
								if (o1[prop] === o2[prop]) count += 1
							})
							if (count == collectFields.length) preSelectedIndexes.push(index)
						})
				})
		}
		return (
			<div className="col-md-12">
				<ExpendableDataGrid
					meta={meta}
					list={list}
					columns={columns}
					fields={fields}
					height={list && list.length > 0 ? list.length * 40 : null}
					importData={importData}
					entity={entity}
					itemDef={itemDef}
					itemCellLookUpClick={this.props.itemCellLookUpClick}
					gridParentData={this.state.gridParentData}
					preSelectedIndexes={preSelectedIndexes}
					handleSelectItems={this.props.handleSelectItems}
					handleUpdateEntity={this.props.handleUpdateEntity}
				/>
			</div>
		)
	}

	render() {
		const { meta, lookUpMeta, lookUpItemDef, searchText } = this.state
		const { t } = this.props
		if (!lookUpItemDef) return <></>
		let itemDef = lookUpMeta[lookUpItemDef.name]
		let actionMeta = meta && meta.actions
		let actions = itemDef && itemDef.actions
		return (
			<div>
				{itemDef && (
					<Modal
						id="LookUpModalId"
						size="lg"
						style={{ maxWidth: '1600px', width: '80%' }}
						isOpen={this.state.modal}
						toggle={this.toggle}
						className={this.props.className}>
						<ModalHeader toggle={this.toggle}>{t('Look Up')} </ModalHeader>
						<ModalBody>
							<div className="row">
								<div className="col-sm-6">
									<input
										type="text"
										name="searchText"
										placeholder={
											itemDef.searchFields && itemDef.searchFields.length > 0 ? `${t('Search like')} ${itemDef.searchFields.join(', ')}` : t(`Search`)
										}
										value={searchText || ''}
										onChange={this.handleSearchTextChange.bind(this)}></input>
								</div>
								<div className="col-sm-3">
									<button className="search-btn-new btn btn-xs" onClick={this.searchLookUp.bind(this)}>
										<i className="fa fa-search fa-lg mt-4"></i>
										{t('Search')}
									</button>
									<button className="btn btn-link" onClick={this.resetSearchLookUp.bind(this)}>
										<i className="fa fa-refresh  "></i>
									</button>
								</div>
								<div className="col-sm-3">
									{actions &&
										actionMeta &&
										actions.map((action, index) => {
											let actionDef = actionMeta[action.name]
											return (
												<Button onClick={this.handleAction.bind(this, { actionDef, itemDef })} key={index} className="dwnld pull-right" color="link">
													{t(actionDef.label)}
												</Button>
											)
										})}
								</div>
							</div>
							<div className="row">{lookUpMeta && this.renderLookItems(itemDef)}</div>
						</ModalBody>
						<ModalFooter>
							{/* <Button outline color="primary" onClick={this.toggle}>Add</Button>{' '} */}
							<Button outline color="secondary" onClick={this.toggle}>
								{t('Cancel')}
							</Button>
						</ModalFooter>
					</Modal>
				)}
			</div>
		)
	}
}

LookUp.propTypes = {
	toggle: PropTypes.bool,
	meta: PropTypes.object,
	toggleLookUp: PropTypes.func,
	lookupvalue: PropTypes.object,
	callApi: PropTypes.func,
}
export default withTranslation()(LookUp)
