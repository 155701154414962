import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { Col, FormGroup, Label, ListGroup, ListGroupItem, Row } from 'reactstrap'
import { CategorySelectInput } from './jsonInput/formInput'
// import PropTypes from 'prop-types';

class TagsComponent extends Component {
	constructor(props) {
		super(props)
		this.state = {
			fieldToCollect: [],
			tagsList: [],
			collectedKey: [],
			collectedValue: [],
			mappedValue: [],
			selectedTags: [],
			selectedKey: '',
			selectedValue: '',
			editCounter: false,
		}
	}

	componentWillMount() {
		const {
			meta: { tagsMeta },
			name,
		} = this.props
		this.setState({ tagsMeta, fieldToCollect: tagsMeta[name].fieldToCollect })
		let itemDef = tagsMeta[name].api
		itemDef && this.props.getRefItems({ apiDef: itemDef, filter: itemDef.filter, t: this.props.t })
	}

	componentDidMount() {}

	componentWillReceiveProps(nextProps) {
		const { fieldToCollect, editCounter } = this.state
		const { value } = nextProps
		const tagList =
			nextProps && nextProps.entity && nextProps.entity.refEntityList && nextProps.entity.refEntityList[nextProps.name]
				? nextProps.entity.refEntityList[nextProps.name]
				: []
		if (value && value.length > 0) {
			this.setState({ editCounter: true })
			let pickedFormValues = []
			!editCounter &&
				value.forEach((item, index) => {
					let pickedvalue = {}
					fieldToCollect &&
						fieldToCollect.length > 0 &&
						fieldToCollect.forEach((field, idx) => {
							pickedvalue[field] = item[field]
						})
					pickedFormValues.push(pickedvalue)
				})
			pickedFormValues && pickedFormValues.length > 0 && this.setState({ selectedTags: pickedFormValues })

			//this.setState({selectedTags:value})
		}

		this.setState({
			tagsList: tagList || [],
		})

		let pickedKeys = []
		let pickedValues = []

		if (tagList && tagList.length > 0) {
			tagList.forEach((item, index) => {
				let pickedKey = {}
				let pickedvalue = {}
				fieldToCollect &&
					fieldToCollect.length > 0 &&
					fieldToCollect.forEach((field, idx) => {
						pickedvalue[field] = item[field]
					})
				pickedKey = { label: item.key, value: item.key, key: item.key }
				pickedValues.push(pickedvalue)
				pickedKeys.push(pickedKey)
			})
			///            const {fieldToCollect}
		}
		if (pickedKeys && pickedKeys.length > 0) this.setState({ collectedKey: pickedKeys, collectedValue: pickedValues })
	}

	handleKeyChange(value, name) {
		const { collectedValue } = this.state
		if (collectedValue && collectedValue.length > 0) {
			let filterValue = collectedValue.filter(x => x.key === value)

			if (filterValue && filterValue.length > 0) this.setState({ selectedKey: value, mappedValue: filterValue })
		}
		//        this.setState({selectedKey:value})
	}

	handleValueChange(value, name) {
		const { selectedTags, collectedValue } = this.state
		if (collectedValue && collectedValue.length > 0) {
			let filterTag = []
			let filterValue = []
			let filterArray = []
			filterTag = selectedTags && selectedTags.pop && selectedTags.length > 0 && selectedTags.filter(x => x.value !== value)
			filterValue = collectedValue && collectedValue.length > 0 && collectedValue.filter(x => x.value === value)
			filterArray = filterTag && filterTag.length > 0 ? [...filterTag, ...filterValue] : [...filterValue]

			this.setState({ selectedValue: value, selectedTags: filterArray })

			if (value && value.length > 0) this.setState({ selectedKey: '', selectedValue: '' })

			filterArray && filterArray.length > 0 && this.props.onComponentChange(this.props.name, filterArray)
		}
	}

	removeItem(key) {
		const { selectedTags } = this.state
		let filerKey = selectedTags && selectedTags.pop && selectedTags.filter(x => x.key !== key)
		this.setState({ selectedTags: filerKey })
	}
	// shouldComponentUpdate(nextProps, nextState) {

	// }

	// componentWillUpdate(nextProps, nextState) {

	// }

	// componentDidUpdate(prevProps, prevState) {

	// }

	// componentWillUnmount() {

	// }

	render() {
		const {t} = this.props
		const { collectedKey, mappedValue, selectedTags, selectedKey, selectedValue } = this.state

		return (
			<div>
				<Row>
					<Col xs="6">
						<FormGroup>
							<Label>{t('Key')}</Label>
							<CategorySelectInput type="select" name="keyvalue" value={selectedKey} options={collectedKey} handleChange={this.handleKeyChange.bind(this)} />
						</FormGroup>
					</Col>

					<Col xs="6">
						<FormGroup>
							<Label>{t('Value')}</Label>
							<CategorySelectInput type="select" name="keyvalue" value={selectedValue} options={mappedValue} handleChange={this.handleValueChange.bind(this)} />
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col xs="12">
						<ListGroup>
							{selectedTags &&
								selectedTags.length > 0 &&
								selectedTags.map((item, idx) => {
									return (
										<ListGroupItem key={idx}>
											{item.key} : {item.value || item.valueLabel || ''}{' '}
											<span style={{ cursor: 'pointer' }} onClick={this.removeItem.bind(this, item.key)} className="text-danger">
												x
											</span>
										</ListGroupItem>
									)
								})}
						</ListGroup>
					</Col>
				</Row>
			</div>
		)
	}
}

TagsComponent.propTypes = {}

export default withTranslation()(TagsComponent)
