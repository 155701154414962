import Joi from 'joi-browser'
// import { apiUrl } from '../../config';
import { i18n, identityServices } from '../common'
import { EntityService } from '../common/base/entity/services/entity.services'
const format = require('string-format')
var subdomain = window.location.host.split('.')[1] ? window.location.host.split('.')[0] : false
// let { store, persistor } = configureStore
class UserEntityService extends EntityService {
	constructor(options) {
		super(options)
		this.apiUrl = this.apiUrl || sessionStorage.getItem('apiUrl')
	}

	getAll({ tenantId, filter, orderBy, skip, limit, top, baseUrl, select, searchText }) {
		//    const { store: {getState, dispatch }} = configureStore()
		let that = this
		let url = `${this.apiUrl}`
		if (baseUrl) {
			url += `${baseUrl}`
		}
		let roles = filter && filter.find(f => f.name === 'roles')
		let firstName = filter && filter.find(f => f.name === 'firstName')
		let lastName = filter && filter.find(f => f.name === 'lastName')
		let email = filter && filter.find(f => f.name === 'email')
		let username = filter && filter.find(f => f.name === 'username')
		let enabled = filter && filter.find(f => f.name === 'enabled1')
		let queryString = `?first=${skip}&max=${top}`
		if (searchText && searchText != '') {
			queryString = `${queryString}&searchText=${searchText}`
		}
		if (roles) queryString = `${queryString}&roles=${roles.operator}~${roles.value}`
		if (firstName) queryString = `${queryString}&firstName=${firstName.operator}~${firstName.value}`
		if (lastName) queryString = `${queryString}&lastName=${lastName.operator}~${lastName.value}`
		if (email) queryString = `${queryString}&email=${email.operator}~${email.value}`
		if (username) queryString = `${queryString}&username=${username.operator}~${username.value}`
		if (enabled) queryString = `${queryString}&enabled=${enabled.value.toLowerCase()}`
		// let query = this.prepareOdataQueryStr_({ filter, orderBy, limit, skip, top, select })
		let requestOptions = {
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			url: `${url}/${this.entityUrlPaths.getAll}${queryString}`,
		}
		let val
		return identityServices.identityApi
			.request(requestOptions)
			.then(response => {
				if (response.status !== 200) {
					return Promise.reject(response)
					// return res
				}
				if (response && response.data == null) return []
				return response.data
			})
			.catch(err => {
				return Promise.reject(`Error in api call ${(err.response && err.response.data && err.response.data.message) || err.message || err}`)
			})
	}
	getOne({ tenantId, filter, orderBy, skip, limit, top, baseUrl, select }) {
		let that = this
		let query = this.prepareOdataQueryStr_({ filter, orderBy, limit, skip, top, select })
		let url = `${this.apiUrl}`
		if (baseUrl) {
			url += `${baseUrl}`
		}
		let requestOptions = {
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			url: `${url}/${this.entityUrlPaths.getOne}?$filter=uid eq '${filter.uid}'&$select=id,userId,username,email,firstName,lastName,attributes,roles,enabled`,
		}
		let val
		return identityServices.identityApi
			.request(requestOptions)
			.then(response => {
				if (response.status !== 200) {
					return Promise.reject(response)
					// return res
				}
				if (response && response.data == null) return []
				let data
				if (response.data.rows) {
					data = response.data.rows[0]
				} else {
					data = Array.isArray(response.data) ? response.data[0] : response.data
				}
				if (data.roles) data.roles = data.roles && data.roles.sort()
				return data
			})
			.catch(err => {
				return Promise.reject(`Error in api call ${(err.response && err.response.data && err.response.data.message) || err.message || err}`)
			})
	}
	editRecord({ item, tenantId, editId }) {
		let that = this
		if(item.username==="adminuser" && item.roles &&  !item.roles.includes('ASC Admin'))
		item.roles.push("ASC Admin")

		const requestOptions = {
			method: 'PUT',
			headers: { 'Content-Type': 'application/json' },
			data: JSON.stringify(item),
			url: `${this.apiUrl}/${this.entityUrlPaths.update}/${editId ? editId : item.userId}?tenantId=${subdomain}`,
		}
		if(item.userAttributes===undefined) item.userAttributes=[]
	      const mandatorySelectedAttr=item.userAttributes.filter(d=>Object.keys(item.attributes).includes(d))
		if (item && item.attrReq && item.attributes && typeof item.attributes === 'object' && mandatorySelectedAttr.length == 0) {
			return Promise.reject(`User Attribute - ${item.userAttributes || ''} Required!`)
		} else if(item.userAttributes.length!==mandatorySelectedAttr.length){
			const addattribute=item.userAttributes.filter(attribute=>!mandatorySelectedAttr.includes(attribute))
			return Promise.reject(`User Attribute - ${addattribute || ''} Required!`)
		}else {
			delete item.attrReq
			delete item.userAttributes
		}
		if (that.methodSchemas && that.methodSchemas.editItem) {
			let val = Joi.validate(item, that.methodSchemas.editItem)
			if (val.error) {
				let errMsg = val.error.message.substring(val.error.message.lastIndexOf('[') + 1, val.error.message.lastIndexOf(']'))
				return Promise.reject(`Api response did not pass validation for Add items '${errMsg}'`)
			}
		}
		return identityServices.identityApi
			.request(requestOptions)
			.then(response => {
				if (response.status >= 300) {
					return Promise.reject(response)
				}
				if (that.methodSchemas && that.methodSchemas.editItem) {
					let val = Joi.validate(response.data[0], that.methodSchemas.editItem)
					if (!val.error) return response.data
					else {
						let errMsg = val.error.message.substring(val.error.message.lastIndexOf('[') + 1, val.error.message.lastIndexOf(']'))
						return Promise.reject(`Api response did not pass validation for Edit item '${errMsg}'`)
					}
				}
				return response.data
			})
			.catch(err => {
				return Promise.reject(`Error in api call ${(err.response && err.response.data && err.response.data.message) || err.message || err}`)
			})
	}
	addNew({ item, tenantId }) {
		let that = this
		if (item && item.email) {
			item.username = item.email
		}
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			data: item,
			url: `${this.apiUrl}/${this.entityUrlPaths.add}?tenantId=${subdomain}`,
		}
		const mandatorySelectedAttr=item?.userAttributes?.filter(d=>Object.keys(item.attributes).includes(d))
		if (item && item.attrReq && item.attributes && typeof item.attributes === 'object' && mandatorySelectedAttr.length==0) {
			return Promise.reject(`User Attribute - ${item.userAttributes || ''} Required!`)
		} else if(item?.userAttributes?.length!==mandatorySelectedAttr?.length){
			const addattribute=item.userAttributes.filter(attribute=>!mandatorySelectedAttr.includes(attribute))
			return Promise.reject(`User Attribute - ${addattribute || ''} Required!`)
		}else {
			delete item.attrReq
			delete item.userAttributes
		}
		if (that.methodSchemas && that.methodSchemas.addItem) {
			let val = Joi.validate(item, that.methodSchemas.addItem)
			if (val.error) {
				let errMsg = val.error.message.substring(val.error.message.lastIndexOf('[') + 1, val.error.message.lastIndexOf(']'))
				// return Promise.reject(`Request data did not pass validation for Add item '${errMsg}'`)
				return Promise.reject(val.error || `Request data did not pass validation for Add item '${errMsg}'` )
			}
		}
		return identityServices.identityApi
			.request(requestOptions)
			.then(response => {
				if (response.data && response.data.status == 409) {
					return Promise.reject({ message: 'User already registered, Please use another Username or Email' })
				} else if (response.status !== 201) {
					return Promise.reject(response)
				} else return {}
			})
			.catch(err => {
				return Promise.reject(i18n.t(`Error in api call ${(err.response && err.response.data && err.response.data.message) || err.message || err}`))
			})
	}
	resetPassword({ item, editId, path }) {
		let that = this
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			data: JSON.stringify(item),
			url: `${this.apiUrl}/${path}/required-actions/${editId}?tenantId=${subdomain}`,
		}
		return identityServices.identityApi
			.request(requestOptions)
			.then(response => {
				if (response.status >= 300) {
					return Promise.reject(response)
				}
				return response.data
			})
			.catch(err => {
				return Promise.reject(`Error in api call ${(err.response && err.response.data && err.response.data.message) || err.message || err}`)
			})
	}

	deleteUser({ item, id, path }) {
		let that = this
		const requestOptions = {
			method: 'DELETE',
			headers: { 'Content-Type': 'application/json' },
			url: `${this.apiUrl}/${path}/${id}?tenantId=${subdomain}`,
		}
		return identityServices.identityApi
			.request(requestOptions)
			.then(response => {
				if (response.status >= 300) {
					return Promise.reject(response)
				}
				return response.data
			})
			.catch(err => {
				return Promise.reject(`Error in api call ${(err.response && err.response.data && err.response.data.message) || err.message || err}`)
			})
	}
}
export { UserEntityService }

