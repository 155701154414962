import Joi from 'joi-browser'
import PropTypes from 'prop-types'
import React from 'react'
import { withTranslation } from 'react-i18next'
import * as schemas from '../schemas'
// import {Buttons} from './'
import { Action, CardLayout, ExpendableDataGrid, Field, WorkflowButton } from './'
// import './style.css';
// import { history } from '../../../../../redux/history'
// import { Loader } from '../../..';
import SectionDetail from './SectionDetail'

export class DetailPage extends React.Component {
	/**
	 * need the following props
	 *
	 * meta as defined in entity.schemas
	 * idKey: defined in meta
	 * itemId: from params
	 * entityValues: from entity.entityValues
	 *
	 * Able to handle for following...
	 *  Update to the entity
	 *   base properties' update or collection additions / deletions and update of child item
	 *  actions taken on the entity.
	 *    These are actions other than CUD actions.. mapped to specific api calls
	 *
	 * @param {*} props
	 */
	constructor(props) {
		super(props)
		this.state = {
			idKey: props.meta.idKey,
			bKeys: props.meta.bKeys,
			meta: props.meta,
			itemId: (props.meta.idKey || props.meta.bKeys) && (props.itemId || (props.params && props.params.itemId)),
			entityValues: (props.subEntity && props.subEntity.entityValues) || {}, // values that fill the fields
		}
		// this.handleAction.bind(this)
	}

	handleAction({ actionMeta, actionObj, actionPayload }) {
		if (!actionMeta) return
		const {
			meta: { idKey },
		} = this.props
		let actionFn = actionMeta.action
		let modelData = {}

		if (actionMeta.confirmAction && !actionMeta.callApiAction) {
			modelData.openModal = true
			modelData.confirmActionMessage = actionMeta.confirmActionMessage
			modelData.actionMeta = actionMeta
			modelData.actionObj = actionObj
			modelData.actionPayload = actionPayload
		} else {
			if (!this.props[actionFn]) console.error(`Action Fn ${actionFn}is not found.`)
			else
				this.props[actionFn]({
					entityValues: this.state.entityValues,
					id: this.state.idKey && this.state.entityValues[idKey],
					actionObj,
					actionPayload,
					actionMeta,
				})
		}
	}

	handleChange(name, value) {
		let entityValues = this.state.entityValues || this.props.entityValues || (this.props.entity && this.props.entity.entityValues)
		entityValues[name] = value
		this.setState({
			entityValues,
		})
	}

	storeFieldOptions(name, options) {
		let optionValues = this.state.optionValues || this.props.optionValues || (this.props.entity && this.props.entity.optionValues)
		this.setState({
			optionValues: {
				...optionValues,
				[name]: options,
			},
		})
	}

	componentWillReceiveProps(props) {
		let val = Joi.validate(props, schemas.propsSchema)
		if (val.error) {
			console.error(`${this.props.moduleName || (this.props.entityList ? this.props.entityList.entityName : 'Entity')} has schema error: ${val.error}`)
		} else {
			let view
			let entityValues = (props.entity && props.entity.entityValues) || props.entityValues || this.state.entityValues
			if (props.itemId || (props.params && props.params.itemId)) {
				view = this.props.meta && this.props.meta.screens && this.props.meta.screens.view
			} else {
				view = this.props.meta && this.props.meta.screens && this.props.meta.screens.create
			}

			let linked = this.state.linked || {}
			let linkedCount = this.state.linkedCount || {}
			if (view && view.loadLists && entityValues) {
				view.loadLists.forEach(l => {
					linked[l.targetProp] = this.props.entity[l.itemType]
					linkedCount[l.targetProp] = this.props.entity[l.itemType + '_count']
					if (!linked || !linked[l.targetProp]) {
						this.props.getAllLinked({ itemType: l.itemType, filter: l.filter, orderBy: l.orderBy, top: l.top, t: this.props.t })
						linked[l.targetProp] = true // to prevent repetitive calls when multiple lists are linked to the entity.
					}
				})
			}
			this.setState({
				meta: props.meta,
				entityValues: entityValues,
				linkedLists: linked,
				idKey: props.meta.idKey,
				bKeys: props.meta.bKeys,
				itemId: props.params && props.params.itemId,
			})
		}
	}

	updateLists(linkedLists) {
		let lists = this.state.linkedLists || {}
		lists = Object.assign({}, lists, linkedLists)
		this.setState({ linkedLists: lists })
	}
	renderItem(item) {
		if (!item) return
		const { fields, actions, sections, gridMeta, workflowActions } = this.state.meta
		const entityValues = this.props.subEntity && this.props.subEntity.entityValues
		const optionValues = this.state.optionValues || (this.props.entity && this.props.entity.optionValues)
		let { t } = this.props
		const title = t(this.props.meta && this.props.meta.screens && this.props.meta.screens.view && this.props.meta.screens.view.title)
		let itemDef, columns, refData
		let linkedLists = this.state.linkedLists
		let dependsOn
		switch (item.type) {
			case 'section':
				itemDef = sections[item.name]
				if (itemDef)
					return (
						<div className="row" key={item.name}>
							<SectionDetail
								meta={this.state.meta}
								title={title}
								moduleName={this.props.moduleName}
								key={item.name}
								name={item.name}
								entityValues={entityValues}
								{...this.props}
								linkedLists={linkedLists}
								handleAction={this.handleAction.bind(this)}
								handleChange={this.handleChange.bind(this)}
								optionValues={optionValues}
							/>
						</div>
					)
				break
			case 'field':
				itemDef = fields[item.name]
				if (itemDef) {
					if (itemDef.dependsOn && itemDef.dependsOn.pop && entityValues) {
						dependsOn = {}
						itemDef.dependsOn.forEach(f => {
							dependsOn[f] = entityValues[f]
						})
					}
					return (
						<div key={item.name}>
							<Field
								{...this.props}
								key={item.name}
								dependsOn={dependsOn}
								name={item.name}
								field={itemDef}
								formValues={entityValues}
								handleChange={this.handleChange.bind(this)}
								handleUploadChange={this.handleUploadChange.bind(this)}
								storeFieldOptions={this.storeFieldOptions.bind(this)}
								optionValues={optionValues}
							/>
						</div>
					)
				}
				break
			case 'action':
				itemDef = actions[item.name]
				if (itemDef)
					return (
						<div key={item.name}>
							<Action actionMeta={itemDef} {...this.props} linkedLists={linkedLists} handleAction={this.handleAction.bind(this)} />
						</div>
					)
				break
			case 'workflowAction':
				itemDef = workflowActions[item.name]
				if (itemDef)
					return (
						<div key={item.name}>
							<WorkflowButton actionMeta={itemDef} {...this.props} linkedLists={linkedLists} />
						</div>
					)
				break
			case 'cardList':
				itemDef = gridMeta[item.name]
				columns = itemDef && itemDef.items
				if (itemDef.dataSource.ref) {
					let refs = itemDef.dataSource.ref.split('.')
					refData = refs.reduce((p, c) => {
						return p[c]
					}, entityValues)
				} else refData = entityValues
				if (itemDef && columns)
					return (
						<div key={item.name}>
							<CardLayout
								meta={this.props.meta}
								data={refData}
								columns={columns}
								cardPerRow={itemDef.cardPerRow}
								backgroundColor={itemDef.backgroundColor}
								color={itemDef.color}
								itemClick={this.props.itemClick}
								handleListAction={this.props.handleListAction}
							/>
						</div>
					)
				break
			case 'grid':
				itemDef = gridMeta[item.name]
				columns = itemDef && itemDef.items
				if (itemDef.dataSource.ref) {
					let refs = itemDef.dataSource.ref.split('.')
					refData = refs.reduce((p, c) => {
						return p[c]
					}, entityValues)
				} else refData = entityValues
				let newProps = { ...this.props }
				if (newProps.columns) delete newProps['columns']
				if (newProps.itemDef) delete newProps['itemDef']
				delete newProps['list']
				if (itemDef && columns)
					return (
						// <div key={item.name}>
						<ExpendableDataGrid
							key={item.name}
							meta={this.props.meta}
							list={refData}
							columns={columns}
							fields={fields}
							totalCount={this.props.totalCount}
							importData={this.props.importData}
							handleListAction={this.props.handleListAction}
							entity={this.props.entity}
							itemDef={itemDef}
							itemCellLookUpClick={this.props.itemCellLookUpClick}
							expendedRowIndex={this.props.selectedIndex}
							setGridParentData={this.props.setGridParentData}
							handleSelectItems={this.props.handleSelectItems}
							handleUpdateEntity={this.props.handleUpdateEntity}
							{...newProps}
						/>
						// </div>
					)
				break
			default:
		}
	}
	render() {
		const { subMeta } = this.props
		// let { modelData } = this.state;
		// let entityValues = this.state.entityValues || (this.props.entity && this.props.entity.entityValues)
		// const { fields, actions, } = meta
		const itemsByCols = {}
		// let screen;

		subMeta &&
			subMeta.items.forEach(i => {
				if (i.col) {
					itemsByCols[i.col] = itemsByCols[i.col] || []
					itemsByCols[i.col].push(i)
				} else {
					itemsByCols.unassigned = itemsByCols.unassigned || []
					itemsByCols.unassigned.push(i)
				}
			})
		itemsByCols.unassigned = itemsByCols.unassigned || (subMeta && subMeta.items)
		return (
			// <div>
			//   <div className="animated fadeIn">
			//   </div>
			//   <div className={''}>
			// {
			itemsByCols &&
			itemsByCols.unassigned &&
			itemsByCols.unassigned.map(item => {
				return this.renderItem(item)
			})
			// }
			//   </div>
			// </div>
		)
	}
}
DetailPage.propTypes = {
	params: PropTypes.object,
	// entityValues: PropTypes.object,
	entity: PropTypes.shape({ entityValues: PropTypes.object, error: PropTypes.string }),

	loading: PropTypes.bool,
}

export default withTranslation()(DetailPage)
