import Joi from 'joi-browser'

const value = Joi.alternatives()
	.try(Joi.string(), Joi.number(), Joi.array().items(Joi.alternatives().try(Joi.string(), Joi.number())))
	.when('operator', {
		is: Joi.only(['is empty', 'is not empty']),
		then: Joi.forbidden(),
		otherwise: Joi.required(),
	})
export const filterSchema = Joi.alternatives().try(
	Joi.object()
		.keys({
			andOrOr: Joi.string().valid('and', 'or'),
			field: Joi.string(),
			operator: Joi.string().valid('contains', 'does not contain', 'is', 'is not', 'is empty', 'is not empty', 'is within', '<', '>', '<=', '>=', 'range', 'eqOrEmpty'),
			value: value,
			values: Joi.array().items(value),
			valueRef: Joi.string(),
			valuesRef: Joi.array().items(Joi.string()),
			defaultValues: Joi.alternatives().try(Joi.array().items(value), value),
		})
		.without('value', 'values'),
	Joi.string() // simple field names
)
export const filtersSchema = Joi.array().items(filterSchema)
export const apiSchema = Joi.object()
	.keys({
		path: Joi.string(),
		name: Joi.string(),
		method: Joi.string().valid('GET', 'POST'),
		pollingEval: Joi.string(),
		metricType: Joi.string(),
		response: Joi.object()
			.keys({
				ref: Joi.string(),
				targetProp: Joi.string(),
				valueKey: Joi.string(),
				computeFn: Joi.string(),
			})
			.nand('valueKey', 'computeFn'),
		fields: Joi.array().items(Joi.string()),
		filters: filtersSchema,
		groupBy: Joi.array().items(Joi.string()),
		groupByAgg: Joi.array()
			.items({ field: Joi.string(), operation: Joi.string().valid('sum', 'min', 'max', 'average', 'countdistint'), as: Joi.string() })
			.min(1),
		orderBy: Joi.array().items(Joi.object().keys({ field: Joi.string(), order: Joi.string().valid('asc', 'desc').default('asc') })),
		top: Joi.number().integer().positive(),
		select: Joi.array().items(Joi.string()),
		bodyOdataMap: Joi.object()
			.keys({
				filters: Joi.string(),
				select: Joi.string(),
				groupBy: Joi.string(),
				groupByAgg: Joi.string(),
				orderBy: Joi.string(),
				top: Joi.number(),
			})
			.with('groupBy', ['groupByAgg']),
		bodyMap: Joi.array().items(Joi.object().keys({ key: Joi.string(), valueKey: Joi.string() })),
		dependsOnFields: Joi.array().items(Joi.string()),
	})
	.with('groupBy', ['groupByAgg'])

export const cardsMeta = Joi.object()
	.keys({
		title: Joi.string(),
		name: Joi.string(),
		// metrics, operations and filters are used for dataview transform aggregate operations. no direct api call.
		metrics: Joi.array().items(
			Joi.object().keys({
				name: Joi.string(),
				fields: Joi.array().items(Joi.string()),
				operations: Joi.array().items(Joi.string().valid('mean', 'count', 'sum', 'product', 'min', 'max')),
				as: Joi.array().items(Joi.string()),
				groupBy: Joi.array().items(Joi.string()),
				value: Joi.any(),
			})
		),
		metricsOperations: Joi.array().items(Joi.string().valid('mean', 'count', 'sum', 'product', 'min', 'max')),
		filters: Joi.array().items(
			Joi.object()
				.keys({
					field: Joi.string(),
					operator: Joi.string().valid('range', 'in', 'is'),
					values: Joi.array().items(Joi.alternatives().try(Joi.string(), Joi.number())),
					value: Joi.alternatives().try(Joi.string(), Joi.number(), Joi.boolean()),
					fieldType: Joi.string().valid('text', 'bool', 'number'),
				})
				.xor('values', 'value')
		), // dashboard level and card level both.
		dynFilters: Joi.object().pattern(/^/, filterSchema),
		// can be a list of values with eq operation or can be a
		dependsOnFields: Joi.array().items(Joi.string()), // all the filter fields that can change the data.
		classes: Joi.string(),
		size: Joi.string().valid('xs', 'sm', 'md', 'xl'),
		icon: Joi.string(),
		api: Joi.string(),
		bodySuffix: Joi.string().allow(null).allow(''),
		bodyPrefix: Joi.string(),
		dataProp: Joi.string(), // if not api or metrics/transform , reuse an existing property for data? e.g. api already fetched this data required and avialble in this path.
	})
	.without('api', ['filters', 'operations'])
// .xor('api', 'dataProp')
