import { i18n } from '../..'
export const getExactError = (error, fields) => {
	if (!error) return error
	if (error && (error.name === 'ValidationError' || error.details)) {
		let errorDetails = error.details
		let errMessages =
			errorDetails &&
			errorDetails
				.map(err => {
					let popLastItem = err.path && err.path.slice(0, -1)
					let exactPath = (popLastItem && popLastItem.filter(p => !(isGuid(p) || p === 'properties' || p === 'props'))) || []
					//console.log("error:::", err)
					let contextLabel = err.context && err.context.label
					if (fields && fields[contextLabel] && fields[contextLabel].label) contextLabel = fields[contextLabel].label
					switch (err.type) {
						case 'any.required':
							return (
								(contextLabel && `${i18n.t(contextLabel)} ${i18n.t('is required')}`) ||
								err.message + (exactPath.length > 0 ? ` of ${exactPath.join(' of ')}` : '')
							)

						case 'array.base':
							return (
								(contextLabel && `${i18n.t(contextLabel)} ${i18n.t('is required')}`) ||
								err.message + (exactPath.length > 0 ? ` of ${exactPath.join(' of ')}` : '')
							)

						case 'string.base':
							return (
								(contextLabel && `${i18n.t(contextLabel)} ${i18n.t('is required')}`) ||
								err.message + (exactPath.length > 0 ? ` of ${exactPath.join(' of ')}` : '')
							)

						case 'any.empty':
							return (
								(contextLabel && `${i18n.t(contextLabel)} ${i18n.t('could not empty')}`) ||
								err.message + (exactPath.length > 0 ? ` of ${exactPath.join(' of ')}` : '')
							)

						case 'string.empty':
							return (
								(contextLabel && `${i18n.t(contextLabel)} ${i18n.t('could not empty')}`) ||
								err.message + (exactPath.length > 0 ? ` of ${exactPath.join(' of ')}` : '')
							)

						case 'string.required':
							return (
								(contextLabel && `${i18n.t(contextLabel)} ${i18n.t('is required')}`) ||
								err.message + (exactPath.length > 0 ? ` of ${exactPath.join(' of ')}` : '')
							)

						case 'string.uri':
							return (
								(contextLabel && `${i18n.t(contextLabel)} ${i18n.t('must be a valid url')}` + (exactPath.length > 0 ? ` of ${exactPath.join(' of ')}` : '')) ||
								err.message + (exactPath.length > 0 ? ` of ${exactPath.join(' of ')}` : '')
							)

						default:
							return err.message + (exactPath.length > 0 ? ` of ${exactPath.join(' of ')}` : '')
					}
				})
				.filter(e => e)
		return (errMessages && errMessages) || error
	} else return error
}

function isGuid(stringToTest) {
	if (stringToTest[0] === '{') {
		stringToTest = stringToTest.substring(1, stringToTest.length - 1)
	}
	var regexGuid = /^(\{){0,1}[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}(\}){0,1}$/gi
	return regexGuid.test(stringToTest)
}
