import React, { Component, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { withTranslation } from 'react-i18next'
// import PropTypes from 'prop-types';
import ReactPlayer from 'react-player'
import {
	Button,
	Col,
	FormGroup,
	FormText,
	Input,
	Jumbotron,
	Label,
	ListGroup,
	ListGroupItem,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Nav,
	NavItem,
	NavLink,
	Row,
	TabContent,
	TabPane
} from 'reactstrap'
import './jsonInput/component.css'
class VideoComponent extends Component {
	constructor(props) {
		super(props)
		this.state = {
			tabSwitch: false,
			upload: true,
			refUrl: '',
			activeTab: '1',
			errorMsg: { validUrl: true },
			playUrl: false,
			fileType: null,
			play: false,
			videoList: null,
			playList:
				this.props.list ||
				[
					// { url: 'https://www.youtube.com/watch?v=I30XwezzcVc', type: 'youTube' },
					// { url: 'https://vimeo.com/90509568', type: 'youTube' },
					// { url: 'https://www.facebook.com/facebook/videos/10153231379946729/', type: 'facebook' },
					// { url: 'http://clips.vorwaerts-gmbh.de/big_buck_bunny.mp4', type: 'mp4' }
				],
		}
		this.toggle = this.toggle.bind(this)
	}
	toggle(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeTab: tab,
			})
		}
	}
	componentWillMount() {}

	componentDidMount() {}

	componentWillReceiveProps(nextProps) {
		if (nextProps && nextProps.value) {
			this.mapValues(nextProps.value)
		}
	}
	mapValues = value => {
		let fetchValue = value && value.length > 0 ? value[0] : null
		if (fetchValue && fetchValue.sasUrl) {
			let sasUrl = fetchValue.sasUrl

			if (fetchValue.contentRef && this.validURL(fetchValue.contentRef)) {
				this.toggle('2')
				this.setState({ refUrl: fetchValue.contentRef, play: true, playUrl: fetchValue.contentRef, fileType: value[0].type || 'video' })
			} else if (this.validURL(sasUrl)) {
				this.toggle('2')
				this.setState({ refUrl: sasUrl, play: true, playUrl: sasUrl, fileType: value[0].type || 'video' })
			} else {
				this.toggle('1')
				this.setState({ videoList: value })
			}
		}
	}

	// shouldComponentUpdate(nextProps, nextState) {

	// }

	// componentWillUpdate(nextProps, nextState) {

	// }

	// componentDidUpdate(prevProps, prevState) {

	// }

	// componentWillUnmount() {

	// }

	validURL(str) {
		var pattern = new RegExp(
			'^(https?:\\/\\/)?' + // protocol
				'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
				'((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
				'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
				'(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
				'(\\#[-a-z\\d_]*)?$',
			'i'
		) // fragment locator
		return !!pattern.test(str)
	}

	handleInputChange = event => {
		const { errorMsg } = this.state
		if (event.target.value) {
			if (this.validURL(event.target.value)) {
				this.saveUrl(event.target.value)
				this.setState({ play: true, playUrl: event.target.value, refUrl: event.target.value })
			} else {
				this.setState({
					refUrl: event.target.value,
					play: false,
					playUrl: null,
					errorMsg: {
						...errorMsg,
						validUrl: true,
						inValidMsg: '',
					},
				})
			}
		} else {
			this.setState({ play: false, refUrl: '', playUrl: false })
		}
	}
	switchTab = tabName => {
		if (tabName && tabName === 'upload') this.setState({ tabSwitch: false, upload: true })
		else this.setState({ tabSwitch: true, upload: false })
	}

	readFileAsync(file) {
		return new Promise((resolve, reject) => {
			let reader = new FileReader()

			reader.onload = () => {
				resolve(reader.result)
			}

			reader.onerror = reject

			reader.readAsDataURL(file)
		})
	}

	async asyncForEach(array, callback) {
		for (let index = 0; index < array.length; index++) {
			await callback(array[index], index, array)
		}
	}
	onDrop = async files => {
		// const { playList } = this.state
		const { errorMsg } = this.state
		let fileList = []
		if (files && files.length > 0) {
			let filesData = Object.keys(files)
			await this.asyncForEach(filesData, async key => {
				// let docName = files[key].name;
				// let docType = files[key].type;
				let contentBuffer = await this.readFileAsync(files[key])

				let fileObj = {
					contentRef: 'Video',
					name: files[key].name || 'File',
					type: files[key].type,
					data: contentBuffer,
				}
				fileList.push(fileObj)
			})
			this.props.onComponentChange(this.props.name, fileList)

			this.resetAll()
		} else {
			this.setState({
				errorMsg: {
					...errorMsg,
					upload: false,
					inValidMsg: 'Please Select a File to Upload',
				},
			})
		}
	}
	cancelUpload = () => {
		this.resetAll()
	}

	setFileType = url => {
		let checkType = (url = url.substr(1 + url.lastIndexOf('/')).split('?')[0]).split('#')[0].substr(url.lastIndexOf('.'))
		if (checkType === '.gif' || checkType === '.jpg' || checkType === '.jpeg' || checkType === '.bmp') {
			return 'image'
		} else {
			return 'video'
		}
	}

	saveUrl = refUrl => {
		const { errorMsg } = this.state
		let fileList = []
		if (this.validURL(refUrl)) {
			let fileType = this.setFileType(refUrl)
			let fileObj = {
				contentRef: refUrl,
				type: fileType,
			}
			fileList.push(fileObj)
			this.setState({ fileType: fileType })
			this.props.onComponentChange(this.props.name, fileList)
			this.resetAll()
		} else
			this.setState({
				errorMsg: {
					...errorMsg,
					validUrl: false,
					inValidMsg: 'Url is Invalid',
				},
			})
	}
	resetAll() {
		this.setState({
			refUrl: '',
			play: false,
			playUrl: false,
			errorMsg: {
				validUrl: true,
				inValidMsg: '',
			},
		})
	}
	cancelUrl = () => {
		this.resetAll()
	}

	render() {
		const { activeTab, playUrl, play, playList, errorMsg, refUrl, fileType, videoList } = this.state
		let { t } = this.props
		const setPreview = () => {
			if (play && playUrl && fileType && fileType !== 'image' && fileType !== 'image/gif') {
				return <Player playUrl={playUrl} control={true} />
			} else {
				return <Image imgSrc={playUrl} />
			}
		}
		return (
			<div className="upload">
				<div className="videopreview">{setPreview()}</div>
				<Nav tabs>
					<NavItem>
						<NavLink
							className={`tablink ${activeTab === '1' ? `active` : ``}`}
							onClick={() => {
								this.toggle('1')
							}}>
							{t('UPLOAD')}
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink
							className={`tablink ${activeTab === '2' ? `active` : ``}`}
							onClick={() => {
								this.toggle('2')
							}}>
							{t('URL')}
						</NavLink>
					</NavItem>
				</Nav>
				<TabContent activeTab={activeTab}>
					<TabPane tabId="1">
						<Row>
							<Col sm="12">
								{errorMsg && !errorMsg.upload && <FormText color="danger">{t(errorMsg.inValidMsg) || ''}</FormText>}
								<FormGroup>
									<DropZoneComponent
									  {...this.props}
										upload={this.onDrop.bind(this)}
										cancel={this.cancelUpload.bind(this)}
										maxUpload={this.props.maxUpload || 1}
										maxUploadSize={this.props.maxUploadSize || 10}
										videoList={this.state.videoList}
									/>
								</FormGroup>
							</Col>
						</Row>
					</TabPane>
					<TabPane tabId="2">
						<Row>
							<Col sm="12">
								{errorMsg && !errorMsg.validUrl && <FormText color="danger">{t(errorMsg.inValidMsg) || ''}</FormText>}
								<FormGroup>
									<Label for="exampleCustomFileBrowser">{t('Enter Url')}</Label>
									<Input
										type="textarea"
										placeholder={t("Enter Refrence URL")}
										value={refUrl}
										onChange={this.handleInputChange.bind(this)}
										id="url"
										name="url"
										label={t("Enter Url!")}
									/>
								</FormGroup>
							</Col>
						</Row>
						{/* <Row><Col sm={6}></Col>
                            <Col sm={6}>
                                <Row className="pull-right">
                                    <div className="col-md-5"><Button color="primary" onClick={this.saveUrl.bind(this)} size="sm">Save</Button></div>
                                    <div className="col-md-5"><Button color="danger" onClick={this.cancelUrl.bind(this)} size="sm">Cancel</Button></div>
                                </Row>
                            </Col>
                        </Row> */}
					</TabPane>
					{playList && playList.length > 0 && <VideoList list={this.state.playList} />}

					{videoList && videoList.length > 0 && (
						<Jumbotron>
							<ListGroup>
								{videoList.map((file, index) => (
									<ListGroupItem key={index} className="justify-content-between listPad spantitle">
										<Player {...this.props} playUrl={file.sasUrl} control={true} />
										{t('File Name')} : {file.name} <br /> {t('Type')} : {file.type}
									</ListGroupItem>
								))}
							</ListGroup>
						</Jumbotron>
					)}
				</TabContent>
			</div>
		)
	}
}

VideoComponent.propTypes = {}

export default withTranslation()(VideoComponent)

// React DropZone Component

const DropZoneComponent = props => {
	const [files, setFiles] = useState([])
	const [errorMsg, setErrorMsg] = useState(false)
	const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
		accept: 'video/mp4,image/gif',
		onDrop: acceptedFiles => {
			setFiles(
				acceptedFiles.map(file =>
					Object.assign(file, {
						preview: URL.createObjectURL(file),
					})
				)
			)
		},
	})
	const [selectedFile, setSelectedFile] = useState(acceptedFiles || null)

	useEffect(() => {
		if (validateFile(acceptedFiles) && validateFileSize(acceptedFiles)) {
			acceptedFiles && acceptedFiles.length > 0 && upload(acceptedFiles)
			setSelectedFile(acceptedFiles)
		}
	}, [acceptedFiles])

	useEffect(
		() => () => {
			// Make sure to revoke the data uris to avoid memory leaks
			files.forEach(file => URL.revokeObjectURL(file.preview))
		},
		[files]
	)

	const removeFile = (fileInfo, event) => {
		let filterFile = selectedFile && selectedFile.length > 0 && selectedFile.filter(x => x.name !== fileInfo.name)
		setSelectedFile(filterFile)
	}
	const validateFile = validate => {
		const { maxUpload } = props
		if (props.videoList && props.videoList.length > 0 && props.videoList.length <= maxUpload) {
			setFiles([])
			setSelectedFile(null)
			setErrorMsg(`Maximum ${maxUpload} Video can be uploaded!`)
			return false
		} else if (validate.length <= maxUpload) {
			setErrorMsg(false)
			return true
		} else {
			setFiles([])
			setSelectedFile(null)
			setErrorMsg(`Maximum ${maxUpload} Video can be uploaded!`)
			return false
		}
	}
	const validateFileSize = validate => {
		const { maxUploadSize } = props
		let mbToByte = maxUploadSize * 1024 * 1024
		let file = validate[0]
		if (file && mbToByte && file.size > mbToByte) {
			setFiles([])
			setSelectedFile(null)
			setErrorMsg(`Maximum ${maxUploadSize} MB Video can be uploaded!`)
			return false
		} else {
			setErrorMsg(false)
			return true
		}
	}
	// const cancel = () => {
	//     setFiles([])
	//     setSelectedFile(null)
	//     setErrorMsg(false)
	//     props.cancel()
	// }

	const upload = uploadFiles => {
		if (uploadFiles.length > 0) {
			props.upload(uploadFiles)
			setErrorMsg(false)
		} else {
			setErrorMsg(`Please Select a file to upload!`)
		}
	}

	return (
		<section className="container">
			{errorMsg && (
				<p>
					<FormText color="danger">{errorMsg}</FormText>
				</p>
			)}
			<div {...getRootProps({ className: 'dropzone' })}>
				<input {...getInputProps()} />
				<Jumbotron>{props.t(`Drag 'n' drop a Video or Gif file here, or click to select a file`)}</Jumbotron>
			</div>
			{/* <Row><Col sm={6}></Col>
                <Col sm={6}>
                    <Row className="pull-right">
                        <div className="col-md-5"><Button color="primary" onClick={upload.bind(this)} size="sm">Upload</Button></div>
                        <div className="col-md-5"><Button color="danger" onClick={cancel.bind(this)} size="sm">Cancel</Button></div>
                        <div className="clearfix"></div>
                    </Row>
                </Col>
            </Row> */}

			<aside>
				{selectedFile && selectedFile.length > 0 && (
					<Jumbotron>
						<ListGroup>
							{selectedFile.map(file => (
								<ListGroupItem key={file.path} className="justify-content-between listPad spantitle">
									{file.path} - {file.size} bytes{' '}
									<span onClick={removeFile.bind(this, file)} className="tablink text-danger">
										x
									</span>
								</ListGroupItem>
							))}
						</ListGroup>
					</Jumbotron>
				)}
			</aside>
		</section>
	)
}

// Video List Component
const VideoList = props => {
	const [list, setList] = useState(props.list || [])
	const [playUrl, setPlayUrl] = useState(null)

	useEffect(() => {
		setList(props.list)
	}, [props])

	const playMe = url => {
		setPlayUrl(url)
	}
	return (
		<div className="videoList">
			<Jumbotron>
				<Row>
					<Col sm="12" className="vcol">
						<Player playUrl={playUrl || list[0].url || null} playing={false} control={true} light={false} height={'100%'} />
					</Col>
				</Row>
				<Row className="vlist">
					{list &&
						list.length > 1 &&
						list.map((file, index) => (
							<Col sm={3} key={index} className="vcol" onClick={playMe.bind(this, file.url)}>
								<div className={file.url === playUrl ? 'videoactive' : ''}>
									<Player playUrl={file.url} control={false} disabled={true} playing={false} light={true} height={'100%'} />
									<span className="tablink spantitle">{`Video ${index + 1}`}</span>
								</div>
							</Col>
						))}
				</Row>
			</Jumbotron>
		</div>
	)
}

// React Player Component
const Player = props => {
	const { playUrl, control, light, playing, embed } = props
	const [state, setState] = useState({
		embedModal: false,
	})
	const embedClick = () => {
		setState({ ...state, embedModal: !state.embedModal })
	}
	return (
		<>
			<div className="player-wrapper">
				<ReactPlayer
					className="react-player"
					url={playUrl}
					playing={playing || false}
					width="100%"
					height={'100%'}
					controls={control || false}
					light={light || false}
					pip={false}
				/>
			</div>
			{embed && (
				<div className="col-md-12 vcol">
					<div className="embed pull-right" onClick={embedClick.bind(this)}>
						Embed <i className="fa fa-share"></i>
					</div>
				</div>
			)}
			{state.embedModal && <Embed modal={state.embedModal} url={playUrl} toggle={embedClick} />}
		</>
	)
}

// Embed Url Modal

const Embed = props => {
	const [embedUrl, setEmbedUrl] = useState(props.url || null)

	useEffect(() => {
		mapEmbedUrl(props.url)
	}, [props])

	const mapEmbedUrl = url => {
		var youtubeExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
		var facebookExp = /^.*(facebook\/|v\/|u\/\w\/|embed\/|facebook\/|videos\/)([^#&?]*).*/
		var vimeoExp = /^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?([0-9]+)/
		var youtubeMatch = url.match(youtubeExp)
		var facebookMatch = url.match(facebookExp)
		var vimeoMatch = url.match(vimeoExp)
		if (youtubeMatch && youtubeMatch[2].length === 11) {
			return setEmbedUrl(`https://www.youtube.com/embed/${youtubeMatch[2]}`)
		} else if (facebookMatch) {
			return setEmbedUrl(
				`https://www.facebook.com/v2.3/plugins/video.php?allowfullscreen=true&autoplay=true&container_width=800&href=https://www.facebook.com/facebook/videos/${facebookMatch[2]}&locale=en_US&sdk=joey`
			)
		} else if (vimeoMatch) {
			return setEmbedUrl(`https://player.vimeo.com/video/${vimeoMatch[5]}`)
		} else {
			return setEmbedUrl(url)
		}
	}

	const toggle = () => {
		props.toggle()
	}
	return (
		<div>
			<Modal isOpen={props.modal} toggle={toggle.bind(this)}>
				<ModalHeader>{props.title || 'Embed Video'}</ModalHeader>
				<ModalBody>
					{embedUrl && (
						<>
							<Input
								type="textarea"
								rows="5"
								value={`   <iframe width="100%" height="315" src=${embedUrl} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen
        >`}
							/>
							<iframe
								title="video"
								width="100%"
								height="315"
								src={embedUrl}
								frameBorder="0"
								allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
								allowFullScreen></iframe>
						</>
					)}
				</ModalBody>
				<ModalFooter>
					<Button color="secondary" size="sm" onClick={toggle.bind(this)}>
						Close
					</Button>
				</ModalFooter>
			</Modal>
		</div>
	)
}

// React Image Component
const Image = props => {
	const { imgSrc, height, width, onClick, className } = props
	const onImageClick = imageUrl => {
		onClick && onClick(imageUrl)
	}
	return (
		<div className="image">
			{imgSrc && (
				<img
					className={className || ''}
					src={imgSrc}
					height={height || undefined}
					width={width || undefined}
					onClick={onImageClick.bind(this, imgSrc)}
					alt=""
				/>
			)}
		</div>
	)
}
