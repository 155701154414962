import axios from 'axios'
import { globalTenantServiceUrl } from '../../../config'

export const checkTenantExists = tenantId => {
	const requestOptions = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
		},
		url: `${globalTenantServiceUrl}/tenant?tenantId=${tenantId}`,
	}

	// if(headerParams){
	//     let headerParamsConfig = JSON.parse(headerParams);
	//     headerParamsConfig.forEach(p =>{
	//         requestOptions.headers[p.name] = p.value
	//     })
	// }
	return axios.request(requestOptions).then(response => {
		if (response.status !== 200) {
			return Promise.reject(`Unable to do tenant validation : Error code : ${response.status}`)
		}

		return response.data
	})
}

export const registerTenant = payload => {
	if (payload && payload.products.length > 1) {
		let Global = {
			label: 'Global',
			value: 'Global',
		}
		payload.products.push('global')
		payload.productLabels.push(Global)
	}
	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		url: `${globalTenantServiceUrl}/tenant`,
		data: payload,
	}

	//  if(headerParams){
	//     let headerParamsConfig = JSON.parse(headerParams);
	//     headerParamsConfig.forEach(p =>{
	//         requestOptions.headers[p.name] = p.value
	//     })
	// }
	return axios.request(requestOptions).then(response => {
		if (response.status !== 201) {
			return Promise.reject(`Unable to do create tenant : Error code : ${response.status}`)
		}

		return response.data
	})
}

export const getTenantLifeCycle = tenantId => {
	// lifecycle has to be checked in region specific cluster and not in globaldiscovery url.
	let apiUrl = sessionStorage.getItem('apiUrl')
	if (apiUrl && !apiUrl.includes('api/v1')) {
		apiUrl += '/api/v1'
		// apiUrl = apiUrl.replace(/\/(\/)+/g, '/')
	}
	const requestOptions = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
		},
		url: `${apiUrl || globalTenantServiceUrl}/tenant/lifecycle/${tenantId}?ts=${new Date().getTime()}`,
	}
	// if(headerParams){
	//     let headerParamsConfig = JSON.parse(headerParams);
	//     headerParamsConfig.forEach(p =>{
	//         requestOptions.headers[p.name] = p.value
	//     })
	// }
	return axios.request(requestOptions).then(response => {
		if (response.status !== 200) {
			return Promise.reject(`Unable to do creation tenant lifecycle : Error code : ${response.status}`)
		}

		return response.data
	})
}

export const validateEmailOTP = payload => {
	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		data: payload,
		url: `${globalTenantServiceUrl}/tenant/validateOTP`,
	}

	// if(headerParams){
	//     let headerParamsConfig = JSON.parse(headerParams);
	//     headerParamsConfig.forEach(p =>{
	//         requestOptions.headers[p.name] = p.value
	//     })
	// }
	return axios.request(requestOptions).then(response => {
		if (response.status !== 200) {
			return Promise.reject(`Unable to validate : Error code : ${response.status}`)
		}

		return response.data
	})
}

export const getOTP = payload => {
	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		data: payload,
		url: `${globalTenantServiceUrl}/tenant/getOTP`,
	}

	// if(headerParams){
	//     let headerParamsConfig = JSON.parse(headerParams);
	//     headerParamsConfig.forEach(p =>{
	//         requestOptions.headers[p.name] = p.value
	//     })
	// }
	return axios.request(requestOptions).then(response => {
		if (response.status !== 200) {
			return Promise.reject(`Unable to do tenant validation : Error code : ${response.status}`)
		}

		return response.data
	})
}
