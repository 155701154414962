// import React from 'react';
import { EntityDetail } from '../../common/base/entity/components/DetailComponent'
// import SectionDetail from '../../common/base/entity/components/SectionDetail'
// import { Field, Action, ConfrimDialog, CardLayout, DataGrid, ExpendableDataGrid, WorkflowButton, LookUpComponent } from '../../common/base/entity/components/index'
// import { Loader, alertActions } from '../../common';
import { toast } from 'react-toastify';

/**
 * extended class for expanding and collapsing the properties field on to form.
 */
class IntentBotDetail extends EntityDetail {
    constructor(props) {
        super(props)
    }

    componentWillMount() {
        let { meta, params, profile } = this.props
        if(profile.roles.indexOf("Tenant Admin") != -1) {
            meta.fields['intentLabel'].readOnly = true
            meta.fields['issueType'].readOnly = true
            meta.fields['skill'].readOnly = true
            meta.fields['subjectArea'].readOnly = true
            meta.fields['intentName'].readOnly = true
            meta.fields['conditionsName'].readOnly = true
            meta.fields['conditions'].readOnly = true
            meta.fields['skill'].readOnly = true
            meta.fields['ticketObj'].readOnly = true
        }
    }


}
export default IntentBotDetail