import { InfoOutlineIcon } from '@chakra-ui/icons'
import {
	Box,
	Button,
	Checkbox,
	CheckboxGroup,
	Flex,
	Heading,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Tooltip,
	Wrap,
	WrapItem,
} from '@chakra-ui/react'
import React, { useCallback, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { session } from '../../../config'
import { identityServices } from '../../common'
import './style.css'
const RoleGroupModal = props => {
	const [list, setList] = useState([])
	const handleClose = useCallback(() => {
		props.handleModal()
	}, [props])

	useEffect(() => {
		let mounted = true
		const getRoleGroups = async () => {
			const requestOptions = {
				url: `${
					props.apiUrl || (props.tenant && props.tenant.apiUrl) || session.get('apiUrl') || sessionStorage.getItem('apiUrl') || localStorage.getItem('apiUrl')
				}/botmgmt-service/RoleGroup/findAndCountAllWithDefaultTenant?$filter=status eq 'Published'`,
				method: 'GET',
			}

			let result = await identityServices.identityApi.request(requestOptions)
			let result1 = result && result.data && result.data.rows ? result.data.rows : result.data && result.data.length > 0 ? result.data : []
			result1 = result1.map(r => {
				r.isChecked = false
				if (r.roleInfo && r.roleInfo.length > 0) {
					r.roleInfo = r.roleInfo.map(n => {
						n.isChecked = false
						if (props.selectedRoles.indexOf(n.role) != -1) {
							n.isChecked = true
							r.isChecked = true
						}
						return n
					})
				}
				return r
			})
			return result1
		}
		getRoleGroups().then(items => {
			if (mounted) {
				setList(items)
			}
		})
		return () => (mounted = false)
	}, [props.apiUrl, props.selectedRoles, props.tenant])

	const handleRoleGroup = useCallback(
		(item, e) => {
			if (item && item.roleGroup) {
				let newList = list.map(l => {
					if (l.roleGroup.toLowerCase() == item.roleGroup.toLowerCase()) {
						l.isChecked = e.target.checked
					}
					return l
				})
				setList(newList)
			}
		},
		[list]
	)

	const handleRoleChange = (group, role, e) => {
		if (group && role) {
			let newList = list.map(l => {
				if (l.roleGroup.toLowerCase() == group.toLowerCase()) {
					l.roleInfo.map(r => {
						if (r.role == role) {
							r.isChecked = e.target.checked
						}
						return r
					})
				}
				return l
			})
			setList(newList)
		}
	}

	const handleUpdateRoles = e => {
		let roles = []
		list &&
			list.map(l => {
				if (l.isChecked) {
					l.roleInfo &&
						l.roleInfo.map(r => {
							if (r.isChecked) {
								roles.push(r.role)
							}
						})
				}
			})
		props.handleUpdateRoles(roles)
	}
	let selectedRoleGroupList = list.filter(l => l.isChecked)
	return (
		<Box>
			<Modal isOpen={props.isOpen} onClose={handleClose} size="3xl">
				<ModalOverlay />
				<ModalContent>
					<ModalHeader background="blue.400" color="white">
						{props.t('Manage Roles')}
					</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Flex p="4">
							<Box w="100%">
								<Heading as="h4" size="md">
									{props.t('Role Groups')}:
								</Heading>
								<Wrap spacing="15px" marginTop="15px" marginLeft="15px">
									{list &&
										list.map((l, i) => {
											return (
												<WrapItem>
													<Checkbox size="lg" colorScheme="blue" index={i} isChecked={l.isChecked} onChange={e => handleRoleGroup(l, e)}>
														{props.t(l.roleGroup)}
													</Checkbox>
												</WrapItem>
											)
										})}
								</Wrap>
							</Box>
						</Flex>
						<Flex p="4">
							<Box w="100%" marginTop="5">
								<Heading as="h4" size="md">
									{selectedRoleGroupList && selectedRoleGroupList.length > 0 ? `${props.t('Roles')}:` : ''}
								</Heading>
								{selectedRoleGroupList &&
									selectedRoleGroupList.map((l, index) => {
										return (
											<Box w="100%" index={l.roleGroup + index} mt="10px">
												<Heading as="h5" size="sm">
													{props.t(l.roleGroup)}:
												</Heading>
												<CheckboxGroup colorScheme="green">
													<Wrap marginTop="15px">
														{l.roleInfo &&
															l.roleInfo.map((r, j) => {
																return (
																	<WrapItem>
																		<Checkbox
																			size="lg"
																			w="100%"
																			colorScheme="blue"
																			index={r.role + j}
																			marginLeft="20px"
																			isChecked={r.isChecked}
																			onChange={e => handleRoleChange(l.roleGroup, r.role, e)}>
																			{props.t(r.role)}
																		</Checkbox>
																		<Tooltip hasArrow label={`${props.t(r.description)}.`} bg="gray.300" color="black" fontFamily="sans-serif" fontSize="lg">
																			<InfoOutlineIcon marginLeft="5px" marginTop="3px" />
																		</Tooltip>
																	</WrapItem>
																)
															})}
													</Wrap>
												</CheckboxGroup>
											</Box>
										)
									})}
							</Box>
						</Flex>
					</ModalBody>

					<ModalFooter background="transparent">
						<Button colorScheme="blue" mr={3} onClick={handleClose}>
							{props.t('Cancel')}
						</Button>
						<Button colorScheme="blue" mr={3} onClick={handleUpdateRoles}>
							{props.t('Update')}
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</Box>
	)
}
export default withTranslation()(RoleGroupModal)
