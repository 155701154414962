import Joi from 'joi-browser'

const subscription = Joi.object().keys({
  id: Joi.number().integer().positive(),
  tenantId: Joi.string().uuid(),
  subscriptions: Joi.array().items(Joi.string()),
  createdBy: Joi.string().allow(null),
  createdAt: Joi.date().allow(null),
  created_at: Joi.date().allow(null),
  updatedBy: Joi.string().allow(null),
  updatedAt: Joi.date().allow(null),
  updated_at: Joi.date().allow(null),
  status: Joi.string().allow(null)
})
const plan = Joi.object().keys({
  product: Joi.string().required(),
  plan: Joi.string().required(),
  fixedRate: Joi.number().allow(null),
  fixedConsumptionLimit1: Joi.number(),
  fixedConsumptionLimit2: Joi.number(),
  startDate: Joi.date(),
  tenantId: Joi.string().uuid(),
  tags: Joi.array().items(Joi.string()).allow(null),
  props: Joi.object(),
  varName: Joi.string(),
  rate: Joi.number(),
  startLimit: Joi.number(),
  endLimit: Joi.number().allow(null)
})
// These are the schemas of the return object from the api server
export const methodSchemas = {
  getAll: Joi.object().keys({
    subscriptions: Joi.array().items(subscription),
    tenantPlans: Joi.array().items(plan),
    defaultPlans: Joi.array().items(plan),
    defaultProducts: Joi.array().items({
      product: Joi.string(),
      defaultSubscription: Joi.boolean(),
      props: Joi.object().allow(null),

    })
  }),
  getOne: subscription,
  addItem: subscription,
  editItem: subscription
}
export const collectionSchemas = {
  //  notes: note
}
export const entitySchema = subscription