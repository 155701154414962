export const EXECUTEAPI_REQUEST = 'EXECUTEAPIENTITY_REQUEST';
export const EXECUTEAPI_SUCCESS = 'EXECUTEAPIENTITY_SUCCESS';
export const EXECUTEAPI_FAILURE = 'EXECUTEAPIENTITY_FAILURE';
export const GETALL_REQUEST = 'GETALL_REQUEST';
export const GETALL_SUCCESS = 'GETALL_SUCCESS';
export const GETALL_FAILURE = 'GETALL_FAILURE';
export const GETONE_REQUEST = 'GETONE_REQUEST';
export const GETONE_SUCCESS = 'GETONE_SUCCESS';
export const GETONE_FAILURE = 'GETONE_FAILURE';
export const CHANGE_STATUS_REQUEST = 'CHANGE_STATUS_REQUEST';
export const CHANGE_STATUS_SUCCESS = 'CHANGE_STATUS_SUCCESS';
export const CHANGE_STATUS_FAILURE = 'CHANGE_STATUS_FAILURE';
export const ADDNEW_REQUEST = 'ADDNEW_REQUEST';
export const ADDNEW_SUCCESS = 'ADDNEW_SUCCESS';
export const ADDNEW_FAILURE = 'ADDNEW_FAILURE';
export const ADDCHILD_REQUEST = 'ADDCHILD_REQUEST';
export const ADDCHILD_SUCCESS = 'ADDCHILD_SUCCESS';
export const ADDCHILD_FAILURE = 'ADDCHILD_FAILURE';
export const REMOVECHILD_REQUEST = 'REMOVECHILD_REQUEST';
export const REMOVECHILD_SUCCESS = 'REMOVECHILD_SUCCESS';
export const REMOVECHILD_FAILURE = 'REMOVECHILD_FAILURE';
export const EDITCHILD_REQUEST = 'EDITCHILD_REQUEST';
export const EDITCHILD_SUCCESS = 'EDITCHILD_SUCCESS';
export const EDITCHILD_FAILURE = 'EDITCHILD_FAILURE';
export const EDIT_REQUEST = 'EDIT_REQUEST';
export const EDIT_SUCCESS = 'EDIT_SUCCESS';
export const EDIT_FAILURE = 'EDIT_FAILURE';
export const DELETE_REQUEST = 'DELETE_REQUEST';
export const DELETE_SUCCESS = 'DELETE_SUCCESS';
export const DELETE_FAILURE = 'DELETE_FAILURE';
export const LOADER_FALSE = 'LOADER_FALSE'
export const ADDLINKED_REQUEST = 'ADDLINKED_REQUEST';
export const ADDLINKED_SUCCESS = 'ADDLINKED_SUCCESS';
export const ADDLINKED_FAILURE = 'ADDLINKED_FAILURE';
export const REMOVELINKED_REQUEST = 'REMOVELINKED_REQUEST';
export const REMOVELINKED_SUCCESS = 'REMOVELINKED_SUCCESS';
export const REMOVELINKED_FAILURE = 'REMOVELINKED_FAILURE';
export const EDITLINKED_REQUEST = 'EDITLINKED_REQUEST';
export const EDITLINKED_SUCCESS = 'EDITLINKED_SUCCESS';
export const EDITLINKED_FAILURE = 'EDITLINKED_FAILURE';
export const GETALLCOUNT_REQUEST = 'GETALLCOUNT_REQUEST';
export const GETALLCOUNT_SUCCESS = 'GETALLCOUNT_SUCCESS';
export const GETALLCOUNT_FAILURE = 'GETALLCOUNT_FAILURE';
export const GETALLLINKED_REQUEST = 'GETALLLINKED_REQUEST';
export const GETALLLINKED_SUCCESS = 'GETALLLINKED_SUCCESS';
export const GETALLLINKED_FAILURE = 'GETALLLINKED_FAILURE';
export const GETALLLINKEDCOUNT_REQUEST = 'GETALLLINKEDCOUNT_REQUEST';
export const GETALLLINKEDCOUNT_SUCCESS = 'GETALLLINKEDCOUNT_SUCCESS';
export const GETALLLINKEDCOUNT_FAILURE = 'GETALLLINKEDCOUNT_FAILURE';
export const IMPORT_REQUEST = 'IMPORT_REQUEST';
export const IMPORT_SUCCESS = 'IMPORT_SUCCESS';
export const IMPORT_FAILURE = 'IMPORT_FAILURE';
export const APPEND_REQUEST = 'APPEND_REQUEST';
export const APPEND_SUCCESS = 'APPEND_SUCCESS';
export const APPEND_FAILURE = 'APPEND_FAILURE';

export const FILTEREDDATA_SUCCESS = 'FILTEREDDATA_SUCCESS';

export const GETAUDITCONFIG_REQUEST = 'GETAUDITCONFIG_REQUEST';
export const GETAUDITCONFIG_SUCCESS = 'GETAUDITCONFIG_SUCCESS';
export const GETAUDITCONFIG_FAILURE = 'GETAUDITCONFIG_FAILURE';
export const GETREFITEMS_REQUEST = 'GETREFITEMS_REQUEST';
export const GETREFITEMS_SUCCESS = 'GETREFITEMS_SUCCESS';
export const GETREFITEMS_FAILURE = 'GETREFITEMS_FAILURE';

export const ASSIGN_TO_STATE_REQUEST = 'ASSIGN_TO_STATE_REQUEST';
export const ASSIGN_TO_STATE_SUCCESS = 'ASSIGN_TO_STATE_SUCCESS';
export const ASSIGN_TO_STATE_FAILURE = 'ASSIGN_TO_STATE_FAILURE';

export const CUSTOM_EDIT_REQUEST = 'CUSTOM_EDIT_REQUEST';
export const CUSTOM_EDIT_SUCCESS = 'CUSTOM_EDIT_SUCCESS';
export const CUSTOM_EDIT_FAILURE = 'CUSTOM_EDIT_FAILURE';

export const GET_UI_FIELDS_META_REQUEST = 'GET_UI_FIELDS_META_REQUEST';
export const GET_UI_FIELDS_META_SUCCESS = 'GET_UI_FIELDS_META_SUCCESS';
export const GET_UI_FIELDS_META_FAILURE = 'GET_UI_FIELDS_META_FAILURE';