// import { LoadSpinner } from '../../components/helpers'
import classnames from 'classnames'
// import { EntityComponents } from '../../../../ComponentLoader'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
// import {SectionBoard} from '../../../analytics/components'
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import SectionDetail from './SectionDetail'
import './tab.css'
// import { history } from '../../../../../redux/history'
class TabDetail extends Component {
	constructor(props) {
		super(props)
		this.state = {
			tabGroupItem: props.tabGroupItem,
			activeTab: (props.params && props.params.activeTab) || (props.tabItem && props.tabItem.activeTab) || '1',
			entityValues: props.entitityValues,
			isHidden: props.isHidden,
		}
		this.toggletab = this.toggletab.bind(this)
	}
	componentWillReceiveProps(props) {}

	toggletab(tab) {
		this.setState({ activeTab: tab })
		this.props.handleTabChange()
	}

	// handleRelation(relatedCaseId) {
	//   if (relatedCaseId)
	//     history.push(`/ticket-detail/${relatedCaseId}`)
	//   window.location.href = window.location.href;
	// }

	render() {
		const { isHidden } = this.state
		const { tabGroupItem, t } = this.props
		const { sections } = this.props.meta

		// const { entitityValues } = this.props
		// let that = this
		return (
			<>
				{!isHidden && tabGroupItem && (
					<div className="col-md-12 custom-notes">
						{tabGroupItem.isToggle && (
							<span className="navHideN" onClick={this.props.handleClickArrow}>
								<i className={`fa fa-angle-${!this.props.switchTabIcon ? 'left' : 'right'}`}></i>
							</span>
						)}
						{!this.props.switchTabIcon && (
							<div className="tab-wrapper">
								<Nav tabs>
									{tabGroupItem.tabs &&
										tabGroupItem.tabs.map((tab, i) => {
											if (tab.type === 'section' && sections[tab.name]) {
												let secDef = sections[tab.name]
												let tabId = '' + (i + 1)
												return (
													<NavItem key={tabId}>
														<NavLink
															className={classnames({ active: this.state.activeTab === tabId })}
															onClick={() => {
																this.toggletab(tabId)
															}}>
															{t(secDef.title || tab.name)}
														</NavLink>
													</NavItem>
												)
											} else return <></>
										})}
								</Nav>
								<TabContent activeTab={this.state.activeTab}>
									{tabGroupItem.tabs &&
										tabGroupItem.tabs.map((t, i) => {
											let tabId = '' + (i + 1)
											let secDef = sections[t.name]

											return (
												<TabPane tabId={tabId} key={tabId}>
													{secDef && (
														<div className={'row ' + (secDef.classes || '')} key={t.name}>
															{/* <div className="col-12" key={secDef.name}> */}
															<SectionDetail {...this.props} meta={this.props.meta} key={secDef.name || t.name} name={secDef.name || t.name} />
															{/* <SectionBoard key={secDef.name} {...that.props} name={t.name} /> */}
															{/* </div> */}
														</div>
													)}
												</TabPane>
											)
										})}
								</TabContent>
							</div>
						)}
					</div>
				)}
			</>
		)
	}
}
/**
 * Will later define shapes.
 */
TabDetail.propTypes = {
	sections: PropTypes.object, // sections Meta collection
	tabGroupItem: PropTypes.object, // tab definition
	entityValues: PropTypes.object, // entity instance object
}

export default withTranslation()(TabDetail)
