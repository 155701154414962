import { profileConstants } from '../';

export const ProfileEntity = (state = { loading: false }, action) => {
  switch (action.type) {
    case profileConstants.MODIFY_USERS_REQUEST:
      return {
        ...state,
        modified: false,
        loading: true
      };
    case profileConstants.MODIFY_USERS_SUCCESS:
      let { user } = state;
      if (action.userUpdate.attributes) {
        user.attributes = action.userUpdate.attributes;
      }

      return {
        modified: true,
        loading: false,
        user: user
      }
    case profileConstants.MODIFY_USERS_FAILURE:
      return {
        ...state,
        modified: false,
        loading: false,
        payload: {}
      };

    case profileConstants.GET_USER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case profileConstants.GET_USER_SUCCESS:
      if (action.payload) {
        return {
          ...state,
          loading: false,
          user: action.payload,
        }
      } else {
        return {
          ...state,
          loading: false
        }
      }
    case profileConstants.GET_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case profileConstants.MODIFY_CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case profileConstants.MODIFY_CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case profileConstants.MODIFY_CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    default:
      return state
  }
}