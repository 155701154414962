import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
const loadMessage = [
	'Initializing',
	'Setting-up the account',
	'Doing some more tech work……',
	'Some even more complex tech work……  :)',
	'Some even more complex tech work……  :)',
	'Some even more complex tech work……  :)',
	'Some even more complex tech work……  :)',
]
class SetupTenant extends Component {
	constructor(props) {
		super(props)
		this.state = {
			isIE: false,
			loadingMessage: false,
		}
	}

	// checking Browser is IE or NOt
	msieversion() {
		var isIE = !!navigator.userAgent.match(/Trident/g) || !!navigator.userAgent.match(/MSIE/g)
		if (isIE) {
			this.setState({ isIE: true })
		} else {
			this.setState({ isIE: false })
		}
	}
	// checking Browser is IE or NOt
	componentWillMount() {
		this.msieversion()
		this.loaderShow()
	}

	componentDidMount() {
		let { t } = this.props.t
		this.props.getTenantLifeCycle(this.props.activeRegistration.tenantId, t)
	}
	loaderShow() {
		let counter = 0
		setInterval(() => {
			this.setState({ loadingMessage: loadMessage[counter] })
			counter++
			if (counter >= loadMessage.length) {
				counter = 0
			}
		}, 6000)
	}

	componentWillReceiveProps(props) {
		if (props.tenantLifeCycle && props.tenantLifeCycle.status && props.tenantLifeCycle.status == 'ACTIVE') {
			props.nextPage()
		}
	}

	render() {
		const { registrationFailed, tenantLifeCycle, nextPage } = this.props
		console.log(this.props.tenantLifeCycle)
		return (
			<div className="step3 steps progress-screen clearfix">
				{registrationFailed && registrationFailed != '' && registrationFailed.length > 0 ? (
					<div className="card text-white bg-danger mb-3" style={{ maxWidth: '18rem' }}>
						<div className="card-body">
							<h5 className="card-title">{this.props.stepConfig.stepFailed.message}</h5>
							<p className="card-text">{this.props.stepConfig.stepFailed.desc}</p>
						</div>
					</div>
				) : tenantLifeCycle && tenantLifeCycle.status === 'ACTIVE' ? (
					<div>
						<div className="setup-progress">
							<svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
								<circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
								<path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
							</svg>
							<h4>{this.props.stepConfig.stepPassed.message}</h4>
						</div>
						<button className="btn btn-primary" onClick={nextPage}>
							Next
						</button>
					</div>
				) : (
					<div>
						<div className="setup-progress">
							<h4>This is exciting! We are setting up a "{this.props.stepConfig.stepPassed.desc}" instance just for you. This might take a couple of minutes.</h4>
						</div>
						<div className="loading-progress">
							<img src={`assets/img/loading.gif`} className="fa-spin" alt="Loading" />
							<div className="laoding-msg">
								{!this.state.isIE && <p>{tenantLifeCycle ? tenantLifeCycle.phase : 'Registration Initiated'} </p>}
								{this.state.isIE && <p>{this.state.isIE ? this.state.loadingMessage : 'Registration Initiated'}</p>}
							</div>
						</div>
					</div>
				)}
			</div>
		)
	}
}

export default withTranslation()(SetupTenant)
