import Joi from 'joi-browser'


const auditConfig = Joi.object().keys({
    id: Joi.number().integer().positive(),
    tenantId: Joi.string().uuid(),
    modelName :  Joi.string().required(),
    requestMethod :  Joi.string().required(),
    createdBy: Joi.string().allow(null),
    createdAt : Joi.date().allow(null),
    updatedBy: Joi.string().allow(null),
    updatedAt : Joi.date().allow(null),
    status: Joi.string().allow(null),
    deletedSeq :Joi.number().integer().allow(null),
})
// These are the schemas of the return object from the api server
export const methodSchemas = {
    getAll: Joi.array().items(auditConfig),
    getOne: auditConfig,
    addItem: auditConfig,
    deleteItem: Joi.object().keys({id: Joi.number().integer().positive()}),
    editItem: auditConfig
}
export const collectionSchemas = {
  //  notes: note
}
export const entitySchema = auditConfig
export const auditConfigSchema = auditConfig