import { EntityActions } from '../common/base/entity'
import { parentFields, childFields } from './LandingPage'
import { CSSLint } from 'csslint'
export class ContentAction extends EntityActions {

    postActionsHooks({actionName, item, tenantId }){
        switch (actionName) {
            case 'getOne':
                  item = Object.assign({}, item, item.config);
                  delete item.config;
                  return item
        
            default:
                return item
        }
    }
      
    preActionsHooks({actionName, item }){
        switch (actionName) {
            case 'editItem':
                let payload={
                    config:{}
                };
                parentFields &&  Object.keys(parentFields).forEach(key => {
                    console.log("keys", key)
                    payload[key] = item[key]
                });
                childFields &&  Object.keys(childFields).forEach(key => {
                    payload.config[key] = item[key]
                });
                payload.tenantId=item.tenantId;
                payload.status = "Published";
                payload.createdAt = new Date()
                payload.updatedAt = new Date()
                item = payload;
                let collectedMessages = {}; 
                let botStyleError = false  
                if (item.config.botStyle) {
                    let cssCode = item.config.botStyle;
                    let lintingResult = CSSLint.verify(cssCode);
                    lintingResult.messages.forEach((message) => {
                        if(message.type === "error" && message.message !== "@import not allowed here."){
                            if (message.message) {
                                botStyleError = true  
                                collectedMessages[message.type] = message.message;
                                console.error(`error:${collectedMessages.error}`);
                            }                     
                        }
                    });
                }
                if(botStyleError){
                    return {
                        error:{message : 'Please enter valid botStyle'}
                    }
                }
                return item;
            default:
                return item
        }
    }
}