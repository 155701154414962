import React from 'react'
import { withTranslation } from 'react-i18next'
import { AutoSizer, List } from 'react-virtualized'
// import { Card, CardTitle, CardBody } from 'reactstrap'
// import DataSet from "@antv/data-set";
import { filterMetricRow, getObjByPath } from '../../helpers/utils'
class ListDetail extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			listHeight: 300,
			listRowHeight: 50,
			overscanRowCount: 10,
			rowCount: 10,
			scrollToIndex: undefined,
			showScrollingPlaceholder: false,
			useDynamicRowHeight: false,
		}
		this.rowRenderer.bind(this)
	}

	componentWillReceiveProps(props) {
		let dependsOn = props.dependsOn
		let changed
		// let body = (this.state && this.state.body) || '-'
		if (dependsOn && Object.keys(dependsOn).length) {
			if (this.state && this.state.dependsOn)
				changed = Object.keys(dependsOn).some(k => {
					if (dependsOn[k] !== this.state.dependsOn[k]) return true
					return false
				})
			else changed = true
		} else {
			changed = true
		}
		let filteredData = this.processData(props)

		this.setState({ ...props, dependsOn, filteredData }, () => {
			changed && props.list.api && this.getValue()
		})
	}
	getValue() {
		let api = this.props.list.api
		if (this.props.list.api) {
			let targetProp = api.response && api.response.targetProp
			targetProp = targetProp || 'lists.' + this.props.list.name
			this.props.executeApi({ apiMeta: this.props.list.api, values: this.props.filterValues, targetProp, t: this.props.t, apiUrl: this.props.tenant && this.props.tenant.apiUrl })
		}
	}
	processData(props) {
		let data
		const { list } = props
		let targetProp
		if (list && list.api) {
			targetProp = list.api.response && list.api.response.targetProp
			targetProp = targetProp || 'lists.' + this.props.list.name
			data = props.dashboard[targetProp]
		} else data = props.data
		// let title = props.list && props.list.title

		const { dataProp } = props.list
		// const { dependsOn } = this.state
		const { filterValues } = props
		// let dv

		// let body
		let listData
		let filteredData
		// const metric = metrics[0]

		if (data) {
			if (dataProp) {
				if (dataProp === 'data') listData = data
				else listData = getObjByPath({ ref: data, path: dataProp })
			} else listData = data
			filteredData = filterMetricRow({ data: listData, filters: list.filters })
			// console.log(`Filtered: ${JSON.stringify(filteredData)}`) // static filters

			if (list.dynFilters) {
				let filters = []
				Object.keys(list.dynFilters).forEach(field => {
					let filter = list.dynFilters[field]
					let filterObj = {}
					if (typeof filter === 'object') {
						// @ts-ignore
						filterObj = { field }
						if (filter.values) filterObj.values = filter.values.map(v => filterValues[v] || v)
						if (filter.value) filterObj.value = filterValues[filter.value] || filter.value
						filterObj.operator = filter.operator
						filters.push(filterObj)
					} else {
						// @ts-ignore
						filterObj = { field, value: filterValues[filter] || filter }
						filters.push(filterObj)
					}
				})
				filteredData = filterMetricRow({ data: filteredData, filters }) // dynamic filters
			}
			// console.log(`Dyn Filtered: ${JSON.stringify(filteredData)}`)
		}
		return filteredData || listData
	}
	rowRenderer({ key, index, isScrolling, isVisible, parent, style }) {
		const { list } = this.props
		const { className } = list
		const data = this.state && this.state.filteredData

		return (
			<div key={key} style={style} className={className}>
				{data && data[index].metricName + data[index].metric}
			</div>
		)
	}
	render() {
		const { list } = this.props
		const { title } = list
		const data = this.state && this.state.filteredData
		return (
			<div className={'shadow my-2'}>
				<h5 className="text-left">
					{' '}
					{title || 'List Data'} {data && data.length}{' '}
				</h5>
				<hr />
				<AutoSizer disableHeight>
					{({ width }) => (
						<List
							className={list.classes || ''}
							height={list.height}
							width={width}
							rowCount={(data && data.length) || 10}
							rowHeight={list.rowHeight || 20}
							rowRenderer={this.rowRenderer.bind(this)}
						/>
					)}
				</AutoSizer>
			</div>
		)
	}
}
export default withTranslation()(ListDetail)
