import { AtomicBlockUtils } from 'draft-js';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
    Alert, EditorIcon,

    EditorLoader, EditorUploadTab,



    UploadFileInput, UploadUrlButton,
    UploadUrlInput
} from './static';

// Upload Component for Text Editor
class Upload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            uploadError: null,
            iconConfig: { toggle: null, icon: 'fa fa-file' },
        }
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }


    toggle() {
        const { modal } = this.state
        this.setState({
            modal: !modal
        })
    }

    setCustomOptions() {
        const { iconConfig, uploadTabConfig } = this.state
        const { tab, icon } = this.props
        this.setState({
            iconConfig: {
                ...iconConfig,
                icon,
                toggle: this.toggle.bind(this)
            }
        })
    }
    componentDidMount() {
        this.setCustomOptions()
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }
    setWrapperRef(node) {
        this.wrapperRef = node;
    }
    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target))
            this.setState({ modal: false })
    }
    render() {
        const { modal, uploadError, iconConfig } = this.state
        return (<div className="rdw-image-wrapper" ref={this.setWrapperRef}>
            <EditorIcon
                {...iconConfig}
            />
            {modal && <UploadModal
                {...this.props}
                mapUrl={this.mapUrl}
                uploadError={uploadError}
                toggle={this.toggle.bind(this)}
            />
            }
        </div>

        );
    }
}
Upload.propTypes = {
    type: PropTypes.string.isRequired,
    renderComponent: PropTypes.string.isRequired,
    tab: PropTypes.shape({
        upload: PropTypes.bool.isRequired,
        url: PropTypes.bool.isRequired
    }),
    icon: PropTypes.string.isRequired,
    maxUploadSize: PropTypes.number.isRequired,
    upload: PropTypes.bool,
    uploadCallback: PropTypes.func.isRequired,
    editorState: PropTypes.object,
    allowed: PropTypes.array.isRequired,
    customFileConfig:PropTypes.object,
    ignoreExtnValidation:PropTypes.string,
};
// Upload Component for Text Editor






// Upload Modal for Upload Component
class UploadModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            errorMsg: false,
            modal: { url: false, upload: false },
            inputUrl: '',
            btndisabled: false,
            uploadTabConfig: { tab: { upload: false, url: false }, switchTab: false, toggleTab: null },
            customInput:{}
        }
    }
    componentWillMount() {
        const { tab } = this.props
        const { uploadTabConfig, modal } = this.state
        if (tab) {
            this.setState({
                uploadTabConfig: {
                    ...uploadTabConfig,
                    tab,
                    switchTab: tab.upload
                },
                modal:{
                    ...modal,
                    url: !tab.upload && tab.url ? true : false ,
                    upload: tab.upload ? true : false 
                }
            })
        }
    }

    toggleTab(toggle) {
        const { uploadTabConfig, modal } = this.state
        this.setState({
            uploadTabConfig: {
                ...uploadTabConfig,
                switchTab: toggle
            },
            modal:{
                ...modal,
                url: !toggle ? true : false,
                upload: toggle ? true : false

            }
        })

    }

    // Creating Custom Entity for Editor using Drafjs and textEditor State :: both Input Url and File Upload
    createEntity = ({entityObj}) => {
        const { type } = this.props
        const { editorState, onChange } = this.props;
        if(type && entityObj && typeof entityObj === 'object' && Object.keys(entityObj).length > 0){
            const entityKey = editorState
                .getCurrentContent()
                .createEntity(`${type}`, 'MUTABLE', entityObj)
                .getLastCreatedEntityKey();

            const newEditorState = AtomicBlockUtils.insertAtomicBlock(
                editorState,
                entityKey,
                ' '
            );
            onChange(newEditorState);
        }else{
            this.setState({errorMsg:`Entity Type is missing from config!`})
        }
    }
   // Creating Custom Entity for Editor using Drafjs and textEditor State :: both Input Url and File Upload




 //Validation for file upload :: Upload File  
   validateFileSize = (size) =>{
        const { maxUploadSize } = this.props
        let maxFileSize = maxUploadSize || 2 //in MB
        if(maxFileSize && size){
            let fileSize = parseFloat(size / 1024 / 1024);
            if(fileSize > maxFileSize){
                this.setState({
                    errorMsg: `File Size must be less than ${maxFileSize}MB`,
                    loading: false
                })
                return false
            }
            else return true
        }
        else {
            this.setState({
                errorMsg: `File size is Missing !`,
                loading: false
            })
            return false
        }
        
    }

    validateFileType = (file) =>{
        const {allowed} = this.props
        if(file && file.name && allowed && Array.isArray(allowed) && allowed.length > 0){
            let splitExt = file.name.substr(file.name.lastIndexOf('.')+1)
            if(splitExt && allowed.indexOf(splitExt) == -1){
                this.setState({
                    errorMsg: `Please Upload only ${allowed.join(',')} extension File!`,
                    loading: false
                })
                return false
            } else return true
        }
        else{
            this.setState({
                errorMsg: `File type or allowed type is Missing!`,
                loading: false
            })
            return false
        }


    }
 //Validation for file upload :: Upload File  


 // Upload File and create Editor Instance for file upload :: Upload File

 onCustomInputChange = (value) =>{
    const {customInput} = this.state
     this.setState({
         ...customInput,
         customInput:value
     })
 }

    async onChange(file) {
        const {customInput}=this.state
        const {type}=this.props
        this.setState({ loading: true })
        if (file && file.size && file.type && this.validateFileSize(file.size) && this.validateFileType(file)) {
                let uploadConfig = {customInput, type:type ? type.toLowerCase() : null, access:'private'}
                let upload = await this.props.uploadCallback(file,uploadConfig)
                if (upload && upload.data) {
                    let entityObj = upload.data
                    this.createEntity({entityObj})
                    this.setState({ loading: false, errorMsg:false })
                    this.props.toggle()
                }
                else{
                    this.setState({errorMsg:`Error while uploading!`})
                }
        }

    }
 // Upload File and create Editor Instance for file upload :: Upload File



//Add Input Text Url to Editor Instance
    addInputUrl() {
        const { inputUrl } = this.state
        this.setState({ btndisabled: true })
        if(inputUrl && this.validateUrlInput(inputUrl)){
                let entityObj = {link:inputUrl}
                this.createEntity({entityObj})
                this.props.toggle()
            }
        
    
    }

    inputChange(value){
        this.setState({inputUrl:value})
    }
// Add Input Text Url to Editor Instance

    

// Validation for URL Input of File   :: Input Text Url 
    validateUrlInput(inputUrl){
        if(this.isValidUrl(inputUrl)){
        if (this.isHttpsURL(inputUrl) && (this.props.ignoreExtnValidation || this.isValidExtenstion(inputUrl)))
            return true
        else
            return false
        }else {
            this.setState({errorMsg:`Invalid URL`,btndisabled:false})
            return false
        }
    }


    isHttpsURL(inputUrl){
        let pattern = new RegExp('^(https:)')
        let checkHttps = pattern.test(inputUrl)
        if(checkHttps){ this.setState({errorMsg:false,btndisabled:true}); return true}
        else{ this.setState({errorMsg:`Please use a URL which starts with 'https'`,btndisabled:false}); return false}
    }

    isValidExtenstion(inputUrl){
        const {allowed}=this.props
        if (inputUrl) {
            let splitExt = inputUrl.substr(inputUrl.lastIndexOf('.')+1)
            if(splitExt && allowed && Array.isArray(allowed) && allowed.length > 0  && allowed.indexOf(splitExt)==-1){
               this.setState({errorMsg:`Invalid ${allowed.join(',')} Extension Upload URL`,btndisabled:true}); return false
            }
            else return true

        }
        else return false
    }

    isValidUrl(inputUrl){
        try {
            new URL(inputUrl);
            return true;
        } catch (_) {
            return false;
        }
    }
// Validation for URL Input of File   :: Input Text Url  

    


    
    render() {
        const { loading, errorMsg, modal, inputUrl, btndisabled, uploadTabConfig } = this.state
        const {inputAccept}=this.props
        return (
            <div className="rdw-image-modal">
                <EditorUploadTab
                    {...uploadTabConfig}
                    toggleTab={this.toggleTab.bind(this)}
                />

                <div>
                    <Alert
                        msg={errorMsg}
                    />
                    <UploadFileInput
                        upload={modal.upload}
                        inputAccept={inputAccept || '*'}
                        onChange={this.onChange.bind(this)}
                        customFileConfig={this.props.customFileConfig}
                        onCustomInputChange={this.onCustomInputChange.bind(this)}
                    />
                    <UploadUrlInput
                        url={modal.url}
                        inputUrl={inputUrl}
                        inputChange={this.inputChange.bind(this)}
                    />

                </div>
                <div>&nbsp;</div>
                <UploadUrlButton
                    url={modal.url}
                    disabled={btndisabled}
                    addInputUrl={this.addInputUrl.bind(this)}
                    toggle={this.props.toggle}
                />
                <EditorLoader loading={loading} />

            </div>

        )
    }
}
UploadModal.propTypes = {

    type: PropTypes.string.isRequired,
    tab: PropTypes.shape({
        upload: PropTypes.bool.isRequired,
        url: PropTypes.bool.isRequired
    }),
    toggle: PropTypes.func.isRequired,
    maxUploadSize: PropTypes.number.isRequired,
    allowed: PropTypes.array.isRequired,
    uploadCallback: PropTypes.func.isRequired,
    editorState: PropTypes.object,
    customFileConfig:PropTypes.object,
    ignoreExtnValidation:PropTypes.string
};
// Upload Modal for Upload Component



export default Upload;