import React, { useEffect, useState } from 'react';
import { FormGroup, Label, Table } from 'reactstrap';
import { MapInput, SelectInput } from './formInput';
import * as helper from './helper';


// Json List Component for simple json Array 
const JsonObjComponent = (props) => {
    const [ setPropList] = useState({})
    const [ setIsProps] = useState(false);

    useEffect(() => {
        setIsProps(helper.isProps(props))
        setPropList(props)
    }
    )

    // const { childProps } = props;

    const saveToParent = () => {
        props.handleInputSubmit()
    }
    const changeToParent = (inputValue) => {
        if (inputValue)
            props.handleInputChange(props.name, inputValue)
    }

    const switchAddComponent = () => {
        return (
            <AddComponent
                {...props}
                changeToParent={changeToParent}
                saveToParent={saveToParent}
            />
        )
    }


    return (
        <div className="row">
            <div className="col-sm-11">

                {switchAddComponent()}


            </div>
            <div className="col-sm-1 listbtn">
                <div onClick={props.handleInputSubmit} className="listbtn success"><i className="fa fa-plus"></i></div>
            </div>
        </div>


    )


}




const AddComponent = (props) => {
    const [propList, setPropList] = useState({})
    const [formKeyValue, setFormKeyValue] = useState('');
    const [state, setState] = useState({
        keyName: '',
        inputValue: '',
        filteredProps: '',
        toggleForm: false,
        inputFormKey: null,
        keyValuePair: null,
    })

    useEffect(() => { setPropList(props) }, [props])

    const inputChange = (inputKey, inputName) => {
        const { childProps: { options } } = propList;
        let filteredProps = helper.filterSelectKey(inputKey, options)
        if (filteredProps && filteredProps != undefined) {
            let inpKey = { ...state.inputFormKey, [inputKey]: '' }
            setState({ inputValue: inputKey, filteredProps: filteredProps, toggleForm: true, inputFormKey: inpKey })
        }
        else {
            setState({ toggleForm: false })
        }
        //props.handleInputChange(inputName,inputValue)

    }

    const handleInputChange = (formKeyValue) => {
        let inputFormKey = { ...formKeyValue }
        setFormKeyValue(inputFormKey)
        props.changeToParent(inputFormKey)
    }


    const handleKeyDown = () => {
        props.saveToParent()
        setState({
            keyName: '',
            inputValue: '',
            filteredProps: '',
            toggleForm: false,
            inputFormKey: null,
        });
        setFormKeyValue({})
    }

    const { inputValue, toggleForm, filteredProps, inputFormKey } = state

    return (
        <>
            {/* {props.preTag(state)} */}
            <SelectInput
                {...propList.childProps}
                handleChange={inputChange}
                value={inputValue}
                handleEnter={handleKeyDown}

            />

            {toggleForm &&
                <InputForm
                    {...filteredProps}
                    isProps={propList.isProps}
                    selectedKey={inputValue}
                    preTag={props.preTag}
                    inputFormKey={formKeyValue || inputFormKey}
                    handleChange={handleInputChange}
                    handleEnter={handleKeyDown}

                />

            }
        </>
    )
}






const InputForm = (props) => {
    const [inputValue, setInputValue] = useState({ keyPair: {} });
    const [state, setState] = useState({ selectedKey: props.selectedKey, inputType: null, inputList: false, inputSelected: '' })

    useEffect(() => {
        const { keyPair } = inputValue;
        let pairing = { ...keyPair, ...props.inputFormKey }
        setInputValue({ ...inputValue, keyPair: pairing })
        setState({ ...state, selectedKey: props.selectedKey })


    }, [props])

    useEffect(() => {
        checkInputType(props)
    }, [props.type])

    const checkInputType = (props) => {
        if (props.type && Array.isArray(props.type)) {
            let setOptions = { options: props.type, name: 'mapInputType' };
            setState({ ...state, inputList: setOptions, inputType: null })
        }
        else { setState({ ...state, inputType: props.type, inputList: false }) }
    }

    const inputChange = (outPutValue, outPutName) => {
        let InputFormKeyValue = { ...inputValue, keyPair: { ...inputValue.keyPair, [outPutName]: outPutValue } }
        props.handleChange(InputFormKeyValue.keyPair)
        setInputValue(InputFormKeyValue)
    }

const componentChange=(outPutName,outPutValue)=>{
    let InputFormKeyValue = { ...inputValue, keyPair: { ...inputValue.keyPair, [outPutName]: outPutValue } }
    props.handleChange(InputFormKeyValue.keyPair)
    setInputValue(InputFormKeyValue)

}
    const handleInputSubmit = () => {
        props.handleEnter()
    }
    const inputSelectChange = (value, name) => {
        setState({ ...state, inputType: value, inputSelected: value })
    }

    const { selectedKey, inputType, inputList, inputSelected } = state;
    const { keyPair } = inputValue;
    return (
        <div className="row">
            <div className="col-sm-12 ">
                <FormGroup className="bgwhite border">
                    {inputList &&
                        <>
                            <Label>Value Type </Label>
                            <SelectInput
                                {...inputList}
                                type={'select'}
                                handleChange={inputSelectChange}
                                value={inputSelected}

                            />
                        </>
                    }
                    {inputType && <Label>{props.label || props.name}</Label>
                    }
                    {inputType && <MapInput
                        {...props}
                        type={inputType}
                        handleChange={inputChange}
                        value={keyPair[selectedKey]}
                        placeholder={'Edit'}
                        handleEnter={handleInputSubmit}
                        handleInputSubmit={handleInputSubmit}
                        onComponentChange={componentChange}
                    />
                    }
                </FormGroup>
            </div>
        </div>
    )

}

const JsonObjListingComponent = (props) => {
    const [propList, setPropList] = useState({})
    const [ state, setState] = useState({ toggleEdit: false, editValue: props.value || '', previousValue: '', id: null })
console.log(state)
    useEffect(() => {
        setState({ editValue: props.value, previousValue: props.value })
        setPropList(props)
    }, [props]
    )

    // const remove = (idx) => {
    //     props.remove(idx)
    // }
    // const editItem = (idx, item) => {
    //     setState({ ...state, toggleEdit: true, id: idx, editValue: props.value[idx] })
    // }

    // const inputChange = (inputValue, inputName) => {
    //     const { id } = state;
    //     setState({ ...state, editValue: inputValue })
    // }

    const handleInputUpdate = (updatedValue, indexId, type) => {
        const { value } = propList;
        let updateValue = null;
        if (type && type === 'array') {
            if (helper.isObject(updatedValue) && Object.keys(updatedValue).length === 0) {
                updateValue = value.filter((x, idx) => idx !== indexId)
            }
            else { value[indexId] = updatedValue; updateValue = value; }

        }
        else if (type && type === 'object') { updateValue = updatedValue }
        else { updateValue = updatedValue }



        props.handleInputUpdate(updateValue)
    }


    // const value=[
    //     {
    //       label: "newlabel",
    //       value: "newvalue",
    //       synonyms: "newsynonyms",
    //       send: "newsend"
    //     }
    //   ]

    const { value } = propList;
    // const { toggleEdit, id, editValue } = state;
    return (
        <div className="row">
            <div className="col-sm-12">
                {value && Array.isArray(value) && value.length > 0 &&
                    value.map((item, idx) => {
                        return (
                            <CreateList
                                key={idx}
                                item={item}
                                id={idx}
                                type="array"
                                handleInputUpdate={handleInputUpdate}
                            />
                        )
                    })}
                {value && helper.isObject(value) &&
                    <CreateList
                        key={1}
                        item={value}
                        id={1}
                        type="object"
                        handleInputUpdate={handleInputUpdate}

                    />
                }
            </div>

        </div>


    )


}


const CreateList = (props) => {
    const [listItem, setListItem] = useState(props.item || '')
    const [state, setState] = useState({ id: -1, editKey: '', editValue: '', toggle: false, type: props.type })
    useEffect(() => {
        setListItem(props.item)
        setState({ ...state, type: props.type })
    }, [props])

    const toggleEdit = (index, key, value) => {
        setState({ ...state, id: index, editKey: key, editValue: value, toggle: true })
    }
    const editChange = (value, name) => {
        setState({ ...state, editValue: value })

    }
    const editChangeSubmit = () => {
        const {  editKey, editValue, type } = state;
        let updatedValue = { ...listItem, [editKey]: editValue }
        setState({ ...state, toggle: false, id: -1, editKey: '', editValue: '' });
        props.handleInputUpdate(updatedValue, props.id, type)

    }
    const remove = (key) => {
        const { type } = state;
        delete listItem[key]
        props.handleInputUpdate(listItem, props.id, type)
    }
    const { toggle, editKey, id, editValue } = state
    return (
        <Table responsive bordered className="bgWhite" size="sm">
            <tbody>
                {listItem && helper.isObject(listItem) && Object.keys(listItem).map((key, index) => {
                    return (
                        <tr key={index}>
                            <td>
                                <div className="row">
                                    <div className="col-sm-3 listlabel">
                                        {key}
                                    </div>
                                    <div className="col-sm-9 listlabel manageInput">
                                        {!toggle && helper.isObject(listItem[key]) ?
                                            <CreateList item={listItem[key]} handleInputUpdate={editChangeSubmit} key={index} id={index} /> :
                                            Array.isArray(listItem[key]) ? <CreateList handleInputUpdate={editChangeSubmit} item={listItem[key]} key={index} id={index} />
                                                :
                                                id !== index &&
                                                <>
                                                    <span style={{ cursor: 'pointer' }} onClick={toggleEdit.bind(this, index, key, listItem[key])}>{listItem[key] && listItem[key].toString()}</span>
                                                    &nbsp; <span className="listbtn danger" onClick={remove.bind(this, key)}><i className="fa fa-trash"></i></span>
                                                </>
                                        }
                                        {toggle && id === index &&
                                            <MapInput
                                                name={editKey}
                                                style={{ padding: '3px 4px 3px!important' }}
                                                size="sm"
                                                id={id}
                                                type={'text'}
                                                value={editValue}
                                                handleEnter={editChangeSubmit}
                                                handleChange={editChange}
                                            />
                                        }

                                    </div>
                                </div>
                            </td>
                        </tr>

                    )
                })

                }
                {listItem && Array.isArray(listItem) && listItem.length > 0 && listItem.map((item, index) => {
                    return (
                        <tr key={index}>
                            <td>
                                <div className="row">
                                    <div className="col-sm-12">
                                        {item}
                                    </div>
                                </div>
                            </td>
                        </tr>

                    )
                })
                }
            </tbody>
        </Table>
    )






}


export {
    JsonObjComponent,
    AddComponent,
    JsonObjListingComponent
};

