/**
 * RU on all settings including whitelisting
 * This is in common as all apps would need it
 * Setting Key value pairs come from db of the app.
 */
import { Entity } from "../common/base/entity/entity";
// import {EntityComponent} from '../entity'
import {
  collectionSchemas,
  entitySchema,
  methodSchemas,
} from "./customer.schema";
import { CustomerAction } from "./customerAction";

class customerEntity extends Entity {}

const fieldsMeta = {
  customerName: {
    label: "Name",
    required: true,
    type: "text",
    name: "customerName",
    placeholder: "Customer Name",
  },
  customerNameUpdate: {
    label: "Name",
    required: true,
    type: "text",
    name: "customerName",
    placeholder: "Customer Name",
    disabled: true,
  },
  contactDetails: {
    label: "Contact Details",
    required: true,
    name: "contactDetails",
    keyLabel: "Key Property",
    valueLabel: "Value Property",
    type: "jsonObj",
  },
  emailDomain: {
    label: "Email Domain",
    name: "emailDomain",
    type: "jsonText",
    required: true,
    // regexPattern:
    //   /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/, // need to change
    error: "Email Domain is not valid",
    width: "100%",
  },
  aadTenantId: {
    label: "AAD Tenant ID",
    type: "text",
    name: "aadTenantId",
    placeholder: "Enter Your AAD Tenant ID",
  },
  aadTenantIdReadOnly: {
    label: "AAD Tenant ID",
    // required: true,
    type: "text",
    name: "aadTenantId",
    placeholder: "Enter Your AAD Tenant ID",
  },
  customerSince: {
    label: "Customer Since",
    required: true,
    type: "date",
    name: "customerSince",
    placeholder: "Select Customer Since",
  },
  assignedAgent: {
    label: "Assigned Team Members",
    required: true,
    name: "assignedAgent",
    placeholder: "Select Your Assigned Team Members",
    type: "select",
    isMulti: true,
    dynamicOptions: true,
    api: {
      path: "User/getUsersByRole/ticket_agent,chat_agent,ticket_agent_supervisor,chat_agent_supervisor,bot_admin,bot_analyst",
      method: "GET",
      response: {
        ref: "data",
        labelKey: "username",
        valueKey: "username",
      },
      filters: [
        {
          field: "status",
          operator: "is",
          value: "Active",
        },
      ],
    },
  },
  status: {
    label: "Status",
    name: "status",
    options: [
      { label: "Published", value: "Published" },
      { label: "Draft", value: "Draft" },
    ],
    type: "select",
    required: true,
    default: "Published",
  },
  createdBy: {
    label: "Created By",
    name: "createdBy",
    required: true,
    type: "text",
  },
  createdAt: {
    label: "Created At",
    name: "createdAt",
    required: true,
    type: "text",
  },
  updatedBy: {
    label: "Last Edited By",
    name: "updatedBy",
    required: true,
    type: "text",
  },
  updatedAt: {
    label: "Last Edited At",
    name: "updatedAt",
    required: true,
    type: "datetime-local",
  },
};

const actionsMeta = {
  doSomething: {
    type: "button",
    label: "Update",
    action: "editItem",
    name: "doSomething",
  },
  editMap: {
    label: "Edit Map",
    name: "editMap",
    action: "showMap",
    condition: 'typeof value === "object"',
  },
  save: {
    label: "Save",
    name: "save",
    action: "save",
  },
  cancel: {
    label: "Cancel",
    name: "cancel",
    action: "cancelEdit",
  },
  update: {
    label: "Update",
    name: "update",
    action: "upsertItem",
    classes: "fa fa-pencil fa-lg ",
    type: "span",
  },
  list: {
    label: "List All",
    name: "list",
    action: "load",
  },
  search: {
    label: "Search",
    name: "search",
    action: "search",
  },
  view: {
    label: "View",
    name: "view",
    action: "getItem",
    classes: "fa fa-eye fa-lg",
    type: "span",
  },
  create: {
    label: "Create",
    name: "create",
    type: "button",
    action: "addNewItem",
  },
  createItem: {
    label: "Create",
    action: "upsertItem",
    name: "create",
    type: "button",
  },
};
const sectionsMeta = {
  general: {
    items: [
      { name: "status", type: "field" },
      { name: "updatedBy", type: "field" },
      { name: "updatedAt", type: "field" },
    ],
  },
  basic: {
    title: "Queue Details",
    items: [
      { name: "customerName", type: "field" },
      { name: "contactDetails", type: "field" },
      { name: "emailDomain", type: "field" },
      { name: "aadTenantId", type: "field" },
      { name: "customerSince", type: "field" },
      { name: "assignedAgent", type: "field" },
      { name: "status", type: "field" },
    ],
    classes: "mspCustomer-form user-detail",
  },
  edit: {
    title: "Queue Details",
    items: [
      { name: "customerNameUpdate", type: "field" },
      { name: "contactDetails", type: "field" },
      { name: "emailDomain", type: "field" },
      { name: "aadTenantId", type: "field" },
      { name: "customerSince", type: "field" },
      { name: "assignedAgent", type: "field" },
      { name: "status", type: "field" },
    ],
    classes: "mspCustomer-form user-detail",
  },
  headerComponent: {
    component: "DetailHeader",
  },
  editForm: {
    cols: [7, 5],
    items: [
      { name: "edit", type: "section", col: 1 },
      { name: "update", type: "action", col: 1 },
    ],
    classes: "composite-inner-form",
  },
  createForm: {
    cols: [7, 5],
    items: [
      { name: "basic", type: "section", col: 1 },
      { name: "createItem", type: "action", col: 1 },
    ],
    classes: "composite-inner-form",
  },
};

const screens = {
  view: {
    items: [
      { name: "headerComponent", type: "section" },
      { name: "editForm", type: "section" },
    ],
    title: "MSP Customer Details",
  },
  create: {
    items: [
      { name: "headerComponent", type: "section" },
      { name: "createForm", type: "section" },
    ],
    title: "Create MSP Customer",
  },
  edit: {
    items: [
      { name: "headerComponent", type: "section" },
      { name: "editForm", type: "section" },
    ],
    title: "Edit/Update MSP Customer",
  },
  list: {
    items: [
      { name: "customerName", type: "link" },
      { name: "emailDomain", type: "field" },
      { name: "aadTenantId", type: "field" },
      { name: "customerSince", type: "field" },
      { name: "status", type: "field" },
    ],
    filter: [
      {
        name: "status",
        operator: "ne",
        value: "Deleted",
      },
    ],
    multiselect: false,
  },
  search: {
    items: [
      { name: "customerName", type: "link" },
    ],
  },
};
// @ts-ignore
const customerMSP = new customerEntity({
  ActionClass: CustomerAction,
  fieldsMeta,
  actionsMeta,
  sectionsMeta,
  screens,
  name: "customerMSP",
  title: "MSP Customer",
  methodSchemas,
  collectionSchemas,
  entitySchema,
  idKey: "id",
  entityUrlPaths: {
    update: "botmgmt-service/CustomerMSP/update",
    getAll: "botmgmt-service/CustomerMSP",
    default: "botmgmt-service/CustomerMSP",
  },
  fetchDetailByApi: false,
});

const CustomerListSearchContainer = customerMSP.getContainer("ListSearch");
const CustomerCreateContainer = customerMSP.getContainer("Create");
const CustomerDetailContainer = customerMSP.getContainer("Detail");
export {
  CustomerCreateContainer,
  CustomerDetailContainer,
  CustomerListSearchContainer,
};
