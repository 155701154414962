import React, { Component } from 'react';
// import { Input, Label } from 'reactstrap';
import { Switch, Box, FormControl, FormLabel, Grid, Input, InputGroup, ButtonGroup, IconButton, SimpleGrid, VStack } from "@chakra-ui/react"
import { AddIcon } from '@chakra-ui/icons'
import { toast } from 'react-toastify'
import {withTranslation} from 'react-i18next'
class CustomJsonClone extends Component{

    constructor(props){
        //console.log("props:: ", props)
        super(props);
       this.state = {
           field: props.field,
           key: '',
           value: '',
           hirarchyItems: [{key: '', value: '', level: 1}],
           lastLevelEnabled: false,
           loadedData: false,
       }


    }


    componentDidUpdate() {
        let { formValues, field } = this.props
        let { hirarchyItems, loadedData, lastLevelEnabled } = this.state
        if(!loadedData && field && formValues && formValues[field.name]) {
            //console.log("formValues[field.name]::: ", formValues[field.name])
            hirarchyItems = formValues[field.name]

            if(hirarchyItems && hirarchyItems[0]) {

                // if(hirerachy && hirarchyItems[hirarchyItems.length-1] && hirarchyItems[hirarchyItems.length-1].values && hirarchyItems[hirarchyItems.length-1].values.length > 1) {
                //     lastLevelEnabled = !lastLevelEnabled
                // }

            }

            this.setState({hirarchyItems, loadedData: !loadedData, lastLevelEnabled})
        }
    }


    handleAddItem(name){
    if(this.state.key && this.state.value)
     this.props.handleAddItem({name, key: this.state.key, value: this.state.value});
     
     this.setState({
         key: '',
         value: ''
     })
    }

    addHirerachyItem(index) {
        let { hirarchyItems, field } = this.state
        if(field.max && ((index + 1) >= field.max)){
            return toast.error(`Max limit is ${field.max}`)
        }

        //console.log("addHirerachyItem:: ", hirarchyItems)

        let level = (index == 0) ? 2 : index + 2;

        
        hirarchyItems.push({key: '', value: '', level})

        
       // console.log(`Add hirearchy item:: ${field.name}:: ${hirarchyItems}`);
        this.props.handleCustomJsonList(field.name, hirarchyItems)

        this.setState({hirarchyItems});
    } 

    getParentValue(i) {

    }

    removeHirerachyItem(index) {
        let { hirarchyItems, field} = this.state
        if(field.min && ((index + 1) <= field.min)){
            return toast.error(`Minimum limit is ${field.min}`)
        }

        hirarchyItems.pop();

        this.setState({ hirarchyItems });
        return true;
    }

    updateParentData(value, name, i) {
        let { hirarchyItems, field } = this.state

        hirarchyItems[i][name] = value

        this.setState({hirarchyItems})

        this.props.handleCustomJsonList(field.name, hirarchyItems)
    }

    updateValues(value, name, parentIndex) {
        let { hirarchyItems, field } = this.state
        if(field.min && !value){
            let emptyValues = hirarchyItems.filter(ht => ht.value !== "")
            if(emptyValues.length && ((emptyValues.length) <= field.min)){
              return toast.error(this.props.t(`Minimum limit is {{value}}`, {value: field.min}))
            }
         }
        hirarchyItems[parentIndex][name] = value

        this.setState({hirarchyItems})

        this.props.handleCustomJsonList(field.name, hirarchyItems)
    }


    render(){
        const { field, hirarchyItems, lastLevelEnabled } = this.state
        return (
            <VStack>
                <Box>
                    <FormControl display="flex" alignItems="center">
                        <FormLabel>{field.label}</FormLabel>
                    </FormControl>
                </Box>
                {hirarchyItems && hirarchyItems.map((elem, index) => 
                <Grid key={index} templateColumns="repeat(3, 1fr)" gap={2}>
                    <Box>    
                        <FormControl display="flex" alignItems="left">
                            <InputGroup size="sm">
                                <Input
                                    name={`key`}
                                    placeholder="Key"
                                    value={elem.key}
                                    onChange={(e) => this.updateParentData(e.target.value, 'key', index)}
                                    disabled={elem.disableKey}
                                >
                                </Input>
                                <Input
                                            name={`${field.name}_key`}
                                            placeholder="Value"
                                            value={elem.value}
                                            onChange={(e) => this.updateValues(e.target.value, 'value', index)}
                                        >
                                        </Input>
                            </InputGroup>
                            {/* {!hirerachy && <span onClick={this.handleAddItem.bind(this, field.name)}><i className="fa fa-plus"></i></span>} */}

                            
                        </FormControl>

                    </Box>                       
                </Grid>
                )}
                
                
            </VStack>
        )
    }
}

export default withTranslation()(CustomJsonClone)