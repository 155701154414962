import { history } from '../../redux/history'
import { alertActions } from '../common'
import { EntityActions } from '../common/base/entity'

String.prototype.toCamelCase = function (cap1st) {
	'use strict'
	return ((cap1st ? '-' : '') + this).replace(/-+([^-])/g, function (a, b) {
		return b.toUpperCase()
	})
}
function isCamelCase(str) {
	'use strict'

	var strArr = str.split('')
	var string = ''
	for (var i in strArr) {
		if (strArr[i].toUpperCase() === strArr[i]) {
			string += '-' + strArr[i].toLowerCase()
		} else {
			string += strArr[i]
		}
	}

	if (string.toCamelCase(true) === str) {
		return true
	} else {
		return false
	}
}
export class ContentAction extends EntityActions {
	postActionsHooks({ actionName, item, tenantId }) {
		switch (actionName) {
			case 'getOne':
				let obj = item
				if (obj) {
					obj.enabled = obj.enabled ? 'true' : 'false'
					obj.username = obj.username || obj.email
					obj.profilePic = obj.attributes && Object.keys(obj.attributes).length > 0 && obj.attributes.profile_pic
					if (obj.attributes && obj.attributes.profile_pic) {
						delete obj.attributes.profile_pic
					}
				}
				return item
			case 'getAll':
				let items = item
				if (items) {
					items.map(d => {
						d.enabled1 = d.enabled ? 'Active' : 'Inactive'
						d.username = d.username || d.email
						if (d.attributes && d.attributes.profilePic) {
							delete d.attributes.profilePic
						}
						d.roles = d.roles && d.roles.filter(r => r.indexOf('_') == -1 && isCamelCase(r))
						return d
					})
				}
				return items

			default:
				return item
		}
	}

	preActionsHooks({ actionName, item}) {
		let obj = item
		switch (actionName) {
			case 'editItem':
				if (obj) {
					if(obj.enabled !== true)
					// obj.enabled = obj.enabled == 'true' ? true : false
					obj.username = obj.username || obj.email	
					if(obj.profilePic){
						if(obj.attributes && Object.keys(obj.attributes).length > 0){
						 obj.attributes = {
						  ...obj.attributes,
						  profile_pic: obj.profilePic
						 }
						}else {
						 obj.attributes = {
							profile_pic: obj.profilePic
						 }
						}
					   }
					   delete obj.profilePic
				}
				return item
			case 'addNewItem':
				if (obj) {
					//obj.enabled = obj.enabled == 'true' ? true : false
					obj.username = obj.username || obj.email
					if(!obj.roles && obj.email && obj.firstName && obj.lastName){
						obj.error={message:'Role is Required'}
					}else{
			         delete obj.error
					}
					
				}
				return item
			case 'importItems':
				if (!item || item.length == 0) return
				let items = item.map(i => {
					let obj = i
					obj.roles = (i.roles && i.roles.split(',')) || []
					return obj
				})
				return items
			default:
				return item
		}
	}
	importItems({ items, tenantId }) {
		if(items && items.pop){
			items = items.map(item => {
				// let attributes = {}
				let str = item && item.attributes
				if(str){
					item.attributes = str 
				}
				return item
			})
		}
		let that = this
		return dispatch => {
			dispatch(that.request({ method: 'IMPORT' }))
			if (that.preActionsHooks)
				items = that.preActionsHooks({
					actionName: 'importItems',
					item: items,
					propFields: that.propFields,
				})

			that.service
				.importItems({ items, tenantId })
				.then((items, errors) => {
					if (items && items.message) {
						let errorMsg = items.message
						dispatch(that.failure({ method: 'IMPORT', errorMsg, errors }))
						dispatch(alertActions.error(errorMsg))
					} else if (items) {
						if (that.postActionsHooks)
							items = that.postActionsHooks({
								actionName: 'importItems',
								item: items,
							})

						dispatch(that.success({ method: 'IMPORT', items, errors }))
						dispatch(alertActions.success(that.resourceName + ' added successfully'))
						setTimeout(() => history.goBack(), 2000)
						// history.push('/user-management')
					} else {
						let errorMsg = 'Could not import ' + that.resourceName + ' at this moment please try again later'
						dispatch(that.failure({ method: 'IMPORT', errorMsg, errors }))
						dispatch(alertActions.error(errorMsg))
					}
				})
				.catch(error => {
					let errorMsg = `Could not import ${that.resourceName} at this moment. ${(error && error.message) || error || 'Please try again later'}`
					dispatch(that.failure({ method: 'IMPORT', errorMsg }))
					dispatch(alertActions.error(errorMsg))
				})
		}
	}
}
