import Joi from 'joi-browser'

const group = Joi.object().keys({
  id: Joi.number().optional(),
  aadTenantId :Joi.any().optional(),
  groupText: Joi.string().required(),
  customerName: Joi.string().required(),
  members: Joi.any(),
  owners: Joi.array().items(Joi.string().allow('',null)).allow('',null).optional(),
  aadGroupId: Joi.string().uuid().allow('', null).optional(),
  groupHistory: Joi.array()
  .items({
    eventText: Joi.string(),
    type: Joi.string(),
    modifiedBy: Joi.string(),
    modifiedAt:  Joi.date(),
  })
  .allow(null),
  status: Joi.string().required(),
  tenantId: Joi.string().uuid(),
  createdAt: Joi.date(),
  updatedAt: Joi.date(),
  createdBy: Joi.string().allow(null),
  updatedBy: Joi.string().allow(null),
  deletedSeq: Joi.number().optional()
}) 
export const methodSchemas = {
    getAll: Joi.array().items(group),
    getOne: group,
    addItem: group,
    editItem: group
}
export const collectionSchemas = {
  //  notes: note
}
export const entitySchema = group
