import React from 'react'
// import styled from 'styled-components'
import { useTable, useExpanded, useRowSelect } from 'react-table'

function ReactTable({ columns: userColumns, data, subColumns, classNames, rowExpendDetailPage, onHandleCheckbox, selectedIndexes, multiselect, mapExpendedRowIndex, preSelectedIndexes }) {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state: { expanded, selectedRowIds},
        visibleColumns,
        // selectedFlatRows
    } = useTable(
        {
            columns: userColumns,
            data,
            initialState:{selectedRowIds:preSelectedIndexes}
        },
        useExpanded,
        useRowSelect,
        hooks => {
            multiselect && hooks.visibleColumns.push(columns => [
                {
                    id: 'selection',
                    Header: ({ getToggleAllRowsSelectedProps }) => (
                        <div>
                            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                        </div>
                    ),
                    Cell: ({ row }) => (
                        <div>
                            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                        </div>
                    ),
                },
                ...columns,
            ])
        },
    )
    const renderRowSubComponent = React.useCallback(
        ({ row }) => {
            return (
                rowExpendDetailPage({ rowData: row.original, isExpanded: row.isExpanded, index: row.index })
            )
        },
        []
    )
    React.useEffect(() => {
        let indexes = Object.keys(selectedRowIds).map(i=>Number(i))
        if (indexes && indexes.length>0 && (indexes.length !== (selectedIndexes && selectedIndexes.length)))
            onHandleCheckbox(indexes)
    }, [selectedRowIds])
    React.useEffect(() => {
        let indexes = Object.keys(expanded).map(i=>Number(i))
        if(indexes && indexes.length>0)
         mapExpendedRowIndex(indexes[0], true)
       // else
       //  mapExpendedRowIndex(indexes[0])
    }, [expanded])
    return (
        <div className="table-responsive">
            <table className={`table ${classNames ? classNames : ''}`} {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                        prepareRow(row)
                        return (
                            <React.Fragment key={i}>
                                <tr  {...row.getRowProps()}>
                                    {row.cells.map(cell => {
                                        return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                    })}
                                </tr>
                                {row.isExpanded ? (
                                    <tr  {...row.getRowProps()}>
                                        <td colSpan={visibleColumns.length}>
                                            {renderRowSubComponent({ row })}
                                        </td>
                                    </tr>
                                ) : null}
                            </React.Fragment>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}
const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
        const defaultRef = React.useRef()
        const resolvedRef = ref || defaultRef

        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate
        }, [resolvedRef, indeterminate])

        return (
            <>
                <input type="checkbox" ref={resolvedRef} {...rest} />
            </>
        )
    }
)
export default ReactTable
