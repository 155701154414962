import Joi from 'joi-browser'

const whiteLabel = Joi.object().keys({
  id: Joi.number().integer().positive(),
  tenantId: Joi.string(),
  appName: Joi.string(),
  pageName: Joi.string(),
  status: Joi.string(),
  publicPrivate:Joi.string(),
  createdAt: Joi.string(),
  updatedAt: Joi.string().allow('', null),
  micrositeName: Joi.string().required().default('Default'),
  config: Joi.object().keys({
                tandC: Joi.string().allow('', null),
                title: Joi.string().allow('', null),
                content: Joi.string().allow('', null),
                privacy: Joi.string().allow('', null),
                showBot: Joi.boolean(),
                botImage: Joi.string(),
                agentImage: Joi.string().allow('', null),
                botName: Joi.string(),
                botStyle: Joi.string().allow('', null),
                botTheme: Joi.string(),
                isHeader: Joi.boolean(),
                isQnaLog: Joi.boolean(),
                botOpenIcon: Joi.string(),
                companyLink: Joi.string(),
                companyLogo: Joi.string(),
                companyName: Joi.string().allow('', null),
                contantInfo: Joi.string().allow('', null),
                botCloseIcon: Joi.string(),
                companyStyle: Joi.string().allow('', null),
                contentStyle: Joi.string().allow('', null),
                landingPageImage: Joi.string().allow('', null),
                landingPageColorStyle:Joi.string(),
                landingPageImageStyle: Joi.string().allow('', null),
                accordianForQna: Joi.boolean().default(false),
                isPowerdBy: Joi.boolean().default(false),
                emailTranscript: Joi.boolean().default(false),
                printTranscript: Joi.boolean().default(false),
                menuFlow: Joi.string().allow('', null),
                welcomeMessage: Joi.string().optional().allow("", null),
                headerHtmlText: Joi.string().optional().allow("", null),
                autoScrollSnapOnActivity: Joi.boolean().allow("", null).default(false).optional(),
                gaCodeId: Joi.string().optional().allow("", null),

        }),
})
// These are the schemas of the return object from the api server
export const methodSchemas = {
    getAll: Joi.array().items(whiteLabel),
    getOne: whiteLabel,
    addItem: whiteLabel,
    deleteItem: Joi.object().keys({id: Joi.number().integer().positive()}),
    editItem: whiteLabel
}
export const collectionSchemas = {
  //  notes: note
}
export const entitySchema = whiteLabel