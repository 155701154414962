import { Axis, Chart, Geom, Tooltip } from 'bizcharts'
import React from 'react'

class Histogram extends React.Component {
	constructor(props) {
		super(props)
		this.state = { loading: true }
	}
	// componentDidUpdate() {
	//     let that = this
	//     this.timeout && clearTimeout(this.timeout) && (this.timeout = null)
	//     this.timeout = setTimeout(() => {
	//         that.chartIns && that.chartIns.forceFit()
	//     }, 300)
	// }

	render() {
		let { t } = this.props
		const loading = this.state && this.state.loading
		if (loading) {
			this.setState({ loading: false })
			return <div>{(t && t('Loading')) || 'Loading...'}</div>
		}
		const data = [
			{ genre: 'Sports', sold: 275, income: 2300 },
			{ genre: 'Strategy', sold: 115, income: 667 },
			{ genre: 'Action', sold: 120, income: 982 },
			{ genre: 'Shooter', sold: 350, income: 5271 },
			{ genre: 'Other', sold: 150, income: 3710 },
			{ genre: 'Other1', sold: 150, income: 3710 },
			{ genre: 'Other2', sold: 150, income: 3710 },
			{ genre: 'Other3', sold: 150, income: 3710 },
		]

		const cols = {
			sold: { alias: 'Sold' },
			genre: { alias: 'New Title' },
		}
		const axisTitle = { textStyle: { fontSize: 15, fontWeight: 'Bolder', fill: '#CCC' } }
		return (
			<div>
				<Chart forceFit width={900} height={300} data={data} scale={cols} className="border" padding={[50, 50, 80, 80]}>
					{/* default padding is 20, 20, 95,80 (t,r,b,l) */}
					<Axis name="genre" title={axisTitle} />
					<Axis name="sold" title={axisTitle} />
					{/* <Legend position="top" dy={-20} /> */}
					<Tooltip />
					<Geom type="interval" position="genre*sold" color="genre" />
				</Chart>
			</div>
		)
	}
	// render() {
	//     const dimensions  = this.state && this.state.dimensions;

	//     return (
	//         <div ref={el => (this.container = el)}>
	//             {dimensions && this.renderContent()}
	//         </div>
	//     );
	// }
}
export default Histogram
