import { ssoConstants } from '..';

export const ssoConfig = (state = {loading: true}, action) => {
    switch (action.type) {

        case ssoConstants.GET_SSO_REQUEST:
          return {
            ...state,
            loading: true
          };
        case ssoConstants.GET_SSO_SUCCESS:
          if(action.payload) {
            return {
              ...state,
              loading: false,
              data: action.payload,
            }
          } else {
            return {
              ...state,
              loading: false
            }
          }
        case ssoConstants.GET_SSO_FAILURE:
          return {
            ...state,
            loading: false,
            error: action.payload
          };  

        case ssoConstants.ADD_SSO_REQUEST:
          return {
            ...state,
            modified: false,
            loading: true
          };
        case ssoConstants.ADD_SSO_SUCCESS:
          return {
            ...state,
            modified: true,
            loading: false,
            payload: action.payload,
          }
        case ssoConstants.ADD_SSO_FAILURE:
          return {
            ...state,
            modified: false,
            loading: false,
            payload: {}
          };             

        case ssoConstants.MODIFY_SSO_REQUEST:
          return {
            ...state,
            modified: false,
            loading: true
          };
        case ssoConstants.MODIFY_SSO_SUCCESS:
          return {
            ...state,
            modified: true,
            loading: false,
            payload: action.payload
          }
        case ssoConstants.MODIFY_SSO_FAILURE:
          return {
            ...state,
            modified: false,
            loading: false,
            payload: {}
          };

        default:
            return state
    }
}