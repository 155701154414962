/**
 * RU on all settings including whitelisting
 * This is in common as all apps would need it
 * Setting Key value pairs come from db of the app.
 */
import { DashboardT } from '../common/analytics'
// import {EntityComponent} from '../entity'
import { methodSchemas, dashboardSchema, reportListSchema } from './schemas'
import { alertActions } from '../common'
import moment from 'moment'
// import {    baseConstants} from '../../entity';

/**
 * Instructions: 
 * 1. For simple usage steps:
 * a. fieldsMeta, actionsMeta, screens, sectionsMeta definition and creation of BaseEntity obj with these inputs
 * 2. For simple overrides of baseEntity 
 * a. create an extended class of baseEntity and override its methods...
 * 3. For more customization
 * a. create a derived Service, derived actions and use them in derived BasedEntity class.
 * b. for component customization pass those components to the constuctor of derived BaseEntity class. SearchList component is the root component.
 *
 * 
 */


// class Admin extends DashboardT {
//     // override the following if needed.
//     // derived actionObj
//     // derived Service obj
//     // getActionFns if you want to suppress the baseActionobj methods
//     getActionFns() {
//         let fns = super.getActionFns()
//         let that = this
//         return {
//             ...fns,

//             addNewItem: (itemData, customerTenantID) => {
//                 // No Create allowed on Settings.
//                 return dispatch => {
//                     let errorMsg = 'Creating ' + that.name + ' is not allowed'
//                     dispatch(fns.failure('ADDNEW', errorMsg));
//                     dispatch(alertActions.error(errorMsg));
//                 }
//             },
//             deleteItem: (id, customerTenantID) => {
//                 // No Create allowed on Settings.
//                 return dispatch => {
//                     let errorMsg = 'Deleting ' + that.name + ' is not allowed'
//                     dispatch(fns.failure('DELETE', errorMsg));
//                     dispatch(alertActions.error(errorMsg));
//                 }
//             }
//         }
//     }
// }

let now = new Date()
let nowF = moment(now).format().slice(0, -6)
let monthBegin = new Date(now.getFullYear(), now.getMonth(), 1)
let monthBeginF =
    moment(monthBegin).tz(moment.tz.guess()).format().slice(0, -6)
const apisMeta = {
    pageMetrics: {
        name: 'pageMetrics',
        /**
         * get most metrics if not all associated with the page.
         */
        dependsOnFields: [ 'queue'], // page filters
        filters: [{
            field: 'createdDate',
            operator: 'is within',
            values: ['fromDate', 'toDate'],
            defaultValues: [monthBeginF, nowF] // to be injected
        }, {
            field: 'agent',
            operator: 'is',
            value: 'agent1' // to be injected
        },
        {
            field: 'queue',
            operator: 'contains',
            value: ['queue1'] // to be injected.
        }
        ],
        select: ['metricName', 'createdDate'],
        groupBy: ['queue', 'agent'], // date, metricname are default grouped
        groupByAgg: [{ field: 'metric', operation: 'average', as: 'metric' }],
        path: '/tickets/metrics',
        method: 'POST',
        
        // response: {
            // targetProp: 'metrics'    data attribute of the reducer... <reducer>.data.metrics
        // },
        orderBy: [{ field: 'createdDate', order: 'desc' }]
    }
}

const cardsMeta = {
    myResponseSLA: {
        title: 'My Response SLA',
        metrics: [{ name: 'responseSLA', fields: ['metric'], operations: ['mean'], groupBy: ['queue'] }],
        filters: [{ field: 'metricName', value: 'responseSLA' }, { field: 'agent', value: 'agent1' }],
        dynFilters: {
            createdDate: { operator: 'range', values: ['fromDate', 'toDate'] },
            queue: 'queue'
        },
        size: 'xs',
        // api: {
        //     path: '/metrics',
        //     method: 'GET',
        //     response: {
        //         ref: 'data', // ref key .. could be defaulted to body. Prop that holds the collection
        //     },
        //     select: ['metricsName'],
        //     dependsOnFields: ['createdDt'],
        //     filters: [{ field: 'metricsName', operator: 'is', value: 'responseSLA' }, { field: 'createdDate', operator: 'is within', value: [/** to be injected */] }, { field: 'agent', operator: 'is', value: '' }, { andOrOr: 'or', field: 'queue', operator: 'contains', value: '' }],
        //     groupBy: ['queue', '']

        //     // page filters are always added dynamically by the page.
        // },
        dependsOnFields: ['fromDate', 'toDate', 'queue'],
        // dataProp: 'data', jsonpath to the rows collection.
        bodySuffix: 'hrs',
        icon: 'fa fa-search',
        classes: 'sm'
    },
    queueResponseSLA: {
        title: 'Queue Response SLA',
        metrics: [{ name: 'responseSLA', fields: ['metric'], operations: ['mean'], groupBy: ['queue'] }],
        filters: [{ field: 'metricName', value: 'responseSLA' }],
        dynFilters: {
            createdDate: { operator: 'range', values: ['fromDate', 'toDate'] },
            queue: 'queue'
        },
        dependsOnFields: ['fromDate', 'toDate', 'queue'],
        // dataProp: 'data', jsonpath to the rows collection.
        bodySuffix: 'hrs',
        classes: 'sm',
        icon: 'fa fa-search',
        size: 'xs'
    },
    myResolutionSLA: {
        title: 'My Resolution SLA',
        metrics: [{ name: 'resolutionSLA', fields: ['metric'], operations: ['mean'], groupBy: ['queue'] }],
        filters: [{ field: 'metricName', value: 'resolutionSLA' }, { field: 'agent', value: 'agent1' }],
        dynFilters: {
            createdDate: { operator: 'range', values: ['fromDate', 'toDate'] },
            queue: 'queue'
        },
        size: 'sm',
        // api: {
        //     path: '/metrics',
        //     method: 'GET',
        //     response: {
        //         ref: 'data', // ref key .. could be defaulted to body. Prop that holds the collection
        //     },
        //     select: ['metricsName'],
        //     dependsOnFields: ['createdDt'],
        //     filters: [{ field: 'metricsName', operator: 'is', value: 'resolutionSLA' }, { field: 'createdDate', operator: 'is within', value: [/** to be injected */] }, { field: 'agent', operator: 'is', value: '' }, { andOrOr: 'or', field: 'queue', operator: 'contains', value: '' }],
        //     groupBy: ['queue', '']

        //     // page filters are always added dynamically by the page.
        // },
        dependsOnFields: ['fromDate', 'toDate', 'queue'],
        // dataProp: 'data', jsonpath to the rows collection.
        bodySuffix: 'hrs',
        // icon: 'fa fa-search',
        classes: 'sm'
    },
    queueResolutionSLA: {
        title: 'Queue Resolution SLA',
        metrics: [{ name: 'resolutionSLA', fields: ['metric'], operations: ['mean'], groupBy: ['queue'] }],
        filters: [{ field: 'metricName', value: 'resolutionSLA' }],
        dynFilters: {
            createdDate: { operator: 'range', values: ['fromDate', 'toDate'] },
            queue: 'queue'
        },
        size: 'sm',
        dependsOnFields: ['fromDate', 'toDate', 'queue'],
        // dataProp: 'data', jsonpath to the rows collection.
        bodySuffix: 'hrs',
        classes: 'sm',
        icon: 'fa fa-search',
    }
}
// show trendline of responsesla and resolution sla over time period for chosen queue
const chartsMeta = {
    /**
     * dualAxis chart eg.
     
     */
    testChart: {
        padding: [20,40,100,80],
        name: 'responseSLAChart',
        title: 'Response Performance Chart',
        chartType: 'dualAxis',
        xy: 'createdDate*metric',
        xy2: 'createdDate*queue',
        axes: [  { field: 'createdDate', alias: 'Time',  type: 'cat'}, { field: 'metric', alias: 'Performance', label:'hrs' }],
        metrics:[ {series: 'Mine', field:'metric', filters:{ agent: 'agent1' }},{series: 'All', field:'metric', operations:['mean'], groupBy:['createdDate','queue']}],
        filters: [{field: 'metricName', value: 'responseSLA'}], // static filters
        dynFilters: { // dynamic filters
            createdDate: { operator: 'range', values: ['fromDate', 'toDate']},
            queue: 'queue'
        },

        series:'series',
        dependsOnFields: ['queue','fromDate','toDate']
    },
    responseSLAChart: {
        name: 'responseSLAChart',
        title: 'Response Performance Chart',
        chartType: 'lines',
        xy: 'createdDate*metric',
        axes: [{ field: 'createdDate', alias: 'Time', range: [0, 1] }, { field: 'metric', alias: 'Performance', label: 'hrs' }],
        metrics: [{ series: 'Mine', field: 'metric', filters: { agent: 'agent1' } }, { series: 'All', field: 'metric', operations: ['mean'], groupBy: ['createdDate', 'queue'] }],
        filters: [{ field: 'metricName', value: 'responseSLA' }], // static filters
        dynFilters: { // dynamic filters
            createdDate: { operator: 'range', values: ['fromDate', 'toDate'] },
            queue: 'queue'
        },
        dependsOnFields: ['queue', 'fromDate', 'toDate']
    },
    resolutionSLAChart: {
        padding: [20,30,80,70],
        name: 'resolutionSLAChart',
        title: 'Resolution Performance Chart',
        chartType: 'histogram',
        xy: 'createdDate*metric',
        series: 'series',
        axes: [{ field: 'createdDate', alias: 'Time', type: 'cat' }, { field: 'metric', alias: 'Performance', label: 'hrs' }],
        metrics: [{ series: 'Mine', field: 'metric', filters: { agent: 'agent1' } }, { series: 'All', field: 'metric', operations: ['mean'], groupBy: ['createdDate', 'queue'] }],
        filters: [{ field: 'metricName', value: 'resolutionSLA' }], // static filters
        dynFilters: { // dynamic filters
            createdDate: { operator: 'range', values: ['fromDate', 'toDate'] },
            queue: 'queue'
        },
        dependsOnFields: ['queue', 'fromDate', 'toDate']
    },
    queuesSLAChart: {
        padding: [20, 70, 50, 100],
        name: 'queuesSLAChart',
        title: "Mine Vs Queue's",
        chartType: 'bars',
        xy: 'metricName*metric',
        series: 'series',
        height: 300,
        axes: [{ field: 'metricName', alias: 'Metric', type: 'cat' }, { field: 'metric', alias: 'Performance in hrs' }],
        metrics: [{ series: 'Mine', field: 'metric', filters: { agent: 'agent1' }, operations: ['mean'], groupBy: ['queue', 'metricName'] }, { series: 'All', field: 'metric', operations: ['mean'], groupBy: ['queue', 'metricName'] }],
        // static filters
        dynFilters: { // dynamic filters
            createdDate: { operator: 'range', values: ['fromDate', 'toDate'] },
            queue: 'queue'
        },
        dependsOnFields: ['queue', 'fromDate', 'toDate']
    },
    myQueuesSLA: {
        padding: [80, 100, 80, 80],
        name: 'queuesSLAChart',
        title: "My SLAs",
        chartType: 'ring',
        value: 'metric',
        series: 'queue',
        height: 300,
        axes: [{ field: 'metric', alias: 'Performance in hrs' }],
        metrics: [{ field: 'metric', filters: { agent: 'agent1' }, operations: ['mean'], groupBy: ['queue'] }],
        // static filters
        dynFilters: { // dynamic filters
            createdDate: { operator: 'range', values: ['fromDate', 'toDate'] },

        },
        dependsOnFields: ['fromDate', 'toDate']
    },


}
const fieldsMeta = {
    fromDate: {
        label: 'From Date',
        name: 'fromDate',
        type: 'datetime-local',
        value: monthBeginF
    },
    toDate: {
        label: 'To Date',
        name: 'toDate',
        type: 'datetime-local',
        value: nowF
    },
    queue: { // list of queues associated with the user..
        label: 'Queue Name',
        type: 'select',
        required: true,
        name: 'queue',
        options: [{ label: 'laptop' }, { label: 'desktop' }],
        value: { label: 'laptop' }
        // api: {
        //     path: 'subcateory',
        //     params: { filter: ['agent'] },
        //     response: {
        //         ref: 'data',
        //         labelProp: 'queueTitle',
        //         valueProp: 'queueName'
        //     }
        // }
    },
    range: {
        label: 'Date Range',
        type: 'datetime-local',
        name: 'range'
    }

}


const actionsMeta = {
    doSomething: {
        type: 'button',
        label: 'Click Me For Some Action',
        action: 'addLinkedItem',
        name: 'doSomething'
    },

}
const listsMeta = {
    exampleList: {
        // api: 
        // dependsOnFields:
        // dataProp: '' ,
        title: 'My List of Items',
        name: 'exampleList',
        filters: [{ field: 'metricName', value: 'resolutionSLA' }], // static filters
        dynFilters: { // dynamic filters
            createdDate: { operator: 'range', values: ['fromDate', 'toDate'] },
            queue: 'queue'
        },
        height: 400,
        colProps: [{field: 'metricName', width: 20, route: '/ticket/settings/update/some'}, {field: 'metric', width: 15 }, {field: 'queue'}, {field: 'agent'},{field: 'createdDate', label: 'Date'}]
    }
}
const sectionsMeta = {
    headerComponent: {
        component: 'DetailHeader',

        // props: [] by default pass all parent props as props to the component.
    },

    basic: {
        title: 'Query Details',
        items: [{ name: 'fromDate', type: 'field' }, { name: 'toDate', type: 'field' }, { name: 'queue', type: 'field' }]
    },
    filters: {
        items: [{ name: 'fromDate', type: 'field', col: 1 }, { name: 'toDate', type: 'field', col: 2 }, { name: 'queue', type: 'field', col: 3 }

        ],
        title: 'Filters',
        cols: [4, 4, 4]
    },
    cards: {
        cols: [3, 3, 3, 3],
        items: [{ name: 'myResponseSLA', type: 'card', col: 1 }, { name: 'queueResponseSLA', type: 'card', col: 2 }, { name: 'myResolutionSLA', type: 'card', col: 3 }, { name: 'queueResolutionSLA', type: 'card', col: 4 }]
    },
    charts: {
        cols: [6, 6],
        items: [{ name: 'responseSLAChart', type: 'chart', col: 1 }, { name: 'queuesSLAChart', type: 'chart', col: 1 }, { name: 'resolutionSLAChart', type: 'chart', col: 2 }, { name: 'myQueuesSLA', type: 'chart', col: 2 }, {name: 'testChart', type: 'chart', col:2}]
    },
    lists: {
        items: [{name: 'exampleList', type: 'list'},] //{name: 'exampleList', type: 'list'}
    }

    // object: {
    //     object: {        ref: 'value' }
    //       // show all value object keys as grid key values. All fields are defaulted to text. If value is not of type object then this section is ignored.

    // // },
    // headerComponent: {
    //     component: 'DetailHeader',

    //     // props: [] by default pass all parent props as props to the component.
    // },

}

const dashboardMeta = {
    items: [{ name: 'filters', type: 'section' }, { name: 'cards', type: 'section' }, { name: 'charts', type: 'section' }, {name: 'lists', type: 'section'}],
    classes: 'composite-form',
    title: 'Admin Dashboard',
    initialValues: {
        filterValues: {
            fromDate: monthBeginF,
            toDate: nowF,
            queue: 'laptop'
        }
    },
    apis: ['pageMetrics']
}


const TestDashboard = new DashboardT({ fieldsMeta, actionsMeta, sectionsMeta, dashboardMeta, apisMeta, cardsMeta, chartsMeta, listsMeta, name: 'dashboardTest', title: 'Test Dashboard', methodSchemas })

const TestDashboardContainer = TestDashboard.getContainer('Dashboard')
export { TestDashboardContainer }