import axios from 'axios'
import { globalTenantServiceUrl } from '../../../config'

export const getTenantService = domain => {
	const requestOptions = {
		url: `${globalTenantServiceUrl}/tenant?domain=${domain}&ts=${new Date().getTime()}`,
		method: 'GET',
	}

	// if (headerParams) {
	// 	requestOptions.headers = {}
	// 	let headerParamsConfig = JSON.parse(headerParams)
	// 	headerParamsConfig.forEach(p => {
	// 		requestOptions.headers[p.name] = p.value
	// 	})
	// }
	return axios.request(requestOptions)
}
