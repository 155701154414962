import React, { Component } from 'react';
import { Loader } from '../../common';
import { localTest, defaultTenantId, pageLimit } from '../../../config'
import { withTranslation } from 'react-i18next'
import isEqual from 'lodash.isequal'


class Subscription extends React.Component {
    // constructor(props) {
    //     super(props)
    // }
    componentDidMount() {

    }
    shouldComponentUpdate(nextProps, nextState, nextContext) {

        return true
    }

    /**
     * render all subscriptions including defaultProducts that are not subscribed
     * Show subscribed apps with their current plans  ( ability to unsubscribe or change plan)
     * Show the default subscribed ones ( with ability to change plan)
     * Show the unsubscribed ones with ability to Subscribe with a chosen plan.  All actions are confirmed once 
     *   ( defaultPlans, defaultProducts contain the unsubscribed ones)
     */
    render() {
        const { defaultPlans, defaultProducts, myPlans, mySubscriptions, loading } = this.props.subscription

        return (
            <div className="animated fadeIn">

                {loading && <Loader />}
            </div>
        )

    }
}
export default withTranslation()(Subscription);