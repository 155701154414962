// import PropTypes from 'prop-types';
import moment from 'moment';
import React from 'react';
import { withTranslation } from 'react-i18next';
class FieldLabel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formValues: props.formValues || {},
            field: props.field
        };
    }
    componentWillMount() {

    }
    componentWillReceiveProps(props){
        this.setState ( {
            formValues: props.formValues || {},
            field: props.field
        });
    }
 /** overwrite this function to do state , createdBy specific returns.... and formatting needed */
    getValue(){
        const  {  formValues, field, t  } = this.props;
        let value = (formValues && formValues[field.name]) || "";
        if(typeof value == 'object') {
            let tempVal = []
            Object.keys(value).map(key => {
                if(Array.isArray(value[key]))
                    return tempVal.push(`${key}: ${value[key].join(', ')}`)
                else
                    return tempVal.push(`${key}: ${value[key]}`)
            })
            value = tempVal.join(', ')
        }
        if(!! value && typeof value !== "string"){
            value = JSON.stringify(value)
        }
        
          return t(value);
    }
    //changes v2.0
    render() {
        const { field, formValues,t } = this.props;
        let display = field.display
        let label = field.label
        if(label) label = t(label)
        const value= ()=>{
            if( field && ['date','datetime','datetime-local'].includes(field.type) ){
                return (
                    <p>
                    {
                        formValues && formValues[field.name] 
                        ? t( '{{date, date}}', {date: moment(formValues[field.name]).format("DD/MM/YYYY")})
                        : ""
                    }
                    </p>
                )
            }
            
            else
            { 
                return (
                    <p>{this.getValue()}</p>
                )
            }

        }
        return (
            <>
            { <div className="custom-form-grp row clearfix"  style={{ display: display ? 'block' : 'none' }} >
           
                <div className="col-sm-3"> 
                 <label>{t(label)}</label>
                </div>
                <div className="col-sm-9">
                    <div className="ts-drop">
                       {value()}
                    </div>
                </div>
            </div>
            }
            </>
        )
    }
}

FieldLabel.propTypes = {
    //classes: PropTypes.object.isRequired,
};

export default withTranslation()(FieldLabel);
