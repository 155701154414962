import React from 'react'

import { Form } from 'reactstrap'
import { FieldFilter } from './'
export default class FormDetail extends React.Component {

    render() {
        const { fields } = this.props.meta
        const { filterValues } = this.props
        let dependsOn
        return (
            <Form className="form-inline">
                {fields && filterValues && Object.keys(filterValues).forEach(f => {
                    let field = fields[f]
                    if (field) {
                        if (field.dependsOn && field.dependsOn.pop && filterValues) {
                            dependsOn = {}
                            field.dependsOn.forEach(f1 => {
                                dependsOn[f1] = field[f1]
                            })
                        }
                        return (
                        <React.Fragment key={field.name}>
                            <FieldFilter key={field.name} name={field.name} dependsOn={dependsOn} field={field} formValues={filterValues} {...this.props} />
                        </React.Fragment>
                    )
                    }
                })
                }
            </Form>
        )
    }
}