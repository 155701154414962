import React, { Component } from 'react';
// Locale
import {withTranslation} from 'react-i18next';
import {Box,Link} from '@chakra-ui/react'
//Locale

class Footer extends Component {
  render() {
    const {t}=this.props;
    return (
      <Box textAlign="right" mt={'0.5rem'} fontSize="13px" fontFamily={'Inter'} p={'0.5rem'}
      paddingRight={'15px'}
      boxShadow={'lg'}
      color={'#111827'}
      cursor={'pointer'}
      _hover={{color:'#0a58ca',textDecoration:'underline'}}
      onClick={()=>window.open('https://www.rezolve.ai/', '_blank')}
      >
copyrights@rezolve.ai
      </Box>
      // <footer className="app-footer clearfix">
      // <p className="lead">{t('For best experience, use one of these fully compatible browsers - (Chrome or Edge on Chromium)')}</p>
      //   <div className="ap-f-left pull-left">
      //     <a target="_blank" href="https://www.rezolve.ai" rel="noopener noreferrer">{t('Rezolve.ai')}</a> &copy; {(new Date()).getFullYear()}
      //     <span className=" apf-right pull-right">&nbsp;
      //     {t('Powered by')} 
      //     <a target="_blank" href="https://www.rezolve.ai" rel="noopener noreferrer">{t('copyrights@rezolve.ai')}</a>
      //   </span>
      //   </div>

      // </footer>
    )
  }
}

export default withTranslation()(Footer);
