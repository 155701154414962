import React from 'react'
import { i18n } from '../'
import { cdnUri } from '../../../config'
export default function Loader() {
	let cdnUri1 = cdnUri
	if (!cdnUri1) cdnUri1 = localStorage.getItem('cdnUri')
	return (
		<div className="outer-overlay loaderpos">
			<div className="ov-content">
				<div className="row qnamaker-spinner">
					<div className="spinner-circle-mod">
						<img src={`assets/img/loading.gif`} alt="loading" />
					</div>
				</div>
				<div className="row spinner">
					<div className="loading-text">{i18n.t('Fetching Details')}</div>
					<div className="loading-text"></div>
				</div>
			</div>
		</div>
	)
}
