
export const getRegexMatch = (pattern, value) => {
    if(!pattern ) return false
    let r
    if (pattern[0] === pattern.slice(-1) && pattern[0] === '/') {
        r = new RegExp(pattern.slice(1,-1))
    } else r = new RegExp(pattern)
    // let r = new RegExp(pattern,)
    var m = r.exec(value);
    if (m) return m[0];
    return null;
}

export const validateType = ( {type, value, label, options, isMulti} ) =>{
    if(!value || !type) return `Invalid value or type`
    let e = ''
    switch (type) {

        case 'url':
                if (!getRegexMatch( '^(http[s]?:\\/\\/){0,1}(www\\.){0,1}[a-zA-Z0-9\\.\\-]+\\.[a-zA-Z]{2,5}[\\.]{0,1}', value)) e += `${label || value} is not valid tel;`
                break
        case 'tel':
                if (!getRegexMatch('(\\W|^)(\\+\\d{1,2})?\\s?\\(?(\\d{3})\\)?[\\s.-]?(\\d{3})[\\s.-]?(\\d{4})$', value)) e += `${label || value} is not valid tel;`
                break
        case 'number': 
            if(isNaN(value)) e += `${label || value} is not valid number;`
            break
        case 'month':
            if(!['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec','January','February','March','April','June','July','August','September','October','November','December',1,2,3,4,5,6,7,8,9,10,11,12,'1','2','3','4','5','6','7','8','9','10','11','12'].includes[value])
            e += `${label || value} is not valid month;`
            break
        case 'datetime-local':
        case 'date':
            if (isNaN(Date.parse(value))) e += `${label || value} is not valid date;`
            break
        case 'checkbox':
            if (options && !options.find(o => o.label === value || o=== value)) e += `${label || value} is not having a valid option ${options.map(o => o.label || o).join(',')};`
            else if (![true, false, 0, 1, 'true', 'false'].includes(value)) e += `${label || value} should be a boolean;`
            break
        case 'radio':
        case 'select':
            // for now only static options... need to add for dynamic options
            //TODO: dynamic options check
            if (isMulti && options) {
                if (!(value && value.split(',').every(v => options.find(o => o.label === v || o=== v)))) e += `${label || value} is not having a valid option ${options.map(o => o.label || o).join(',')};`
            }
            else if (!(options && options.find(o => o.label === value || o=== value))) e += `${label || value} is not having a valid option ${options.map(o => o.label || o).join(',')};`
            break
        case 'email':
            if (!/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/.test(value)) e += `${label || value} is not valid email;`
            break
        case 'text':
        default:
    }
    return e
}