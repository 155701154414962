import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { registerTenantActions} from '../'
import RegisterComponent from '../components/RegisterComponent'

const mapStateToProps = (state) => {
    const { identity, registerNewTenant, tenant } = state;
    return {
        identity,
        registerNewTenant,
        tenant
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        ...registerTenantActions
    }, dispatch)
}

export default connect(mapStateToProps,mapDispatchToProps)(RegisterComponent);