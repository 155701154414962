// import { apiUrl } from  '../../../config';
import { identityServices } from '../'

export const changeUserPassword = (tenanId, userId, body, apiUrl) => {
	if (tenanId === undefined || tenanId == null) Promise.reject('`Error : Missing Tenant Id ')
	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		url: `${apiUrl}/role/users/change-password/${userId}?tenantId=${tenanId}`,
		data: body,
	}

	return identityServices.identityApi.request(requestOptions).then(response => {
		if (response.status !== 200) {
			return Promise.reject(`Unable to fetch the list of users : Error code : ${response.status}`)
		}

		return response.data
	})
}

export const modifyUsers = (tenanId, userId, userPayload, apiUrl) => {
	if (tenanId === undefined || tenanId == null) Promise.reject('`Error : Missing Tenant Id ')
	const requestOptions = {
		method: 'PUT',
		headers: {
			'Content-Type': 'application/json',
		},
		url: `${apiUrl}/role/users/${userId}?tenantId=${tenanId}`,
		data: userPayload,
	}

	return identityServices.identityApi.request(requestOptions).then(response => {
		if (response.status !== 201) {
			return Promise.reject(`Unable to update the user role : Error code : ${response.status}`)
		}

		return response.data
	})
}

export const getUserById = (tenanId, userId, apiUrl) => {
	if (tenanId === undefined || tenanId == null) Promise.reject('`Error : Missing Tenant Id ')
	if (!apiUrl) apiUrl = sessionStorage.getItem('apiUrl')
	const requestOptions = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
		},
		url: `${apiUrl}/role/users/${userId}?tenantId=${tenanId}`,
	}

	return identityServices.identityApi.request(requestOptions).then(response => {
		if (response.status !== 200 && response.status !== 304) {
			return Promise.reject(`Unable to fetch the list of users : Error code : ${response.status}`)
		}

		return response.data
	})
}

export const getUserByUserName = (tenanId, userName, apiUrl) => {
	if (tenanId === undefined || tenanId == null) Promise.reject('`Error : Missing Tenant Id ')
	if (!apiUrl) apiUrl = sessionStorage.getItem('apiUrl')
	const requestOptions = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
		},
		url: `${apiUrl}/User?$filter=username eq '${userName}' and tenantId eq '${tenanId}'`,
	}

	return identityServices.identityApi.request(requestOptions).then(response => {
		if (response.status !== 200 && response.status !== 304) {
			return Promise.reject(`Unable to fetch the User : Error code : ${response.status}`)
		}

		return response.data
	})
}
