import {
	Chip,
	FormControl,
	Input,
	InputLabel,
	LinearProgress,
	MenuItem,
	Paper,
	Select,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TextField,
	Typography,
} from '@material-ui/core'
import { blueGrey, indigo } from '@material-ui/core/colors'
import { withStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import { Check as CheckIcon, Edit as EditIcon, Lock as LockOpenIcon, LockOpen as LockIcon } from '@material-ui/icons'
import React from 'react'
import { withTranslation } from 'react-i18next'

const styles = theme => ({
	root: {
		width: '100%',
		marginTop: theme.spacing.unit * 3,
		overflowX: 'auto',
	},
	table: {
		minWidth: 700,
	},
	row: {
		'&:nth-of-type(even)': {
			backgroundColor: blueGrey[50],
		},
	},
	head: {
		backgroundColor: indigo[300],
		color: theme.palette.common.white,
	},
	chip: {
		margin: theme.spacing.unit,
	},
	formControl: {
		margin: theme.spacing.unit,
		minWidth: theme.wid,
		maxWidth: 300,
	},
	roles: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	role: {
		margin: theme.spacing.unit / 4,
	},
})

const row = (
	x,
	i,
	roles,
	attributes,
	loading,
	header,
	handleLocking,
	startEditing,
	editIdx,
	handleChange,
	stopEditing,
	classes,
	handleRequiredAction,
	userAttributeKey,
	selectedAttr,
	userAttributeValues,
	userAttributeValue,
	selectedKeyValAttributes,
	handleChangeAttr,
	addSelectedAttributes,
	removeAttr,
	t
) => {
	const currentlyEditing = editIdx === i
	return (
		<TableRow className={classes.row} key={`tr-${i}`}>
			{header.map((y, k) => (
				<TableCell key={`trc-${k}`}>
					{currentlyEditing ? (
						x[y.prop] instanceof Array ? (
							<FormControl fullWidth>
								<InputLabel htmlFor="select-multiple-role">Roles</InputLabel>
								<Select
									multiple
									value={x[y.prop]}
									onChange={e => handleChange(e, y.prop, i)}
									input={<Input id="select-multiple-role" />}
									renderValue={selected => (
										<div className={classes.roles}>{selected && selected.map(value => <Chip key={value} label={value} className={classes.roles} />)}</div>
									)}>
									{roles &&
										roles.map(role => (
											<MenuItem key={role.name} value={role.name}>
												{role.name}
											</MenuItem>
										))}
								</Select>
							</FormControl>
						) : x[y.prop] instanceof Object || y.prop == 'attributes' ? (
							<div>
								<FormControl fullWidth>
									<InputLabel htmlFor="select-multiple-role">{t('User Attributes')}</InputLabel>
									<Select value={userAttributeKey} onChange={handleChangeAttr} input={<Input name="userAttributeKey" id="select-user-attr-edt" />}>
										{attributes &&
											attributes.map(attr => {
												if (attr.status == 'Active') {
													return (
														<MenuItem key={attr.key} value={attr.key}>
															{attr.label}
														</MenuItem>
													)
												}
											})}
									</Select>
								</FormControl>

								{selectedAttr &&
									selectedAttr.isMultivalue == 'Yes' &&
									(!selectedAttr.validationRule || selectedAttr.validationRule.field == 'Predefined List') && (
										<FormControl fullWidth>
											<div className="attr-multi-select">
												<InputLabel htmlFor="select-multiple-role">{'Attribute Values'}</InputLabel>
												<Select
													multiple
													value={userAttributeValues}
													onChange={handleChangeAttr}
													input={<Input name="userAttributeValues" id="select-user-attr-values-edt" />}
													renderValue={selected => (
														<div className={classes.roles}>
															{selected && selected.map(value => <Chip key={value} label={value} className={classes.roles} />)}
														</div>
													)}>
													{selectedAttr &&
														selectedAttr.valueLabels &&
														selectedAttr.valueLabels.map(attr => (
															<MenuItem key={attr} value={attr}>
																{attr}
															</MenuItem>
														))}
												</Select>
											</div>
											<span className="for-add-field small-plus" onClick={addSelectedAttributes}>
												<i className="fa fa-plus y"></i>
											</span>
										</FormControl>
									)}

								{selectedAttr && selectedAttr.isMultivalue == 'No' && (!selectedAttr.validationRule || selectedAttr.validationRule.field == 'Predefined List') && (
									<FormControl fullWidth>
										<div className="attr-multi-select">
											<InputLabel htmlFor="select-multiple-role">{'Attribute Value'}</InputLabel>
											<Select
												value={userAttributeValue}
												onChange={handleChangeAttr}
												input={<Input name="userAttributeValue" id="select-user-attr-value-edt" />}>
												{selectedAttr &&
													selectedAttr.valueLabels &&
													selectedAttr.valueLabels.map(attr => (
														<MenuItem key={attr} value={attr}>
															{attr}
														</MenuItem>
													))}
											</Select>
										</div>
										<span className="for-add-field small-plus" onClick={addSelectedAttributes}>
											<i className="fa fa-plus y"></i>
										</span>
									</FormControl>
								)}

								{selectedAttr &&
									selectedAttr.validationRule &&
									(selectedAttr.validationRule.field == 'Free Text' || selectedAttr.validationRule.field == 'number') && (
										<FormControl fullWidth className="fc-split">
											<div className="attr-multi-select">
												<TextField
													required
													onChange={handleChangeAttr}
													value={userAttributeValue}
													id="userAttributeValueTxt"
													name="userAttributeValue"
													label={'User Attribute Value'}
													type={selectedAttr.validationRule.field == 'Free Text' ? 'text' : selectedAttr.validationRule.field}
													fullWidth
													// onBlur={this.handleEmailValidation.bind(this)}
												/>
											</div>

											{/* { 
                    this.state.email 
                    && !this.state.isEmailValid 
                    &&  <span className={classes.errorColor}  >
                    {t('Please enter valid email')} 
                    </span>} */}
											<span className="for-add-field small-plus" onClick={addSelectedAttributes}>
												<i className="fa fa-plus y"></i>
											</span>
										</FormControl>
									)}

								{selectedKeyValAttributes &&
									attributes &&
									Object.keys(selectedKeyValAttributes).length > 0 &&
									Object.keys(selectedKeyValAttributes).map((elem, i) => {
										let keyLabel = elem
										attributes.forEach(labelTxt => {
											if (labelTxt.key == elem) {
												keyLabel = labelTxt.label
											}
										})

										if (keyLabel != 'profile_pic') {
											return (
												<span className="attr-wrapper-span" key={i}>
													{keyLabel + ': ' + selectedKeyValAttributes[elem].join(', ')}
													<span className="close-tag">
														<i className="fa fa-close" onClick={e => removeAttr(e, elem)}></i>
													</span>
												</span>
											)
										} else return
									})}
							</div>
						) : (
							<TextField name={y.prop} onChange={e => handleChange(e, y.prop, i)} value={x[y.prop]} disabled={y.disabled} />
						)
					) : x[y.prop] instanceof Array ? (
						x[y.prop].map(value => <Chip key={value} label={value} className={classes.chip} />)
					) : x[y.prop] instanceof Object ? (
						Object.keys(x[y.prop]).map(key => {
							let keyLabel = attributes && attributes.find(o => o.key == key)
							keyLabel = keyLabel && keyLabel.label ? keyLabel.label : key
							if (x[y.prop][key] && key != 'profile_pic') {
								if (x[y.prop][key].join(', ').length > 30) {
									return (
										<Chip
											title={keyLabel + ':' + x[y.prop][key].join(', ')}
											key={key}
											label={keyLabel + ': ' + x[y.prop][key].join(', ').substring(0, 30) + ' ...'}
											className={classes.chip}
										/>
									)
								} else {
									return (
										<Chip
											title={keyLabel + ':' + x[y.prop][key].join(', ')}
											key={key}
											label={keyLabel + ': ' + x[y.prop][key].join(', ')}
											className={classes.chip}
										/>
									)
								}
							}
						})
					) : (
						x[y.prop]
					)}
				</TableCell>
			))}
			<TableCell>
				{currentlyEditing ? (
					<CheckIcon hidden={loading} onClick={() => stopEditing()} />
				) : (
					<Tooltip title="Edit">
						<EditIcon hidden={loading} onClick={() => startEditing(i, x.id)} />
					</Tooltip>
				)}
			</TableCell>
			<TableCell>
				{x['enabled'] ? (
					<Tooltip title="Deactive">
						<LockIcon hidden={loading} onClick={() => handleLocking(i)} />
					</Tooltip>
				) : (
					<Tooltip title="Active">
						<LockOpenIcon hidden={loading} onClick={() => handleLocking(i)} />
					</Tooltip>
				)}
			</TableCell>
			<TableCell>
				<Tooltip title="Reset Password">
					<i className="fa fa-user fa-2x" onClick={() => handleRequiredAction(i)}></i>
				</Tooltip>
			</TableCell>
		</TableRow>
	)
}

const TableList = ({
	data,
	header,
	roles,
	attributes,
	handleLocking,
	startEditing,
	editIdx,
	handleChange,
	stopEditing,
	loading,
	classes,
	handleRequiredAction,
	userAttributeKey,
	selectedAttr,
	userAttributeValues,
	userAttributeValue,
	selectedKeyValAttributes,
	handleChangeAttr,
	addSelectedAttributes,
	removeAttr,
}) => (
	<Paper className={classes.root}>
		{loading && <LinearProgress />}
		<Table className={classes.table}>
			<TableHead className={classes.head}>
				<TableRow className={classes.row}>
					{header.map((x, i) => (
						<TableCell key={`thc-${i}`}>
							<Typography style={{ color: 'white' }}>{x.name}</Typography>
						</TableCell>
					))}
					<TableCell />
					<TableCell />
					<TableCell />
				</TableRow>
			</TableHead>
			<TableBody>
				{data &&
					data.map((x, i) =>
						row(
							x,
							i,
							roles,
							attributes,
							loading,
							header,
							handleLocking,
							startEditing,
							editIdx,
							handleChange,
							stopEditing,
							classes,
							handleRequiredAction,
							userAttributeKey,
							selectedAttr,
							userAttributeValues,
							userAttributeValue,
							selectedKeyValAttributes,
							handleChangeAttr,
							addSelectedAttributes,
							removeAttr
						)
					)}
			</TableBody>
		</Table>
	</Paper>
)

export default withTranslation()(withStyles(styles)(TableList))
