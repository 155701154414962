import React from 'react'
import ReactDataGrid from 'react-data-grid'
import { withTranslation } from 'react-i18next'
import MainLayout from '../../common/base/entity/components/grid/MainGrid'
import { ConfrimDialog, FullSearchComponent } from '../../common/base/entity/components/index'
import { EntityComponent } from '../../common/base/entity/components/ListSearchComponent'
import { Loader } from '../../common/index'

/**
 * extended class for expanding and collapsing the properties field on to form.
 */

class UserAttributeListsearch extends EntityComponent {
	constructor(props) {
		super(props)
		this.state.limit = 100
	}

	render() {
		const { modelData } = this.state
		const { alert } = this.props
		let entityList

		// const { entityList } = this.state;

		entityList = this.props.entityList
		const {
			meta,
			meta: { idKey },
			t,
		} = this.props
		// const {  entity:{itemData }, name } = this.props;
		// const meta = this.props.entity? this.props.entity.meta : undefined
		let itemData = entityList && entityList.itemData
		const loading = entityList && entityList.loading
		const filteredData = this.state.filteredData || (this.props.entityList && this.props.entityList.filteredData)
		const searchText = this.props.entityList && this.props.entityList.searchText
		const searchParams = this.props.entityList && this.props.entityList.searchParams

		if (filteredData) itemData = filteredData
		const { entity } = this.props
		let { entityValues } = entity
		let auditConfig = entity && entity.auditConfig
		let enableAuditLogAction = auditConfig ? true : false
		let createAction
		let viewAction
		let editAction
		let deleteAction
		let auditLogAction
		let importAction

		let gridColumns = [
			{ key: 'key', name: 'Key', editable: false },
			{ key: 'label', name: 'Label', editable: false },
		]

		if (enableAuditLogAction) {
			auditLogAction = {
				label: 'View Audit Log',
				name: 'auditLog',
				type: 'button',
				action: 'getAuditLog',
			}
		}
		if (meta && meta.actions) {
			createAction = meta.actions.create
			viewAction = meta.actions.View
			editAction = meta.actions.Update
			deleteAction = meta.actions.Delete
			importAction = meta.actions.import
		}
		// const { globalSetting: { globalSettingData } } = this.props;
		const listScreen = meta.screens && meta.screens.list
		let itemsByCols
		if (listScreen && listScreen.renderItems) {
			itemsByCols = {}
			listScreen.cols &&
				listScreen.renderItems.forEach(i => {
					if (i.col) {
						itemsByCols[i.col] = itemsByCols[i.col] || []
						itemsByCols[i.col].push(i)
					} else {
						itemsByCols.unassigned = itemsByCols.unassigned || []
						itemsByCols.unassigned.push(i)
					}
				})
			// @ts-ignore
			itemsByCols.unassigned = itemsByCols.unassigned || (listScreen && !listScreen.cols && listScreen.renderItems)
			// @ts-ignore
			if (listScreen.cols && itemsByCols.unassigned) return <div>{t(`Error: Some elements are not assigned to columns ${itemsByCols.unassigned} `)}</div>
		}
		return (
			<div className="animated fadeIn">
				{loading && <Loader />}

				<div className="common-heading">
					{alert.message && <div className={`alert ${alert.type}`}>{t(alert.message)}</div>}
					<div className="common-heading row">
						<div className="col-sm-6">
							<div className="heading">
								<h5>{t(meta ? meta.title : 'Meta missing')}</h5>
							</div>
						</div>
					</div>
				</div>
				{itemsByCols && (
					<div className={listScreen.classes}>
						{listScreen && listScreen.cols && (
							<div className="row">
								{listScreen.cols.map((c, i) => {
									return (
										<div className="col-sm-{c}" key={i}>
											{itemsByCols[i + 1] &&
												itemsByCols[i + 1].map(item => {
													return this.renderItem(item)
												})}
										</div>
									)
								})}{' '}
							</div>
						)}
						{itemsByCols &&
							// @ts-ignore
							itemsByCols.unassigned &&
							// @ts-ignore
							itemsByCols.unassigned.map(item => {
								return this.renderItem(item)
							})}
					</div>
				)}
				<div className="search-section">
					<div className="row">
						<div className="col-sm-12">
							{meta && meta.screens && meta.screens.search && (
								<FullSearchComponent
									meta={this.props.meta}
									handleSearch={this.handleSearch.bind(this)}
									handleTextSearch={this.handleTextSearch.bind(this)}
									searchText={searchText}
									searchForm={searchParams}
									handleAction={this.handleAction.bind(this)}
									filters={this.props.routeProps && this.props.routeProps.filter}
								/>
							)}
						</div>
					</div>
				</div>

				<div className="main-section">
					<div className="main-inner">
						<div className="row table-title">
							{createAction && (
								<div className="col-sm-6">
									<div className="show-section">
										<h4 className="add">
											{' '}
											<span className="add-ico" onClick={this.addItem.bind(this)}>
												{' '}
												<i className="fa fa-plus-square-o" aria-hidden="true"></i>
												{t(createAction.label)}
											</span>
										</h4>
									</div>
								</div>
							)}
						</div>

						<div className="row">
							<div className="col-sm-12">
								{meta.screens && meta.screens.list && meta.screens.list.items && (
									<MainLayout
										data={itemData}
										columns={meta.screens.list.items}
										fields={meta.fields}
										meta={meta}
										totalCount={this.props.entity && this.props.entity.count && this.props.entity.count.length}
										itemClick={this.itemClick.bind(this)}
										importData={this.importData.bind(this)}
										viewAuditLog={this.viewAuditLog.bind(this)}
										handleSearch={this.handleSearch.bind(this)}
										preFilters={this.props.routeProps && this.props.routeProps.filter}
										handleSort={this.handleSort.bind(this)}
										handleListAction={this.handleListAction.bind(this)}
										handleExportData={this.handleExportData.bind(this)}
										auditLogAction={auditLogAction}
									/>
								)}
							</div>
						</div>
						<div className="row">
							<div className="col-sm-12">
								{/* <div className="pagination-control pull-right clearfix">
                                {itemData && !loading && itemData.length > 0 &&
                                    <Pagination
                                        activePage={this.state.activePage}
                                        totalItemsCount={this.state.totalCount || (this.props.entity && this.props.entity.count && this.props.entity.count)}
                                        onChange={this.handlePageChange.bind(this)}
                                        itemsCountPerPage={this.state.limit}
                                    />
                                }


                            </div> */}
							</div>
						</div>
						{modelData && (
							<ConfrimDialog
								openModal={modelData.openModal}
								message={modelData.confirmActionMessage}
								actionMeta={modelData.actionMeta}
								actionObj={modelData.actionObj}
								actionPayload={modelData.actionPayload}
								handleStateReset={this.handleStateReset.bind(this)}
								handleAction={this.handleAction.bind(this)}
							></ConfrimDialog>
						)}
					</div>
				</div>

				{entityValues && entityValues.userAttrData && entityValues.userAttrData.length > 0 && (
					<div className="row additional-grid">
						<div className="col-sm-12">
							<div className="col-sm-6">
								<h5>{t('SSO User Attributes')}</h5>
							</div>
						</div>

						<div className="col-sm-12">
							<ReactDataGrid 
								columns={gridColumns} 
								rowGetter={i => entityValues.userAttrData[i]} 
								rowsCount={entityValues.userAttrData.length}
								enableCellAutoFocus={false}
							/>
						</div>
					</div>
				)}
			</div>
		)
	}
}
export default withTranslation()(UserAttributeListsearch)
