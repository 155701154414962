// @ts-nocheck
import { AddIcon, InfoIcon, MinusIcon, RepeatIcon } from '@chakra-ui/icons'
import {
	Alert,
	AlertDescription,
	AlertIcon,
	AlertTitle,
	Box,
	Button,
	Checkbox,
	Container,
	Divider,
	FormControl,
	FormLabel,
	Heading,
	Link,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Stack,
	Text,
	useDisclosure
} from '@chakra-ui/react'
import React, { useCallback, useState, useEffect } from 'react'
import { session } from '../../../config'
import { history } from '../../../redux/history'
import { identityServices } from '../../common'
import { withTranslation } from 'react-i18next'
const GroupHistory = props => {
	const { isOpen, onOpen, onClose } = useDisclosure()
	const [aadGroupMembers, SetAadGroupMembers] = useState([])
	const [localGroupMembers, SetLocalGroupMembers] = useState([])
	const [sync, setSync] = useState(false)
	const [checkedItems, setCheckedItems] = React.useState([])
	const [nestedgp, setNestedgp] = useState(false)
	const groupLogs = props.groupLogs
	if (groupLogs && groupLogs.length > 0) groupLogs.sort((a, b) => new Date(b.modifiedAt).getTime() - new Date(a.modifiedAt).getTime())

	const iconText = type => {
		switch (type) {
			case 'added':
				return <AddIcon variant="solid" color="green" w="1em" mr="4px" />
			case 'removed':
				return <MinusIcon variant="solid" color="red" w="1em" mr="4px" />
			case 'message':
				return <InfoIcon variant="solid" color="blue" w="1em" mr="4px" />
			default:
				return <InfoIcon variant="solid" color="blue" w="1em" mr="4px" />
		}
	}
	const getStatus = type => {
		switch (type) {
			case 'added':
				return 'success'
			case 'removed':
				return 'error'
			case 'message':
				return 'info'
			default:
				return 'info'
		}
	}

	useEffect(() => {
		if (props.entityValues.aadGroupId != null || undefined){
			handleNestedGP(props.entityValues.aadGroupId)
		}
	},[props.entityValues.aadGroupId])

	const handleNestedGP = useCallback(
		async (aadGroupId) => {
			let apiUrl = session.get('apiUrl') || sessionStorage.getItem('apiUrl') || localStorage.getItem('apiUrl')
			const reqOptions = {
				url: `${apiUrl}/group/getMembersByGroupId?aadGroupId=${aadGroupId}`,
				method: 'GET',
			}
			const res = await identityServices.identityApi.request(reqOptions)
			if (res.status === 200) {
				let nestedgpvalue = res && res.data.length && res.data.some(e => {
					if (e['@odata.type'] === '#microsoft.graph.group') return true
					else return false
				})
				nestedgpvalue ? setNestedgp(true) : setNestedgp(false)
			}
			
		}, []
	)

	const handleSyncModalClick = useCallback(
		async (aadGroupId, members, tenantConfig, entityValues) => {
			setSync(true)
			let apiUrl = session.get('apiUrl') || sessionStorage.getItem('apiUrl') || localStorage.getItem('apiUrl')
			let url, aadTenantId
			if (tenantConfig) {
				const requestOptions2 = {
					url: `${apiUrl}/botmgmt-service/CustomerMSP?$filter=status eq 'Published' and customerName eq '${entityValues.customerName}'`,
					method: 'GET',
				}
				const response = await identityServices.identityApi.request(requestOptions2)
				if (response.status === 200) {
					aadTenantId = response?.data[0]?.aadTenantId
					if (!aadTenantId) return setSync(false)
				} else {
					SetAadGroupMembers([])
					setSync(false)
					onOpen()
				}
				url =`${apiUrl}/msteams/getAADMemberByGroupId/${aadTenantId}/${aadGroupId}`
			} else {
				url = `${apiUrl}/group/getMembersByGroupId?aadGroupId=${aadGroupId}`
			}
			if (nestedgp) url += `&resNested=true`
			const requestOptions = {
				url: url,
				method: 'GET',
			}
			const requestOptions1 = {
				url: `${apiUrl}/botmgmt-service/appusers?$filter=enabled eq 'true'`,
				method: 'GET',
			}
			let results = await Promise.all([identityServices.identityApi.request(requestOptions)])
			if (results && results.length > 0) {
				if (results[0].status === 200 && results[0].data.length > 0) {
					results[0].data = results[0].data.filter(ele => ele['@odata.type'] !== '#microsoft.graph.group')
				}			
				const filteredData = results[0].data.filter(item=>{
                    if(item.givenName && item.mail){
                        return item
                    }
				})
				SetAadGroupMembers(filteredData)
				setSync(false)
				onOpen()
			}
		 
		},
		[onOpen, aadGroupMembers, SetAadGroupMembers,nestedgp]
	)

	const handleSyncWithAddMember = useCallback((checkedItems, msp) => {
		if (checkedItems && checkedItems.length > 0)  props.handleUpdateGroup({ AADuser : checkedItems} , msp)
	}, [])

	const handleBack = () => {
		history.push('/group')
	}
	const btnBack = document.querySelector('.common-heading .btn.btn-link.pull-right')
	// @ts-ignore
	if (btnBack && btnBack.style) btnBack.style.display = 'none'

	const headBg = document.querySelector('.common-heading')
	// @ts-ignore
	if (headBg && headBg.style) headBg.style.marginRight = '-38px'

	return (
		<Box>
			<Container h="54px" background="#fff" width="100%" maxW="100%">
				<Stack direction="row" spacing={4} position="absolute" right="15px">
					{ props.entityValues && props.entityValues.aadGroupId && !props.restrictUpdate && 
						props.tenantConfig === 1  ?
						(
							<Button
								leftIcon={<RepeatIcon />}
								isLoading={sync}
								loadingText="Syncing...!"
								colorScheme="blue"
								variant="solid"
								onClick={() => handleSyncModalClick(props.entityValues.aadGroupId, props.entityValues.members, props.tenantConfig, props.entityValues)}
								key={'md'}
								m={3}>
								Sync MSP Customer
							</Button>
						)
						:
						(
							<Button
								leftIcon={<RepeatIcon />}
								isLoading={sync}
								loadingText="Syncing...!"
								colorScheme="blue"
								variant="solid"
								onClick={() => handleSyncModalClick(props.entityValues.aadGroupId, props.entityValues.members, props.tenantConfig, props.entityValues)}
								key={'md'}
								m={3}>
								{nestedgp ? 'Sync Nested Group' : 'Sync With AAD'}
							</Button>
						)
					}
			 
					<Link color="blue.500" href="#" className="link-back-to-list" onClick={handleBack} padding="15px">
						{props.t ? props.t("Back to List") : "Back to List"}
					</Link>
				</Stack>
			</Container>
			<Container bg="white" mt={6} p="5px">
				<Heading as="h4" size="md" lineHeight="2.4">
					Recent changes to group:
					<Box as="span" fontSize="17px" color="tomato" ml="5px">
						{props.groupText}
					</Box>
				</Heading>
				<Divider variant="solid" h="2px" colorScheme="gray" style={{ border: '1' }} mb="2" />
				{groupLogs.length < 1 && (
					<Stack spacing={3} mb="2">
						<Alert status="info">
							<AlertIcon />
							There are no changes!
						</Alert>
					</Stack>
				)}
				<Container maxHeight="510px" overflow="auto">
					<Stack spacing={3}>
						{groupLogs.map((log, index) => {
							return (
								<Alert status={getStatus(log.type)} index={index} key={index}>
									{iconText(log.type)}
									<Box flex="1">
										<Text as="i">
											{new Date(log.modifiedAt).toLocaleString('en-US', {
												weekday: 'short',
												day: 'numeric',
												year: 'numeric',
												month: 'long',
												hour: 'numeric',
												minute: 'numeric',
												second: 'numeric',
											})}
										</Text>
										<AlertDescription display="block">{log.eventText}</AlertDescription>
										<AlertTitle>by {log.modifiedBy}</AlertTitle>
									</Box>
								</Alert>
							)
						})}
					</Stack>
				</Container>
			</Container>
			<Modal onClose={onClose} size={props.size || 'xl'} isOpen={isOpen}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Sync AAD Group Members</ModalHeader>
					<ModalCloseButton />
					<ModalBody overflowY="auto" maxHeight="400px">
						{aadGroupMembers && aadGroupMembers.length > 0 && (
							<Box bg="teal.500" w="100%" p={4} color="white" mt="10px">
								<FormControl as="fieldset">
									<FormLabel as="legend">
										<Heading as="h5" size="sm">
											AAD Group: The members are not user and group member.
										</Heading>
 
									</FormLabel>
									<Checkbox
										colorScheme={'green'}
										w="100%"
										isChecked={aadGroupMembers.length === checkedItems.length}
										onChange={() => {
											if (aadGroupMembers.length === checkedItems.length) {
												setCheckedItems([])
											} else {
												setCheckedItems(aadGroupMembers.map(member => member.mail))
											}
										}}
									>
										Select All
									</Checkbox>
									{aadGroupMembers &&
										aadGroupMembers.map((aad, i) => {
											return (
												<Stack spacing={10} direction="row" w="100%">
													<Checkbox
														colorScheme="green"
														w="100%"
														isChecked={checkedItems.indexOf(aad.mail) != -1}
														onChange={e => {
															if (e.target.checked) setCheckedItems([...checkedItems, aad.mail])
															else {
																let index = checkedItems.indexOf(aad.mail)
																if (index > -1) checkedItems.splice(index, 1)
																setCheckedItems([...checkedItems])
															}
														}}>
														{aad.givenName} - {aad.mail.toLowerCase()}
													</Checkbox>
												</Stack>
											)
										})}
								</FormControl>
							</Box>
						)}
						{/* <Box bg="gray.400" w="100%" p={4} color="white" mt="10px">
              <FormControl as="fieldset">
                <FormLabel as="legend">
                  <Heading as="h5" size="sm">
                    Local Group: Not available in AAD Group
                  </Heading>
                </FormLabel>

                {localGroupMembers &&
                  localGroupMembers.map(aad => {
                    return (
                      <Stack spacing={10} direction="row">
                        <Checkbox colorScheme="green" w="100%">
                          {aad.username}
                        </Checkbox>
                      </Stack>
                    );
                  })}
              </FormControl>
            </Box> */}
					</ModalBody>
					{aadGroupMembers && aadGroupMembers.length > 0 ? (
						<ModalFooter bg="transparent" textAlign="center">
							<Button colorScheme="blue" variant="solid" onClick={() => handleSyncWithAddMember(checkedItems, props?.tenantConfig === 1 ? true : false)} key={'md'} m={3}>
								Update Group with Member's
							</Button>
						</ModalFooter>
					) : (
						<Box bg="teal.500" w="100%" p={4} color="white" mt="10px">
							<Text fontSize="lg">There are no members available.</Text>
						</Box>
					)}
				</ModalContent>
			</Modal>
		</Box>
	)
}

export default withTranslation() (GroupHistory)
