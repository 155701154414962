// import { apiUrl } from '../../../config'
import { identityServices } from '../../common'

/**
 *  @todo Custom dynamic function with dynamic urlItemName
 * @todo remove tenanatId from url as ticketing
 */
export class BaseService {
	constructor(options) {
		//super(options)
		this.urlItemName = options.urlItemName
		if (!this.apiUrl) this.apiUrl = sessionStorage.getItem('apiUrl') || localStorage.getItem('apiUrl')
		this.apiHostUrl = options.apiModuleUrl ? `${this.apiUrl || this.apiUrl}/${options.apiModuleUrl}` : this.apiUrl || this.apiUrl
	}
	getAll(tenantId, queryStringArr, apiUrl) {
		let apiUrlCustom = tenantId !== undefined && tenantId !== null ? `${this.apiHostUrl}/${tenantId}` : apiUrl || this.apiHostUrl
		queryStringArr = queryStringArr && queryStringArr != undefined && queryStringArr.length > 0 ? queryStringArr.join('&') : ''
		const requestOptions = {
			method: 'GET',
			headers: { 'Content-Type': 'application/json', clientApp: window.location.pathname.split('/')[1] },
			url: `${apiUrlCustom}/${this.urlItemName}?${queryStringArr}`,
		}

		return identityServices.identityApi.request(requestOptions).then(response => {
			if (response.status !== 200 && response.status !== 304) {
				return Promise.reject(response)
			}

			return response.data
		})
	}
	addNew(item, customerTenantID, apiUrl) {
		let apiUrlCustom = customerTenantID !== undefined && customerTenantID !== null ? `${this.apiHostUrl}/${customerTenantID}` : apiUrl || this.apiHostUrl
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json', clientApp: window.location.pathname.split('/')[1] },
			data: item,
			url: `${apiUrlCustom}/${this.urlItemName}`,
		}

		return identityServices.identityApi.request(requestOptions).then(response => {
			if (response.status !== 200 && response.status !== 304) {
				return Promise.reject(response)
			}

			return response.data
		})
	}
	editRecord(item, customerTenantID, editId, apiUrl) {
		let apiUrlCustom = customerTenantID !== undefined && customerTenantID !== null ? `${this.apiHostUrl}/${customerTenantID}` : apiUrl || this.apiHostUrl
		const requestOptions = {
			method: 'PUT',
			headers: { 'Content-Type': 'application/json', clientApp: window.location.pathname.split('/')[1] },
			data: JSON.stringify(item),
			url: `${apiUrlCustom}/${this.urlItemName}/${editId}`,
		}

		return identityServices.identityApi.request(requestOptions).then(response => {
			if (response.status !== 200 && response.status !== 304) {
				return Promise.reject(response)
			}

			return response.data
		})
	}
	deleteRecord(id, customerTenantID, apiUrl) {
		let apiUrlCustom = customerTenantID !== undefined && customerTenantID !== null ? `${this.apiHostUrl}/${customerTenantID}` : apiUrl || this.apiHostUrl
		const requestOptions = {
			method: 'DELETE',
			headers: { 'Content-Type': 'application/json', clientApp: window.location.pathname.split('/')[1] },
			url: `${apiUrlCustom}/${this.urlItemName}/${id}`,
		}

		return identityServices.identityApi.request(requestOptions).then(response => {
			return response.data
		})
	}
	upsertRecord(item, customerTenantID, apiUrl) {
		let apiUrlCustom = customerTenantID !== undefined && customerTenantID !== null ? `${this.apiHostUrl}/${customerTenantID}` : apiUrl || this.apiHostUrl
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json', clientApp: window.location.pathname.split('/')[1] },
			data: JSON.stringify(item),
			url: `${apiUrlCustom}/${this.urlItemName}/upsert`,
		}

		return identityServices.identityApi.request(requestOptions).then(response => {
			if (response.status !== 202) {
				return Promise.reject(response)
			}

			return response.data
		})
	}
}

// class Qnaservice extends BaseService {
//     publish(){

//     }
// }
