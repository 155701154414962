import moment from 'moment';
import React, { Component } from "react";
import Datetime from 'react-datetime';
// Locale
import { withTranslation } from 'react-i18next';
import Switch from "react-switch";
import Select from "react-virtualized-select";
import "react-virtualized-select/styles.css";
import { Badge, Button, Dropdown, DropdownMenu, DropdownToggle, FormGroup, Input, Label } from 'reactstrap';
import "./react-select.css";
//Locale
class Ffilter extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      dropdownOpen: false,
      applyFilterCheck: false,
      checked: true,
      columns: this.props.columns,
      fields: this.props.fields,
      operators: [{ label: 'Is', value: 'eq' }, { label: 'Contains', value: 'like' }, { label: 'Is Not', value: 'ne' }],
      filters: this.props.filters || [],
      conditions: [{ label: 'And', value: 'and' }, { label: 'Or', value: 'or' }, { label: 'Not', value: 'not' }],
      filteredColumn: [],
      errorMessage: null,
      schemaModel: null,
      selectField: [],
      startDate: null,
      endDate: null,
      fromOpen:false,
      toOpen:false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleDeleteFilter = this.handleDeleteFilter.bind(this);
  }


  componentWillMount() {
    const { entitySchema } = this.props
    if (entitySchema) {
      let schemaModel = entitySchema && entitySchema.describe().children
      this.setState({ schemaModel })
    }
  }

  handleChange(checked) {
    this.setState({ checked });
  }

  toggle() {
    if(!this.state.applyFilterCheck){
        const { filters } = this.state;
        if(filters && filters.length > 0 && !this.isValid(filters)){
          this.setState({filters: filters.filter((e, i) => i !== filters.length - 1), errorMessage: null })
        }
    }
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }
  componentWillReceiveProps(props) {
    if (props && props.resetFilter && !props.filter) {
      this.setState({ filters: [] })
    }
  
    if(props && props.deleteFilter !== null && props.deleteFilter >= 0){
      this.handleDeleteFilter(props.deleteFilter)
    }

    if(props && props.filters){
      this.setState({filters: props.filters})
    }

  }
  componentWillUnmount() {
    let lcS = localStorage.getItem('listPageState')
    if(lcS){
      let listPageState = JSON.parse(lcS)
      if(listPageState.filters && listPageState.filters.length > 0){
        listPageState.filters = listPageState.filters.filter(e =>{
          if(e.operator === 'gt' || e.operator === 'lt'){
            return false
          }
          return true
        })
      }
      localStorage.setItem('listPageState', JSON.stringify(listPageState))
    }
  }

  handleFilterTextChange(index, e) {
    const { filters } = this.state;
    let newFilter = filters && filters.map((f, i) => {
      if (index === i)
        f.value = e.target && e.target.value ? e.target.value : ''
      return f;
    })
    this.setState({
      filters: newFilter,
      errorMessage: null
    })
  }

  handleFilterSelectChange(index, e) {
    const { filters } = this.state;
    let newFilter = filters && filters.map((f, i) => {
      if (index === i)
        f.value = e && e.value ? e.value : ''
      return f;
    })
    this.setState({
      filters: newFilter,
      errorMessage: null
    })
  }

  handleNewFilterAdd() {
    const { filters } = this.state;
    const { t } = this.props;
    if (filters && filters.length > 4) {
      this.setState({
        errorMessage: t("Only 5 filters are allowed.")
      })
      return true;
    }
    let newFilter = [...filters, { name: '', label: '', operator: '', value: '', cond: '', type: '' }]
    this.setState({
      applyFilterCheck: false,
      filters: newFilter,
      errorMessage: null
    })
  }

  handleDeleteFilter(index, e) {
    const { filters } = this.state;
    let newFilter = filters && filters.filter((f, i) => i !== index)
    let applyFilter = newFilter;
    if (this.props.preFilters) {
      let preFilter = Object.keys(this.props.preFilters).map(key => {
        return { name: key, value: this.props.preFilters[key] }
      })
      applyFilter = [...applyFilter, ...preFilter];
    }
    if (this.isValid(applyFilter)) {
      this.props.handleSearch(applyFilter, newFilter);
      this.setState({ dropdownOpen: false })
    }
    this.setState({
      filters: newFilter ? newFilter : [],
      errorMessage: null
    })
    localStorage.removeItem('filter')
  }

  checkJsonFilter(val) {
    const { schemaModel } = this.state;
    let filterVal = val
    if (schemaModel && val) {
      Object.keys(schemaModel).forEach((key, index) => {
        if (schemaModel[key].type === 'string' && key === val) {
          return filterVal = key
        }
        else if (schemaModel[key].type === 'object') {
          let subChildren = schemaModel[key].children;
          subChildren && Object.keys(subChildren).forEach((subKey, subIndex) => {
            if (subChildren[subKey].type === 'string' && subKey === val) {
              return filterVal = `${key}_${val}`
            } else if(subChildren[subKey].type === 'string' && key === val && subKey === Object.keys(subChildren)[0]){
              return filterVal = `${key}_${subKey}`
            }
          })

        }

      })
    }
    return filterVal
  }

  async mapColFields(e) {
    const { columns, selectField, selectFieldOptions } = this.state;
    let col = e && e.value && columns && columns.pop && columns.find(x => x.name === e.value)
    if (col) {
      if (col && col.config && ((col.config.type && col.config.type == 'select') || (col.config.searchType && col.config.searchType == 'select'))) {
        let data = await this.props.handleFieldApi(col.config)
        let listMap = col.config && col.config.options && col.config.options.listMap ? col.config.options.listMap : null
        let options = listMap && data && Array.isArray(data) && data.length > 0 && data.map((x, idx) => {
          return { label: x[listMap.value], value: x[listMap.key] }
        })
        if (options && Array.isArray(options) && options.length > 0) {
          let setSelect = [...selectField, { key: e.value, options }]
          this.setState({
            selectField: setSelect
          })
        }
      }
    }
  }
  handleFieldChange(index, e) {
    const { filters , columns } = this.state;
    const { t } = this.props;
    this.mapColFields(e)
    let filterColumns = columns && columns.length && e && e.value && columns.filter(c => c.name === e.value)
    let newFilter = filters && filters.map((f, i) => {
      if (index === i) {
        f.name = e && e.value ? e.value : ''
        f.label = e && e.label ? e.label : ''
        f.type = filterColumns && filterColumns.length && (filterColumns[0].config && filterColumns[0].config.fieldType || filterColumns[0].type) ==="date"? 'date' : null
        f.startDate = e && e.startDate ? e.startDate : ''
        f.endDate = e && e.endDate ? e.endDate : ''
      }
      return f;
    })
    if (newFilter && newFilter.length > 1) {
      let filter = newFilter.filter(f => f.type === 'date')
      if (filter && filter.length > 1) {
        filters && filters.map((f, i) => {
          if (index === i) {
            f.name = ''
            f.label = ''
            f.type = null
          }
          return f;
        })
        this.setState({
          filters: filters,
          errorMessage: t("Only one filter for date is allowed.")
        })
        return true;
      }
    }
    this.setState({
      filters: newFilter,
      errorMessage: null
    })
  }

  handleOperatorChange(index, e) {
    const { filters } = this.state;
    let newFilter = filters && filters.map((f, i) => {
      if (index === i)
        f.operator = e && e.value ? e.value : ''
      return f;
    })
    this.setState({
      filters: newFilter,
      errorMessage: null
    })
  }

  handleCondChange(index, e) {
    const { filters } = this.state;
    let newFilter = filters && filters.map((f, i) => {
      if (index === i)
        f.cond = e && e.value ? e.value : ''
      return f;
    })
    this.setState({
      filters: newFilter,
      errorMessage: null
    })
  }

  handleFilterApply() {
    const { filters,startDate,endDate } = this.state;
    let applyFilter = filters;
    if (this.props.preFilters) {
      let preFilter = Object.keys(this.props.preFilters).map(key => {
        return { name: key, value: this.props.preFilters[key] }
      })
      applyFilter = [...applyFilter, ...preFilter];
    }
    let mapJsonFilter=applyFilter && applyFilter.pop && applyFilter.length>0 && applyFilter.map((f,idx)=>{
      if (!f.type){
        if(Array.isArray(f.value)){
          return { name: this.checkJsonFilter(f.name), label: f.label, value: f.value, operator:f.operator || 'contains', cond: f.cond }
        }
        return { name: this.checkJsonFilter(f.name), label: f.label, value: f.value.replace(/(["])/g, '\\$&').replace(/([^']|^)'((?:'')*)(?!')/g, "$1$2''"), operator:f.operator, cond: f.cond }
      }
    })
    let dateFilterMap = []
    if (applyFilter && applyFilter.pop && applyFilter.length>0 && applyFilter.find(x => x.type === 'date')) {
      applyFilter.forEach((a, i) => {
        if (a.fromDate != '' || startDate == '') {
          dateFilterMap.push({
            label : a.label,
            name: a.name,
            value: a.fromDate ? a.fromDate + 'T00:00:000' : startDate + 'T00:00:000',  //moment.utc(a.fromDate).toISOString(),
            cond: 'and',
            operator: 'ge',
          });
        }
        if (a.toDate != '' || endDate == '' ) {
          dateFilterMap.push({
            label : a.label,
            name: a.name,
           value: a.toDate ? a.toDate + 'T23:59:000' : endDate + 'T23:59:000', //moment.utc(a.toDate).toISOString(),
            cond: 'and',
            operator: 'le',
          });
        }
      });
      mapJsonFilter = [ ...dateFilterMap]   
    }
    localStorage.setItem('filter', JSON.stringify(filters))
    if (this.isValid(mapJsonFilter)) {
      this.props.handleSearch(mapJsonFilter, filters);
      this.setState({ applyFilterCheck: true, dropdownOpen: false, errorMessage: null })
    }

  }

  isValid(filters) {
    const { t } = this.props
    let valid = filters && filters.every((filter, index) => {
      if (filter.name == "" || filter.value == "" || filter.operator == "" || filter.type == '' ) {
        this.setState({
          errorMessage: t('Please choose Field Name, Operator and value')
        })
        return false;
      }
      else if (index !== 0 && filter.cond == "") {
        this.setState({
          errorMessage: t('Please choose Condition')
        })
        return false
      }
      else
        return true
    })
    return valid;
  }

  handleKeepFilter(checked) {
    const { filters } = this.state;
    this.setState({ checked });
    if (checked) {
      if (this.isValid(filters)) {
        let applyFilter = filters;
        if (this.props.preFilters) {
          let preFilter = Object.keys(this.props.preFilters).map(key => {
            return { name: key, value: this.props.preFilters[key] }
          })
          applyFilter = [...applyFilter, ...preFilter];
        }
        this.props.handleSearch(applyFilter, filters);
        this.setState({ dropdownOpen: false,  errorMessage: null })
      }
    }
    else {
      let filterValues = [];
      let applyFilter = filterValues;
        if (this.props.preFilters) {
          let preFilter = Object.keys(this.props.preFilters).map(key => {
            return { name: key, value: this.props.preFilters[key] }
          })
          applyFilter = [...applyFilter, ...preFilter];
        }
      this.props.handleSearch(applyFilter, filterValues);
      this.setState({ dropdownOpen: false,  errorMessage: null })
    }
  }

  setOptions(filter, options) {
    const { t } = this.props
    let findVal = options && options.pop && options.length > 0 && options.find(x => x.key === filter)
    if (findVal && findVal.options)
      return findVal.options
    else
      return [{ label: t('--select--'), value: '' }]
  }
  setSelectedValue(key, value, options) {
    let findVal = options && options.pop && options.length > 0 && options.find(x => x.key === key)
    if (value && findVal && findVal.options) {
      return findVal.options.find(x => x.value === value)
    }

  }
  onChangeDateTime(type, index, event) {
    const { filters } = this.state;
		event = event.format('YYYY-MM-DD')
    let newFilter = filters && filters.map((f, i) => {
      if (index === i){
        if (type === 'From') {
          this.setState({
            fromOpen: false,
            toOpen: true,
            startDate: event,
            endDate: ''
          })
          f.fromDate = event
        }
        else if(type === 'To'){
          this.setState({
            fromOpen: false,
            toOpen: false,
            endDate: event
          })
          f.toDate = event
        }
        f.type = 'date'
      }
      return f;
    })
    this.setState({
      filters: newFilter,
      errorMessage: null
    })
  }
  toggleCal(type) {
		if (type == 'from') {
			this.setState({
				fromOpen: !this.state.fromOpen,
				toOpen: false,
			})
		} else {
			this.setState({
				fromOpen: false,
				toOpen: !this.state.toOpen,
			})
		}
	}
  render() {
    const { columns, operators, filters, conditions, selectField} = this.state;
    const { t } = this.props;
    let options = columns && columns.pop && columns.map((f, index) => {
      return { label: t(f.label), value: f.key || f.name, disabled: filters && filters.some(filter => filter.name === f.key) }
    })
    options = options && options.pop && options.filter(f => {
      let filterable = this.props.fields && this.state.fields[f.value] && this.state.fields[f.value].filterable;
      return (filterable === undefined || filterable === true ? true : false)
    })
    options = options && options.filter(i=> {return i.value !== "data_queueObj_lookupProps_usecase"})
    let operator = operators.map(el => {
      return {
        label: t(el.label),
        value: el.value
      }
    });
    let date = {
			From: {
				value: this.state.startDate,
				open: this.state.fromOpen,
				onClick: this.toggleCal.bind(this, 'from'),
			},
			To: {
				value: this.state.endDate,
				open: this.state.toOpen,
				onClick: this.toggleCal.bind(this, 'to'),
			},
		}
    let valid = (type, current) => {
			let diff = 356;
			let today = moment()
			if (type == 'From') return current.isBefore(today)
			if (type == 'To') return current.isAfter(moment(this.state.startDate).subtract(1, 'day').format('YYYY-MM-DD')) && current.isBefore(moment(this.state.startDate).add(diff, 'day')) && current.isBefore(today)
			return current.isAfter(moment(this.state.startDate).subtract(1, 'day').format('YYYY-MM-DD')) && current.isBefore(today)
		}
    return (
      <div className="for-hide-fields wd-1 filter-view">

        <Dropdown className="text-left asc-transparent-btn" size="sm" isOpen={this.state.dropdownOpen} toggle={this.toggle}>
          <DropdownToggle caret color="transparent" >
            <span className="pr-1" ><i className="fa fa-sort-amount-asc"></i></span> {t("Filter")}
            {filters && filters.length > 0 && <Badge color="success" pill>{filters && filters.length}</Badge>}
          </DropdownToggle>
          <DropdownMenu>
            <ul className="fields-list mt-1">
              {filters && filters.length < 1 && <li><span classs="no-sort">{t("No filter applied")}</span></li>}
              {filters && filters.length > 0 && filters.map((filter, index) => <li className="clearfix" key={index}>
                <span className="for-delete pull-left pr-3 mt-3 " onClick={this.handleDeleteFilter.bind(this, index)}><i className="fa fa-trash"></i></span>
                <div className="b-left pull-right">
                  {index === 0 && <span className="for-label-text pt-3">{t('Where')}</span>}
                  {index >= 1 && <span className="for-label-drop p-2 align-top">
                    <Select invalid options={conditions} value={conditions && conditions.find(o => o.value === filter.cond)} onChange={this.handleCondChange.bind(this, index)} />
                  </span>}
                  <span className="for-label-drop p-2 align-top"><Select options={options} placeholder={t("Field Name")} value={options && options.find(o => o.value === filter.name)} onChange={this.handleFieldChange.bind(this, index)} /></span>
                  {/* <span className="for-label-drop p-2 align-top"><Select options={operator} placeholder={t("Operator")} value={operators && operators.find(o => o.value === filter.operator)} onChange={this.handleOperatorChange.bind(this, index)} /></span> */}
                  {filter && filter.type === 'date' && Object.keys(date).map((key, i) => {
                    return(
                    <span className="for-label-drop p-2 align-top" >
                    <FormGroup className="custom-margin-form-group" key={i} >
                   
                    <Label for="ex-url">{t(key)}</Label>
                    <div className="custom-calendar">
                      <span className="set-date-sec" name="schedule_date" onClick={date[key].onClick}>
                        {date[key].value ? moment(date[key].value, 'YYYY-MM-DD').format('DD MMM YY') : t('Select')}
                        <span className="cal-ico">
                          <i className="fa fa-calendar fa-lg" />
                        </span>
                      </span>
                     <div className={`custom-calendar-inner ${key == 'From' ? 'cal-left-zero' : 'cal-right-zero'}`}
                       style={{ display: date[key].open ? 'block' : 'none' }}>
                       <Datetime
                         key={index}
                         isValidDate={valid.bind(this, key)}
                         value={key == 'To' ? (date[key].value && date[key].value != '' ? new Date(date[key].value) : new Date(date['From'].value)) : date[key].value}
                         input={false}
                         onChange={this.onChangeDateTime.bind(this, key , index)}
                         viewDate={date[key].value}
                         timeFormat={false}
                       />
                     </div>
                    </div>
                  </FormGroup>
                  </span>
                  )
                  })}
                 {!filter.type && <><span className="for-label-drop p-2 align-top"><Select options={operator} placeholder={t("Operator")} value={operators && operators.find(o => o.value === filter.operator)} onChange={this.handleOperatorChange.bind(this, index)} /></span>
                  <span className="for-input-text p-2 align-top" style={{ minWidth: '200px' }}>
                    {!selectField || !selectField.some((val, idx) => val.key == filter.name) && <Input bsSize="sm" name="filterText" id="filterText" value={(filter.value)} onChange={this.handleFilterTextChange.bind(this, index)} placeholder="" />}
                    {selectField && selectField.pop && selectField.length > 0 && selectField.some((val, idx) => val.key === filter.name) && <Select options={this.setOptions(filter.name, selectField)} value={this.setSelectedValue(filter.name, filter.value, selectField)} onChange={this.handleFilterSelectChange.bind(this, index)} />}
                  </span>
                  </>}
                </div>
              </li>
              )
              }
            </ul>
            <div className="row">

            </div>
            <div className="row">
              <div className="col-sm-6 filter-btn clear-fix mt-1 ">
                <button className="pull-left mb-2" size="sm" onClick={this.handleNewFilterAdd.bind(this)}>
                  <i className="fa fa-plus pr-2"></i>
                  {t("Add Filter")}
                </button>
              </div>
              <div className="col-sm-6">
                <Button className="pull-right" color="secondary" onClick={this.handleFilterApply.bind(this)}>{t('Apply Filter')}</Button>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="filter-btn clear-fix mt-1  fixed-bottom asc-grey pl-2 pt-2 " style={{ zIndex: 0 }}>
                  <span className="for-switch"> <label style={{marginRight:"5px"}}> <Switch uncheckedIcon={false} checkedIcon={false} margin={'none'} height={13} width={20} onChange={this.handleKeepFilter.bind(this)} checked={this.state.checked} /></label>{t("keep Filtered")}
                  </span>
                  <span style={{ float: 'right', color: 'red', marginRight: '5px' }}>{t(this.state.errorMessage)}</span>
                </div>
              </div>
            </div>


          </DropdownMenu>
        </Dropdown>
      </div>
    )
  }
}

export default withTranslation()(Ffilter);
