import { roleConstants, roleServices } from '../'
import { alertActions } from '../../common'

const getAllUsersReqeust = () => ({
	type: roleConstants.GETALL_USERS_REQUEST,
})

const getAllUsersSuccess = payload => ({
	type: roleConstants.GETALL_USERS_SUCCESS,
	payload,
})

const getAllUsersFailure = payload => ({
	type: roleConstants.GETALL_USERS_FAILURE,
	payload,
})

export const getAllUsers = (tenandId, t) => async dispatch => {
	try {
		dispatch(getAllUsersReqeust())
		let users = await roleServices.getAllUsers(tenandId)
		dispatch(getAllUsersSuccess(users))
	} catch (error) {
		let errorMsg = (t && t('Unable to fetch the list of users')) || 'Unable to fetch the list of users'
		errorMsg = errorMsg + ' ' + (error ? error.message : '')
		dispatch(getAllUsersFailure(errorMsg))
		dispatch(alertActions.error(errorMsg))
	}
}

const addUsersReqeust = () => ({
	type: roleConstants.ADD_USERS_REQUEST,
})

const addUsersSuccess = () => ({
	type: roleConstants.ADD_USERS_SUCCESS,
})

const addUsersFailure = payload => ({
	type: roleConstants.ADD_USERS_FAILURE,
	payload,
})

export const addUsers = (tenandId, userPayload, t) => async dispatch => {
	try {
		dispatch(addUsersReqeust())
		let users = await roleServices.addUsers(tenandId, userPayload)
		if (users && users.status && users.status === 201) {
			dispatch(addUsersSuccess())
			dispatch(alertActions.success((t && t('Successfully added new user')) || 'Successfully added new user'))
		} else dispatch(alertActions.error((t && t('User exists with same Username, Please try another')) || 'User exists with same Username, Please try another'))
	} catch (error) {
		let errorMsg = (t && t('Unable to add user')) || 'Unable to add of user'
		errorMsg = errorMsg + ' ' + (error ? error.message : '')
		dispatch(addUsersFailure(errorMsg))
		dispatch(alertActions.error(errorMsg))
	}
}

const modifyUsersReqeust = () => ({
	type: roleConstants.MODIFY_USERS_REQUEST,
})

const modifyUsersSuccess = users => ({
	type: roleConstants.MODIFY_USERS_SUCCESS,
	userUpdate: users,
})

const modifyUsersFailure = () => ({
	type: roleConstants.MODIFY_USERS_FAILURE,
})

export const modifyUsers = (tenandId, userId, userPayload, t) => async dispatch => {
	try {
		dispatch(modifyUsersReqeust())
		await roleServices.modifyUsers(tenandId, userId, userPayload)
		dispatch(modifyUsersSuccess(userPayload))
		dispatch(alertActions.success((t && t('User updated successfully')) || 'User updated successfully'))
	} catch (error) {
		dispatch(modifyUsersFailure())
		dispatch(alertActions.error((t && t(`Unable to update user`)) || `Unable to update user : ${error}`))
	}
}

const getUserRolesReqeust = () => ({
	type: roleConstants.GET_ROLES_REQUEST,
})

const getUserRolesSuccess = payload => ({
	type: roleConstants.GET_ROLES_SUCCESS,
	payload,
})

const getUserRolesFailure = payload => ({
	type: roleConstants.GET_ROLES_FAILURE,
	payload,
})

export const getUserRoles = (tenandId, userId, t) => async dispatch => {
	dispatch(getUserRolesReqeust())
	let errorMsg =
		(t && t('Cannot fetch the list of roles at this moment, please try again later')) || 'Cannot fetch the list of roles at this moment, please try again later'
	try {
		let roles = await roleServices.getUserRoles(tenandId, userId)
		dispatch(getUserRolesSuccess(roles))
	} catch (error) {
		errorMsg = errorMsg + ' ' + (error ? error.message : '')
		dispatch(getUserRolesFailure(errorMsg))
		dispatch(alertActions.error(errorMsg))
	}
}

const getUsersCountReqeust = () => ({
	type: roleConstants.GET_USERS_COUNT_REQUEST,
})

const getUsersCountSuccess = payload => ({
	type: roleConstants.GETUSERS_COUNT_SUCCESS,
	payload,
})

const getUsersCOuntFailure = payload => ({
	type: roleConstants.GETUSERS_COUNT_FAILURE,
	payload,
})

export const getUsersCount = (tenandId, t) => async dispatch => {
	try {
		dispatch(getUsersCountReqeust())
		let userCount = await roleServices.getUsersCount(tenandId)
		dispatch(getUsersCountSuccess(userCount))
	} catch (error) {
		let errorMsg = (t && t('Unable to fetch the list of users')) || 'Unable to fetch the list of users'
		errorMsg = errorMsg + ' ' + (error ? error.message : '')
		dispatch(getUsersCOuntFailure(errorMsg))
		dispatch(alertActions.error(errorMsg))
	}
}

const modifyResetEmailReqeust = () => ({
	type: roleConstants.PASSWORD_RESET_EMAIL_REQUEST,
})

const modifyResetEmailSuccess = () => ({
	type: roleConstants.PASSWORD_RESET_EMAIL_SUCCESS,
})

const modifyResetEmailFailure = () => ({
	type: roleConstants.PASSWORD_RESET_EMAIL_FAILURE,
})

export const registerUserRequiredActions = (tenandId, userId, body, t) => async dispatch => {
	try {
		dispatch(modifyResetEmailReqeust())
		let resp = await roleServices.registerUserRequiredActions(tenandId, userId, body)
		dispatch(modifyResetEmailSuccess())
		dispatch(alertActions.success(resp))
	} catch (error) {
		dispatch(modifyResetEmailFailure())
		dispatch(alertActions.error((t && t(`Unable to send email to user`)) || `Unable to send email to user ${error}`))
	}
}

const getUserReqeust = () => ({
	type: roleConstants.GET_USER_REQUEST,
})

const getUserSuccess = payload => ({
	type: roleConstants.GET_USER_SUCCESS,
	payload,
})

const getUserFailure = payload => ({
	type: roleConstants.GET_USER_FAILURE,
	payload,
})

export const getUserById = (tenandId, userId, t) => async dispatch => {
	try {
		dispatch(getUserReqeust())
		let users = await roleServices.getUserById(tenandId, userId)
		dispatch(getUserSuccess(users))
	} catch (error) {
		let errorMsg = (t && t('Unable to fetch the user')) || 'Unable to fetch the user'
		errorMsg = errorMsg + ' ' + (error ? error.message : '')
		dispatch(getUserFailure(errorMsg))
		dispatch(alertActions.error(errorMsg))
	}
}

const modifyChangePasswordReqeust = () => ({
	type: roleConstants.MODIFY_CHANGE_PASSWORD_REQUEST,
})

const modifyChangePasswordSuccess = () => ({
	type: roleConstants.MODIFY_CHANGE_PASSWORD_SUCCESS,
})

const modifyChangePasswordFailure = error => ({
	type: roleConstants.MODIFY_CHANGE_PASSWORD_FAILURE,
	error,
})

export const changeUserPassword = (tenandId, userId, body, t) => async dispatch => {
	try {
		dispatch(modifyChangePasswordReqeust())
		let resp = await roleServices.changeUserPassword(tenandId, userId, body)
		if (resp === 204) {
			dispatch(modifyChangePasswordSuccess())
			dispatch(alertActions.success((t && t('Password has been changed successfully')) || 'Password has been changed successfully'))
		} else dispatch(alertActions.success((t && t('Unable to password, please try later')) || 'Unable to password, please try later'))
	} catch (error) {
		let errorMsg = (t && t('Unable to change password')) || 'Unable to change password'
		errorMsg = errorMsg + ' ' + (error ? error.message : '')
		dispatch(modifyChangePasswordFailure(errorMsg))
		dispatch(alertActions.error(errorMsg))
	}
}

export const getTenantRoles = (tenandId, roleName, t) => async dispatch => {
	try {
		dispatch(getUserRolesReqeust())
		let tenantRoles = await roleServices.getTenantRoles(tenandId, roleName)
		dispatch(getUserRolesSuccess(tenantRoles))
	} catch (error) {
		let errorMsg = (t && t('Unable to fetch the list of roles for user')) || 'Unable to fetch the list of roles for user'
		errorMsg = errorMsg + ' ' + (error ? error.message : '')

		dispatch(getUserRolesFailure(errorMsg))
		dispatch(alertActions.error(errorMsg))
	}
}

// Get all user custom attributes from db

const getAllUserAttributesRequest = () => ({
	type: roleConstants.GET_ALL_USER_ATTRIBUTES_REQUEST,
})

const getAllUserAttributesSuccess = payload => ({
	type: roleConstants.GET_ALL_USER_ATTRIBUTES_SUCCESS,
	payload,
})

const getAllUserAttributesFaliure = error => ({
	type: roleConstants.GET_ALL_USER_ATTRIBUTES_FAILURE,
	error,
})

export const getAllUserAttributes = t => async dispatch => {
	try {
		dispatch(getAllUserAttributesRequest())
		let resp = await roleServices.getAllUserAttributes()
		if (resp) {
			dispatch(getAllUserAttributesSuccess(resp))
		} else {
			dispatch(alertActions.success((t && t('Unable to fetch user attributes, please try later')) || 'Unable to fetch user attributes, please try later'))
			dispatch(getAllUserAttributesFaliure((t && t(`Unable to fetch user attributes`)) || `Unable to fetch user attributes`))
		}
	} catch (error) {
		let errorMsg = (t && t('Unable to fetch user attributes')) || 'Unable to fetch user attributes '
		errorMsg = errorMsg + ' ' + (error ? error.message : '')
		dispatch(getAllUserAttributesFaliure(errorMsg))
		dispatch(alertActions.error(errorMsg))
	}
}
