/**
 * RU on all settings including whitelisting
 * This is in common as all apps would need it
 * Setting Key value pairs come from db of the app.
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import SubscriptionComponent from '../components/subscription'
import { SubscriptionService, SubscriptionActions } from '../'
/**
 * Show the current subscriptions...
 * 
 * Able to change plans on current apps.
 * 
 * Able to add new apps to my subscriptions with choice of plans to choose from.
 * 
 * Not a standard crud...
 * 
 * 
 * 
 */






let service = new SubscriptionService()
let ActionObj = new SubscriptionActions({service})
const mapDispatchToProps = (dispatch, props) => {
    let fns = ActionObj.getFns()
    return {
        ...bindActionCreators(
            {
                ...fns

            },
            dispatch
        )
    }

}
const mapStateToProps = (state, ownProps) => {

    const { identity, alert, tenant, entityList, entity, baseResource, subscription } = state
    const profile = identity ? identity.profile : null
    let props = { profile, alert, tenant, subscription }
    props.moduleName = 'Subscription'
    return props
}





export const SubscriptionContainer = connect(mapStateToProps, mapDispatchToProps)(SubscriptionComponent)
