import React from 'react'
import { withTranslation } from 'react-i18next'
// import { ListGroup, ListGroupItem } from 'reactstrap';
import { CardLayout, ExpendableDataGrid } from '..'
import { utils } from '../../../..'
const format = require('string-format')
class GridLayout extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			meta: this.props.meta,
			envityValues: this.props.entityValues,
			lookUpMapping: this.props.lookUpMapping,
			list: this.props.list,
			columns: this.props.columns,
			totalCount: this.props.totalCount,
			importData: this.props.importData,
			handleListAction: this.props.handleListAction,
			entity: this.props.entity,
			itemDef: this.props.itemDef,
			cardPerRow: this.props.cardPerRow,
			backgroundColor: this.props.backgroundColor,
			color: this.props.color,
			currentItem: this.props.currentItem,
			entityValues: this.props.entityValues,
		}
	}
	componentDidMount() {
		this.getRequest()
	}

	getRequest() {
		const {
			itemDef,
			params: { itemId },
			meta: { bKeys },
		} = this.props
		if (itemDef && itemDef.dataSource && itemDef.dataSource.api) {
			let params = [],
				idObj = {}
			let paramsSplit = itemId && itemId.split('__')

			paramsSplit &&
				bKeys.pop &&
				bKeys.forEach((k, i) => {
					if (k === 'tenantId') {
						idObj[k] = paramsSplit[i]
					} else idObj[k] = paramsSplit[i]
				})
			if (itemDef.dataSource.params) {
				itemDef.dataSource.params.forEach(c => {
					params.push(idObj[c])
				})
			}
			let apiDef = {}
			apiDef = utils.cloneObject(itemDef.dataSource.api)
			if (apiDef.response && apiDef.response.ref) {
				apiDef.response.ref = format(apiDef.response.ref, idObj)
			}
			this.props.getRefItems({ apiDef: apiDef, filter: itemDef.dataSource.filter, params: params, t: this.props.t })
		}
	}

	componentWillReceiveProps(props) {
		const { itemDef } = this.state
		if (props.entity && props.entity.refEntityList) {
			let data = itemDef && itemDef.dataSource && itemDef.dataSource.api && props.entity.refEntityList[itemDef.dataSource.api.entityName]
			if (data && itemDef.dataSource && itemDef.dataSource.ref) {
				let responseDef = itemDef.dataSource.ref && itemDef.dataSource.ref.split('.')
				let res =
					responseDef &&
					responseDef.reduce((p, c) => {
						return p[c]
					}, data)
				this.setState({
					list: res ? res : [],
				})
			} else {
				this.setState({
					list: data,
				})
			}
		}
		if (props.entity && props.entity.entityValues) {
			this.setState({
				entityValues: props.entity.entityValues,
			})
		}
	}
	renderItem(item) {
		const { itemDef } = this.state
		switch (item.type) {
			case 'grid':
				return (
					<ExpendableDataGrid
						lookUpMapping={this.state.lookUpMapping}
						meta={this.props.meta}
						list={this.state.list}
						columns={this.state.columns}
						fields={this.state.fields}
						totalCount={this.props.totalCount}
						importData={this.props.importData}
						handleListAction={this.props.handleListAction}
						entity={this.state.entity}
						itemDef={this.state.itemDef}
						itemClick={this.props.itemClick}
						itemCellLookUpClick={this.props.itemCellLookUpClick}
						setGridParentData={this.props.setGridParentData}
						handleSelectItems={this.props.handleSelectItems}
						gridParentData={this.props.gridParentData}
						// handleUpdateEntity={this.handleUpdateEntity.bind(this)}
						//{...this.props}
					/>
				)
			case 'cardList':
				return (
					<CardLayout
						key={item.name}
						meta={this.props.meta}
						data={this.state.list}
						columns={this.state.columns}
						cardPerRow={itemDef.cardPerRow}
						backgroundColor={itemDef.backgroundColor}
						color={itemDef.color}
						itemClick={this.props.itemClick}
						handleListAction={this.props.handleListAction}
						itemDef={this.state.itemDef}
						getRequest={this.getRequest.bind(this)}
						{...this.props}
					/>
				)

			default:
				break
		}
	}

	render() {
		return (
			<div key={this.state.itemDef.name} className="col-sm-12">
				{this.renderItem(this.state.currentItem)}
			</div>
		)
	}
}
export default withTranslation()(GridLayout)
