/**
 * RU on all settings including whitelisting
 * This is in common as all apps would need it
 * Setting Key value pairs come from db of the app.
 */
import { Entity } from '../common/base/entity/entity'
import { methodSchemas, entitySchema, collectionSchemas } from './roleGroup.schema'
import RoleGroupDetail from './components/roleGroup.detail'
// import IntentBotDetail from './component/intent.bot.detail'
import { ContentAction } from "./ContentAction";

/**
 * Instructions: 
 * 1. For simple usage steps:
 * a. fieldsMeta, actionsMeta, screens, sectionsMeta definition and creation of BaseEntity obj with these inputs
 * 2. For simple overrides of baseEntity 
 * a. create an extended class of baseEntity and override its methods...
 * 3. For more customization
 * a. create a derived Service, derived actions and use them in derived BasedEntity class.
 * b. for component customization pass those components to the constuctor of derived BaseEntity class. SearchList component is the root component.
 *
 * 
 */


class RoleGroupEntity extends Entity {
    // override the following if needed.
    // derived actionObj
    // derived Service obj
    // getActionFns if you want to suppress the baseActionobj methods
    constructor(options) {
        super(options);
    }
}

const fieldsMeta = {

    roleGroup: {
        label: 'Group Name',
        required: true,
        type: 'text',
        name: 'roleGroup',
    },
    status: {
        label: "Status",
        name: 'status',
        options: [{ label: 'Published', value: 'Published' }, { label: 'Draft', value: 'Draft' }],
        type: 'select',
        required: true,
        width: '20%'
    },
    roleInfo: {
        label: "Roles",
        name: 'roleInfo',
        type: 'text',
    },
    createdAt: {
        label: 'Created At',
        type: 'text',
        name: 'createdAt',
    },
    createdBy: {
        label: 'Created By',
        type: 'text',
        name: 'createdBy',
    },
    updatedAt: {
        label: 'Updated At',
        type: 'text',
        name: 'updatedAt',
    },
}

const actionsMeta = {
    create: {
        label: 'Create',
        name: 'create',
        type: 'button',
        action: 'upsertItem',
    },
    upsert: {
        label: 'Update',
        name: 'upsert',
        type: 'button',
        action: 'upsertItem',
    },
}
const sectionsMeta = {
    headerComponent: {
        component: 'DetailHeader',
    },
    roleGroupUpdateForm: {
        cols: [7, 5],
        items: [
        { name: 'roleGroup', type: 'field' },
        { name: 'status', type: 'field' },
        // { name: 'createdBy', type: 'field' },
        // { name: 'createdAt', type: 'field' },
        { name: 'upsert', type: 'action', col: 2 }
        ],
        classes: 'composite-inner-form'
    },


    roleGroupCreateForm: {
        cols: [7, 5],
        items: [
        { name: 'roleGroup', type: 'field', col: 1 },
        { name: 'status', type: 'field', col: 1 },
        { name: 'upsert', type: 'action', col: 2 }
    ],
        classes: 'composite-inner-form'
    },
}


const tabsMeta = {

}

const screens = {
    view: {
        items: [{ name: 'headerComponent', type: 'section' }, { name: 'roleGroupCreateForm', type: 'section' }],
        classes: 'composite-form',
        title: 'Manage Role Group'
    },
    create: {
        items: [{ name: 'headerComponent', type: 'section' }, { name: 'roleGroupCreateForm', type: 'section' }]
    },
    edit: {
        items: [{ name: 'headerComponent', type: 'section' }, { name: 'roleGroupUpdateForm', type: 'section' }]
    },
    list: {
        items: [
        { name: 'roleGroup', type: 'link' },
        {name: 'roleInfo', type: 'field'},
        { name: 'status', type: 'field' },
        // { name: 'createdAt', type: 'field' },
        { name: 'createdBy', type: 'field' },
        { name: 'updatedAt', type: 'date' }
        ],
        // filter: [
        //     { 
        //         name:'status',
        //         value: 'Deleted',
        //         operator: 'ne'
        //     }
        // ]
    },
}
// const components = { searchList: EntityComponent} // can overrides the components like this if needed
const RoleGroup = new RoleGroupEntity({ ActionClass: ContentAction, fieldsMeta, actionsMeta, sectionsMeta, tabsMeta, screens, name: 'RoleGroup', title: 'Manage Role Group', methodSchemas, collectionSchemas, entitySchema, bKeys: ["roleGroup", "tenantId", "status"], entityUrlPaths: { getAll: 'botmgmt-service/RoleGroup/findAndCountAllWithDefaultTenant',  default: "botmgmt-service/RoleGroup" }, fetchDetailByApi: true, components: {
    Create: RoleGroupDetail,
    Detail: RoleGroupDetail,
} })

const RoleGroupListSearchContainer = RoleGroup.getContainer('ListSearch')
const RoleGroupCreateContainer = RoleGroup.getContainer('Create')
const RoleGroupDetailContainer = RoleGroup.getContainer('Detail')
export { RoleGroupListSearchContainer, RoleGroupCreateContainer, RoleGroupDetailContainer }